import React from "react";
import Popup from "../Popup";

export interface PopupProps {
  closePopup();
}

const wrapComponentWithPopup = (Component) => {
  return class extends React.Component<any, any>  {
    render() {
      const { className, preventClose, onStateChange, ...other } = this.props;

      return (
        <Popup
          preventClose={preventClose}
          className={className}
          onStateChange={onStateChange}
        >
          <Component {...other} />
        </Popup>
      );
    }
  }
}

export default wrapComponentWithPopup;
