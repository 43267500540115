import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../../state/newDevelopment";
import OwnerNameFilters from "./OwnerNameFilters/OwnerNameFilters";

const OwnerNameFiltersContainer = () => {
  const shouldDisplay = useSelector(newDevelopmentSelectors.getOwnerNameFiltersShouldDisplay);

  return shouldDisplay
    ? <>
        <OwnerNameFilters />
        <div className="divisor" />
      </>
    : null;
}

export default OwnerNameFiltersContainer;
