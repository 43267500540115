import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Tabs from "../../../../sharedComponents/Tabs";
import Tab from "../../../../sharedComponents/Tabs/Tab";
import TabContainer from "../../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../../sharedComponents/Tabs/TabPanel";
import { usageColor } from "../../../../utils/buildingUsageProperties";
import CondoPanel from "./CondoPanel";
import config from "./config";
import HotelPanel from "./HotelPanel";
import IndustrialPanel from "./IndustrialPanel";
import MultifamilyPanel from "./MultifamilyPanel";
import OfficePanel from "./OfficePanel";
import ParkingConstraintsPanel from "./ParkingConstraintsPanel";
import RetailPanel from "./RetailPanel";

const mapStateToProps = (state) => {
  return {
    toggles: developmentSelectors.getBuildingUsageToggles(state)
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const BUILDING_USE_ORDER = [
  BuildingUse.Condo,
  BuildingUse.Multifamily,
  BuildingUse.Hotel,
  BuildingUse.Office,
  BuildingUse.Retail,
  BuildingUse.Industrial,
]

class PropertyUses extends React.Component<Props, {}>  {
  /**
   * Render component for tab button.
   */
  renderTabButton = (buildingUse: BuildingUse) => {
    const className = config[buildingUse].class;
    const label = config[buildingUse].label;
    const color = usageColor(buildingUse);

    return (
      <>
        <div
          className={`icon ${className}`}
          style={{ backgroundColor: color }}
        />
        <label>{label}</label>
      </>
    );
  }

  render() {
    const { toggles } = this.props;

    const defaultTab = BUILDING_USE_ORDER.filter((buildingUse: BuildingUse) => toggles[buildingUse])[0];
    if (!defaultTab) return null;

    return (
      <div className="component--property-uses">
        <Tabs defaultTab={defaultTab}>
          {(onTabClick, activeTab) =>
            <>
              <TabContainer>
                {BUILDING_USE_ORDER.map((buildingUse) => {
                  if (!toggles[buildingUse]) return null;
                  return (
                    <Tab
                      tabId={buildingUse}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                      button={this.renderTabButton(buildingUse)}
                      key={`building_use_${buildingUse}`}
                    />
                  )
                })}
                <Tab
                  tabId={BuildingUse.Parking}
                  onTabClick={onTabClick}
                  activeTab={activeTab}
                  button={this.renderTabButton(BuildingUse.Parking)}
                />
              </TabContainer>
              <TabPanel tabId={BuildingUse.Condo} activeTab={activeTab}>
                <CondoPanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Multifamily} activeTab={activeTab}>
                <MultifamilyPanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Hotel} activeTab={activeTab}>
                <HotelPanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Office} activeTab={activeTab}>
                <OfficePanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Retail} activeTab={activeTab}>
                <RetailPanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Industrial} activeTab={activeTab}>
                <IndustrialPanel />
              </TabPanel>
              <TabPanel tabId={BuildingUse.Parking} activeTab={activeTab}>
                <ParkingConstraintsPanel />
              </TabPanel>
            </>
          }
        </Tabs>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(PropertyUses);
