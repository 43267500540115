import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../state/development";
import developmentAccessors from "../../../state/development/utils/developmentAccessors";
import Format from "../../../types/Format";
import Unit from "../../../types/Unit";
import unitConversions from "../../../utils/unitConversions";
import valueFormatter from "../../../utils/valueFormatter";

const mapStateToProps = (state, ownProps) => {
  let development = developmentSelectors.getDevelopment(state);
  return {
    value: developmentAccessors.getInputValue(
      development,
      ownProps.variableId
    ) as number,
  };
};

interface OwnProps {
  variableId: string;
  text: string;
  formatOptions: Format.Options;
  unitTarget: Unit.Type;
  unitIsInverse: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class PanelSectionTitle extends React.PureComponent<Props, {}> {
  render() {
    const { text, value, formatOptions, unitTarget, unitIsInverse } =
      this.props;
    let finalValue = unitTarget
      ? unitConversions.convertFromBase(value, unitTarget, unitIsInverse)
      : value;

    finalValue = formatOptions
      ? valueFormatter.format(finalValue, formatOptions)
      : finalValue;

    return (
      <div className="top-label">
        <div className="text">{text}</div>
        <span>{finalValue}</span>
      </div>
    );
  }
}

export default connect(mapStateToProps)(PanelSectionTitle);
