import React from "react";
import Demographics from "./Demographics";
import PropertyInformation from "./PropertyInformation";
import Zoning from "./Zoning";
import { ParentPage } from "./types";

type OwnProps = {
  parcelFeature: any;
  parentPage?: ParentPage;
};

type Props = OwnProps;

class ParcelInformationTable extends React.PureComponent<Props, {}> {
  render() {
    const { parcelFeature, parentPage } = this.props;
    return (
      <div className="component--parcel-information-table">
        <Demographics parcelFeature={parcelFeature} />
        <PropertyInformation parcelFeature={parcelFeature} parentPage={parentPage} />
        <Zoning parcelFeature={parcelFeature} parentPage={parentPage} />
      </div>
    );
  }
}

export default ParcelInformationTable;
