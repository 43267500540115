import React from "react";
import companyInfo from "../../../../../utils/companyInfo";

export default class Support extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--support">
        <div className="header">SUPPORT</div>

        <div className="links-container">
          {/* Support */}
          <a href={companyInfo.TUTORIALS_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-support" />
            Product Tutorials
          </a>
          <a href={companyInfo.BLOG_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-blog" />
            Blog
          </a>
        </div>
      </div>
    );
  }
}
