import { useEffect } from "react";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { Props } from "../NewProjectMap";
import { deepClone } from "./hooksUtils";

// /** Logic from componentDidUpdate for layerConfigurations */
export const useLayerConfigurationsEffect = (
  props: Props,
  prevProps: React.MutableRefObject<Props | undefined>,
  handleMoveEnd: () => void
) => {
  useEffect(() => {
    if (!prevProps.current) {
      prevProps.current = props;
      return;
    }
    const previousProps = deepClone(prevProps.current);
    prevProps.current = deepClone(props);

    if (
      !previousProps.layerConfigurations[
        MapStyleProperties.LayerId.SmartSearchResultsFill
      ].isActive &&
      props.layerConfigurations[
        MapStyleProperties.LayerId.SmartSearchResultsFill
      ].isActive
    ) {
      props.setQueryViewport(true);
    }

    if (
      !previousProps.layerConfigurations[
        MapStyleProperties.LayerId.UsaParcelsQuery
      ].isVisible &&
      props.layerConfigurations[
        MapStyleProperties.LayerId.SmartSearchResultsFill
      ].isVisible &&
      props.layerConfigurations[
        MapStyleProperties.LayerId.SmartSearchResultsFill
      ].isEnabled
    ) {
      handleMoveEnd();
    }
  }, [props.layerConfigurations]);
};
