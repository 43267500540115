import { DynamicLayerConfiguration } from "types/DynamicLayerGroupConfiguration";
import { LayerSector } from "types/LayerSector";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";

const config: DynamicLayerConfiguration = {
  [DynamicLayerId.Dynamic_Layer_Population]: {
    layerSector: LayerSector.Census,
    buttonText: "Population",
    initializationYear: MapStyleProperties.RawDynamicLayersFieldId.Population2022,
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2010,
      MapStyleProperties.LayerId.DynamicPopulationFill_2011,
      MapStyleProperties.LayerId.DynamicPopulationFill_2012,
      MapStyleProperties.LayerId.DynamicPopulationFill_2013,
      MapStyleProperties.LayerId.DynamicPopulationFill_2014,
      MapStyleProperties.LayerId.DynamicPopulationFill_2015,
      MapStyleProperties.LayerId.DynamicPopulationFill_2016,
      MapStyleProperties.LayerId.DynamicPopulationFill_2017,
      MapStyleProperties.LayerId.DynamicPopulationFill_2018,
      MapStyleProperties.LayerId.DynamicPopulationFill_2019,
      MapStyleProperties.LayerId.DynamicPopulationFill_2020,
      MapStyleProperties.LayerId.DynamicPopulationFill_2021,
      MapStyleProperties.LayerId.DynamicPopulationFill_2022,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2010,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2011,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2012,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2013,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2014,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2015,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2016,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2017,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2018,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2019,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2020,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2010,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2011,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2012,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2013,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2014,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2015,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2016,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2017,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2018,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2019,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2020,
    ],
    legendTitle: "Population",
    layerDescription: "Number of people living in a census tract according to data from 2010 to 2020.",
  },
  [DynamicLayerId.Dynamic_Layer_MedianRent]: {
    layerSector: LayerSector.Census,
    buttonText: "Median Rent",
    initializationYear: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2022,
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2013,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2014,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2015,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2016,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2017,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2018,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2019,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2020,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2021,
      MapStyleProperties.LayerId.DynamicMedianRentFill_2022,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2013,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2014,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2015,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2016,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2017,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2018,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2019,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2020,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022,
    ],
    legendTitle: "Median Gross Rent",
    layerDescription: "Median gross rents by census tract according to data from 2010 to 2020.",
  },
  [DynamicLayerId.Dynamic_Layer_Employment_Per_Population]: {
    layerSector: LayerSector.Census,
    buttonText: "Employment",
    initializationYear: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022,
    ],
    legendTitle: "Employment per Population",
    layerDescription: "Employment per population by census tract according to data from 2010 to 2020.",
  },
  [DynamicLayerId.Dynamic_Layer_Household_Income]: {
    layerSector: LayerSector.Census,
    buttonText: "Median Income",
    initializationYear: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2022,
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2013,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2014,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2015,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2016,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2017,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2018,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2019,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2020,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2021,
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2022,

      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022,

      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022,
    ],
    legendTitle: "Median Household Income",
    layerDescription: "Median household income by census tract according to data from 2010 to 2020.",
  },

  [DynamicLayerId.Dynamic_Layer_Sea_Level_Rise]: {
    layerSector: LayerSector.Environmental,
    buttonText: "Sea Level Rise",
    initializationYear: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_1,
    layers: [MapStyleProperties.LayerId.SeaLevelRiseQuery],
    legendTitle: "Sea Level Rise",
    layerDescription: "Sea Level Rise as per the NOAA Office for Coastal Management.",
  },
};

export default config;
