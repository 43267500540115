import { SubscriptionInvitationsState } from "./reducers";

/**
 * Get subscription invitations state.
 */
const getSubscriptionInvitationsState = (state): SubscriptionInvitationsState => {
  return state.subscriptionInvitations;
}

/**
 * Get subscription invitations.
 */
const getSubscriptionInvitations = (state) => {
  const subscriptionInvitations = getSubscriptionInvitationsState(state);
  return subscriptionInvitations.invitations;
}

/**
 * Get the managed subscriptions.
 */
const getManagedSubscriptions = (state) => {
  const subscriptionInvitations = getSubscriptionInvitationsState(state);
  return subscriptionInvitations.managedSubscriptions;
}

/**
 * Get the managed subscriptions images.
 */
const getManagedSubscriptionsImages = (state) => {
  const subscriptionInvitations = getSubscriptionInvitationsState(state);
  return subscriptionInvitations.managedSubscriptionsImages;
}

/**
 * Get the `invitationSent` flag.
 */
const getInvitationSent = (state) => {
  const subscriptionInvitations = getSubscriptionInvitationsState(state);
  return subscriptionInvitations.invitationSent;
}

export default {
  getSubscriptionInvitations,
  getManagedSubscriptions,
  getManagedSubscriptionsImages,
  getInvitationSent,
}
