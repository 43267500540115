import React from "react";
import PdfContext from "../../../PdfContext";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const TOTAL_PIXELS_FOR_TITLE = 320;
const DEFAULT_TITLE = "Property Report";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "132px",
    right: "0",
    width: "232px",
    height: "88px",
    padding: "16px 4px",
  },
  titleText: {
    textTransform: "uppercase",
  },
});

const getTitleFontSize = (textLength: number) => {
  return Math.min(TOTAL_PIXELS_FOR_TITLE / textLength, 40);
};

const Title = () => (
  <PdfContext.Consumer>
    {({ title, colorPalette }) => {
      const coverTitle = title || DEFAULT_TITLE;
      const titleFontSize = title ? getTitleFontSize(title.length) : 20;

      return (
        <View style={[styles.container, { backgroundColor: colorPalette.primary }]}>
          <Text style={[styles.titleText, { color: colorPalette.text, fontSize: titleFontSize }]}>{coverTitle}</Text>
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Title;
