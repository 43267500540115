import React from "react";
import PdfContext from "../../../../PdfContext";
import { displayableUsages, usageColor } from "../../../../../../../utils/buildingUsageProperties";
import { buildingUsageIsEnabled, allTogglesDisabled } from "../../../../../../../utils/uiToggleHelper";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 40,
    right: 12,
    display: "flex",
    flexDirection: "row",
    padding: "3px 2px 3px 7px",
    backgroundColor: "#F5F7F8",
  },
  use: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 5,
  },
  box: {
    height: 4,
    width: 8,
    marginRight: 3,
    backgroundColor: "#FFFFFF",
  },
  text: {
    fontFamily: "Roboto Condensed",
    fontSize: 6.25,
    color: "#333333",
    textTransform: "capitalize",
  },
});

const UsesLegend = () => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      if (allTogglesDisabled(toggles)) return null;

      const displayableUsagesList = displayableUsages().filter((usage) => buildingUsageIsEnabled(toggles, usage));

      return (
        <View style={styles.container}>
          {displayableUsagesList.map((usage) => (
            <View style={styles.use} key={usage}>
              <View style={[styles.box, { backgroundColor: usageColor(usage) }]} />
              <Text style={styles.text}>{usage}</Text>
            </View>
          ))}
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default UsesLegend;
