import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the office buildables presets.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const commonAreaFactor = developmentAccessors.getInputValue(development, VariableId.OfficeCommonAreaFactor) as number;
  const netUsableArea = targetArea * (1 - commonAreaFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development, VariableId.OfficeNetUsableAreaToggleable);

  return {
    [VariableId.OfficeNetUsableAreaToggleable]: {
      "minimum": 0,
      "maximum": 2 * netUsableArea || previousMaximum,
      "value": netUsableArea,
    },
    [VariableId.OfficeYearOneRentPerArea]: await generatorFromModel.generateRentPreset(development, VariableId.OfficeYearOneRentPerArea, BuildingUse.Office),// TODO Area?
    [VariableId.OfficeHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.OfficeHardCostPerArea, BuildingUse.Office),
  };
}

export default generatePresets;
