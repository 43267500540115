import { Map as MapboxMap } from "mapbox-gl";
import React from "react";
import ReactMapboxGl, { RotationControl } from "react-mapbox-gl";
import Measure from "react-measure";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import { pdfSelectors } from "state/pdf";
import { GraphDataSources } from "types/Graph";
import authentication from "utils/authentication";
import { DynamicLayerId, LayerId, RawDynamicLayersFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicEmploymentPerPopLayer from "views/pages/NewProjectPage/NewProjectMap/DynamicMapLayers/DynamicEmploymentPerPopLayer";
import DynamicMedianIncomeLayer from "views/pages/NewProjectPage/NewProjectMap/DynamicMapLayers/DynamicMedianIncomeLayer";
import DynamicMedianRentLayer from "views/pages/NewProjectPage/NewProjectMap/DynamicMapLayers/DynamicMedianRentLayer";
import DynamicPopulationLayer from "views/pages/NewProjectPage/NewProjectMap/DynamicMapLayers/DynamicPopulationLayer";
import DynamicSeaLevelRiseLayer from "views/pages/NewProjectPage/NewProjectMap/DynamicMapLayers/DynamicSeaLevelRiseLayer";
import dynamicLayersConfig from "views/sharedComponents/DynamicLayerSelection/dynamicLayersConfig";
import { getRandomColor } from "views/utils/colors";
import { layersActions } from "../../../../state/layers";
import { pdfActions } from "../../../../state/pdf";
import { mapsActions, mapsSelectors } from "../../../../state/ui/shared/maps";
import { ReactMapboxGlCamera } from "../../../../types/ReactMapboxGlCamera";
import arrayHelper from "../../../../utils/arrayHelper";
import layerHelper, {
  DynamicLayerConfigurationsLayerIds,
  LayerConfigurationsLayerIds,
} from "../../../../utils/mapbox/layerHelper";
import mapboxHelper from "../../../../utils/mapbox/mapboxHelper";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import LayerSelection from "../../../sharedComponents/LayerSelection";
import HotelAverageDailyRateFeatureLayer from "../../NewProjectPage/NewProjectMap/RentFeatureLayers/HotelAverageDailyRateFeatureLayer";
import IndustrialRateFeatureLayer from "../../NewProjectPage/NewProjectMap/RentFeatureLayers/IndustrialRateFeatureLayer";
import MultifamilyRentFeatureLayer from "../../NewProjectPage/NewProjectMap/RentFeatureLayers/MultifamilyRentFeatureLayer";
import OfficeRateFeatureLayer from "../../NewProjectPage/NewProjectMap/RentFeatureLayers/OfficeRateFeatureLayer";
import RetailRateFeatureLayer from "../../NewProjectPage/NewProjectMap/RentFeatureLayers/RetailRateFeatureLayer";
import BuildingLayer from "./BuildingLayer";

/**
 * @fileoverview This container wraps the Mapbox map component and manages any data and
 * functionality it requires.
 */

const VISIBLE_LAYERS: Array<LayerConfigurationsLayerIds> = [
  MapStyleProperties.LayerId.UsaGraphsQuery,
  MapStyleProperties.LayerId.UsaDemographicsQuery,
  MapStyleProperties.LayerId.UsaParcelsBorder,
  MapStyleProperties.LayerId.SeaLevelRiseQuery,
];

const DYNAMIC_VISIBLE_LAYERS: Array<DynamicLayerConfigurationsLayerIds> = [
  MapStyleProperties.LayerId.SeaLevelRiseQuery,
];

const mapStateToProps = (state) => {
  return {
    mapStyleUrl: mapsSelectors.getMapStyleUrl(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
    styleChanged: mapsSelectors.getStyleChanged(state),
    parcelCenterOfMass: developmentSelectors.getParcelCenterOfMass(state),
    pdfGraphDataSourcesAreInitialized: pdfSelectors.getGraphDataSourcesAreInitialized(state),
  };
};

const mapDispatchToProps = {
  setCameraForPdf: pdfActions.setCamera,
  initializeLayers: mapsActions.initializeLayers,
  initializeLayersOnStyleChange: mapsActions.initializeLayersOnStyleChange,
  setAvailableLayers: mapsActions.setAvailableLayers,
  setAvailableDynamicLayers: mapsActions.setAvailableDynamicLayers,
  generateRentFeaturesStart: layersActions.generateRentFeaturesStart,
  initializePdfGraphDataSources: pdfActions.initializeGraphDataSources,
  resetDynamicLayers: mapsActions.resetDynamicLayers,
};

interface OwnProps {
  camera: any;
  setMap(map: MapboxMap);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

interface State {
  boundingBox?: [number, number, number, number];
}

/**
 * @fileoverview This container wraps the Mapbox map component and manages
 *  any data and functionality it requires.
 */

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  transformRequest: (url, resourceType) => {
    const currentUser = authentication.getCurrentUser();
    if (currentUser) {
      const fbToken = authentication.getFirebaseId();
      if (
        resourceType === MapStyleProperties.MapResourceType.TileServer &&
        !url.includes(MapStyleProperties.BaseUrlsForRequest.BaseMapboxURL)
      ) {
        return {
          url,
          headers: { authHeader: fbToken },
        };
      } else {
        return undefined;
      }
    } else {
      if (
        resourceType === MapStyleProperties.MapResourceType.TileServer &&
        !url.includes(MapStyleProperties.BaseUrlsForRequest.BaseMapboxURL)
      ) {
        return {
          url,
          headers: { "cache-control": "max-age=0" },
        };
      } else {
        return undefined;
      }
    }
  },
});

class Map extends React.Component<Props, State> {
  map: any;
  camera: ReactMapboxGlCamera;

  constructor(props) {
    super(props);

    // This attribute helps us to manage the quirky behavior of the `<Mapbox>`
    // component, implemented by `react-mapbox-gl`.
    // See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
    // TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
    this.camera = {
      zoom: [props.camera.zoom],
      bearing: [props.camera.bearing],
      pitch: [props.camera.pitch],
      center: props.camera.target,
    };

    this.state = {};

    this.props.setCameraForPdf(this.camera);
  }

  /**
   * Resize the Map when component updates.
   */
  componentDidUpdate() {
    if (this.map) {
      this.map.resize();
    }
  }

  /**
   * Resize the Map.
   */
  resizeMap = () => {
    if (this.map) {
      this.map.resize();
    }
  };

  /**
   * The `<Mapbox>` component, implemented by `react-mapbox-gl`, requires the
   * camera props to be wrapped in arrays, and will jump the camera to the
   * indicated position if the array objects that are passed change (NOT if the
   * values they contain change). In other words, in order to tell the camera
   * to jump to a new location, it is necessary to break the array equality, and
   * replace the old array objects with new array objects.
   *
   * See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
   *
   * This method helps work around this behavior, by ensuring that the array
   * objects that are passed to the `<Mapbox>` props are only replaced with a
   * new array objects at the appropriate time.
   *
   * TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
   */
  updateCameraArrays = () => {
    let cameraIsDifferent =
      this.props.camera.zoom !== this.camera.zoom[0] ||
      this.props.camera.pitch !== this.camera.pitch[0] ||
      this.props.camera.bearing !== this.camera.bearing[0] ||
      this.props.camera.target[0] !== this.camera.center[0] ||
      this.props.camera.target[1] !== this.camera.center[1];

    if (cameraIsDifferent) {
      this.camera.zoom = [this.props.camera.zoom];
      this.camera.pitch = [this.props.camera.pitch];
      this.camera.bearing = [this.props.camera.bearing];
      this.camera.center = this.props.camera.target.slice();
    }
  };

  /**
   * Set the camera for PDF generation.
   */
  handleMoveEnd = () => {
    if (this.map) {
      let mapCenter = this.map.getCenter();
      this.props.setCameraForPdf({
        zoom: [this.map.getZoom()],
        pitch: [this.map.getPitch()],
        bearing: [this.map.getBearing()],
        center: [mapCenter.lng, mapCenter.lat],
      });
    }
  };

  /**
   * Update available layers currently in the viewport.
   */
  updateAvailableLayers = () => {
    const layersIds = Object.keys(this.props.layerConfigurations).filter((layerId) =>
      Boolean(this.map.getLayer(layerId))
    );
    const availableLayers: Set<LayerConfigurationsLayerIds> = this.map
      .queryRenderedFeatures({ layers: layersIds })
      .reduce((layerIdsSet, featureLayer) => {
        layerIdsSet.add(featureLayer.layer.id);

        return layerIdsSet;
      }, new Set());

    this.props.setAvailableLayers(availableLayers);
  };

  /**
   * Update available dynamic layers currently in the viewport.
   */
  updateAvailableDynamicLayers = () => {
    if (this.map.getZoom() < 10) {
      this.props.setAvailableDynamicLayers([]);
      return;
    }

    let dynamicLayerIds: Array<LayerId> = [];

    const seaLevelRiseFeatures = this.map.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.SeaLevelRiseQuery],
    });

    const demographicFeatures = this.map.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaGraphsQuery],
    });

    const isSeaLevelDataAvailable = layerHelper.isSeaLevelDataAvailable(seaLevelRiseFeatures);
    const isPopulationDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.Population2022,
      demographicFeatures
    );
    const isMedianRentDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.MedianGrossRentTotal2022,
      demographicFeatures
    );
    const isEmploymentDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
      demographicFeatures
    );
    const isMedianIncomeAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.MedianHouseholdIncome2022,
      demographicFeatures
    );

    if (isSeaLevelDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Sea_Level_Rise].layers);
    if (isPopulationDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Population].layers);
    if (isMedianRentDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_MedianRent].layers);
    if (isEmploymentDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(
        dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Employment_Per_Population].layers
      );
    if (isMedianIncomeAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(
        dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Household_Income].layers
      );

    this.props.setAvailableDynamicLayers(dynamicLayerIds);
  };

  /**
   * Extract the graph data source features at the current development's parcel and put them in
   * Redux so they are available when the PDF is generated.
   */
  initializeGraphDataSources = () => {
    if (!this.map) return;

    // Query the map's graph data layer for the features containing the graph data.
    // Uses the parcel's center of mass as the query point.
    const { parcelCenterOfMass } = this.props;
    const queryPoint = this.map.project(parcelCenterOfMass);
    const graphDataFeatures = this.map.queryRenderedFeatures(queryPoint, {
      layers: [MapStyleProperties.LayerId.UsaGraphsQuery],
    });

    // Generate the `GraphDataSources` object from the query results.
    const graphFeatureDataLookup: GraphDataSources = {};
    graphDataFeatures.forEach((graphDataFeature) => {
      const geoId = graphDataFeature.properties[MapStyleProperties.RawGraphingFieldId.GeoId];
      graphFeatureDataLookup[geoId] = {
        color: getRandomColor(),
        feature: graphDataFeature,
      };
    });

    // Update the PDF Redux state with the results.
    this.props.initializePdfGraphDataSources(graphFeatureDataLookup);
  };

  /**
   * Handle map style load.
   * NOTE: This handler is only called once in the lifetime of the map component.
   * This means that changing the style will not trigger this event.
   */
  handleStyleLoad = (map: MapboxMap) => {
    this.map = map;
    if (this.props.setMap) this.props.setMap(map);

    this.initializeMapLayersVisibility();
    this.props.resetDynamicLayers();
    // Set initial configurations on the store for the opacity.
    this.props.initializeLayers(VISIBLE_LAYERS);

    map.on("idle", () => {
      const currentBoundingBox = mapboxHelper.calculateCurrentBoundingBox(this.map);
      if (currentBoundingBox) {
        if (!this.state.boundingBox || !arrayHelper.equals(this.state.boundingBox, currentBoundingBox)) {
          this.setState({ boundingBox: currentBoundingBox });
          mapboxHelper.updateRentFeatures(this.map, this.props.generateRentFeaturesStart);
        }
      }

      if (!this.props.styleChanged) this.updateAvailableLayers();
      if (!this.props.styleChanged) this.updateAvailableDynamicLayers();
      if (!this.props.pdfGraphDataSourcesAreInitialized) this.initializeGraphDataSources();
    });

    map.on("style.load", () => {
      // NOTE: For the reason stated on the function description above, initializing the map
      // after a style change has to be done on 'style.load' event.
      this.initializeMapLayersVisibility();
      // Set initial configurations on the store for the opacity.
      this.props.initializeLayersOnStyleChange(VISIBLE_LAYERS);
      this.props.resetDynamicLayers();
    });
  };

  /**
   * Initialize layers visibility layout property on the map.
   */
  initializeMapLayersVisibility = () => {
    layerHelper.initializeMapLayers(this.map, this.props.layerConfigurations, VISIBLE_LAYERS);
    layerHelper.initializeDynamicMapLayers(this.map, this.props.dynamicLayerConfigurations, DYNAMIC_VISIBLE_LAYERS);
  };

  render() {
    this.updateCameraArrays();
    const { mapStyleUrl } = this.props;

    return (
      <Measure onResize={this.resizeMap}>
        {({ measureRef }) => (
          <div ref={measureRef} className="component--map">
            <Mapbox
              style={mapStyleUrl}
              zoom={this.camera.zoom}
              pitch={this.camera.pitch}
              bearing={this.camera.bearing}
              center={this.camera.center}
              onStyleLoad={this.handleStyleLoad}
              onMoveEnd={this.handleMoveEnd}
              containerStyle={{
                height: "100%",
                width: "100%",
                margin: "0 auto",
              }}
              movingMethod="jumpTo"
            >
              {/* Explorer Page Specific Layers */}
              <BuildingLayer />
              <RotationControl position="bottom-right" className="rotation-control" />

              {/* Generic Generated Layers */}
              <HotelAverageDailyRateFeatureLayer />
              <IndustrialRateFeatureLayer />
              <MultifamilyRentFeatureLayer />
              <OfficeRateFeatureLayer />
              <RetailRateFeatureLayer />

              {/* Dynamic Layers */}
              <DynamicPopulationLayer />
              <DynamicMedianRentLayer />
              <DynamicEmploymentPerPopLayer />
              <DynamicMedianIncomeLayer />
              <DynamicSeaLevelRiseLayer />
            </Mapbox>
            <LayerSelection map={this.map} />
          </div>
        )}
      </Measure>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
