import React, { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import RedirectWarningPopup from "./RedirectWarningPopup";
import authentication from "../../../utils/authentication";
import { Path } from "../../../types/Path";
import { useNavigate } from "react-router-dom";
import { developmentActions, developmentSelectors } from "../../../state/development";
import { Development } from "types/Development/Development";

const mapDispatchToProps = {
  saveDevelopment: developmentActions.saveStart,
};

const mapStateToProps = (state) => {
  let development = developmentSelectors.getDevelopment(state);
  return {
    project: development,
  };
};

interface OwnProps {
  classes: string | undefined;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & OwnProps;

const NewProject: React.FC<Props> = ({
  classes,
  saveDevelopment,
  project,
}: {
  classes?: string;
  saveDevelopment: any;
  project: Development;
}) => {
  const [redirectWarningIsActive, setRedirectWarningIsActive] = useState(false);
  const navigate = useNavigate();

  const toggleRedirectWarning = () => {
    setRedirectWarningIsActive(!redirectWarningIsActive);
  };

  const handleRedirectToNewProjectButton = async () => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    let navigateToUrl = `${Path.NewProject}`;
    const cameraCenter = project?.camera?.target;
    if (cameraCenter) {
      navigateToUrl = `${Path.NewProject}/${cameraCenter[0]},${cameraCenter[1]},16.1`;
    }
    if (userIsAuthenticated) {
      saveDevelopment();
      navigate(navigateToUrl);
      return;
    }

    setRedirectWarningIsActive(true);
  };

  return (
    <div
      className={`component--new-project ${redirectWarningIsActive ? "active" : ""} ${classes || ""}`}
      onClick={handleRedirectToNewProjectButton}
    >
      <div className="icon" />
      <div className="text">New Project</div>

      {redirectWarningIsActive && (
        <RedirectWarningPopup onStateChange={toggleRedirectWarning} onClickSubscribe={saveDevelopment} />
      )}
    </div>
  );
};

export default connector(NewProject);
