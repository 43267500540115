import React from "react";
import PdfContext from "../../../PdfContext";
import companyInfo from "../../../../../../../utils/companyInfo";
import { View, StyleSheet, Text, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexGrow: 1,
    marginTop: "35px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Roboto",
  },
  label: {
    paddingBottom: "2px",
    borderBottom: "1px",
    fontFamily: "Roboto Bold",
    fontSize: "17px",
  },
  summaryText: {
    flexGrow: 1,
    flexShrink: 1,
    marginTop: "10px",
    fontSize: "8px",
    fontWeight: "normal",
    letterSpacing: "0.25px",
    lineHeight: "1.7px",
  },
  companyLink: {
    color: "red",
  },
});

const renderDefaultSummary = () => {
  return (
    <Text style={styles.summaryText}>
      {
        "The following report was generated with the use of the Deepblocks platform, which integrates the data, calculations, visualization, and return analysis of any real property. Deepblocks uses artificial intelligence to get smarter about each market, periodically adjusting proprietary global indexes to improve the market data provided. \n\n\
      Deepblocks is a PropTech company coming out of the Singularity University accelerator, with a goal to make real estate investment analysis simple and accessible to all investors. \n\n\
      If you'd like to know more about creating your own report please visit our website: "
      }
      <Link style={styles.companyLink} src={companyInfo.WEBSITE_URL}>
        deepblocks.com
      </Link>
    </Text>
  );
};

const Summary = () => (
  <PdfContext.Consumer>
    {({ summary, colorPalette }) => {
      return (
        <View style={styles.container}>
          <Text style={[styles.label, { borderColor: colorPalette.secondary }]}>SUMMARY</Text>
          {summary ? <Text style={styles.summaryText}>{summary}</Text> : renderDefaultSummary()}
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Summary;
