import React from "react";
import { useSelector } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import config from "./config";
import KpiSection from "./KpiSection";

const KpiPanel = () => {
  const unitSystem = useSelector(developmentSelectors.getUnitSystem);
  const projectIsForSale = useSelector(developmentSelectors.getProjectIsForSale);

  return (
    <div className="component--kpi-panel">
      <ConfigurationHandler
        config={config.floorAreaRatio}
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"dotted-right"}
      />
      <ConfigurationHandler
        config={config.projectTotalUnitQuantity}
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"dotted-right"}
      />
      <ConfigurationHandler
        config={config.projectNumberOfFloors}
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"solid-right"}
      />
      <ConfigurationHandler
        config={config.projectAnnualNetOperatingIncomeForBackOfEnvelope}
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"dotted-right"}
      />
      <ConfigurationHandler
        config={config.projectTotalDevelopmentCost}
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"dotted-right"}
      />
      <ConfigurationHandler
        config={
          projectIsForSale
              ? config.saleReturnOnInvestmentForBackOfEnvelope
              : config.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope
        }
        component={KpiSection}
        unitSystem={unitSystem}
        styleVariation={"roc-text"}
      />
    </div>
  );
}

export default KpiPanel;
