import { combineReducers } from "redux";
import accordionSectionReducer, { AccordionSectionState, INITIAL_STATE as panelsInitialState } from "./accordionSection/reducers";
import authenticationUiReducer, { AuthenticationUiState, INITIAL_STATE as authenticationInitialState } from "./authentication/reducers";
import mapsReducer, { INITIAL_STATE as mapsInitialState, MapsState } from "./maps/reducers";
import routeReducer, { INITIAL_STATE as routeInitialState, RouteState } from "./route/reducers";

export interface SharedUiState {
  accordionSection: AccordionSectionState;
  maps: MapsState;
  authentication: AuthenticationUiState;
  route: RouteState;
}

export const INITIAL_STATE: SharedUiState = {
  accordionSection: panelsInitialState,
  maps: mapsInitialState,
  authentication: authenticationInitialState,
  route: routeInitialState,
}

export default combineReducers<SharedUiState>({
  maps: mapsReducer,
  accordionSection: accordionSectionReducer,
  authentication: authenticationUiReducer,
  route: routeReducer,
});
