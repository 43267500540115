export enum BuildingUse {
  Condo = "condo",
  Multifamily = "multifamily",
  Hotel = "hotel",
  Office = "office",
  Industrial = "industrial",
  Retail = "retail",
  Parking = "parking",
  Project = "project",
  RentalUses = "rentalUses",
  IncomeProducing = "incomeProducing",
}
