import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";

const config: Config = {
  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Project Cost",
    formatOptions: {
      type: Format.Type.Currency,
    },
  },

  [VariableId.FloorAreaRatio]: {
    variableId: VariableId.FloorAreaRatio,
    buildingUse: BuildingUse.Project,
    text: "FAR with parking",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 2,
    },
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    text: "Floors",
    formatOptions: {
      type: Format.Type.Number,
    },
  },

  [VariableId.SaleReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.SaleReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "NOI",
    formatOptions: {
      type: Format.Type.Currency,
    },
  },

  [VariableId.ProjectTotalUnitQuantity]: {
    variableId: VariableId.ProjectTotalUnitQuantity,
    text: "Total Units",
    formatOptions: {
      type: Format.Type.Number,
    },
  },
};

export default config;
