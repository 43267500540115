import { useEffect } from "react";
import { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import analytics from "utils/analytics";
import { ParcelTool } from "types/ParcelTool";
import { SetPolygonIsBeingChangedFunction } from "state/newDevelopment/types";

export const drawOnCreate = (
  map: React.MutableRefObject<Map | undefined>,
  mapboxDraw: React.MutableRefObject<MapboxDraw | null>,
  parcelToolFromToolbar: ParcelTool,
  cleanDrawnParcels: (features: any) => void,
  updateDrawnParcels: () => void,
  setParcelPointToBeSelected: (feature: any) => void,
  setPolygonIsBeingChanged: SetPolygonIsBeingChangedFunction,
  setDistanceToLastPolygonVertex: React.Dispatch<
    React.SetStateAction<number | null>
  >
) => {
  useEffect(() => {
    if (map.current) {
      const onCreate = (data) => {
        if (mapboxDraw && mapboxDraw.current) {
          analytics.trackDrawParcel(data.features[0]);
          cleanDrawnParcels(data.features);

          updateDrawnParcels();
          const features = mapboxDraw.current.getAll().features;
          if (features.length === 0) return;

          const lastFeature = features[features.length - 1];
          setParcelPointToBeSelected(lastFeature);
          setPolygonIsBeingChanged(false);
          setDistanceToLastPolygonVertex(null);
        }
      };

      map.current.on("draw.create", onCreate);

      return () => {
        if (map.current) {
          map.current.off("draw.create", onCreate);
        }
      };
    } else {
      return () => {}; // Return a no-op function when map.current is not set
    }
  }, [parcelToolFromToolbar]);
};
