import React from "react";
import PdfContext from "../../PdfContext";
import StandardPage from "../sharedComponents/StandardPage";
import AddressBar from "../sharedComponents/AddressBar";
import Summary from "./Summary";
import CoverImage from "./CoverImage";
import LogoImage from "./LogoImage";
import Title from "./Title";
import FromContactDetails from "./FromContactDetails";
import ToContactDetails from "./ToContactDetails";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  leftContainer: {
    flex: 2.5,
    width: "411px",
    height: "100%",
  },
  rightContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flex: 1,
    height: "100%",
    padding: "36px 15px 20px 15px",
    boxSizing: "content-box",
  },
  contactsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "35px",
    flexGrow: 1,
    paddingBottom: 30,
  },
  addressContainer: {
    borderBottom: "1px",
    borderColor: "#333333",
    display: "flex",
    marginTop: "160px",
    paddingBottom: "10px",
  },
});

const CoverPage = () => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <StandardPage>
        <View style={styles.content}>
          <View style={styles.leftContainer}>
            <CoverImage />
            <View style={styles.contactsContainer}>
              <FromContactDetails />
              <ToContactDetails />
            </View>
          </View>

          <View style={styles.rightContainer}>
            <LogoImage />
            <Title />
            <View
              style={[
                styles.addressContainer,
                { borderColor: colorPalette.secondary },
              ]}
            >
              <AddressBar textColor="#333333" />
            </View>
            <Summary />
          </View>
        </View>
      </StandardPage>
    )}
  </PdfContext.Consumer>
);

export default CoverPage;
