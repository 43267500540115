import React from "react";
import { Chargebee } from "../../../../../types/Service";
import chargebee from "../../../../../utils/functions/chargebee";

interface OwnProps {
  onStateChange(data: { priceEstimate: number | undefined; couponIsValid: boolean });
  children(
    calculateFinalEstimate: (
      planPrices: Chargebee.PlanPrices,
      planId: Chargebee.PlanId,
      couponCode: string
    ) => Promise<void>
  );
}

type Props = OwnProps;

export class PriceEstimateCalculator extends React.PureComponent<Props, {}> {
  /**
   * Get coupon object if it exists.
   */
  calculateFinalEstimate = async (planPrices: Chargebee.PlanPrices, planId: Chargebee.PlanId, couponCode: string) => {
    const { onStateChange } = this.props;

    let coupon: Chargebee.Coupon | null = null;
    if (couponCode) {
      try {
        coupon = await chargebee.getCoupon(couponCode);
        if (
          coupon &&
          coupon.status === Chargebee.CouponStatus.Active &&
          ((coupon.plan_constraint === Chargebee.CouponPlanConstraint.Specific && coupon.plan_ids.includes(planId)) ||
            coupon.plan_constraint === Chargebee.CouponPlanConstraint.All)
        ) {
          const priceEstimate = await this.getEstimateWithCoupon(planId, couponCode);
          if (priceEstimate === null) {
            onStateChange({ couponIsValid: true, priceEstimate: planPrices[planId] });
          } else {
            onStateChange({ couponIsValid: true, priceEstimate: priceEstimate });
          }
        } else {
          onStateChange({ couponIsValid: false, priceEstimate: planPrices[planId] });
        }
      } catch (error) {
        console.warn(error);
        onStateChange({ couponIsValid: false, priceEstimate: planPrices[planId] });
      }
    } else {
      onStateChange({ couponIsValid: true, priceEstimate: planPrices[planId] });
    }
  };

  /**
   * Update estimate for current plan and coupons if available.
   */
  getEstimateWithCoupon = async (planId: Chargebee.PlanId, coupon) => {
    try {
      const estimate = await chargebee.getEstimate(planId, [coupon]);
      return estimate.invoice_estimate.sub_total / 100;
    } catch (error) {
      console.warn(error);
      return null;
    }
  };

  render() {
    return this.props.children(this.calculateFinalEstimate);
  }
}

export default PriceEstimateCalculator;
