import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class TrafficCountLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.TrafficDailyAverage2019}
        classes="traffic-count"
        legendRows={[
          <LegendRow
            color="#429a1a"
            text="0 - 6,380"
          />,
          <LegendRow
            color="#8ec21d"
            text="6,380 - 15,350"
          />,
          <LegendRow
            color="#f9e81e"
            text="15,350 - 31,871"
          />,
          <LegendRow
            color="#feb81d"
            text="31,871 - 64,906"
          />,
          <LegendRow
            color="#e86800"
            text="64,906 - 125,584"
          />,
          <LegendRow
            color="#f53d00"
            text="125,584 - 461,000"
          />,
        ]}
      />
    );
  }
}

export default TrafficCountLegend;
