import produce from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import { GraphDataSource, GraphDataSources } from "types/Graph";
import actionTypes from "./actionTypes";

export type GraphsState = {
  graphDataSources: GraphDataSources;
  highlightedGraphDataSource?: GraphDataSource;
}

const INITIAL_STATE: GraphsState = {
  graphDataSources: {},
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: GraphsState = INITIAL_STATE, action): GraphsState => {
  switch (action.type) {
    case actionTypes.UPDATE_GRAPH_DATA_SOURCES: return updateGraphDataSources(previousState, action);
    case actionTypes.SET_HIGHLIGHTED_GRAPH_DATA_SOURCE: return setHighlightedGraphDataSource(previousState, action);
    case actionTypes.CLEAR_HIGHLIGHTED_GRAPH_DATA_SOURCE: return clearHighlightedGraphDataSource(previousState, action);
    default: return previousState;
  }
}

/**
 * See `updateGraphDataSources` action creator.
 *
 * Return state with graph data updated.
 */
const updateGraphDataSources = (
  previousState: GraphsState,
  action: PayloadAction<GraphDataSources>
): GraphsState => {
  const graphDataSources = action.payload;

  const previousModifiedState = produce(previousState, (draftState) => {
    Object.keys(previousState.graphDataSources).forEach((geoId) => {
      if (!graphDataSources[geoId]) {
        delete draftState.graphDataSources[geoId];
      } else {
        draftState.graphDataSources[geoId].feature = graphDataSources[geoId].feature;
      }
    });

    delete draftState.highlightedGraphDataSource;
  });

  return {
    ...previousModifiedState,
    graphDataSources: {
      ...graphDataSources,
      ...previousModifiedState.graphDataSources,
    }
  };
}

/**
 * See `setHighlightedGraphDataSource` action creator.
 *
 * Return state with the highlighted graph data source updated.
 */
const setHighlightedGraphDataSource = (
  previousState: GraphsState,
  action: PayloadAction<GraphDataSource>
): GraphsState => ({
  ...previousState,
  highlightedGraphDataSource: action.payload,
})

/**
 * See `clearHighlightedGraphDataSource` action creator.
 *
 * Return state with no highlighted graph data source.
 */
const clearHighlightedGraphDataSource = (
  previousState: GraphsState,
  action: PayloadAction<undefined>
): GraphsState => ({
  ...previousState,
  highlightedGraphDataSource: undefined,
})

export default reducer;
