import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class OpportunityZoneLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.OpportunityZone}
        classes="opportunity-zone"
        legendRows={[
          <LegendRow
            color="#f9b924"
            text="Opportunity Zone"
          />
        ]}
      />
    );
  }
}

export default OpportunityZoneLegend;
