import React from "react";
import companyInfo from "utils/companyInfo";

export default class ZoningReportLink extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="application-link zoning-report">
        <div className="zoning-report-icon" />
        <h5>Zoning Report</h5>
        <p>Purchase a zoning report. An instant snapshot of what can be build on any property in 126 US cities.</p>
        <a href={companyInfo.ZONING_REPORT_URL} target="_blank" className="zoning-report-link">
          ZONING REPORT
        </a>
      </div>
    );
  }
}
