import React from "react";

export default class ExistingBuildingLink extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="application-link coming-soon">
        <div className="existing-building-icon" />
        <h5>Existing Building</h5>
        <p>Understand income producing properties and rehab potential, to determine investment viability.</p>
        <p className="bottom">COMING SOON</p>
      </div>
    );
  }
}
