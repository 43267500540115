import developments from "./developments";
import * as firebase from "firebase";
import { Development } from  "../../../../types/Development/Development";
import Firebase from "../../../../types/Service/Firebase";

type Hydration = (data: firebase.firestore.DocumentData) => void;
type Dehydration = (data: Development) => void;

const IDENTITY_FUNCTION = (data) => data;

/**
 * Return the correct dehydrator function depending on the given collection.
 */
const dehydratorFor = (collection: Firebase.Collection): Dehydration => {
  switch (collection) {
    case Firebase.Collection.Developments:  return developments.dehydrate;
    default:                                return IDENTITY_FUNCTION;
  }
}

/**
 * Return the correct hydrator function depending on the given collection.
 */
const hydratorFor = (collection: Firebase.Collection): Hydration => {
  switch (collection) {
    case Firebase.Collection.Developments:  return developments.hydrate;
    default:                                return IDENTITY_FUNCTION;
  }
}

/**
 * Apply the proper dehydration depending on the collection the document is in.
 */
let dehydrateData = (data: Development, collection: Firebase.Collection) => {
  dehydratorFor(collection)(data);
}

/**
* Apply the proper hydration depending on the collection the document is in.
*/
let hydrateData = (data: firebase.firestore.DocumentData, collection: Firebase.Collection) => {
  hydratorFor(collection)(data);
}

export default {
  dehydrateData,
  hydrateData
};
