import { colorFor } from "views/utils/colors";
import { MapStyleProperties } from "./mapStyleProperties";
import { RawDynamicLayersFieldId } from "./mapStyleProperties/mapStyleProperties";

export const paintDemographicDynamicLayerFill = (field: RawDynamicLayersFieldId, layerSet:MapStyleProperties.DynamicLayerId) => {
  let values:Array<number> = [0,0,0,0,0];

  switch (layerSet) {
    case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population:
      values = [1000,2000,3000,5000,12000];
      break;
    case MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent:
      values = [500,1000,1500,2000,2500];
      break;
    case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population:
      values = [10,30,50,70,90];
      break;
    case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income:
      values = [25000,50000,75000,100000,150000];
      break;
  }

  const LAYER_FILL_PAINT = {
    "fill-opacity": [
      "case",
      [
        "has",
        field
      ],
      [
        "match",
        [
          "get",
          field
        ],
        0,
        0,
        0.5
      ],
      0
    ],
    "fill-color": [
      "interpolate",
      ["linear"],
      [
        "get",
        field
      ],
      values[0],
      "#fd0d0d",
      values[1],
      "#fff305",
      values[2],
      "#0fe600",
      values[3],
      "#16e7fe",
      values[4],
      "#024be8",
    ]
  }
  return LAYER_FILL_PAINT;
}


export const paintSeaLevelRiseDynamicLayerFill = (field: RawDynamicLayersFieldId) => {

  const LAYER_FILL_PAINT = {
    "fill-opacity": [
      "case",
      [
        "has",
        field
      ],
      [
        "match",
        [
          "get",
          field
        ],
        0,
        0,
        0.5
      ],
      0
    ],
    "fill-color": colorFor("sea-level-rise-layer"),
  }
  return LAYER_FILL_PAINT;
}


export const seaLevelRiseLinePaint = (fieldId) => {
  const LAYER_FILL_PAINT = {
  "line-opacity": [
    "case",
    [
      "has",
      fieldId,
    ],
    [
      "match",
      [
        "get",
        fieldId,
      ],
      0,
      0,
      1
    ],
    0
  ],
  "line-color": colorFor("sea-level-rise-layer"),
  "line-width": [
    "interpolate",
    ["exponential", 0.71],
    ["zoom"],
    11,
    0.5,
    20,
    0.5
  ],
}
return LAYER_FILL_PAINT;
}
