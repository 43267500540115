import { combineReducers } from "redux";
import sharedReducers from "./shared/reducers";
import newDevelopmentReducers from "./newDevelopment";
import explorerReducers from "./explorer";
import subscriptionManagementReducers from "./subscriptionManagement";
import { SharedUiState } from "./shared/reducers";
import { NewDevelopmentUiState } from "./newDevelopment/reducers";
import { ExplorerUiState } from "./explorer/reducers";
import { SubscriptionManagementUiState } from "./subscriptionManagement/reducers";

export interface UiState {
  shared: SharedUiState;
  newDevelopment: NewDevelopmentUiState;
  explorer: ExplorerUiState;
  subscriptionManagement: SubscriptionManagementUiState;
}

const reducer = combineReducers<UiState>({
  shared: sharedReducers,
  newDevelopment: newDevelopmentReducers,
  explorer: explorerReducers,
  subscriptionManagement: subscriptionManagementReducers,
});

export default reducer;
