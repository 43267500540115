import * as tf from "@tensorflow/tfjs";
import * as firebase from "firebase/app";
import { BuildingUse } from "../../../../../types/BuildingUse";
import { Development } from "../../../../../types/Development/Development";
import Unit from "../../../../../types/Unit";
import unitConversions from "../../../../unitConversions";
import featureHelper, { NormalizationConfig } from "./featureHelper";

const STORAGE_PATH_FOLDER = "rent_model";

let model: tf.GraphModel;
let rentNormalizationConfig: NormalizationConfig | undefined;

export type RentModelFeatures = [
  number, // MEDIAN_HOUSEHOLD_INCOME
  number, // POPULATION
  number, // MEDIAN_GROSS_RENT_TOTAL
  number, // EMPLOYMENT_PER_POP_RATIO
  number, // Hotel
  number, // Industrial
  number, // Office
  number, // Residential
  number, // Retail
  number, // Market 1
  number, // Market 2
  number // Market 3
];

interface RentModelFeatureData {
  medianIncome: number;
  population: number;
  medianGrossRent: number;
  employmentPerPopulation: number;
  market: number;
  buildingUse: BuildingUse;
}

/**
 * Initialize rent model.
 */
const initialize = async () => {
  if (!model) {
    const modelReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/model.json`);

    const modelUrl = await modelReference.getDownloadURL();
    model = await tf.loadGraphModel(modelUrl, {
      weightUrlConverter: async (fileName) => {
        const binaryReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/${fileName}`);
        return binaryReference.getDownloadURL();
      },
    });

    const normalizationParamsReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/normalizationParams.json`);
    const normParamsUrl = await normalizationParamsReference.getDownloadURL();
    let data: any = await fetch(normParamsUrl);
    data = await data.json();
    rentNormalizationConfig = { ...(data as NormalizationConfig) };
  }
};

/**
 * Build feature array for the rent model.
 */
const buildFeature = (data: RentModelFeatureData) => {
  if (!rentNormalizationConfig) throw new Error("Rent normalization configuration was not initialized");
  if ([data.medianIncome, data.medianGrossRent, data.population, data.employmentPerPopulation].some((value) => !value)) {
    return;
  }

  let normalizedFeatures: RentModelFeatures = [
    featureHelper.normalize(data.medianIncome, rentNormalizationConfig.medianIncome),
    featureHelper.normalize(data.population, rentNormalizationConfig.population),
    featureHelper.normalize(data.medianGrossRent, rentNormalizationConfig.medianGrossRent),
    featureHelper.normalize(data.employmentPerPopulation, rentNormalizationConfig.employmentPerPopulation),
    data.buildingUse === BuildingUse.Hotel ? 1 : 0, // Hotel
    data.buildingUse === BuildingUse.Industrial ? 1 : 0, // Industrial
    data.buildingUse === BuildingUse.Office ? 1 : 0, // Office
    data.buildingUse === BuildingUse.Multifamily ? 1 : 0, // Residential
    data.buildingUse === BuildingUse.Retail ? 1 : 0, // Retail,
    data.market === 1 ? 1 : 0, // Market 1
    data.market === 2 ? 1 : 0, // Market 2
    data.market === 3 ? 1 : 0, // Market 3
  ];

  return normalizedFeatures;
};

/**
 * Build feature array from the development object and building Use.
 */
const buildFeatureFromDevelopment = (development: Development, buildingUse: BuildingUse) => {
  let { medianIncome, population, medianGrossRent, employmentPerPopulation, market } = featureHelper.getBaseFeaturesFromDevelopment(development);

  const feature = buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse });

  if (!feature) {
    throw new Error(
      `Value for one or all parameters for model are possibly missing: {[medianIncomeTotal, grossMedianRent, populationDensity, employmentPerPopulation, market]} : ${[
        medianIncome,
        medianGrossRent,
        population,
        employmentPerPopulation,
        market,
      ]}`
    );
  }

  return feature;
};

/**
 * Get prediction for the passed in data.
 */
const predict = async (data: Array<RentModelFeatures>): Promise<tf.TypedArray> => {
  if (!model) throw new Error("Rent model is not instantiated");

  const prediction = await (model.predict(tf.tensor2d(data), {}) as tf.Tensor).data();
  return prediction;
};

/**
 * Generate presets for rent.
 */
const generatePreset = async (development: Development, buildingUse: BuildingUse, area = 1, unitDivisor = 1) => {
  const features = buildFeatureFromDevelopment(development, buildingUse);
  const predictionResult = await predict([features]);
  const pricePerSquareFeet = predictionResult[0];
  const pricePerSquareMeter = unitConversions.convert(pricePerSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters, true);

  const price = (pricePerSquareMeter * area) / unitDivisor;

  return {
    minimum: Math.round(price / 1.5),
    value: price,
    maximum: Math.round(price * 1.5),
  };
};

export default {
  initialize,
  generatePreset,
  buildFeature,
  predict,
};
