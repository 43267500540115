const RESET_FLAGS = "ui/shared/maps/RESET_FLAGS";
const TOGGLE_LAYERS = "ui/shared/maps/TOGGLE_LAYERS";
const TOGGLE_DYNAMIC_LAYERS = "ui/shared/maps/TOGGLE_DYNAMIC_LAYERS";
const RESET_DYNAMIC_LAYERS = "ui/shared/maps/RESET_DYNAMIC_LAYERS";
const ACTIVATE_DYNAMIC_LAYERS = "ui/shared/maps/ACTIVATE_DYNAMIC_LAYERS";
const INITIALIZE_LAYERS = "ui/shared/maps/INITIALIZE_LAYERS";
const INITIALIZE_LAYERS_ON_STYLE_CHANGE = "ui/shared/maps/INITIALIZE_LAYERS_ON_STYLE_CHANGE";
const TOGGLE_MAP_STYLE = "ui/shared/maps/TOGGLE_MAP_STYLE";
const TOGGLE_DEVELOPER_MAP_STYLE = "ui/shared/maps/TOGGLE_DEVELOPER_MAP_STYLE";
const SET_AVAILABLE_LAYERS = "ui/shared/maps/SET_AVAILABLE_LAYERS";
const SET_AVAILABLE_DYNAMIC_LAYERS = "ui/shared/maps/SET_AVAILABLE_DYNAMIC_LAYERS";
const SET_SELECTED_DYNAMIC_LAYER = "ui/shared/maps/SET_SELECTED_DYNAMIC_LAYER";

export default {
  RESET_FLAGS,
  TOGGLE_LAYERS,
  TOGGLE_DYNAMIC_LAYERS,
  ACTIVATE_DYNAMIC_LAYERS,
  RESET_DYNAMIC_LAYERS,
  INITIALIZE_LAYERS,
  TOGGLE_MAP_STYLE,
  TOGGLE_DEVELOPER_MAP_STYLE,
  SET_AVAILABLE_LAYERS,
  INITIALIZE_LAYERS_ON_STYLE_CHANGE,
  SET_AVAILABLE_DYNAMIC_LAYERS,
  SET_SELECTED_DYNAMIC_LAYER,
};
