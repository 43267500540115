import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { connect } from "react-redux";
import Pdf from "types/Pdf";
import { ReactMapboxGlCamera } from "types/ReactMapboxGlCamera";
import { developmentSelectors } from "state/development";
import mapboxPresentationProperties from "views/utils/mapboxPresentationProperties";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import Map from "../sharedComponents/Map";

const DEFAULT_ZOOM = 11;

const mapStateToProps = (state) => ({
  parcelCenterOfMass: developmentSelectors.getParcelCenterOfMass(state),
});

type Props = ReturnType<typeof mapStateToProps>;

class MapLocation extends React.PureComponent<Props, {}> {
  camera: ReactMapboxGlCamera;

  constructor(props) {
    super(props);

    this.camera = {
      zoom: [DEFAULT_ZOOM],
      pitch: [0],
      bearing: [0],
      center: this.props.parcelCenterOfMass as [number, number],
    };
  }

  render() {
    return (
      <Map pdfImageType={Pdf.ImageType.Location} camera={this.camera} mapStyleUrl={MapStyleProperties.StyleUrl.Streets}>
        <Layer type="symbol" layout={mapboxPresentationProperties.mapPinLayout}>
          <Feature coordinates={this.props.parcelCenterOfMass} />
        </Layer>
      </Map>
    );
  }
}

export default connect(mapStateToProps)(MapLocation);
