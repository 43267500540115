import React from "react";
import { connect } from "react-redux";
import { authenticationSelectors } from "../../../../state/authentication";
import { developmentActions, developmentSelectors } from "../../../../state/development";
import { authenticationUiActions } from "../../../../state/ui/shared/authentication";
import { userSelectors } from "../../../../state/user";

const mapStateToProps = (state) => {
  return {
    isFromShared: developmentSelectors.getIsFromShared(state),
    developmentName: developmentSelectors.getName(state),
    userDocument: userSelectors.getUserDocument(state),
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
  };
};

const mapDispatchToProps = {
  keepProject: developmentActions.keepProject,
  saveDevelopment: developmentActions.saveStart,
  toggleLoginIsOpen: authenticationUiActions.toggleLoginIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  show: boolean;
}

class KeepProject extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: this.props.isFromShared || !Boolean(props.userIsAuthenticated),
    };
  }

  /**
   * Remove component.
   */
  close = () => {
    this.setState({ show: false });
  };

  /**
   * Mark this project as non from shared project.
   */
  keepProject = () => {
    if (this.props.userIsAuthenticated) {
      this.props.keepProject();
      this.props.saveDevelopment();
      this.setState({ show: false });
    } else {
      this.props.toggleLoginIsOpen();
    }
  };

  render() {
    if (!this.state.show) return null;

    const { userIsAuthenticated } = this.props;

    return (
      <div className="component--keep-project">
        <div className="text">
          {userIsAuthenticated ? "Shared with me" : "Project Not Saved"} <span className="close" onClick={this.close} />
        </div>
        <div className="button-container">
          <button onClick={this.keepProject}>Keep Project</button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeepProject);
