import React from "react";
import PdfContext from "../../../PdfContext";
import Pdf from "../../../../../../../types/Pdf";
import { View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "600px",
  },
  image: {
    objectFit: "scale-down",
    width: "auto",
    maxWidth: "100%",
    height: "auto",
  },
});

const CoverImage = () => (
  <PdfContext.Consumer>
    {({ mapImages, coverImages }) => {
      const imageUrl =
        (coverImages[Pdf.ImageType.Cover] && coverImages[Pdf.ImageType.Cover].url) || mapImages[Pdf.ImageType.Cover];

      return (
        <View style={styles.container}>
          <Image style={styles.image} src={imageUrl} />
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default CoverImage;
