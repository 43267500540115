import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../state/development";
import { buildingUsageIsEnabled } from "../../utils/uiToggleHelper";
import { BuildingUse } from "../../../types/BuildingUse";

interface Props {
  classes: string;
  usageGroup: BuildingUse;
  toggles: object;
}

class CellRow extends PureComponent<Props, {}> {
  render() {
    if (this.props.usageGroup && !buildingUsageIsEnabled(this.props.toggles, this.props.usageGroup)) { return null; }
    return (
      <tr className={this.props.classes} >
        {this.props.children}
      </tr>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return (
    {
      toggles: ownProps.usageGroup ? developmentSelectors.getBuildingUsageToggles(state) : {}
    }
  )
};

export default connect(
  mapStateToProps
)(CellRow);
