import React from "react";

interface OwnProps {
  color?: string;
  background?: string;
  text: string;
  circle?: boolean;
  wide?: boolean;
}

type Props = OwnProps;

class LegendRow extends React.PureComponent<Props, {}> {
  render() {

    const { color, text, circle, background, wide } = this.props;
    return (
      <div className="component--legend-row">
        <div
          className={`color ${circle ? "circle" : ""} ${wide ? "wide" : ""}`}
          style={
            color
              ? { backgroundColor: color }
              : background
                ? { background: background }
                : undefined
          }
        />
        {text}
      </div>
    );
  }
}

export default LegendRow;
