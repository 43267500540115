import { useEffect } from "react";
import { BuildingModel } from "types/Development/BuildingModel";
import { Values } from "types/Development/Values";
import { Props } from "../ExplorerPage";

export const useExplorerBuildingAlert = (
  getBuildingIsReduced: (buildingModel: BuildingModel, values: Values) => boolean,
  prevProps: React.MutableRefObject<Props | undefined>,
  props: Props,
  setAlertIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (!prevProps.current) return;
    const buildingWasReduced = getBuildingIsReduced(prevProps.current.buildingModel, prevProps.current.values);
    const buildingIsReduced = getBuildingIsReduced(props.buildingModel, props.values);
    if (!buildingWasReduced && buildingIsReduced) setAlertIsOpen(true);
  });
};
