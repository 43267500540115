// import React from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../types/Path";
import { useEffect } from "react";

interface EmailAuthenticationQueryParameters {
  mode: string | null;
  actionCode: string | null;
}

/**
 * Parse the url query into an object of parameters names and values.
 */
const parseUrlQuery = () => {
  let queryString = window.location.search.substring(1);
  let searchParamaters = new URLSearchParams(queryString);
  let queryParameters: EmailAuthenticationQueryParameters = {
    mode: searchParamaters.get("mode"),
    actionCode: searchParamaters.get("oobCode"),
  };
  return queryParameters;
};

const AuthenticationLinkHandler: React.FC = () => {
  const navigate = useNavigate();
  let query = parseUrlQuery();

  useEffect(() => {
    switch (query.mode) {
      case "resetPassword":
        navigate(Path.ResetPassword, {
          state: { actionCode: query.actionCode },
        });
        break;
      case "verifyEmail":
        navigate(Path.VerifyEmail, {
          state: { actionCode: query.actionCode },
        });
        break;
      default:
        break;
    }
  }, [navigate, query]);

  return <></>;
};

export default AuthenticationLinkHandler;
