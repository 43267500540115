import React from "react";
import PdfContext from "../../../PdfContext";
import Pdf from "../../../../../../../types/Pdf";
import logo from "../../../../../../../assets/images/branding-watermark.png";
import { View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "151px",
    height: "60px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  image: {
    objectFit: "scale-down",
    width: "auto",
    maxWidth: "100%",
    height: "auto",
  },
});

const LogoImage = () => (
  <PdfContext.Consumer>
    {({ coverImages }) => {
      let imageUrl = coverImages[Pdf.ImageType.Logo] && coverImages[Pdf.ImageType.Logo].url;

      return (
        <View style={styles.container}>
          <Image style={styles.image} src={imageUrl || logo} />
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default LogoImage;
