enum DynamicLayerGroup {
  Population2010 = "population2010",
  Population2011 = "population2011",
  Population2012 = "population2012",
  Population2013 = "population2013",
  Population2014 = "population2014",
  Population2015 = "population2015",
  Population2016 = "population2016",
  Population2017 = "population2017",
  Population2018 = "population2018",
  Population2019 = "population2019",
  Population2020 = "population2020",
  Population2021 = "population2021",
  Population2022 = "population2022",

  MedianRent2013 = "medianRent2013",
  MedianRent2014 = "medianRent2014",
  MedianRent2015 = "medianRent2015",
  MedianRent2016 = "medianRent2016",
  MedianRent2017 = "medianRent2017",
  MedianRent2018 = "medianRent2018",
  MedianRent2019 = "medianRent2019",
  MedianRent2020 = "medianRent2020",
  MedianRent2021 = "medianRent2021",
  MedianRent2022 = "medianRent2022",

  EmploymentPerPopRatio2013 = "employmentPerPopRatio2013",
  EmploymentPerPopRatio2014 = "employmentPerPopRatio2014",
  EmploymentPerPopRatio2015 = "employmentPerPopRatio2015",
  EmploymentPerPopRatio2016 = "employmentPerPopRatio2016",
  EmploymentPerPopRatio2017 = "employmentPerPopRatio2017",
  EmploymentPerPopRatio2018 = "employmentPerPopRatio2018",
  EmploymentPerPopRatio2019 = "employmentPerPopRatio2019",
  EmploymentPerPopRatio2020 = "employmentPerPopRatio2020",
  EmploymentPerPopRatio2021 = "employmentPerPopRatio2021",
  EmploymentPerPopRatio2022 = "employmentPerPopRatio2022",

  MedianIncome2013 = "medianIncome2013",
  MedianIncome2014 = "medianIncome2014",
  MedianIncome2015 = "medianIncome2015",
  MedianIncome2016 = "medianIncome2016",
  MedianIncome2017 = "medianIncome2017",
  MedianIncome2018 = "medianIncome2018",
  MedianIncome2019 = "medianIncome2019",
  MedianIncome2020 = "medianIncome2020",
  MedianIncome2021 = "medianIncome2021",
  MedianIncome2022 = "medianIncome2022",
}

export default DynamicLayerGroup;
