import * as firebase from "firebase/app";
import "firebase/storage";

/**
 * Upload a project thumbnail to Firebase Storage.
 */
const upload = async (thumbnail: Blob, developmentId: string): Promise<void> => {
  let thumbnailReference = firebase.storage().ref(pathToThumbnail(developmentId));

  try {
    await thumbnailReference.put(thumbnail);
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Delete a project thumbnail from Firebase Storage.
 */
const remove = async (developmentId: string): Promise<void> => {
  let thumbnailReference = firebase.storage().ref(pathToThumbnail(developmentId));

  try {
    await thumbnailReference.delete();
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Construct the path to the thumbnail of the given development in the Firebase Storage.
 */
const pathToThumbnail = (developmentId: string): string => {
  return `developments/thumbnails/${developmentId}`;
}

/**
 * Download a project thumbnail from Firebase Storage.
 */
const download = async (developmentId: string): Promise<string> => {
  let thumbnailReference = firebase.storage().ref(pathToThumbnail(developmentId));
  let thumbnailUrl = await thumbnailReference.getDownloadURL();
  let response = await fetch(thumbnailUrl);

  if (!response.ok) throw new Error(`A thumbnail with ID "${developmentId}" could not be retrieved. The request return with status ${response.status}.`);

  let blob = await response.blob();
  let objectUrl = URL.createObjectURL(blob);
  return objectUrl;
}

export default {
  upload,
  remove,
  download,
}
