import React from "react";
import TiersPanel from "../../../sharedComponents/TiersPanel";

class SubscriptionRequiredContent extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--subscription-required-content">
        <TiersPanel />
      </div>
    );
  }
}

export default SubscriptionRequiredContent;
