import * as firebase from "firebase/app";
import "firebase/firestore";
import { jsonDeepCopy } from "../deepCopy";
import databaseHelper from "./utils/databaseHelper";
import pdf from "./pdf";
import taskQueue from "../AsynchronousTaskQueue";
import DocumentNotFoundError from "../../errors/DocumentNotFoundError";
import Firebase from "../../types/Service/Firebase";
import sharedProjects from "./sharedProjects";
import { UserDocument } from "../../types/UserDocument";
import { Development } from "../../types/Development/Development";

/**
 * Create a development project.
 */
const create = async (development: Development, userAttributes, pdfDocument) => {
  const task = async () => {
    const projectsCollectionReference = firebase.firestore().collection("projects");
    const newProjectDocumentReference = projectsCollectionReference.doc();
    let newProjectId: string | null = newProjectDocumentReference.id;
    const temporaryProjectData = jsonDeepCopy(development);

    const createProject: Firebase.Operation = {
      type: Firebase.OperationType.Set,
      documentReference: newProjectDocumentReference,
      data: temporaryProjectData
    }

    const createPdf = pdf.createOperation(newProjectId, pdfDocument);

    let updateUser: Firebase.Operation | null = null;
    let createDemoProject: Firebase.Operation | null = null;
    let userData: UserDocument | undefined;
    if (userAttributes) {
      const userReference = firebase.firestore().doc(`users/${userAttributes.id}`);

      userData = jsonDeepCopy(userAttributes.data) as UserDocument;
      userData.currentProjectId = newProjectId;
      userData.projects[newProjectId] = {
        name: temporaryProjectData.name,
        leaseUsesReturnOnCost: temporaryProjectData.values.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
        saleReturnOnCost: temporaryProjectData.values.saleReturnOnInvestmentForBackOfEnvelope,
        isForSale: temporaryProjectData.isForSale,
        timeModified: firebase.firestore.Timestamp.now().toMillis(),
        isFromShared: temporaryProjectData.isFromShared || null,
      }

      updateUser = {
        type: Firebase.OperationType.Update,
        documentReference: userReference,
        data: userData
      }
    } else {
      const demoProjectReference = firebase.firestore().doc(`demoProjects/${newProjectId}`);

      createDemoProject = {
        type: Firebase.OperationType.Set,
        documentReference: demoProjectReference,
        data: {}
      }
    }

    try {
      if (updateUser) {
        await databaseHelper.batchWrite([updateUser, createProject, createPdf]);
      } else if (createDemoProject) {
        await databaseHelper.batchWrite([createDemoProject, createProject, createPdf]);
      }
    } catch (error) {
      console.warn(error);
      newProjectId = null;
    }

    return {
      newProjectId,
      userData,
    };
  }

  const result = await taskQueue.push(task);
  return result;
}

/**
 * Get a development project, making a copy of it when needed.
 */
const read = async (projectId, userAttributes) => {
  try {
    let projectReference = firebase.firestore().doc(`projects/${projectId}`);
    const projectData = await databaseHelper.getDocument(projectReference);
    let userData: UserDocument = userAttributes ? userAttributes.data : null;

    let projectIsBeingShared;
    try {
      projectIsBeingShared = Boolean(await sharedProjects.read(projectId));
    } catch (error) {
      if (error instanceof DocumentNotFoundError) {
        projectIsBeingShared = false;
      } else {
        throw error;
      }
    }

    let projectIsDemo;
    try {
      const demoProjectReference = firebase.firestore().doc(`demoProjects/${projectId}`);
      projectIsDemo = Boolean(await databaseHelper.getDocument(demoProjectReference));
    } catch (error) {
      if (error instanceof DocumentNotFoundError) {
        projectIsDemo = false;
      } else {
        throw error;
      }
    }

    let copyProject = false;
    if (!userAttributes && projectIsBeingShared && !projectIsDemo) copyProject = true;

    if (userAttributes) {
      const userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
      const userDocument = await databaseHelper.getDocument(userReference);
      const userProjectsList = Object.keys(userDocument.projects);
      if (!userProjectsList.includes(projectId) && (projectIsBeingShared || projectIsDemo)) {
        copyProject = true;
      };
    }

    if (copyProject) {
      if (projectIsBeingShared) projectData.isFromShared = true;
      const result = await create(projectData, userAttributes, {});
      if (result) {
        projectId = result.newProjectId;
        userData = result.userData;
        projectReference = firebase.firestore().doc(`projects/${projectId}`);
      }
    }

    return {
      userData,
      projectData,
      newProjectId: projectId,
    };
  } catch (error) {
    console.warn(error);
    return null;
  }
}

/**
 * Update a development project.
 */
const update = async (userAttributes, projectId, newProjectData, pdfData) => {
  const task = async () => {
    if(!projectId) throw new Error(`Project ID is not valid. projectId:${projectId}`)

    let updateUser: Firebase.Operation | null = null;
    let userData: UserDocument | undefined;
    if (userAttributes) {
      let userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
      userData = jsonDeepCopy(userAttributes.data) as UserDocument;
      userData.projects[projectId] = {
        name: newProjectData.name,
        leaseUsesReturnOnCost: newProjectData.values.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
        saleReturnOnCost: newProjectData.values.saleReturnOnInvestmentForBackOfEnvelope,
        isForSale: newProjectData.isForSale,
        timeModified: firebase.firestore.Timestamp.now().toMillis(),
        isFromShared: newProjectData.isFromShared || null,
      }

      updateUser = {
        type: Firebase.OperationType.Update,
        documentReference: userReference,
        data: userData,
      }
    }

    let projectReference = firebase.firestore().doc(`projects/${projectId}`);
    let updateProject: Firebase.Operation | null = null;
    if (projectId) {
      updateProject = {
        type: Firebase.OperationType.Update,
        documentReference: projectReference,
        data: newProjectData,
      }
    }

    let updatePdf: Firebase.Operation = pdf.updateOperation(projectId, pdfData);

    try {
      if (updateProject && updateUser) {
        await databaseHelper.batchWrite([updateProject, updatePdf, updateUser]);
      } else if (updateProject) {
        await databaseHelper.batchWrite([updateProject, updatePdf]);
      } else if (updateUser) {
        await databaseHelper.batchWrite([updateUser]);
      }

      return {
        userData,
      };
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  const result = await taskQueue.push(task);
  return result;
}

/**
 * Delete a development project.
 */
const remove = async (projectId, userAttributes) => {
  const task = async () => {
    const projectDocumentReference = firebase.firestore().doc(`projects/${projectId}`);
    let deleteProject: Firebase.Operation = {
      type: Firebase.OperationType.Delete,
      documentReference: projectDocumentReference,
    };

    let updateUser: Firebase.Operation | null = null;
    let userData: UserDocument | undefined;
    if (userAttributes) {
      let userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
      userData = jsonDeepCopy(userAttributes.data) as UserDocument;

      delete userData.projects[projectId];

      if (userData.currentProjectId === projectId) userData.currentProjectId = "";
      updateUser = {
        type: Firebase.OperationType.Update,
        documentReference: userReference,
        data: userData,
      }
    }

    let deletePdf = pdf.removeOperation(projectId);

    try {
      updateUser
          ? await databaseHelper.batchWrite([updateUser, deleteProject, deletePdf])
          : await databaseHelper.batchWrite([deleteProject, deletePdf]);

      return {
        userData,
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const result = await taskQueue.push(task);
  return result;
}

export default {
  create,
  read,
  update,
  remove,
}
