import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NewProjectPage from "./pages/NewProjectPage";
import ExplorerPage from "./pages/ExplorerPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import EmailVerificationCompletePage from "./pages/EmailVerificationCompletePage";
import SubscriptionRequiredPage from "./pages/SubscriptionRequiredPage";
import SubscriptionManagementPage from "./pages/SubscriptionManagementPage";
import DashboardPage from "./pages/DashboardPage";
import AuthenticationLinkHandler from "./sharedComponents/AuthenticationLinkHandler";
import database from "../utils/database";
import { Path } from "../types/Path";
import { UserStatus } from "../types/UserStatus";
import { BrowserSupportRequirement } from "../types/BrowserSupportRequirement";
import DemoOnboardingOverlay from "./sharedComponents/DemoOnboardingOverlay";
import "../app.css";
import RouteComponent from "./routes/RouteComponent/RouteComponent";

class App extends React.Component {
  constructor(props) {
    super(props);
    database.initialize();
  }

  render() {
    return (
      <div>
        <div id="pop-up-container" />
        <DemoOnboardingOverlay />
        <Routes>
          <Route
            path={Path.SignUp}
            element={<Navigate to={Path.Home} replace />}
          />
          <Route
            path={Path.AuthAction}
            element={
              <RouteComponent
                component={AuthenticationLinkHandler}
                allow={[UserStatus.NotAuthenticated]}
              />
            }
          />
          <Route
            path={Path.VerifyEmail}
            element={
              <RouteComponent
                component={EmailVerificationCompletePage}
                allow={[UserStatus.NotAuthenticated]}
                browserSupportRequirement={BrowserSupportRequirement.Partial}
              />
            }
          />
          <Route
            path={Path.ForgotPassword}
            element={
              <RouteComponent
                component={ForgotPasswordPage}
                allow={[UserStatus.NotAuthenticated]}
                browserSupportRequirement={BrowserSupportRequirement.Partial}
              />
            }
          />
          <Route
            path={Path.ResetPassword}
            element={
              <RouteComponent
                component={ResetPasswordPage}
                allow={[UserStatus.NotAuthenticated]}
                browserSupportRequirement={BrowserSupportRequirement.Partial}
              />
            }
          />
          <Route
            path={Path.Home}
            element={
              <RouteComponent
                component={HomePage}
                allow={[UserStatus.NotAuthenticated]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
          <Route
            path={Path.Dashboard}
            element={
              <RouteComponent
                component={DashboardPage}
                allow={[UserStatus.Subscribed, UserStatus.Verified]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
          <Route
            path={`${Path.Explorer}/:projectId?`}
            element={
              <RouteComponent
                component={ExplorerPage}
                allow={[
                  UserStatus.Subscribed,
                  UserStatus.Verified,
                  UserStatus.NotAuthenticated,
                ]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
          <Route
            path={`${Path.NewProject}/:camera?`}
            element={
              <RouteComponent
                component={NewProjectPage}
                allow={[
                  UserStatus.Subscribed,
                  UserStatus.Verified,
                  UserStatus.NotAuthenticated,
                ]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
          <Route
            path={Path.Subscribe}
            element={
              <RouteComponent
                component={SubscriptionRequiredPage}
                allow={[
                  UserStatus.Verified,
                  UserStatus.Subscribed,
                  UserStatus.NotAuthenticated,
                  UserStatus.Authenticated,
                ]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
          <Route
            path={Path.SubscriptionManagement}
            element={
              <RouteComponent
                component={SubscriptionManagementPage}
                allow={[UserStatus.Verified, UserStatus.Subscribed]}
                browserSupportRequirement={BrowserSupportRequirement.Full}
              />
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App;
