import { Config } from "../../../../../../types/Config";
import { VariableId } from "../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";

const config: Config = {
  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    text: "Parking After Reduction",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces"
    }
  },

  [VariableId.ParkingReductionFactor]: {
    variableId: VariableId.ParkingReductionFactor,
    buildingUse: BuildingUse.Project,
    text: "Parking Reduction",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.ParkingReductionFeePerSpace]: {
    variableId: VariableId.ParkingReductionFeePerSpace,
    buildingUse: BuildingUse.Project,
    text: "Parking Reduction Fee",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/space"
    }
  },

  [VariableId.ParkingSpaceArea]: {
    variableId: VariableId.ParkingSpaceArea,
    buildingUse: BuildingUse.Project,
    text: "Average Area Per Space",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.ParkingRequiredSpacesBeforeReduction]: {
    variableId: VariableId.ParkingRequiredSpacesBeforeReduction,
    text: "Parking Requirements",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces"
    }
  },

  [VariableId.ParkingRatioCondo]: {
    variableId: VariableId.ParkingRatioCondo,
    buildingUse: BuildingUse.Condo,
    text: "Condo (Spaces/Unit)",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    }
  },

  [VariableId.ParkingRatioMultifamily]: {
    variableId: VariableId.ParkingRatioMultifamily,
    buildingUse: BuildingUse.Multifamily,
    text: "Multifamily (Spaces/Unit)",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    }
  },

  [VariableId.ParkingRatioHotel]: {
    variableId: VariableId.ParkingRatioHotel,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel (Spaces/Key)",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    }
  },

  [VariableId.ParkingRatioOffice]: {
    variableId: VariableId.ParkingRatioOffice,
    buildingUse: BuildingUse.Office,
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      text: "Office (Spaces/1000 SF)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    },
    [Unit.System.Metric]: {
      text: "Office (Spaces/~92.9 m²)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    }
  },

  [VariableId.ParkingRatioRetail]: {
    variableId: VariableId.ParkingRatioRetail,
    buildingUse: BuildingUse.Retail,
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      text: "Retail (Spaces/1000 SF)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    },
    [Unit.System.Metric]: {
      text: "Retail (Spaces/~92.9 m²)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    }
  },

  [VariableId.ParkingRatioIndustrial]: {
    variableId: VariableId.ParkingRatioIndustrial,
    buildingUse: BuildingUse.Industrial,
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      text: "Industrial (Spaces/1000 SF)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    },
    [Unit.System.Metric]: {
      text: "Industrial (Spaces/~92.9 m²)",
      // Use same unit type to keep same parking ratio across units.
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true
    }
  }
};

export default config;
