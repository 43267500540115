import React from "react";
import Dropdown from "../Dropdown";
import SignOutDropdownButton from "./SignOutDropdownButton";
import companyInfo from "../../../utils/companyInfo";
import { authenticationSelectors } from "../../../state/authentication";
import { connect } from "react-redux";
import RedirectDropdownButton from "../RedirectDropdownButton";
import { Path } from "../../../types/Path";

const mapStateToProps = (state) => {
  return {
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
  };
};

interface State {
  settingsMenuIsActive: boolean;
}

interface OwnProps {
  location: Location;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class ToolbarSettings extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      settingsMenuIsActive: false,
    };
  }

  /**
   * Toggle the settingsMenuIsActive state.
   */
  toggleSettingsMenuState = () => {
    this.setState({ settingsMenuIsActive: !this.state.settingsMenuIsActive });
  };

  /**
   * Set the settingsMenuIsActive state to false.
   */
  hideSettingsMenu = () => {
    this.setState({ settingsMenuIsActive: false });
  };

  render() {
    const { userIsAuthenticated } = this.props;

    return (
      <div className="component--toolbar-settings">
        <Dropdown
          isActive={this.state.settingsMenuIsActive}
          onClick={this.toggleSettingsMenuState}
          onBlur={this.hideSettingsMenu}
          toggleButton={
            <div className="user-settings-menu">
              <div className="toggle-dropdown">
                <div className={`menu-button ${this.state.settingsMenuIsActive ? "active" : ""}`} />
              </div>
            </div>
          }
        >
          {this.props.children}

          {userIsAuthenticated && <RedirectDropdownButton path={Path.SubscriptionManagement} text="Subscription" />}
          {/* TODO: Using prevent default on mouse down is a hack to avoid a racing condition between the anchor tag href and the onBlur
          event on dropdown. This should be further investigated and fixed. */}
          <li key="tutorials" onMouseDown={(e) => e.preventDefault()}>
            <a href={companyInfo.TUTORIALS_URL} target="_blank" rel="noreferrer noopener" className="button">
              Product Tutorials
            </a>
          </li>
          <li key="blog" onMouseDown={(e) => e.preventDefault()}>
            <a href={companyInfo.BLOG_URL} target="_blank" rel="noreferrer noopener" className="button">
              Blog
            </a>
          </li>
          <SignOutDropdownButton />
        </Dropdown>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ToolbarSettings);
