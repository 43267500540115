import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import { setbackModeActions } from "../../../../state/setbackMode";

const mapStateToProps = (state) => {
  return {
    selectedSetbackFloor: developmentSelectors.getSelectedSetbackFloor(state),
  }
};

const mapDispatchToProps = {
  setSetbackModeIsActive: setbackModeActions.setSetbackMode,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class SetbackClosePanel extends React.PureComponent<Props, {}> {
  render() {
    const { selectedSetbackFloor, setSetbackModeIsActive } = this.props;

    return (
      <div className="component--setback-close-panel">
        <div className="floor-label">
          {selectedSetbackFloor === 0 ? "Ground Floor" : `Floor ${selectedSetbackFloor + 1}`}
        </div>
        <button onClick={() => setSetbackModeIsActive(false)}>Exit Setback Mode</button>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetbackClosePanel);
