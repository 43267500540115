import * as tf from "@tensorflow/tfjs";
import * as firebase from "firebase/app";
import { Development } from "../../../../../types/Development/Development";
import Unit from "../../../../../types/Unit";
import unitConversions from "../../../../unitConversions";
import featureHelper, { NormalizationConfig } from "./featureHelper";

const STORAGE_PATH_FOLDER = "sales_model";

let model: tf.GraphModel;
let rentNormalizationConfig: NormalizationConfig | undefined;

type SalesModelFeatures = [
  number, // MEDIAN_HOUSEHOLD_INCOME
  number, // POPULATION
  number, // MEDIAN_GROSS_RENT_TOTAL
  number, // EMPLOYMENT_PER_POP_RATIO
  number, // OneBedroom
  number, // TwoBedroom
  number, // ThreeBedroom
  number, // Studio | MicroUnit
  number, // Land
  number, // Building
  number, // Market 1
  number, // Market 2
  number // Market 3
];

export enum SalesUseType {
  Building = "building",
  Land = "land",
  MicroUnit = "microUnit",
  Studio = "studio",
  OneBedroom = "oneBedroom",
  TwoBedroom = "twoBedroom",
  ThreeBedroom = "threeBedroom",
}

/**
 * Initialize rent model.
 */
const initialize = async () => {
  if (!model) {
    const modelReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/model.json`);

    const modelUrl = await modelReference.getDownloadURL();
    model = await tf.loadGraphModel(modelUrl, {
      weightUrlConverter: async (fileName) => {
        const binaryReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/${fileName}`);
        return binaryReference.getDownloadURL();
      },
    });

    const normalizationParamsReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/normalizationParams.json`);
    const normParamsUrl = await normalizationParamsReference.getDownloadURL();
    let data: any = await fetch(normParamsUrl);
    data = await data.json();
    rentNormalizationConfig = { ...(data as NormalizationConfig) };
  }
};

/**
 * Build feature array from the development object and building Use.
 */
const buildFeatures = (development: Development, useType: SalesUseType) => {
  if (!rentNormalizationConfig) throw new Error("Sales normalization configuration was not initialized");

  let { medianIncome, population, medianGrossRent, employmentPerPopulation, market } = featureHelper.getBaseFeaturesFromDevelopment(development);

  let normalizedFeatures: SalesModelFeatures = [
    featureHelper.normalize(medianIncome, rentNormalizationConfig.medianIncome),
    featureHelper.normalize(population, rentNormalizationConfig.population),
    featureHelper.normalize(medianGrossRent, rentNormalizationConfig.medianGrossRent),
    featureHelper.normalize(employmentPerPopulation, rentNormalizationConfig.employmentPerPopulation),
    useType === SalesUseType.OneBedroom ? 1 : 0, // OneBedroom
    useType === SalesUseType.TwoBedroom ? 1 : 0, // TwoBedroom
    useType === SalesUseType.ThreeBedroom ? 1 : 0, // ThreeBedroom
    useType === SalesUseType.Studio || useType === SalesUseType.MicroUnit ? 1 : 0, // Studio | MicroUnit
    useType === SalesUseType.Land ? 1 : 0, // Building
    useType === SalesUseType.Building ? 1 : 0, // Building
    market === 1 ? 1 : 0, // Market 1
    market === 2 ? 1 : 0, // Market 2
    market === 3 ? 1 : 0, // Market 3
  ];

  return normalizedFeatures;
};

/**
 * Get prediction for the passed in data.
 */
const predict = async (data: SalesModelFeatures): Promise<number> => {
  if (!model) throw new Error("Sales model is not instantiated");

  const prediction = await (model.predict(tf.tensor2d([data]), {}) as tf.Tensor).data();
  return prediction[0];
};

/**
 * Generate presets for sale prices.
 */
const generatePreset = async (development: Development, hardCostUseType: SalesUseType, area = 1) => {
  const features = buildFeatures(development, hardCostUseType);
  const pricePerSquareFeet = await predict(features);
  const pricePerSquareMeter = unitConversions.convert(pricePerSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters, true);

  const price = pricePerSquareMeter * area;

  return {
    minimum: price / 1.2,
    value: price,
    maximum: price * 1.2,
  };
};

export default {
  initialize,
  generatePreset,
  buildFeatures,
  predict,
};
