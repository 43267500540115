import LayerGroup from "../../../types/LayerGroup";
import { LayerSector } from "../../../types/LayerSector";
import { LayerConfigurationsLayerIds } from "../../../utils/mapbox/layerHelper";
import { MapStyleProperties } from "../../../utils/mapbox/mapStyleProperties";

type LayerGroupConfiguration = {
  [key in LayerGroup]: {
    layers: Array<LayerConfigurationsLayerIds>;
    buttonText: string;
    legendTitle: string;
    layerDescription: string;
    layerSector: LayerSector;
  };
};

const config: LayerGroupConfiguration = {
  [LayerGroup.BuildableAreaRatio]: {
    layers: [MapStyleProperties.LayerId.BuildableAreaRatio],
    buttonText: "Buildable Area Ratio",
    legendTitle: "Buildable Area Ratio",
    layerDescription:
      "Parcel's potential buildable area ratio calculated using the maximum buildable SF divided by the lot area.",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.HistoricBuildings]: {
    layers: [MapStyleProperties.LayerId.HistoricBuildingsSymbol, MapStyleProperties.LayerId.HistoricBuildingsPoint],
    buttonText: "Historic Buildings",
    legendTitle: "Historic Buildings",
    layerDescription: "Historic buildings under 10 acres listed on the National Register of Historic Places.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.Buildings]: {
    layers: [MapStyleProperties.LayerId.UsaBuildings],
    buttonText: "Existing Building",
    legendTitle: "Existing Building",
    layerDescription: "Existing building models from open sourced data.",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.Businesses]: {
    layers: [MapStyleProperties.LayerId.BusinessesPoint, MapStyleProperties.LayerId.BusinessesSymbol],
    buttonText: "Local Businesses",
    legendTitle: "Local Businesses",
    layerDescription: "Companies providing goods or services to the local population.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.CapRates]: {
    layers: [MapStyleProperties.LayerId.CapRates],
    buttonText: "Cap Rates",
    legendTitle: "Cap Rates",
    layerDescription: "Cap rates according to local listings in the area.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.CensusTracts]: {
    layers: [MapStyleProperties.LayerId.CensusTractBorder, MapStyleProperties.LayerId.CensusTractSymbol],
    buttonText: "Census Tracts",
    legendTitle: "Census Tracts",
    layerDescription: "Census tract districts boundaries.",
    layerSector: LayerSector.Borders,
  },

  [LayerGroup.EducationFacilities]: {
    layers: [MapStyleProperties.LayerId.EducationFacilitiesPoint, MapStyleProperties.LayerId.EducationFacilitiesSymbol],
    buttonText: "Education",
    legendTitle: "Education Facilities",
    layerDescription: "Education facilities from Elementary to Universities.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.HotelAverageDailyRate]: {
    layers: [
      MapStyleProperties.LayerId.HotelAverageDailyRateFill,
      MapStyleProperties.LayerId.HotelAverageDailyRateBorder,
      MapStyleProperties.LayerId.HotelAverageDailyRateSymbol,
    ],
    buttonText: "Hotel ADR",
    legendTitle: "Hotel Average Daily Rate",
    layerDescription: "ADR by census tract, generated by our machine learning models.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.Hotels]: {
    layers: [MapStyleProperties.LayerId.HotelsPoint, MapStyleProperties.LayerId.HotelsSymbol],
    buttonText: "Hotels",
    legendTitle: "Hotels",
    layerDescription: "Hotels and Inns in 50 states of the United States.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.Hospitals]: {
    layers: [MapStyleProperties.LayerId.HospitalsSymbol, MapStyleProperties.LayerId.HospitalsPoint],
    buttonText: "Hospitals",
    legendTitle: "Hospitals",
    layerDescription: "Institutions providing medical treatment and nursing care.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.IndustrialRate]: {
    layers: [
      MapStyleProperties.LayerId.IndustrialRateFill,
      MapStyleProperties.LayerId.IndustrialRateBorder,
      MapStyleProperties.LayerId.IndustrialRateSymbol,
    ],
    buttonText: "Industrial Rates",
    legendTitle: "Industrial Lease Rates per SF per Year",
    layerDescription: "Average industrial rates by census tract, generated by our machine learning models.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.MultifamilyRent]: {
    layers: [
      MapStyleProperties.LayerId.MultifamilyRentFill,
      MapStyleProperties.LayerId.MultifamilyRentBorder,
      MapStyleProperties.LayerId.MultifamilyRentSymbol,
    ],
    buttonText: "Resi Rates",
    legendTitle: "Residential Rents per SF per Month",
    layerDescription: "Average residential rates by census tract, generated by our machine learning models.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.OfficeRate]: {
    layers: [
      MapStyleProperties.LayerId.OfficeRateFill,
      MapStyleProperties.LayerId.OfficeRateBorder,
      MapStyleProperties.LayerId.OfficeRateSymbol,
    ],
    buttonText: "Office Rates",
    legendTitle: "Office Lease Rate per SF per Year",
    layerDescription: "Average office rates by census tract, generated by our machine learning models.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.OpportunityZone]: {
    layers: [MapStyleProperties.LayerId.UsaOpportunityZoneFill],
    buttonText: "Opportunity Zone",
    legendTitle: "Opportunity Zone",
    layerDescription: "Census tract districts designated as Qualified Opportunity Zones.",
    layerSector: LayerSector.Incentives,
  },

  [LayerGroup.ReitsProjects]: {
    layers: [MapStyleProperties.LayerId.ReitsProjectsPoint, MapStyleProperties.LayerId.ReitsProjectsSymbol],
    buttonText: "REITs Projects",
    legendTitle: "REITs Projects",
    layerDescription: "Properties owned by Real Estate Investment Trusts.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.RetailRate]: {
    layers: [
      MapStyleProperties.LayerId.RetailRateFill,
      MapStyleProperties.LayerId.RetailRateBorder,
      MapStyleProperties.LayerId.RetailRateSymbol,
    ],
    buttonText: "Retail Rates",
    legendTitle: "Retail Lease Rate per SF per Year",
    layerDescription: "Average retail rates by census tract, generated by our machine learning models.",
    layerSector: LayerSector.Rates,
  },

  [LayerGroup.RoadType]: {
    layers: [MapStyleProperties.LayerId.RoadTypeBorder],
    buttonText: "Road Type",
    legendTitle: "Road Type",
    layerDescription: "Road classification from interstate to local types.",
    layerSector: LayerSector.Borders,
  },

  [LayerGroup.SmartSearchResults]: {
    layers: [MapStyleProperties.LayerId.SmartSearchResultsFill],
    buttonText: "Search Engine Results",
    legendTitle: "Search Engine Results",
    layerDescription: "These are the results for the smart search.",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.TrafficDailyAverage2019]: {
    layers: [MapStyleProperties.LayerId.TrafficDailyAverage2019Border],
    buttonText: "Traffic Count",
    legendTitle: "Traffic Count",
    layerDescription: "Annual average daily traffic for 2019.",
    layerSector: LayerSector.Borders,
  },

  [LayerGroup.Zoning]: {
    layers: [MapStyleProperties.LayerId.UsaZoningBorder, MapStyleProperties.LayerId.UsaZoningSymbol],
    buttonText: "Zoning Map",
    legendTitle: "Zoning Map",
    layerDescription: "Zoning district names and boundaries as per local zoning ordinance.",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.FederalOwnedLands]: {
    layers: [MapStyleProperties.LayerId.FederalOwnedLandsFill, MapStyleProperties.LayerId.FederalOwnedLandsSymbol],
    buttonText: "Federal Owned Land",
    legendTitle: "Federal Owned Land",
    layerDescription: "Federally owned land as provided by the U.S. Department of the Interior.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.NaturalAndMarineProtectedLands]: {
    layers: [
      MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsFill,
      MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsSymbol,
    ],
    buttonText: "Protected Areas",
    legendTitle: "Protected Areas",
    layerDescription: "Nature and marine protected areas as provided by the National Inventory of Protected Areas.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.Forests]: {
    layers: [MapStyleProperties.LayerId.ForestsFill, MapStyleProperties.LayerId.ForestsSymbol],
    buttonText: "Forests",
    legendTitle: "Forests",
    layerDescription: "Forest locations as provided by the USDA Forest Service.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.HazardousSites]: {
    layers: [
      MapStyleProperties.LayerId.HazardousSitesFill,
      MapStyleProperties.LayerId.HazardousSitesSymbol,
      MapStyleProperties.LayerId.HazardousSitesPoint,
    ],
    buttonText: "Hazardous Sites",
    legendTitle: "Hazardous Sites",
    layerDescription: "Hazardous sites as provided by the USDA Forest Service.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.Watersheds]: {
    layers: [MapStyleProperties.LayerId.WatershedsFill, MapStyleProperties.LayerId.WatershedsSymbol],
    buttonText: "Watersheds",
    legendTitle: "Watersheds",
    layerDescription: "Watershed locations as provided by the USDA Forest Service.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.HistoricSites]: {
    layers: [MapStyleProperties.LayerId.HistoricSitesSymbol, MapStyleProperties.LayerId.HistoricSitesPoint],
    buttonText: "Historic Sites",
    legendTitle: "Historic Sites",
    layerDescription:
      "Historic sites under 10 acres listed on the National Register of Historic Places. A site is the location of a significant event, a prehistoric or historic occupation or activity, or a building or structure, whether standing, ruined, or vanished, where the location itself possesses historic, cultural, or archeological value regardless of the value of any existing structure.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.Parks]: {
    layers: [MapStyleProperties.LayerId.ParksFill],
    buttonText: "Parks",
    legendTitle: "Parks",
    layerDescription: "National, State, County and local Parks and Forests.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.IndianReservations]: {
    layers: [MapStyleProperties.LayerId.IndianReservationsFill],
    buttonText: "Indian Reservations",
    legendTitle: "Indian Reservations",
    layerDescription: "Indian Reservations as provided by the U.S. Department of the Interior.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.BayAreaHousingSites]: {
    layers: [
      MapStyleProperties.LayerId.BayAreaHousingSitesFill,
      MapStyleProperties.LayerId.BayAreaHousingSitesSymbol,
      MapStyleProperties.LayerId.BayAreaHousingSitesBorder,
    ],
    buttonText: "Bay Area Housing Sites",
    legendTitle: "Potential Housing Units per Site",
    layerDescription:
      "Housing opportunity sites identified by local jurisdictions in their Housing Element as areas suitable to accommodate the development of all housing units assigned to them through the Regional Housing Need Assessment (RHNA).",
    layerSector: LayerSector.Incentives,
  },

  [LayerGroup.LIHTC_ExistingPropertyInventory]: {
    layers: [
      MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventoryPoint,
      MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventorySymbol,
    ],
    buttonText: "LIHTC Existing Inventory",
    legendTitle: "Number of Low-Income Units per Property",
    layerDescription: "Inventory of 49,447 existing low-income housing projects throughout the United States.",
    layerSector: LayerSector.Location,
  },

  [LayerGroup.DifficultDevelopmentAreas]: {
    layers: [
      MapStyleProperties.LayerId.DifficultDevelopmentAreasFill,
      MapStyleProperties.LayerId.DifficultDevelopmentAreasBorder,
    ],
    buttonText: "DDA",
    legendTitle: "DDA (Difficult Development Areas)",
    layerDescription:
      "DDA (Difficult Development Areas) for the Low Income Housing Credit Program. Designated by HUD (U.S. Department of Housing and Urban Development) and defined in stature as areas with high construction, land and utility costs relative to its Area Median Gross Income (AMGI).",
    layerSector: LayerSector.Incentives,
  },

  [LayerGroup.QualifiedCensusTracts]: {
    layers: [
      MapStyleProperties.LayerId.QualifiedCensusTractsFill,
      MapStyleProperties.LayerId.QualifiedCensusTractsBorder,
    ],
    buttonText: "QCT",
    legendTitle: "QCT (Qualified Census Tracts)",
    layerDescription:
      "QCT (Qualified Census Tracts) for the Low Income Housing Credit Program. Any census tract in which at least 50 percent of households have an income less than 60 percent of the Area Median Gross Income (AMGI) or a poverty rate of at least 25 percent.",
    layerSector: LayerSector.Incentives,
  },

  [LayerGroup.CityBoundaries]: {
    layers: [
      MapStyleProperties.LayerId.CityBoundariesFill,
      MapStyleProperties.LayerId.CityBoundariesSymbol,
      MapStyleProperties.LayerId.CityBoundariesBorder,
    ],
    buttonText: "City Boundaries",
    legendTitle: "City Boundaries",
    layerDescription:
      "Geographies defined by the U.S. Census Bureau, which include both Incorporated Places and Census Designated Places (CDPs).",
    layerSector: LayerSector.Borders,
  },

  [LayerGroup.CountyBoundaries]: {
    layers: [MapStyleProperties.LayerId.UsaCountiesFill, MapStyleProperties.LayerId.UsaCountiesBorder],
    buttonText: "Areas with Parcels",
    legendTitle: "Areas with Parcels",
    layerDescription: "Boundaries defining areas with parcels level data",
    layerSector: LayerSector.Borders,
  },

  [LayerGroup.TransitOrientedCommunity]: {
    layers: [MapStyleProperties.LayerId.TransitOrientedCommunityFill],
    buttonText: "TOC",
    legendTitle: "TOC (Transit Oriented Community)",
    layerDescription: "Transit Oriented Communities Incentive Program tiers ",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.Wetlands]: {
    layers: [MapStyleProperties.LayerId.WetlandsFill, MapStyleProperties.LayerId.WetlandsBorder],
    buttonText: "Wetlands",
    legendTitle: "Wetland Types",
    layerDescription: "Wetlands of all (50) states as provided by the U.S. Fish & Wildlife Service.",
    layerSector: LayerSector.Environmental,
  },

  [LayerGroup.IndustrialSites]: {
    layers: [MapStyleProperties.LayerId.IndustrialSitesFill, MapStyleProperties.LayerId.IndustrialSitesBorder],
    buttonText: "Underutilized Properties",
    legendTitle: "Underutilized Properties",
    layerDescription:
      "Properties in which the existing building is less than 50% of the potential building, in either buildable area SF or residential units allowed.",
    layerSector: LayerSector.Zoning,
  },

  [LayerGroup.MultifamilySites]: {
    layers: [MapStyleProperties.LayerId.MultifamilySitesFill, MapStyleProperties.LayerId.MultifamilySitesBorder],
    buttonText: "Vacant Land",
    legendTitle: "Vacant Land",
    layerDescription:
      "Properties that are identified as vacant through the land use allocation or based on existing building data.",
    layerSector: LayerSector.Zoning,
  },
};

export default config;
