import React from "react";
import authentication from "../../../../utils/authentication";
import wrapComponentWithPopup from "../../wrapComponentWithPopup";
import { PopupProps } from "../../wrapComponentWithPopup/wrapComponentWithPopup";
import { Path } from "../../../../types/Path";
import { connect } from "react-redux";
import { developmentActions } from "../../../../state/development";

interface OwnProps {
  developmentId: string;
  developmentName: string;
  classes?: string;
}

const mapDispatchToProps = {
  setProjectIsShared: developmentActions.setProjectIsShared,
};

type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & DispatchProps & PopupProps;

class ShareProjectPopup extends React.PureComponent<Props, {}> {
  /**
   * Get the user feedback text.
   */
  getText = () => {
    const userIsAuthenticated = authentication.getCurrentUser();
    return userIsAuthenticated
        ? "Anyone with the link will have a copy of the project."
        : "Anyone with the link can edit the project.";
  }

  /**
   * Handle the share project click.
   */
  handleShareProjectClick = async () => {
    this.copyStringToClipboard();
    this.props.setProjectIsShared(this.props.developmentId);
    this.props.closePopup();
  }

  /**
   * Copy the given string to the clipboard.
   */
  copyStringToClipboard = () => {
    var temporaryElement = document.createElement("textarea");
    temporaryElement.value = `${window.location.origin}${Path.Explorer}/${this.props.developmentId}`;

    document.body.appendChild(temporaryElement);
    temporaryElement.select();
    document.execCommand("copy");
    document.body.removeChild(temporaryElement);
  }

  render() {
    const url = `${window.location.origin}${Path.Explorer}/${this.props.developmentId}`

    return (
      <div className={`component--share-project-popup ${this.props.classes}`} onClick={(event) => event.stopPropagation()}>
        <div className="top">
          <div className="header">{`${this.props.developmentName} Project`}</div>
          <div className="top-separator" />
          <div className="text">{this.getText()}</div>
        </div>

        <div className="bottom">
          <div className="url-container">{url}</div>

          <button onClick={this.handleShareProjectClick}>
            Copy Project URL
          </button>
        </div>
      </div>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    null,
    mapDispatchToProps,
  )(ShareProjectPopup)
);
