import React from "react";
import ApplicationLinks from "./ApplicationLinks";
import DevelopmentsList from "./DevelopmentsList";
import Footer from "./Footer";
import GeocoderSearch from "../../../sharedComponents/GeocoderSearch";
import { userSelectors } from "../../../../state/user";
import { connect } from "react-redux";
import { subscriptionSelectors } from "state/subscription";

const mapStateToProps = (state) => {
  return {
    developmentsList: userSelectors.getDevelopments(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

class DashboardContent extends React.PureComponent<StateProps, {}> {
  /**
   * Render development list and application links.
   */
  renderDevelopmentList = () => {
    const { tier } = this.props;
    return (
      <>
        <ApplicationLinks tier={tier} />
        <DevelopmentsList developmentsList={this.props.developmentsList} />
      </>
    );
  };

  render() {
    return (
      <div className="component--dashboard-content">
        {this.props.developmentsList.length === 0 ? <GeocoderSearch /> : this.renderDevelopmentList()}
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(DashboardContent);
