import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class MultifamilyRentLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.MultifamilyRent}
        classes="multifamily-rent"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< $0.5"
          />,
          <LegendRow
            color="#fff305"
            text="$0.5 - $2.5"
          />,
          <LegendRow
            color="#0fe600"
            text="$2.5 - $3.5"
          />,
          <LegendRow
            color="#16e7fe"
            text="$3.5 - $4.5"
          />,
          <LegendRow
            color="#024be8"
            text="> $4.5"
          />,
        ]}
      />
    );
  }
}

export default MultifamilyRentLegend;
