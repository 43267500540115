import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class ReitsProjectsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.ReitsProjects}
        classes="reits-projects"
        legendRows={[
          <LegendRow
            color="#f2f45f"
            text="Mixed Use"
            circle
          />,
          <LegendRow
            color="#666666"
            text="Retail"
            circle
          />,
          <LegendRow
            color="#e552a0"
            text="Residential"
            circle
          />,
          <LegendRow
            color="#bf6835"
            text="Healthcare"
            circle
          />,
          <LegendRow
            color="#e31a1c"
            text="Hotel"
            circle
          />,
          <LegendRow
            color="#fdbf6f"
            text="Senior Living"
            circle
          />,
          <LegendRow
            color="#ff7f00"
            text="Industrial"
            circle
          />,
          <LegendRow
            color="#cab2d6"
            text="Storage"
            circle
          />,
          <LegendRow
            color="#6a3d9a"
            text="Office"
            circle
          />,
          <LegendRow
            color="#82dede"
            text="Multifamily"
            circle
          />,
          <LegendRow
            color="#fb9a99"
            text="Other"
            circle
          />,
        ]}
      />
    );
  }
}

export default ReitsProjectsLegend;
