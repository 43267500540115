import * as firebase from "firebase/app";

/**
 * Remove managed subscriptions array from user document.
 */
const removeManagedSubscriptions = (userDocument) => {
  userDocument.managedSubscriptions = firebase.firestore.FieldValue.delete();
}

export default removeManagedSubscriptions;
