import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../../../../state/newDevelopment";
import { Path } from "../../../../../../types/Path";
import MapboxGeocoder from "../../../../../sharedComponents/MapboxGeocoder";
import RedirectButton from "../../../../../sharedComponents/RedirectButton";
import wrapComponentWithPopup from "../../../../../sharedComponents/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    searchAddress: newDevelopmentSelectors.getSearchAddress(state),
  }
}

const mapDispatchToProps = {
  initializeNewDevelopment: newDevelopmentActions.initialize,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  errorMessage: string;
}

class NewDevelopmentPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: "",
    }

    props.initializeNewDevelopment();
  }

  /**
   * Clear the error message when a valid address gets selected.
   */
  componentDidUpdate(previousProps: Props) {
    if (!previousProps.pinPosition && this.props.pinPosition) {
      this.setState({ errorMessage: "" })
    }
  }

  /**
   * Check if user has selected item from the dropdown.
   */
  inputIsValid = () => {
    if (!Boolean(this.props.pinPosition) || !this.props.searchAddress) {
      this.setState({ errorMessage: "Please enter a valid address and select from the dropdown list." });
      return false;
    }

    return true;
  }

  render() {
    const { pinPosition } = this.props;
    const path = pinPosition ? `${Path.NewProject}/${pinPosition[0]},${pinPosition[1]},16.1` : "";

    return (
      <div className="component--new-development-popup">
        <div className="new-development-icon" />
        <p className="header">Find Property</p>
        <p>
          Enter a property address. On the next screen, you’ll see the data available for that property.
        </p>
        <div className={`geocoder-container ${this.state.errorMessage ? "error" : ""}`}>
          <MapboxGeocoder placeholder="Enter address to begin" />
          <RedirectButton
            text="START PROJECT"
            path={path}
            shouldContinue={this.inputIsValid}
          />
        </div>
        <div className="error-container">
          <p>{this.state.errorMessage}</p>
        </div>
      </div>
    )
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewDevelopmentPopup)
);
