import { Chargebee } from "../../types/Service/Chargebee";
import { Tier } from "../../types/Tier";
import authentication from "../../utils/authentication";

/**
 * Get the user tier based on its subscription.
 */
const getTier = (subscription?: Chargebee.Subscription | null) => {
  const user = authentication.getCurrentUser();

  if (!subscription) {
    return (user && user.emailVerified) ? Tier.Free : Tier.None;
  }

  if (![
        Chargebee.SubscriptionStatus.Active,
        Chargebee.SubscriptionStatus.NonRenewing,
        Chargebee.SubscriptionStatus.InTrial,
      ].includes(subscription.status)) {
    return (user && user.emailVerified) ? Tier.Free : Tier.None;
  }

  switch (subscription.plan_id) {
    case Chargebee.PlanId.DeveloperMonthly:
    case Chargebee.PlanId.DeveloperYearly:
      return Tier.Developer;
    case Chargebee.PlanId.AdvancedMonthly:
    case Chargebee.PlanId.AdvancedYearly:
    case Chargebee.PlanId.ProMonthly:
    case Chargebee.PlanId.ProYearly:
      return Tier.Pro;
  }

  return Tier.None;
}

/**
 * Get the tier based on the planId.
 */
const getPlanTier = (planId: Chargebee.PlanId | undefined): Tier => {
  const user = authentication.getCurrentUser();
  if (!planId) {
    return (user && user.emailVerified) ? Tier.Free : Tier.None;
  }

  switch (planId) {
    case Chargebee.PlanId.DeveloperMonthly:
    case Chargebee.PlanId.DeveloperYearly:
      return Tier.Developer;
    case Chargebee.PlanId.AdvancedMonthly:
    case Chargebee.PlanId.AdvancedYearly:
    case Chargebee.PlanId.ProMonthly:
    case Chargebee.PlanId.ProYearly:
      return Tier.Pro;
    default:
      return (user && user.emailVerified) ? Tier.Free : Tier.None;
  }
}

export default {
  getTier,
  getPlanTier,
}
