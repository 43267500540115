import React from "react";
import { LayerDisclaimerObject } from "types/LayerDisclaimer";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

const BAY_AREA_HOUSING__DISCLAIMER_TEXT = "Please note: Although jurisdictions have identified these sites as potential locations for the development of new housing, it does not mean that development is either imminent or will ever occur at these locations.";
const disclaimer: LayerDisclaimerObject = { disclaimerText: BAY_AREA_HOUSING__DISCLAIMER_TEXT };

class BayAreaHousingSitesLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.BayAreaHousingSites}
        classes="bay-area-sites"
        disclaimer={disclaimer}
        legendRows={[
          <LegendRow
            color="#bdbdbd"
            text="No Max Units Specified"
          />,
          <LegendRow
            color="#fff5eb"
            text="1 - 13"
          />,
          <LegendRow
            color="#fdd2a5"
            text="14 - 43"
          />,
          <LegendRow
            color="#fd9243"
            text="44 - 125"
          />,
          <LegendRow
            color="#df5005"
            text="126 - 500"
          />,
          <LegendRow
            color="#7f2704"
            text="501 - 4358"
          />,
        ]}
      />
    );
  }
}

export default BayAreaHousingSitesLegend;
