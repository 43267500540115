/**
 * @fileoverview The primary responsibility of this module is to enrich and sanitize the
 * parcel feature data with zoning features data and opportunity features data.
 */

import { ParcelProperty } from "./ParcelProperty";
import { MapStyleProperties } from "../mapbox/mapStyleProperties";
import { GeoJSON } from "geojson";
import sanitizer from "./sanitizer";
import turf from "../turf";
import unitConversions from "../../utils/unitConversions";
import Unit from "../../types/Unit";
import BuildingFeatureModel from "../../types/BuildingFeatureModel";

interface ParcelPopulatorFeatures {
  parcelFeature: GeoJSON;
  zoningFeature?: GeoJSON;
  opportunityZoneFeature?: GeoJSON;
  demographicsFeature?: GeoJSON;
  buildingFeature?: GeoJSON;
  existingBuildingData?: GeoJSON;
}

const PUBLISHED_COMPUTED_AREA_RATIO = 0.6; // 60%;
const EXISTING_AREA_MIN_THRESHOLD_IN_METERS_SQUARED = 1; // 10 SF in m2

const DEFAULT_DEMOGRAPHIC_VALUES = {
  [MapStyleProperties.RawDemographicsFieldId.EmploymentPerPopulationRatio]: 59.3,
  [MapStyleProperties.RawDemographicsFieldId.GrossMedianRent]: 1023,
  [MapStyleProperties.RawDemographicsFieldId.PopulationDensity]: 4000,
  [MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotal]: 60293,
};

/**
 * Enrich parcel feature properties with data from available features.
 */
const populateParcelProperties = (features: ParcelPopulatorFeatures): GeoJSON => {
  let enrichedParcelFeature = {
    ...features.parcelFeature,
    properties: {
      ...getPropertiesFromParcelFeature(features.parcelFeature),
      ...getPropertiesFromZoningFeature(features.zoningFeature),
      ...getPropertiesFromDemographicsFeature(features.demographicsFeature),
      ...getPropertiesFromBuildingFeature(features.buildingFeature, features.parcelFeature),
      ...getPropertiesFromExistingBuildingFeature(features.existingBuildingData),

      [ParcelProperty.IsInOpportunityZone]: Boolean(features.opportunityZoneFeature),
      [ParcelProperty.IsAnAssembly]: false,
    },
  };

  return enrichedParcelFeature;
};

/**
 * Get properties from raw parcel feature.
 */
const getPropertiesFromParcelFeature = (rawParcelFeature) => {
  if (!rawParcelFeature || !rawParcelFeature.properties) return {};
  const rawParcelFeatureProperties = rawParcelFeature.properties;

  const areaComputed = turf.area(rawParcelFeature as any);
  const areaPublishedInSquareFeet = sanitizer.forNumber(
    rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.AreaPublished]
  );
  let areaPublishedInSquareMeters = unitConversions.convert(
    areaPublishedInSquareFeet,
    Unit.Type.SquareFeet,
    Unit.Type.SquareMeters
  );
  areaPublishedInSquareMeters =
    areaPublishedInSquareMeters / areaComputed < PUBLISHED_COMPUTED_AREA_RATIO ? null : areaPublishedInSquareMeters;

  const existingStructureAreaInSquareFeet = sanitizer.forNumber(
    rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ExistingStructureArea]
  );
  let existingStructureAreaInSquareMeters = unitConversions.convert(
    existingStructureAreaInSquareFeet,
    Unit.Type.SquareFeet,
    Unit.Type.SquareMeters
  );
  existingStructureAreaInSquareMeters =
    existingStructureAreaInSquareMeters > 0 &&
    existingStructureAreaInSquareMeters < EXISTING_AREA_MIN_THRESHOLD_IN_METERS_SQUARED
      ? null
      : existingStructureAreaInSquareMeters;

  return {
    [ParcelProperty.AreaComputed]: areaComputed,
    [ParcelProperty.Id]: sanitizer.forLowerCaseString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.Id]
    ),
    [ParcelProperty.Address]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.Address]
    ),
    [ParcelProperty.City]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.City]),
    [ParcelProperty.State]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.State]),
    [ParcelProperty.ZipCode]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ZipCode]
    ),
    [ParcelProperty.AreaPublished]: areaPublishedInSquareMeters,
    [ParcelProperty.PurchasePrice]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.PurchasePrice]
    ),
    [ParcelProperty.ExistingStructureArea]: existingStructureAreaInSquareMeters,
    [ParcelProperty.ExistingStructureYearBuilt]: sanitizer.forExistingStructureYearBuilt(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ExistingStructureYearBuilt]
    ),
    [ParcelProperty.BasicStratum]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.BasicStratum]
    ),
    [ParcelProperty.LandUseCode]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.LandUseCode]
    ),
    [ParcelProperty.PublicLand]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.PublicLand]
    ),
    [ParcelProperty.ParcelId]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ParcelId]
    ),
    [ParcelProperty.NumberOfResidentialUnits]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.NumberOfResidentialUnits]
    ),
    [ParcelProperty.NumberOfBuildings]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.NumberOfBuildings]
    ),
    [ParcelProperty.ConstructionClass]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ConstructionClass]
    ),
    [ParcelProperty.ImprovementQuality]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ImprovementQuality]
    ),
    [ParcelProperty.LandValue]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.LandValue]
    ),
    [ParcelProperty.SalePrice]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SalePrice]
    ),
    [ParcelProperty.SaleYear]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SaleYear]
    ),
    [ParcelProperty.SaleMonth]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SaleMonth]
    ),
    [ParcelProperty.MultiParcelSale]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.MultiParcelSale]
    ),
    [ParcelProperty.OwnerName]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerName]
    ),
    [ParcelProperty.OwnerAddress]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerAddress]
    ),
    [ParcelProperty.OwnerCity]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerCity]
    ),
    [ParcelProperty.OwnerState]: sanitizer.forString(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerState]
    ),
    [ParcelProperty.OwnerZipCode]: sanitizer.forNumber(
      rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerZipCode]
    ),
  };
};

/**
 * Get properties from raw zoning feature.
 */
const getPropertiesFromZoningFeature = (rawZoningFeature) => {
  if (!rawZoningFeature || !rawZoningFeature.properties) return {};
  const rawZoningFeatureProperties = rawZoningFeature.properties;

  const buildableAreaByFAR = sanitizer.forString(
    rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedBuildableArea]
  );

  const buildingHeightQueryInFeet = sanitizer.forNumber(
    rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.BuildingHeightQuery]
  );
  const buildingHeightQueryInMeters = unitConversions.convert(
    buildingHeightQueryInFeet,
    Unit.Type.Feet,
    Unit.Type.Meters
  );

  const buildableAreaQueryInSquareFeet = sanitizer.forNumber(
    rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.BuildableAreaQuery]
  );
  const buildableAreaQueryInSquareMeters = unitConversions.convert(
    buildableAreaQueryInSquareFeet,
    Unit.Type.SquareFeet,
    Unit.Type.SquareMeters
  );

  return {
    [ParcelProperty.BuildableAreaByFAR]: buildableAreaByFAR,
    [ParcelProperty.BuildableAreaQuery]: buildableAreaQueryInSquareMeters,
    [ParcelProperty.AllowedDetailedUses]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedDetailedUses]
    ),
    [ParcelProperty.AllowedUses]: sanitizer.forAllowedUses(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedUses]
    ),
    [ParcelProperty.ArchitectName]: sanitizer.forString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ArchitectFirm]
    ),
    [ParcelProperty.ArchitectUrl]: sanitizer.forUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ArchitectUrl]
    ),
    [ParcelProperty.ProjectURL_1]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.Project_URL_1]
    ),
    [ParcelProperty.ProjectURL_2]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.Project_URL_2]
    ),
    [ParcelProperty.ProjectURL_3]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.Project_URL_3]
    ),
    [ParcelProperty.ProjectURL_4]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.Project_URL_4]
    ),
    [ParcelProperty.PD_URL_1]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PD_URL_1]
    ),
    [ParcelProperty.PD_URL_2]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PD_URL_2]
    ),
    [ParcelProperty.PD_URL_3]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PD_URL_3]
    ),
    [ParcelProperty.PD_URL_4]: sanitizer.forDataUrl(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PD_URL_4]
    ),
    [ParcelProperty.Overlays]: sanitizer.forString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.Overlays]
    ),
    [ParcelProperty.BuildingHeight]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.BuildingHeight]
    ),
    [ParcelProperty.BuildingHeightQuery]: buildingHeightQueryInMeters,
    [ParcelProperty.FloorAreaRatio]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.FloorAreaRatio]
    ),
    [ParcelProperty.FloorAreaRatioQuery]: sanitizer.forNumber(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.FloorAreaRatioQuery]
    ),
    [ParcelProperty.HotelUnitDensityDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityDenominator]
    ),
    [ParcelProperty.HotelUnitDensityNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityNumerator]
    ),
    [ParcelProperty.HotelUnitDensityUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityUnitOfMeasure]
    ),
    [ParcelProperty.LivingUnitDensityNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityNumerator]
    ),
    [ParcelProperty.LivingUnitDensityDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityDenominator]
    ),
    [ParcelProperty.LivingUnitDensityUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityUnitOfMeasure]
    ),
    [ParcelProperty.LotCoverage]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LotCoverage]
    ),
    [ParcelProperty.MinimumLotSize]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.MinimumLotSize]
    ),
    [ParcelProperty.NumberOfFloors]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfFloors]
    ),
    [ParcelProperty.NumberOfUnitsAllowed]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfUnitsAllowed]
    ),
    [ParcelProperty.NumberOfUnitsAllowedQuery]: sanitizer.forNumber(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfUnitsAllowedQuery]
    ),
    [ParcelProperty.ParkingSpacesResidentialNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialNumerator]
    ),
    [ParcelProperty.ParkingSpacesResidentialDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialDenominator]
    ),
    [ParcelProperty.ParkingSpacesResidentialUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialUnitOfMeasure]
    ),
    [ParcelProperty.ParkingSpacesSingleFamilyNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyNumerator]
    ),
    [ParcelProperty.ParkingSpacesSingleFamilyDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyDenominator]
    ),
    [ParcelProperty.ParkingSpacesSingleFamilyUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyUnitOfMeasure]
    ),
    [ParcelProperty.ParkingSpacesHotelNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelNumerator]
    ),
    [ParcelProperty.ParkingSpacesHotelDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelDenominator]
    ),
    [ParcelProperty.ParkingSpacesHotelUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelUnitOfMeasure]
    ),
    [ParcelProperty.ParkingSpacesOfficeNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeNumerator]
    ),
    [ParcelProperty.ParkingSpacesOfficeDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeDenominator]
    ),
    [ParcelProperty.ParkingSpacesOfficeUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeUnitOfMeasure]
    ),
    [ParcelProperty.ParkingSpacesRetailNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailNumerator]
    ),
    [ParcelProperty.ParkingSpacesRetailDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailDenominator]
    ),
    [ParcelProperty.ParkingSpacesRetailUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailUnitOfMeasure]
    ),
    [ParcelProperty.ParkingSpacesIndustrialNumerator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialNumerator]
    ),
    [ParcelProperty.ParkingSpacesIndustrialDenominator]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialDenominator]
    ),
    [ParcelProperty.ParkingSpacesIndustrialUnitOfMeasure]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialUnitOfMeasure]
    ),
    [ParcelProperty.SetbacksPrimary]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PrimarySetback]
    ),
    [ParcelProperty.SetbacksSideStreet]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.SideStreetSetback]
    ),
    [ParcelProperty.SetbacksSideInterior]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.InteriorSideSetback]
    ),
    [ParcelProperty.SetbacksRear]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.RearSetback]
    ),
    [ParcelProperty.SetbacksPrimaryAboveFirstFloor]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PrimarySetbackAboveFirstFloor]
    ),
    [ParcelProperty.SetbacksSideStreetAboveFirstFloor]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.SideStreetSetbackAboveFirstFloor]
    ),
    [ParcelProperty.SetbacksSideInteriorAboveFirstFloor]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.InteriorSideSetbackAboveFirstFloor]
    ),
    [ParcelProperty.SetbacksRearAboveFirstFloor]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.RearSetbackAboveFirstFloor]
    ),
    [ParcelProperty.ZoneId]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ZoningCode]
    ),
    [ParcelProperty.ZoneIdTruncated]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ZoningCodeTruncated]
    ),
    [ParcelProperty.BuildingHeightFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.BuildingHeightFootnote]
    ),
    [ParcelProperty.UnitDensityFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.UnitDensityFootnote]
    ),
    [ParcelProperty.HotelDensityFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelDensityFootnote]
    ),
    [ParcelProperty.FarFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.FarFootnote]
    ),
    [ParcelProperty.LotCoverageFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LotCoverageFootnote]
    ),
    [ParcelProperty.NumberOfFloorsFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfFloorsFootnote]
    ),
    [ParcelProperty.SetbackFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.SetbackFootnote]
    ),
    [ParcelProperty.UsesFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.UsesFootnote]
    ),
    [ParcelProperty.ParkingMultifamilyFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingMultifamilyFootnote]
    ),
    [ParcelProperty.ParkingSingleFamilyFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSingleFamilyFootnote]
    ),
    [ParcelProperty.ParkingHotelFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingHotelFootnote]
    ),
    [ParcelProperty.ParkingOfficeFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingOfficeFootnote]
    ),
    [ParcelProperty.ParkingRetailFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingRetailFootnote]
    ),
    [ParcelProperty.ParkingIndustrialFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingIndustrialFootnote]
    ),
    [ParcelProperty.MinimumLotSizeFootnote]: sanitizer.forLowerCaseString(
      rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.MinimumLotSizeFootnote]
    ),
  };
};

/**
 * Get properties from raw demographics feature.
 */
const getPropertiesFromDemographicsFeature = (demographicsFeature) => {
  if (!demographicsFeature || !demographicsFeature.properties) return {};
  const rawDemographicsFeatureProperties = demographicsFeature.properties;
  const sanitizedGdp = sanitizer.forNumber(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.Gdp]
  );

  let medianIncome = sanitizer.forNumber(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotal]
  );
  let medianIncomeGenerated = sanitizer.forBoolean(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotalGenerated]
  );
  if (!medianIncome) {
    medianIncome = DEFAULT_DEMOGRAPHIC_VALUES[MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotal];
    medianIncomeGenerated = true;
  }

  let grossMedianRent = sanitizer.forNumber(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.GrossMedianRent]
  );
  let grossMedianRentGenerated = sanitizer.forBoolean(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.GrossMedianRentGenerated]
  );
  if (!grossMedianRent) {
    grossMedianRent = DEFAULT_DEMOGRAPHIC_VALUES[MapStyleProperties.RawDemographicsFieldId.GrossMedianRent];
    grossMedianRentGenerated = true;
  }

  let populationDensity = sanitizer.forNumber(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.PopulationDensity]
  );
  let populationDensityGenerated = sanitizer.forBoolean(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.PopulationDensityGenerated]
  );
  if (!populationDensity) {
    populationDensity = DEFAULT_DEMOGRAPHIC_VALUES[MapStyleProperties.RawDemographicsFieldId.PopulationDensity];
    populationDensityGenerated = true;
  }

  let employmentPerPopulation = sanitizer.forNumber(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.EmploymentPerPopulationRatio]
  );
  let employmentPerPopulationGenerated = sanitizer.forBoolean(
    rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.EmploymentPerPopulationRatioGenerated]
  );
  if (!employmentPerPopulation) {
    employmentPerPopulation =
      DEFAULT_DEMOGRAPHIC_VALUES[MapStyleProperties.RawDemographicsFieldId.EmploymentPerPopulationRatio];
    employmentPerPopulationGenerated = true;
  }

  return {
    [ParcelProperty.MedianIncomeTotal]: medianIncome,
    [ParcelProperty.MedianIncomeTotalGenerated]: medianIncomeGenerated,
    [ParcelProperty.GrossMedianRent]: grossMedianRent,
    [ParcelProperty.GrossMedianRentGenerated]: grossMedianRentGenerated,
    [ParcelProperty.PopulationDensity]: populationDensity,
    [ParcelProperty.PopulationDensityGenerated]: populationDensityGenerated,
    [ParcelProperty.EmploymentPerPopulation]: employmentPerPopulation,
    [ParcelProperty.EmploymentPerPopulationGenerated]: employmentPerPopulationGenerated,
    [ParcelProperty.Market]: sanitizer.forNumber(
      rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.Market]
    ),
    [ParcelProperty.Gdp]: sanitizedGdp ? sanitizedGdp / 1000000 : sanitizedGdp,
  };
};

/**
 * Get properties from raw buildings feature.
 */
const getPropertiesFromBuildingFeature = (buildingFeature, parcelFeature) => {
  if (!buildingFeature || !buildingFeature.properties) return {};
  const rawBuildingFeatureProperties = buildingFeature.properties;
  const height = sanitizer.forNumber(rawBuildingFeatureProperties[MapStyleProperties.RawBuildingFieldId.Height]);

  let targetFeature;
  if (turf.booleanContains(parcelFeature, buildingFeature)) {
    targetFeature = buildingFeature;
  } else {
    targetFeature = turf.intersect(buildingFeature, parcelFeature);
    if (!targetFeature) return {};
  }

  const area = turf.area(targetFeature);

  let buildingFeatureModel: BuildingFeatureModel | undefined;
  if (height !== null) {
    buildingFeatureModel = { height: height, footprintArea: area };
  }

  return {
    [ParcelProperty.BuildingFeatureModel]: buildingFeatureModel,
  };
};

/**
 * Get properties from existing buildings feature.
 */
const getPropertiesFromExistingBuildingFeature = (existingBuildingFeature) => {
  if (!existingBuildingFeature || !existingBuildingFeature.properties) return {};
  const rawExistingBuildingFeatureProperties = existingBuildingFeature.properties;
  const height = sanitizer.forNumber(
    rawExistingBuildingFeatureProperties[MapStyleProperties.RawBuildingFieldId.Height]
  );
  const existingStructureAreaOpenSource = sanitizer.forNumber(
    rawExistingBuildingFeatureProperties[MapStyleProperties.RawBuildingFieldId.StructureArea]
  );

  return {
    [ParcelProperty.ExistingBuildingHeight]: height,
    [ParcelProperty.ExistingStructureAreaOpenSource]: existingStructureAreaOpenSource,
  };
};

export default populateParcelProperties;
