import { Range } from "rc-slider";
import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions } from "../../../../../../state/newDevelopment";
import { Filter } from "../../../../../../types/Filter";
import Format from "../../../../../../types/Format";
import { KeyCode } from "../../../../../../types/KeyCodes";
import Unit from "../../../../../../types/Unit";
import analytics from "../../../../../../utils/analytics";
import authentication from "../../../../../../utils/authentication";
import unitConversions from "../../../../../../utils/unitConversions";
import valueFormatter from "../../../../../../utils/valueFormatter";
import { colorFor } from "../../../../../utils/colors";
import { ToggleField } from "../../../../../utils/forms/fieldRenderers";
import equalWithinEpsilon from "../../../../../../utils/floatCompare";
import roundToDecimal from "../../../../../../utils/roundToDecimal";

const SLIDER_STEP = 0.000001;

enum ValueType {
  Min = "min",
  Max = "max"
}

const mapDispatchToProps = {
  updateFilter: newDevelopmentActions.updateFilter,
};

interface OwnProps {
  filter: Filter;
  index: number;
  label: string;
  min: number;
  max: number;
  absoluteMax?: number;
  step?: number;
  unitTarget: Unit.Type;
  isInverse: boolean;
  formatOptions?: Format.Options;
  dataTip: any;
}

type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & OwnProps;

interface State {
  value: number[];
  min: number;
  max: number;
}

class SearchRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { filter, min, max } = props;

    this.state = {
      value: filter.value,
      min: min,
      max: Math.max(max, filter.max || 0),
    };
  }

  /**
   * Handle change on toggles.
   */
  handleToggleChange = () => {
    const { filter, updateFilter } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated && !filter.allowUnauthenticated) return;

    updateFilter(filter.id, !filter.isActive, this.state.value, this.state.max);
    this.trackFilterChange(filter.id, filter.isActive, this.state.value);
  }

  /**
   * Track changes to filters.
   */
  trackFilterChange = (id, isActive, value) => {
    let [min, max] = value;
    analytics.trackFilterToggle(id, isActive, min, max);
  }

  /**
   * Handle sliders release.
   */
  handleSliderAfterChange = (rawValues) => {
    const { filter, updateFilter } = this.props;

    updateFilter(filter.id, filter.isActive, rawValues, this.state.max);
    this.trackFilterChange(filter.id, filter.isActive, rawValues);
  }

  /**
   * Handle sliders change.
   */
  handleSliderChange = (rawSliderValues) => {
    let value = [...this.state.value];
    if (equalWithinEpsilon(rawSliderValues[1], value[1], SLIDER_STEP)) { // Min slider is being changed
      value[0] = this.getNearestSnappingPoint(rawSliderValues[0]);
    } else { // Max slider is being changed
      value[1] = this.getNearestSnappingPoint(rawSliderValues[1]);
    }

    this.setState({ value });
  };

  /**
   * Check and enforce a minimum and maximum value.
   */
  checkMinMaxConstraints = (sliderValue) => {
    const { absoluteMax } = this.props;
    let { min, max } = this.state;
    let value = sliderValue;

    if (min !== undefined && value < min) {
      value = min;
    } else if (max !== undefined && value > max) {

      if (absoluteMax !== undefined && value > absoluteMax) {
        value = absoluteMax;
      }

      this.setState({ max: value });
    }

    return value;
  }

  /**
   * Convert value to current unit system from the base unit.
   */
  convertFromBase = (value) => {
    const { unitTarget, isInverse } = this.props;
    return unitTarget ? unitConversions.convertFromBase(value, unitTarget, isInverse) : value;
  }

  /**
  * Convert value from current unit system to the base unit.
  */
  convertToBase = (value) => {
    const { unitTarget, isInverse } = this.props;
    return unitTarget ? unitConversions.convertToBase(value, unitTarget, isInverse) : value;
  }

  /**
   * Handle the onBlur event. Update the slider (and whole platform) with the new value.
   */
  handleInputBlur = (event, valueType) => {
    const { filter, updateFilter, formatOptions } = this.props;
    const targetValue = parseInt(event.target.value.replace(",", ""));
    if (!isNaN(targetValue)) {
      const convertedValue = this.checkMinMaxConstraints(this.convertToBase(targetValue));
      let placeholder = (valueType === ValueType.Min)
        ? valueFormatter.format(this.convertFromBase(this.state.value[0]), formatOptions)
        : valueFormatter.format(this.convertFromBase(this.state.value[1]), formatOptions)

      let nextStateValue = (valueType === ValueType.Min)
        ? [convertedValue, this.state.value[1]]
        : [this.state.value[0], convertedValue];

      if (valueType === ValueType.Min && nextStateValue[0] > nextStateValue[1]) {
        nextStateValue = [nextStateValue[0], nextStateValue[0]];
      } else if (valueType === ValueType.Max && nextStateValue[1] < nextStateValue[0]) {
        nextStateValue = [nextStateValue[1], nextStateValue[1]];
      }

      this.setState({ value: nextStateValue });

      updateFilter(filter.id, filter.isActive, nextStateValue, this.state.max);
      this.trackFilterChange(filter.id, filter.isActive, nextStateValue);
      event.target.placeholder = placeholder;
    }

    event.target.value = "";
  }

  /**
   * Round value to the appropriate number of decimal places.
   */
  round = (value) => {
    let decimalPlaces = 0;
    if (this.props.formatOptions && this.props.formatOptions.decimalPlaces) {
      decimalPlaces = this.props.formatOptions.decimalPlaces;
    }
    return roundToDecimal(value, decimalPlaces);
  }

  /**
   * Handle the onFocus event.
   */
  handleInputFocus = (event, value) => {
    event.target.placeholder = "";
    event.target.value = this.round(this.convertFromBase(value));
    event.target.select();
  }

  /**
   * Return the value of the nearest snapping point of the slider for a given value.
   * The snapping points of a slider are the multiples of the increment, the min and the max.
   */
  getNearestSnappingPoint = (sliderValue: number) => {
    const { step } = this.props;
    const { min, max } = this.state;
    let value = sliderValue;

    if (step === undefined
      || min === undefined
      || max === undefined) return value;

    value = this.convertToBase(Math.round(this.convertFromBase(value) / step) * step);
    value = Math.max(min, Math.min(max, value));
    return value;
  }

  /**
   * Get the slider style.
   */
  getStyle = (filter) => {
    return {
      handle: [
        { backgroundColor: filter.isActive ? colorFor("smart-search-slider-box") : colorFor("smart-search-slider-disabled") },
        { backgroundColor: filter.isActive ? colorFor("smart-search-slider-box") : colorFor("smart-search-slider-disabled") },
      ],
      track: [
        { backgroundColor: filter.isActive ? colorFor("smart-search-slider-box") : colorFor("smart-search-slider-disabled") },
      ],
      rail: { backgroundColor: filter.isActive ? colorFor("smart-search-slider-rail") : colorFor("smart-search-slider-disabled") },
    };
  }

  /**
   * Blur input on pressing of Enter key.
   */
  handleKeyDown = (event) => {
    if (event.key === KeyCode.Enter) event.target.blur();
  }

  /**
   * Render slider.
   */
  renderSlider = () => {
    const { label, formatOptions, filter } = this.props;
    const { min, max } = this.state;
    const { value } = this.state;

    if (value.length > 1) {
      if (min === undefined || max === undefined) return;

      return (
        <div className="slider-filter">
          <div className={`check ${filter.isActive ? "active" : ""}`} onClick={this.handleToggleChange} />
          <div className="slider-wrapper">
            <div className="slider-label">
              {label}
            </div>
            <div className="rc-slider-wrapper">
              <Range
                min={min}
                max={max}
                step={SLIDER_STEP}
                disabled={!filter.isActive}
                onChange={this.handleSliderChange}
                onAfterChange={this.handleSliderAfterChange}
                value={value}
                handleStyle={this.getStyle(filter).handle}
                trackStyle={this.getStyle(filter).track}
                railStyle={this.getStyle(filter).rail}
              />
            </div>
            <div className="values">
              <input
                className={`min-input ${filter.isActive ? "" : "disabled"}`}
                placeholder={valueFormatter.format(this.convertFromBase(this.state.value[0]), formatOptions)}
                type="number"
                onBlur={(event) => this.handleInputBlur(event, ValueType.Min)}
                onFocus={(event) => this.handleInputFocus(event, this.state.value[0])}
                onKeyDown={this.handleKeyDown}
              />
              <input
                className={`max-input ${filter.isActive ? "" : "disabled"}`}
                placeholder={valueFormatter.format(this.convertFromBase(this.state.value[1]), formatOptions)}
                type="number"
                onBlur={(event) => this.handleInputBlur(event, ValueType.Max)}
                onFocus={(event) => this.handleInputFocus(event, this.state.value[1])}
                onKeyDown={this.handleKeyDown}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <ToggleField
          label={label}
          color={colorFor("smart-search-toggle")}
          toggleElementProps={{
            value: filter.isActive,
            onChange: this.handleToggleChange
          }}
        />
      );
    }
  }

  render() {
    return (
      <div className="component--search-row" data-for="click-right" data-tip={this.props.dataTip}>
        {this.renderSlider()}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SearchRow);
