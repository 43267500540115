import { useEffect } from "react";
import { SetSetbackModeFunction } from "state/setbackMode/types";

export const usePrevPropsProjectId = (
  prevPropProjectId: string | null,
  projectId: string | null,
  setSetbackMode: SetSetbackModeFunction
) => {
  useEffect(() => {
    if (prevPropProjectId !== null) {
      if (prevPropProjectId !== projectId) {
        setSetbackMode(false);
      }
    }
  }, [prevPropProjectId, projectId]);
};
