import actionTypes from "./actionTypes";
import { developmentActionTypes } from "../development";
import { MeasurementTool } from "types/MeasurementTools";
import turf from "utils/turf";
import geometry from "utils/geometry";
export interface SetbackModeState {
  setbackModeIsActive: boolean;
  selectedPolygonIndex: number;
  selectedEdgeIndex: number;
  measurementTool: MeasurementTool;
  measurementToolFromToolbar: MeasurementTool;
  shapeIsBeingChanged: boolean;
  shapeArea: number;
  shapePerimeter: number;
  areaLabelVisibility: boolean;
  setbackTypeMarkerVisibility: boolean;
}

const INITIAL_STATE: SetbackModeState = {
  setbackModeIsActive: false,
  selectedPolygonIndex: 0,
  selectedEdgeIndex: 0,
  measurementTool: MeasurementTool.SelectShape,
  measurementToolFromToolbar: MeasurementTool.SelectShape,
  shapeIsBeingChanged: false,
  shapeArea: 0,
  shapePerimeter: 0,
  areaLabelVisibility: true,
  setbackTypeMarkerVisibility: true,
};

const reducer = (previousState = INITIAL_STATE, action): SetbackModeState => {
  switch (action.type) {
    case actionTypes.SET_SETBACK_MODE: return setSetbackMode(previousState, action.payload);
    case actionTypes.SET_INDEX_VALUES: return setIndexValues(previousState, action.payload);
    case developmentActionTypes.SWITCH_DEVELOPMENT: return resetState(previousState, action.payload);
    case actionTypes.SET_SHAPE_IS_BEING_CHANGED: return setShapeIsBeingChanged(previousState, action.payload);
    case actionTypes.SET_MEASUREMENT_TOOL: return setMeasurementTool(previousState, action.payload);
    case actionTypes.SET_SHAPE_MEASUREMENTS: return setShapeMeasurements(previousState, action.payload);
    case actionTypes.RESET_SHAPE_MEASUREMENTS: return resetshapeMeasurements(previousState, action.payload);
    case actionTypes.SET_AREA_LABEL_VISIBILITY: return setAreaLabelVisibility(previousState, action.payload);
    case actionTypes.SET_SETBACK_TYPE_MARKER_VISIBILITY: return setSetbackTypeMarkerVisibility(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * See `setSetbackMode` action creator.
 *
 * Sets the setbackModeIsActive flag.
 */
const setSetbackMode = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    setbackModeIsActive: payload.setbackModeIsActive,
  }
}

/**
 * See `setIndexValues` action creator.
 *
 * Sets the selected index values.
 */
const setIndexValues = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    selectedPolygonIndex: payload.selectedPolygonIndex,
    selectedEdgeIndex: payload.selectedEdgeIndex,
  }
}

/**
 * Reset the state to it's initial value.
 */
const resetState = (previousState: SetbackModeState, payload): SetbackModeState => {
  return INITIAL_STATE;
}

/**
 * See `setMeasurementTool` action creator.
 *
 * Returns a new state object with the measurementTool updated.
 */
 const setMeasurementTool = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    measurementTool: payload.measurementTool,
    measurementToolFromToolbar: payload.measurementTool,
  }
}

/**
 * See `setShapeIsBeingChanged` action creator.
 *
 * Returns a new state object with the shapeIsBeingChanged updated.
 */
const setShapeIsBeingChanged = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    shapeIsBeingChanged: payload.shapeIsBeingChanged
  }
}

/**
 * See `setShapeMeasurements` action creator.
 *
 * Returns a new state object with the shapeArea and shapePerimeter updated.
 */
const setShapeMeasurements = (previousState: SetbackModeState, payload): SetbackModeState => {
  const { feature } = payload;
  const shapeArea = turf.area(feature);
  const shapePerimeter = geometry.perimeter(feature);

  return {
    ...previousState,
    shapeArea,
    shapePerimeter,
  };
}

/**
 * See `resetShapeMeasurements` action creator.
 *
 * Returns new state with default shape values.
 */
const resetshapeMeasurements = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    shapeArea: INITIAL_STATE.shapeArea,
    shapePerimeter: INITIAL_STATE.shapePerimeter,
    shapeIsBeingChanged: false
  }
}

/**
 * See `setAreaLabelVisibility` action creator.
 *
 * Sets the AreaLabelVisibility flag.
 */
 const setAreaLabelVisibility = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    areaLabelVisibility: payload.areaLabelVisibility,
  }
}

/**
 * See `setSetbackTypeMarkerVisibility` action creator.
 *
 * Sets the SetbackTypeMarker flag.
 */
 const setSetbackTypeMarkerVisibility = (previousState: SetbackModeState, payload): SetbackModeState => {
  return {
    ...previousState,
    setbackTypeMarkerVisibility: payload.setbackTypeMarkerVisibility,
  }
}

export default reducer;
