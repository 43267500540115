import React from "react";
import { connect } from "react-redux";
import { mapsActions, mapsSelectors } from "state/ui/shared/maps";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import dynamicLayersConfig from "../dynamicLayersConfig";

interface ownProps {
  layerGroup: DynamicLayerId;
}

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
})

const mapDispatchToProps = {
  resetDynamicLayers: mapsActions.resetDynamicLayers,
  activateDynamicLayers: mapsActions.activateDynamicLayers,
  setSelectedDynamicLayerGroup: mapsActions.setSelectedDynamicLayerGroup,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = ownProps & StateProps & DispatchProps;

export class DynamicLayerButton extends React.PureComponent<Props, {}> {

/**
 *
 * Toggle the Dynamic Slider
 */
 toggleDynamicLayersActivation = () => {
  const { layerGroup, setSelectedDynamicLayerGroup, getSelectedDynamicLayer } = this.props;
  const { resetDynamicLayers, activateDynamicLayers } = this.props;

  if(getSelectedDynamicLayer === null){
    setSelectedDynamicLayerGroup(layerGroup);
    return activateDynamicLayers(layerGroup, dynamicLayersConfig[layerGroup].initializationYear, layerGroup);
  }

  if(getSelectedDynamicLayer !== null && getSelectedDynamicLayer !== layerGroup){
    resetDynamicLayers();
    setSelectedDynamicLayerGroup(layerGroup);
    return activateDynamicLayers(layerGroup, dynamicLayersConfig[layerGroup].initializationYear, layerGroup);
  }

  return resetDynamicLayers();
}

render (){
  const { layerGroup } = this.props;
  const { getSelectedDynamicLayer } = this.props;
  const deactivateLayer = getSelectedDynamicLayer !== layerGroup;

  return (
    <button className={`dynamic-layer-button ${!deactivateLayer ? "enabled" : "" }`} onClick={this.toggleDynamicLayersActivation}>{dynamicLayersConfig[layerGroup].buttonText}</button>
  )
}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicLayerButton);
