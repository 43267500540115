import * as firebase from "firebase/app";
import "firebase/storage";

/**
 * Upload a user's thumbnail to Firebase Storage.
 */
const upload = async (thumbnail: Blob, userId: string): Promise<void> => {
  const thumbnailReference = firebase.storage().ref(pathToThumbnail(userId));

  try {
    await thumbnailReference.put(thumbnail);
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Delete a user's thumbnail from Firebase Storage.
 */
const remove = async (userId: string): Promise<void> => {
  const thumbnailReference = firebase.storage().ref(pathToThumbnail(userId));

  try {
    await thumbnailReference.delete();
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Construct the path to the thumbnail of the given user in the Firebase Storage.
 */
const pathToThumbnail = (userId: string): string => {
  return `users/thumbnails/${userId}`;
}

/**
 * Download a user's thumbnail from Firebase Storage.
 */
const download = async (userId: string): Promise<string> => {
  const thumbnailReference = firebase.storage().ref(pathToThumbnail(userId));
  const thumbnailUrl = await thumbnailReference.getDownloadURL();
  const response = await fetch(thumbnailUrl);

  if (!response.ok) throw new Error(`A thumbnail with ID "${userId}" could not be retrieved. The request return with status ${response.status}.`);

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
}

export default {
  upload,
  remove,
  download,
}
