import React from "react";
import companyInfo from "utils/companyInfo";

export default function ZoningReportButtonLink() {
  return (
    <a href={companyInfo.ZONING_REPORT_URL} target="_blank" className="button-link">
      Zoning Report
    </a>
  );
}
