import React from "react";
import EditInformation from "./EditInformation";
import DisplayInformation from "./DisplayInformation"

interface State {
  isEditing: boolean;
}

class AccountInformation extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    }
  }

  /**
   * Toggle the isEditing state.
   */
  toggleIsEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <div className="component--account-information">
        <div className="header">Account Information</div>
        <div className="content">
          {this.state.isEditing
              ? <EditInformation closeEditing={this.toggleIsEditing}/>
              : <DisplayInformation openEditing={this.toggleIsEditing}/>
          }
        </div>
      </div>
    );
  }
}

export default AccountInformation;
