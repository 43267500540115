import actionTypes from "./actionTypes";
import { MeasurementTool } from "types/MeasurementTools";
import { GeoJSON } from "geojson";

/**
 * Set setback mode flag.
 */
const setSetbackMode = (setbackModeIsActive: boolean) => {
  return {
    type: actionTypes.SET_SETBACK_MODE,
    payload: { setbackModeIsActive },
  }
}

/**
 * Set the selectedPolygonIndex value.
 */
const setIndexValues = (selectedPolygonIndex, selectedEdgeIndex) => {
  return {
    type: actionTypes.SET_INDEX_VALUES,
    payload: { selectedPolygonIndex, selectedEdgeIndex },
  }
}

/**
 * Set the measurementTool in the state.
 */
const setMeasurementTool = (measurementTool: MeasurementTool) => {
  return {
    type: actionTypes.SET_MEASUREMENT_TOOL,
    payload: {
      measurementTool,
    },
  };
}

/**
 * Set the shapeIsBeingChanged in the state.
 */
const setShapeIsBeingChanged = (shapeIsBeingChanged: boolean) => {
  return {
    type: actionTypes.SET_SHAPE_IS_BEING_CHANGED,
    payload: {
      shapeIsBeingChanged,
    },
  };
}

/**
 * Set the shape measurements in the state.
 */
const setShapeMeasurements = (feature: GeoJSON) => {
  return {
    type: actionTypes.SET_SHAPE_MEASUREMENTS,
    payload: {
      feature,
    },
  };
}

/**
 * Reset shape measurements to default values.
 */
const resetShapeMeasurements = () => {
  return {
    type: actionTypes.RESET_SHAPE_MEASUREMENTS
  };
}

/**
 * Set Area Label visibility.
 */
 const setAreaLabelVisibility = (areaLabelVisibility: boolean) => {
  return {
    type: actionTypes.SET_AREA_LABEL_VISIBILITY,
    payload: { areaLabelVisibility },
  }
}

/**
 * Set Setback Type Marker visibility.
 */
 const setSetbackTypeMarkerVisibility = (setbackTypeMarkerVisibility: boolean) => {
  return {
    type: actionTypes.SET_SETBACK_TYPE_MARKER_VISIBILITY,
    payload: { setbackTypeMarkerVisibility },
  }
}

export default {
  setSetbackMode,
  setIndexValues,
  setMeasurementTool,
  setShapeIsBeingChanged,
  setShapeMeasurements,
  resetShapeMeasurements,
  setAreaLabelVisibility,
  setSetbackTypeMarkerVisibility,
}
