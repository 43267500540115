import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { Chargebee } from "../../../../../types/Service";
import CreditCardPopup from "./CreditCardPopup";

const mapStateToProps = (state) => {
  return {
    creditCard: subscriptionSelectors.getCreditCard(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

interface State {
  creditCardPopupIsOpen: boolean;
}

class PaymentMethod extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      creditCardPopupIsOpen: false,
    }
  }

  /**
   * Toggle the creditCardPopupIsOpen state.
   */
  togglePopup = () => {
    this.setState({ creditCardPopupIsOpen: !this.state.creditCardPopupIsOpen });
  }

  render() {
    const { creditCard } = this.props;
    return (
      <div className="component--payment-method">
        <div className="header">Payment Method</div>
        <div className="content ">
          <div className="text">
            {creditCard
                ? <>
                    <span className="capitalize bold">{creditCard.card_type}</span>
                    <span> ending in </span>
                    <span className="bold">{creditCard.last4}</span>
                    {creditCard.status === Chargebee.CardStatus.Expired && <span className="warning"> (Expired)</span>}
                    {creditCard.status === Chargebee.CardStatus.Expiring && <span className="warning"> (Expiring soon)</span>}
                  </>
                : "No Payment Method on record"
            }
          </div>
          <button onClick={this.togglePopup}>
            {creditCard
                ? "Edit Payment Method"
                : "Add Payment Method"
            }
          </button>
        </div>

        {this.state.creditCardPopupIsOpen && <CreditCardPopup onStateChange={this.togglePopup} />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(PaymentMethod);
