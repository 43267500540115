import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsSelectors, subscriptionInvitationsActions } from "../../../../../state/subscriptionInvitations";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { SubscriptionInvitationStatus } from "../../../../../types/SubscriptionInvitations";
import WithPanelHeader from "../../../../sharedComponents/WithPanelHeader";
import wrapComponentWithPopup, { PopupProps } from "../../../wrapComponentWithPopup/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    invitations: subscriptionInvitationsSelectors.getSubscriptionInvitations(state),
    subscriptionId: subscriptionSelectors.getId(state),
  }
}
const mapDispatchToProps = {
  acceptInvitation: subscriptionInvitationsActions.acceptStart,
  rejectInvitation: subscriptionInvitationsActions.rejectStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & StateProps & DispatchProps;

class InvitationsPopup extends React.PureComponent<Props, {}>  {
  /**
   * Reject an invitation.
   */
  rejectInvitation = (ownerId) => {
    const { rejectInvitation } = this.props;
    rejectInvitation(ownerId);
  }

  /**
   * Accept an invitation.
   */
  acceptInvitation = (ownerId) => {
    const { subscriptionId, acceptInvitation, closePopup } = this.props;
    acceptInvitation(ownerId, subscriptionId);
    closePopup();
  }

  render() {
    const { invitations, closePopup } = this.props;
    const pendingInvitations = Object.keys(invitations).filter((ownerId) => invitations[ownerId].status === SubscriptionInvitationStatus.Pending);

    return (
      <WithPanelHeader title={`Invitation${pendingInvitations.length > 1 ? "s" : ""} to PRO`} hideControls>
        <div className="component--invitations-popup">
          {pendingInvitations.map((ownerId, index) => (
            <div className="invitation" key={`invitation_${index}`}>
              <div className="text">
                You have received an invitation from <span>{invitations[ownerId].ownerName}</span> for Deepblocks PRO. <br />
                Upon acceptance, your subscription and payment will be managed by < br />
                <span>{invitations[ownerId].ownerName}</span>.
              </div>
              <div className="buttons-container">
                <button onClick={() => this.rejectInvitation(ownerId)}>Decline</button>
                <button onClick={() => this.acceptInvitation(ownerId)}>Accept</button>
              </div>
            </div>
          ))}

          <button onClick={closePopup}>Close</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InvitationsPopup)
);
