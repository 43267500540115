import { createAction } from "@reduxjs/toolkit";

/**
 * Leave the new project page.
 */
const leaveNewProjectPage = createAction("navigation/LEAVE_NEW_PROJECT_PAGE");

export default {
  leaveNewProjectPage
}
