import React from "react";
import { connect } from "react-redux";
import { userSelectors } from "../../../../../../../state/user";
import authentication from "../../../../../../../utils/authentication";
import ResetPasswordPopup from "./ResetPasswordPopup";

const mapStateToProps = (state) => {
  return {
    userDocument: userSelectors.getUserDocument(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

interface State {
  feedbackPopupIsOpen: boolean;
}

class LogInButton extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      feedbackPopupIsOpen: false,
    }
  }

  /**
   * Send password reset email and display feedback.
   */
  sendResetPasswordEmail = () => {
    const { userDocument } = this.props;
    if (!userDocument) return;

    authentication.sendResetPasswordEmail(userDocument.email);
    this.setState({ feedbackPopupIsOpen: true });
  }

  /**
   * Close the feedback popup.
   */
  closeFeedbackPopup = () => {
    this.setState({ feedbackPopupIsOpen: false });
  }

  render() {
    if (!authentication.isUserAuthenticated()) return null;
    const { feedbackPopupIsOpen } = this.state;

    return (
      <>
        <button
          className={`${feedbackPopupIsOpen ? "active" : ""}`}
          onClick={this.sendResetPasswordEmail}
        >
          Reset Password
        </button>

        {feedbackPopupIsOpen && <ResetPasswordPopup onStateChange={this.closeFeedbackPopup} />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(LogInButton);
