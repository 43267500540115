import React from "react";
import valueFormatter from "../../../utils/valueFormatter";
import Format from "../../../types/Format";

interface Props {
  isActive?: boolean;
  projectIsForSale: boolean;
  saleReturnOnCost: number;
  leaseUsesReturnOnCost: number;
}

class ReturnOnCost extends React.PureComponent<Props, {}> {
  render() {
    const { isActive, saleReturnOnCost, leaseUsesReturnOnCost, projectIsForSale } = this.props;
    if (isActive !== undefined && !isActive) return null;

    const returnOnCost = projectIsForSale ? saleReturnOnCost : leaseUsesReturnOnCost;

    return (
      <div className="component--return-on-cost">
        <div className="header">Return</div>
        <div className="separator" />
        <div className="value">{valueFormatter.format(returnOnCost, { type: Format.Type.Percentage, decimalPlaces: 2 })}</div>
      </div>
    );
  }
}

export default ReturnOnCost;
