import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { subscriptionSelectors } from "../../../../../../state/subscription";
import { mapsSelectors } from "../../../../../../state/ui/shared/maps";
import { Path } from "../../../../../../types/Path";
import layerHelper, {
  LayerConfigurations,
  LayerConfigurationsLayerIds,
} from "../../../../../../utils/mapbox/layerHelper";
import { colorFor } from "../../../../../utils/colors";
import { ToggleField } from "../../../../../utils/forms/fieldRenderers";
import LayerToggleBase from "../../../../LayerToggleBase";
import { TooltipType } from "../../../../Tooltip/config";
import { Tier } from "types/Tier";

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  };
};

const LayerToggle = ({
  layerIds,
  tier,
  layerConfigurations,
}: {
  layerIds: Array<LayerConfigurationsLayerIds>;
  tier: Tier;
  layerConfigurations: LayerConfigurations;
}) => {
  const location = useLocation();
  const side = location.pathname.includes(Path.Explorer) ? "left" : "right";
  const disableForDemo = layerHelper.disableForDemo(tier, layerIds, layerConfigurations);

  return (
    <LayerToggleBase layerIds={layerIds}>
      {(isActive, onClick) => (
        <ToggleField
          classes={disableForDemo ? "disable-for-demo" : ""}
          color={colorFor("legend-toggle")}
          toggleElementProps={{
            value: isActive,
            onChange: disableForDemo ? undefined : onClick,
          }}
          tooltipId={`click-${side}`}
          tooltipType={disableForDemo ? TooltipType.LayerSelectionPanel : null}
        />
      )}
    </LayerToggleBase>
  );
};

export default connect(mapStateToProps)(LayerToggle);
