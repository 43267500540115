import developmentAccessors from "../../../../../state/development/utils/developmentAccessors";
import { Development } from "../../../../../types/Development/Development";
import parcelAccessors from "../../../../parcel/parcelAccessors";

type NormalizationParams = {
  std: number,
  mean: number,
}

export type NormalizationConfig = {
  medianIncome: NormalizationParams;
  population: NormalizationParams;
  medianGrossRent: NormalizationParams;
  employmentPerPopulation: NormalizationParams;
}

/**
 * Get the base features from the development object.
 * Throws error if one of them is 0.
 */
const getBaseFeaturesFromDevelopment = (development: Development) => {
  const parcel = developmentAccessors.getParcel(development);

  const medianIncome = Math.max(...parcelAccessors.getMedianIncomeTotal(parcel), 0);
  const medianGrossRent = Math.max(...parcelAccessors.getGrossMedianRent(parcel), 0);
  const population = Math.max(...parcelAccessors.getPopulationDensity(parcel), 0);
  const employmentPerPopulation = Math.max(...parcelAccessors.getEmploymentPerPopulation(parcel), 0);
  const market = Math.max(...parcelAccessors.getMarket(parcel), 1);

  if ([medianIncome, medianGrossRent, population, employmentPerPopulation].some((value) => value === 0)) {
    throw new Error(`Value for one or all parameters for model are possibly missing: {medianIncomeTotal, grossMedianRent, populationDensity, employmentPerPopulation} : ${[medianIncome, medianGrossRent, population, employmentPerPopulation]}`);
  }

  return {
    medianIncome,
    population,
    medianGrossRent,
    employmentPerPopulation,
    market,
  }
}

/**
 * Normalize the value depending on the normalization parameters.
 */
const normalize = (value: number, normalizationParams: NormalizationParams) => {
  return (value - normalizationParams.mean) / normalizationParams.std;
}

export default {
  getBaseFeaturesFromDevelopment,
  normalize,
}
