import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "state/newDevelopment";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import { mapsSelectors } from "state/ui/shared/maps";
import { LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import downloadSearchResultsCsv from "./utils/downloadSearchResultsCsv";

const ParcelsCsvExportButton = () => {
  const parcelSearchResults = useSelector(newDevelopmentSelectors.getSmartSearchResult);
  const subscriptionTier = useSelector(subscriptionSelectors.getTier);
  const layerConfigurations = useSelector(mapsSelectors.getLayerConfigurations);

  const searchResultsExist = parcelSearchResults.length > 0;
  const userHasProSubscription = subscriptionTier === Tier.Pro;
  const parcelSearchLayerIsActive = layerConfigurations[LayerId.SmartSearchResultsFill].isActive;

  const isEnabled = userHasProSubscription && parcelSearchLayerIsActive && searchResultsExist;

  return (
    <button
      className={`component--parcels-csv-export-button ${isEnabled ? "" : "disabled"}`}
      onClick={isEnabled ? () => downloadSearchResultsCsv(parcelSearchResults) : undefined}
    >
      Export CSV
    </button>
  );
};

export default ParcelsCsvExportButton;
