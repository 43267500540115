import { newDevelopmentProcesses } from "./newDevelopment";
import { developmentProcesses } from "./development";
import { subscriptionProcesses } from "./subscription";
import { subscriptionInvitationsProcesses } from "./subscriptionInvitations";
import { thumbnailsProcesses } from "./thumbnails";
import { userProcesses } from "./user";
import { pdfProcesses } from "./pdf";
import { authenticationProcesses } from "./authentication";
import { layersProcesses } from "./layers";

/**
 * Each saga has to be registered with the redux-saga middleware in order to run.
 * Register all sagas here.
 *
 * NOTE: Make sure only sagas (i.e. Generator functions) are passed in to the
 * `SAGAS` array. Also, make sure to include only parent sagas and not to
 * include child sagas as this might have unintended consequences.
 *
 * For example:
 *
 *    import { helloWorldProcesses } from ".../helloWorld"
 *
 *    const SAGAS = [
 *      ...Object.values(helloWorldProcesses)
 *    ]
 *
 */
const SAGAS = [
  ...Object.values(authenticationProcesses),
  ...Object.values(newDevelopmentProcesses),
  ...Object.values(developmentProcesses),
  ...Object.values(subscriptionProcesses),
  ...Object.values(subscriptionInvitationsProcesses),
  ...Object.values(thumbnailsProcesses),
  ...Object.values(userProcesses),
  ...Object.values(pdfProcesses),
  ...Object.values(layersProcesses),
];

const registerWithMiddleWare = (middleware: { run: Function }) => {
  for (let saga of SAGAS) {
    middleware.run(saga);
  }
};

export default { registerWithMiddleWare };
