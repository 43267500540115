import React from "react";
import { TooltipType } from "../../sharedComponents/Tooltip/config";

/**
 * @fileoverview This module provides common-use field renderers, in the form of
 * stateless (i.e "functional") react components.
 */

interface ToggleFieldProps {
  label?: string;
  error?: string;
  color?: string;
  toggleElementProps: {
    onChange?:() => void;
    value: any;
  };
  classes?: string;
  tooltipId?: string;
  tooltipType?: TooltipType | null;
}

interface ToggleButtonProps {
  label?: string;
  error?: string;
  color?: string;
  isActive?: any;
  classes?: string;
  onClick(): void;
}

interface InputFieldProps {
  type?: string;
  placeholder?: string,
  label?: string;
  error?: string;
  inputElementProps?: any;
}

interface SelectFieldOption {
  label: string;
  value: any;
  hidden?: boolean;
}

interface SelectFieldProps {
  options: Array<SelectFieldOption>;
  label?: string;
  error?: string;
  selectElementProps?: any;
}

/**
 * Render error message wrapped in a span element if an error message exists.
 */
const renderError = (error) => {
  return error
    ? <span className="error-message">{error}</span>
    : null;
}

/**
 * A CSS class name that can be used to to style elements differently depending
 * on whether or not form errors are present.
 */
const outerWrapperErrorClass = (error) => {
  return error
    ? "with-error"
    : "";
};

/**
 * Return the style object with background set to color
 * if the value of the input is true, otherwise return empty object.
 * @param input input element
 * @param color color to be set for background
 */
const toggleStyle = (input, color) => {
  return input && (input.value || input.checked)
    ? { background: color }
    : {}
}

/**
 * Return the style object with proper styles set to color
 * if the value of active is set to true, otherwise return empty object.
 *
 * @param isActive boolean flag
 * @param color color to be set for background
 */
const toggleButtonStyle = (isActive, color) => {
  return isActive
    ? {
      backgroundColor: color,
      boxShadow: `0px 3px 6px ${color}66`, // 66 for 40% opacity
    }
    : {}
}

/**
 * An <input type="checkbox"> field and accompanying HTML.
 */
const ToggleField = ({ classes, label, error, color, toggleElementProps, tooltipId, tooltipType }: ToggleFieldProps) => {
  return (
    <div className={`toggle-field-wrapper ${outerWrapperErrorClass(error)} ${classes ? classes : ""}`}>
      <div
        className="toggle-container"
        data-for={tooltipId}
        data-tip={tooltipType}
      >
        <label className="toggle-box">
          <input {...toggleElementProps} type="checkbox" checked={Boolean(toggleElementProps.value)} />
          <div
            className="toggle"
            style={toggleStyle(toggleElementProps, color)}
          />
        </label>
        <label className="toggle-label">
          {label}
        </label>
        {renderError(error)}
      </div>
    </div>
  );
}

/**
 * An toggle button field and accompanying HTML.
 */
const ToggleButton = ({ label, error, color, isActive, classes, onClick }: ToggleButtonProps) => {
  return (
    <div className={`toggle-button-wrapper ${outerWrapperErrorClass(error)}`} onClick={onClick}>
      <div className="toggle-container">
        <div
          className={`icon ${classes ? classes : ""} ${isActive ? "active" : ""}`}
          style={toggleButtonStyle(isActive, color)}
        />
        <label className="toggle-label">
          {label}
        </label>
        {renderError(error)}
      </div>
    </div>
  );
}

/**
 * An <input> field and accompanying HTML.
 */
const InputField = ({ label, type, error, placeholder, inputElementProps }: InputFieldProps) => {
  let name = inputElementProps ? inputElementProps.name : null;

  return (
    <div className={`input-field-wrapper ${outerWrapperErrorClass(error)}`}>
      <label className="top-label" htmlFor={name} hidden={type === "hidden" ? true : false} >{label}</label>
      <div>
        <input {...inputElementProps} placeholder={placeholder ? placeholder : label} type={type} />
        {renderError(error)}
      </div>
    </div>
  );
};

/**
 * A <select> field and accompanying HTML.
 */
const SelectField = ({ options, label, error, selectElementProps }: SelectFieldProps) => {
  let name = selectElementProps ? selectElementProps.name : null;

  return (
    <div className={`select-field-wrapper ${outerWrapperErrorClass(error)}`}>
      <label className="top-label" htmlFor={name}>{label}</label>
      <div>
        <select {...selectElementProps}>
          {options.map((option) =>
            <option
              value={option.value}
              key={`${name}-${option.value}`}
              hidden={option.hidden}
            >
              {option.label}
            </option>)}
        </select>
        {renderError(error)}
      </div>
    </div>
  );
};

export {
  InputField,
  ToggleField,
  ToggleButton,
  SelectField,
};
