import React from "react";
import { LayerConfigurationsLayerIds } from "../../../../utils/mapbox/layerHelper";
import LayerToggleBase from "../../LayerToggleBase";
import { TooltipType } from "../../Tooltip/config";

interface OwnProps {
  layerIds: Array<LayerConfigurationsLayerIds>;
  text: string;
  disableForDemo: boolean;
}

type Props = OwnProps;

class LayerButton extends React.PureComponent<Props, {}> {
  render() {
    const { layerIds, text, disableForDemo } = this.props;

    return (
      <LayerToggleBase layerIds={layerIds}>
        {(isActive, onClick) => (
          <div
            data-for="click-left"
            data-tip={disableForDemo ? TooltipType.LayerSelectionPanel : null}
            className={`component--layer-button ${disableForDemo ? "disable-for-demo" : ""} ${isActive ? "active" : ""}`}
            onClick={disableForDemo ? undefined : onClick}
          >
            <div className="text">{text}</div>
          </div>
        )}
      </LayerToggleBase>
    );
  }
}

export default LayerButton;
