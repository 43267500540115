import React from "react";
import developmentAccessors from "../../../../../../../state/development/utils/developmentAccessors";
import PdfContext from "../../../PdfContext";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    minHeight: 17,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#FFFFFF",
    borderStyle: "solid",
    padding: "4px 0",
    maxWidth: 267,
  },
  label: {
    fontFamily: "Roboto",
    fontSize: 10,
    maxLines: 1,
    maxWidth: 200,
  },
  value: {
    fontFamily: "Roboto",
    fontSize: 8.16,
    fontWeight: 700,
    maxWidth: 140,
  },
  valueContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
});

/**
 * Render value.
 */
const renderValue = (value, props, index?) => {
  if (value.includes("\n")) {
    value = value.replace(/(\n\n|\n)/gm, " ");
  }

  return (
    <Text
      style={[
        styles.value,
        {
          fontFamily: props.valueFontFamily || "Roboto",
          fontSize: props.fontSize || 8.16,
          textTransform: props.styleVariation || "none",
        },
      ]}
      key={index}
    >
      {value}
    </Text>
  );
};

/**
 * Render the cell row value.
 */
const renderCellRowValue = (value, props, development) => {
  if (value === undefined || value === null) return null;

  const { formatOptions, formattingMap, unitTarget, unitIsInverse, getter } = props;

  const formattedValue = getter(
    value,
    formatOptions,
    formattingMap,
    unitTarget,
    unitIsInverse,
    developmentAccessors.getParcel(development)
  );
  if (formattedValue === undefined || formattedValue === null) return null;

  return (
    <View style={styles.valueContainer}>
      {Array.isArray(formattedValue)
        ? formattedValue.map((line, index) => renderValue(line, props, index))
        : renderValue(formattedValue, props)}
    </View>
  );
};

const BaseRow = (props) => (
  <PdfContext.Consumer>
    {({ development }) => {
      let value = props.value || developmentAccessors.getInputValue(development, props.variableId);
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      const customNames = developmentAccessors.getCustomNames(development, props.variableId);
      const rowLabel = customNames[0] ? customNames[0].customName : props.text;

      if (props.buildingUse && !toggles[props.buildingUse]) return null;
      const ValueContainer = renderCellRowValue(value, props, development);
      if (!ValueContainer) return null;

      return (
        <View
          style={[
            styles.container,
            {
              borderColor: props.borderColor,
              borderStyle: props.borderStyle,
              borderTop: props.borderTop,
              borderBottom: props.borderBottom,
              minHeight: props.minHeight,
              paddingVertical: props.paddingTopBottom,
            },
          ]}
        >
          <Text style={[styles.label, { fontFamily: props.labelFontFamily, fontSize: props.fontSize }]}>
            {rowLabel}
          </Text>
          {ValueContainer}
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default BaseRow;
