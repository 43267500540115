import * as firebase from "firebase/app";
import "firebase/firestore";
import databaseHelper from "./utils/databaseHelper";

/**
 * Create subscription document for the given user id.
 */
const create = async (userId: string, subscriptionDocument: object) => {
  const subscriptionReference = getSubscriptionsReference(userId);
  await databaseHelper.createDocument(subscriptionReference, subscriptionDocument);
}

/**
 * Read and return subscription document.
 */
const read = async (userId: string): Promise<any> => {
  try {
    const subscriptionReference = getSubscriptionsReference(userId);
    return await databaseHelper.getDocument(subscriptionReference);
  } catch (error) {
    console.warn(error);
    return null;
  }
}

/**
 * Update a subscription document.
 */
const update = async (userId: string, subscriptionDocument: object) => {
  const subscriptionReference = getSubscriptionsReference(userId);
  await databaseHelper.updateDocument(subscriptionReference, subscriptionDocument);
}

/**
 * Return reference to the subscription collection.
 */
const getSubscriptionsReference = (userId) => {
  return firebase.firestore().doc(`subscriptions/${userId}`);
}

export default {
  create,
  read,
  update,
}
