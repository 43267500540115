import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { getCameraFromUrl } from "../../NewProjectMap/Helpers/NewprojectMapHelpers";

const CAMERA_SELECT_PARCEL_ZOOM: number = 16.1;

export const useDeveloperMapConstructor = (
  cameraString: string | undefined,
  userLocation: [number, number] | null | undefined,
  setCameraCenter: React.Dispatch<React.SetStateAction<[number, number]>>,
  setCameraZoom: React.Dispatch<React.SetStateAction<[number]>>
) => {
  const location = useLocation();
  useEffect(() => {
    let cameraCenter = MapStyleProperties.camera.center;
    let cameraZoom = MapStyleProperties.camera.zoom;

    const cameraFromUrl = getCameraFromUrl(cameraString);

    if (cameraFromUrl) {
      cameraCenter = cameraFromUrl.center;
      cameraZoom = cameraFromUrl.zoom;
    } else if (location.state && location.state.pinPosition) {
      cameraCenter = location.state.pinPosition;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    } else if (userLocation) {
      cameraCenter = userLocation;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    }

    setCameraCenter(cameraCenter);
    setCameraZoom([cameraZoom]);
  }, []);
};
