import { createReducer } from "@reduxjs/toolkit";
import { navigationActions } from "state/navigation";
import { developmentActionTypes } from "../../development";
import actions from "./actions";

export interface NewDevelopmentUiState {
  initialValuesPanelIsOpen: boolean;
  developmentIsBeingCreated: boolean;
}

const INITIAL_STATE: NewDevelopmentUiState = {
  initialValuesPanelIsOpen: false,
  developmentIsBeingCreated: false,
}

/**
 * Reset state to default values.
 */
const resetState = (): NewDevelopmentUiState => (INITIAL_STATE);

/**
 * See `openInitialValuesPanel` action creator.
 *
 * Open the initial values panel.
 */
const openInitialValuesPanel = (previousState: NewDevelopmentUiState): NewDevelopmentUiState => ({
  ...previousState,
  initialValuesPanelIsOpen: true,
})

/**
 * See `closeInitialValuesPanel` action creator.
 *
 * Close the initial values panel.
 */
const closeInitialValuesPanel = (previousState: NewDevelopmentUiState): NewDevelopmentUiState => ({
  ...previousState,
  initialValuesPanelIsOpen: false,
})

/**
 * See `developmentActions.createStart` action creator.
 *
 * Display spinner and disable continue button.
 */
const displaySpinnerOnContinueButton = (previousState: NewDevelopmentUiState): NewDevelopmentUiState => ({
  ...previousState,
  developmentIsBeingCreated: true,
})

/**
 * The complete new development UI reducer.
 */
const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(actions.openInitialValuesPanel, openInitialValuesPanel)
    .addCase(actions.closeInitialValuesPanel, closeInitialValuesPanel)
    .addCase(developmentActionTypes.CREATE_START, displaySpinnerOnContinueButton)
    .addCase(navigationActions.leaveNewProjectPage, resetState)
})

export default reducer;
