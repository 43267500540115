import { createAction } from "@reduxjs/toolkit";
import { Feature } from "geojson";

/**
 * Generate the rent features from the census tract features available in the viewport
 */
const generateRentFeaturesStart = createAction<Array<Feature>>("layers/GENERATE_RENT_FEATURES_START");

/**
 * Set the generated rent features in the state.
 */
const generateRentFeaturesSuccess = createAction("layers/GENERATE_RENT_FEATURES_SUCCESS",
  (
    multifamilyRentFeatures: Array<Feature>,
    hotelAverageDailyRateFeatures: Array<Feature>,
    officeRateFeatures: Array<Feature>,
    retailRateFeatures: Array<Feature>,
    industrialRateFeatures: Array<Feature>,
  ) => ({
    payload: {
      multifamilyRentFeatures,
      hotelAverageDailyRateFeatures,
      officeRateFeatures,
      retailRateFeatures,
      industrialRateFeatures,
    }
  })
);

/**
 * Error generating rent features.
 */
const generateRentFeaturesError = createAction("layers/GENERATE_RENT_FEATURES_ERROR");

export default {
  generateRentFeaturesStart,
  generateRentFeaturesSuccess,
  generateRentFeaturesError,
}
