import Firebase from "../../types/Service/Firebase";
import { UserDocument } from "../../types/UserDocument";
import actionTypes from "./actionTypes";

/**
 * Start loading the user.
 */
const loadStart = () => {
  return {
    type: actionTypes.LOAD_START,
  };
}

/**
 * Save user document to the store.
 */
const loadSuccess = (userDocument, imageUrl) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { userDocument, imageUrl },
  };
}

/**
 * Create a user document and save it to the database.
 */
const createStart = (user: Firebase.User, userData: Partial<UserDocument>) => {
  return {
    type: actionTypes.CREATE_START,
    payload: { user, userData },
  };
}

/**
 * Save user document to the store.
 */
const createSuccess = (userDocument) => {
  return {
    type: actionTypes.CREATE_SUCCESS,
    payload: { userDocument },
  };
}

/**
 * Get user geolocation.
 */
const getLocationStart = () => {
  return {
    type: actionTypes.GET_LOCATION_START,
  };
}

/**
 * Success fetch of user's geolocation.
 */
const getLocationSuccess = (location: [any, any]) => {
  return {
    type: actionTypes.GET_LOCATION_SUCCESS,
    payload: { location },
  };
}

/**
 * Error fetching user's location.
 */
const getLocationError = () => {
  return {
    type: actionTypes.GET_LOCATION_ERROR,
  };
}

/**
 * Set user's isOnboarded flag to true.
 */
const setUserIsOnboarded = () => {
  return {
    type: actionTypes.SET_IS_ONBOARDED,
  };
}

/**
 * Upload image to the database.
 */
const uploadImageStart = (userId: string, imageFile: File) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_START,
    payload: { userId, imageFile },
  }
}

/**
 * Upload image success.
 */
const uploadImageSuccess = (imageUrl: string) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_SUCCESS,
    payload: { imageUrl },
  }
}

/**
 * Update a user document to the database.
 */
const updateStart = (userId: string, userData: Partial<UserDocument>) => {
  return {
    type: actionTypes.UPDATE_START,
    payload: { userId, userData },
  };
}

/**
 * Save user document to the store.
 */
const updateSuccess = (userDocument) => {
  return {
    type: actionTypes.UPDATE_SUCCESS,
    payload: { userDocument },
  };
}

/**
 * User email has been verified.
 */
const emailVerified = () => {
  return {
    type: actionTypes.EMAIL_VERIFIED,
    payload: {},
  };
}

export default {
  loadStart,
  loadSuccess,
  createStart,
  createSuccess,
  getLocationStart,
  getLocationSuccess,
  getLocationError,
  setUserIsOnboarded,
  uploadImageStart,
  uploadImageSuccess,
  updateStart,
  updateSuccess,
  emailVerified,
}
