import { Action } from "@reduxjs/toolkit";
import { Feature, Geometry } from "geojson";
import { call, put, takeEvery } from "redux-saga/effects";
import { BuildingUse } from "../../types/BuildingUse";
import { jsonDeepCopy } from "../../utils/deepCopy";
import { MapStyleProperties } from "../../utils/mapbox/mapStyleProperties";
import { RentFeatureFieldId } from "../../utils/mapbox/mapStyleProperties/mapStyleProperties";
import rentModel, { RentModelFeatures } from "../../utils/presets/generators/generatePresetsForBuildables/utils/rentModel";
import roundToDecimal from "../../utils/roundToDecimal";
import actions from "./actions";

const MONTHS_PER_YEAR = 12;
const DAYS_PER_YEAR = 365;
const AVERAGE_HOTEL_ROOM_AREA_SQ_FT = 250;

interface RentFeatureProperties {
  [RentFeatureFieldId.Value]: number | null;
}

type RentFeature = Feature<Geometry, RentFeatureProperties> & { layer?: object };

interface FeatureIndexTuple {
  feature: RentFeature;
  index: number;
}

/**
 * Copy feature and initialize properties.
 */
const buildRentFeature = (feature: Feature): RentFeature => {
  let rentFeature: RentFeature = {
    type: "Feature",
    geometry: jsonDeepCopy(feature.geometry),
    properties: {
      value: null
    }
  }

  return rentFeature;
}

/**
 * Generate the rent feature layers from the census tract layer.
 */
function* generateRentFeaturesStart(action: Action) {
  try {
    if (actions.generateRentFeaturesStart.match(action)) {
      let multifamilyFeatureTuples: Array<FeatureIndexTuple> = [];
      let hotelFeatureTuples: Array<FeatureIndexTuple> = [];
      let retailFeatureTuples: Array<FeatureIndexTuple> = [];
      let industrialFeatureTuples: Array<FeatureIndexTuple> = [];
      let officeFeatureTuples: Array<FeatureIndexTuple> = [];

      let modelInputFeatures: Array<RentModelFeatures> = [];
      for (const demographicsFeature of action.payload) {
        if (!demographicsFeature || !demographicsFeature.properties) continue;

        const medianIncome = demographicsFeature.properties[MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotal];
        const population = demographicsFeature.properties[MapStyleProperties.RawDemographicsFieldId.PopulationDensity];
        const medianGrossRent = demographicsFeature.properties[MapStyleProperties.RawDemographicsFieldId.GrossMedianRent];
        const employmentPerPopulation = demographicsFeature.properties[MapStyleProperties.RawDemographicsFieldId.EmploymentPerPopulationRatio];
        const market = demographicsFeature.properties[MapStyleProperties.RawDemographicsFieldId.Market];

        // Multifamily Feature
        const multifamilyInputFeature = rentModel.buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse: BuildingUse.Multifamily });
        if (multifamilyInputFeature) {
          const index = modelInputFeatures.push(multifamilyInputFeature) - 1;
          multifamilyFeatureTuples.push({
            feature: buildRentFeature(demographicsFeature),
            index: index,
          })
        }

        // Hotel Feature
        const hotelInputFeature = rentModel.buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse: BuildingUse.Hotel });
        if (hotelInputFeature) {
          const index = modelInputFeatures.push(hotelInputFeature) - 1;
          hotelFeatureTuples.push({
            feature: buildRentFeature(demographicsFeature),
            index: index,
          })
        }

        // Retail Feature
        const retailInputFeature = rentModel.buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse: BuildingUse.Retail });
        if (retailInputFeature) {
          const index = modelInputFeatures.push(retailInputFeature) - 1;
          retailFeatureTuples.push({
            feature: buildRentFeature(demographicsFeature),
            index: index,
          })
        }

        // Office Feature
        const officeInputFeature = rentModel.buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse: BuildingUse.Office });
        if (officeInputFeature) {
          const index = modelInputFeatures.push(officeInputFeature) - 1;
          officeFeatureTuples.push({
            feature: buildRentFeature(demographicsFeature),
            index: index,
          })
        }

        // Industrial Feature
        const industrialInputFeature = rentModel.buildFeature({ medianIncome, population, medianGrossRent, employmentPerPopulation, market, buildingUse: BuildingUse.Industrial });
        if (industrialInputFeature) {
          const index = modelInputFeatures.push(industrialInputFeature) - 1;
          industrialFeatureTuples.push({
            feature: buildRentFeature(demographicsFeature),
            index: index,
          })
        }
      }

      if (modelInputFeatures.length === 0) {
        yield put(actions.generateRentFeaturesSuccess([], [], [], [], [],));
        return;
      }

      const predictionArray = yield call(rentModel.predict, modelInputFeatures);

      // Process multifamily results and combine into a single array of features.
      const multifamilyRentFeatures: Array<RentFeature> = multifamilyFeatureTuples.map((multifamilyTuple) => {
        let feature = multifamilyTuple.feature;
        feature.properties[RentFeatureFieldId.Value] = roundToDecimal(predictionArray[multifamilyTuple.index] / MONTHS_PER_YEAR, 2);
        return feature;
      });

      // Process hotel results and combine into a single array of features.
      const hotelAverageDailyRateFeatures: Array<RentFeature> = hotelFeatureTuples.map((hotelTuple) => {
        let feature = hotelTuple.feature;
        feature.properties[RentFeatureFieldId.Value] = roundToDecimal(predictionArray[hotelTuple.index] * AVERAGE_HOTEL_ROOM_AREA_SQ_FT / DAYS_PER_YEAR, 2);
        return feature;
      });

      // Process office results and combine into a single array of features.
      const officeRateFeatures: Array<RentFeature> = officeFeatureTuples.map((officeTuple) => {
        let feature = officeTuple.feature;
        feature.properties[RentFeatureFieldId.Value] = roundToDecimal(predictionArray[officeTuple.index], 2);
        return feature;
      });

      // Process retail results and combine into a single array of features.
      const retailRateFeatures: Array<RentFeature> = retailFeatureTuples.map((retailTuple) => {
        let feature = retailTuple.feature;
        feature.properties[RentFeatureFieldId.Value] = roundToDecimal(predictionArray[retailTuple.index], 2);
        return feature;
      });

      // Process industrial results and combine into a single array of features.
      const industrialRateFeatures: Array<RentFeature> = industrialFeatureTuples.map((industrialTuple) => {
        let feature = industrialTuple.feature;
        feature.properties[RentFeatureFieldId.Value] = roundToDecimal(predictionArray[industrialTuple.index], 2);
        return feature;
      });

      yield put(actions.generateRentFeaturesSuccess(
        multifamilyRentFeatures,
        hotelAverageDailyRateFeatures,
        officeRateFeatures,
        retailRateFeatures,
        industrialRateFeatures,
      ));
    }
  } catch (error) {
    console.warn(error);
    yield put(actions.generateRentFeaturesError)
  }
}

/**
 * Watcher for the `generateRentFeaturesStart` action.
 */
function* watchGenerateRentLayersStart() {
  yield takeEvery(actions.generateRentFeaturesStart.toString(), generateRentFeaturesStart);
}

export default {
  watchResetState: watchGenerateRentLayersStart
}
