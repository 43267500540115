import React from "react";
import { connect } from "react-redux";
import getFormattedValuesForPresentation from "utils/parcel/parcelDataPresentationHelper";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import valueFormatter from "../../../../../utils/valueFormatter";
import Cell from "../../../Cell";
import CellRow from "../../../CellRow";
import CellText from "../../../CellText";

type OwnProps = {
  parcelFeature: any;
};

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render unit density ratio section.
 */
class Setbacks extends React.PureComponent<Props, {}> {
  renderSetbackSection = (parcelFeature, index, isAssembly = false) => {
    const { isNumeric } = valueFormatter;
    const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), this.props.tier);
    const address = parcelAccessors.getAddress(parcelFeature) || `Property ${index + 1}`;

    const setbacks = {
      primary: parcelAccessors.getSetbacksPrimary(parcelFeature),
      sideStreet: parcelAccessors.getSetbacksSideStreet(parcelFeature),
      sideInterior: parcelAccessors.getSetbacksSideInterior(parcelFeature),
      rear: parcelAccessors.getSetbacksRear(parcelFeature),
      primaryAboveFirstFloor: parcelAccessors.getSetbacksPrimaryAboveFirstFloor(parcelFeature),
      sideStreetAboveFirstFloor: parcelAccessors.getSetbacksSideStreetAboveFirstFloor(parcelFeature),
      interiorSideAboveFirstFloor: parcelAccessors.getSetbacksSideInteriorAboveFirstFloor(parcelFeature),
      rearAboveFirstFloor: parcelAccessors.getSetbacksRearAboveFirstFloor(parcelFeature),
    };

    const formattedPrimarySetback = getFormattedValuesForPresentation(setbacks.primary);
    const formattedSideStreetSetback = getFormattedValuesForPresentation(setbacks.sideStreet);
    const formattedRearSetback = getFormattedValuesForPresentation(setbacks.rear);
    const formattedSideInteriorSetback = getFormattedValuesForPresentation(setbacks.sideInterior);

    if (Object.values(setbacks).every((type) => type === null)) return null;

    const assemblyLabel = isAssembly ? ` for ${address}` : "";

    return (
      <table key={`table_${index}`}>
        <thead>
          <tr>
            <th colSpan={2}>{`Setbacks${assemblyLabel}`}</th>
          </tr>
        </thead>
        <tbody>
          {setbacks.primary ? (
            <CellRow classes="sub-section">
              <CellText text="• Front" />
              <Cell
                getter={valueGetters.generic}
                value={formattedPrimarySetback}
                formatOptions={{
                  type: isNumeric(setbacks.primary) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: " FT",
                  hidden: !userHasAccess,
                }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.sideStreet ? (
            <CellRow classes="sub-section">
              <CellText text="• Secondary Front" />
              <Cell
                getter={valueGetters.generic}
                value={formattedSideStreetSetback}
                formatOptions={{
                  type: isNumeric(setbacks.sideStreet) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: " FT",
                  hidden: !userHasAccess,
                }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.sideInterior ? (
            <CellRow classes="sub-section">
              <CellText text="• Side" />
              <Cell
                getter={valueGetters.generic}
                value={formattedSideInteriorSetback}
                formatOptions={{
                  type: isNumeric(setbacks.sideInterior) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: " FT",
                  hidden: !userHasAccess,
                }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.rear ? (
            <CellRow classes="sub-section">
              <CellText text="• Rear" />
              <Cell
                getter={valueGetters.generic}
                value={formattedRearSetback}
                formatOptions={{
                  type: isNumeric(setbacks.rear) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: " FT",
                  hidden: !userHasAccess,
                }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.primaryAboveFirstFloor ? (
            <CellRow classes="sub-section">
              <CellText text="• Front Above First Floor" />
              <Cell
                getter={valueGetters.generic}
                value={setbacks.primaryAboveFirstFloor}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.sideStreetAboveFirstFloor ? (
            <CellRow classes="sub-section">
              <CellText text="• Secondary Front Above First Floor" />
              <Cell
                getter={valueGetters.generic}
                value={setbacks.sideStreetAboveFirstFloor}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.interiorSideAboveFirstFloor ? (
            <CellRow classes="sub-section">
              <CellText text="• Side Above First Floor" />
              <Cell
                getter={valueGetters.generic}
                value={setbacks.interiorSideAboveFirstFloor}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {setbacks.rearAboveFirstFloor ? (
            <CellRow classes="sub-section">
              <CellText text="• Rear Above First Floor" />
              <Cell
                getter={valueGetters.generic}
                value={setbacks.rearAboveFirstFloor}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
        </tbody>
      </table>
    );
  };

  render() {
    const { parcelFeature } = this.props;

    return parcelAccessors.getIsAnAssembly(parcelFeature)
      ? parcelAccessors
          .getAssemblyParcels(parcelFeature)
          .map((parcel, index) => this.renderSetbackSection(parcel, index, true))
      : this.renderSetbackSection(parcelFeature, 0);
  }
}

export default connect(mapStateToProps)(Setbacks);
