import * as firebase from "firebase/app";
import { Development } from "../../../../../types/Development/Development";
import { UserDocument } from "../../../../../types/UserDocument";
import databaseHelper from "../../databaseHelper";

const MISSING_RETURN_ON_COST = -42; // Flag indicating that the project still doesn't have a return on cost value.
const MISSING_TIME_MODIFIED = -1; // Flag indicating that the project still doesn't have a timeModified value.

/**
 * Add missing data to project objects in the user document.
 * NOTE: This file is also responsible for keeping the object `projects` from the user document free
 *       of possible `null` in both keys and values.
 */
const updateProjectsMissingData = async (userData: UserDocument, userId: string) => {
  const user = firebase.auth().currentUser;
  if (!user || user.uid !== userId) return false;

  let userModified = false;
  let projectData: Development | undefined;
  for (let projectId of Object.keys(userData.projects)) {
    // Strip null projects from user document `projects` object.
    if (projectId === "null" || !userData.projects[projectId]) {
      delete userData.projects[projectId];
      userModified = true;
      continue;
    }

    // Add `saleReturnOnInvestmentForBackOfEnvelope` as  `saleReturnOnCost`.
    if (userData.projects[projectId].saleReturnOnCost === MISSING_RETURN_ON_COST) {
      const projectReference = firebase.firestore().doc(`projects/${projectId}`);
      projectData = await databaseHelper.getDocument(projectReference) as Development;
      userData.projects[projectId].saleReturnOnCost = projectData.values.saleReturnOnInvestmentForBackOfEnvelope || 0;
      userModified = true;
    }

    // Add `incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope` as  `leaseUsesReturnOnCost`.
    if (userData.projects[projectId].leaseUsesReturnOnCost === MISSING_RETURN_ON_COST) {
      const projectReference = firebase.firestore().doc(`projects/${projectId}`);
      if (!projectData) projectData = await databaseHelper.getDocument(projectReference) as Development;
      userData.projects[projectId].leaseUsesReturnOnCost = projectData.values.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope || 0;
      userModified = true;
    }

    // Add `isForSale` flag if not available.
    if (userData.projects[projectId].isForSale === null) {
      const projectReference = firebase.firestore().doc(`projects/${projectId}`);
      if (!projectData) projectData = await databaseHelper.getDocument(projectReference) as Development;
      userData.projects[projectId].isForSale = projectData.isForSale;
      userModified = true;
    }

    // Add timeModified value to entries in user projects list.
    if (userData.projects[projectId].timeModified === MISSING_TIME_MODIFIED) {
      const projectReference = firebase.firestore().doc(`projects/${projectId}`);
      if (!projectData) projectData = await databaseHelper.getDocument(projectReference) as Development;
      userData.projects[projectId].timeModified = projectData.timeModified || null;
      userModified = true;
    }
  }

  return userModified;
}

export default updateProjectsMissingData;
