import actionTypes from "./actionTypes";
import { Accordions } from "./reducers";

/**
 * Set the accordion is open flag.
 */
const setStaticAccordionIsOpen = (accordion: Accordions, value: boolean) => {
  return {
    type: actionTypes.SET_STATIC_ACCORDION_IS_OPEN,
    payload: {
      accordion,
      value,
    },
  }
}

/**
 * Set listing accordion is open flag.
 */
const setListingAccordionIsOpen = (accordionId: string, value: boolean) => {
  return {
    type: actionTypes.SET_LISTING_ACCORDION_IS_OPEN,
    payload: {
      accordionId,
      value,
    },
  }
}

/**
 * Set boe accordion is open flag.
 */
const setBoeAccordionIsOpen = (accordionId: string, value: boolean) => {
  return {
    type: actionTypes.SET_BOE_ACCORDION_IS_OPEN,
    payload: {
      accordionId,
      value,
    },
  }
}

/**
 * Reset the accordion section state.
 */
const reset = () => {
  return {
    type: actionTypes.RESET,
  }
}

export default {
  setStaticAccordionIsOpen,
  setListingAccordionIsOpen,
  setBoeAccordionIsOpen,
  reset,
}
