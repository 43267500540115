import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pdfActions, pdfSelectors } from "../../../../../../state/pdf";
import DragAndDrop from "./DragAndDrop";
import Pdf from "../../../../../../types/Pdf";
import BusySpinner from "../../../../../sharedComponents/BusySpinner";

interface Props {
  classPrefix: string;
  labelText: string;
  imageType: Pdf.ImageType.Logo | Pdf.ImageType.Cover;
}

const ImageUpload: React.FC<Props> = ({ classPrefix, labelText, imageType }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const image = useSelector((state: any) => pdfSelectors.getCoverPageImage(state, imageType));

  const setCoverImage = (file?: File) => {
    if (!projectId) return;
    if (file) {
      dispatch(pdfActions.uploadCoverPageImageStart(projectId, file, imageType));
    } else {
      dispatch(pdfActions.deleteCoverPageImageStart(projectId, imageType));
    }
  };

  const clearImage = () => {
    setCoverImage();
  };

  const renderFileInformation = () => {
    let fileName;
    if (image) {
      fileName = image.fileName;
      if (fileName && fileName.length > 30) {
        fileName = fileName.slice(0, 30) + "...";
      }
    }
    if (!image) return <DragAndDrop handleUpload={setCoverImage} />;
    if (image.isLoading) return <BusySpinner />;

    return (
      <p className="file-name">
        {image ? fileName : ""} <span className="file-remove" onClick={clearImage} />
      </p>
    );
  };

  return (
    <div className="component--image-upload">
      <div className="label-container">
        <div className={`${classPrefix}-icon icon`} />
        <label>{labelText}</label>
      </div>
      <div className="drag-and-drop-container">
        <label className="title">{image ? "Uploaded Image" : "Upload"}</label>
        {renderFileInformation()}
      </div>
    </div>
  );
};

export default ImageUpload;
