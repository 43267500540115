import Unit from "types/Unit";
import Format from "types/Format";
import unitConversions from "utils/unitConversions";
import valueFormatter from "utils/valueFormatter";
import { ParcelProperty } from "utils/parcel/ParcelProperty";
import downloadBlobAsFile from "utils/downloadBlobAsFile";
import formatAllowedUsesForCsv from "./formatAllowedUsesForCsv";
import Papa from "papaparse";

interface CsvColumn {
  columnHeader: string;
  parcelProperty: ParcelProperty;
  formatter: (any) => string;
}

interface CsvData {
  [key: string]: string | number;
}

const CSV_FILENAME = "Deepblocks Parcel Search Results.csv";

const CSV_COLUMNS: Array<CsvColumn> = [
  {
    columnHeader: "Property Id",
    parcelProperty: ParcelProperty.Id,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Address",
    parcelProperty: ParcelProperty.Address,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "City",
    parcelProperty: ParcelProperty.City,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "State",
    parcelProperty: ParcelProperty.State,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Zip Code",
    parcelProperty: ParcelProperty.ZipCode,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Opportunity Zone",
    parcelProperty: ParcelProperty.IsInOpportunityZone,
    formatter: (value) => valueFormatter.format(value ? "YES" : "NO", { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Folio Number",
    parcelProperty: ParcelProperty.ParcelId,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Market Value",
    parcelProperty: ParcelProperty.PurchasePrice,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    columnHeader: "Year Built",
    parcelProperty: ParcelProperty.ExistingStructureYearBuilt,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Existing Land Use",
    parcelProperty: ParcelProperty.LandUseCode,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Lot Size (SF)",
    parcelProperty: ParcelProperty.AreaPublished,
    formatter: (value) =>
      valueFormatter.format(unitConversions.convertFromBase(value, Unit.Type.SquareFeet), {
        type: Format.Type.Number,
        decimalPlaces: 0,
      }),
  },
  {
    columnHeader: "Existing Structure Regrid (SF)",
    parcelProperty: ParcelProperty.ExistingStructureArea,
    formatter: (value) =>
      valueFormatter.format(unitConversions.convertFromBase(value, Unit.Type.SquareFeet), {
        type: Format.Type.Number,
        decimalPlaces: 0,
      }),
  },
  {
    columnHeader: "Existing Structure Open Source (SF)",
    parcelProperty: ParcelProperty.ExistingStructureAreaOpenSource,
    formatter: (value) =>
      valueFormatter.format(unitConversions.convertFromBase(value, Unit.Type.SquareFeet), {
        type: Format.Type.Number,
        decimalPlaces: 0,
      }),
  },
  {
    columnHeader: "Existing Resi Units",
    parcelProperty: ParcelProperty.NumberOfResidentialUnits,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number, decimalPlaces: 0 }),
  },
  {
    columnHeader: "Existing Buildings",
    parcelProperty: ParcelProperty.NumberOfBuildings,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number, decimalPlaces: 0 }),
  },
  {
    columnHeader: "Appraised Land Value",
    parcelProperty: ParcelProperty.LandValue,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    columnHeader: "Last Sale Price",
    parcelProperty: ParcelProperty.SalePrice,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    columnHeader: "Last Sale Year",
    parcelProperty: ParcelProperty.SaleYear,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Owner Name",
    parcelProperty: ParcelProperty.OwnerName,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Owner Address",
    parcelProperty: ParcelProperty.OwnerAddress,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Owner City",
    parcelProperty: ParcelProperty.OwnerCity,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Owner State",
    parcelProperty: ParcelProperty.OwnerState,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Owner Zip Code",
    parcelProperty: ParcelProperty.OwnerZipCode,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.PlainText }),
  },
  {
    columnHeader: "Approximate Buildable Area (SF)",
    parcelProperty: ParcelProperty.BuildableAreaQuery,
    formatter: (value) =>
      valueFormatter.format(unitConversions.convertFromBase(value, Unit.Type.SquareFeet), {
        type: Format.Type.Number,
        decimalPlaces: 0,
      }),
  },
  {
    columnHeader: "Allowed Uses",
    parcelProperty: ParcelProperty.AllowedDetailedUses,
    // NOTE: The following formatter duplicates some of the work performed by the `parcelAccessors`
    // getter for parsing this field, which has a complicated format. Ideally, this duplication
    // would not be necessary - we would simply parse this data using the accessor and then format
    // the parsed result. This is not an option at this time since it would require some
    // refactoring of both that module and this one to provide a consistent interface that can be
    // used by every CSV column. Currently, this module does not equiped to use `parcelAccessors`
    // at all, and `parcelAccessors` does not have all the accessors this module would need.
    formatter: formatAllowedUsesForCsv,
  },
  {
    columnHeader: "Estimated Units Allowed",
    parcelProperty: ParcelProperty.NumberOfUnitsAllowedQuery,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number, decimalPlaces: 0 }),
  },
  {
    columnHeader: "Median Income",
    parcelProperty: ParcelProperty.MedianIncomeTotal,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    columnHeader: "Median Rent",
    parcelProperty: ParcelProperty.GrossMedianRent,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    columnHeader: "Census Tract Population",
    parcelProperty: ParcelProperty.PopulationDensity,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number, decimalPlaces: 0 }),
  },
];

/**
 * Extracts and formats the predetermined set of values from the given parcel properties
 * and returns them in an object keyed by human-readable header.
 */
const getFormattedParcelProperties = (parcelProperties, columns: Set<string>) => {
  const result = {};
  for (const { columnHeader, parcelProperty, formatter } of CSV_COLUMNS) {
    result[columnHeader] = formatter(parcelProperties[parcelProperty]);
    if (result[columnHeader]) {
      columns.add(columnHeader);
    }
  }
  return result;
};

function removeCharacters(arr: CsvData[]): CsvData[] {
  return arr.map((obj: CsvData) => {
    const newObj: CsvData = {};
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        newObj[key] = (obj[key] as string).replace(/[@]/g, "");
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}

/**
 * Generates a CSV string for the given set of parcels and triggers a dialog box to download it.
 *
 * NOTE: The CSV string is generated synchronously and on the fly. This means that when this
 * function is called, it blocks the main program flow until the complete file content has been
 * generated in memory.
 */
const downloadSearchResultsCsv = (parcels) => {
  const columns = new Set<string>();
  const parcelProperties = parcels.map((parcel) => getFormattedParcelProperties(parcel.properties, columns));

  // Remove the data delimiter characters from the CSV data
  const formattedParcelProperties = removeCharacters(parcelProperties);

  const csvString = Papa.unparse(formattedParcelProperties, { header: true, columns: Array.from(columns) });

  const blob = new Blob([csvString], { type: "text/csv" });
  downloadBlobAsFile(blob, CSV_FILENAME);
};

export default downloadSearchResultsCsv;
