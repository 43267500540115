import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";
import { VariableId } from "../../../../../../types/VariableId";
import unitConversions from "../../../../../../utils/unitConversions";
import valueFormatter from "../../../../../../utils/valueFormatter";

const BIG_ROC_NUMBERS_FORMAT = {
  type: Format.Type.Percentage,
  decimalPlaces: 0,
};

const FORMATTED_ROC_MAX_VALUE = 9.9;

interface OwnProps {
  variableId: VariableId;
  unitTarget: Unit.Type;
  text: string;
  formatOptions: Format.Options;
  styleVariation?: string;
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    value: developmentSelectors.getValue(state, ownProps.variableId),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class KpiSection extends React.PureComponent<Props, {}> {
  render() {
    const {
      value,
      text,
      formatOptions,
      unitTarget,
      styleVariation,
      variableId,
    } = this.props;
    const convertedValue = unitTarget
      ? unitConversions.convertFromBase(value, unitTarget)
      : value;

    const classes = styleVariation ? styleVariation : "";

    // There is a corner case when the ROC value is bigger than 1000%,
    // it will break the UI for the KPI section.
    // In that case we will format the value to have no decimal points,
    // instead of the default value for decimal points = 2.

    // Check if the value is a ROC value
    const isRocValue =
      variableId === VariableId.SaleReturnOnInvestmentForBackOfEnvelope ||
      variableId ===
        VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope;

    // Check if the ROC value is bigger than the max value
    const isRocValueBiggerThanMax =
      isRocValue && convertedValue > FORMATTED_ROC_MAX_VALUE;

    // Format the value
    const formattedValue = isRocValueBiggerThanMax
      ? valueFormatter.format(convertedValue, BIG_ROC_NUMBERS_FORMAT)
      : valueFormatter.format(convertedValue, formatOptions);

    return (
      <div className={`component--kpi-section ${classes}`}>
        <div className="value">{formattedValue}</div>
        <div className="text">{text}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(KpiSection);
