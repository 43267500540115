import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsSelectors } from "../../../state/subscriptionInvitations";
import { subscriptionActions, subscriptionSelectors } from "../../../state/subscription";
import wrapComponentWithPopup, { PopupProps } from "../wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../sharedComponents/WithPanelHeader";
import authentication from "../../../utils/authentication";
import BusySpinner from "../BusySpinner";
import { subscriptionManagementSelectors } from "../../../state/ui/subscriptionManagement";

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    subscriptionId: subscriptionSelectors.getId(state),
    userIdToBeCancelled: subscriptionSelectors.getUserIdToBeCancelled(state),
    subscriptionIsBeingCancelled: subscriptionManagementSelectors.getSubscriptionIsBeingCancelled(state),
  }
}

const mapDispatchToProps = {
  cancelSubscription: subscriptionActions.cancelStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & DispatchProps & StateProps;

class CancelSubscriptionPopup extends React.PureComponent<Props, {}>  {
  /**
   * Cancel Subscription.
   */
  cancelSubscription = () => {
    const user = authentication.getCurrentUser();
    const { userIdToBeCancelled, managedSubscriptions, subscriptionId, cancelSubscription } = this.props;
    if (!user || !userIdToBeCancelled) return;

    if (user.uid === userIdToBeCancelled) {
      if (!subscriptionId) return;
      cancelSubscription(subscriptionId);
    } else {
      if (!managedSubscriptions || !managedSubscriptions[userIdToBeCancelled].subscriptionId) return;
      cancelSubscription(managedSubscriptions[userIdToBeCancelled].subscriptionId)
    }
  }

  render() {
    const user = authentication.getCurrentUser();
    const { userIdToBeCancelled, managedSubscriptions, subscriptionIsBeingCancelled } = this.props;
    if (!user || !userIdToBeCancelled) return null;

    return (
      <WithPanelHeader title="Cancel Subscription" hideControls>
        <div className="component--cancel-subscription-popup">
          <div className="text">
            Are you sure you want to cancel
            <span>{userIdToBeCancelled === user.uid ? " your " : ` ${managedSubscriptions && managedSubscriptions[userIdToBeCancelled].name} `}</span>
            subscription?
          </div>

          <div className="buttons-container">
            <button
              className="close-button"
              onClick={this.props.closePopup}
            >
              Close
            </button>
            <button
              className="cancel"
              onClick={this.cancelSubscription}
            >
              {subscriptionIsBeingCancelled
                ? <BusySpinner classes="white-spinner" />
                : "Cancel Subscription"
              }
            </button>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CancelSubscriptionPopup)
);
