import produce from "immer";
import { Development } from "../../types/Development/Development";
import { VariableId } from "../../types/VariableId";
import { pdfActionTypes } from "../pdf";
import { userActionTypes } from "../user";
import actionTypes from "./actionTypes";
import developmentAccessors from "./utils/developmentAccessors";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";

export interface DevelopmentState {
  developmentDocument: Development;
  isEdited: boolean;
  isReady: boolean;
  id: string | null;
  accessRestricted: boolean;
}

const INITIAL_STATE: DevelopmentState = {
  developmentDocument: {
    values: {},
    floorsWithSetbacks: [0],
    selectedSetbackFloor: 0,
    selectedSetbackFloorIndex: 0,
  } as Development,
  isEdited: false,
  isReady: false,
  id: null,
  accessRestricted: false,
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: DevelopmentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_START:
    case actionTypes.CREATE_START:
    case actionTypes.RESET_STATE_CONTINUE:
      return INITIAL_STATE;
    case actionTypes.CREATE_SUCCESS:
    case actionTypes.LOAD_SUCCESS:
      return initialize(action.payload);
    case actionTypes.LOAD_FAILURE:
      return setAccessRestricted(previousState, action.payload);
    case actionTypes.SAVE_SUCCESS:
      return projectSaved(previousState, action.payload);
    case actionTypes.RE_CALCULATE:
      return recalculate(previousState, action.payload);
    case actionTypes.SET_NAME:
      return setName(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT:
      return setRangedInput(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT_MINIMUM:
      return setRangedInputMinimum(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT_MAXIMUM:
      return setRangedInputMaximum(previousState, action.payload);
    case actionTypes.SET_SETBACK_TYPE:
      return setSetbackType(previousState, action.payload);
    case actionTypes.TOGGLE_BOOLEAN_INPUT:
      return toggleBooleanInput(previousState, action.payload);
    case actionTypes.SET_CAMERA:
      return setCamera(previousState, action.payload);
    case actionTypes.SET_UNIT_SYSTEM:
      return setUnitSystem(previousState, action.payload);
    case actionTypes.KEEP_PROJECT:
      return keepProject(previousState, action.payload);
    case actionTypes.ADD_SETBACK_FLOOR:
      return addSetbackFloor(previousState, action.payload);
    case actionTypes.REMOVE_SETBACK_FLOOR:
      return removeSetbackFloor(previousState, action.payload);
    case actionTypes.RESET_SELECTED_SETBACK_FLOOR:
      return resetSelectedSetbackFloor(previousState, action.payload);
    case pdfActionTypes.SET_TITLE:
    case pdfActionTypes.SET_SUMMARY:
    case pdfActionTypes.SET_TO_CONTACTS_DETAILS:
    case pdfActionTypes.SET_FROM_CONTACTS_DETAILS:
    case pdfActionTypes.SET_COLOR_PALETTE:
    case pdfActionTypes.SET_ADDRESS:
      return setProjectIsEdited(previousState, action.payload);
    case actionTypes.SWITCH_DEVELOPMENT:
      return switchDevelopment(previousState, action.payload);
    case actionTypes.SET_PROJECT_IS_FOR_SALE:
      return setProjectIsForSale(previousState, action.payload);
    case actionTypes.SET_PROJECT_IS_READY:
    case userActionTypes.CREATE_START:
      return onUserCreate(previousState, action.payload);
    case actionTypes.SET_FLOOR_PLAN:
      return setFloorPlan(previousState, action.payload);
    case actionTypes.SET_CUSTOM_SLIDER_NAMES:
      return setCustomSliderName(previousState, action.payload);
    default:
      return previousState;
  }
};

/**
 * See `initialize` action creator.
 *
 * Modify the development object to initialize it with the content of the payload.
 */
const initialize = (payload): DevelopmentState => {
  let initialState: DevelopmentState = {
    ...INITIAL_STATE,
    developmentDocument: {
      ...INITIAL_STATE.developmentDocument,
      ...payload.development,
    },
  };

  developmentAccessors.ensureRequiredInputs(initialState.developmentDocument);
  developmentAccessors.update(initialState.developmentDocument);
  developmentAccessors.updateFloorsWithSetback(initialState.developmentDocument);

  return {
    ...initialState,
    id: payload.projectId,
    isReady: true,
  };
};

/**
 * Set project is ready flag.
 */
const onUserCreate = (previousState: DevelopmentState, payload): DevelopmentState => {
  const isReady = payload.value ? payload.value : false;

  return {
    ...previousState,
    isReady: isReady,
  };
};

/**
 * See `switchDevelopment` action creator.
 *
 * Temporary set ready flag to false.
 */
const switchDevelopment = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    isReady: false,
  };
};

/**
 * See `initialize` action creator.
 *
 * Modify the development object to initialize it with the content of the payload.
 */
const setAccessRestricted = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    accessRestricted: true,
    isReady: true,
  };
};

/**
 * See `recalculate` action creator.
 *
 * Modify the development object to re-initialize it with the content of the payload.
 */
const recalculate = (previousState: DevelopmentState, payload): DevelopmentState => {
  let newDevelopment = payload.newDevelopment;
  developmentAccessors.ensureRequiredInputs(newDevelopment);
  developmentAccessors.update(newDevelopment);

  return {
    ...previousState,
    developmentDocument: newDevelopment,
    isEdited: true,
  };
};

/**
 * See `resetSelectedSetbackFloor` action creator.
 */
const resetSelectedSetbackFloor = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    developmentDocument: {
      ...previousState.developmentDocument,
      selectedSetbackFloor: INITIAL_STATE.developmentDocument.selectedSetbackFloor,
      selectedSetbackFloorIndex: INITIAL_STATE.developmentDocument.selectedSetbackFloorIndex,
    },
  };
};

/**
 * Set project is edited flag.
 */
const setProjectIsEdited = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    isEdited: true,
  };
};

/**
 * See `projectSaved` action creator.
 *
 * Update project edited flag.
 */
const projectSaved = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    isEdited: false,
  };
};

/**
 * See `keepProject` action creator.
 *
 * Update isFromShared flag.
 */
const keepProject = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    developmentDocument: {
      ...previousState.developmentDocument,
      isFromShared: false,
    },
    isEdited: true,
  };
};

/**
 * See `setRangedInput` action creator.
 *
 * Modify the development object by fully updating it using the new input
 * value.
 *
 */
const setRangedInput = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    setInputValue(draftDevelopment.developmentDocument, payload.inputId, payload.inputValue, payload.index);
    draftDevelopment.isEdited = true;
  });
};

/**
 * Modify the development object by setting the input value to the target if
 * it is on range, otherwise it sets it to the closest bound.
 */
const setInputValue = (development: Development, inputId: VariableId, targetValue, index?) => {
  if (targetValue > development.constraints.maximums[inputId]) {
    developmentAccessors.setInputMaximumConstrained(development, inputId, targetValue, index);
    developmentAccessors.setInputValueConstrained(development, inputId, targetValue, index);
  } else if (targetValue < development.constraints.minimums[inputId]) {
    developmentAccessors.setInputMinimumConstrained(development, inputId, targetValue, index);
    developmentAccessors.setInputValueConstrained(development, inputId, targetValue, index);
  } else {
    developmentAccessors.setInputValue(development, inputId, targetValue, index);
  }
  developmentAccessors.update(development);
};

/**
 * See `setRangedInputMinimum` action creator.
 *
 * Modify the development object by fully updating it using the new minimum input
 * value.
 */
const setRangedInputMinimum = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setInputMinimumConstrained(
      draftDevelopment.developmentDocument,
      payload.inputId,
      payload.inputMinimum,
      payload.index
    );
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See `setRangedInputMaximum` action creator.
 *
 * Modify the development object by fully updating it using the new maximum input
 * value.
 */
const setRangedInputMaximum = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setInputMaximumConstrained(
      draftDevelopment.developmentDocument,
      payload.inputId,
      payload.inputMaximum,
      payload.index
    );
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;
  });
};

/**
 * Modify the development object by fully updating it with the new type of the given setback.
 */
const setSetbackType = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setSetbackType(
      draftDevelopment.developmentDocument,
      draftDevelopment.developmentDocument.selectedSetbackFloorIndex,
      payload.polygonIndex,
      payload.setbackIndex,
      payload.setbackType
    );
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See the `addSetbackFloor` action creator.
 *
 * Modify the development object by adding a setback to the given floor.
 */
const addSetbackFloor = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.addSetbackFloor(draftDevelopment.developmentDocument, payload.floor);
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See the `removeSetbackFloor` action creator.
 *
 * Modify the development object by removing the setback at the given floor.
 */
const removeSetbackFloor = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.removeSetbackFloor(draftDevelopment.developmentDocument, payload.floor);
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See the `setName` action creator.
 *
 * Modify the development object with the new name value.
 */
const setName = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setName(draftDevelopment.developmentDocument, payload.name);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See `toggleBooleanInput` action creator.
 *
 * Modify the development by fully updating it after negating the
 * value of the identified input.
 */
const toggleBooleanInput = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    const nextToggleValue = !developmentAccessors.getInputValue(draftDevelopment.developmentDocument, payload.inputId);
    developmentAccessors.setInputValue(draftDevelopment.developmentDocument, payload.inputId, nextToggleValue);
    developmentAccessors.update(draftDevelopment.developmentDocument);
    draftDevelopment.isEdited = true;

    if (payload.inputId === VariableId.CondoToggle) {
      draftDevelopment.developmentDocument.isForSale = nextToggleValue;
    }
  });
};

/**
 * See `setCamera` action creator.
 *
 * Modifies the development object adding the new camera values.
 */
const setCamera = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setCamera(draftDevelopment.developmentDocument, payload.camera);
    draftDevelopment.isEdited = true;
  });
};

/**
 * See `setUnitSystem` action creator.
 *
 * Modifies the development object by setting the unit system.
 */
const setUnitSystem = (previousState: DevelopmentState, payload): DevelopmentState => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setUnitSystem(draftDevelopment.developmentDocument, payload.unitSystem);
  });
};

/**
 * See `setProjectIsForSale` action creator.
 *
 * Sets the projectIsForSale flag.
 */
const setProjectIsForSale = (previousState: DevelopmentState, payload): DevelopmentState => {
  return {
    ...previousState,
    developmentDocument: {
      ...previousState.developmentDocument,
      isForSale: payload.value,
    },
    isEdited: true,
  };
};

/**
 * See `setFloorPlan` action creator.
 *
 * Returns a new state object with the floorPlan updated for the given floor.
 */
const setFloorPlan = (previousState: DevelopmentState, payload): DevelopmentState => {
  let floorPlansByFloor: any = {};
  const floor = payload.floor;

  payload.floorPlan.forEach((shape) => {
    let shapeId = shape.properties[MapStyleProperties.FloorPlanFields.FloorPlanShapeId];
    // Remove id property of feature that gets added by mapbox-draw.
    delete shape.id;
    floorPlansByFloor[shapeId] = shape;
  });

  return {
    ...previousState,
    developmentDocument: {
      ...previousState.developmentDocument,
      buildingModel: {
        ...previousState.developmentDocument.buildingModel,
        floorPlansByFloor: {
          ...previousState.developmentDocument.buildingModel.floorPlansByFloor,
          [floor]: floorPlansByFloor,
        },
      },
    },
  };
};

/**
 * See `setCustomSliderName` action creator.
 *
 * Returns a new state object with the customSliderNames object updated for the given variableId.
 */
const setCustomSliderName = (previousState: DevelopmentState, payload): DevelopmentState => {
  const { variableId, defaultName, customName } = payload;
  const newCustomSliderName = { variableId, defaultName, customName };

  return {
    ...previousState,
    developmentDocument: {
      ...previousState.developmentDocument,
      customSliderNames: {
        ...previousState.developmentDocument.customSliderNames,
        [variableId]: newCustomSliderName,
      },
    },
    isEdited: true,
  };
};

export default reducer;
