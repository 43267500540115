import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import DynamicSliderComponent from "../DynamicSliderComponent";
import { Tier } from "types/Tier";
import layerHelper from "utils/mapbox/layerHelper";

const FIELD_TO_LABEL_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_1]: "1 foot",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_2]: "2 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_3]: "3 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_4]: "4 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_5]: "5 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_6]: "6 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_7]: "7 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_8]: "8 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_9]: "9 feet",
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_10]: "10 feet",
}

const SELECTED_TO_DEFAULT_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_1]: 1,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_2]: 2,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_3]: 3,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_4]: 4,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_5]: 5,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_6]: 6,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_7]: 7,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_8]: 8,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_9]: 9,
  [MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_10]: 10,
}

const mapStateToProps = (state) => ({
  tier: subscriptionSelectors.getTier(state),
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

export class SeaLevelRiseDynamicLayerSlider extends React.PureComponent<Props, {}> {

  render() {
    const { getSelectedDynamicLayer, tier, dynamicLayerConfigurations, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer || !getSelectedDynamicLayerSet) return null;
    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if(!layerSelected) return null;

    const isSeaLevelRiseLayer = (getSelectedDynamicLayer === MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise);
    const isDemo = tier === Tier.None;
    const hasData = layerHelper.areLayersAvailableInGroup(dynamicLayerConfigurations, getSelectedDynamicLayer);
    const renderComponent = isSeaLevelRiseLayer && !isDemo && hasData;

    return (
      <>
      { renderComponent &&
        <DynamicSliderComponent
          currentLabel={FIELD_TO_LABEL_YEAR[layerSelected]}
          sliderMin={1}
          sliderMax={10}
          defaultValue={SELECTED_TO_DEFAULT_YEAR[layerSelected]}
        />
       }
      </>
    )
  }
}

export default connect(
  mapStateToProps,
)(SeaLevelRiseDynamicLayerSlider);