import { Config } from "../../../../../../types/Config";
import { VariableId } from "../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";

const config: Config = {
  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Condo Unit Mix",
    formatOptions: {
      suffix: " units",
      type: Format.Type.Number
    }
  },

  [VariableId.CondoMicrounitQuantityToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "Micro-Units",
    variableId: VariableId.CondoMicrounitQuantityToggleable,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.CondoStudioQuantityToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "Studios",
    variableId: VariableId.CondoStudioQuantityToggleable,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.CondoOneBedQuantityToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "One Bedrooms",
    variableId: VariableId.CondoOneBedQuantityToggleable,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.CondoTwoBedQuantityToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "Two Bedrooms",
    variableId: VariableId.CondoTwoBedQuantityToggleable,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.CondoThreeBedQuantityToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "Three Bedrooms",
    variableId: VariableId.CondoThreeBedQuantityToggleable,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.CondoGrossBuildableArea]: {
    text: "Condo Gross Buildable",
    variableId: VariableId.CondoGrossBuildableArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoMicrounitArea]: {
    buildingUse: BuildingUse.Condo,
    text: "Micro-Unit",
    variableId: VariableId.CondoMicrounitArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoStudioArea]: {
    buildingUse: BuildingUse.Condo,
    text: "Studio",
    variableId: VariableId.CondoStudioArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoOneBedArea]: {
    variableId: VariableId.CondoOneBedArea,
    text: "One Bedroom",
    formatOptions: { type: Format.Type.Number },
    buildingUse: BuildingUse.Condo,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoTwoBedArea]: {
    buildingUse: BuildingUse.Condo,
    text: "Two Bedroom",
    variableId: VariableId.CondoTwoBedArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoThreeBedArea]: {
    buildingUse: BuildingUse.Condo,
    text: "Three Bedroom",
    variableId: VariableId.CondoThreeBedArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoLossFactor]: {
    buildingUse: BuildingUse.Condo,
    text: "Loss Factor",
    variableId: VariableId.CondoLossFactor,
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.CondoAmenitiesAreaToggleable]: {
    buildingUse: BuildingUse.Condo,
    text: "Amenities",
    variableId: VariableId.CondoAmenitiesAreaToggleable,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoGrossSales]: {
    text: "Condo Sales",
    variableId: VariableId.CondoGrossSales,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoSalePricePerMicrounit]: {
    buildingUse: BuildingUse.Condo,
    text: "Micro-Unit",
    variableId: VariableId.CondoSalePricePerMicrounit,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoSalePricePerStudio]: {
    buildingUse: BuildingUse.Condo,
    text: "Studio",
    variableId: VariableId.CondoSalePricePerStudio,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoSalePricePerOneBed]: {
    buildingUse: BuildingUse.Condo,
    text: "One Bedroom",
    variableId: VariableId.CondoSalePricePerOneBed,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoSalePricePerTwoBed]: {
    buildingUse: BuildingUse.Condo,
    text: "Two Bedroom",
    variableId: VariableId.CondoSalePricePerTwoBed,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoSalePricePerThreeBed]: {
    buildingUse: BuildingUse.Condo,
    text: "Three Bedroom",
    variableId: VariableId.CondoSalePricePerThreeBed,
    formatOptions: { type: Format.Type.Currency }
  },
};

export default config;
