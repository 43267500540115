import React from "react";
import PdfContext from "../../../PdfContext";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
  },
  footerImage: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  pageNumber: {
    position: "absolute",
    bottom: 9,
    right: 25,
    color: "#F75959",
    fontFamily: "Roboto Condensed Bold",
    fontSize: 8.5,
  },
  content: {
    position: "absolute",
    fontFamily: "Roboto Condensed",
    fontSize: 7.2,
    color: "#F4F5F8",
    top: 11,
    left: 25,
  },
});

const Footer = (props) => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <View style={styles.container} fixed>
        <View style={[styles.footerImage, { backgroundColor: colorPalette.primary }]} />
        <Text style={styles.content}>{props.children}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
      </View>
    )}
  </PdfContext.Consumer>
);

export default Footer;
