import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class HistoricSitesLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.HistoricSites}
        classes="historic-sites"
        legendRows={[
          <LegendRow
            color="#db1e2a"
            text="Historic Sites"
          />
        ]}
      />
    );
  }
}

export default HistoricSitesLegend;
