import * as firebase from "firebase/app";
import "firebase/firestore";

// The enumerated string must match the collection name in Firestore.
export enum Collection {
  Users = "users",
  Developments = "projects",
}

export enum OperationType {
  Set = "SET",
  Update = "UPDATE",
  Delete = "DELETE"
};

export type User = firebase.User | null;

export interface Operation {
  type: OperationType;
  documentReference: firebase.firestore.DocumentReference;
  data?: firebase.firestore.DocumentData;
};

export enum ErrorCode {
  ObjectNotFound = "storage/object-not-found",
}
