import React from "react";
import { connect } from "react-redux";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import DividerComponent from "views/sharedComponents/DynamicLayerSelection/DividerComponent";
import config from "../config";
import LayerSectorPanel from "../LayerSectorPanel";
import { LayerSector } from "types/LayerSector";

interface OwnProps {
  layerSector: LayerSector;
}

const mapStateToProps = (state) => ({
  layerConfigurations: mapsSelectors.getLayerConfigurations(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

export class StaticLayerPanel extends React.PureComponent<Props, {}> {
  areLayersAvailable = () => {
    const layerGroup = Object.keys(config).filter(
      (layerGroup) => config[layerGroup].layerSector === this.props.layerSector
    );
    let layersIdsFromGroups: Array<MapStyleProperties.LayerId> = [];

    layerGroup.forEach((layerGroup) => {
      const layersIds = config[layerGroup].layers;
      layersIdsFromGroups = layersIdsFromGroups.concat(layersIds);
    });

    return layersIdsFromGroups.some((layerId) => this.props.layerConfigurations[layerId].isAvailable);
  };

  renderComponent = () => {
    return (
      <>
        <DividerComponent dividerLabel="Layers" />
        <LayerSectorPanel layerSector={this.props.layerSector} />
      </>
    );
  };

  render() {
    const layersAreAvailable = this.areLayersAvailable();

    return <>{layersAreAvailable && this.renderComponent()}</>;
  }
}

export default connect(mapStateToProps)(StaticLayerPanel);
