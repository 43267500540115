import React from "react";
import DevelopmentCard from "./DevelopmentCard";
import Pagination from "./Pagination";

const PAGE_SIZE = 18;

interface Props {
  developmentsList: Array<any>;
}

interface State {
  pageNumber: number;
}

class DevelopmentsList extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
    }
  }

  /**
   * Decrease the page number when the page becomes empty.
   */
  componentDidUpdate() {
    let { developmentsList } = this.props;

    // If the user is currently in the last page and delete all the projects, the previous page should load.
    if (developmentsList && developmentsList.length > 0 && (developmentsList.length <= this.state.pageNumber * PAGE_SIZE)) {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
      });
    }
  }

  /**
   * Change the developments list page being displayed.
   */
  changePage = (pageNumber: number) => {
    this.setState({ pageNumber });
  }

  /**
   * Render the development cards.
   */
  renderDevelopmentCards = () => {
    const { developmentsList } = this.props;

    return (
      <>
        {developmentsList
          .slice(this.state.pageNumber * PAGE_SIZE, (this.state.pageNumber + 1) * PAGE_SIZE)
          .map((development) =>
            <DevelopmentCard
              development={development}
              key={development.id}
            />
          )}
      </>
    );
  }

  render() {
    return (
      <div className="component--developments-list" >
        <div className="developments-header">
          MY REAL ESTATE PROJECTS
        </div>

        <div className="developments-wrapper">
          {this.renderDevelopmentCards()}
        </div>

        <Pagination
          pageNumber={this.state.pageNumber}
          totalDevelopments={this.props.developmentsList.length}
          pageSize={PAGE_SIZE}
          changePage={this.changePage}
        />
      </div >
    )
  }
}

export default DevelopmentsList;
