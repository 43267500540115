import React from "react";
import Unit from "../../../types/Unit";
import analytics from "../../../utils/analytics";
import { useLocation } from "react-router-dom";

type OwnProps = {
  unitSystem: Unit.System;
  setUnitSystem(unitSystem: Unit.System): void;
};

const UnitSystemInput: React.FC<OwnProps> = ({ unitSystem, setUnitSystem }) => {
  const location = useLocation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    analytics.trackSetUnitSystem(event.target.value as Unit.System, location.pathname);
    setUnitSystem(event.target.value as Unit.System);
  };

  return (
    <div className="component--unit-system-input">
      <label>
        <input type="radio" value={Unit.System.Imperial} name="unitSystem" onChange={handleInputChange} />
        <span className={`custom-radio ${unitSystem === Unit.System.Imperial ? "selected" : ""}`} />
        Feet and Inches
      </label>
      <label>
        <input type="radio" value={Unit.System.Metric} name="unitSystem" onChange={handleInputChange} />
        <span className={`custom-radio ${unitSystem === Unit.System.Metric ? "selected" : ""}`} />
        Metric System
      </label>
    </div>
  );
};

export default UnitSystemInput;
