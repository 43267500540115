import { createReducer } from "@reduxjs/toolkit";
import { INVITATION_ERROR_MESSAGE_MAP } from "../../../types/InvitationError";
import { subscriptionActions, subscriptionActionTypes } from "../../subscription";
import { subscriptionInvitationsActions, subscriptionInvitationsActionTypes } from "../../subscriptionInvitations";
import actions from "./actions";

export interface SubscriptionManagementUiState {
  tiersPanelIsActive: boolean;
  deleteInvitationPopupIsOpen: boolean;
  invitationIsBeingDeleted: boolean;
  cancelInvitationPopupIsOpen: boolean;
  invitationIsBeingCancelled: boolean;
  cancelSubscriptionPopupIsOpen: boolean;
  subscriptionIsBeingCancelled: boolean;
  errorMessage: string;
}

export const INITIAL_STATE: SubscriptionManagementUiState = {
  tiersPanelIsActive: false,
  deleteInvitationPopupIsOpen: false,
  invitationIsBeingDeleted: false,
  cancelInvitationPopupIsOpen: false,
  invitationIsBeingCancelled: false,
  cancelSubscriptionPopupIsOpen: false,
  subscriptionIsBeingCancelled: false,
  errorMessage: "",
}

/**
 * See `setInvitationError` action creator.
 *
 * Sets error message.
 */
const setInvitationError = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.setInvitationError>) => {
  state.errorMessage = INVITATION_ERROR_MESSAGE_MAP[action.payload.invitationError];
}

/**
 * See `clearInvitationError` action creator.
 *
 * Clears error message.
 */
const clearInvitationError = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.clearInvitationError>) => {
  state.errorMessage = INITIAL_STATE.errorMessage;
}

/**
 * See `toggleTiersPanelIsActive` action creator.
 *
 * Toggles the tiersPanelIsActive flag.
 */
const toggleTiersPanelIsActive = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.toggleTiersPanelIsActive>) => {
  const nextValue = action.payload.value !== undefined ? action.payload.value : !state.tiersPanelIsActive;

  state.tiersPanelIsActive = nextValue;
}

/**
 * See `toggleDeleteInvitationPopupIsOpen` action creator.
 *
 * Toggles the deleteInvitationPopupIsOpen flag.
 */
const toggleDeleteInvitationPopupIsOpen = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.toggleDeleteInvitationPopupIsOpen>) => {
  state.deleteInvitationPopupIsOpen = action.payload.value;
}

/**
 * See `toggleCancelInvitationPopupIsOpen` action creator.
 *
 * Toggles the cancelInvitationPopupIsOpen flag.
 */
const toggleCancelInvitationPopupIsOpen = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.toggleCancelInvitationPopupIsOpen>) => {
  state.cancelInvitationPopupIsOpen = action.payload.value;
}

/**
 * See `toggleCancelSubscriptionPopupIsOpen` action creator.
 *
 * Toggles the cancelSubscriptionPopupIsOpen flag.
 */
const toggleCancelSubscriptionPopupIsOpen = (state: SubscriptionManagementUiState, action: ReturnType<typeof actions.toggleCancelSubscriptionPopupIsOpen>) => {
  state.cancelSubscriptionPopupIsOpen = action.payload.value;
}

/**
 * See `subscriptionInvitationActions.loadSuccess` action creator.
 *
 * Update tiers panel is open flag on subscription invitations load success.
 */
const onSubscriptionInvitationLoadSuccess = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionInvitationsActions.loadSuccess>) => {
  const payload = action.payload;
  const tiersPanelIsActive = !Boolean(payload.managedSubscriptions && Object.keys(payload.managedSubscriptions).length > 0);

  state.tiersPanelIsActive = tiersPanelIsActive;
  state.invitationIsBeingDeleted = false;
  state.invitationIsBeingCancelled = false;
  state.cancelInvitationPopupIsOpen = false;
  state.subscriptionIsBeingCancelled = false;
  state.cancelSubscriptionPopupIsOpen = false;
}

/**
 * See `subscriptionInvitationActions.cancelSuccess` action creator.
 *
 * Update flags on subscription invitations cancel success.
 */
const onSubscriptionInvitationCancelSuccess = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionInvitationsActions.cancelSuccess>) => {
  state.invitationIsBeingDeleted = false;
  state.invitationIsBeingCancelled = false;
  state.cancelInvitationPopupIsOpen = false;
}

/**
 * See `subscriptionInvitationActions.deleteStart` action creator.
 *
 * Update flags when an invitation is being deleted.
 */
const onSubscriptionInvitationDeleteStart = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionInvitationsActions.deleteStart>) => {
  state.invitationIsBeingDeleted = true;
}

/**
 * See `subscriptionActions.cancelStart` action creator.
 *
 * Update flags when an invitation is being deleted.
 */
const onSubscriptionActionsCancelStart = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionActions.cancelStart>) => {
  state.subscriptionIsBeingCancelled = true;
}

/**
 * See `subscriptionInvitation.cancelStart` action creator.
 *
 * Update flags when an invitation is being deleted.
 */
const onSubscriptionInvitationCancelStart = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionInvitationsActions.cancelStart>) => {
  state.invitationIsBeingCancelled = true;
}

/**
 * See `subscriptionInvitation.loadSuccess` action creator.
 *
 * Update tiers panel is open flag on subscription invitations load success.
 */
const onSubscriptionInvitationDeleteSuccess = (state: SubscriptionManagementUiState, action: ReturnType<typeof subscriptionInvitationsActions.deleteSuccess>) => {
  const payload = action.payload;
  const tiersPanelIsActive = !Boolean(payload.managedSubscriptions && Object.keys(payload.managedSubscriptions).length > 0);

  state.tiersPanelIsActive = tiersPanelIsActive;
  state.deleteInvitationPopupIsOpen = false;
  state.invitationIsBeingDeleted = false;
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(actions.clearInvitationError, clearInvitationError)
    .addCase(actions.setInvitationError, setInvitationError)
    .addCase(actions.toggleTiersPanelIsActive, toggleTiersPanelIsActive)
    .addCase(actions.toggleDeleteInvitationPopupIsOpen, toggleDeleteInvitationPopupIsOpen)
    .addCase(actions.toggleCancelInvitationPopupIsOpen, toggleCancelInvitationPopupIsOpen)
    .addCase(actions.toggleCancelSubscriptionPopupIsOpen, toggleCancelSubscriptionPopupIsOpen)
    .addCase(subscriptionActionTypes.CANCEL_START, onSubscriptionActionsCancelStart)
    .addCase(subscriptionInvitationsActionTypes.LOAD_SUCCESS, onSubscriptionInvitationLoadSuccess)
    .addCase(subscriptionInvitationsActionTypes.CANCEL_SUCCESS, onSubscriptionInvitationCancelSuccess)
    .addCase(subscriptionInvitationsActionTypes.CANCEL_START, onSubscriptionInvitationCancelStart)
    .addCase(subscriptionInvitationsActionTypes.DELETE_START, onSubscriptionInvitationDeleteStart)
    .addCase(subscriptionInvitationsActionTypes.DELETE_SUCCESS, onSubscriptionInvitationDeleteSuccess)
});

export default reducer;
