import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { developmentSelectors } from "../../../state/development";
import { newDevelopmentSelectors } from "../../../state/newDevelopment";
import { Getter } from "../../../types/Config";
import Format from "../../../types/Format";
import { Path } from "../../../types/Path";
import Unit from "../../../types/Unit";
import { VariableId } from "../../../types/VariableId";
import { TooltipType } from "../Tooltip/config";

const mapStateToProps = (state, ownProps) => {
  let value =
    ownProps.variableId === undefined ? ownProps.value : developmentSelectors.getValue(state, ownProps.variableId);

  return {
    value,
    newProjectParcel: newDevelopmentSelectors.getSelectedFeature(state),
    explorerParcel: developmentSelectors.getParcel(state),
  };
};

type OwnProps = {
  getter: Getter;
  variableId?: VariableId;
  styleVariation?: string;
  value?: any;
  formatOptions?: Format.Options;
  unitTarget?: Unit.Type;
  unitIsInverse?: boolean;
  tdElementProps?: any;
  isFromBackOfEnvelope?: boolean;
  isExternalURL?: boolean;
  formattingMap?: Format.FormattingMap;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const Cell: React.FC<Props> = ({
  value,
  formatOptions,
  styleVariation,
  tdElementProps,
  formattingMap,
  unitTarget,
  unitIsInverse,
  isFromBackOfEnvelope,
  newProjectParcel,
  explorerParcel,
  getter,
  isExternalURL,
}) => {
  const location = useLocation();

  const parcel = location.pathname.includes(Path.Explorer) ? explorerParcel : newProjectParcel;

  const renderCellForArrayValue = (index, line, isExternalURL, hiddenValues, formattedValue) => {
    return isExternalURL ? (
      <span key={index}>
        <a
          className={`array-item ${hiddenValues ? "blurry-text" : ""}`}
          href={formattedValue}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          {line}
          <br />
        </a>
      </span>
    ) : (
      <span className={`array-item ${hiddenValues ? "blurry-text" : ""}`} key={index}>
        {line}
        <br />
      </span>
    );
  };

  const hiddenValues = formattingMap ? formattingMap("").formatOptions?.hidden : formatOptions?.hidden;

  const formattedValue = getter(value, formatOptions, formattingMap, unitTarget, unitIsInverse, parcel);

  let classes = "component--cell";
  if (styleVariation) classes += ` ${styleVariation}`;
  const place = location?.pathname?.includes(Path.Explorer) ? "left" : "right";

  return (
    <td
      {...tdElementProps}
      className={classes}
      data-tooltip-id={`hover-${place}`}
      data-tooltip-content={
        hiddenValues && (isFromBackOfEnvelope ? TooltipType.BackOfEnvelope : TooltipType.InformationPanel)
      }
    >
      {Array.isArray(formattedValue) ? (
        formattedValue.map((line, index) =>
          renderCellForArrayValue(index, line, isExternalURL, hiddenValues, formattedValue)
        )
      ) : (
        <span className={`value ${hiddenValues ? " blurry-text" : ""}`}>{formattedValue}</span>
      )}
    </td>
  );
};

export default connect(mapStateToProps)(Cell);
