import React from "react";
import Sidebar from "../../../sharedComponents/Sidebar";
import AccountLinks from "./AccountLinks";
import Support from "./Support";

class DashboardSidebar extends React.PureComponent<{}, {}> {
  render() {
    return (
      <Sidebar>
        <AccountLinks />
        <Support />
      </Sidebar>
    );
  }
}

export default DashboardSidebar;
