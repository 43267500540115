import { useEffect } from "react";
import { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

export const drawOnUpdate = (
  map: React.MutableRefObject<Map | undefined>,
  mapboxDraw: React.MutableRefObject<MapboxDraw | null>,
  parcelIdBeingEdited: React.MutableRefObject<string | null>
) => {
  useEffect(() => {
    if (map.current) {
      const onDrawUpdate = () => {
        if (mapboxDraw && mapboxDraw.current) {
          parcelIdBeingEdited.current = mapboxDraw.current.getSelectedIds()[0];
        }
      };

      map.current.on("draw.update", onDrawUpdate);

      return () => {
        if (map.current) {
          map.current.off("draw.update", onDrawUpdate);
        }
      };
    } else {
      return () => {};
    }
  }, [map, mapboxDraw, parcelIdBeingEdited]);
};
