import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsSelectors } from "../../../../../state/subscriptionInvitations";
import { Path } from "../../../../../types/Path";
import { SubscriptionInvitationStatus } from "../../../../../types/SubscriptionInvitations";
import RedirectComponent from "../../../../sharedComponents/RedirectComponent";

const mapStateToProps = (state) => {
  return {
    invitations: subscriptionInvitationsSelectors.getSubscriptionInvitations(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class AccountLinks extends React.PureComponent<Props, {}> {
  render() {
    const { invitations } = this.props;
    const pendingInvitations = Object.keys(invitations).filter((ownerId) => invitations[ownerId].status === SubscriptionInvitationStatus.Pending).length;

    return (
      <div className="component--account-links">
        <div className="header">ACCOUNT</div>

        <div className="links-container">
          {/* Plans */}
          <RedirectComponent path={Path.SubscriptionManagement} >
            {({ redirect }) => (
              <div className="side-bar-link" onClick={redirect}>
                <span className="icon icon-subscribe" />
                Subscription
                {pendingInvitations > 0 && <div className="number">{pendingInvitations}</div>}
              </div>
            )}
          </RedirectComponent>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
)(AccountLinks);
