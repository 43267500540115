import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { newDevelopmentActions, newDevelopmentSelectors } from "state/newDevelopment";
import { TooltipType } from "../../../../../../sharedComponents/Tooltip/config";
import { subscriptionSelectors } from "../../../../../../../state/subscription";
import { Tier } from "../../../../../../../types/Tier";

const STYLE = {
  chips: {
    background: "#3A4276",
    borderRadius: "0",
    textTransform: "uppercase",
  },
  searchBox: {
    border: "none",
  },
  multiselectContainer: {
    color: "FFFFFF",
  },
  optionContainer: {
    background: "#3A4276",
    color: "FFFFFF",
  },
  option: {
    color: "white",
    font: "Bold",
    textTransform: "uppercase",
  },
};

const ZoningFilters = () => {
  const dispatch = useDispatch()
  const enabledZoningFilterIds = useSelector(newDevelopmentSelectors.getEnabledZoningFilterIds);
  const zoningFilterIds = useSelector(newDevelopmentSelectors.getZoningFilterIds);
  const tier = useSelector(subscriptionSelectors.getTier);
  const isDemoUser = tier === Tier.None;
  const dataTip = isDemoUser ? TooltipType.SmartSearch : null;

  return (
    <div className="component--zoning-filters">
      <div className="count-wrapper section-divisor">
        <label>Zoning Districts</label>
      </div>

      <div className="zoning-dropdown-box" data-for="click-right" data-tip={dataTip}>
        <Multiselect
          options={zoningFilterIds}
          selectedValues={enabledZoningFilterIds}
          onSelect={(selectedList, selectedZoneId) => dispatch(newDevelopmentActions.updateZoningFilterValues({ [selectedZoneId]: true }))}
          onRemove={(selectedList, selectedZoneId) => dispatch(newDevelopmentActions.updateZoningFilterValues({ [selectedZoneId]: false }))}
          isObject={false}
          placeholder="Click here to search for zoning districts."
          style={STYLE}
          avoidHighlightFirstOption={true}
          closeIcon="cancel"
          disable={isDemoUser}
        />
      </div>
    </div>
  );
}

export default ZoningFilters;
