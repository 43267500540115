import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsSelectors } from "../../../state/subscriptionInvitations";
import DashboardButton from "../../sharedComponents/Buttons/DashboardButton";
import LogOutButton from "../../sharedComponents/Buttons/LogOutButton";
import TiersPanel from "../../sharedComponents/TiersPanel";
import TiersButton from "../../sharedComponents/Buttons/TiersButton";
import AccountSettings from "./AccountSettings";
import TeamMemberSettings from "./TeamMemberSettings";
import InvitationsButton from "../../sharedComponents/Buttons/InvitationsButton";
import BusySpinner from "../../sharedComponents/BusySpinner";
import { subscriptionManagementActions, subscriptionManagementSelectors } from "../../../state/ui/subscriptionManagement";
import CancelSubscriptionPopup from "../../sharedComponents/CancelSubscriptionPopup";
import InvitationErrorPopup from "../../sharedComponents/InvitationErrorPopup";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    tiersIsActive: subscriptionManagementSelectors.getTiersPanelIsActive(state),
    subscriptionIsBeingCancelled: subscriptionManagementSelectors.getSubscriptionIsBeingCancelled(state),
    cancelSubscriptionPopupIsOpen: subscriptionManagementSelectors.getCancelSubscriptionPopupIsOpen(state),
    invitationErrorMessage: subscriptionManagementSelectors.getInvitationErrorMessage(state),
    tier: subscriptionSelectors.getTier(state),
  }
}

const mapDispatchToProps = {
  toggleTiersIsActive: subscriptionManagementActions.toggleTiersPanelIsActive,
  toggleCancelSubscriptionPopupIsOpen: subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  membersIsReady: boolean;
}

class SubscriptionRequiredPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      membersIsReady: Boolean(props.managedSubscriptions),
    }
  }

  /**
   * Update the state when the managed subscriptions finish loading.
   */
  componentDidUpdate(previousProps: Props) {
    if (!previousProps.managedSubscriptions && this.props.managedSubscriptions) {
      if (Object.keys(this.props.managedSubscriptions).length > 0) {
        this.setState({ membersIsReady: true });
      } else {
        this.setState({ membersIsReady: true });
      }
    }
  }

  /**
   * Toggle the `cancelSubscriptionPopupIsOpen` state.
   */
  toggleCancelSubscriptionPopupIsOpen = () => {
    this.props.toggleCancelSubscriptionPopupIsOpen(!this.props.cancelSubscriptionPopupIsOpen);
  }

  render() {
    const { membersIsReady } = this.state;
    const {
      invitationErrorMessage,
      tier,
      toggleTiersIsActive,
      tiersIsActive,
      cancelSubscriptionPopupIsOpen,
      subscriptionIsBeingCancelled
    } = this.props;
    const isFreeAccount = tier === Tier.Free;

    return (
      <div className="component--subscription-management-page">
        <div className="content-container">
          <div className="left-container">
            <AccountSettings tier = {tier}/>
          </div>

          <div className="right-container">
            {membersIsReady
              ? tiersIsActive
                ? <TiersPanel presentationOnly />
                : <TeamMemberSettings />
              : <BusySpinner />
            }
          </div>
        </div>

        <div className="buttons-container">
          { !isFreeAccount && <DashboardButton /> }
          <InvitationsButton />
          <TiersButton isActive={tiersIsActive} toggleIsActive={() => toggleTiersIsActive()} />
          <LogOutButton />
        </div>

        {cancelSubscriptionPopupIsOpen
          && <CancelSubscriptionPopup onStateChange={this.toggleCancelSubscriptionPopupIsOpen} preventClose={subscriptionIsBeingCancelled} />}
        {invitationErrorMessage
          && <InvitationErrorPopup />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionRequiredPage);
