import React from "react";
import { connect } from "react-redux";
import DeleteDevelopmentPopup from "./DeleteDevelopmentPopup";
import { developmentSelectors, developmentActions } from "../../../state/development";
import { userSelectors } from "../../../state/user";
import analytics from "../../../utils/analytics";
import authentication from "../../../utils/authentication";

interface State {
  deleteDevelopmentPopupIsActive: boolean;
}

const mapStateToProps = (state) => {
  return {
    development: developmentSelectors.getDevelopment(state),
    userDocument: userSelectors.getUserDocument(state),
  };
};

interface OwnProps {
  developmentId: string;
  developmentName: string;
  showText?: boolean;
}

const mapDispatchToProps = {
  removeDevelopment: developmentActions.removeStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class DeleteDevelopment extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      deleteDevelopmentPopupIsActive: false
    };
  }

  /**
   * Callback for confirm button in DeleteDevelopmentPopup.
   */
  handleDeleteDevelopmentConfirm = async () => {
    const user = authentication.getCurrentUser();
    this.props.removeDevelopment(user, this.props.developmentId, this.props.userDocument);
    analytics.trackDeleteProject(this.props.developmentId);
  }

  /**
   * Toggle the dialog box active state.
   */
  handleDeleteDevelopmentPopupStateChange = (event) => {
    if (event) event.stopPropagation();

    this.setState({ deleteDevelopmentPopupIsActive: !this.state.deleteDevelopmentPopupIsActive })
  }

  /**
   * Render the delete development popup.
   */
  renderPopup = () => {
    if (!this.state.deleteDevelopmentPopupIsActive) return null;

    return (
      <DeleteDevelopmentPopup
        onStateChange={this.handleDeleteDevelopmentPopupStateChange}
        onConfirm={this.handleDeleteDevelopmentConfirm}
        developmentName={this.props.developmentName}
      />
    );
  }

  render() {
    return (
      <div
        className={`component--delete-development ${this.state.deleteDevelopmentPopupIsActive ? "active" : ""}`}
        onClick={this.handleDeleteDevelopmentPopupStateChange}
      >
        <div className="icon" />
        {this.props.showText && <div className="text">Discard</div>}
        {this.renderPopup()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDevelopment);
