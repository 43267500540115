import { Config } from "../../../../../../types/Config";
import { VariableId } from "../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";

const config: Config = {
  [VariableId.IndustrialGrossBuildableArea]: {
    variableId: VariableId.IndustrialGrossBuildableArea,
    text: "Industrial Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.IndustrialNetLeasableAreaToggleable]: {
    variableId: VariableId.IndustrialNetLeasableAreaToggleable,
    buildingUse: BuildingUse.Industrial,
    text: "Net Leasable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    text: "Industrial Income",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IndustrialLossFactor]: {
    variableId: VariableId.IndustrialLossFactor,
    buildingUse: BuildingUse.Industrial,
    text: "Loss Factor",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    buildingUse: BuildingUse.Industrial,
    text: "Industrial Lease Income",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    },
  },

  [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.IndustrialYearOneExpenseReimbursementFeePerArea,
    buildingUse: BuildingUse.Industrial,
    text: "Expense Reimbursement",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    },
  },

  [VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Industrial NOI",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IndustrialStabilizedVacancyPercentage]: {
    variableId: VariableId.IndustrialStabilizedVacancyPercentage,
    buildingUse: BuildingUse.Industrial,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1
    }
  },

  [VariableId.IndustrialYearOneOperatingExpensePerArea]: {
    variableId: VariableId.IndustrialYearOneOperatingExpensePerArea,
    buildingUse: BuildingUse.Industrial,
    text: "OpEx per Year",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    },
  }
};

export default config
