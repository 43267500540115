import * as firebase from "firebase/app";
import "firebase/firestore";
import taskQueue from "../AsynchronousTaskQueue";
import databaseHelper from "./utils/databaseHelper";

/**
 * Create user document for the given user id.
 */
const create = async (userId: string, userDocument: object) => {
  const task = async () => {
    const userReference = getUserReference(userId);
    await databaseHelper.createDocument(userReference, userDocument);
  }

  await taskQueue.push(task);
}

/**
 * Read and return user document.
 */
const read = async (userId: string): Promise<any> => {
  const task = async () => {
    try {
      const userReference = getUserReference(userId);
      return await databaseHelper.getDocument(userReference);
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  return await taskQueue.push(task);
}

/**
 * Update a user document.
 */
const update = async (userId: string, userDocument: object) => {
  const task = async () => {
    const userReference = getUserReference(userId);
    await databaseHelper.updateDocument(userReference, userDocument);
  }

  await taskQueue.push(task);
}

/**
 * Return reference to the user collection.
 */
const getUserReference = (userId) => {
  return firebase.firestore().doc(`users/${userId}`);
}

export default {
  create,
  read,
  update,
}
