import React from "react"
import { Layer, Source } from "react-mapbox-gl"
import { connect } from "react-redux"
import { layersSelectors } from "../../../../../../state/layers"
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties"
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties"

const mapStateToProps = (state) => ({
  multifamilyFeatures: layersSelectors.selectMultifamilyMonthlyRentFeatures(state),
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const MULTIFAMILY_RENT_LAYER_SOURCE_ID = "multifamily-rent-layer-source"

const LAYER_FILL_PAINT = {
  "fill-opacity": 0.5,
  "fill-color": [
    "interpolate",
    ["linear"],
    [
      "get",
      MapStyleProperties.RentFeatureFieldId.Value
    ],
    .5,
    "#fd0d0d",
    1.5,
    "#fff305",
    2.5,
    "#0fe600",
    3.5,
    "#16e7fe",
    4.5,
    "#024be8",
  ]
}

class MultifamilyRentFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Source
          id={MULTIFAMILY_RENT_LAYER_SOURCE_ID}
          geoJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.props.multifamilyFeatures
            }
          }}
        />
        <Layer
          id={MapStyleProperties.LayerId.MultifamilyRentFill}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MULTIFAMILY_RENT_LAYER_SOURCE_ID}
          paint={LAYER_FILL_PAINT}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={MapStyleProperties.LayerId.MultifamilyRentBorder}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MULTIFAMILY_RENT_LAYER_SOURCE_ID}
          paint={mapboxPresentationProperties.rentLayersLinePaint}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={MapStyleProperties.LayerId.MultifamilyRentSymbol}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={MULTIFAMILY_RENT_LAYER_SOURCE_ID}
          layout={mapboxPresentationProperties.rentLabelsSymbolLayout(MapStyleProperties.RentFeatureFieldId.Value, { prefix: "$" })}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          minZoom={13}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    )
  }
}

export default connect(
  mapStateToProps
)(MultifamilyRentFeatureLayer);
