import { SubscriptionState } from "./reducers";

/**
 * Select the whole subscription.
 */
const getSubscription = (state): SubscriptionState => {
  return state.subscription;
}

/**
 * Get subscription status.
 */
const getStatus = (state) => {
  const subscription = getSubscription(state);
  return subscription.status;
}

/**
 * Get subscription id.
 */
const getId = (state) => {
  const subscription = getSubscription(state);
  return subscription.id;
}

/**
 * Get subscription plan id.
 */
const getPlanId = (state) => {
  const subscription = getSubscription(state);
  return subscription.planId;
}

/**
 * Get plan prices.
 */
const getPlanPrices = (state) => {
  const subscription = getSubscription(state);
  return subscription.planPrices;
}

/**
 * Get credit card.
 */
const getCreditCard = (state) => {
  const subscription = getSubscription(state);
  return subscription.creditCard;
}

/**
 * Get subscription tier.
 */
const getTier = (state) => {
  const subscription = getSubscription(state);
  return subscription.tier;
}

/**
 * Get memberSince value.
 */
const getMemberSince = (state) => {
  const subscription = getSubscription(state);
  return subscription.memberSince;
}

/**
 * Get currentTermStart value.
 */
const getCurrentTermStart = (state) => {
  const subscription = getSubscription(state);
  return subscription.currentTermStart;
}

/**
 * Get currentTermEnd value.
 */
const getCurrentTermEnd = (state) => {
  const subscription = getSubscription(state);
  return subscription.currentTermEnd;
}

/**
 * Get userIdToBeCancelled value.
 */
const getUserIdToBeCancelled = (state) => {
  const subscription = getSubscription(state);
  return subscription.userIdToBeCancelled;
}

/**
 * Get isPaymentStarted value.
 */
const getIsPaymentStarted = (state) => {
  const subscription = getSubscription(state);
  return subscription.isPaymentStarted;
}

/**
 * Get isPaymentSuccess value.
 */
const getIsPaymentSuccess = (state) => {
  const subscription = getSubscription(state);
  return subscription.isPaymentSuccess;
}

/**
 * Get isError value.
 */
const getIsPaymentError = (state) => {
  const subscription = getSubscription(state);
  return subscription.isError;
}

/**
 * Get errorMessage value.
 */
const getErrorMessage = (state) => {
  const subscription = getSubscription(state);
  return subscription.errorMessage;
}

export default {
  getStatus,
  getId,
  getPlanId,
  getPlanPrices,
  getCreditCard,
  getTier,
  getMemberSince,
  getCurrentTermStart,
  getCurrentTermEnd,
  getUserIdToBeCancelled,
  getIsPaymentStarted,
  getIsPaymentSuccess,
  getIsPaymentError,
  getErrorMessage,
}
