import { BuildingModel } from "types/Development/BuildingModel";
import { Values } from "types/Development/Values";
import { VariableId } from "types/VariableId";

export const getBuildingIsReduced = (buildingModel: BuildingModel, values: Values) => {
  if (!buildingModel) return false;

  const buildableArea = values[VariableId.ProjectGrossBuildableArea];
  const buildingArea = buildingModel.floors.reduce((total, floor) => {
    return total + floor.footprint.area;
  }, 0);

  return Math.floor(buildingArea) < Math.floor(buildableArea);
};
