import React from "react";
import { connect } from "react-redux";
import { GeoJsonObject } from "geojson";
import Format from "../../../../types/Format";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import CellRow from "../../CellRow";
import Cell from "../../Cell";
import ConditionalCellRow from "../ConditionalCellRow";
import AccordionSection from "../../AccordionSection";
import { Accordions } from "../../../../state/ui/shared/accordionSection/reducers";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../state/ui/shared/accordionSection";
import CellText from "../../CellText";
import valueGetters from "../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";

type OwnProps = {
  parcelFeature: GeoJsonObject;
};

const mapStateToProps = (state) => {
  return {
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.Demographics),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

/**
 * Render demographics part.
 */
class Demographics extends React.PureComponent<Props, {}> {
  /**
   * Display if [or how many of] the parcels are in an opportunity zone.
   */
  displayOpportunityZone = () => {
    let opportunityZoneArray = parcelAccessors.getIsInOpportunityZone(this.props.parcelFeature);

    if (!opportunityZoneArray) return null;

    let numberOfOpportunityZones = opportunityZoneArray.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue),
      0
    );

    let value = "";
    if (numberOfOpportunityZones === 0) value = "No";
    else if (numberOfOpportunityZones === opportunityZoneArray.length) value = "Yes";
    else value = `${numberOfOpportunityZones} out of ${opportunityZoneArray.length}`;

    return (
      <CellRow>
        <CellText text="Opportunity Zone" styleVariation="yellow" />
        <Cell value={value} getter={valueGetters.generic} />
      </CellRow>
    );
  };

  /**
   * Format array to add type format and display inline.
   */
  getArrayAverage = (array: Array<number>): number | null => {
    if (array.length === 0) return null;

    const totalSum = array.reduce((totalSum, currentValue) => totalSum + currentValue, 0);
    return totalSum / array.length;
  };

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.Demographics, !this.props.accordionIsOpen);
  };

  render() {
    const { parcelFeature, tier } = this.props;
    const isDeveloperTier = tier === Tier.Developer;
    const isDemoUser = tier === Tier.None;
    if (isDeveloperTier || isDemoUser) return null;

    const medianIncomeTotal = parcelAccessors.getNonGeneratedMedianIncomeTotal(parcelFeature);
    const grossMedianRent = parcelAccessors.getNonGeneratedGrossMedianRent(parcelFeature);
    const populationDensity = parcelAccessors.getNonGeneratedPopulationDensity(parcelFeature);

    return (
      <AccordionSection
        title="Demographics"
        iconClass="demographics"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
      >
        <table>
          <tbody>
            {ConditionalCellRow(
              this.getArrayAverage(medianIncomeTotal),
              <CellText text="Median Household Income" />,
              <Cell
                getter={valueGetters.generic}
                styleVariation="bold"
                formatOptions={{
                  type: Format.Type.Currency,
                  suffix: `${medianIncomeTotal.length > 1 ? " (Average)" : ""}`,
                }}
              />
            )}
            {ConditionalCellRow(
              this.getArrayAverage(grossMedianRent),
              <CellText text="Median Gross Rent" />,
              <Cell
                getter={valueGetters.generic}
                styleVariation="bold"
                formatOptions={{
                  type: Format.Type.Currency,
                  suffix: `${grossMedianRent.length > 1 ? " (Average)" : ""}`,
                }}
              />
            )}
            {ConditionalCellRow(
              this.getArrayAverage(populationDensity),
              <CellText text="Census Tract Population" />,
              <Cell
                getter={valueGetters.generic}
                styleVariation="bold"
                formatOptions={{
                  type: Format.Type.Number,
                  suffix: ` ${populationDensity.length > 1 ? " (Average)" : ""}`,
                }}
              />
            )}
            {this.displayOpportunityZone()}
          </tbody>
        </table>
      </AccordionSection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);
