import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow/LegendRow";

class MultifamilySitesLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.MultifamilySites}
        classes="multifamily-sites-legend"
        legendRows={[
          <LegendRow color="#0BFF96" text="Residential" key="Residential" />,
          <LegendRow color="#EC1379" text="Industrial" key="Industrial" />,
          <LegendRow color="#006FFF" text="Commercial" key="Commercial" />,
          <LegendRow color="#6C0092" text="Other" key="Other" />,
        ]}
      />
    );
  }
}

export default MultifamilySitesLegend;
