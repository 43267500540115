import React from "react";
import { Link } from "react-router-dom";
import { Path } from "types/Path";

interface OwnProps {
  linkLabel: string;
}

type Props = OwnProps;

class SignUpLink extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--sign-up-link"><Link className="sign-up-link" to={Path.Subscribe}>{this.props.linkLabel}</Link></div>
    );
  }
}

export default SignUpLink;
