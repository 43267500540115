import React from "react";
import wrapComponentWithPopup, { PopupProps } from "../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../../sharedComponents/WithPanelHeader";

type OwnProps = {
  message?: string;
}

type Props = OwnProps & PopupProps;

class RequiredFieldsPopup extends React.PureComponent<Props, {}>  {
  render() {
    const { message } = this.props;

    return (
      <WithPanelHeader title="Required Fields" hideControls>
        <div className="component--required-fields-popup">
          <div className="text">
            {message || "Please correctly fill all the required fields."}
          </div>

          <button onClick={this.props.closePopup}>Close</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(RequiredFieldsPopup);
