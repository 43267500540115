import React from "react";
import { connect } from "react-redux";
import { developmentActions } from "../../../state/development";
import { Path } from "../../../types/Path";
import Logo from "../Logo";
import { useLocation } from "react-router-dom";

const mapDispatchToProps = {
  saveDevelopment: developmentActions.saveStart,
};

type DispatchProps = typeof mapDispatchToProps;

const Toolbar: React.FC<DispatchProps> = (props) => {
  const location = useLocation();

  /**
   * Save current development.
   */
  const saveDevelopment = async () => {
    if (location.pathname.includes(Path.Explorer)) props.saveDevelopment();
  };

  return (
    <div className="component--toolbar">
      <Logo onClick={saveDevelopment} />
      <div className="toolbar-children-container">{props.children}</div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Toolbar);
