import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authentication from "../../../utils/authentication";
import analytics from "../../../utils/analytics";
import ActivityFeedback from "../../sharedComponents/ActivityFeedback";
import Navbar from "../../sharedComponents/Navbar";
import { Path } from "../../../types/Path";

enum Status {
  Initial = "INITIAL",
  Sending = "SENDING",
  Sent = "SENT",
}

const FEEDBACK_TEXT = {
  sending: "Resetting your password...",
  sent: "Your password has been reset.",
};

const SPINNING_TIME = 1000; // 1 second.
const ANSWER_TIME = 2000; // 2 second.

const ResetPasswordPage: React.FC<{ location: any }> = (props) => {
  const location = useLocation();
  const [status, setStatus] = useState<Status>(Status.Initial);
  const [redirectToSignIn, setRedirectToSignIn] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    analytics.trackPageview(Path.ResetPassword);
  }, []);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newPassword === newPasswordConfirmation) {
      try {
        await authentication.verifyPasswordResetCode(location.state.actionCode);
        await authentication.confirmPasswordReset(location.state.actionCode, newPassword);
        analytics.trackChangePassword();
        setStatus(Status.Sending);
        setTimeout(() => {
          setStatus(Status.Sent);
          setTimeout(() => {
            setRedirectToSignIn(true);
            setErrorMessage("");
          }, ANSWER_TIME);
        }, SPINNING_TIME);
      } catch (error) {
        const handleErrorMessage: any = error;
        setErrorMessage(authentication.getErrorMessage(handleErrorMessage.code));
      }
    } else {
      setErrorMessage("Passwords don't match.");
    }
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    setErrorMessage("");
  };

  const handleNewPasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordConfirmation(event.target.value);
    setErrorMessage("");
  };

  const renderContent = () => {
    switch (status) {
      case Status.Initial:
        return (
          <div className="content-wrapper">
            <div className="header">Let’s reset your password.</div>
            <div className="info-message">Submit, and then Log In to close any open sessions.</div>

            <div className="form-container">
              <form onSubmit={handleFormSubmit}>
                <div className="input-section hidden">
                  <input type="email" name="email" autoComplete="username" />
                </div>

                <div className="input-section">
                  <div className="input-label">New password</div>
                  <div className="input-field">
                    <input
                      type="password"
                      name="new-password"
                      autoComplete="new-password"
                      onChange={handleNewPasswordChange}
                    />
                  </div>
                </div>

                <div className="input-section">
                  <div className="input-label">Repeat new password</div>
                  <div className="input-field">
                    <input
                      type="password"
                      name="new-password-confirmation"
                      autoComplete="new-password"
                      onChange={handleNewPasswordConfirmationChange}
                    />
                  </div>
                </div>

                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <button type="submit" className="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        );
      case Status.Sending:
        return <ActivityFeedback message={FEEDBACK_TEXT.sending} displaySpinner={true} />;
      case Status.Sent:
        return <ActivityFeedback message={FEEDBACK_TEXT.sent} displaySpinner={false} />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  if (redirectToSignIn) {
    navigate(Path.Home);
  }

  return (
    <div className="component--reset-password-page">
      <Navbar />
      {renderContent()}
    </div>
  );
};

export default ResetPasswordPage;
