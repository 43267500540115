import * as firebase from "firebase";
import { call, put, takeEvery } from "redux-saga/effects";
import { InvitationError } from "../../types/InvitationError";
import { Chargebee } from "../../types/Service/Chargebee";
import Firebase from "../../types/Service/Firebase";
import { SubscriptionInvitationStatus } from "../../types/SubscriptionInvitations";
import authentication from "../../utils/authentication";
import subscriptionInvitations from "../../utils/database/subscriptionInvitations";
import subscriptions from "../../utils/database/subscriptions";
import users from "../../utils/database/users";
import databaseHelper from "../../utils/database/utils/databaseHelper";
import chargebee from "../../utils/functions/chargebee";
import subscriptionInvitation from "../../utils/functions/subscriptionInvitation";
import { authenticationActions, authenticationActionTypes } from "../authentication";
import { subscriptionActionTypes } from "../subscription";
import { subscriptionManagementActions } from "../ui/subscriptionManagement";
import actions from "./actions";
import actionTypes from "./actionTypes";
import { Invitation } from "./reducers";

/**
 * Load managed subscriptions for the current user.
 */
function* loadByUser(action: ReturnType<typeof authenticationActions.signInSuccess>) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const { subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages } = yield call(
      loadByUserHelper,
      user
    );
    yield put(actions.loadSuccess(subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Load managed subscriptions for the current user.
 */
function* loadByUserHelper(user: firebase.User) {
  const userId = user.uid;
  const subscriptionInvitationsDocument = yield call(subscriptionInvitations.read, userId);
  const { managedSubscriptions, managedSubscriptionsImages } = yield call(
    subscriptionInvitations.getManagedSubscriptions,
    userId
  );

  return { subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages };
}

/**
 * Accept a subscription invitation.
 */
function* acceptSubscription(action: ReturnType<typeof actions.acceptStart>) {
  const user = authentication.getCurrentUser();
  if (!user) return;

  let invitations;

  try {
    const invitationsDocument = yield call(subscriptionInvitations.read, user.uid);
    invitations = invitationsDocument.invitations;
    const { ownerId, subscriptionId } = action.payload;
    const currentInvitation = invitations[ownerId];

    if (currentInvitation.status === SubscriptionInvitationStatus.Cancelled) {
      yield put(subscriptionManagementActions.setInvitationError(InvitationError.InvitationCancelled, invitations));
      return;
    } else if (currentInvitation.status !== SubscriptionInvitationStatus.Pending) {
      yield put(subscriptionManagementActions.setInvitationError(InvitationError.Other, invitations));
      return;
    }

    let subscriptionDocument = yield call(subscriptions.read, user.uid);
    const currentOwnerId = subscriptionDocument && subscriptionDocument.ownerId;

    if (subscriptionId) {
      yield call(chargebee.cancelSubscription, subscriptionId, true);
    }

    // NOTE:  Firebase doesn't allow to store empty arrays. So in case the codes are empty strings,
    // we filter the array because chargebee doesn't allow empty strings as coupon codes.
    currentInvitation.discountCode = currentInvitation.discountCode.filter((code) => code);
    const chargebeeResult: Chargebee.CreateSubscriptionResult = yield call(
      chargebee.createSubscriptionForCustomer,
      currentInvitation.planId,
      currentInvitation.chargebeeCustomerId,
      currentInvitation.discountCode
    );

    Object.keys(invitations).forEach((key) => {
      switch (key) {
        case ownerId:
          invitations[key].status = SubscriptionInvitationStatus.Accepted;
          break;
        case currentOwnerId:
          invitations[key].status = SubscriptionInvitationStatus.Cancelled;
          break;
        default:
          if (invitations[key].status === SubscriptionInvitationStatus.Pending) {
            invitations[key].status = SubscriptionInvitationStatus.Rejected;
          }
          break;
      }
    });

    const invitationDocument = {
      invitations,
    };

    const invitationReference = firebase.firestore().doc(`invitations/${user.uid}`);
    const updateInvitation = {
      type: Firebase.OperationType.Update,
      documentReference: invitationReference,
      data: invitationDocument,
    };

    const newSubscriptionId = chargebeeResult.subscription.id;
    subscriptionDocument = {
      ownerId,
      subscriptionId: newSubscriptionId,
    };

    const subscriptionReference = firebase.firestore().doc(`subscriptions/${user.uid}`);
    const setSubscription = {
      type: Firebase.OperationType.Set,
      documentReference: subscriptionReference,
      data: subscriptionDocument,
    };

    yield call(databaseHelper.batchWrite, [updateInvitation, setSubscription]);
    yield put(actions.acceptSuccess());
  } catch (error: any) {
    if (error.type === "payment" && invitations) {
      const ownerId = action.payload.ownerId;

      invitations[ownerId].status = SubscriptionInvitationStatus.PaymentFailed;
      const invitationDocument = {
        invitations: invitations,
      };

      yield call(subscriptionInvitations.update, user.uid, invitationDocument);
      const { subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages } = yield call(
        loadByUserHelper,
        user
      );
      yield put(actions.loadSuccess(subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages));
    }

    console.warn(error);
  }
}

/**
 * Reject a subscription Invitation.
 */
function* rejectSubscription(action: ReturnType<typeof actions.acceptStart>) {
  const user = authentication.getCurrentUser();
  if (!user) return;

  try {
    const invitationsDocument = yield call(subscriptionInvitations.read, user.uid);
    const ownerId = action.payload.ownerId;
    const invitations = invitationsDocument.invitations;
    const currentInvitation = invitations[ownerId];

    if (currentInvitation.status === SubscriptionInvitationStatus.Cancelled) {
      yield put(subscriptionManagementActions.setInvitationError(InvitationError.InvitationCancelled, invitations));
      return;
    } else if (currentInvitation.status !== SubscriptionInvitationStatus.Pending) {
      yield put(subscriptionManagementActions.setInvitationError(InvitationError.Other, invitations));
      return;
    }

    currentInvitation.status = SubscriptionInvitationStatus.Rejected;

    yield call(subscriptionInvitations.update, user.uid, invitationsDocument);
    yield put(actions.rejectSuccess());
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Send a subscription invitation.
 */
function* sendInvite(action: ReturnType<typeof actions.inviteStart>) {
  const user = authentication.getCurrentUser();
  if (!user) return;

  try {
    const { userEmail, planId, userName, discountCode } = action.payload;
    const result = yield call(subscriptionInvitation.invite, userEmail, userName, discountCode, user.uid, planId);

    result.data ? yield put(actions.inviteSuccess()) : yield put(actions.inviteFailure());

    const { subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages } = yield call(
      loadByUserHelper,
      user
    );
    yield put(actions.loadSuccess(subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages));
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Remove a subscription invitation.
 */
function* remove(action: ReturnType<typeof actions.deleteStart>) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const { userId } = action.payload;
    const { managedSubscriptions, managedSubscriptionsImages } = yield call(
      subscriptionInvitations.remove,
      user.uid,
      userId
    );

    const subscriptionInvitationsDocument = yield call(subscriptionInvitations.read, user.uid);

    yield put(
      actions.deleteSuccess(userId, subscriptionInvitationsDocument, managedSubscriptions, managedSubscriptionsImages)
    );
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Resend a subscription invitation.
 */
function* resend(action: ReturnType<typeof actions.resendStart>) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const { managedUserId, couponCode } = action.payload;
    const userDocument = yield call(users.read, managedUserId);
    const subscriptionInvitationsDocument = yield call(subscriptionInvitations.read, managedUserId);
    const invitation: Invitation = subscriptionInvitationsDocument.invitations[user.uid];
    const fullName = invitation.userName;

    yield put(actions.inviteStart(userDocument.email, fullName, invitation.planId, [couponCode]));

    if (couponCode && !invitation.discountCode.includes(couponCode)) {
      invitation.discountCode = [couponCode];
      call(subscriptionInvitations.update, managedUserId, subscriptionInvitationsDocument);
    }
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Cancel a subscription invitation.
 */
function* cancel(action: ReturnType<typeof actions.cancelStart>) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const { userId } = action.payload;
    let subscriptionInvitationsDocument = yield call(subscriptionInvitations.read, userId);
    subscriptionInvitationsDocument.invitations[user.uid].status = SubscriptionInvitationStatus.Cancelled;

    yield call(subscriptionInvitations.update, userId, subscriptionInvitationsDocument);
    const { subscriptionInvitationsUpdatedDocument, managedSubscriptions, managedSubscriptionsImages } = yield call(
      loadByUserHelper,
      user
    );
    yield put(
      actions.cancelSuccess(
        userId,
        subscriptionInvitationsUpdatedDocument,
        managedSubscriptions,
        managedSubscriptionsImages
      )
    );
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Process all `cancelStart` actions.
 */
function* watchCancelStart() {
  yield takeEvery(actionTypes.CANCEL_START, cancel);
}

/**
 * Process all `resendStart` actions.
 */
function* watchResendStart() {
  yield takeEvery(actionTypes.RESEND_START, resend);
}

/**
 * Process all `deleteStart` actions.
 */
function* watchDeleteStart() {
  yield takeEvery(actionTypes.DELETE_START, remove);
}

/**
 * Process all `inviteStart` actions.
 */
function* watchInviteStart() {
  yield takeEvery(actionTypes.INVITE_START, sendInvite);
}

/**
 * Process all `rejectStart` actions.
 */
function* watchRejectStart() {
  yield takeEvery(actionTypes.REJECT_START, rejectSubscription);
}

/**
 * Process all `acceptStart` actions.
 */
function* watchAcceptStart() {
  yield takeEvery(actionTypes.ACCEPT_START, acceptSubscription);
}

/**
 * Process all the action that require a managed subscriptions load.
 */
function* watchSignInSuccess() {
  yield takeEvery(
    [
      authenticationActionTypes.SIGN_IN_SUCCESS,
      subscriptionActionTypes.CANCEL_SUCCESS,
      actionTypes.ACCEPT_SUCCESS,
      actionTypes.REJECT_SUCCESS,
    ],
    loadByUser
  );
}

export default {
  watchSignInSuccess,
  watchAcceptStart,
  watchRejectStart,
  watchInviteStart,
  watchDeleteStart,
  watchResendStart,
  watchCancelStart,
};
