import React from "react";
import { LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import LayerToggle from "../../../../../sharedComponents/MapLegendPanel/sharedComponents/LayerLegend/LayerToggle";
import ParcelsCsvExportButton from "./ParcelsCsvExportButton";
import ParcelCounter from "./ParcelCounter";

const SearchEngineHeader = () => (
  <div className="component--search-engine-header">

    <div className="smart-search-toggle">
      <div className="toggle-wrapper">
        <LayerToggle layerIds={[LayerId.SmartSearchResultsFill]} />
      </div>
    </div>

    <div className="legend-content">
      <div className="content-wrapper">
        <div className="title-container">
          <div className="title">Search Engine</div>
          <div className="text">Adjust search criteria to see <br /> matching parcels in the viewport.</div>
        </div>

        <div className="functionalities-container">
          <ParcelCounter />
          <ParcelsCsvExportButton />
        </div>
      </div>
    </div>

  </div>
)

export default SearchEngineHeader;
