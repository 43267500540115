import React from "react";
import { connect } from "react-redux";
import { subscriptionManagementActions, subscriptionManagementSelectors } from "../../../state/ui/subscriptionManagement";
import WithPanelHeader from "../WithPanelHeader";
import wrapComponentWithPopup, { PopupProps } from "../wrapComponentWithPopup/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    invitationErrorMessage: subscriptionManagementSelectors.getInvitationErrorMessage(state),
  }
}

const mapDispatchToProps = {
  clearInvitationError: subscriptionManagementActions.clearInvitationError,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & DispatchProps & StateProps;

class InvitationErrorPopup extends React.PureComponent<Props, {}>  {
  componentWillUnmount() {
    this.props.clearInvitationError();
  }

  render() {
    const { invitationErrorMessage } = this.props;

    return (
      <WithPanelHeader title="Error" hideControls>
        <div className="component--invitation-error-popup">
          <div className="text">
            {invitationErrorMessage}
          </div>

          <div className="buttons-container">
            <button
              className="close-button"
              onClick={this.props.closePopup}
            >
              Close
            </button>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InvitationErrorPopup)
);
