import React from "react";
import wrapComponentWithPopup, { PopupProps } from "../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../../sharedComponents/WithPanelHeader";

type Props = PopupProps;

class InvitationSuccessPopup extends React.PureComponent<Props, {}>  {
  render() {
    return (
      <WithPanelHeader title="Invitation Sent" hideControls>
        <div className="component--invitation-success-popup">
          <div className="text">
            The invitation has been sent.
          </div>

          <button onClick={this.props.closePopup}>Close</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(InvitationSuccessPopup);
