import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class HotelsLegend
 extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Hotels}
        classes="hotels"
        legendRows={[
          <LegendRow
            color="#c1d4e7"
            text="< $50"
            circle
          />,
          <LegendRow
            color="#95a9d0"
            text="$50 - $100"
            circle
          />,
          <LegendRow
            color="#8c6cb2"
            text="$100 - $300"
            circle
          />,
          <LegendRow
            color="#83278b"
            text="$300 - $500"
            circle
          />,
          <LegendRow
            color="#380046"
            text="> $500"
            circle
          />,
        ]}
      />
    );
  }
}

export default HotelsLegend
;
