/**
 * @fileoverview This file is for typing objects defined by the Chargebee API.
 * See https://apidocs.chargebee.com/docs/api
 */

// See https://apidocs.chargebee.com/docs/api/portal_sessions#portal_session_attributes
export interface PortalSession { }

// See https://apidocs.chargebee.com/docs/api/hosted_pages#hosted_page_attributes
export interface HostedPage {
  content?: {
    subscription: Subscription,
  }
}

// See https://apidocs.chargebee.com/docs/api/customers#customer_attributes
export interface Customer {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

// See https://apidocs.chargebee.com/docs/api/subscriptions#subscription_attributes
export interface Subscription {
  id: string;
  status: SubscriptionStatus;
  plan_id: PlanId;
  plan_unit_price: number;
  created_at: number;
  current_term_end: number;
  current_term_start: number;
}

// See https://apidocs.chargebee.com/docs/api/subscriptions#create_a_subscription_id
export interface CreateSubscriptionResult {
  customer: Customer;
  subscription: Subscription;
}

// See https://apidocs.chargebee.com/docs/api/subscriptions#subscription_status
export enum SubscriptionStatus {
  Active = "active",
  InTrial = "in_trial",
  Cancelled = "cancelled",
  Future = "future",
  NonRenewing = "non_renewing",
  Paused = "paused",
  None = "none",
}

// These values are defined by us via the Chargebee platform. You must have a
// Chargebee login in order to update or examine these values.
export enum PlanId {
  DeveloperMonthly = "draw-monthly-001",
  DeveloperYearly = "draw-annual-001",
  AdvancedMonthly = "parcels-monthly-001",
  AdvancedYearly = "parcels-annual-001",
  ProMonthly = "standard-monthly-001",
  ProYearly = "standard-annual-001",
  Free = "free", // Not actually coming from Chargebee.
}

export type PlanPrices = {
  [key in PlanId]?: number;
}

// See https://apidocs.chargebee.com/docs/api/plans#plan_attributes
export interface Plan {
  id: PlanId;
  price: number;
}

// See https://apidocs.chargebee.com/docs/api/coupons?lang=node#coupon_status
export enum CouponStatus {
  Active = "active",
  Expired = "expired",
  Archived = "archived",
  Deleted = "deleted",
}

// See https://apidocs.chargebee.com/docs/api/coupons?lang=node#coupon_discount_type
export enum CouponDiscountType {
  FixedAmount = "fixed_amount",
  Percentage = "percentage",
}

export enum CouponPlanConstraint {
  None = "none",
  All = "all",
  Specific = "specific",
  NotApplicable = "not_applicable",
}

// See https://apidocs.chargebee.com/docs/api/coupons?lang=node#coupon_attributes
export interface Coupon {
  id: string;
  discount_type: CouponDiscountType;
  discount_percentage: number; // min=0.01, max=100.0
  discount_amount: number; // in cents
  plan_constraint: CouponPlanConstraint;
  status: CouponStatus;
  plan_ids: Array<PlanId>;
}

// See https://apidocs.chargebee.com/docs/api/estimates#invoice_estimate_attributes
interface InvoiceEstimate {
  amount_due: number;
  sub_total: number;
  discounts: Array<EstimateDiscount>;
}

// See https://apidocs.chargebee.com/docs/api/estimates#estimate_discounts
interface EstimateDiscount {
  amount: number;
}

// See https://apidocs.chargebee.com/docs/api/estimates#estimate_attributes
export interface Estimate {
  invoice_estimate: InvoiceEstimate;
}

// See https://apidocs.chargebee.com/docs/api/payment_intents#payment_intent_status
export enum PaymentIntentStatus {
  Inited = "inited",
  InProgress = "in_progress",
  Authorized = "authorized",
  Consumed = "consumed",
  Expired = "expired",
}

// See https://apidocs.chargebee.com/docs/api/payment_intents#payment_intent_attributes
export interface PaymentIntent {
  id: string;
  status: PaymentIntentStatus;
}

// See https://apidocs.chargebee.com/docs/api/cards#card_status
export enum CardStatus {
  Valid = "valid", // A valid and active credit card.
  Expiring = "expiring", // A card which is expiring in the current month.
  Expired = "expired" // An expired card.
}

// See https://apidocs.chargebee.com/docs/api/cards
export interface Card {
  card_type: string;
  last4: string,
  status: CardStatus,
}
