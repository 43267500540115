import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { DrawingMode, hiddenMode } from "types/DrawingModes";
import { ParcelTool } from "types/ParcelTool";
import geometry from "utils/geometry";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import ReactMapboxGl, { Marker, RotationControl } from "react-mapbox-gl";
import { Map, Point, LngLat } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import mapboxPresentationProperties from "views/utils/mapboxPresentationProperties";
import analytics from "utils/analytics";
import turf from "utils/turf";
import unitConversions from "utils/unitConversions";
import Unit from "types/Unit";
import parcelAccessors from "utils/parcel/parcelAccessors";
import DrawingInformation from "../NewProjectMap/DrawingInformation";
import DrawnFeatureLayer from "../NewProjectMap/DrawnFeatureLayer";
import HoveredFeatureLayer from "../NewProjectMap/HoveredFeatureLayer";
import SelectedFeatureLayer from "../NewProjectMap/SelectedFeatureLayer";
import { KeyCode } from "types/KeyCodes";
import {
  newDevelopmentActions,
  newDevelopmentSelectors,
} from "state/newDevelopment";
import { userSelectors } from "state/user";
import { mapsSelectors } from "state/ui/shared/maps";
import { subscriptionSelectors } from "state/subscription";
import log from "loglevel";
import roundToDecimal from "utils/roundToDecimal";
import { Path } from "types/Path";
import valueFormatter from "utils/valueFormatter";
import Format from "types/Format";
import { useNavigate, useParams } from "react-router-dom";
import { useDeveloperMapConstructor } from "./DeveloperMapCustomHooks/useDeveloperMapConstructor";
import populateParcelProperties from "utils/parcel/populateParcelProperties";
import { drawOnCreate } from "../utils/customMapHooks/drawOnCreate";
import { drawOnUpdate } from "../utils/customMapHooks/drawOnUpdate";
import { drawOnModeChange } from "../utils/customMapHooks/drawOnModeChange";
import { useDeveloperMapOnIdle } from "./DeveloperMapCustomHooks/MapEvents/useDeveloperMapOnIdle";
import { drawOnSelectionChange } from "../utils/customMapHooks/drawOnSelectionChange";
import { useDeveloperMapPositionEffect } from "./DeveloperMapCustomHooks/useDeveloperMapPositionEffect";
import { getCameraFromUrl } from "../NewProjectMap/Helpers/NewprojectMapHelpers";

const CAMERA_INITIAL_PITCH: [number] = [0];
const CAMERA_INITIAL_BEARING: [number] = [0];
const TOOL_TIP_OFFSET: [number, number] = [40, 30];
const CAMERA_SELECT_PARCEL_ZOOM: number = 16.1;
let smartSearchTimeoutId: any;

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  dragRotate: false,
  touchZoomRotate: false,
  boxZoom: false,
});

const PARCEL_TOOL_TO_DRAWING_MODE = {
  [ParcelTool.DrawParcel]: DrawingMode.DrawPolygon,
  [ParcelTool.EditParcel]: DrawingMode.SimpleSelect,
  [ParcelTool.DeleteParcel]: DrawingMode.SimpleSelect,
  [ParcelTool.CopyParcel]: DrawingMode.SimpleSelect,
};

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    parcelTool: newDevelopmentSelectors.getParcelTool(state),
    parcelToolFromToolbar:
      newDevelopmentSelectors.getParcelToolFromToolbar(state),
    polygonIsBeingChanged:
      newDevelopmentSelectors.getPolygonIsBeingChanged(state),
    drawnParcels: newDevelopmentSelectors.getDrawnParcels(state),
    userLocation: userSelectors.getLocation(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    selectedFeatureMembers:
      newDevelopmentSelectors.getSelectedFeatureMembers(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
    smartSearchIsOpen: newDevelopmentSelectors.getSmartSearchIsOpen(state),
    displayPin: newDevelopmentSelectors.getDisplayPin(state),
    mapStyleUrl: mapsSelectors.getMapStyleUrl(state),
    developerMapStyleUrl: mapsSelectors.getDeveloperMapStyleUrl(state),
    tier: subscriptionSelectors.getTier(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    styleChanged: mapsSelectors.getStyleChanged(state),
  };
};

const mapDispatchToProps = {
  setProximityCenter: newDevelopmentActions.setProximityCenter,
  combineParcelsStart: newDevelopmentActions.combineParcelsStart,
  selectParcelStart: newDevelopmentActions.selectParcelStart,
  hoverFeature: newDevelopmentActions.hoverFeature,
  setPolygonMeasurements: newDevelopmentActions.setPolygonMeasurements,
  resetPolygonMeasurements: newDevelopmentActions.resetPolygonMeasurements,
  setPolygonIsBeingChanged: newDevelopmentActions.setPolygonIsBeingChanged,
  clearFeatureSelection: newDevelopmentActions.clearFeatureSelection,
  setDrawnParcels: newDevelopmentActions.setDrawnParcels,
  setDisplayPin: newDevelopmentActions.setDisplayPin,
  setParcelTool: newDevelopmentActions.setParcelTool,
};

interface OwnProps {
  setMap(map: Map);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export type Props = StateProps & DispatchProps & OwnProps;

const NewProjectDeveloperMap = (props: Props) => {
  const navigate = useNavigate();
  const { camera: cameraString } = useParams<{ camera: string | undefined }>();
  const cameraFromUrl = getCameraFromUrl(cameraString);
  const zoomFromUrl = cameraFromUrl
    ? cameraFromUrl.zoom
    : MapStyleProperties.camera.zoom;

  // State Management
  const [distanceToLastPolygonVertex, setDistanceToLastPolygonVertex] =
    useState<number | null>(null);
  const [drawingMode, setDrawingMode] = useState<boolean>(false);
  const [cameraCenter, setCameraCenter] = useState<[number, number]>(
    cameraFromUrl ? cameraFromUrl.center : MapStyleProperties.camera.center
  );
  const [cameraZoom, setCameraZoom] = useState<[number]>([zoomFromUrl]);
  const [cursorCoordinates, setCursorCoordinates] = useState<[number, number]>([
    0, 0,
  ]);
  const [boundingBox, setBoundingBox] = useState<
    [number, number, number, number] | null
  >(null);
  const [prevParcelTool, setPrevParcelTool] = useState<ParcelTool>(
    ParcelTool.SelectParcel
  );

  // Mutable values
  const map = useRef<Map | undefined>(undefined);
  const mapboxDraw = useRef<MapboxDraw | null>(null);
  const setModeToDrawingOnKeyDown = useRef<boolean>(false);
  const contextMenuClickPoint = useRef<[number, number] | null>(null);
  const parcelIdBeingEdited = useRef<string | null>(null);
  const parcelPointToBeSelected = useRef<Point | null>(null);
  const prevProps = useRef<Props | undefined>();

  /** --------------------------------------------------------------
   *
   *
   *  Functions that need to be passed
   *  to the custom hooks. They need to be
   *  declared on the top of the component
   *
   *
   * -------------------------------------------------------------- /*

    /**
   * Jump to the selected parcel.
   */
  const jumpToCoordinates = (center, customZoom?: number) => {
    let useZoom;
    if (!customZoom) {
      const mapZoom = map.current ? map.current.getZoom() : 0;
      useZoom = Math.max(mapZoom, CAMERA_SELECT_PARCEL_ZOOM);
    } else {
      useZoom = customZoom;
    }
    setCameraCenter(center);
    setCameraZoom([useZoom]);
  };

  /**
   * Select parcel if it exists at coordinates.
   */
  const selectParcelIfAvailable = (coordinates) => {
    if (!map.current) return false;
    const point = map.current.project(coordinates);
    let [selectedParcel, selectedCoordinates] = getParcel(point);
    if (selectedParcel && "type" in selectedParcel) {
      props.selectParcelStart(
        selectedParcel,
        selectedCoordinates as [number, number]
      );
      jumpToCoordinates(selectedCoordinates);
      return true;
    }

    return false;
  };

  /**
   * Clean drawn parcels
   */
  const cleanDrawnParcels = (features) => {
    if (features.length > 0) {
      deleteDrawnParcels(features);
      // This line is necessary because for some reason the mapboxDraw is initialized with a feature
      // which contains a bad geometry, so this line will clean it up and delete it.
      features = features.filter((feature) =>
        feature.geometry.coordinates[0].every(Boolean)
      );

      let cleanFeatures = geometry.cleanDrawnPolygons(features);
      cleanFeatures.forEach((feature) => mapboxDraw.current.add(feature));
    }
  };

  /**
   * Update the state with the current drawn features.
   */
  const updateDrawnParcels = () => {
    if (!mapboxDraw || !mapboxDraw.current) return;
    const features = mapboxDraw.current.getAll().features;
    props.setDrawnParcels(features);
  };

  /**
   * Set parcel point to be selected or deleted.
   */
  const setParcelPointToBeSelected = (feature) => {
    if (!map.current) return;
    const center: any = turf.getCoord(turf.center(feature));
    parcelPointToBeSelected.current = map.current.project(center);
  };

  /**
   * Delete drawn parcels.
   */
  const deleteDrawnParcels = (features) => {
    if (features.length > 0) {
      let featureIds = features.map((feature) => feature.id);
      mapboxDraw.current.delete(featureIds);
    }
  };

  /**
   * Update the polygon information, specifically:
   *
   * - Area.
   * - Perimeter.
   * - Distance to last point added (if we are in draw parcel mode).
   */
  const updatePolygonInformation = () => {
    if (!mapboxDraw || !mapboxDraw.current) return;
    if (
      props.parcelTool === ParcelTool.DrawParcel &&
      props.polygonIsBeingChanged
    ) {
      let features = mapboxDraw.current.getAll().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[features.length - 1];
      try {
        let ring = turf.getCoords(currentFeature)[0];
        let currentPoint = ring[ring.length - 2];
        let lastPolygonPoint = ring[ring.length - 3];
        setDistanceToLastPolygonVertex(
          unitConversions.convert(
            turf.distance(currentPoint, lastPolygonPoint),
            Unit.Type.KiloMeters,
            Unit.Type.Meters
          )
        );

        props.setPolygonMeasurements(currentFeature);
      } catch (error) {
        log.warn(error);
      }
    } else if (
      mapboxDraw.current &&
      props.parcelTool === ParcelTool.EditParcel
    ) {
      let features = mapboxDraw.current.getSelected().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[0];
      props.setPolygonMeasurements(currentFeature);
    }
  };

  /**
   * Clean drawn parcels of parcel being edited.
   */
  const cleanEditedParcel = () => {
    if (!mapboxDraw || !mapboxDraw.current) return;
    if (parcelIdBeingEdited) {
      const editedFeature = mapboxDraw.current.get(parcelIdBeingEdited);
      if (editedFeature) cleanDrawnParcels([editedFeature]);
      parcelIdBeingEdited.current = null;
    }
  };

  /**
   * Select the parcel to be edited.
   */
  const selectParcelToEdit = (point) => {
    if (!mapboxDraw.current) return;

    let clickedFeatureIds = mapboxDraw.current.getFeatureIdsAt(point);

    if (clickedFeatureIds.length > 0) {
      for (const featureId of clickedFeatureIds) {
        if (featureId) {
          mapboxDraw.current.changeMode(DrawingMode.DirectSelect, {
            featureId,
          });
          break;
        }
      }
    }
  };

  /**
   * Update the url.
   */
  const updateUrl = () => {
    if (!map.current) return;

    const zoom = roundToDecimal(map.current.getZoom(), 2);
    const center = map.current.getCenter().wrap();
    const centerCoordinates = [
      roundToDecimal(center.lng, 7),
      roundToDecimal(center.lat, 7),
    ];

    const cameraString = centerCoordinates.join(",") + "," + zoom;
    navigate(`${Path.NewProject}/${cameraString}`, { replace: true });
  };

  /** Get the parcel at point position. */
  const getParcel = (point: Point) => {
    if (!point || !map || !map.current) return [null, null];
    let selectedFeatureUnion = getCompleteParcelFeatureAtScreenPoint(point);

    if (selectedFeatureUnion === null) return [null, null];

    const latLngFromMap: LngLat = map.current.unproject(point);
    const clickedCoordinates = [latLngFromMap.lng, latLngFromMap.lat];

    return [selectedFeatureUnion, clickedCoordinates];
  };

  /** -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  Component's Hooks
   * -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  -------------------------------------------------------------/*


  /**
   * Custom Hooks for the Component
   */
  useDeveloperMapConstructor(
    cameraString,
    props.userLocation,
    setCameraCenter,
    setCameraZoom
  );

  /**
   * Custom Hook for Logic for pinPosition, selectedFeature, userLocation, and getCameraFromUrl
   */
  useDeveloperMapPositionEffect(
    map,
    props,
    cameraString,
    prevProps,
    jumpToCoordinates,
    selectParcelIfAvailable
  );

  /** Logic from componentDidUpdate for parcelToolFromToolbar */
  useEffect(() => {
    if (!prevProps.current) {
      prevProps.current = props;
      return;
    }

    if (prevParcelTool === props.parcelToolFromToolbar || !map.current) {
      return;
    }
    props.resetPolygonMeasurements();
    setDistanceToLastPolygonVertex(null);

    if (mapboxDraw.current !== null && map.current) {
      // Temporarily change mode to `DrawPolygon` to force finalization of unfinished parcels being drawn.
      mapboxDraw.current.changeMode(DrawingMode.DrawPolygon);
      cleanDrawnParcels(mapboxDraw.current.getAll().features);

      switch (props.parcelToolFromToolbar) {
        case ParcelTool.EditParcel:
        case ParcelTool.DeleteParcel:
        case ParcelTool.CopyParcel:
        case ParcelTool.DrawParcel:
          if (props.parcelToolFromToolbar !== ParcelTool.DrawParcel) {
            props.setDrawnParcels([]);
            props.clearFeatureSelection();
          }

          if (!drawingMode) {
            setDrawingMode(true);
          }

          mapboxDraw.current.changeMode(
            PARCEL_TOOL_TO_DRAWING_MODE[props.parcelToolFromToolbar]
          );
          break;
        default:
          if (drawingMode) {
            updateDrawnParcels();
            mapboxDraw.current.changeMode(DrawingMode.HiddenMode);
            setDrawingMode(false);
          }
          break;
      }
    }

    setPrevParcelTool(props.parcelToolFromToolbar);
  }, [props.parcelToolFromToolbar]);

  /**-------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * Custom Hooks for Map Event listeners and Drawing functionalities
   * -------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * ------------------------------------------------------------------------*/

  useDeveloperMapOnIdle(
    map,
    boundingBox,
    setBoundingBox,
    updateUrl,
    props.pinPosition,
    props.selectedFeature,
    props.smartSearchIsOpen,
    selectParcelIfAvailable,
    parcelPointToBeSelected,
    props.parcelTool,
    getParcel,
    props.combineParcelsStart,
    props.selectedFeatureMembers
  );

  drawOnCreate(
    map,
    mapboxDraw,
    props.parcelToolFromToolbar,
    cleanDrawnParcels,
    updateDrawnParcels,
    setParcelPointToBeSelected,
    props.setPolygonIsBeingChanged,
    setDistanceToLastPolygonVertex
  );

  drawOnSelectionChange(
    map,
    mapboxDraw,
    props.parcelToolFromToolbar,
    props.setPolygonIsBeingChanged,
    setDistanceToLastPolygonVertex,
    deleteDrawnParcels,
    updatePolygonInformation
  );

  drawOnUpdate(map, mapboxDraw, parcelIdBeingEdited);

  drawOnModeChange(
    map,
    mapboxDraw,
    props.parcelTool,
    cleanEditedParcel,
    setModeToDrawingOnKeyDown,
    contextMenuClickPoint,
    setDistanceToLastPolygonVertex,
    selectParcelToEdit
  );

  /** -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  Drawing Mode Handlers
   *  -------------------------------------------------------------
   * --------------------------------------------------------------
   * -------------------------------------------------------------- */

  /**
   * Attach the map drawing object to the map and set its event listeners.
   */
  const attachMapDraw = () => {
    if (!map.current || !map) return;
    if (!mapboxDraw.current) {
      mapboxDraw.current = new MapboxDraw({
        displayControlsDefault: false,
        styles: mapboxPresentationProperties.drawingPolygonStyle,
        modes: Object.assign(
          { [DrawingMode.HiddenMode]: hiddenMode },
          MapboxDraw.modes
        ),
        defaultMode: DrawingMode.HiddenMode,
      });

      map.current.addControl(mapboxDraw.current);
    }

    if (mapboxDraw.current && props.drawnParcels.length > 0) {
      Object.values(props.drawnParcels).forEach((currentFeature) =>
        mapboxDraw.current.add(currentFeature)
      );
    }
  };

  /**
   * Handle click when the map is on draw parcel mode.
   */
  const handleDrawingPolygonClick = (point) => {
    if (!mapboxDraw.current) return;

    if (mapboxDraw.current.getMode() !== DrawingMode.DrawPolygon) {
      mapboxDraw.current.changeMode(DrawingMode.DrawPolygon);
    } else if (!props.polygonIsBeingChanged) {
      initializePolygonIsBeingDrawn();
    }

    updateCursorCoordinates(point);
  };

  /**
   * Initialize the polygon being drawn.
   */
  const initializePolygonIsBeingDrawn = () => {
    props.setPolygonIsBeingChanged(true);
    setDistanceToLastPolygonVertex(0);
  };

  /**
   * Copy drawn parcels.
   */
  const selectParcelToCopy = (point) => {
    if (
      !mapboxDraw.current ||
      mapboxDraw.current.getMode() === DrawingMode.DirectSelect
    )
      return;
    let clickedFeatureIds = mapboxDraw.current.getFeatureIdsAt(point);
    let idOfFeatureToCopy = clickedFeatureIds[0];

    if (idOfFeatureToCopy) {
      let feature = mapboxDraw.current
        .getAll()
        .features.reduce((obj, feature) => {
          if (feature.id === idOfFeatureToCopy) return feature;
          return obj;
        }, null);

      if (!feature) return;

      let featureClone = geometry.clone(feature, 0.02);
      mapboxDraw.current.add(featureClone);
      const featureId =
        featureClone.properties[MapStyleProperties.RawParcelFieldId.Id];
      mapboxDraw.current.changeMode(DrawingMode.DirectSelect, { featureId });
    }
  };

  /** -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  Map Handlers
   *  -------------------------------------------------------------
   * --------------------------------------------------------------
   * -------------------------------------------------------------- */

  /**
   * Set up all the map dependent sources, listeners and bindings after it is loaded.
   * NOTE: This handler is only called once in the lifetime of the map component.
   * This means that changing the style will not trigger this event.
   */
  const handleStyleLoad = (mapboxMap: Map) => {
    map.current = mapboxMap;
    if (props.setMap) props.setMap(map.current);

    updateGeocoderProximity();
    attachMapDraw();

    map.current.on("error", (error) => {
      if (
        !error.error.message.includes(
          MapStyleProperties.LayerId.UsaParcelsZoningQuery
        )
      ) {
        console.error(error.error);
      }
    });
  };

  /**
   * Allow the geocoder to recommend Addresses/Places by proximity.
   */
  const updateGeocoderProximity = () => {
    if (map.current) {
      let center = map.current.getCenter().wrap();
      if (center.lng && center.lat)
        props.setProximityCenter([center.lng, center.lat]);
    }
  };

  /**
   * Handle click on the map depending on parcel tool.
   */
  const handleMapClick = (map: Map, event: any) => {
    if (!map) return;
    if (event.type === "contextmenu") {
      contextMenuClickPoint.current =
        props.parcelTool === ParcelTool.DrawParcel ||
        props.parcelTool === ParcelTool.EditParcel
          ? event.point
          : null;
    }

    switch (props.parcelTool) {
      case ParcelTool.SelectParcel:
      case ParcelTool.CombineParcels:
        clearTimeout(smartSearchTimeoutId);
        handleClickedParcel(event.point, map, props.drawnParcels);
        break;
      case ParcelTool.DrawParcel:
        handleDrawingPolygonClick(event.point);
        break;
      case ParcelTool.EditParcel:
        selectParcelToEdit(event.point);
        break;
      case ParcelTool.CopyParcel:
        selectParcelToCopy(event.point);
        break;
    }
  };

  /**
   * Set cursor coordinates from map point.
   */
  const updateCursorCoordinates = (point) => {
    if (map.current) {
      let cursorCoordinates = map.current.unproject(point);
      setCursorCoordinates([cursorCoordinates.lng, cursorCoordinates.lat]);
    }
  };

  /**
   * Handle mouse move over the map depending on map mode.
   */
  const handleMouseMove = (map, event) => {
    if (!map || map.isMoving()) return;

    switch (props.parcelTool) {
      case ParcelTool.DrawParcel:
      case ParcelTool.EditParcel:
        if (props.polygonIsBeingChanged) {
          updateCursorCoordinates(event.point);
          updatePolygonInformation();
        }
        break;
      default:
        break;
    }
  };

  /**
   * Update proximity and camera zoom when camera stops moving.
   */
  const handleMoveEnd = () => {
    updateGeocoderProximity();
  };

  /**
   * Render a tool tip next to the cursor showing of the distance to the last point added to the polygon.
   */
  const renderToolTip = (): any => {
    if (distanceToLastPolygonVertex === null) return;

    let [unitTarget, suffix] =
      props.unitSystem === Unit.System.Metric
        ? [Unit.Type.Meters, " m"]
        : [Unit.Type.Feet, " FT"];
    let convertedValue = unitConversions.convertFromBase(
      distanceToLastPolygonVertex,
      unitTarget
    );

    let formattedValue = valueFormatter.format(convertedValue, {
      type: Format.Type.Number,
      suffix: suffix,
    });
    return (
      <Marker coordinates={cursorCoordinates} offset={TOOL_TIP_OFFSET}>
        <div className="tool-tip">{formattedValue}</div>
      </Marker>
    );
  };

  /**
   * Render the map pin.
   */
  const renderPin = (): any => {
    const { pinPosition, displayPin } = props;
    if (!pinPosition || !displayPin) return;

    return <Marker coordinates={pinPosition} className="map-pin" />;
  };

  /**
   * Handle key down when map is on draw parcel mode.
   */
  const handleKeyDown = (event) => {
    switch (event.key) {
      case KeyCode.Enter:
      case KeyCode.Esc:
        if (props.parcelTool === ParcelTool.DrawParcel) {
          props.resetPolygonMeasurements();
          setDistanceToLastPolygonVertex(null);
          setModeToDrawingOnKeyDown.current = true;
        }
        break;
      case KeyCode.Delete:
        if (props.parcelTool === ParcelTool.EditParcel) {
          contextMenuClickPoint.current = null;
          mapboxDraw.current.trash();
        }
        break;
    }
  };

  /** -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  -------------------------------------------------------------
   *  Parcel Handlers
   *  -------------------------------------------------------------
   * --------------------------------------------------------------
   * -------------------------------------------------------------- */

  /**
   * Generate a complete parcel from raw Mapbox features
   */
  const getCompleteParcelFeatureAtScreenPoint = (point: Point) => {
    let parcelFeature = getDetiledFeatureAtScreenPoint(point);
    if (!parcelFeature) return null;

    return populateParcelProperties({
      parcelFeature,
    });
  };

  /**
   * Get the parcel features at a given point in the screen.
   */
  const getDetiledFeatureAtScreenPoint = (point: Point) => {
    let { id, isDrawn } = getParcelIdFromScreenPoint(point);
    if (!id) return null;

    if (isDrawn) {
      return props.drawnParcels[id];
    }

    return null;
  };

  /**
   * Get the id of the parcel that includes a given screen point in the current viewport.
   */
  const getParcelIdFromScreenPoint = (point: Point) => {
    if (!map || !map.current) return {};

    let drawnFeature = map.current.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.DrawnParcels],
    })[0];

    if (drawnFeature && drawnFeature.properties) {
      return {
        isDrawn: true,
        id: drawnFeature.properties[MapStyleProperties.RawParcelFieldId.Id],
      };
    } else {
      return {};
    }
  };

  /**
   * Handle what action to dispatch depending on the parcelTool and if a parcel was clicked or not.
   */
  const handleClickedParcel = (point, map: Map, drawnParcels) => {
    let queryPoint = point;
    let [clickedParcel, clickedCoordinates] = getParcel(queryPoint);

    if (clickedParcel === null) {
      props.clearFeatureSelection();
      return;
    }

    const parcelId = parcelAccessors.getParcelId(clickedParcel);
    if (parcelId)
      analytics.trackParcelClick(
        parcelId,
        clickedCoordinates as [number, number]
      );

    if (props.parcelTool === ParcelTool.SelectParcel) {
      if (clickedParcel && "type" in clickedParcel) {
        props.selectParcelStart(
          clickedParcel,
          clickedCoordinates as [number, number]
        );
      }
    } else if (props.parcelTool === ParcelTool.CombineParcels) {
      if (clickedParcel && "type" in clickedParcel) {
        props.combineParcelsStart(
          clickedParcel,
          clickedCoordinates as [number, number],
          props.selectedFeatureMembers
        );
      }
    }
  };

  return (
    <div
      className={`component--new-project-map ${props.parcelTool}`}
      onKeyDown={handleKeyDown}
    >
      <Mapbox
        style={props.developerMapStyleUrl}
        center={cameraCenter}
        zoom={cameraZoom}
        pitch={CAMERA_INITIAL_PITCH}
        bearing={CAMERA_INITIAL_BEARING}
        onStyleLoad={handleStyleLoad}
        onClick={handleMapClick}
        onContextMenu={handleMapClick}
        onMouseMove={handleMouseMove}
        onMove={() => clearInterval(smartSearchTimeoutId)}
        onMoveEnd={handleMoveEnd}
        containerStyle={{
          height: "100%",
          width: "100%",
          margin: "0 auto",
        }}
        movingMethod="jumpTo"
      >
        {/* New Project Page Specific Layers */}
        <DrawingInformation />
        <DrawnFeatureLayer />
        <HoveredFeatureLayer />
        <SelectedFeatureLayer />
        <RotationControl position="bottom-right" className="rotation-control" />
        {renderPin()}
        {renderToolTip()}
      </Mapbox>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProjectDeveloperMap);
