/**
 * @fileoverview This component allows the user to authenticate using
 * Google Sign-In as the authentication provider.
 */

import React from "react";
import { connect } from "react-redux";
import analytics from "../../../../utils/analytics";
import authentication, { AuthenticationProviderId } from "../../../../utils/authentication";
import Authentication from "../../../../types/Authentication";
import { userActions } from "../../../../state/user";

const mapDispatchToProps = {
  createUser: userActions.createStart,
};

interface OwnProps {
  onError(errorCode: Authentication.ErrorCode): void;
  termsOfServiceAccepted?: boolean;
}

type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & DispatchProps;

class AuthenticateWithGoogle extends React.PureComponent<Props, {}> {
  /**
   * Authenticate the user using Google as the authentication provider. If the
   * user is a new user, also perform necessary sign-up actions after
   * authenticating.
   */
  signInWithGoogle = async () => {
    if (!this.props.termsOfServiceAccepted) {
      this.props.onError(Authentication.CustomErrorCode.TermsOfServiceNotAccepted);
      return;
    }

    try {
      const userCredential = await authentication.signInWithGooglePopup();
      const isNewUser =
        userCredential && userCredential.additionalUserInfo && userCredential.additionalUserInfo.isNewUser;

      const firstName =
        userCredential &&
        userCredential.additionalUserInfo &&
        userCredential.additionalUserInfo.profile &&
        userCredential.additionalUserInfo.profile["given_name"];
      const lastName =
        userCredential &&
        userCredential.additionalUserInfo &&
        userCredential.additionalUserInfo.profile &&
        userCredential.additionalUserInfo.profile["family_name"];

      if (isNewUser) {
        const user = authentication.getCurrentUser();
        this.props.createUser(user, { firstName, lastName });
        analytics.trackSignUp(AuthenticationProviderId.Google);
      } else {
        analytics.trackLogIn(AuthenticationProviderId.Google);
      }
    } catch (error: any) {
      this.props.onError(error.code);
      analytics.trackAuthenticationError(error, AuthenticationProviderId.Google);
    }
  };

  render() {
    return (
      <button className="component--button-google" onClick={this.signInWithGoogle}>
        Log In with Google
      </button>
    );
  }
}

export default connect(null, mapDispatchToProps)(AuthenticateWithGoogle);
