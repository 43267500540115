import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class DifficultDevelopmentAreasLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.DifficultDevelopmentAreas}
        classes="dda"
        legendRows={[
          <LegendRow
            color="#f18d13"
            text="DDA (Difficult Development Areas)"
          />
        ]}
      />
    );
  }
}

export default DifficultDevelopmentAreasLegend;