import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../../state/newDevelopment";
import LandUseFilters from "./LandUseFilters/LandUseFilters";

const LandUseFiltersContainer = () => {
  const shouldDisplay = useSelector(newDevelopmentSelectors.getLandUseFiltersShouldDisplay);

  return shouldDisplay ? (
    <>
      <LandUseFilters />
      <div className="divisor" />
    </>
  ) : null;
};

export default LandUseFiltersContainer;
