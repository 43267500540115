import valueGetters from "../state/development/utils/valueGetters";
import { ConfigBase, UnitSpecificConfig } from "../types/Config";
import Unit from "../types/Unit";
import authentication from "./authentication";

/**
 * Create configuration object specific for unitSystem, if there is one available.
 */
const getConfigForUnitSystem = (config, unitSystem: Unit.System): UnitSpecificConfig => {
  let unitSpecificConfig: any = {
    ...config,
    ...config[unitSystem],
  }

  unitSpecificConfig.formatOptions = {
    ...config.formatOptions,
    ...unitSpecificConfig.formatOptions
  }

  Object.values(Unit.System).forEach((unitSystem) => delete unitSpecificConfig[unitSystem]);

  return unitSpecificConfig;
}

/**
 * Add the hidden value to the format options of a given config file.
 */
const hydrateHiddenValue = (config: ConfigBase): ConfigBase => {
  const userIsAuthenticated = authentication.isUserAuthenticated();
  const hiddenOptions = {
    formatOptions: {
      ...config.formatOptions,
      hidden: !userIsAuthenticated,
    },
  };

  return {
    ...config,
    ...hiddenOptions,
  }
}

/**
 * Add the generic value getter.
 */
const hydrateGetter = (config: ConfigBase): ConfigBase => {
  return {
    ...config,
    getter: valueGetters.generic
  }
}


export default {
  getConfigForUnitSystem,
  hydrateHiddenValue,
  hydrateGetter,
}
