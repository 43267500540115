import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import Unit from "types/Unit";
import parcelAccessors from "utils/parcel/parcelAccessors";
import downloadAssumptionsCsv from "./utils/downloadAssumptionsCsv";

const mapStateToProps = (state) => {
  return {
    developmentName: developmentSelectors.getName(state),
    deveopmentAddress: developmentSelectors.getAddress(state),
    deveopmentAddressForAssembly: developmentSelectors.getAddressForAssembly(state),
    developmentCity: developmentSelectors.getCity(state),
    deveopmentCityForAssembly: developmentSelectors.getCityForAssembly(state),
    developmentState: developmentSelectors.getState(state),
    deveopmentStateForAssembly: developmentSelectors.getStateForAssembly(state),
    values: developmentSelectors.getValues(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
    usageGroup: developmentSelectors.getBuildingUsageToggles(state),
    subscriptionTier: subscriptionSelectors.getTier(state),
    allCustomSliderNames: developmentSelectors.getAllCustomSliderNames(state),
    parcel: developmentSelectors.getParcel(state),
    projectIsForSale: developmentSelectors.getProjectIsForSale(state),
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class AssumptionsCsvExportButton extends PureComponent<Props, {}> {
  render() {
    const {
      values,
      subscriptionTier,
      usageGroup,
      allCustomSliderNames,
      developmentName,
      deveopmentAddress,
      developmentCity,
      developmentState,
      parcel,
      deveopmentAddressForAssembly,
      deveopmentCityForAssembly,
      deveopmentStateForAssembly,
      projectIsForSale,
    } = this.props;
    const userHasPaidSubscription = subscriptionTier === Tier.Pro || subscriptionTier === Tier.Developer;
    const isAnAssembly = parcelAccessors.getIsAnAssembly(parcel);

    const formattedAddress = !isAnAssembly ? deveopmentAddress : deveopmentAddressForAssembly;
    const formattedCity = !isAnAssembly ? developmentCity : deveopmentCityForAssembly;
    const formattedState = !isAnAssembly ? developmentState : deveopmentStateForAssembly;

    return (
      <button
        className={`component--assumptions-csv-export-button ${userHasPaidSubscription ? "" : "disabled"}`}
        onClick={
          userHasPaidSubscription
            ? () =>
                downloadAssumptionsCsv(
                  values,
                  Unit.System.Imperial,
                  usageGroup,
                  allCustomSliderNames,
                  developmentName,
                  formattedAddress,
                  formattedCity,
                  formattedState,
                  isAnAssembly,
                  projectIsForSale
                )
            : undefined
        }
      >
        Export Assumptions to CSV
      </button>
    );
  }
}
export default connect(mapStateToProps)(AssumptionsCsvExportButton);
