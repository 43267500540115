const LOAD_START = "user/LOAD_START";
const LOAD_SUCCESS = "user/LOAD_SUCCESS";
const CREATE_START = "user/CREATE_START";
const CREATE_SUCCESS = "user/CREATE_SUCCESS";
const GET_LOCATION_START = "user/GET_LOCATION_START";
const GET_LOCATION_SUCCESS = "user/GET_LOCATION_SUCCESS";
const GET_LOCATION_ERROR = "user/GET_LOCATION_ERROR";
const SET_IS_ONBOARDED = "user/SET_IS_ONBOARDED";
const UPLOAD_IMAGE_START = "user/UPLOAD_IMAGE_START";
const UPLOAD_IMAGE_SUCCESS = "user/UPLOAD_IMAGE_SUCCESS";
const UPDATE_START = "user/UPDATE_START";
const UPDATE_SUCCESS = "user/UPDATE_SUCCESS";
const EMAIL_VERIFIED = "user/EMAIL_VERIFIED";

export default {
  LOAD_START,
  LOAD_SUCCESS,
  CREATE_START,
  CREATE_SUCCESS,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_START,
  GET_LOCATION_ERROR,
  SET_IS_ONBOARDED,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_SUCCESS,
  UPDATE_START,
  UPDATE_SUCCESS,
  EMAIL_VERIFIED,
};
