import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class CapRateLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.CapRates}
        classes="cap-rates"
        legendRows={[
          <LegendRow
            color="#f9e81e"
            text="< 3.8 %"
            circle
          />,
          <LegendRow
            color="#39e41b"
            text="3.8 - 5.8 %"
            circle
          />,
          <LegendRow
            color="#1be4c6"
            text="5.8 - 7.8 %"
            circle
          />,
          <LegendRow
            color="#0b55ea"
            text="7.8 - 9 %"
            circle
          />,
          <LegendRow
            color="#9c10cb"
            text="> 9 %"
            circle
          />,
        ]}
      />
    );
  }
}

export default CapRateLegend;
