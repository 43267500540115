import React from "react";
import { connect } from "react-redux";
import { Chargebee } from "../../../types/Service/Chargebee";
import { subscriptionSelectors } from "../../../state/subscription";
import WithPanelHeader from "../../sharedComponents/WithPanelHeader";
import ProTierPanel from "./ProTierPanel";
import EnterpriseTierPanel from "./EnterpriseTierPanel";
import DeveloperTierPanel from "./DeveloperTierPanel";

const mapStateToProps = (state) => {
  return {
    planPrices: subscriptionSelectors.getPlanPrices(state),
  }
}

interface OwnProps {
  presentationOnly?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

interface State {
  planPeriodIsYearly: boolean
}

class TiersPanel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      planPeriodIsYearly: false,
    }
  }

  /**
   * Get difference in percentages between monthly and yearly plans.
   */
  getPercentageDifference = (monthlyPlanId: Chargebee.PlanId, yearlyPlanId: Chargebee.PlanId) => {
    const monthlyPrice = Number(this.props.planPrices[monthlyPlanId]) * 12;
    const yearlyPrice = Number(this.props.planPrices[yearlyPlanId]);
    return Math.floor(((monthlyPrice - yearlyPrice) / monthlyPrice) * 100);
  }

  /**
   * Calculate the maximum amount of savings for all plans.
   */
  getMaximumSavingsPercentage = () => {
    let maxSavings = Math.max(
      this.getPercentageDifference(Chargebee.PlanId.DeveloperMonthly, Chargebee.PlanId.DeveloperYearly),
      this.getPercentageDifference(Chargebee.PlanId.AdvancedMonthly, Chargebee.PlanId.AdvancedYearly),
      this.getPercentageDifference(Chargebee.PlanId.ProMonthly, Chargebee.PlanId.ProYearly),
    );

    return `You can save ${maxSavings}% by selecting the annual plan`;
  }

  render() {
    const { planPeriodIsYearly } = this.state;
    const { presentationOnly } = this.props;

    return (
      <WithPanelHeader title="Subscription Plans" hideControls>
        <div className="component--tiers-panel">
          <div className="background" />
          <div className="sub-title-box">
            <label className="subtitle-label">Development Leads Automated</label>
          </div>
          <div className="toggle-wrapper">
            <div
              className={`toggle-button ${planPeriodIsYearly ? "" : "selected"}`}
              onClick={() => this.setState({ planPeriodIsYearly: false })}
            >
              Bill Monthly
            </div>
            <div
              className={`toggle-button ${planPeriodIsYearly ? "selected" : ""}`}
              onClick={() => this.setState({ planPeriodIsYearly: true })}
            >
              Bill Yearly
            </div>
          </div>

          <div className="discount-message">
            {this.getMaximumSavingsPercentage()}
          </div>

          <div className="tiers-wrapper">
            <DeveloperTierPanel planPeriodIsYearly={planPeriodIsYearly} presentationOnly={presentationOnly} />
            <ProTierPanel planPeriodIsYearly={planPeriodIsYearly} presentationOnly={presentationOnly} />
            <EnterpriseTierPanel presentationOnly={presentationOnly} />
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
)(TiersPanel);
