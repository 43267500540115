import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";

class BuildingsLegend extends React.PureComponent<{}, {}> {

  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Buildings}
        classes="buildings"
      />
    );
  }
}

export default BuildingsLegend;
