import React from "react";
import { allTogglesDisabled } from "../../../../../../../utils/uiToggleHelper";
import SectionTitle from "../../../sharedComponents/SectionTitle";
import { color } from "../../../../../../../utils/colors";
import PdfContext from "../../../../PdfContext";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";
import { View, StyleSheet } from "@react-pdf/renderer";

const PADDING_BOTTOM = 9;
const BORDER_BOTTOM = 0.48;
const BORDER_COLOR = color("silver");

const styles = StyleSheet.create({
  contentContainer: {
    marginTop: 20,
  },
});

// const Container = styled.View`
//   margin-top: 20px;
// `;

const Section = (props) => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      if (props.requiresUsages && allTogglesDisabled(toggles)) return null;

      return (
        <View style={styles.contentContainer}>
          <SectionTitle
            title={props.title}
            titleIcon={props.titleIcon}
            paddingBottom={PADDING_BOTTOM}
            borderBottom={BORDER_BOTTOM}
            borderColor={BORDER_COLOR}
          />

          {props.children}
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Section;
