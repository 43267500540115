import React from "react";
import { connect } from "react-redux";
import {
  developmentSelectors,
  developmentActions,
} from "../../../../../state/development";
import SliderBox from "../../sharedComponents/SliderBox";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import config from "./config";
import Dropdown from "../../../../sharedComponents/Dropdown";
import RemoveSetbackPopup from "./RemoveSetbackPopup";
import PanelSectionTitle from "../../../../sharedComponents/PanelSectionTitle";
import {
  setbackModeActions,
  setbackModeSelectors,
} from "../../../../../state/setbackMode";
import { mapsSelectors } from "state/ui/shared/maps";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    setbacks: developmentSelectors.getSetbacks(state),
    buildingModel: developmentSelectors.getBuildingModel(state),
    floorsWithSetbacks: developmentSelectors.getFloorsWithSetbacks(state),
    selectedSetbackFloor: developmentSelectors.getSelectedSetbackFloor(state),
    selectedSetbackFloorIndex:
      developmentSelectors.getSelectedSetbackFloorIndex(state),
    setbackModeIsActive: setbackModeSelectors.getSetbackModeIsActive(state),
    mapIsReady: mapsSelectors.getMapIsReady(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  addSetbackFloor: developmentActions.addSetbackFloor,
  removeSetbackFloor: developmentActions.removeSetbackFloor,
  resetSelectedSetbackFloor: developmentActions.resetSelectedSetbackFloor,
  setSetbackModeIsActive: setbackModeActions.setSetbackMode,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  floorListIsOpen: boolean;
  setbackToRemove: number | null;
}

class HeightAndSetbacks extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      floorListIsOpen: false,
      setbackToRemove: null,
    };
  }

  /**
   * Reset selected setback floor.
   */
  componentWillUnmount() {
    this.props.resetSelectedSetbackFloor();
  }

  /**
   * Toggle the `floorListIsOpen` state.
   */
  toggleFloorListIsOpen = () => {
    this.setState({ floorListIsOpen: !this.state.floorListIsOpen });
  };

  /**
   * Set the `floorListIsOpen` state to false.
   */
  closeFloorListIsOpen = () => {
    this.setState({ floorListIsOpen: false });
  };

  /**
   * Remove a setback.
   */
  removeSetback = () => {
    const { setbackToRemove } = this.state;
    if (!setbackToRemove) return;
    this.props.removeSetbackFloor(setbackToRemove);
  };

  /**
   * Reset the `setbackToRemove` state to null when the remove setback popup closes.
   */
  handleRemoveSetbackPopupStateChange = (event) => {
    if (event) event.stopPropagation();
    this.setState({ setbackToRemove: null });
  };

  /**
   * Set `setbackToRemove` in the state.
   */
  removeSetbackClick = (event, selectedSetbackFloor: number) => {
    event.stopPropagation();
    if (selectedSetbackFloor === 0) {
      this.setState({ setbackToRemove: null, floorListIsOpen: false });
      return;
    }

    this.setState({ setbackToRemove: selectedSetbackFloor });
  };

  /**
   * Render the list of floors.
   */
  renderDropdownList = () => {
    const floors = this.props.buildingModel.floors;
    const { floorsWithSetbacks } = this.props;

    return floors.map((floor, floorIndex) => (
      <li key={floorIndex}>
        <div
          className={`button ${
            floorsWithSetbacks.includes(floorIndex) ? "with-setback" : ""
          }`}
          onClick={() => this.props.addSetbackFloor(floorIndex)}
        >
          <span>
            {floorIndex === 0 ? "Ground Floor" : `Floor ${floorIndex + 1}`}
          </span>
          {floorIndex > 0 && floorsWithSetbacks.includes(floorIndex) && (
            <div
              className="remove-setback"
              onClick={(event) => this.removeSetbackClick(event, floorIndex)}
            />
          )}
        </div>
      </li>
    ));
  };

  /**
   * Render inactive setbacks.
   */
  renderInactiveSetbacks = () => {
    const floors = this.props.buildingModel.floors;
    const { floorsWithSetbacks } = this.props;

    const inactiveSetbacks = floorsWithSetbacks.filter(
      (floorWithSetbacks) => floorWithSetbacks >= floors.length
    );
    if (inactiveSetbacks.length === 0) return null;

    return (
      <>
        <li className="inactive-divisor">
          <div>Inactive Setbacks</div>
        </li>
        {inactiveSetbacks.map((inactiveSetbackIndex) => (
          <li key={inactiveSetbackIndex}>
            <div
              className="button inactive"
              onClick={() => this.props.addSetbackFloor(inactiveSetbackIndex)}
            >
              <span>
                {inactiveSetbackIndex === 0
                  ? "Ground Floor"
                  : `Floor ${inactiveSetbackIndex + 1}`}
              </span>
              {
                <div
                  className="remove-setback"
                  onClick={(event) =>
                    this.removeSetbackClick(event, inactiveSetbackIndex)
                  }
                />
              }
            </div>
          </li>
        ))}
      </>
    );
  };

  /**
   * Render the floor selection menu.
   */
  renderFloorSelectionMenu = () => {
    let { selectedSetbackFloor } = this.props;

    return (
      <Dropdown
        isActive={this.state.floorListIsOpen}
        onClick={this.toggleFloorListIsOpen}
        onBlur={this.closeFloorListIsOpen}
        toggleButton={
          <div className="floor-toggle-button">
            {selectedSetbackFloor === 0
              ? "Ground Floor"
              : `Floor ${selectedSetbackFloor + 1}`}
            <div className="down-arrow" />
          </div>
        }
      >
        {this.renderDropdownList()}
        {this.renderInactiveSetbacks()}
      </Dropdown>
    );
  };

  /**
   * Render Building Height section.
   */
  renderBuildingHeightSection = () => {
    return (
      <>
        <ConfigurationHandler
          config={config.buildingHeight}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.heightOfGroundFloor}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.heightOfTypicalFloor}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </>
    );
  };

  /**
   * Toggle setback mode.
   */
  toggleSetbackSelectionMode = () => {
    this.props.setSetbackModeIsActive(!this.props.setbackModeIsActive);
  };

  /**
   * Render Setback Mode section.
   */
  renderSetbackModeSection = () => {
    return (
      <>
        <div className="setback-mode-title">
          <PanelSectionTitle text="Setbacks" />
        </div>

        <div className="text-box">
          In setback mode, select any floor and assign setbacks to each side.{" "}
          <br />
          Setbacks will adjust to that floor and above, or until the next set of{" "}
          <br />
          setbacks above.
        </div>

        {this.renderFloorSelectionMenu()}
      </>
    );
  };

  /**
   * Check if a given setback sliderBox should be disabled (i.e. the setback type is not being used).
   */
  sliderBoxIsDisabled = (setbackType) => {
    let disabled = true;
    for (let polygonSetbacks of this.props.setbacks) {
      if (polygonSetbacks.includes(setbackType)) {
        disabled = false;
        break;
      }
    }

    return disabled;
  };

  /**
   * Render Building Setback section.
   */
  renderBuildingSetbacksSection = () => {
    const { selectedSetbackFloorIndex } = this.props;
    return (
      <>
        <ConfigurationHandler
          config={config.siteCoveragePercentage}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.setbackA}
            component={SliderBox}
            index={selectedSetbackFloorIndex}
            disabled={this.sliderBoxIsDisabled("A")}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.setbackB}
            component={SliderBox}
            index={selectedSetbackFloorIndex}
            disabled={this.sliderBoxIsDisabled("B")}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.setbackC}
            component={SliderBox}
            index={selectedSetbackFloorIndex}
            disabled={this.sliderBoxIsDisabled("C")}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.setbackD}
            component={SliderBox}
            index={selectedSetbackFloorIndex}
            disabled={this.sliderBoxIsDisabled("D")}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </>
    );
  };

  /**
   * Render `Go to Setbacks Mode` button
   */
  renderSetbacksButton = () => {
    const { tier, mapIsReady } = this.props;
    const isProTier = tier === Tier.Pro;
    return (
      <button
        type="button"
        onClick={this.toggleSetbackSelectionMode}
        className={`${this.props.setbackModeIsActive ? "active" : ""}`}
        disabled={!mapIsReady && isProTier}
      >
        {`${
          this.props.setbackModeIsActive ? "Exit Setbacks" : "Edit Setbacks"
        }`}
      </button>
    );
  };

  render() {
    const { setbackToRemove } = this.state;

    return (
      <div className="component--height-and-setbacks">
        <div className="panel-inner-wrapper">
          {this.renderBuildingHeightSection()}
          {this.renderSetbackModeSection()}
          {this.renderBuildingSetbacksSection()}
        </div>
        {setbackToRemove && (
          <RemoveSetbackPopup
            setbackToRemove={setbackToRemove}
            onStateChange={this.handleRemoveSetbackPopupStateChange}
            onConfirm={this.removeSetback}
          />
        )}

        <div className="button-wrapper">{this.renderSetbacksButton()}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeightAndSetbacks);
