import React from "react";
import { Layer } from "react-mapbox-gl";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties";
import { RawDynamicLayersFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { connect } from "react-redux";
import { paintDemographicDynamicLayerFill } from "utils/mapbox/layerPaintersHelper";

const FIELD_TO_FILL_LAYER_ID = {
  [RawDynamicLayersFieldId.Population2010]: MapStyleProperties.LayerId.DynamicPopulationFill_2010,
  [RawDynamicLayersFieldId.Population2011]: MapStyleProperties.LayerId.DynamicPopulationFill_2011,
  [RawDynamicLayersFieldId.Population2012]: MapStyleProperties.LayerId.DynamicPopulationFill_2012,
  [RawDynamicLayersFieldId.Population2013]: MapStyleProperties.LayerId.DynamicPopulationFill_2013,
  [RawDynamicLayersFieldId.Population2014]: MapStyleProperties.LayerId.DynamicPopulationFill_2014,
  [RawDynamicLayersFieldId.Population2015]: MapStyleProperties.LayerId.DynamicPopulationFill_2015,
  [RawDynamicLayersFieldId.Population2016]: MapStyleProperties.LayerId.DynamicPopulationFill_2016,
  [RawDynamicLayersFieldId.Population2017]: MapStyleProperties.LayerId.DynamicPopulationFill_2017,
  [RawDynamicLayersFieldId.Population2018]: MapStyleProperties.LayerId.DynamicPopulationFill_2018,
  [RawDynamicLayersFieldId.Population2019]: MapStyleProperties.LayerId.DynamicPopulationFill_2019,
  [RawDynamicLayersFieldId.Population2020]: MapStyleProperties.LayerId.DynamicPopulationFill_2020,
  [RawDynamicLayersFieldId.Population2021]: MapStyleProperties.LayerId.DynamicPopulationFill_2021,
  [RawDynamicLayersFieldId.Population2022]: MapStyleProperties.LayerId.DynamicPopulationFill_2022,
};

const FIELD_TO_BORDER_LAYER_ID = {
  [RawDynamicLayersFieldId.Population2010]: MapStyleProperties.LayerId.DynamicPopulationBorder_2010,
  [RawDynamicLayersFieldId.Population2011]: MapStyleProperties.LayerId.DynamicPopulationBorder_2011,
  [RawDynamicLayersFieldId.Population2012]: MapStyleProperties.LayerId.DynamicPopulationBorder_2012,
  [RawDynamicLayersFieldId.Population2013]: MapStyleProperties.LayerId.DynamicPopulationBorder_2013,
  [RawDynamicLayersFieldId.Population2014]: MapStyleProperties.LayerId.DynamicPopulationBorder_2014,
  [RawDynamicLayersFieldId.Population2015]: MapStyleProperties.LayerId.DynamicPopulationBorder_2015,
  [RawDynamicLayersFieldId.Population2016]: MapStyleProperties.LayerId.DynamicPopulationBorder_2016,
  [RawDynamicLayersFieldId.Population2017]: MapStyleProperties.LayerId.DynamicPopulationBorder_2017,
  [RawDynamicLayersFieldId.Population2018]: MapStyleProperties.LayerId.DynamicPopulationBorder_2018,
  [RawDynamicLayersFieldId.Population2019]: MapStyleProperties.LayerId.DynamicPopulationBorder_2019,
  [RawDynamicLayersFieldId.Population2020]: MapStyleProperties.LayerId.DynamicPopulationBorder_2020,
};

const FIELD_TO_SYMBOL_LAYER_ID = {
  [RawDynamicLayersFieldId.Population2010]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2010,
  [RawDynamicLayersFieldId.Population2011]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2011,
  [RawDynamicLayersFieldId.Population2012]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2012,
  [RawDynamicLayersFieldId.Population2013]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2013,
  [RawDynamicLayersFieldId.Population2014]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2014,
  [RawDynamicLayersFieldId.Population2015]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2015,
  [RawDynamicLayersFieldId.Population2016]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2016,
  [RawDynamicLayersFieldId.Population2017]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2017,
  [RawDynamicLayersFieldId.Population2018]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2018,
  [RawDynamicLayersFieldId.Population2019]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2019,
  [RawDynamicLayersFieldId.Population2020]: MapStyleProperties.LayerId.DynamicPopulationSymbol_2020,
};

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DynamicPopulationLayer extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer) return null;
    if (getSelectedDynamicLayer !== MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population) return null;
    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    return (
      <>
        <Layer
          id={FIELD_TO_FILL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={paintDemographicDynamicLayerFill(
            layerSelected,
            MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
          )}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
        <Layer
          id={FIELD_TO_BORDER_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={mapboxPresentationProperties.demographicsLinePaint(layerSelected)}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
        <Layer
          id={FIELD_TO_SYMBOL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          layout={mapboxPresentationProperties.demographicsLabelsSymbolLayout(layerSelected)}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          minZoom={12}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    );
  }
}

export default connect(mapStateToProps)(DynamicPopulationLayer);
