import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class EducationLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.EducationFacilities}
        classes="education"
        legendRows={[
          <LegendRow
            color="#94eda1"
            text="College / University"
            circle
          />,
          <LegendRow
            color="#33a02c"
            text="School"
            circle
          />,
          <LegendRow
            color="#b7d2e1"
            text="School: Elementary"
            circle
          />,
          <LegendRow
            color="#65b0d6"
            text="School: High School"
            circle
          />,
          <LegendRow
            color="#4a56b9"
            text="School: Middle School"
            circle
          />,
          <LegendRow
            color="#4acbc6"
            text="Technical / Trade School"
            circle
          />,
        ]}
      />
    );
  }
}

export default EducationLegend;
