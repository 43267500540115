import authentication from "../utils/authentication";
import { developmentActions, developmentSelectors } from "./development";
import developmentActionTypes from "./development/actionTypes";
import { pdfSelectors } from "./pdf";
import pdfActionTypes from "./pdf/actionTypes";
import { thumbnailsSelectors } from "./thumbnails";
import { userSelectors } from "./user";
import userActionTypes from "./user/actionTypes";

const AUTOSAVE_TIMEOUT = 3000; // 3 seconds
let autoSaveId: any;

const AUTOSAVE_TRIGGER_ACTIONS = [
  developmentActionTypes.RE_CALCULATE,
  developmentActionTypes.SET_UNIT_SYSTEM,
  developmentActionTypes.SET_NAME,
  developmentActionTypes.SET_RANGED_INPUT,
  developmentActionTypes.SET_RANGED_INPUT_MINIMUM,
  developmentActionTypes.SET_RANGED_INPUT_MAXIMUM,
  developmentActionTypes.SET_SETBACK_TYPE,
  developmentActionTypes.TOGGLE_BOOLEAN_INPUT,
  developmentActionTypes.SET_CAMERA,
  developmentActionTypes.ADD_SETBACK_FLOOR,
  developmentActionTypes.REMOVE_SETBACK_FLOOR,
  developmentActionTypes.KEEP_PROJECT,
  developmentActionTypes.SET_PROJECT_IS_FOR_SALE,
  developmentActionTypes.SET_FLOOR_PLAN,
  developmentActionTypes.SET_CUSTOM_SLIDER_NAMES,
  pdfActionTypes.SET_TITLE,
  pdfActionTypes.SET_SUMMARY,
  pdfActionTypes.SET_TO_CONTACTS_DETAILS,
  pdfActionTypes.SET_FROM_CONTACTS_DETAILS,
  pdfActionTypes.SET_COLOR_PALETTE,
  pdfActionTypes.SET_ADDRESS,
  userActionTypes.SET_IS_ONBOARDED,
];

/**
 * Stop the auto saving timeout.
 */
const stopAutoSave = () => {
  clearInterval(autoSaveId);
}

/**
 * Dispatch the autosave on the right actions.
 */
const autosaveMiddleware = (store) => (next) => (action) => {
  if (AUTOSAVE_TRIGGER_ACTIONS.includes(action.type)) {
    stopAutoSave();
    autoSaveId = setTimeout(
      () => {
        store.dispatch(developmentActions.saveStart());
      }, AUTOSAVE_TIMEOUT
    );
  } else if (action.type === developmentActionTypes.SAVE_START) {
    const currentState = store.getState();
    const user = authentication.getCurrentUser();
    const {
      developmentId,
      developmentDocument,
      userDocument,
      pdfDocument,
      thumbnail
    } = Object.assign({}, {
      developmentId: developmentSelectors.getProjectId(currentState),
      developmentDocument: developmentSelectors.getDevelopment(currentState),
      userDocument: userSelectors.getUserDocument(currentState),
      pdfDocument: pdfSelectors.getPdfDocument(currentState),
      thumbnail: thumbnailsSelectors.getCurrentThumbnail(currentState),
    })

    stopAutoSave();
    action.payload = {
      user,
      developmentId,
      developmentDocument,
      userDocument,
      pdfDocument,
      thumbnail
    };
  }

  return next(action);
}

export default autosaveMiddleware;
