import React from "react";
import { Layer, Source } from "react-mapbox-gl";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties";
import { connect } from "react-redux";
import { seaLevelRiseLinePaint } from "utils/mapbox/layerPaintersHelper";
import { paintSeaLevelRiseDynamicLayerFill } from "utils/mapbox/layerPaintersHelper";

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DynamicSeaLevelRiseLayer extends React.PureComponent<Props, {}> {

  render() {
    const { getSelectedDynamicLayer, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer) return null;
    if (getSelectedDynamicLayer !== MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise) return null;
    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if(!layerSelected) return null;

    return (
      <>
        <Source
        id={MapStyleProperties.SourceLayerId.SeaLevelRise}
        tileJsonSource={{
          type: MapStyleProperties.SourceType.Vector,
          tiles: MapStyleProperties.SOURCE_ID_TO_URL_MAP[MapStyleProperties.SourceId.SeaLevelRise]
        }}
      />
        <Layer
          id={MapStyleProperties.LayerId.SeaLevelRiseFill}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.DynamicLayers.SeaLevelRiseSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.SeaLevelRiseSourceLayer}
          paint={paintSeaLevelRiseDynamicLayerFill(layerSelected)}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
          <Layer
          id={MapStyleProperties.LayerId.SeaLevelRiseBorder}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MapStyleProperties.DynamicLayers.SeaLevelRiseSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.SeaLevelRiseSourceLayer}
          paint={seaLevelRiseLinePaint(layerSelected)}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
      </>
    )
  }
}

export default connect(
  mapStateToProps
)(DynamicSeaLevelRiseLayer);
