const MISSING_RETURN_ON_COST = -42; // Flag indicating that the project still doesn't have a return on cost value.

/**
 * Add saleReturnOnInvestmentForBackOfEnvelope and leaseUsesReturnOnCost to user projects
 * and remove condoReturnOnCost.
 */
const updateReturnOnCost = (user) => {
  for (let projectId of Object.keys(user.projects)) {
    if(projectId === "null" || !user.projects[projectId]) {
      delete user.projects[projectId];
      continue;
    }

    user.projects[projectId] = {
      ...user.projects[projectId],
      leaseUsesReturnOnCost: MISSING_RETURN_ON_COST,
      saleReturnOnCost: MISSING_RETURN_ON_COST,
      isForSale: null,
    }

    // Firestore sentinel values are not needed for deletion because this is a nested object.
    delete user.projects[projectId].condoReturnOnCost;
  }
}

export default updateReturnOnCost;
