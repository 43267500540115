import { AppState } from "../../../rootReducer";
import { AuthenticationUiState } from "./reducers";

/**
 * Select the whole ui state object.
 */
const getAuthenticationState = (state: AppState): AuthenticationUiState => {
  return state.ui.shared.authentication;
}

/**
 * Select the loginIsOpen flag.
 */
const getLoginIsOpen = (state: AppState): boolean => {
  const shared = getAuthenticationState(state);
  return shared.loginIsOpen;
}

export default {
  getLoginIsOpen,
}
