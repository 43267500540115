import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import parcelAccessors from "../../../parcel/parcelAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import generatorForCondo from "./generatorForCondo";
import generatorForMultifamily from "./generatorForMultifamily";
import generatorForHotel from "./generatorForHotel";
import generatorForOffice from "./generatorForOffice";
import generatorForRetail from "./generatorForRetail";
import generatorForIndustrial from "./generatorForIndustrial";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";

/**
 * @fileoverview This module is responsible for generating presets for buildable
 * values such that they meet the target Floor Area Ratio (FAR), by distributing
 * the buildable area among a development's toggled uses.
 *
 * There are 5 uses, each of which can either be on or off, for a total of
 * 2^5 = 32 possible combinations. In order to choose between the 32 possible
 * combinations of the 5 building uses we are going to assign an integer number
 * between 0 and 31 to each combination. In those integers' binary
 * representation, each bit will represent a usage, where 1 means use enabled
 * and 0 use disabled. The uses order would be as follows:
 *
 * 0. industrial  -> 2^0
 * 1. office      -> 2^1
 * 2. hotel       -> 2^2
 * 3. multifamily -> 2^3
 * 4. condo       -> 2^4
 *
 * Example:
 *
 * The number 23 in binary is 10111 which means that the industrial, office, hotel and
 * condo uses are enabled.
 */

const USES = [BuildingUse.Industrial, BuildingUse.Office, BuildingUse.Hotel, BuildingUse.Multifamily, BuildingUse.Condo];
const USE_INDEX_OFFSETS = Object.assign({}, ...USES.map((use, index) => ({ [use]: Math.pow(2, index) })));

const USE_AREA_PERCENTAGES = [
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 1.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 1.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.80, [BuildingUse.Industrial]: 0.20 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 1.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.90, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.70, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.65, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.05 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 1.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.90, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.70, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.65, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.05 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.70, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.60, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.50, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.20, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.00, [BuildingUse.Multifamily]: 0.40, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.20, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 1.00, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.80, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.20 },
  { [BuildingUse.Condo]: 0.70, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.50, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.40, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.60, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.40, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.50, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.40, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.50, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.20, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.50, [BuildingUse.Multifamily]: 0.00, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.15, [BuildingUse.Industrial]: 0.05 },
  { [BuildingUse.Condo]: 0.50, [BuildingUse.Multifamily]: 0.50, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.45, [BuildingUse.Multifamily]: 0.45, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.40, [BuildingUse.Multifamily]: 0.40, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.20, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.30, [BuildingUse.Multifamily]: 0.30, [BuildingUse.Hotel]: 0.00, [BuildingUse.Office]: 0.30, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.35, [BuildingUse.Multifamily]: 0.35, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.30, [BuildingUse.Multifamily]: 0.30, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.00, [BuildingUse.Industrial]: 0.10 },
  { [BuildingUse.Condo]: 0.30, [BuildingUse.Multifamily]: 0.30, [BuildingUse.Hotel]: 0.30, [BuildingUse.Office]: 0.10, [BuildingUse.Industrial]: 0.00 },
  { [BuildingUse.Condo]: 0.25, [BuildingUse.Multifamily]: 0.25, [BuildingUse.Hotel]: 0.25, [BuildingUse.Office]: 0.20, [BuildingUse.Industrial]: 0.05 },
];

const DEFAULT_PERCENTAGES = USE_AREA_PERCENTAGES[31];

const GENERATOR_BY_USE = {
  [BuildingUse.Condo]: generatorForCondo,
  [BuildingUse.Multifamily]: generatorForMultifamily,
  [BuildingUse.Hotel]: generatorForHotel,
  [BuildingUse.Office]: generatorForOffice,
  [BuildingUse.Industrial]: generatorForIndustrial,
  [BuildingUse.Retail]: generatorForRetail,
}

/**
 * Get the index on the USE_AREA_PERCENTAGES array of an specific uses combination.
 */
const getUsesCombinationIndex = (toggleValues: object): number => {
  return Object.keys(toggleValues)
    .filter((use) => use !== BuildingUse.Retail)
    .reduce(
      (accumulator, buildingUse) => {
        return accumulator += toggleValues[buildingUse] ? USE_INDEX_OFFSETS[buildingUse] : 0;
      }, 0
    );
}

/**
 * Get the percentage for each building use.
 */
const getAreaPercentages = (development: Development): object => {
  const toggleValues = developmentAccessors.getBuildingUsageToggles(development);
  return USE_AREA_PERCENTAGES[getUsesCombinationIndex(toggleValues)];
}

/**
 * Generate a JSON object with the presets for buildable areas, using the given
 * Floor Area Ratio value. Presets are generated for each enabled building use
 * on the development object, such that the total buildable area of all uses
 * results in the given Floor Area Ratio.
 */
const generatePresetsForBuildables = async (development: Development, targetFloorAreaRatio: number): Promise<object> => {
  const parcel = developmentAccessors.getParcel(development);
  const parcelArea = parcelAccessors.getAreaPreferPublished(parcel);
  let totalTargetArea = targetFloorAreaRatio * parcelArea;
  const areaPercentages = getAreaPercentages(development);
  let remainingArea = totalTargetArea;

  developmentAccessors.update(development);

  const firstFloor = developmentAccessors.getFloors(development)[0];
  const footprintArea = firstFloor.footprint.area;

  let result: object = {};

  const toggleValues = developmentAccessors.getBuildingUsageToggles(development);
  let retailArea = 0;
  const parkingArea = Number(developmentAccessors.getInputValue(development, VariableId.ParkingGrossBuildableArea));

  if (toggleValues[BuildingUse.Retail]) {
    const otherUsesEnabled = USES.some((use) => toggleValues[use]);
    retailArea = otherUsesEnabled ? (footprintArea / 4) : totalTargetArea;
    const generatorResult = await GENERATOR_BY_USE[BuildingUse.Retail](development, retailArea)
    result = { ...result, ...generatorResult };
    remainingArea -= retailArea;
    totalTargetArea -= retailArea;
  }

  let firstUseBuilt = false;
  for (let use of USES) {
    const initialTargetArea = totalTargetArea * (areaPercentages[use] || DEFAULT_PERCENTAGES[use]);
    let targetArea = initialTargetArea;
    if (toggleValues[use]) {
      let factor;
      if (!firstUseBuilt) {
        factor = Math.round((targetArea + retailArea + parkingArea) / footprintArea);
        targetArea = Math.max(Math.min((factor * footprintArea) - retailArea - parkingArea, remainingArea), 0) || initialTargetArea;
        firstUseBuilt = true;
      } else {
        factor = Math.round(targetArea / footprintArea);
        targetArea = Math.max(Math.min(factor * footprintArea, remainingArea), 0) || initialTargetArea;
      }
      remainingArea -= targetArea;
    }

    let generatorResult = await GENERATOR_BY_USE[use](development, targetArea);
    result = { ...result, ...generatorResult };
  }

  return result;
}

export default generatePresetsForBuildables;
