/**
 * @fileoverview This module handles event tracking with Google Tag Manager (GTM). The functions exported by
 * this module fall under one of the following categories:
 *
 * - Authenticated Events: These events happen while the user is logged in.
 * - Unauthenticated Events: These events happen while the user is not logged in.
 * - Authentication Events: These events happen while the user is being authenticated (i.e. sign up or log in).
 *
 * TODO: This whole analytics subsystem should be a middleware which listens to the right
 * action events and tracks them accordingly. In the meantime, we are importing the redux store to get the
 * current subscription plan id.
 */

import authentication, { AuthenticationProviderId } from "./authentication";
import { Chargebee } from "../types/Service/Chargebee";
import intercom from "./intercom";
import Unit from "../types/Unit";
import { BuildingUse } from "../types/BuildingUse";
import { ParcelTool } from "../types/ParcelTool";
import { FilterId } from "../types/Filter";
import geocoder from "./mapbox/geocoder";
import addressHelper from "./addressHelper";
import turf from "./turf";
import geometry from "./geometry";
import { subscriptionSelectors } from "../state/subscription";
import reduxStore from "../state/reduxStore";
import { userSelectors } from "../state/user";

// These are the event names that Google Tag Manager triggers should respond to.
// Authentication events
const SIGN_UP_EVENT = "deepblocks/SIGN_UP";
const SIGN_IN_EVENT = "deepblocks/SIGN_IN";
const AUTHENTICATION_ERROR_EVENT = "deepblocks/AUTHENTICATION_ERROR";
const SIGN_OUT_EVENT = "deepblocks/SIGN_OUT";
const EMAIL_VERIFICATION_EVENT = "deepblocks/EMAIL_VERIFICATION";
const REQUEST_PASSWORD_CHANGE_EVENT = "deepblocks/REQUEST_PASSWORD_CHANGE";
const PASSWORD_CHANGE_EVENT = "deepblocks/PASSWORD_CHANGE";
// Subscription events.
const CHECKOUT_START_EVENT = "deepblocks/CHECKOUT_START";
const CHECKOUT_SUCCESS_EVENT = "deepblocks/CHECKOUT_SUCCESS";
const CHECKOUT_CLOSE_EVENT = "deepblocks/CHECKOUT_CLOSE";
const SUBSCRIPTION_CHANGE_EVENT = "deepblocks/SUBSCRIPTION_CHANGE";
const SUBSCRIPTION_CANCEL_EVENT = "deepblocks/SUBSCRIPTION_CANCEL";
const UPGRADE_BUTTON_CLICK_EVENT = "deepblocks/UPGRADE_BUTTON_CLICK";
// New project events.
const CREATE_PROJECT_EVENT = "deepblocks/CREATE_PROJECT";
const PARCEL_CLICK_EVENT = "deepblocks/PARCEL_CLICK";
const PARCEL_DRAW_EVENT = "deepblocks/PARCEL_DRAW_EVENT";
const NEW_PROJECT_CREATED_WITH_CONDO_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_CONDO";
const NEW_PROJECT_CREATED_WITH_MULTIFAMILY_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_MULTIFAMILY";
const NEW_PROJECT_CREATED_WITH_HOTEL_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_HOTEL";
const NEW_PROJECT_CREATED_WITH_OFFICE_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_OFFICE";
const NEW_PROJECT_CREATED_WITH_INDUSTRIAL_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_INDUSTRIAL";
const NEW_PROJECT_CREATED_WITH_RETAIL_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_RETAIL";
const NEW_PROJECT_CREATED_WITH_METRIC_SYSTEM_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_METRIC_SYSTEM";
const NEW_PROJECT_CREATED_WITH_IMPERIAL_SYSTEM_EVENT = "deepblocks/NEW_PROJECT_CREATED_WITH_IMPERIAL_SYSTEM";
const PARCEL_TOOL_SELECT_PARCEL_CLICK_EVENT = "deepblocks/PARCEL_TOOL_SELECT_PARCEL_CLICK";
const PARCEL_TOOL_COMBINE_PARCELS_CLICK_EVENT = "deepblocks/PARCEL_TOOL_COMBINE_PARCELS_CLICK";
const PARCEL_TOOL_DRAW_PARCEL_CLICK_EVENT = "deepblocks/PARCEL_TOOL_DRAW_PARCEL_CLICK";
const PARCEL_TOOL_EDIT_PARCEL_CLICK_EVENT = "deepblocks/PARCEL_TOOL_EDIT_PARCEL_CLICK";
const PARCEL_TOOL_DELETE_PARCEL_CLICK_EVENT = "deepblocks/PARCEL_TOOL_DELETE_PARCEL_CLICK";
const PARCEL_TOOL_COPY_PARCEL_CLICK_EVENT = "deepblocks/PARCEL_TOOL_COPY_PARCEL_CLICK";
const FILTER_TOGGLE_EVENT = "deepblocks/FILTER_TOGGLE";
const FILTER_PARCEL_AREA_TURNED_ON_EVENT = "deepblocks/FILTER_PARCEL_AREA_TURNED_ON";
const FILTER_MEDIAN_INCOME_TURNED_ON_EVENT = "deepblocks/FILTER_MEDIAN_INCOME_TURNED_ON";
const FILTER_MEDIAN_RENT_TURNED_ON_EVENT = "deepblocks/FILTER_MEDIAN_RENT_TURNED_ON";
const FILTER_EXISTING_BUILDING_AREA_TURNED_ON_EVENT = "deepblocks/FILTER_EXISTING_BUILDING_AREA_TURNED_ON";
const FILTER_FLOOR_AREA_RATIO_TURNED_ON_EVENT = "deepblocks/FILTER_FLOOR_AREA_RATIO_TURNED_ON";
const FILTER_EXISTING_YEAR_BUILT_TURNED_ON_EVENT = "deepblocks/FILTER_EXISTING_YEAR_BUILT_TURNED_ON";
const FILTER_NUMBER_OF_UNITS_ALLOWED_TURNED_ON_EVENT = "deepblocks/FILTER_NUMBER_OF_UNITS_ALLOWED_TURNED_ON_EVENT";
const FILTER_BUILDING_HEIGHT_TURNED_ON_EVENT = "deepblocks/FILTER_BUILDING_HEIGHT_TURNED_ON_EVENT";
const FILTER_EXISTING_BUILDING_HEIGHT_TURNED_ON_EVENT = "deepblocks/FILTER_EXISTING_BUILDING_HEIGHT_TURNED_ON_EVENT";
const FILTER_BUILDABLE_AREA_TURNED_ON_EVENT = "deepblocks/FILTER_BUILDABLE_AREA_TURNED_ON_EVENT";
const FILTER_NUMBER_OF_EXISTING_UNITS_TURNED_ON_EVENT = "deepblocks/FILTER_NUMBER_OF_EXISTING_UNITS_TURNED_ON_EVENT";
// Other events.
const PAGEVIEW_EVENT = "deepblocks/PAGEVIEW";
const ADDRESS_SEARCH_EVENT = "deepblocks/ADDRESS_SEARCH";
const SET_RANGED_INPUT_EVENT = "deepblocks/SET_RANGED_INPUT";
const SET_RANGED_INPUT_MAXIMUM_EVENT = "deepblocks/SET_RANGED_INPUT_MAXIMUM";
const SET_RANGED_INPUT_MINIMUM_EVENT = "deepblocks/SET_RANGED_INPUT_MINIMUM";
const SLIDER_CHANGE_EVENT = "deepblocks/SLIDER_CHANGE";
const TOGGLE_CHANGE_EVENT = "deepblocks/TOGGLE_CHANGE";
const OPEN_PANEL_EVENT = "deepblocks/OPEN_PANEL";
const CLOSE_PANEL_EVENT = "deepblocks/CLOSE_PANEL";
const DUPLICATE_PROJECT_EVENT = "deepblocks/DUPLICATE_PROJECT";
const OPEN_PROJECT_EVENT = "deepblocks/OPEN_PROJECT";
const DELETE_PROJECT_EVENT = "deepblocks/DELETE_PROJECT";
const SELECT_KPI_EVENT = "deepblocks/SELECT_KPI";
const PARCEL_INFO_PAGE_SCROLL_EVENT = "deepblocks/PARCEL_INFO_PAGE_SCROLL";
const SET_UNIT_SYSTEM_EVENT = "deepblocks/SET_UNIT_SYSTEM";
const OPEN_ANALYZE_PROPERTY_POPUP_EVENT = "deepblocks/OPEN_ANALYZE_PROPERTY_POPUP";
const OPEN_NEW_DEVELOPMENT_POPUP_EVENT = "deepblocks/OPEN_NEW_DEVELOPMENT_POPUP";
const LEGEND_TOGGLE_EVENT = "deepblocks/LEGEND_TOGGLE";
const MAP_STYLE_BUTTON_CLICK_EVENT = "deepblocks/MAP_STYLE_BUTTON_CLICK";
const RECALCULATE_PROJECT_EVENT = "deepblocks/RECALCULATE_PROJECT";
const GENERATE_PDF_REPORT_EVENT = "deepblocks/GENERATE_PDF_REPORT";
const MAP_LOAD_DRAW_PARCEL_EVENT = "deepblocks/MAP_LOAD_DRAW_PARCEL";
const MAP_LOAD_PARCEL_DATA_EVENT = "deepblocks/MAP_LOAD_PARCEL_DATA";
const MAP_LOAD_ZONING_DATA_EVENT = "deepblocks/MAP_LOAD_ZONING_DATA";

// This is an array that's keeping record of the current throttled events.
let throttleArray: string[] = [];

// Building use to event map
const BUILDING_USE_TO_EVENT_MAP: {
  [key in Exclude<
    BuildingUse,
    BuildingUse.Parking | BuildingUse.Project | BuildingUse.RentalUses | BuildingUse.IncomeProducing
  >]: string;
} = {
  [BuildingUse.Condo]: NEW_PROJECT_CREATED_WITH_CONDO_EVENT,
  [BuildingUse.Multifamily]: NEW_PROJECT_CREATED_WITH_MULTIFAMILY_EVENT,
  [BuildingUse.Hotel]: NEW_PROJECT_CREATED_WITH_HOTEL_EVENT,
  [BuildingUse.Office]: NEW_PROJECT_CREATED_WITH_OFFICE_EVENT,
  [BuildingUse.Industrial]: NEW_PROJECT_CREATED_WITH_INDUSTRIAL_EVENT,
  [BuildingUse.Retail]: NEW_PROJECT_CREATED_WITH_RETAIL_EVENT,
};

// TO - DO: Change the copyParcel event (right now pointing to the delete one)
const PARCEL_TOOL_TO_EVENT_MAP: { [key in ParcelTool]: string } = {
  [ParcelTool.SelectParcel]: PARCEL_TOOL_SELECT_PARCEL_CLICK_EVENT,
  [ParcelTool.CombineParcels]: PARCEL_TOOL_COMBINE_PARCELS_CLICK_EVENT,
  [ParcelTool.DrawParcel]: PARCEL_TOOL_DRAW_PARCEL_CLICK_EVENT,
  [ParcelTool.EditParcel]: PARCEL_TOOL_EDIT_PARCEL_CLICK_EVENT,
  [ParcelTool.DeleteParcel]: PARCEL_TOOL_DELETE_PARCEL_CLICK_EVENT,
  [ParcelTool.CopyParcel]: PARCEL_TOOL_COPY_PARCEL_CLICK_EVENT,
};

const FILTER_ID_TO_EVENT_MAP: { [key in FilterId]: string } = {
  [FilterId.ExistingStructureArea]: FILTER_EXISTING_BUILDING_AREA_TURNED_ON_EVENT,
  [FilterId.ExistingStructureAreaOpenSource]: FILTER_EXISTING_BUILDING_AREA_TURNED_ON_EVENT,
  [FilterId.BuildingHeight]: FILTER_BUILDING_HEIGHT_TURNED_ON_EVENT,
  [FilterId.ExistingBuildingHeight]: FILTER_EXISTING_BUILDING_HEIGHT_TURNED_ON_EVENT,
  [FilterId.BuildableArea]: FILTER_BUILDABLE_AREA_TURNED_ON_EVENT,
  [FilterId.MedianIncomeTotal]: FILTER_MEDIAN_INCOME_TURNED_ON_EVENT,
  [FilterId.GrossMedianRent]: FILTER_MEDIAN_RENT_TURNED_ON_EVENT,
  [FilterId.AreaPublished]: FILTER_PARCEL_AREA_TURNED_ON_EVENT,
  [FilterId.FloorAreaRatio]: FILTER_FLOOR_AREA_RATIO_TURNED_ON_EVENT,
  [FilterId.ExistingYearBuilt]: FILTER_EXISTING_YEAR_BUILT_TURNED_ON_EVENT,
  [FilterId.NumberOfUnitsAllowed]: FILTER_NUMBER_OF_UNITS_ALLOWED_TURNED_ON_EVENT,
  [FilterId.NumberOfResidentialUnits]: FILTER_NUMBER_OF_EXISTING_UNITS_TURNED_ON_EVENT,
};

/**
 * Track events for which there is an already signed in user. The user is identified and the user email is added to
 * the event properties.
 */
const trackEvent = (eventName: string, properties?: object) => {
  const user = authentication.getCurrentUser();
  const userData = userSelectors.getUserDocument(reduxStore.getState());

  let metadata = {
    ...properties,
  };

  let userMetaData = {};
  if (user && userData) {
    metadata["userId"] = user.uid;

    userMetaData = {
      userEmail: user.email,
      userEmailIsVerified: user.emailVerified,
      firstName: userData.firstName,
      lastName: userData.lastName,
      companyName: userData.companyName,
      companySize: userData.companySize,
    };
  }

  const currentPlanId =
    (properties && properties["currentPlanId"]) || subscriptionSelectors.getPlanId(reduxStore.getState());
  if (currentPlanId) userMetaData["currentPlanId"] = currentPlanId;

  window["dataLayer"].push({
    event: eventName,
    deepblocks: {
      ...metadata,
      ...userMetaData,
    },
  });

  intercom.track(eventName, metadata);
};

/**
 * This function tracks events once every `time` seconds to prevent calling the tracking libraries multiple times.
 */
const trackAuthenticatedThrottledEvent = (time: number, eventName: string, properties?: object) => {
  if (!throttleArray.includes(eventName)) {
    throttleArray.push(eventName);
    trackEvent(eventName, properties);
    setTimeout(() => {
      throttleArray = throttleArray.filter((eventNameThrottled) => eventName !== eventNameThrottled);
    }, time);
  }
};

/**
 * Track authentication events. The user is identified, the personal information is set, and the user email is added to
 * the event properties.
 */
const trackAuthenticationEvent = async (
  eventName: string,
  authenticationProvider: AuthenticationProviderId,
  properties?: object
) => {
  const user = authentication.getCurrentUser();
  if (user) {
    window["dataLayer"].push({
      event: eventName,
      deepblocks: {
        ...properties,
        authenticationProvider: authenticationProvider,
        userEmail: user.email,
        userEmailIsVerified: user.emailVerified,
        userId: user.uid,
      },
    });
  }
};

/**
 * Track pageview.
 */
const trackPageview = (pagePath) => {
  trackEvent(PAGEVIEW_EVENT, {
    pagePath: pagePath,
  });
};

/**
 * Track checkout button pressed, indicating checkout has started.
 */
const trackCheckoutStart = (selectedPlanId: Chargebee.PlanId) => {
  trackEvent(CHECKOUT_START_EVENT, { selectedPlanId: selectedPlanId });
};

/**
 * Track successful checkout of a subscription.
 */
const trackCheckoutSuccess = (currentPlanId: Chargebee.PlanId) => {
  trackEvent(CHECKOUT_SUCCESS_EVENT, { currentPlanId: currentPlanId });
};

/**
 * Track closing of the checkout modal.
 */
const trackCheckoutClose = (currentPlanId?: Chargebee.PlanId) => {
  trackEvent(CHECKOUT_CLOSE_EVENT, { currentPlanId: currentPlanId });
};

/**
 * Track subscription change.
 */
const trackChangeSubscription = () => {
  trackEvent(SUBSCRIPTION_CHANGE_EVENT);
};

/**
 * Track cancellation of a subscription.
 */
const trackCancelSubscription = () => {
  trackEvent(SUBSCRIPTION_CANCEL_EVENT);
};

/**
 * Track change slider.
 */
const trackChangeSlider = (id, value) => {
  trackEvent(SLIDER_CHANGE_EVENT, { sliderId: id, sliderValue: value });
};

/**
 * Track change toggle.
 */
const trackChangeToggle = (id, value) => {
  trackEvent(TOGGLE_CHANGE_EVENT, { toggleId: id, toggleValue: value });
};

/**
 * Track open panel.
 */
const trackOpenPanel = (panelName) => {
  trackEvent(OPEN_PANEL_EVENT, { panelName: panelName });
};

/**
 * Track close panel.
 */
const trackClosePanel = (panelName) => {
  trackEvent(CLOSE_PANEL_EVENT, { panelName: panelName });
};

/**
 * Track create new project.
 */
const trackCreateNewProject = (projectId, initialValues) => {
  trackEvent(CREATE_PROJECT_EVENT, {
    projectId: projectId,
    initialValues: initialValues,
  });

  // Track building use toggles selected on project creation.
  Object.values(BuildingUse).forEach((buildingUse) => {
    if (initialValues[`${buildingUse}Toggle`]) trackEvent(BUILDING_USE_TO_EVENT_MAP[buildingUse]);
  });

  // Track the unit system selected.
  const unitSystemSelectedEvent =
    initialValues.unitSystem === Unit.System.Imperial
      ? NEW_PROJECT_CREATED_WITH_IMPERIAL_SYSTEM_EVENT
      : NEW_PROJECT_CREATED_WITH_METRIC_SYSTEM_EVENT;

  trackEvent(unitSystemSelectedEvent);
};

/**
 * Track duplicate project.
 */
const trackDuplicateProject = (projectId) => {
  trackEvent(DUPLICATE_PROJECT_EVENT, { projectId: projectId });
};

/**
 * Track open project.
 */
const trackOpenProject = (projectId) => {
  trackEvent(OPEN_PROJECT_EVENT, { projectId: projectId });
};

/**
 * Track delete project.
 */
const trackDeleteProject = (projectId) => {
  trackEvent(DELETE_PROJECT_EVENT, { projectId: projectId });
};

/**
 * Track select kpi.
 */
const trackSelectKpi = (kpi) => {
  trackEvent(SELECT_KPI_EVENT, { kpi: kpi });
};

/**
 * Track log out.
 */
const trackLogOut = () => {
  trackEvent(SIGN_OUT_EVENT);

  // Clear the data layer.
  window["dataLayer"].push({
    deepblocks: null,
  });
};

/**
 * Track verify email.
 */
const trackVerifyEmail = () => {
  trackEvent(EMAIL_VERIFICATION_EVENT);
};

/**
 * Track change password.
 */
const trackChangePassword = () => {
  trackEvent(PASSWORD_CHANGE_EVENT);
};

/**
 * Track request password change.
 */
const trackRequestPasswordChange = (email) => {
  trackEvent(REQUEST_PASSWORD_CHANGE_EVENT, { userEmail: email });
};

/**
 * Track sign up.
 */
const trackSignUp = (authenticationProvider: AuthenticationProviderId) => {
  trackAuthenticationEvent(SIGN_UP_EVENT, authenticationProvider);
};

/**
 * Track log in.
 */
const trackLogIn = (authenticationProvider: AuthenticationProviderId) => {
  trackAuthenticationEvent(SIGN_IN_EVENT, authenticationProvider);
};

/**
 * Track a failure to authenticate (log in or sign up) due to an error.
 */
const trackAuthenticationError = (
  error: Error & firebase.FirebaseError,
  attemptedAuthenticationProvider: AuthenticationProviderId,
  attemptedAction?: string
) => {
  const errorMessage = error.code || error.message || null;
  trackEvent(AUTHENTICATION_ERROR_EVENT, {
    errorMessage: errorMessage,
    attemptedAuthenticationProvider: attemptedAuthenticationProvider,
    attemptedAction: attemptedAction || null,
  });
};

/**
 * Tracks a click on a parcel.
 */
const trackParcelClick = async (parcelId: string, coordinates: [number, number]) => {
  let geocodedFeature = await geocoder.getGeocodedFeature(coordinates);
  let city = addressHelper.getCity(geocodedFeature.place_name);
  trackEvent(PARCEL_CLICK_EVENT, {
    clickedParcelId: parcelId,
    clickedParcelCoordinates: coordinates,
    city: city,
  });
};

/**
 * Tracks submission of a property address search.
 */
const trackAddressSubmit = (searchAddress: string) => {
  trackEvent(ADDRESS_SEARCH_EVENT, { searchAddress });
};

/**
 * Tracks change in ranged input.
 */
const trackSetRangedInput = (inputId: string, inputValue: number) => {
  trackEvent(SET_RANGED_INPUT_EVENT, { inputId, inputValue });
};

/**
 * Tracks change in ranged input.
 */
const trackSetRangedInputMaximum = (inputId: string, inputMaximumValue: number) => {
  trackEvent(SET_RANGED_INPUT_MAXIMUM_EVENT, { inputId, inputMaximumValue });
};

/**
 * Tracks change in ranged input.
 */
const trackSetRangedInputMinimum = (inputId: string, inputMinimumValue: number) => {
  trackEvent(SET_RANGED_INPUT_MINIMUM_EVENT, { inputId, inputMinimumValue });
};

/**
 * Tracks scrolling in the parcel information panel.
 */
const trackParcelInfoPanelScroll = () => {
  trackAuthenticatedThrottledEvent(30000, PARCEL_INFO_PAGE_SCROLL_EVENT);
};

/**
 * Track value of the unit system selected by the user.
 */
const trackSetUnitSystem = (unitSystem: Unit.System, context: string) => {
  trackEvent(SET_UNIT_SYSTEM_EVENT, {
    unitSystem: unitSystem,
    context: context,
  });
};

/**
 * Track upgrade button click.
 */
const trackUpgradeButtonClick = () => {
  trackEvent(UPGRADE_BUTTON_CLICK_EVENT);
};

/**
 * Track opening of the analyze property popup.
 */
const trackOpenAnalyzePropertyPopup = () => {
  trackEvent(OPEN_ANALYZE_PROPERTY_POPUP_EVENT);
};

/**
 * Track opening of the new development popup.
 */
const trackOpenNewDevelopmentPopup = () => {
  trackEvent(OPEN_NEW_DEVELOPMENT_POPUP_EVENT);
};

/**
 * Track drawn parcel.
 */
const trackDrawParcel = async (feature) => {
  const polygonArea = turf.area(feature);
  const polygonPerimeter = geometry.perimeter(feature);
  let center: any = turf.centerOfMass(feature);
  center = center.geometry && center.geometry.coordinates;
  let geocodedFeature = await geocoder.getGeocodedFeature(center);
  let city = geocodedFeature ? addressHelper.getCity(geocodedFeature.place_name) : null;

  trackEvent(PARCEL_DRAW_EVENT, {
    centerParcelCoordinates: center,
    polygonArea: polygonArea,
    polygonPerimeter: polygonPerimeter,
    city: city,
  });
};

/**
 * Track parcel tool selected.
 */
const trackSelectParcelTool = (parcelTool: ParcelTool) => {
  trackEvent(PARCEL_TOOL_TO_EVENT_MAP[parcelTool]);
};

/**
 * Track legend toggle.
 */
const trackLegendToggle = (expanded) => {
  trackEvent(LEGEND_TOGGLE_EVENT, { expanded: expanded });
};

/**
 * Track filters toggled and the values.
 */
const trackFilterToggle = (filterName: string, isActive: boolean, minValue?, maxValue?) => {
  trackEvent(FILTER_TOGGLE_EVENT, {
    name: filterName,
    isActive: isActive,
    minValue: minValue,
    maxValue: maxValue,
  });

  // Individually track filter if it was turned on.
  if (isActive) trackEvent(FILTER_ID_TO_EVENT_MAP[filterName]);
};

/**
 * Track map style button click.
 */
const trackMapStyleButtonClick = (mapStyle) => {
  trackEvent(MAP_STYLE_BUTTON_CLICK_EVENT, { mapStyle: mapStyle });
};

/**
 * Track when users recalculate project.
 */
const trackRecalculateProject = (floorAreaRatio) => {
  trackEvent(RECALCULATE_PROJECT_EVENT, {
    recalculateFloorAreaRatio: floorAreaRatio,
  });
};

/**
 * Track when users generate pdf report.
 */
const trackGeneratePdfReport = () => {
  trackEvent(GENERATE_PDF_REPORT_EVENT);
};

/**
 * Track when users lands in the map and there is not parcel nor zoning data.
 */
const trackMapLoadDrawParcels = () => {
  trackEvent(MAP_LOAD_DRAW_PARCEL_EVENT);
};

/**
 * Track when users lands in the map and there is parcel data but not zoning data.
 */
const trackMapLoadParcelData = () => {
  trackEvent(MAP_LOAD_PARCEL_DATA_EVENT);
};

/**
 * Track when users lands in the map and there is zoning data.
 */
const trackMapLoadZoningData = () => {
  trackEvent(MAP_LOAD_ZONING_DATA_EVENT);
};

export default {
  trackChangeSubscription,
  trackCancelSubscription,
  trackCheckoutStart,
  trackCheckoutSuccess,
  trackCheckoutClose,
  trackChangeSlider,
  trackChangeToggle,
  trackOpenPanel,
  trackClosePanel,
  trackSignUp,
  trackVerifyEmail,
  trackLogIn,
  trackAuthenticationError,
  trackLogOut,
  trackChangePassword,
  trackRequestPasswordChange,
  trackCreateNewProject,
  trackDuplicateProject,
  trackOpenProject,
  trackDeleteProject,
  trackSelectKpi,
  trackParcelClick,
  trackAddressSubmit,
  trackSetRangedInput,
  trackSetRangedInputMaximum,
  trackSetRangedInputMinimum,
  trackParcelInfoPanelScroll,
  trackSetUnitSystem,
  trackUpgradeButtonClick,
  trackOpenAnalyzePropertyPopup,
  trackOpenNewDevelopmentPopup,
  trackDrawParcel,
  trackSelectParcelTool,
  trackFilterToggle,
  trackMapStyleButtonClick,
  trackLegendToggle,
  trackRecalculateProject,
  trackGeneratePdfReport,
  trackMapLoadDrawParcels,
  trackMapLoadParcelData,
  trackMapLoadZoningData,
  trackPageview,
};
