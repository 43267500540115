import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import log from "loglevel";
import ReactDOM from "react-dom"
import Firebase from "../../types/Service/Firebase";

/**
 * @fileoverview This module handles user sessions to prevent multiple
 * logins for the same user.
 */

let sessionUnsubscribe;

/**
 * This listener is meant to listen for changes in the database for `userSession\{userId}` documents.
 * See: https://firebase.google.com/docs/firestore/query-data/listen
 */
const userSessionSnapshotListener = (snapshot) => {
  // Ignore session `set` or `update` events originating from this session
  // (indicated by `hasPendingWrites`).
  if (snapshot.metadata.hasPendingWrites) return;

  // Ignore session `delete` events (indicated by `exists === false`).
  if (!snapshot.exists) return;

  // TODO: Remove the call to `ReactDOM` once the popup implementation has
  // been fixed.
  // See https://deepblocks.tpondemand.com/entity/4085-fix-hacky-popup-implementation
  let popUpContainer = document.getElementById("pop-up-container") as HTMLElement;
  ReactDOM.unmountComponentAtNode(popUpContainer)

  firebase.auth().signOut()
    .then(() => alert("You logged into your account on another device. This session has been logged out."));

  setTimeout(() => {
    document.location.reload();
  }, 100)
}

/**
 * A boolean indicating whether there is currently an active user session
 * listener.
 */
const isListening = (): boolean => {
  return Boolean(sessionUnsubscribe);
}

/**
 * Start listening to session events for the given user.
 */
const startListening = (user: Firebase.User) => {
  if (!user) return;

  // Make sure there are no redundant listeners.
  stopListening();

  let userSessionDocumentReference = firebase.firestore().collection("userSessions").doc(user.uid);
  sessionUnsubscribe = userSessionDocumentReference.onSnapshot(userSessionSnapshotListener);
}

/**
 * Stop listening to user session events.
 */
const stopListening = () => {
  if (isListening()) {
    sessionUnsubscribe();
    sessionUnsubscribe = null;
  }
}

/**
 * Perform a document write for the given user so that listeners from other
 * sessions are notified.
 */
const notifyUserSessions = async (user: Firebase.User) => {
  if (!user) return;

  try {
    let userSessionDocumentReference = firebase.firestore().collection("userSessions").doc(user.uid);
    await userSessionDocumentReference.set({ lastSignInTime: new Date().toJSON() });
  } catch (error) {
    log.error(error);
  }
}

/**
 * Delete the user session document for the given user.
 */
const deleteUserSession = async (user: Firebase.User) => {
  if (!user) return;

  try {
    await firebase.firestore().collection("userSessions").doc(user.uid).delete();
  } catch (error) {
    log.error(error);
  }
}

export default {
  startListening,
  stopListening,
  notifyUserSessions,
  deleteUserSession,
}
