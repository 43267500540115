import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";
import produce from "immer";
import { ManagedSubscriptionStatus, SubscriptionInvitationStatus } from "../../types/SubscriptionInvitations";
import actions from "./actions";
import { authenticationActionTypes } from "../authentication";
import { subscriptionManagementActions } from "../ui/subscriptionManagement";

export interface Invitation {
  subscriptionId: string;
  planId: Chargebee.PlanId;
  userEmail: string;
  userName: string;
  ownerName: string;
  status: string;
  chargebeeCustomerId: string;
  discountCode: Array<string>;
}

export type ManagedSubscription = {
  name: string;
  email: string;
  status: string;
  memberSince: number;
  discountCode: Array<string>;
  planId: Chargebee.PlanId;
  subscriptionId: string;
  currentTermEnd?: number;
  currentTermStart?: number;
}

export type ManagedSubscriptions = {
  [userId: string]: ManagedSubscription;
}

export interface SubscriptionInvitationsState {
  invitations: {
    [ownerId: string]: Invitation;
  },
  managedSubscriptions?: ManagedSubscriptions;
  managedSubscriptionsImages?: object;
  invitationSent?: boolean | null;
}

const INITIAL_STATE: SubscriptionInvitationsState = {
  invitations: {},
};

const reducer = (previousState = INITIAL_STATE, action): SubscriptionInvitationsState => {
  switch (action.type) {
    case actionTypes.CANCEL_SUCCESS:
    case actionTypes.DELETE_SUCCESS:
    case actionTypes.LOAD_SUCCESS: return setInvitations(previousState, action.payload);
    case actionTypes.UPDATE_MANAGED_SUBSCRIPTION_STATUS: return updateManagedSubscription(previousState, action);
    case actionTypes.INVITE_START: return inviteStart(previousState, action.payload);
    case actionTypes.INVITE_SUCCESS: return inviteSuccess(previousState, action.payload);
    case actionTypes.INVITE_FAILURE: return inviteFailure(previousState, action.payload);
    case subscriptionManagementActions.setInvitationError.toString(): return updateInvitesOnError(previousState, action);
    case authenticationActionTypes.SIGN_IN_SUCCESS: return INITIAL_STATE;
    default: return previousState;
  }
}

/**
 * Returns state with updated invitationSent.
 */
const inviteStart = (previousState: SubscriptionInvitationsState, payload): SubscriptionInvitationsState => {
  return {
    ...previousState,
    invitationSent: null,
  };
}

/**
 * Returns state with updated invitations document.
 */
const updateInvitesOnError = (previousState: SubscriptionInvitationsState, action: ReturnType<typeof subscriptionManagementActions.setInvitationError>): SubscriptionInvitationsState => {
  return {
    ...previousState,
    invitations: { ...action.payload.invitations },
  };
}

/**
 * Returns state with updated invitationSent.
 */
const inviteSuccess = (previousState: SubscriptionInvitationsState, payload): SubscriptionInvitationsState => {
  return {
    ...previousState,
    invitationSent: true,
  };
}

/**
 * Returns state with updated invitationSent.
 */
const inviteFailure = (previousState: SubscriptionInvitationsState, payload): SubscriptionInvitationsState => {
  return {
    ...previousState,
    invitationSent: false,
  };
}

/**
 * Returns state with updated subscription and managed invitations information.
 */
const setInvitations = (previousState: SubscriptionInvitationsState, payload): SubscriptionInvitationsState => {
  const invitations = payload.subscriptionInvitations && payload.subscriptionInvitations.invitations;

  return {
    ...previousState,
    invitations: { ...(invitations || {}) },
    managedSubscriptions: payload.managedSubscriptions,
    managedSubscriptionsImages: payload.managedSubscriptionsImages,
  };
}

/**
 * Returns state with managedSubscriptions updated.
 */
const updateManagedSubscription = (previousState: SubscriptionInvitationsState, action: ReturnType<typeof actions.updateManagedSubscriptionStatus>): SubscriptionInvitationsState => {
  const payload = action.payload;

  return produce(previousState, (newStateDraft) => {
    if (newStateDraft.managedSubscriptions && newStateDraft.managedSubscriptions[payload.userId]) {
      newStateDraft.managedSubscriptions[payload.userId] = {
        ...newStateDraft.managedSubscriptions[payload.userId],
        ...payload.subscriptionData,
      }

      let status = payload.status;
      if (status === SubscriptionInvitationStatus.Accepted) status = ManagedSubscriptionStatus.Active;
      newStateDraft.managedSubscriptions[payload.userId].status = status;
    }
  })
}

export default reducer;
