import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class FederalOwnedLandsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.FederalOwnedLands}
        classes="federal-land"
        legendRows={[
          <LegendRow
            color="#b18f9b"
            text="Federal Owned Land"
          />
        ]}
      />
    );
  }
}

export default FederalOwnedLandsLegend;
