import React from "react";
import { connect } from "react-redux";
import { LayerDisclaimerObject } from "types/LayerDisclaimer";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import LayerDisclaimer from "../LayerDisclaimer";
import Legend from "../Legend";
import dynamicLayersConfig from "views/sharedComponents/DynamicLayerSelection/dynamicLayersConfig";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerToggle from "./DynamicLayerToggle/DynamicLayerToggle";

const mapStateToProps = (state) => {
  return {
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  }
}

interface OwnProps {
  classes?: string;
  layerGroup: DynamicLayerId;
  legendRows?: Array<JSX.Element>;
  disclaimer?: LayerDisclaimerObject;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

interface State {
  legendIsActive: boolean;
}

class DynamicLayerLegend extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      legendIsActive: false,
    }
  }

  /**
   * Get layer is active flag.
   */
  getLayerIsActive = () => dynamicLayersConfig[this.props.layerGroup].layers.some((layerId) => this.props.dynamicLayerConfigurations[layerId].isActive);

  /**
   * Get layer is enabled flag.
   */
  getLayerIsEnabled = () => dynamicLayersConfig[this.props.layerGroup].layers.some((layerId) => this.props.dynamicLayerConfigurations[layerId].isEnabled && this.props.dynamicLayerConfigurations[layerId].isAvailable);

  /**
   * Toggle legend.
   */
  toggleLegend = () => {
    this.setState({ legendIsActive: !this.state.legendIsActive });
  }

  render() {
    const { classes, legendRows: LegendRows, layerGroup, disclaimer } = this.props;
    if (!this.getLayerIsEnabled()) return null;

    const title = dynamicLayersConfig[layerGroup].legendTitle;
    const layerIds = dynamicLayersConfig[layerGroup].layers;
    const text = dynamicLayersConfig[layerGroup].layerDescription;
    const layerIsActive = this.getLayerIsActive();
    const displayLegend = layerIsActive && this.state.legendIsActive;

    return (
      <div className={`component--layer-legend ${classes}`}>
        <div className="icon" />
        <div className="legend-content">

          <div className="content-wrapper">
            <div className="title-container">
              <div className="title">{title}</div>
              <div className="text">{text}</div>
            </div>
            <DynamicLayerToggle layerGroup={layerGroup} layerIds={layerIds} />
          </div>

          {LegendRows &&
            <>
              <button
                className={`legend-button ${layerIsActive ? "" : "disabled"}`}
                onClick={this.toggleLegend}
              >
                {displayLegend ? "Hide Legend" : "Show Legend"} <span className={displayLegend ? "active" : "inactive"} />
              </button>

              {displayLegend &&
               <div>
                <Legend>
                  {LegendRows.map((LegendComponent, index) =>
                  <LegendComponent.type {...LegendComponent.props} key={`legend_${index}`} />)}
                </Legend>
                {disclaimer && <LayerDisclaimer disclaimer={disclaimer}/>}
                </div>
              }
            </>
          }
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(DynamicLayerLegend) as React.ComponentType<OwnProps>;
