import { AllowedUses } from "types/AllowedUses";
import { AllowedUsesFilters } from "types/AllowedUsesFilter";
import { OwnerAddressesFilter } from "types/OwnerAddressesFilter";
import { OwnerNamesFilter } from "types/OwnerNamesFilter";
import { Filters, FilterId } from "../types/Filter";
import { ZoningFilters } from "../types/ZoningFilters";
import parcelAccessors from "./parcel/parcelAccessors";
import { ParcelProperty } from "./parcel/ParcelProperty";
import { GeoJSON } from "geojson";
import { LandUseFilter } from "types/LandUseFilter";

const MIN_RATIO = 0.8;
const PARCEL_FIELD_TO_FILTER_ZONING_BY = ParcelProperty.ZoneIdTruncated;
const PARCEL_FIELD_TO_FILTER_OWNER_NAME_BY = ParcelProperty.OwnerName;
const PARCEL_FIELD_TO_FILTER_OWNER_ADDRESS_BY = ParcelProperty.OwnerAddress;
const PARCEL_FIELD_TO_FILTER_LAND_USE_BY = ParcelProperty.LandUseCode;

const INITIAL_FILTERS: Filters = {
  [FilterId.AreaPublished]: {
    id: FilterId.AreaPublished,
    variableId: ParcelProperty.AreaPublished,
    shouldDisplay: true,
    isActive: false,
    allowUnauthenticated: true,
    value: [0, 13935.46],
  },
  [FilterId.BuildableArea]: {
    id: FilterId.BuildableArea,
    variableId: ParcelProperty.BuildableAreaQuery,
    shouldDisplay: true,
    isActive: false,
    value: [0, 92903.04],
  },
  [FilterId.NumberOfUnitsAllowed]: {
    id: FilterId.NumberOfUnitsAllowed,
    variableId: ParcelProperty.NumberOfUnitsAllowedQuery,
    shouldDisplay: true,
    isActive: false,
    value: [0, 1500],
  },
  [FilterId.NumberOfResidentialUnits]: {
    id: FilterId.NumberOfResidentialUnits,
    variableId: ParcelProperty.NumberOfResidentialUnits,
    shouldDisplay: true,
    isActive: false,
    value: [0, 1000],
  },
  [FilterId.BuildingHeight]: {
    id: FilterId.BuildingHeight,
    variableId: ParcelProperty.BuildingHeightQuery,
    shouldDisplay: true,
    isActive: false,
    value: [0, 60.96], // meters
  },
  [FilterId.ExistingBuildingHeight]: {
    id: FilterId.ExistingBuildingHeight,
    variableId: ParcelProperty.ExistingBuildingHeight,
    shouldDisplay: true,
    isActive: false,
    value: [0, 60.96], // meters
  },
  [FilterId.ExistingStructureArea]: {
    id: FilterId.ExistingStructureArea,
    variableId: ParcelProperty.ExistingStructureArea,
    shouldDisplay: true,
    isActive: false,
    value: [46.4515, 139354.7],
  },
  [FilterId.ExistingStructureAreaOpenSource]: {
    id: FilterId.ExistingStructureAreaOpenSource,
    variableId: ParcelProperty.ExistingStructureAreaOpenSource,
    shouldDisplay: true,
    isActive: false,
    value: [46.4515, 139354.7],
  },
  [FilterId.FloorAreaRatio]: {
    id: FilterId.FloorAreaRatio,
    variableId: ParcelProperty.FloorAreaRatioQuery,
    shouldDisplay: true,
    isActive: false,
    value: [0, 100],
  },
  [FilterId.ExistingYearBuilt]: {
    id: FilterId.ExistingYearBuilt,
    variableId: ParcelProperty.ExistingStructureYearBuilt,
    shouldDisplay: true,
    isActive: false,
    value: [1900, 2020],
  },
  [FilterId.MedianIncomeTotal]: {
    id: FilterId.MedianIncomeTotal,
    variableId: ParcelProperty.MedianIncomeTotal,
    shouldDisplay: true,
    isActive: false,
    value: [0, 1000000],
  },
  [FilterId.GrossMedianRent]: {
    id: FilterId.GrossMedianRent,
    variableId: ParcelProperty.GrossMedianRent,
    shouldDisplay: true,
    isActive: false,
    value: [0, 10000],
  },
};

/**
 * Returns initial ranged filter values.
 */
const initializeFilters = () => INITIAL_FILTERS;

/**
 * Returns a boolean indicating whether there are any active ranged filters.
 */
const filtersAreActive = (filters: Filters) =>
  Object.values(filters).some((filter) => filter.isActive && filter.shouldDisplay);

/**
 * Returns a boolean indicating whether there are any active zoning filters.
 */
const zoningFiltersAreActive = (zoningFilters: ZoningFilters) =>
  Object.values(zoningFilters).some((isEnabled) => isEnabled);

/**
 * Returns a boolean indicating whether there are any active owner-name filters.
 */
const ownerNameFiltersAreActive = (ownerNameFilters: OwnerNamesFilter) =>
  Object.values(ownerNameFilters).some((isEnabled) => isEnabled);

/**
 * Returns a boolean indicating whether there are any active owner-address filters.
 */
const ownerAddressFiltersAreActive = (ownerAddressFilters: OwnerAddressesFilter) =>
  Object.values(ownerAddressFilters).some((isEnabled) => isEnabled);

/**
 * Returns a boolean indicating whether there are any active land-uses filters.
 */
const landUseFiltersAreActive = (landUseFilters: LandUseFilter) =>
  Object.values(landUseFilters).some((isEnabled) => isEnabled);

/**
 * Returns a boolean indicating whether there are any active allowed uses filters.
 */
const allowedUsesFiltersAreActive = (allowedUsesFilters: AllowedUsesFilters) =>
  Object.values(allowedUsesFilters).some((isEnabled) => isEnabled);

/**
 * Get the smart search result features by applying the filters to the parcels in the viewport.
 */
const applyFilters = (
  parcelsInViewport,
  filters: Filters,
  zoningFilters: ZoningFilters,
  allowedUsesFilters: AllowedUsesFilters,
  ownerNameFilters: OwnerNamesFilter,
  ownerAddressFilters: OwnerAddressesFilter,
  landUseFilters: LandUseFilter
) => {
  // Return empty list if no filter is active.
  if (
    !(
      filtersAreActive(filters) ||
      zoningFiltersAreActive(zoningFilters) ||
      allowedUsesFiltersAreActive(allowedUsesFilters) ||
      ownerNameFiltersAreActive(ownerNameFilters) ||
      ownerAddressFiltersAreActive(ownerAddressFilters) ||
      landUseFiltersAreActive(landUseFilters)
    )
  )
    return [];

  let smartSearchResult = parcelsInViewport;

  // Apply ranged filters.
  Object.values(filters).forEach((filter) => {
    if (filter.isActive && filter.shouldDisplay) {
      if (filter.value.length === 1) {
        smartSearchResult = smartSearchResult.filter((parcel) => {
          return (
            parcel.properties[filter.variableId] !== null &&
            Number(parcel.properties[filter.variableId]) === filter.value[0]
          );
        });
      } else {
        smartSearchResult = smartSearchResult.filter((parcel) => {
          return (
            parcel.properties[filter.variableId] !== null &&
            Number(parcel.properties[filter.variableId]) >= filter.value[0] &&
            Number(parcel.properties[filter.variableId]) <= filter.value[1]
          );
        });
      }
    }
  });

  // Apply zoning filters.
  const enabledZoneIds = Object.entries(zoningFilters)
    .filter(([zoneId, isEnabled]) => isEnabled)
    .map(([zoneId, isEnabled]) => zoneId);

  if (enabledZoneIds.length > 0) {
    smartSearchResult = smartSearchResult.filter((parcel) => {
      const zoneId = parcel.properties[PARCEL_FIELD_TO_FILTER_ZONING_BY];
      return zoneId !== null && enabledZoneIds.includes(zoneId);
    });
  }

  // Apply Allowed Uses Filters.
  const enabledUses = Object.entries(allowedUsesFilters)
    .filter(([use, isEnabled]) => isEnabled)
    .map(([use, isEnabled]) => use);

  if (enabledUses.length > 0) {
    smartSearchResult = smartSearchResult.filter((parcel) => {
      const uses = parcelAccessors.getAllowedUses(parcel);
      return allowedUsesOnParcel(enabledUses, uses);
    });
  }

  // Apply owner name filters.
  const enabledOwnerNames = Object.entries(ownerNameFilters)
    .filter(([ownerName, isEnabled]) => isEnabled)
    .map(([ownerName, isEnabled]) => ownerName);

  if (enabledOwnerNames.length > 0) {
    smartSearchResult = smartSearchResult.filter((parcel) => {
      const ownerName = parcel.properties[PARCEL_FIELD_TO_FILTER_OWNER_NAME_BY];
      return ownerName !== null && enabledOwnerNames.includes(ownerName);
    });
  }

  // Apply owner address filters.
  const enabledOwnerAddress = Object.entries(ownerAddressFilters)
    .filter(([ownerAddress, isEnabled]) => isEnabled)
    .map(([ownerAddress, isEnabled]) => ownerAddress);

  if (enabledOwnerAddress.length > 0) {
    smartSearchResult = smartSearchResult.filter((parcel) => {
      const ownerAddress = parcel.properties[PARCEL_FIELD_TO_FILTER_OWNER_ADDRESS_BY];
      return ownerAddress !== null && enabledOwnerAddress.includes(ownerAddress);
    });
  }

  // Apply land use filters.
  const enabledLandUse = Object.entries(landUseFilters)
    .filter(([landUse, isEnabled]) => isEnabled)
    .map(([landUse, isEnabled]) => landUse);

  if (enabledLandUse.length > 0) {
    smartSearchResult = smartSearchResult.filter((parcel) => {
      const landUse = parcel.properties[PARCEL_FIELD_TO_FILTER_LAND_USE_BY];
      return landUse !== null && enabledLandUse.includes(landUse);
    });
  }

  return smartSearchResult;
};

/**
 *
 * @param filterParcelCount Object with the amount of parcels that have the data to be filtered on.
 * @param parcelsInViewport List of parcels in the viewport.
 * @param filterId Either `FilterId.ExistingStructureArea` or `FilterId.ExistingStructureAreaOpenSource`
 * @returns Boolean to be assigned to the `shouldDisplay` property of the filter.
 */
const updateShouldDisplayForExistingStructureArea = (
  filterParcelCount: { [key in FilterId]: number },
  parcelsInViewport: GeoJSON[],
  filterId: FilterId
) => {
  const showRegrid = filterParcelCount[FilterId.ExistingStructureArea] / parcelsInViewport.length > MIN_RATIO;
  if (filterId === FilterId.ExistingStructureArea) {
    return showRegrid;
  }
  return filterParcelCount[FilterId.ExistingStructureAreaOpenSource] > 0 ? !showRegrid : false;
};

/**
 * Update ranged filters `shouldDisplay` based on the current parcels available in the viewport.
 * If no parcel has the data to be filtered on, the filter is not displayed.
 *
 * NOTE: This function changes the filters in place.
 */
const updateFiltersToDisplay = (parcelsInViewport, filters: Filters): void => {
  let filterParcelCount: { [key in FilterId]: number } = {
    [FilterId.ExistingStructureArea]: 0,
    [FilterId.ExistingStructureAreaOpenSource]: 0,
    [FilterId.BuildingHeight]: 0,
    [FilterId.BuildableArea]: 0,
    [FilterId.MedianIncomeTotal]: 0,
    [FilterId.GrossMedianRent]: 0,
    [FilterId.AreaPublished]: 0,
    [FilterId.FloorAreaRatio]: 0,
    [FilterId.ExistingYearBuilt]: 0,
    [FilterId.NumberOfUnitsAllowed]: 0,
    [FilterId.NumberOfResidentialUnits]: 0,
    [FilterId.ExistingBuildingHeight]: 0,
  };

  for (let parcel of parcelsInViewport) {
    Object.keys(filters).forEach((filterId) => {
      const variableId = filters[filterId].variableId;
      if (parcel.properties[variableId] !== null && parcel.properties[variableId] !== undefined) {
        filterParcelCount[filterId] += 1;
      }
    });
  }

  Object.keys(filters).forEach((filterId) => {
    let shouldDisplay;
    const isExistingStructureArea =
      filterId === FilterId.ExistingStructureArea || filterId === FilterId.ExistingStructureAreaOpenSource;

    // Logic to show either the Existing Building Slider for Regrid
    // or the Slider for the Open Source data `usa-buildings-query`
    if (isExistingStructureArea) {
      shouldDisplay = updateShouldDisplayForExistingStructureArea(filterParcelCount, parcelsInViewport, filterId);
    } else {
      shouldDisplay = filterParcelCount[filterId] > 0;
    }
    filters[filterId].shouldDisplay = shouldDisplay;
  });
};

/**
 * Returns an updated zoning filters object derived from the given list of parcels. Any filters
 * present in the previous zoning filters are preserved if they are still in the new list, while
 * all others are dropped.
 *
 * @param {array} parcelsInViewport - A list of parcels that are currently in the viewport.
 * @param {object} previousZoningFilters - The previous zoning filters. Required to preserve the state of any
 * existing zoning filters that are still active.
 *
 * @returns - A new zoning filters object.
 */
const updateZoningFiltersToDisplay = (parcelsInViewport, previousZoningFilters): ZoningFilters => {
  // Get the list of zone IDs.
  const zoneIdsInViewport = new Set<string>();
  for (const parcel of parcelsInViewport) {
    const zoneId = parcel.properties[PARCEL_FIELD_TO_FILTER_ZONING_BY];
    if (zoneId) zoneIdsInViewport.add(zoneId);
  }

  // Copy over existing, matching zone filters. Initialize new ones.
  // Zone IDs that are no longer in the viewport are dropped.
  const zoningFilters = {};
  zoneIdsInViewport.forEach((zoneId) => {
    zoningFilters[zoneId] = previousZoningFilters[zoneId] || false;
  });

  return zoningFilters;
};

/**
 * Returns an updated allowed uses filters object derived from the given list of parcels. Any filters
 * present in the previous allowed uses filters are preserved if they are still in the new list, while
 * all others are dropped.
 *
 * @param {array} parcelsInViewport - A list of parcels that are currently in the viewport.
 * @param {object} previousAllowedUsesFilters - The previous allowed uses filters. Required to preserve the state of any
 * existing allowed uses filters that are still active.
 *
 * @returns - A new allowed uses filters object.
 */
const updateAllowedUsesFiltersToDisplay = (parcelsInViewport, previousAllowedUsesFilters): AllowedUsesFilters => {
  const allowedUsesFilters = {};

  for (const parcel of parcelsInViewport) {
    const uses = parcelAccessors.getAllowedUses(parcel);
    if (uses) {
      for (let mainCategory of Object.keys(uses)) {
        allowedUsesFilters[mainCategory] = previousAllowedUsesFilters[mainCategory] || false;
        const detailedUses = uses[mainCategory];
        detailedUses.forEach((use) => {
          allowedUsesFilters[use] = previousAllowedUsesFilters[use] || false;
        });
      }
    }
  }
  return allowedUsesFilters;
};

/**
 * Returns an updated owner name filters object derived from the given list of parcels. Any filters
 * present in the previous allowed uses filters are preserved if they are still in the new list, while
 * all others are dropped.
 *
 * @param {array} parcelsInViewport - A list of parcels that are currently in the viewport.
 * @param {object} previousOwnerNameFilters - The previous owner name filters. Required to preserve the state of any
 * existing owner name filters that are still active.
 *
 * @returns - A new owner name filters object.
 */
const updateOwnerNameFiltersToDisplay = (parcelsInViewport, previousOwnerNameFilters): OwnerNamesFilter => {
  // Get the list of owner names.
  const ownerNamesInViewport = new Set<string>();
  for (const parcel of parcelsInViewport) {
    const ownerName = parcel.properties[PARCEL_FIELD_TO_FILTER_OWNER_NAME_BY];
    if (ownerName) ownerNamesInViewport.add(ownerName);
  }

  // Copy over existing, matching ownerName filters. Initialize new ones.
  // Owner names that are no longer in the viewport are dropped.
  const ownerNameFilters = {};
  ownerNamesInViewport.forEach((ownerName) => {
    ownerNameFilters[ownerName] = previousOwnerNameFilters[ownerName] || false;
  });

  return ownerNameFilters;
};

/**
 * Returns an updated owner address filters object derived from the given list of parcels. Any filters
 * present in the previous owner name filters are preserved if they are still in the new list, while
 * all others are dropped.
 *
 * @param {array} parcelsInViewport - A list of parcels that are currently in the viewport.
 * @param {object} previousOwnerAddressFilters - The previous owner address filters. Required to preserve the state of any
 * existing owner address filters that are still active.
 *
 * @returns - A new owner name filters object.
 */
const updateOwnerAddressFiltersToDisplay = (parcelsInViewport, previousOwnerAddressFilters): OwnerAddressesFilter => {
  // Get the list of owner addresses.
  const ownerAddressesInViewport = new Set<string>();
  for (const parcel of parcelsInViewport) {
    const ownerAddress = parcel.properties[PARCEL_FIELD_TO_FILTER_OWNER_ADDRESS_BY];
    if (ownerAddress) ownerAddressesInViewport.add(ownerAddress);
  }

  // Copy over existing, matching ownerAddress filters. Initialize new ones.
  // Owner names that are no longer in the viewport are dropped.
  const ownerAddressFilters = {};
  ownerAddressesInViewport.forEach((ownerAddress) => {
    ownerAddressFilters[ownerAddress] = previousOwnerAddressFilters[ownerAddress] || false;
  });

  return ownerAddressFilters;
};

/**
 * Returns an updated land use filters object derived from the given list of parcels. Any filters
 * present in the previous land use filters are preserved if they are still in the new list, while
 * all others are dropped.
 *
 * @param {array} parcelsInViewport - A list of parcels that are currently in the viewport.
 * @param {object} previousLandUseFilters - The previous land use filters. Required to preserve the state of any
 * existing land use filters that are still active.
 *
 * @returns - A new land use filters object.
 */
const updateLandUseFiltersToDisplay = (parcelsInViewport, previousLandUseFilters): LandUseFilter => {
  // Get the list of land uses.
  const landUsesInViewport = new Set<string>();
  for (const parcel of parcelsInViewport) {
    const landUse = parcel.properties[PARCEL_FIELD_TO_FILTER_LAND_USE_BY];
    if (landUse) landUsesInViewport.add(landUse);
  }

  // Copy over existing, matching landUse filters. Initialize new ones.
  // land uses that are no longer in the viewport are dropped.
  const landUsesFilters = {};
  landUsesInViewport.forEach((landUse) => {
    landUsesFilters[landUse] = previousLandUseFilters[landUse] || false;
  });

  return landUsesFilters;
};

/**
 * Check if all the enabled Uses are present in the parcel's uses.
 * Return false if at least one of the enabled uses are not present in the parcel's uses.
 */
const allowedUsesOnParcel = (enabledUses: Array<string>, parcelUses: AllowedUses) => {
  const newUses = {};

  //Get all the detailed uses for the parcel (including Main Category)
  for (let mainCategory of Object.keys(parcelUses)) {
    newUses[mainCategory] = mainCategory;
    const uses = parcelUses[mainCategory];
    uses.forEach((use, index) => {
      newUses[use] = index;
    });
  }

  //Check if at least one of the enabled uses is present on the parcel's uses
  const checkUses = enabledUses.some((use) => {
    return newUses[use] !== undefined;
  });

  return checkUses;
};

export default {
  initializeFilters,
  updateFiltersToDisplay,
  updateZoningFiltersToDisplay,
  updateAllowedUsesFiltersToDisplay,
  updateOwnerNameFiltersToDisplay,
  updateOwnerAddressFiltersToDisplay,
  updateLandUseFiltersToDisplay,
  applyFilters,
};
