import actionTypes from "./actionTypes";

export interface AuthenticationUiState {
  loginIsOpen: boolean;
}

export const INITIAL_STATE: AuthenticationUiState = {
  loginIsOpen: false,
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): AuthenticationUiState => {
  switch (action.type) {
    case actionTypes.TOGGLE_LOGIN_IS_OPEN:
      return toggleLoginIsOpen(previousState);
    default:
      return previousState;
  }
};

/**
 * Toggle the loginIsOpen flag.
 */
const toggleLoginIsOpen = (previousState) => {
  return {
    ...previousState,
    loginIsOpen: !previousState.loginIsOpen,
  };
};

export default reducer;
