import { AccordionSectionState } from "./reducers";

/**
 * Get the accordionSection object.
 */
const getAccordionSectionState = (state): AccordionSectionState => {
  const accordionSectionState = state.ui.shared.accordionSection;
  return accordionSectionState;
}

/**
 * Get the static accordions are open object.
 */
const getStaticAccordionsAreOpen = (state) => {
  const accordionSectionState = getAccordionSectionState(state);
  return accordionSectionState.staticAccordionsAreOpen;
}

/**
 * Get a specific accordion is open value.
 */
const getStaticAccordionIsOpen = (state, id): boolean => {
  const accordionsAreOpen = getStaticAccordionsAreOpen(state);
  return accordionsAreOpen[id];
}

/**
 * Get listings accordions are open object.
 */
const getListingsAccordionsAreOpen = (state) => {
  const accordionSectionState = getAccordionSectionState(state);
  return accordionSectionState.listingAccordionsAreOpen;
}

/**
 * Get boe accordions are open object.
 */
const getBoeAccordionsAreOpen = (state) => {
  const accordionSectionState = getAccordionSectionState(state);
  return accordionSectionState.boeAccordionsAreOpen;
}

export default {
  getStaticAccordionsAreOpen,
  getStaticAccordionIsOpen,
  getListingsAccordionsAreOpen,
  getBoeAccordionsAreOpen,
}
