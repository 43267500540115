import { combineReducers } from "redux";
import userReducer from "./user";
import developmentReducer from "./development";
import newDevelopmentReducer from "./newDevelopment";
import subscriptionReducer from "./subscription";
import setbackModeReducer from "./setbackMode";
import pdfReducer from "./pdf";
import thumbnailsReducer from "./thumbnails";
import uiReducer from "./ui";
import subscriptionInvitationsReducer from "./subscriptionInvitations";
import authenticationReducer, { authenticationActionTypes } from "./authentication";
import graphsReducer from "./graphs";
import layersReducer from "./layers";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  development: developmentReducer,
  newDevelopment: newDevelopmentReducer,
  subscription: subscriptionReducer,
  pdf: pdfReducer,
  thumbnails: thumbnailsReducer,
  user: userReducer,
  ui: uiReducer,
  setbackMode: setbackModeReducer,
  subscriptionInvitations: subscriptionInvitationsReducer,
  graphs: graphsReducer,
  layers: layersReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export default (state, action) => {
  switch (action.type) {
    case authenticationActionTypes.SIGN_OUT_SUCCESS:
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
};
