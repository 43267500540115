import React from "react";
import { connect } from "react-redux";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import DynamicSliderComponent from "../DynamicSliderComponent";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import layerHelper from "utils/mapbox/layerHelper";

const FIELD_TO_LABEL_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2013]: "2013",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2014]: "2014",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2015]: "2015",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2016]: "2016",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2017]: "2017",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2018]: "2018",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2019]: "2019",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2020]: "2020",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2021]: "2021",
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2022]: "2022",
};

const SELECTED_TO_DEFAULT_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2013]: 2013,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2014]: 2014,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2015]: 2015,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2016]: 2016,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2017]: 2017,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2018]: 2018,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2019]: 2019,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2020]: 2020,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2021]: 2021,
  [MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2022]: 2022,
};

const mapStateToProps = (state) => ({
  tier: subscriptionSelectors.getTier(state),
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

export class MedianIncomeDynamicSlider extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, tier, dynamicLayerConfigurations, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer || !getSelectedDynamicLayerSet) return null;

    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    const isDemo = tier === Tier.None;
    const isMedianIncomeDynamicLayer =
      getSelectedDynamicLayer === MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income;
    const hasData = layerHelper.areLayersAvailableInGroup(dynamicLayerConfigurations, getSelectedDynamicLayer);
    const renderComponent = isMedianIncomeDynamicLayer && !isDemo && hasData;

    return (
      <>
        {renderComponent && (
          <DynamicSliderComponent
            currentLabel={FIELD_TO_LABEL_YEAR[layerSelected]}
            sliderMin={2013}
            sliderMax={2022}
            defaultValue={SELECTED_TO_DEFAULT_YEAR[layerSelected]}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(MedianIncomeDynamicSlider);
