import updateProjectsMissingData from"./updateProjectsMissingData";
import userEmailAndSubscription from"./userEmailAndSubscription";
import { AsyncDataPopulator } from "..";
import { UserDocument } from "../../../../../types/UserDocument";

const dataPopulators: Array<AsyncDataPopulator<UserDocument>> = [
  updateProjectsMissingData,
  userEmailAndSubscription,
];

export default dataPopulators;
