import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the multifamily buildables presets. Only one bedroom units will be initialized to non zero values.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.MultifamilyLossFactor) as number;
  const unitArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyTwoBedArea) as number;

  const microUnitBedroomArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyMicrounitArea) as number;
  const studioBedroomArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyStudioArea) as number;
  const oneBedroomArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyOneBedArea) as number;
  const twoBedroomArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyTwoBedArea) as number;
  const threeBedroomArea = developmentAccessors.getInputValue(development, VariableId.MultifamilyThreeBedArea) as number;

  const usableArea = (targetArea * (1 - lossFactor));
  let amenitiesArea = usableArea * 0.05;

  // Guard against amenities consuming all of the usable area.
  if (usableArea - amenitiesArea < unitArea) amenitiesArea = 0;

  const areaAvailableForUnits = usableArea ? usableArea - amenitiesArea : 0;
  const unitQuantity = unitArea ? Math.floor(areaAvailableForUnits / unitArea) : 0;
  const leftoverArea = areaAvailableForUnits - (unitQuantity * unitArea);
  amenitiesArea += leftoverArea;

  const previousMaximumUnits = developmentAccessors.getInputMaximum(development, VariableId.MultifamilyOneBedQuantityToggleable);
  const previousMaximumAmenities = developmentAccessors.getInputMaximum(development, VariableId.MultifamilyAmenitiesAreaToggleable);

  return {
    [VariableId.MultifamilyOneBedQuantityToggleable]: {
      "minimum": 0,
      "value": 0,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.MultifamilyMicrounitQuantityToggleable]: {
      "minimum": 0,
      "value": 0,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.MultifamilyStudioQuantityToggleable]: {
      "minimum": 0,
      "value": 0,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.MultifamilyTwoBedQuantityToggleable]: {
      "minimum": 0,
      "value": unitQuantity,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.MultifamilyThreeBedQuantityToggleable]: {
      "minimum": 0,
      "value": 0,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.MultifamilyAmenitiesAreaToggleable]: {
      "minimum": 0,
      "value": amenitiesArea,
      "maximum": 10 * amenitiesArea || previousMaximumAmenities
    },
    [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: await generatorFromModel.generateRentPreset(development, VariableId.MultifamilyYearOneMonthlyRentPerMicrounit, BuildingUse.Multifamily, microUnitBedroomArea, 12),
    [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: await generatorFromModel.generateRentPreset(development, VariableId.MultifamilyYearOneMonthlyRentPerStudio, BuildingUse.Multifamily, studioBedroomArea, 12),
    [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: await generatorFromModel.generateRentPreset(development, VariableId.MultifamilyYearOneMonthlyRentPerOneBed, BuildingUse.Multifamily, oneBedroomArea, 12),
    [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: await generatorFromModel.generateRentPreset(development, VariableId.MultifamilyYearOneMonthlyRentPerTwoBed, BuildingUse.Multifamily, twoBedroomArea, 12),
    [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: await generatorFromModel.generateRentPreset(development, VariableId.MultifamilyYearOneMonthlyRentPerThreeBed, BuildingUse.Multifamily, threeBedroomArea, 12),
    [VariableId.MultifamilyHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.MultifamilyHardCostPerArea, BuildingUse.Multifamily),
  };
}

export default generatePresets;
