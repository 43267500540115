import React from "react";
import ShareProjectPopup from "./ShareProjectPopup";

interface OwnProps {
  developmentId: string;
  developmentName: string;
  text: string;
  popupClasses?: string;
  classes?: string;
}

type Props = OwnProps;

interface State {
  shareProjectPopupIsActive: boolean;
}

class ShareProject extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      shareProjectPopupIsActive: false,
    };
  }

  /**
   * Toggle the shareProjectPopupIsActive state.
   */
  toggleShareProjectPopupState = () => {
    this.setState({ shareProjectPopupIsActive: !this.state.shareProjectPopupIsActive });
  }

  render() {
    const { developmentId, developmentName, popupClasses, classes, text } = this.props;
    return (
      <div
        className={`component--share-project ${this.state.shareProjectPopupIsActive ? "active" : ""} ${classes || ""}`}
        onClick={this.toggleShareProjectPopupState}
      >
        <div className="icon" />
        <div className="text">{text}</div>
        {this.state.shareProjectPopupIsActive
            && <ShareProjectPopup developmentId={developmentId} developmentName={developmentName} classes={popupClasses || ""} onStateChange={this.toggleShareProjectPopupState} />}
      </div>
    );
  }
}

export default ShareProject;
