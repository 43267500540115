import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../../state/newDevelopment";
import ZoningFilters from "./ZoningFilters";

const ZoningFiltersContainer = () => {
  const shouldDisplay = useSelector(newDevelopmentSelectors.getZoningFiltersShouldDisplay);

  return shouldDisplay
    ? <>
        <ZoningFilters />
        <div className="divisor" />
      </>
    : null;
}

export default ZoningFiltersContainer;
