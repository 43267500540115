import React from "react";

interface OwnProps {
  isActive: boolean;
  toggleIsActive(): void;
}

type Props = OwnProps;

class TiersButton extends React.PureComponent<Props, {}> {
  render() {
    const { isActive, toggleIsActive } = this.props;

    return (
      <div className={`component--button tiers ${isActive ? "active" : ""}`} onClick={toggleIsActive}>
        <div className="button" />
        <div className="text">Tiers</div>
      </div>
    );
  }
}

export default TiersButton;
