import authentication from "../utils/authentication";
import { authenticationActionTypes } from "./authentication";

const REFRESH_FIREBASE_TOKEN_TIMEOUT = 3300000; // 55 minutes
let autoSaveId: any;
let timeToExec = true;

const REFRESH_TRIGGER_ACTIONS = [
  authenticationActionTypes.SIGN_IN_SUCCESS,
];

/**
 * Stop the refreshToken timeout.
 */
const stopAutoRefresh = () => {
  clearInterval(autoSaveId);
}

/**
 * Fetch the refreshed Token on the right action.
 */
const refreshFirebaseTokenMiddleware = (store) => (next) => (action) => {
  if (REFRESH_TRIGGER_ACTIONS.includes(action.type) || timeToExec) {
    timeToExec = false;
    stopAutoRefresh();
    authentication.fetchFirebaseId();

    autoSaveId = setTimeout(
      () => {
        timeToExec = true;
      }, REFRESH_FIREBASE_TOKEN_TIMEOUT
    );
  }
    return next(action);
  }

export default refreshFirebaseTokenMiddleware;
