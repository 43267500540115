const SET_STATIC_ACCORDION_IS_OPEN = "ui/shared/accordionSection/SET_ACCORDION_IS_OPEN";
const SET_LISTING_ACCORDION_IS_OPEN = "ui/shared/accordionSection/SET_LISTING_ACCORDION_IS_OPEN";
const SET_BOE_ACCORDION_IS_OPEN = "ui/shared/accordionSection/SET_BOE_ACCORDION_IS_OPEN";
const RESET = "ui/shared/accordionSection/RESET";

export default {
  SET_STATIC_ACCORDION_IS_OPEN,
  SET_LISTING_ACCORDION_IS_OPEN,
  SET_BOE_ACCORDION_IS_OPEN,
  RESET,
};
