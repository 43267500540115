import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    alignSelf: "center",
    bottom: 70,
    width: "100%",
  },
  text: {
    fontFamily: "Roboto",
    letterSpacing: 0.1,
    fontSize: 6.25,
    color: "#999999",
  },
});

const Disclaimer = () => (
  <View style={styles.container}>
    <Text style={styles.text}>
      As stated in our Terms of Services, all the data that is accessible
      through this platform is provided as a convenience only, is not official,
      and should not be relied upon. The data is provided "AS IS, WHERE IS AND
      WITH ALL FAULTS". Deep Blocks, Inc. disclaims any express or implied
      warranties of accuracy, completeness, merchantability or fitness of the
      data for any particular purpose.
    </Text>
  </View>
);

export default Disclaimer;
