import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class HotelAverageDailyRateLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.HotelAverageDailyRate}
        classes="hotel-average-daily-rate"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< $50"
          />,
          <LegendRow
            color="#fff305"
            text="$50 - $200"
          />,
          <LegendRow
            color="#0fe600"
            text="$200 - $300"
          />,
          <LegendRow
            color="#16e7fe"
            text="$300 - $500"
          />,
          <LegendRow
            color="#024be8"
            text="> $500"
          />,
        ]}
      />
    );
  }
}

export default HotelAverageDailyRateLegend;
