import React from "react";

interface Props {
  label: string;
  onClick(): void;
}

class CancelLoadingButton extends React.PureComponent<Props, {}> {

  render() {
    const { label, onClick } = this.props;
    return (
      <div className="component--cancel-loading-button">
        <button className="button" onClick={onClick}>{label}</button>
      </div>
    );
  }
}

export default CancelLoadingButton;
