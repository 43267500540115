import { RouteState } from "./reducers";

/**
 * Get the RouteState object.
 */
const getRouteState = (state): RouteState => {
  let routeState = state.ui.shared.route;
  return routeState;
}

/**
 * Get the route is blocked flag.
 */
const getRouteIsBlocked = (state) => {
  const routeState = getRouteState(state);
  return routeState.routeIsBlocked;
}

/**
 * Get the original path name.
 */
const getPathName = (state) => {
  const routeState = getRouteState(state);
  return routeState.pathName;
}

export default {
  getRouteIsBlocked,
  getPathName,
}
