import * as tf from "@tensorflow/tfjs";
import * as firebase from "firebase/app";
import { Development } from "../../../../../types/Development/Development";
import { BuildingUse } from "../../../../../types/BuildingUse";
import featureHelper, { NormalizationConfig } from "./featureHelper";
import unitConversions from "../../../../unitConversions";
import Unit from "../../../../../types/Unit";

const STORAGE_PATH_FOLDER = "hard_cost_model";
const CONSTRUCTION_COST_PERCENT = 0.5;

let model: tf.GraphModel;
let rentNormalizationConfig: NormalizationConfig | undefined;

type RentModelFeatures = [
  number, // POPULATION
  number, // MEDIAN_HOUSEHOLD_INCOME
  number, // MEDIAN_GROSS_RENT_TOTAL
  number, // EMPLOYMENT_PER_POP_RATIO
  number, // Condo
  number, // Hotel
  number, // Industrial
  number, // Multifamily
  number, // Office
  number, // Parking
  number, // Retail
  number, // Market 1
  number, // Market 2
  number // Market 3
];

/**
 * Initialize rent model.
 */
const initialize = async () => {
  if (!model) {
    const modelReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/model.json`);

    const modelUrl = await modelReference.getDownloadURL();
    model = await tf.loadGraphModel(modelUrl, {
      weightUrlConverter: async (fileName) => {
        const binaryReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/${fileName}`);
        return binaryReference.getDownloadURL();
      },
    });

    const normalizationParamsReference = firebase.storage().ref(`models/${STORAGE_PATH_FOLDER}/normalizationParams.json`);
    const normParamsUrl = await normalizationParamsReference.getDownloadURL();
    let data: any = await fetch(normParamsUrl);
    data = await data.json();
    rentNormalizationConfig = { ...(data as NormalizationConfig) };
  }
};

/**
 * Build feature array from the development object and building Use.
 */
const buildFeatures = (development: Development, buildingUse: BuildingUse) => {
  if (!rentNormalizationConfig) throw new Error("Hard cost normalization configuration was not initialized");

  let { medianIncome, population, medianGrossRent, employmentPerPopulation, market } = featureHelper.getBaseFeaturesFromDevelopment(development);

  let normalizedFeatures: RentModelFeatures = [
    featureHelper.normalize(population, rentNormalizationConfig.population),
    featureHelper.normalize(medianIncome, rentNormalizationConfig.medianIncome),
    featureHelper.normalize(medianGrossRent, rentNormalizationConfig.medianGrossRent),
    featureHelper.normalize(employmentPerPopulation, rentNormalizationConfig.employmentPerPopulation),
    buildingUse === BuildingUse.Condo ? 1 : 0, // Condo
    buildingUse === BuildingUse.Hotel ? 1 : 0, // Hotel
    buildingUse === BuildingUse.Industrial ? 1 : 0, // Industrial
    buildingUse === BuildingUse.Multifamily ? 1 : 0, // Multifamily
    buildingUse === BuildingUse.Office ? 1 : 0, // Office
    buildingUse === BuildingUse.Parking ? 1 : 0, // Parking
    buildingUse === BuildingUse.Retail ? 1 : 0, // Retail,
    market === 1 ? 1 : 0, // Market 1
    market === 2 ? 1 : 0, // Market 2
    market === 3 ? 1 : 0, // Market 3
  ];

  return normalizedFeatures;
};

/**
 * Get prediction for the passed in data.
 */
const predict = async (data: RentModelFeatures): Promise<number> => {
  if (!model) throw new Error("Hard cost model is not instantiated");

  const prediction = await (model.predict(tf.tensor2d([data]), {}) as tf.Tensor).data();
  return prediction[0];
};

/**
 * Generate presets for the hard cost.
 */
const generatePreset = async (development: Development, buildingUse: BuildingUse) => {
  const features = buildFeatures(development, buildingUse);
  const pricePerSquareFeet = await predict(features);
  const pricePerSquareMeter = unitConversions.convert(pricePerSquareFeet * CONSTRUCTION_COST_PERCENT, Unit.Type.SquareFeet, Unit.Type.SquareMeters, true);

  return {
    minimum: pricePerSquareMeter / 2,
    value: pricePerSquareMeter,
    maximum: pricePerSquareMeter * 2,
  };
};

export default {
  initialize,
  generatePreset,
};
