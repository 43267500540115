import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../../rootReducer";
import { SubscriptionManagementUiState } from "./reducers";

/**
 * Get the getMapsState object.
 */
const getSubscriptionManagementUiStateState = (state: AppState): SubscriptionManagementUiState => state.ui.subscriptionManagement;

/**
 * Get the tierPanelIsActive flag.
 */
const getTiersPanelIsActive = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.tiersPanelIsActive
);

/**
 * Get the tierPanelIsActive flag.
 */
const getDeleteInvitationPopupIsOpen = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.deleteInvitationPopupIsOpen
);

/**
 * Get the `invitationSent` flag.
 */
const getInvitationIsBeingDeleted = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.invitationIsBeingDeleted
);

/**
 * Get the `invitationIsBeingCancelled` flag.
 */
const getInvitationIsBeingCancelled = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.invitationIsBeingCancelled
);

/**
 * Get the `cancelInvitationPopupIsOpen` flag.
 */
const getCancelInvitationPopupIsOpen = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.cancelInvitationPopupIsOpen
);

/**
 * Get the `invitationIsBeingCancelled` flag.
 */
const getSubscriptionIsBeingCancelled = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.subscriptionIsBeingCancelled
);

/**
 * Get the `cancelSubscriptionPopupIsOpen` flag.
 */
const getCancelSubscriptionPopupIsOpen = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.cancelSubscriptionPopupIsOpen
);

/**
 * Get the invitation error message.
 */
const getInvitationErrorMessage = createSelector(
  getSubscriptionManagementUiStateState,
  (state) => state.errorMessage
);

export default {
  getTiersPanelIsActive,
  getDeleteInvitationPopupIsOpen,
  getInvitationIsBeingDeleted,
  getCancelInvitationPopupIsOpen,
  getInvitationIsBeingCancelled,
  getSubscriptionIsBeingCancelled,
  getCancelSubscriptionPopupIsOpen,
  getInvitationErrorMessage,
}
