import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { subscriptionInvitationsActions, subscriptionInvitationsSelectors } from "../../../../../state/subscriptionInvitations";
import Format from "../../../../../types/Format";
import { Chargebee } from "../../../../../types/Service";
import valueFormatter from "../../../../../utils/valueFormatter";
import NoCreditCardPopup from "../../../../sharedComponents/NoCreditCardPopup";
import PriceEstimateCalculator from "../../sharedComponents/PriceEstimateCalculator";
import DuplicateInvitationPopup from "./DuplicateInvitationPopup";
import InvitationFailurePopup from "./InvitationFailurePopup";
import InvitationSuccessPopup from "./InvitationSuccessPopup";
import RequiredFieldsPopup from "./RequiredFieldsPopup";
import SendingInvitationPopup from "./SendingInvitationPopup";

const TIMEOUT = 1000;

const mapStateToProps = (state) => {
  return {
    creditCard: subscriptionSelectors.getCreditCard(state),
    planPrices: subscriptionSelectors.getPlanPrices(state),
    invitationSent: subscriptionInvitationsSelectors.getInvitationSent(state),
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
  }
}

const mapDispatchToProps = {
  sendInvitation: subscriptionInvitationsActions.inviteStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  name: string;
  email: string;
  couponCode: string;
  couponIsValid: boolean | null;
  emailIsValid: boolean | null;
  planPeriodIsYearly: boolean;
  priceEstimate?: number;
  noCreditCardPopupIsOpen: boolean;
  requiredFieldsPopupIsOpen: boolean;
  inviteFeedbackPopupIsOpen: boolean;
  duplicateInvitationPopupIsOpen: boolean;
}

class MyTeam extends React.PureComponent<Props, State> {
  calculateEstimateTimeout: any;
  validateEmailTimeout: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      couponCode: "",
      couponIsValid: null,
      emailIsValid: null,
      noCreditCardPopupIsOpen: false,
      requiredFieldsPopupIsOpen: false,
      planPeriodIsYearly: false,
      priceEstimate: props.planPrices[Chargebee.PlanId.ProMonthly],
      inviteFeedbackPopupIsOpen: false,
      duplicateInvitationPopupIsOpen: false,
    }
  }

  /**
   * Check validity of Coupon when plan duration is changed.
   */
  componentDidUpdate(previousProps: Props) {
    if (previousProps.invitationSent !== null && this.props.invitationSent === null) {
      this.setState({ inviteFeedbackPopupIsOpen: true });
    }

    if (previousProps.invitationSent === null && this.props.invitationSent === true) {
      this.setState({
        name: "",
        email: "",
        couponCode: "",
        priceEstimate: this.state.planPeriodIsYearly
          ? this.props.planPrices[Chargebee.PlanId.ProYearly]
          : this.props.planPrices[Chargebee.PlanId.ProMonthly]
      });
    }

    if (this.state.priceEstimate === undefined) {
      this.setState({
        priceEstimate: this.state.planPeriodIsYearly
          ? this.props.planPrices[Chargebee.PlanId.ProYearly]
          : this.props.planPrices[Chargebee.PlanId.ProMonthly],
      });
    }
  }

  /**
   * Updates the state when the name changes.
   */
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  /**
   * Updates the state when the email changes.
   */
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value, emailIsValid: null });

    if (this.validateEmailTimeout) clearInterval(this.validateEmailTimeout);
    this.validateEmailTimeout = setTimeout(() => {
      const emailIsValid = /\S+@\S+\.\S+/.test(this.state.email);
      this.setState({ emailIsValid });
      this.validateEmailTimeout = null;
    }, TIMEOUT);
  }

  /**
   * Updates the state when the discount changes.
   */
  handleCouponCodeChange = (event, calculateFinalEstimate: (planPrices: Chargebee.PlanPrices, planId: Chargebee.PlanId, couponCode: string) => Promise<void>) => {
    const couponCode = event.target.value && event.target.value.replace(" ", "").toUpperCase();
    this.setState({ couponCode: couponCode, couponIsValid: null });

    if (this.calculateEstimateTimeout) clearInterval(this.calculateEstimateTimeout);
    this.calculateEstimateTimeout = setTimeout(() => {
      calculateFinalEstimate(this.props.planPrices, this.getPlanId(this.state.planPeriodIsYearly), this.state.couponCode);
    }, TIMEOUT);
  }

  /**
   * Send subscription invitation.
   */
  submit = async (calculateFinalEstimate: (planPrices: Chargebee.PlanPrices, planId: Chargebee.PlanId, couponCode: string) => Promise<void>) => {
    const { creditCard, managedSubscriptions, planPrices } = this.props;
    const planId = this.getPlanId(this.state.planPeriodIsYearly);

    await calculateFinalEstimate(planPrices, planId, this.state.couponCode);

    if (!this.hasRequiredFields()) {
      this.setState({ requiredFieldsPopupIsOpen: true });
      return;
    }

    if (!creditCard || creditCard.status === Chargebee.CardStatus.Expired) {
      this.setState({ noCreditCardPopupIsOpen: true });
      return;
    }

    if (managedSubscriptions && Object.values(managedSubscriptions).some((subscriptionManaged) => subscriptionManaged.email === this.state.email)) {
      this.setState({ duplicateInvitationPopupIsOpen: true });
      return;
    }

    const couponCode = this.state.couponCode ? [this.state.couponCode] : [];
    const paymentPlan = this.state.planPeriodIsYearly ? Chargebee.PlanId.ProYearly : Chargebee.PlanId.ProMonthly;
    this.props.sendInvitation(this.state.email, this.state.name, paymentPlan, couponCode);
  }

  /**
   * Returns true when the form required fields are filled and false otherwise.
   */
  hasRequiredFields = (): boolean => {
    if (this.state.couponIsValid === false) return false;

    return Boolean(this.state.name && this.state.emailIsValid);
  }

  /**
   * Toggle the `noCreditCardPopupIsOpen` state.
   */
  toggleNoCreditCardPopupIsOpen = () => {
    this.setState({ noCreditCardPopupIsOpen: !this.state.noCreditCardPopupIsOpen });
  }

  /**
   * Toggle the `requiredFieldsPopupIsOpen` state.
   */
  toggleRequiredFieldsPopupIsOpen = () => {
    this.setState({ requiredFieldsPopupIsOpen: !this.state.requiredFieldsPopupIsOpen });
  }

  /**
   * Toggle the `inviteFeedbackPopupIsOpen` state.
   */
  toggleInviteFeedbackPopupIsOpen = () => {
    this.setState({ inviteFeedbackPopupIsOpen: !this.state.inviteFeedbackPopupIsOpen });
  }

  /**
   * Toggle the `duplicateInvitationPopupIsOpen` state.
   */
  toggleDuplicateInvitationPopupIsOpen = () => {
    this.setState({ duplicateInvitationPopupIsOpen: !this.state.duplicateInvitationPopupIsOpen });
  }

  /**
   *  Toggle the `planPeriodIsYearly` state.
   */
  togglePlanPeriodIsYearly = (calculateFinalEstimate: (planPrices: Chargebee.PlanPrices, planId: Chargebee.PlanId, couponCode: string) => Promise<void>) => {
    if (this.calculateEstimateTimeout) clearInterval(this.calculateEstimateTimeout);
    const nextPlanPeriodIsYearly = !this.state.planPeriodIsYearly;
    const planId = this.getPlanId(nextPlanPeriodIsYearly);
    calculateFinalEstimate(this.props.planPrices, planId, this.state.couponCode);
    this.setState({ planPeriodIsYearly: nextPlanPeriodIsYearly });
  }

  /**
   * Render the feedback popup.
   */
  renderFeedBackPopup = () => {
    const { inviteFeedbackPopupIsOpen } = this.state;
    const { invitationSent } = this.props;
    if (!inviteFeedbackPopupIsOpen) return null;

    switch (invitationSent) {
      case null:
        return <SendingInvitationPopup preventClose />;
      case true:
        return <InvitationSuccessPopup onStateChange={this.toggleInviteFeedbackPopupIsOpen} />;
      case false:
        return <InvitationFailurePopup onStateChange={this.toggleInviteFeedbackPopupIsOpen} />;
    }

    return;
  }

  /**
   * Callback that has price estimate and coupon valid values.
   */
  handlePriceEstimateChange = (values: { priceEstimate: number, couponIsValid: boolean }) => {
    this.setState({ ...values });
  }

  /**
   * Get the plan ID depending on whether the period is yearly or not.
   */
  getPlanId = (planPeriodIsYearly: boolean): Chargebee.PlanId => {
    return planPeriodIsYearly ? Chargebee.PlanId.ProYearly : Chargebee.PlanId.ProMonthly;
  }

  render() {
    const {
      couponIsValid,
      emailIsValid,
      noCreditCardPopupIsOpen,
      requiredFieldsPopupIsOpen,
      duplicateInvitationPopupIsOpen,
      planPeriodIsYearly,
      priceEstimate,
      name,
      email,
      couponCode,
    } = this.state;

    return (
      <div className="component--my-team">
        <PriceEstimateCalculator onStateChange={this.handlePriceEstimateChange}>
          {(calculateFinalEstimate) => (
            <>
              <div className="header">My Team</div>
              <div className="content">
                <div className="title">Invite Team to PRO</div>
                <div className="toggle-wrapper">
                  <div
                    className={`toggle-button ${planPeriodIsYearly ? "" : "selected"}`}
                    onClick={() => this.togglePlanPeriodIsYearly(calculateFinalEstimate)}
                  >
                    Bill Monthly
                  </div>
                  <div
                    className={`toggle-button ${planPeriodIsYearly ? "selected" : ""}`}
                    onClick={() => this.togglePlanPeriodIsYearly(calculateFinalEstimate)}
                  >
                    Bill Yearly
                  </div>
                </div>

                <div className="text">
                  {priceEstimate !== undefined && `${valueFormatter.format(priceEstimate, { type: Format.Type.Currency })}${planPeriodIsYearly ? "/yr" : "/mo"} will be added to your next bill.`}
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    placeholder="Full Name (required)"
                    autoComplete="off"
                    onChange={this.handleNameChange}
                    value={name}
                  />
                </div>

                <div className={`input-field ${emailIsValid === false ? "invalid" : ""}`}>
                  <input
                    type="email"
                    placeholder="Email Address (required)"
                    autoComplete="off"
                    onChange={this.handleEmailChange}
                    value={email}
                  />
                </div>

                <div className={`input-field ${couponIsValid === false ? "invalid" : ""}`}>
                  <input
                    type="text"
                    placeholder="Discount Code"
                    onChange={(event) => this.handleCouponCodeChange(event, calculateFinalEstimate)}
                    value={couponCode}
                  />
                </div>
                <button onClick={() => this.submit(calculateFinalEstimate)}>Send Invitation</button>
              </div>
              {noCreditCardPopupIsOpen && <NoCreditCardPopup onStateChange={this.toggleNoCreditCardPopupIsOpen} />}
              {duplicateInvitationPopupIsOpen && <DuplicateInvitationPopup onStateChange={this.toggleDuplicateInvitationPopupIsOpen} />}
              {requiredFieldsPopupIsOpen && <RequiredFieldsPopup onStateChange={this.toggleRequiredFieldsPopupIsOpen} message={(couponIsValid === false) && "Coupon is invalid."} />}
              {this.renderFeedBackPopup()}
            </>
          )}
        </PriceEstimateCalculator>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyTeam);
