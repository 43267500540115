import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service";
import { ManagedSubscription } from "./reducers";

/**
 * Send subscription invitation.
 */
const inviteStart = (userEmail: string, userName: string, planId: Chargebee.PlanId, discountCode: Array<string>) => {
  return {
    type: actionTypes.INVITE_START,
    payload: { userEmail, planId, userName, discountCode },
  };
}

/**
 * Set the invitationSent flag on invitation success.
 */
const inviteFailure = () => {
  return {
    type: actionTypes.INVITE_FAILURE,
  };
}

/**
 * Set the invitationSent flag on invitation success.
 */
 const inviteSuccess = () => {
  return {
    type: actionTypes.INVITE_SUCCESS,
  };
}

/**
 * Resend subscription invitation.
 */
const resendStart = (managedUserId: string, couponCode: string) => {
  return {
    type: actionTypes.RESEND_START,
    payload: { managedUserId, couponCode },
  };
}

/**
 * Cancel subscription invitation.
 */
const cancelStart = (userId: string) => {
  return {
    type: actionTypes.CANCEL_START,
    payload: { userId },
  };
}

/**
 * Cancel subscription invitation.
 */
const cancelSuccess = (managedUserId: string, subscriptionInvitationsUpdatedDocument, managedSubscriptions, managedSubscriptionsImages) => {
  return {
    type: actionTypes.CANCEL_SUCCESS,
    payload: { managedUserId, subscriptionInvitationsUpdatedDocument, managedSubscriptions, managedSubscriptionsImages },
  };
}

/**
 * Load a `subscriptionInvitations` document.
 */
const loadStart = (userId: string) => {
  return {
    type: actionTypes.LOAD_START,
    payload: { userId },
  };
}

/**
 * Update the subscription invitations concern in the state.
 */
const loadSuccess = (subscriptionInvitations, managedSubscriptions, managedSubscriptionsImages) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { subscriptionInvitations, managedSubscriptions, managedSubscriptionsImages },
  };
}

/**
 * Delete a subscription invitation.
 */
const deleteStart = (userId: string) => {
  return {
    type: actionTypes.DELETE_START,
    payload: { userId },
  };
}

/**
 * Delete a subscription invitation.
 */
const deleteSuccess = (managedUserId: string, subscriptionInvitations, managedSubscriptions, managedSubscriptionsImages) => {
  return {
    type: actionTypes.DELETE_SUCCESS,
    payload: { managedUserId, subscriptionInvitations, managedSubscriptions, managedSubscriptionsImages },
  };
}

/**
 * Accept a subscription invitation.
 */
const acceptStart = (ownerId: string, subscriptionId?: string) => {
  return {
    type: actionTypes.ACCEPT_START,
    payload: { ownerId, subscriptionId },
  };
}

/**
 * Reload the managed subscriptions.
 */
const acceptSuccess = () => {
  return {
    type: actionTypes.ACCEPT_SUCCESS,
  };
}

/**
 * Reject a subscription invitation.
 */
const rejectStart = (ownerId: string) => {
  return {
    type: actionTypes.REJECT_START,
    payload: { ownerId },
  };
}

/**
 * Reload the managed subscriptions.
 */
const rejectSuccess = () => {
  return {
    type: actionTypes.REJECT_SUCCESS,
  };
}

/**
 * Reload the managed subscriptions.
 */
const updateManagedSubscriptionStatus = (status: string, userId: string, subscriptionData: Partial<ManagedSubscription>) => {
  return {
    type: actionTypes.UPDATE_MANAGED_SUBSCRIPTION_STATUS,
    payload: { status, userId, subscriptionData },
  };
}

export default {
  inviteStart,
  inviteSuccess,
  inviteFailure,
  resendStart,
  cancelStart,
  cancelSuccess,
  loadStart,
  loadSuccess,
  acceptStart,
  acceptSuccess,
  rejectStart,
  rejectSuccess,
  deleteStart,
  updateManagedSubscriptionStatus,
  deleteSuccess,
}
