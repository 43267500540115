import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Path } from "../../../../../types/Path";
import { UserType } from "../../../../sharedComponents/TiersPanel/SubscribeButton/SubscribeButton";
import { useNavigate } from "react-router-dom";

interface OwnProps {
  closePopup(): void;
  userType: UserType;
}

const mapStateToProps = (state) => {
  return {
    projectId: developmentSelectors.getProjectId(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const Welcome: React.FC<Props> = ({ closePopup, userType, projectId }) => {
  const navigate = useNavigate();

  /**
   * Redirect the user to the appropriate page and close the popup.
   */
  const handleClick = () => {
    if (projectId) {
      navigate(`${Path.Explorer}/${projectId}`);
    } else {
      navigate(userType === UserType.Team ? Path.SubscriptionManagement : Path.Dashboard);
    }
    closePopup();
  };

  return (
    <div className="component--welcome">
      <div className="welcome-image" />
      <p>Your account is ready.</p>
      <button onClick={handleClick}>Start Working</button>
    </div>
  );
};

export default connect(mapStateToProps)(Welcome);
