import Chart from "chart.js";
import { ChartData } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { GraphId, GraphDataSources } from "types/Graph";
import { graphsActions, graphsSelectors } from "../../../../../state/graphs";
import { AppState } from "../../../../../state/rootReducer";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { Tier } from "../../../../../types/Tier";
import { getGraphData, GRAPH_TEXT_SETTINGS } from "../../../../../utils/graphHelper";
import { TooltipType } from "../../../../sharedComponents/Tooltip/config";

const mapStateToProps = (state: AppState) => {
  return {
    graphDataSourceList: graphsSelectors.getGraphDataSourceList(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setHighlightedGraphDataSource: graphsActions.setHighlightedGraphDataSource,
  clearHighlightedGraphDataSource: graphsActions.clearHighlightedGraphDataSource,
};

interface OwnProps {
  graphId: GraphId;
  requiresAccess?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & OwnProps & DispatchProps;

export class LineGraph extends React.PureComponent<Props, {}> {
  graphFeatureDataMap: GraphDataSources = {};
  currentFeatureId: string = "";

  /**
   * Set graph feature data on legend item hover.
   */
  handleLegendHover = (event, item: Chart.ChartLegendLabelItem) => {
    if (!item.text || this.currentFeatureId === item.text || item.hidden) return;

    this.currentFeatureId = item.text;
    this.props.setHighlightedGraphDataSource({
      ...this.graphFeatureDataMap[item.text],
    });
  };

  /**
   * Clear hovered feature layer on legend leave.
   */
  handleLegendLeave = (event, item) => {
    this.currentFeatureId = "";
    this.props.clearHighlightedGraphDataSource();
  };

  render() {
    const { graphId, tier, requiresAccess, graphDataSourceList } = this.props;

    if (graphDataSourceList.length === 0) return null;

    const hasAccess = !requiresAccess || tier === Tier.Pro;

    this.graphFeatureDataMap = {};

    const { title, xAxisLabel, yAxisLabel, yAxisTickValueFormatter } = GRAPH_TEXT_SETTINGS[graphId];

    const graphData = getGraphData(graphDataSourceList, graphId);

    const data: ChartData = {
      labels: graphData.labels,
      datasets: graphData.dataset.map((dataset) => {
        this.graphFeatureDataMap[dataset.label] = {
          feature: dataset.feature,
          color: dataset.color,
        };

        return {
          label: dataset.label,
          fill: false,
          lineTension: 0.1,
          borderColor: dataset.color,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: dataset.color,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: dataset.color,
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataset.data,
        };
      }),
    };

    return (
      <div
        className={`component--line-graph ${hasAccess ? "" : "hidden"}`}
        data-for="hover-left"
        data-tip={hasAccess ? null : TooltipType.MarketInsightPanel}
      >
        <div className="title-wrapper">
          <span className="title">{title}</span>
        </div>
        <Line
          data={data}
          height={300}
          options={{
            legend: {
              reverse: true,
              position: "bottom",
              align: "start",
              onHover: this.handleLegendHover,
              onLeave: this.handleLegendLeave,
              display: true,
              labels: {
                boxWidth: 15,
              },
            },

            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: Boolean(xAxisLabel),
                    labelString: xAxisLabel,
                  },
                },
              ],

              yAxes: [
                {
                  ticks: {
                    callback: yAxisTickValueFormatter,
                  },

                  scaleLabel: {
                    display: Boolean(yAxisLabel),
                    labelString: yAxisLabel,
                  },
                },
              ],
            },
          }}
        />
        <div className="legend">
          <strong>Legend:</strong> Census tracts. Click to turn on/off, hover to see on the map.
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineGraph) as React.ComponentType<OwnProps>;
