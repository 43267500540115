import { NavigateFunction } from "react-router-dom";
import actionTypes from "./actionTypes";

/**
 * Start signing out process.
 */
const signOutStart = (
  currentDevelopmentIsEdited: boolean,
  routeOptions?: {
    navigate: NavigateFunction;
    pathName: string;
  }
) => {
  return {
    type: actionTypes.SIGN_OUT_START,
    payload: { currentDevelopmentIsEdited, routeOptions },
  };
};

/**
 * User successfully signed out.
 */
const signOutSuccess = () => {
  return {
    type: actionTypes.SIGN_OUT_SUCCESS,
  };
};

/**
 * User successfully signed in.
 */
const signInSuccess = () => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
  };
};

export default {
  signOutStart,
  signOutSuccess,
  signInSuccess,
};
