import authentication from "../authentication";
import log from "loglevel";
import userSessions from "./userSessions";
import reduxStore from "../../state/reduxStore";
import { authenticationActions } from "../../state/authentication";
import intercom from "../intercom";
import development from "./development";
import subscriptionInvitations from "./subscriptionInvitations";
import analytics from "../analytics";

/**
 * Initializes firebase
 */
const initialize = () => {
  try {

    authentication.onStateChange((user) => {
      if (!user) {
        userSessions.stopListening();
        subscriptionInvitations.stopListening();

        reduxStore.dispatch(authenticationActions.signOutSuccess());
        analytics.trackLogOut();
        intercom.shutdown();
        intercom.initializeAnonymous();
        authentication.fetchFirebaseId();
      } else {
        // It is important to start listening _before_ sending the notification.
        // Otherwise, we may respond to our own notification's "echo" from Firestore.
        userSessions.startListening(user);
        userSessions.notifyUserSessions(user);
        reduxStore.dispatch(authenticationActions.signInSuccess());
        authentication.fetchFirebaseId();
      }
    });

  } catch (error) {
    log.warn(error);
  }
};

export default {
  initialize,
  development,
};
