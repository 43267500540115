import React from "react";
import WithPanelHeader from "../../../../../../../sharedComponents/WithPanelHeader";
import wrapComponentWithPopup, { PopupProps } from "../../../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";

type Props = PopupProps;

class ResetPasswordPopup extends React.PureComponent<Props, {}>  {
  render() {
    return (
      <WithPanelHeader title="Reset Password" hideControls>
        <div className="component--reset-password-popup">
          <div className="text">
            We have sent you a reset password link. Please check your inbox
            and follow the instructions to reset your password.
          </div>

          <button onClick={this.props.closePopup}>Close</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(ResetPasswordPopup);
