import React from "react";
import { MapContext } from "react-mapbox-gl";
import { connect } from "react-redux";
import { setbackModeActions, setbackModeSelectors } from "state/setbackMode";
import Format from "types/Format";
import { MapTool } from "types/MapTool";
import { MeasurementTool } from "types/MeasurementTools";
import Unit from "types/Unit";
import unitConversions from "utils/unitConversions";
import valueFormatter from "utils/valueFormatter";
import MapTools from "views/sharedComponents/MapTools";
import { developmentSelectors } from "../../../../state/development";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";

const PANEL_DESCRIPTION = "Sketches are saved with the project.";
const ALLOW_DIMENSION_BOX = [MeasurementTool.Measurement, MeasurementTool.EditShape]

const TOOL_NAME = {
  [MeasurementTool.SelectShape]: "Move",
  [MeasurementTool.Measurement]: "Draw",
  [MeasurementTool.EditShape]: "Edit",
  [MeasurementTool.DeleteShape]: "Erase",
  [MeasurementTool.CopyShape]: "Copy",
}

const mapStateToProps = (state) => {

  return {
    measurementTool: setbackModeSelectors.getMeasurementTool(state),
    shapePerimeter: setbackModeSelectors.getShapePerimeter(state),
    shapeArea: setbackModeSelectors.getShapeArea(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
  };
};

const mapDispatchToProps = {
  setMeasurementTool: setbackModeActions.setMeasurementTool,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class SetbackMeasurementTools extends React.PureComponent<Props, {}> {

  constructor(props){
    super(props);

    if(this.props.measurementTool !== MeasurementTool.SelectShape){
      this.props.setMeasurementTool(MeasurementTool.SelectShape);
    }
  }

/**
 * Set measurement tool.
 */
  setMeasurementTool = (measurementTool) => {
    return () => {
      this.props.setMeasurementTool(measurementTool);
    }
  }

  /**
   * Convert and format value.
   */
  convertAndFormatValue = (value, unitTarget, formatOptions: Format.Options) => {
    let convertedValue = unitConversions.convertFromBase(value, unitTarget);

    return valueFormatter.format(convertedValue, formatOptions);
  }

  /**
   * Convert, format and return shape area.
   */
  getArea = () => {
    if (!this.props.shapeArea) return null;
    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.SquareMeters, " m²"] : [Unit.Type.SquareFeet, " ft²"];
    let formatOptions = { type: Format.Type.Number, suffix: suffix };

    return this.convertAndFormatValue(this.props.shapeArea, unitTarget, formatOptions);
  }

  /**
   * Convert, format and return shape perimeter.
   */
  getPerimeter = () => {
    if (!this.props.shapePerimeter) return null;
    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.Meters, " m"] : [Unit.Type.Feet, " FT"];
    let formatOptions = { type: Format.Type.Number, suffix: suffix };

    return this.convertAndFormatValue(this.props.shapePerimeter, unitTarget, formatOptions);
  }

/**
 * Render individual tool icons.
 */
  renderToolIcon = (measurementTool: MeasurementTool, description: string) => {
    let className = `control-icon ${measurementTool}`
        + ` ${this.props.measurementTool === measurementTool ? "active" : ""}`

    return <div title={description} className={className} onClick={this.setMeasurementTool(measurementTool)} />;
  }

  /**
   * Render map tool icons.
   */
  renderMapToolIcon = (mapTool: MapTool, description: string, onClickHandler) => {
    let className = `control-icon ${mapTool}`

    return <div title={description} className={className} onClick={() => onClickHandler(mapTool)} />;
  }

  /**
   * Render tools icons
   */
  renderButtons = () => {
    return (
      <>
        <div className="tool-box">
          {this.renderToolIcon(MeasurementTool.SelectShape, "Select Shape")}
          <div className="tool-name"> {TOOL_NAME[MeasurementTool.SelectShape]} </div>
        </div>
        <div className="tool-box">
          {this.renderToolIcon(MeasurementTool.Measurement, "Measurement")}
          <div className="tool-name"> {TOOL_NAME[MeasurementTool.Measurement]} </div>
        </div>
        <div className="tool-box">
          {this.renderToolIcon(MeasurementTool.EditShape, "Edit")}
          <div className="tool-name"> {TOOL_NAME[MeasurementTool.EditShape]} </div>
        </div>
        <div className="tool-box">
          {this.renderToolIcon(MeasurementTool.DeleteShape, "Delete")}
          <div className="tool-name"> {TOOL_NAME[MeasurementTool.DeleteShape]} </div>
        </div>
        <div className="tool-box">
          {this.renderToolIcon(MeasurementTool.CopyShape, "Copy")}
          <div className="tool-name"> {TOOL_NAME[MeasurementTool.CopyShape]} </div>
        </div>
      </>
    )
  }

  /**
   * Render Dimensions Box
   */
  renderDimensionsBox = () => {
    const { measurementTool } = this.props;

    if (!ALLOW_DIMENSION_BOX.includes(measurementTool)) return;

    return (
      <div className="dimensions-box">
        <div className="value-box">
          <p className="label">Perimeter</p>
          <p className="value">{this.getPerimeter() || "-"}</p>
        </div>
        <div className="value-box">
          <p className="label">Area</p>
          <p className="value">{this.getArea() || "-"}</p>
        </div>
      </div>
    )
  }

  render() {
    return (
      <WithPanelHeader title="Measurement Tools">
        <div className="component--setback-measurement-tools">
        <div className="header">{PANEL_DESCRIPTION}</div>
        <div className="tools-row">
          <div className="shapes-buttons">
            {this.renderButtons()}
          </div>
          <div className="map-controls">
            <MapContext.Consumer>
              {(map) => {
                if (!map) return null;

                return (
                  <MapTools map={map}>
                    {(pitchIs3D, onClick) => (
                      <div className="tool-box">
                        <div className="column">
                          {this.renderMapToolIcon(MapTool.ZoomIn, "Zoom In", onClick)}
                          {this.renderMapToolIcon(MapTool.ZoomOut, "Zoom Out", onClick)}
                        </div>
                        <div className="zoom-tool-name"> Zoom Level </div>
                      </div>
                    )}
                  </MapTools>
                )
              }}
            </MapContext.Consumer>
          </div>
        </div>
        {this.renderDimensionsBox()}
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetbackMeasurementTools);
