import actionTypes from "./actionTypes"

/**
 * Toggle the loginIsOpen flag.
 */
const toggleLoginIsOpen = () => {
  return {
    type: actionTypes.TOGGLE_LOGIN_IS_OPEN,
  }
}

export default {
  toggleLoginIsOpen,
}
