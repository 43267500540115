import actionTypes from "./actionTypes";

/**
 * Start fetching thumbnail.
 *
 * @param {string} developmentId
 */
const loadStart = (developmentId: string) => {
  return {
    type: actionTypes.LOAD_START,
    payload: { developmentId },
  };
}

/**
 * On successful fetching of a thumbnail, update store with the image.
 *
 * @param {string} developmentId
 * @param {string} imageUrl
 */
const loadSuccess = (developmentId: string, imageUrl: string) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { developmentId, imageUrl },
  };
}

/**
 * On error fetching a thumbnail, update store to reflect the error.
 *
 * @param {string} developmentId
 */
const loadError = (developmentId: string) => {
  return {
    type: actionTypes.LOAD_ERROR,
    payload: { developmentId },
  };
}

/**
 * Delete an specific thumbnail.
 *
 * @param {string} developmentId
 */
const deleteThumbnail = (developmentId: string) => {
  return {
    type: actionTypes.DELETE,
    payload: { developmentId },
  };
}

/**
 * Set the current thumbnail.
 *
 * @param {Blob} thumbnail
 */
const setCurrentThumbnail = (thumbnail: Blob) => {
  return {
    type: actionTypes.SET_CURRENT_THUMBNAIL,
    payload: { thumbnail },
  };
}

export default {
  loadStart,
  loadSuccess,
  loadError,
  deleteThumbnail,
  setCurrentThumbnail,
}
