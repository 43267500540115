import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Path } from "types/Path";
import analytics from "utils/analytics";
import authentication from "utils/authentication";
import ActivityFeedback from "views/sharedComponents/ActivityFeedback/ActivityFeedback";
import Navbar from "views/sharedComponents/Navbar/Navbar";

enum Status {
  Sending = "SENDING",
  Sent = "SENT",
}

const FEEDBACK_TEXT = {
  verifying: "Verifying your email...",
  success: "Your email has been verified!",
  failure: "This link has expired.",
};

const SPINNING_TIME = 1000; // 1 second.

const EmailVerificationComplete: React.FC = () => {
  const location = useLocation();
  const [status, setStatus] = useState<Status>(Status.Sending);
  const [emailIsVerified, setEmailIsVerified] = useState<boolean>(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await authentication.verifyEmail(location.state.actionCode);
        setEmailIsVerified(true);
        analytics.trackVerifyEmail();
      } catch {
        setEmailIsVerified(false);
      }

      setTimeout(() => {
        setStatus(Status.Sent);
      }, SPINNING_TIME);
    };

    if (location.state && location.state.actionCode) {
      verifyEmail();
    } else {
      console.log(
        "Location state or actionCode is not set. Please try again later"
      );
    }

    analytics.trackPageview(Path.VerifyEmail);
  }, [location]);

  const renderFeedback = () => {
    switch (status) {
      case Status.Sending:
        return (
          <ActivityFeedback
            message={FEEDBACK_TEXT.verifying}
            displaySpinner={true}
          />
        );
      case Status.Sent:
        if (emailIsVerified) {
          return (
            <ActivityFeedback
              message={FEEDBACK_TEXT.success}
              secondaryMessage="You may close this tab."
              displaySpinner={false}
            />
          );
        } else {
          return (
            <ActivityFeedback
              message={FEEDBACK_TEXT.failure}
              secondaryMessage="You may close this tab."
              displaySpinner={false}
            />
          );
        }
      default:
        return null;
    }
  };

  return (
    <div className="component--email-verification-complete-page">
      <Navbar disableLink />
      {renderFeedback()}
    </div>
  );
};

export default EmailVerificationComplete;
