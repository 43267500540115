import React from "react";
import ReactDOM from "react-dom";
import ReactPdf from "@react-pdf/renderer";
import PdfContext from "./PdfContext";
import PdfDocument from "./PdfDocument";
import { PdfContextValue } from "./PdfContext/PdfContext";

type Props = {
  className: string;
  state: PdfContextValue;
  onPdfRender(): void;
  onStateChange(): void;
};

// NOTE: ReactPdf.PDFViewer renders an iFrame, which creates a separate DOM tree
// across which the existing Redux store is not preserved. Although it is possible
// to re-mount the Redux store provider onto the iFrame's DOM tree here, doing
// so resulted in a large number of orphaned callbacks within `react-redux` that
// severely damaged application performance. It was therefore decided that the
// Redux store should not be mounted beyond this point, and a special React
// context (`PdfContext`) would be used instead to provide the necessary data to
// the PDF's components.
class PdfViewer extends React.Component<Props, {}> {
  // NOTE: There is an error when PdfViewer is rendered multiple times. In order to prevent such error,
  // this lifecycle method is used to prevent the component from re-rendering. However, as noted on the official
  // docs (https://reactjs.org/docs/react-component.html#shouldcomponentupdate), this method may change in the future.
  // As of react version 16.8.5, this is working as intended.
  shouldComponentUpdate() {
    return false;
  }

  /**
   * Render the PdfViewer.
   */
  renderPdfViewer = () => {
    return (
      <ReactPdf.PDFViewer className="component--pdf-viewer">
        <PdfContext.Provider value={this.props.state}>
          <PdfDocument onRender={this.props.onPdfRender} />
        </PdfContext.Provider>
      </ReactPdf.PDFViewer>
    );
  };

  render() {
    return ReactDOM.createPortal(this.renderPdfViewer(), document.getElementById("pop-up-container") as HTMLElement);
  }
}

export default PdfViewer;
