import React from "react";
import { connect } from "react-redux";
import getFormattedValuesForPresentation from "utils/parcel/parcelDataPresentationHelper";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import Cell from "../../../Cell";
import CellRow from "../../../CellRow";
import CellText from "../../../CellText";

type OwnProps = {
  parcelFeature: any;
}

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render parking ratio section.
 */
const ParkingRatio = (props: Props) => {
  const { parcelFeature, tier } = props;
  const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);

  const parkingRatio = {
    residential: parcelAccessors.getResidentialParkingRatio(parcelFeature),
    singleFamily: parcelAccessors.getSingleFamilyParkingRatio(parcelFeature),
    hotel: parcelAccessors.getHotelParkingRatio(parcelFeature),
    retail: parcelAccessors.getRetailParkingRatio(parcelFeature),
    office: parcelAccessors.getOfficeParkingRatio(parcelFeature),
    industrial: parcelAccessors.getIndustrialParkingRatio(parcelFeature),
  }
  const hiddenMap: Format.FormattingMap = (value) => ({ value, formatOptions: { hidden: !userHasAccess } });

  if (Object.values(parkingRatio).every((use) => use.length === 0)) return null;

  const formattedResidentailParkingRatio = getFormattedValuesForPresentation(parkingRatio.residential);
  const formattedSingleFamilyParkingRatio = getFormattedValuesForPresentation(parkingRatio.singleFamily);
  const formattedHotelParkingRatio = getFormattedValuesForPresentation(parkingRatio.hotel);
  const formattedRetailParkingRatio = getFormattedValuesForPresentation(parkingRatio.retail);
  const formattedOfficeParkingRatio = getFormattedValuesForPresentation(parkingRatio.office);
  const formattedIndustrialParkingRatio = getFormattedValuesForPresentation(parkingRatio.industrial);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Parking by Use</th>
        </tr>
      </thead>
      <tbody>
        {parkingRatio.residential.length > 0
            ? <CellRow>
                <CellText text="Residential" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedResidentailParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
        {parkingRatio.singleFamily.length > 0
            ? <CellRow>
                <CellText text="Single Family" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedSingleFamilyParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
        {parkingRatio.hotel.length > 0
            ? <CellRow>
                <CellText text="Hotel" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedHotelParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
        {parkingRatio.retail.length > 0
            ? <CellRow>
                <CellText text="Retail" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedRetailParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
        {parkingRatio.office.length > 0
            ? <CellRow>
                <CellText text="Office" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedOfficeParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
        {parkingRatio.industrial.length > 0
            ? <CellRow>
                <CellText text="Industrial" hiddenText={!userHasAccess} />
                <Cell getter={valueGetters.generic} value={formattedIndustrialParkingRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
              </CellRow>
            : null
        }
      </tbody>
    </table>
  );
}

export default connect(
  mapStateToProps
)(ParkingRatio);
