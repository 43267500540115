import React from "react"
import { Map } from "mapbox-gl";
import { MapTool } from "../../../types/MapTool";

interface State {
  pitchIs3D: boolean;
}

type Props = {
  map: Map;
  children(currentPitchIs3D: boolean, onClick: (mapTool: MapTool) => void);
};

const INITIAL_3D_PITCH = 60;

class MapTools extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pitchIs3D: props.map.getPitch() > 0,
    }

    props.map.on("pitchend", this.updateComponent);
  }

  /**
   * Remove map listeners.
   */
  componentWillUnmount() {
    this.props.map.off("pitchend", this.updateComponent);
  }

  /**
   * Attach event listener in case maps reference changes.
   */
  componentDidUpdate(previousProps: Props) {
    if(previousProps.map !== this.props.map) {
      // This is necessary because of a delay of updating the map
      // from the context provider. It keeps a reference to the old
      // map, so we need to attach the "pitchend" callback on the new map reference.
      this.props.map.on("pitchend", this.updateComponent);

      this.setState({
        pitchIs3D: this.props.map.getPitch() > 0,
      })
    }
  }

  /**
   * Handle onClick event depending on the map tool used.
   */
  onClickHandler = (mapTool: MapTool) => {
    const { map } = this.props;

    switch (mapTool) {
      case MapTool.Rotate:
        this.rotateMap(map);
        break;
      case MapTool.TogglePitch:
        this.togglePitch(map);
        break;
      case MapTool.ZoomIn:
        this.zoomIn(map);
        break;
      case MapTool.ZoomOut:
        this.zoomOut(map);
        break;
    }
  }

  /**
   * Rotate map by 45 degrees from current bearing.
   */
  rotateMap = (map: Map) => {
    const currentBearing = map.getBearing();
    const nextBearing = currentBearing - 45 - (currentBearing % 45);
    map.rotateTo(nextBearing);
  }

  /**
   * Allow user to switch between 2D and 3D.
   */
  togglePitch = (map: Map) => {
    const nextPitchIs3D = !map.dragRotate.isEnabled();

    if (nextPitchIs3D) {
      map.dragRotate.enable();
      map.setPitch(INITIAL_3D_PITCH);
    } else {
      const previousPitch = map.getPitch();
      map.dragRotate.disable();

      if (previousPitch === 0) {
        this.setState({ pitchIs3D: nextPitchIs3D });
      } else {
        map.setPitch(0);
      }
    }
  }

  /**
   * Zoom in the map.
   */
  zoomIn = (map: Map) => {
    map.zoomIn();
  }

  /**
   * Zoom out the map.
   */
  zoomOut = (map: Map) => {
    map.zoomOut();
  }

  /**
   * Update local state when map pitch changes.
   */
  updateComponent = () => {
    const currentPitchIs3D = this.props.map.dragRotate.isEnabled();
    if (this.state.pitchIs3D !== currentPitchIs3D) {
      this.setState({ pitchIs3D: currentPitchIs3D });
    }
  }

  render() {
    const { children } = this.props;
    return children(this.state.pitchIs3D, this.onClickHandler)
  }
}

export default MapTools;
