import React from "react";
import { subscriptionInvitationsActions, subscriptionInvitationsSelectors } from "../../../../../state/subscriptionInvitations";
import wrapComponentWithPopup, { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../sharedComponents/WithPanelHeader";
import { connect } from "react-redux";
import BusySpinner from "../../../../sharedComponents/BusySpinner";
import { subscriptionManagementSelectors } from "../../../../../state/ui/subscriptionManagement";

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    invitationIsBeingDeleted: subscriptionManagementSelectors.getInvitationIsBeingDeleted(state),
  }
}

const mapDispatchToProps = {
  deleteInvitation: subscriptionInvitationsActions.deleteStart,
};

interface OwnProps {
  userId: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & DispatchProps & StateProps & OwnProps;

class DeleteInvitationPopup extends React.PureComponent<Props, {}>  {
  /**
   * Delete invitation.
   */
  deleteInvitation = () => {
    const { userId, deleteInvitation, invitationIsBeingDeleted } = this.props;
    if (invitationIsBeingDeleted) return;
    deleteInvitation(userId);
  }

  render() {
    const { userId, managedSubscriptions, invitationIsBeingDeleted } = this.props;
    if (!managedSubscriptions || !managedSubscriptions[userId]) return null;

    return (
      <WithPanelHeader title="Delete Invitation" hideControls>
        <div className="component--delete-invitation-popup">
          <div className="text">
            Are you sure you’d like to delete <span>{managedSubscriptions[userId].name}</span> invitation?
          </div>

          <div className="buttons-container">
            <button
              className="cancel"
              onClick={this.props.closePopup}
            >
              Cancel
            </button>
            <button
              className="delete"
              onClick={this.deleteInvitation}
            >
              {invitationIsBeingDeleted
                ? <BusySpinner classes="white-spinner" />
                : "Delete"
              }
            </button>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DeleteInvitationPopup)
);
