import React from "react";
import { View, StyleSheet, Image } from "@react-pdf/renderer";
import Pdf from "../../../../../../../types/Pdf";
import PdfContext from "../../../PdfContext";
import UsesLegend from "./UsesLegend";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  locationImage: {
    top: 0,
  },
  buildingImage: {
    top: 6,
  },
});

const Images = () => (
  <PdfContext.Consumer>
    {({ mapImages }) => {
      return (
        <View style={styles.container}>
          <Image style={styles.locationImage} src={mapImages[Pdf.ImageType.Location]} />
          <Image style={styles.buildingImage} src={mapImages[Pdf.ImageType.Building]} />
          <UsesLegend />
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Images;
