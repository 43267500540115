import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../rootReducer";

/**
 * Select the features state.
 */
const selectFeaturesState = (state: AppState) => state.layers;

/**
 * Select Hotel ADR Features from the layers state.
 */
const selectHotelAverageDailyRateFeatures = createSelector(
  selectFeaturesState,
  (state) => state.hotelAverageDailyRateFeatures
)

/**
 * Select Industrial Rent Features from the layers state.
 */
const selectIndustrialYearlyRentFeatures = createSelector(
  selectFeaturesState,
  (state) => state.industrialYearlyRentFeatures
);

/**
 * Select Multifamily Rent Features from the layers state.
 */
const selectMultifamilyMonthlyRentFeatures = createSelector(
  selectFeaturesState,
  (state) => state.multifamilyMonthlyRentFeatures
)

/**
 * Select Office Rent Features from the layers state.
 */
const selectOfficeYearlyRentFeatures = createSelector(
  selectFeaturesState,
  (state) => state.officeYearlyRentFeatures
)

/**
 * Select Retail Rent Features from the layers state.
 */
const selectRetailYearlyRentFeatures = createSelector(
  selectFeaturesState,
  (state) => state.retailYearlyRentFeatures
)

export default {
  selectHotelAverageDailyRateFeatures,
  selectIndustrialYearlyRentFeatures,
  selectMultifamilyMonthlyRentFeatures,
  selectOfficeYearlyRentFeatures,
  selectRetailYearlyRentFeatures,
}
