const EMPTY_NAME_PLACEHOLDER = "Untitled Project";
const DATE_STRING_FORMATTER = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric", year: "numeric" });
const TIME_STRING_FORMATTER = new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" });

/**
 * Generate the display name for a development.
 * Return the value of the developmentName argument if it's not empty, null or undefined
 * and the value of the constant EMPTY_NAME_PLACEHOLDER otherwise.
 */
const getDisplayName = (developmentName: string): string => {
  const trimmedDevelopmentName = developmentName.trim();
  return trimmedDevelopmentName || EMPTY_NAME_PLACEHOLDER;
}

/**
 * Generate the last modified string for a development.
 * The date string will be formatted as:
 *
 * [Short Month] [Day], [Year] at [H:MM] [AM/PM]
 *
 * Example:
 *
 * Apr 8, 2019 at 10:08 AM
 */
const getLastModifiedString = (developmentTimeModified: number | null): string => {
  if (!developmentTimeModified) return "";

  const lastModifiedDate = new Date(developmentTimeModified);
  const lastModifiedDayString = DATE_STRING_FORMATTER.format(lastModifiedDate);
  const lastModifiedTimeString = TIME_STRING_FORMATTER.format(lastModifiedDate);

  return `Last opened: ${lastModifiedDayString} at ${lastModifiedTimeString}`;
}

export default {
  EMPTY_NAME_PLACEHOLDER,
  getDisplayName,
  getLastModifiedString
}
