import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "rc-slider";
import { developmentActions, developmentSelectors } from "../../../../../state/development";
import Format from "../../../../../types/Format";
import { VariableId } from "../../../../../types/VariableId";
import Unit from "../../../../../types/Unit";
import { BuildingUse } from "../../../../../types/BuildingUse";
import valueFormatter from "../../../../../utils/valueFormatter";
import { buildingUsageIsEnabled } from "../../../../utils/uiToggleHelper";
import roundToDecimal from "../../../../../utils/roundToDecimal";
import analytics from "../../../../../utils/analytics";
import unitConversions from "../../../../../utils/unitConversions";
import { usageColor } from "../../../../utils/buildingUsageProperties";
import { colorFor } from "../../../../utils/colors";

enum InputName {
  Minimum = "MINIMUM",
  Maximum = "MAXIMUM",
  Value = "VALUE",
};

const CONDO_BUILDABLE_TO_UNITS = {
  [VariableId.CondoMicrounitArea]: VariableId.CondoMicrounitQuantityToggleable,
  [VariableId.CondoStudioArea]: VariableId.CondoStudioQuantityToggleable,
  [VariableId.CondoOneBedArea]: VariableId.CondoOneBedQuantityToggleable,
  [VariableId.CondoTwoBedArea]: VariableId.CondoTwoBedQuantityToggleable,
  [VariableId.CondoThreeBedArea]: VariableId.CondoThreeBedQuantityToggleable,
}

const CONDO_SALEPRICE_TO_UNITS = {
  [VariableId.CondoSalePricePerMicrounit]: VariableId.CondoMicrounitQuantityToggleable,
  [VariableId.CondoSalePricePerStudio]: VariableId.CondoStudioQuantityToggleable,
  [VariableId.CondoSalePricePerOneBed]: VariableId.CondoOneBedQuantityToggleable,
  [VariableId.CondoSalePricePerTwoBed]: VariableId.CondoTwoBedQuantityToggleable,
  [VariableId.CondoSalePricePerThreeBed]: VariableId.CondoThreeBedQuantityToggleable,
}

const MULTIFAMILY_BUILDABLE_TO_UNITS = {
  [VariableId.MultifamilyMicrounitArea]: VariableId.MultifamilyMicrounitQuantityToggleable,
  [VariableId.MultifamilyStudioArea]: VariableId.MultifamilyStudioQuantityToggleable,
  [VariableId.MultifamilyOneBedArea]: VariableId.MultifamilyOneBedQuantityToggleable,
  [VariableId.MultifamilyTwoBedArea]: VariableId.MultifamilyTwoBedQuantityToggleable,
  [VariableId.MultifamilyThreeBedArea]: VariableId.MultifamilyThreeBedQuantityToggleable,
}

const MULTIFAMILY_SALEPRICE_TO_UNITS = {
  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: VariableId.MultifamilyMicrounitQuantityToggleable,
  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: VariableId.MultifamilyStudioQuantityToggleable,
  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: VariableId.MultifamilyOneBedQuantityToggleable,
  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: VariableId.MultifamilyTwoBedQuantityToggleable,
  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: VariableId.MultifamilyThreeBedQuantityToggleable,
}



interface OwnProps {
  variableId: VariableId;
  unitTarget: Unit.Type;
  unitIsInverse: boolean;
  buildingUse: BuildingUse;
  disabled: boolean;
  text: string;
  formatOptions: Format.Options;
  index?: number;
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    [InputName.Value]: developmentSelectors.getValue(state, ownProps.variableId, ownProps.index),
    [InputName.Minimum]: developmentSelectors.getMinimumConstraint(state, ownProps.variableId, ownProps.index),
    [InputName.Maximum]: developmentSelectors.getMaximumConstraint(state, ownProps.variableId, ownProps.index),
    increment: developmentSelectors.getIncrementConstraint(state, ownProps.variableId),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
    allCustomSliderNames: developmentSelectors.getAllCustomSliderNames(state),
  };
};

const mapDispatchToProps = {
  setRangedInput: developmentActions.setRangedInput,
  setRangedInputMaximum: developmentActions.setRangedInputMaximum,
  setRangedInputMinimum: developmentActions.setRangedInputMinimum,
  setCustomSliderName: developmentActions.setCustomSliderName,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class ReadOnlyLinkedSliderBox extends Component<Props>  {
  sliderReference: any;

  constructor(props) {
    super(props);

    this[InputName.Value] = this.props[InputName.Value];
    this[InputName.Minimum] = this.props[InputName.Minimum];
    this[InputName.Maximum] = this.props[InputName.Maximum];
  }

  /**
   * Handle the slider changes.
   */
  handleValueUpdate = (sliderValue: number) => {
    let value = this.getNearestSnappingPoint(sliderValue);
    this.props.setRangedInput(this.props.variableId, value, this.props.index);
  }

  /**
   * Return the value of the nearest snapping point of the slider for a given value.
   * The snapping points of a slider are the multiples of the increment, the min and the max.
   */
  getNearestSnappingPoint = (sliderValue: number) => {
    let min = this.props[InputName.Minimum];
    let max = this.props[InputName.Maximum];
    let value = sliderValue;
    let increment = this.props.increment;

    if (value <= min) {
      value = min;
    } else if (value >= max) {
      value = max;
    } else {
      let remainder = value % increment;
      let previousMultiple = value - remainder;
      let nextMultiple = previousMultiple + increment;
      let previousSnap = Math.max(previousMultiple, min);
      let nextSnap = Math.min(nextMultiple, max);
      value = value - previousSnap < nextSnap - value
          ? previousSnap
          : nextSnap;
    }

    return value;
  }

  /**
   * Makes sure that when the slider Handle is clicked but not moved, any input
   * that is currently being modified losses its focus.
   */
  handleClick = () => {
    this.sliderReference.focus();
  }

  /**
   * Handle the input changes.
   */
  handleInputChange = (event) => {
    this[event.target.name] = Number(event.target.value);
  }

  /**
   * Handle form submission. When user presses enter the focus is removed from the input element
   * and the handleInputBlur handles the rest of the logic. This was necessary
   * to avoid doing the logic twice.
   */
  handleFormSubmit = (event) => {
    event.preventDefault();
    this[`${event.target.name}Reference`].blur();
  }

  /**
   * Convert value to metric system.
   */
  convertToBase = (value) => {
    return this.props.unitTarget
        ? unitConversions.convertToBase(value, this.props.unitTarget, this.props.unitIsInverse)
        : value;
  }

  /**
   * Convert value from metric system.
   */
  convertFromBase = (value) => {
    return this.props.unitTarget
        ? unitConversions.convertFromBase(value, this.props.unitTarget, this.props.unitIsInverse)
        : value;
  }

  /**
   * Handle the onBlur event. Update the slider (and whole platform) with the new value.
   */
  handleInputBlur = (event) => {
    // Round the value before saving it.
    let value = this.round(this[event.target.name]);

    // Convert before saving it.
    value = this.convertToBase(value);

    // Put the percentages back to the 0-1 range.
    if (this.props.formatOptions.type === Format.Type.Percentage) {
      value = value / 100;
    }

    switch (event.target.name as InputName) {
      case InputName.Minimum:
        analytics.trackSetRangedInputMinimum(this.props.variableId, value);
        this.props.setRangedInputMinimum(this.props.variableId, value, this.props.index);
        break;
      case InputName.Maximum:
        analytics.trackSetRangedInputMaximum(this.props.variableId, value);
        this.props.setRangedInputMaximum(this.props.variableId, value, this.props.index);
        break;
      default: // Value.
        analytics.trackSetRangedInput(this.props.variableId, value);
        this.props.setRangedInput(this.props.variableId, value, this.props.index);
        break;
    }

    event.target.value = "";
    event.target.placeholder = this.formatInputPlaceholder(event.target.name);
  }

  /**
   * Round value to the appropriate number of decimal places.
   */
  round = (value) => {
    let decimalPlaces = 0;
    if (this.props.formatOptions && this.props.formatOptions.decimalPlaces) {
      decimalPlaces = this.props.formatOptions.decimalPlaces;
    }
    return roundToDecimal(value, decimalPlaces);
  }

  /**
   * Handle the onFocus event.
   */
  handleInputFocus = (event) => {
    event.target.placeholder = "";
    let inputValue;

    // Show percentages on range 0-100.
    if (this.props.formatOptions.type === Format.Type.Percentage) {
      inputValue = this.props[event.target.name] * 100;
    } else {
      inputValue = this.convertFromBase(this.props[event.target.name]);
    }
    inputValue = this.round(inputValue);

    event.target.value = inputValue;
    this[event.target.name] = Number(event.target.value);
    event.target.select();
  }

  /**
   * Prevent the user from entering a non-number value.
   */
  handleKeyPress = (event) => {
    if ((event.key < "0" || event.key > "9") && event.key !== "." && event.key !== "Enter" && event.key !== "-") {
      event.preventDefault();
    }
  }

  /**
   * Prevent the numbers on the input to change on scrolling.
   */
  handleWheel = (event) => {
    if (document.activeElement === this[`${event.target.name}Reference`]) {
      event.preventDefault();
    }
  }

  /**
   * Format the input values to be set on the placeholder.
   */
  formatInputPlaceholder = (nameOfInput: InputName) => {
    let suffix: string | undefined;

    switch (nameOfInput) {
      case InputName.Value:
        suffix = this.props.formatOptions ? this.props.formatOptions.suffix : undefined;
        break;
      default: // Minimum and Maximum
        suffix = undefined;
        break;
    }

    let value = this.convertFromBase(this.props[nameOfInput]);

    return valueFormatter.format(value, { ...this.props.formatOptions, suffix: suffix });
  }

  /**
   * Render the forms of the slider box.
   */
  sliderForm = (inputName: InputName, type: string) => {
    return (
      <form
        className={`slider-row-${type}`}
        name={inputName}
        onSubmit={this.handleFormSubmit}
        noValidate
      >
        <input
          ref={(reference) => this[`${inputName}Reference`] = reference}
          name={inputName}
          type="number"
          placeholder={this.formatInputPlaceholder(inputName)}
          step="any"
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onKeyPress={this.handleKeyPress}
          onWheel={this.handleWheel}
        />
        <input type="submit" />
      </form>
    );
  }

  /**
   * Check to see if the current usageGroup is enabled.
   */
  isBuildingUsageEnabled = () => {
    let usageGroup = this.props.buildingUse;
    return usageGroup && buildingUsageIsEnabled(this.props.toggles, usageGroup);
  }

  /**
   * Display slider name based on the existence (or not) of a custom name for the corresponding units slider.
   */
  displaySliderName = () => {
    const { allCustomSliderNames, variableId, text } = this.props;
    if(!allCustomSliderNames) return text;

    let sliderName = text;
    const condoBuildableSlider = CONDO_BUILDABLE_TO_UNITS[variableId];
    const condoSalesPriceSlider = CONDO_SALEPRICE_TO_UNITS[variableId];
    const multifamilyBuildableSlider = MULTIFAMILY_BUILDABLE_TO_UNITS[variableId];
    const multifamilySalesPriceSlider = MULTIFAMILY_SALEPRICE_TO_UNITS[variableId];

    if(condoBuildableSlider && allCustomSliderNames[condoBuildableSlider]) sliderName = allCustomSliderNames[condoBuildableSlider].customName;
    if(condoSalesPriceSlider && allCustomSliderNames[condoSalesPriceSlider]) sliderName = allCustomSliderNames[condoSalesPriceSlider].customName;
    if(multifamilyBuildableSlider && allCustomSliderNames[multifamilyBuildableSlider]) sliderName = allCustomSliderNames[multifamilyBuildableSlider].customName;
    if(multifamilySalesPriceSlider && allCustomSliderNames[multifamilySalesPriceSlider]) sliderName = allCustomSliderNames[multifamilySalesPriceSlider].customName;

    return sliderName;
  }

  /**
   * Handle slider naming change (user input)
   */
  handleSliderNaming = (event) => {
    const { variableId, text } = this.props;
    const customName = event.target.value;

    this.props.setCustomSliderName({variableId, defaultName: text, customName})
  }

  render() {
    if (!this.isBuildingUsageEnabled()) return null;

    const { variableId, disabled, buildingUse } = this.props;
    return (
      <div className={`component--slider-box ${disabled ? "disabled" : ""}`}>
        <div className="slider-row-body">
          <div className="read-only-slider-title"> {this.displaySliderName()} </div>
          <div className="slider-wrapper">
            <div className="slider-inner-wrapper">
              <Slider
                ref={(reference) => this.sliderReference = reference}
                min={this.props[InputName.Minimum]}
                max={this.props[InputName.Maximum]}
                step={0.000001}
                disabled={disabled}
                onBeforeChange={this.handleClick}
                onChange={this.handleValueUpdate}
                onAfterChange={(value) => analytics.trackChangeSlider(variableId, value)}
                value={this.props[InputName.Value]}
                tabIndex={-1}
                handleStyle={{
                  backgroundColor: disabled ? colorFor("disabled-slider-box") : usageColor(buildingUse)
                }}
                trackStyle={{
                  backgroundColor: disabled ? colorFor("disabled-slider-box") : usageColor(buildingUse),
                }}
              />
            </div>
          </div>
          <div className="slider-limits-wrapper">
            {this.sliderForm(InputName.Minimum, "limits")}
            {this.sliderForm(InputName.Maximum, "limits")}
          </div>
        </div>

        {this.sliderForm(InputName.Value, "value")}

      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadOnlyLinkedSliderBox);
