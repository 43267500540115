import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../../state/development";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../../state/ui/shared/accordionSection/reducers";
import AccordionSection from "../../../../sharedComponents/AccordionSection";
import CellRow from "../../../../sharedComponents/CellRow";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import CellsContainer from "../../../../sharedComponents/CellsContainer";
import config from "../config";
import { BuildingUse } from "../../../../../types/BuildingUse";
import { VariableId } from "../../../../../types/VariableId";
import Format from "../../../../../types/Format";
import SliderBox from "../../sharedComponents/SliderBox";
import { incomeProducingUsesIsEnabled } from "../../../../utils/uiToggleHelper";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    boeAccordionsAreOpen: accordionSectionSelectors.getBoeAccordionsAreOpen(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    projectIsForSale: developmentSelectors.getProjectIsForSale(state),
  };
};

const mapDispatchToProps = {
  setBoeAccordionIsOpen: accordionSectionActions.setBoeAccordionIsOpen,
  setProjectIsForSale: developmentActions.setProjectIsForSale,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class BackOfEnvelope extends React.PureComponent<Props, {}>  {
  /**
   * Toggle the accordions.
   */
  toggleAccordionIsOpen = (accordionId: Accordions) => {
    const accordionIsOpen = this.props.boeAccordionsAreOpen[accordionId];
    this.props.setBoeAccordionIsOpen(accordionId, !accordionIsOpen);
  }

  render() {
    const {
      boeAccordionsAreOpen,
      toggles,
      projectIsForSale,
      setProjectIsForSale
    } = this.props;
    const hasIncomeProducingUses = incomeProducingUsesIsEnabled(toggles);
    const condoIsActive = toggles[BuildingUse.Condo];

    return (
      <div className="panel-content">
        <div className="selection-container">
          <div className="sale-lease">
            <label className={`${condoIsActive ? "disabled" : ""}`}>
              <input
                type="radio"
                checked={!projectIsForSale}
                onChange={() => setProjectIsForSale(false)}
                disabled={condoIsActive}
              />
              Income Producing
            </label>
            <label>
              <input
                type="radio"
                checked={projectIsForSale}
                onChange={() => setProjectIsForSale(true)}
              />
              For Sale
            </label>
          </div>
          {projectIsForSale &&
            <>
              {hasIncomeProducingUses &&
                <div className="slider-container">
                  <SliderBox
                    variableId={VariableId.ExitCapRate}
                    text="Exit Cap Rate"
                    formatOptions={{ type: Format.Type.Percentage, decimalPlaces: 1 }}
                    buildingUse={BuildingUse.Project}
                  />
                </div>
              }
              <div className="slider-container">
                <SliderBox
                  variableId={VariableId.ProjectSaleCommissionPercentage}
                  text="Sales Commission"
                  formatOptions={{ type: Format.Type.Percentage, decimalPlaces: 1 }}
                  buildingUse={BuildingUse.Project}
                />
              </div>
            </>
          }
        </div>

        {hasIncomeProducingUses &&
          <>
            <AccordionSection
              title="Gross Income"
              isOpen={boeAccordionsAreOpen[Accordions.GrossIncome]}
              toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.GrossIncome)}
            >
              <table>
                <tbody>
                  <CellRow usageGroup={BuildingUse.Multifamily}>
                    <ConfigurationHandler config={config.multifamilyYearOnePotentialGrossIncome} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Retail}>
                    <ConfigurationHandler config={config.retailYearOnePotentialGrossIncome} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Office}>
                    <ConfigurationHandler config={config.officeYearOnePotentialGrossIncome} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Industrial}>
                    <ConfigurationHandler config={config.industrialYearOnePotentialGrossIncome} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualPotentialRoomRevenueForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualPotentialOtherRevenueForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                </tbody>
              </table>
            </AccordionSection>
            <table className="section-end">
              <tbody>
                <CellRow>
                  <ConfigurationHandler config={config.incomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>
          </>
        }

        {condoIsActive &&
          <table className="section-end separate">
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.condoGrossSales} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        }

        {hasIncomeProducingUses &&
          <>
            <AccordionSection
              title="Vacancy"
              isOpen={boeAccordionsAreOpen[Accordions.Vacancy]}
              toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Vacancy)}
            >
              <table>
                <tbody>
                  <CellRow usageGroup={BuildingUse.Multifamily}>
                    <ConfigurationHandler config={config.multifamilyAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Retail}>
                    <ConfigurationHandler config={config.retailAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Office}>
                    <ConfigurationHandler config={config.officeAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Industrial}>
                    <ConfigurationHandler config={config.industrialAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualRoomUnrealizedRevenueForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualOtherUnrealizedRevenueForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                </tbody>
              </table>
            </AccordionSection>
            <table className="section-end">
              <tbody>
                <CellRow usageGroup={BuildingUse.IncomeProducing}>
                  <ConfigurationHandler config={config.incomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>

            <AccordionSection
              title="Operating Expense"
              isOpen={boeAccordionsAreOpen[Accordions.OperatingExpense]}
              toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.OperatingExpense)}
            >
              <table>
                <tbody>
                  <CellRow usageGroup={BuildingUse.Multifamily}>
                    <ConfigurationHandler config={config.multifamilyYearOneOperatingExpense} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Retail}>
                    <ConfigurationHandler config={config.retailYearOneOperatingExpense} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Office}>
                    <ConfigurationHandler config={config.officeYearOneOperatingExpense} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Industrial}>
                    <ConfigurationHandler config={config.industrialYearOneOperatingExpense} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualRoomOperatingExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Hotel}>
                    <ConfigurationHandler config={config.hotelAnnualOtherOperatingExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                </tbody>
              </table>
            </AccordionSection>
            <table className="section-end">
              <tbody>
                <CellRow usageGroup={BuildingUse.IncomeProducing}>
                  <ConfigurationHandler config={config.incomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>
          </>
        }

        <table className="section-end separate">
          <tbody>
            <CellRow usageGroup={BuildingUse.IncomeProducing}>
              <ConfigurationHandler config={config.projectAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
            </CellRow>
          </tbody>
        </table>

        {!projectIsForSale &&
          <table className="section-end separate">
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        }

        {projectIsForSale &&
          <>
            <AccordionSection
              title="Sale"
              isOpen={boeAccordionsAreOpen[Accordions.Sale]}
              toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Sale)}
            >
              <table>
                <tbody>
                  <CellRow usageGroup={BuildingUse.Project}>
                    <ConfigurationHandler config={config.exitCapRate} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.IncomeProducing}>
                    <ConfigurationHandler config={config.incomeProducingUsesSaleValueForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Condo}>
                    <ConfigurationHandler config={config.condoGrossSales} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                </tbody>
              </table>
            </AccordionSection>
            <table className="section-end">
              <tbody>
                <CellRow usageGroup={BuildingUse.Project}>
                  <ConfigurationHandler config={config.totalProjectSaleForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>
          </>
        }

        {projectIsForSale &&
          <>
            <AccordionSection
              title="Profit"
              isOpen={boeAccordionsAreOpen[Accordions.Profit]}
              toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Profit)}
            >
              <table>
                <tbody>
                  <CellRow usageGroup={BuildingUse.Project}>
                    <ConfigurationHandler config={config.projectSaleCommissionPercentage} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow usageGroup={BuildingUse.Project}>
                    <ConfigurationHandler config={config.totalProjectSalesCommissions} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                  <CellRow>
                    <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                  </CellRow>
                </tbody>
              </table>
            </AccordionSection>
            <table className="section-end">
              <tbody>
                <CellRow usageGroup={BuildingUse.Project}>
                  <ConfigurationHandler config={config.totalProjectSaleProfitForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>
          </>
        }

        {projectIsForSale
          ?
          <table className="section-end separate">
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.saleReturnOnInvestmentForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
          :
          <table className="section-end separate">
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BackOfEnvelope);
