const LOAD_START = "development/LOAD_START";
const LOAD_SUCCESS = "development/LOAD_SUCCESS";
const LOAD_FAILURE = "development/LOAD_FAILURE";
const SAVE_START = "development/SAVE_START";
const SAVE_SUCCESS = "development/SAVE_SUCCESS";
const CREATE_START = "development/CREATE_START";
const CREATE_SUCCESS = "development/CREATE_SUCCESS";
const DUPLICATE_START = "development/DUPLICATE_START";
const DUPLICATE_SUCCESS = "development/DUPLICATE_SUCCESS";
const REMOVE_START = "development/REMOVE_START";
const REMOVE_SUCCESS = "development/REMOVE_SUCCESS";
const RE_CALCULATE = "development/RE_CALCULATE";
const SET_NAME = "development/SET_NAME";
const SET_RANGED_INPUT = "development/SET_RANGED_INPUT";
const SET_RANGED_INPUT_MINIMUM = "development/SET_RANGED_INPUT_MINIMUM";
const SET_RANGED_INPUT_MAXIMUM = "development/SET_RANGED_INPUT_MAXIMUM";
const SET_SETBACK_TYPE = "development/SET_SETBACK_TYPE";
const SET_CAMERA = "development/SET_CAMERA";
const TOGGLE_BOOLEAN_INPUT = "development/TOGGLE_BOOLEAN_INPUT";
const SET_UNIT_SYSTEM = "development/SET_UNIT_SYSTEM";
const RESET_STATE_START = "development/RESET_STATE_START";
const RESET_STATE_CONTINUE = "development/RESET_STATE_CONTINUE";
const KEEP_PROJECT = "development/KEEP_PROJECT";
const SWITCH_DEVELOPMENT = "development/SWITCH_DEVELOPMENT";
const ADD_SETBACK_FLOOR = "development/ADD_SETBACK_FLOOR";
const REMOVE_SETBACK_FLOOR = "development/REMOVE_SETBACK_FLOOR";
const RESET_SELECTED_SETBACK_FLOOR = "development/RESET_SELECTED_SETBACK_FLOOR";
const SET_PROJECT_IS_SHARED = "development/SET_PROJECT_IS_SHARED";
const SET_PROJECT_IS_READY = "development/SET_PROJECT_IS_READY";
const SET_PROJECT_IS_FOR_SALE = "development/SET_PROJECT_IS_FOR_SALE";
const SET_FLOOR_PLAN = "development/SET_FLOOR_PLAN";
const SET_CUSTOM_SLIDER_NAMES = "development/SET_CUSTOM_SLIDER_NAMES";

export default {
  LOAD_START,
  LOAD_SUCCESS,
  LOAD_FAILURE,
  SAVE_START,
  SAVE_SUCCESS,
  CREATE_START,
  CREATE_SUCCESS,
  DUPLICATE_START,
  DUPLICATE_SUCCESS,
  REMOVE_START,
  REMOVE_SUCCESS,
  RE_CALCULATE,
  SET_NAME,
  SET_RANGED_INPUT,
  SET_RANGED_INPUT_MINIMUM,
  SET_RANGED_INPUT_MAXIMUM,
  SET_SETBACK_TYPE,
  TOGGLE_BOOLEAN_INPUT,
  SET_CAMERA,
  SET_UNIT_SYSTEM,
  RESET_STATE_START,
  RESET_STATE_CONTINUE,
  KEEP_PROJECT,
  SWITCH_DEVELOPMENT,
  ADD_SETBACK_FLOOR,
  REMOVE_SETBACK_FLOOR,
  RESET_SELECTED_SETBACK_FLOOR,
  SET_PROJECT_IS_SHARED,
  SET_PROJECT_IS_READY,
  SET_PROJECT_IS_FOR_SALE,
  SET_FLOOR_PLAN,
  SET_CUSTOM_SLIDER_NAMES,
};
