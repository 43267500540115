import React from "react";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";

const TITLE_HEIGHT = 11;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    left: 0,
    height: 25,
    paddingBottom: 0,
    borderBottom: 0,
  },
  titleIcon: {
    position: "absolute",
    height: 11,
    left: 0,
  },
  titleText: {
    position: "absolute",
    top: 1,
    left: 10,
    fontFamily: "Roboto Bold",
    fontSize: 9,
    letterSpacing: 0.25,
    color: "#333333",
  },
});

const SectionTitle = (props) => (
  <View
    style={[
      styles.container,
      {
        borderBottom: props.borderBottom,
        borderColor: props.borderColor,
        paddingBottom: props.paddingBottom,
        height: TITLE_HEIGHT + (props.paddingBottom || 0),
      },
    ]}
  >
    <Image style={styles.titleIcon} src={props.titleIcon} />
    <Text style={styles.titleText}> {props.title} </Text>
  </View>
);

export default SectionTitle;
