const LOAD_START = "subscription/LOAD_START";
const LOAD_SUCCESS = "subscription/LOAD_SUCCESS";
const LOAD_CREDIT_CARD_START = "subscription/LOAD_CREDIT_CARD_START";
const LOAD_CREDIT_CARD_SUCCESS = "subscription/LOAD_CREDIT_CARD_SUCCESS";
const SET_PLAN_PRICES = "subscription/SET_PLAN_PRICES";
const CREATE_START = "subscription/CREATE_START";
const CREATE_SUCCESS = "subscription/CREATE_SUCCESS";
const CANCEL_START = "subscription/CANCEL_START";
const CANCEL_SUCCESS = "subscription/CANCEL_SUCCESS";
const UPDATE_CREDIT_CARD = "subscription/UPDATE_CREDIT_CARD";
const PAYMENT_PROCESS_START = "subscription/PAYMENT_PROCESS_START";
const PAYMENT_PROCESS_SUCCESS = "subscription/PAYMENT_PROCESS_SUCCESS";
const SET_PAYMENT_ERROR = "subscription/SET_PAYMENT_ERROR";
const RESET_ERROR_FLAGS = "subscription/RESET_ERROR_FLAGS";

export default {
  LOAD_START,
  LOAD_SUCCESS,
  CREATE_START,
  CREATE_SUCCESS,
  CANCEL_START,
  CANCEL_SUCCESS,
  LOAD_CREDIT_CARD_START,
  LOAD_CREDIT_CARD_SUCCESS,
  SET_PLAN_PRICES,
  UPDATE_CREDIT_CARD,
  PAYMENT_PROCESS_START,
  PAYMENT_PROCESS_SUCCESS,
  SET_PAYMENT_ERROR,
  RESET_ERROR_FLAGS,
};
