import * as firebase from "firebase/app";

/**
 * Delete the `fullName`, `displayName` and `industry` fields.
 */
const deleteUnusedFields = (user) => {
  user.fullName = firebase.firestore.FieldValue.delete();
  user.displayName = firebase.firestore.FieldValue.delete();
  user.industry = firebase.firestore.FieldValue.delete();
}

export default deleteUnusedFields;
