/**
 * Add `exitCapRate`, `totalProjectSalesCommissions` and `projectSaleCommissionPercentage`
 * to the values sub-object.
 *
 * Remove 'Condo' sales commission and percentage values.
 */
const updateSalesValues = (development) => {
  development.values.exitCapRate = 0.065;
  development.constraints.maximums.exitCapRate = 0.25;
  development.constraints.minimums.exitCapRate = 0;
  development.constraints.increments.exitCapRate = 0.005;

  development.values.projectSaleCommissionPercentage =
      development.values.condoSaleCommissionPercentage || 0.06;

  development.constraints.maximums.projectSaleCommissionPercentage =
      development.constraints.maximums.condoSaleCommissionPercentage || 0.1;

  development.constraints.minimums.projectSaleCommissionPercentage =
      development.constraints.minimums.condoSaleCommissionPercentage || 0;

  development.constraints.increments.projectSaleCommissionPercentage =
      development.constraints.increments.condoSaleCommissionPercentage  || 0.005;

  // Firestore sentinel values are not needed for deletion because this is a nested object.
  delete development.values.condoTotalSalesCommissions;
  delete development.values.condoSaleCommissionPercentage;
  delete development.constraints.maximums.condoSaleCommissionPercentage;
  delete development.constraints.minimums.condoSaleCommissionPercentage;
  delete development.constraints.increments.condoSaleCommissionPercentage;
}

export default updateSalesValues;
