import React from "react"

interface OwnProps {
  text: string;
}

export class DemoMapLayerButton extends React.PureComponent<OwnProps, {}> {
  render() {
    const { text } = this.props;

    return (
          <div className={`component--demo-layer-button`}>
            <div className="text">{text}</div>
          </div>
        )}
  }

export default DemoMapLayerButton;
