import React from "react";

interface OwnProps {
  title: string;
  onStateChange?(isActive: boolean): void;
  isActive?: boolean;
  hideBottomDivisor?: boolean;
  hideControls?: boolean;
  classes?: string;
}

type Props = OwnProps;

interface State {
  isActive: boolean;
}

class WithPanelHeader extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let isActive = true;
    if (this.isActiveComesFromProps()) isActive = Boolean(props.isActive);

    this.state = {
      isActive: isActive,
    };
  }

  /**
   * Check if isActive flag comes from props.
   */
  isActiveComesFromProps = () => {
    return typeof this.props.isActive !== "undefined";
  };

  /**
   * Update isActive state.
   */
  componentDidUpdate() {
    if (this.isActiveComesFromProps() && this.state.isActive !== this.props.isActive) {
      this.setState({ isActive: Boolean(this.props.isActive) });
    }
  }

  /**
   * Toggle isActive flag.
   */
  onClickHandler = () => {
    if (this.props.hideControls) return;
    const nextIsActive = !this.state.isActive;
    if (!this.isActiveComesFromProps()) {
      this.setState({ isActive: nextIsActive });
    }

    if (this.props.onStateChange) this.props.onStateChange(nextIsActive);
  };

  render() {
    const { title, children, hideBottomDivisor, hideControls, classes } = this.props;
    const { isActive } = this.state;

    return (
      <div className={`component--with-panel-header ${classes ? classes : ""}`}>
        <div className={`with-panel-header ${isActive ? "active" : ""}`} onClick={this.onClickHandler}>
          {!hideControls && (
            <span className={`expand-icon ${isActive ? "active" : ""}`} onClick={this.onClickHandler} />
          )}
          {title}
        </div>
        {isActive && children}
        {!hideBottomDivisor && isActive && <div className="bottom-container" />}
      </div>
    );
  }
}

export default WithPanelHeader;
