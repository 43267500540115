import React from "react";
import { LayerDisclaimerObject } from "types/LayerDisclaimer";

interface OwnProps {
  disclaimer: LayerDisclaimerObject;
}

class LayerDisclaimer extends React.PureComponent<OwnProps, {}> {

  render() {
    const { disclaimer } = this.props;
    return (
      <div className="layer-disclaimer-component">
        <label className="text-label">{disclaimer.disclaimerText}</label>
      </div>
    )
  }
}

export default LayerDisclaimer;