import { Config } from "../../../../types/Config";
import { VariableId } from "../../../../types/VariableId";
import Format from "../../../../types/Format";
import Unit from "../../../../types/Unit";
import valueGetters from "../../../../state/development/utils/valueGetters";

const config: Config = {
  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Size",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },
  [VariableId.ExistingBuildingHeight]: {
    variableId: VariableId.ExistingBuildingHeight,
    text: "Existing Building Height",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " FT" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " M" },
    },
    getter: valueGetters.forExistingBuildingHeightInPropertyInfo,
  },
  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Structure Data",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
    getter: valueGetters.forExistingBuildingAreaInPropertyInfo,
  },
  [VariableId.ExistingStructureAreaOpenSource]: {
    variableId: VariableId.ExistingStructureAreaOpenSource,
    text: "Structure Area (Open Source)",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
    getter: valueGetters.forExistingBuildingAreaOpenSourceInPropertyInfo,
  },
};

export default config;
