import actionTypes from "./actionTypes";
import subscriptionActionTypes from "../subscription/actionTypes";
import { developmentActionTypes } from "../development";
import { UserDocument } from "../../types/UserDocument";

export interface UserState {
  isReady: boolean;
  userDocument?: UserDocument;
  imageUrl?: string;
  location?: [number, number] | null;
}

const INITIAL_STATE: UserState = {
  isReady: false,
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: UserState = INITIAL_STATE, action) => {
  switch (action.type) {
    case developmentActionTypes.LOAD_SUCCESS:
    case developmentActionTypes.SAVE_SUCCESS:
    case developmentActionTypes.CREATE_SUCCESS:
    case developmentActionTypes.DUPLICATE_SUCCESS:
    case developmentActionTypes.REMOVE_SUCCESS:
    case actionTypes.CREATE_SUCCESS:
    case actionTypes.UPDATE_SUCCESS:
    case actionTypes.LOAD_SUCCESS: return saveUserDocument(previousState, action.payload);
    case actionTypes.GET_LOCATION_SUCCESS: return getLocationSuccess(previousState, action.payload);
    case actionTypes.GET_LOCATION_ERROR: return getLocationError(previousState, action.payload);
    case subscriptionActionTypes.CREATE_SUCCESS: return updateChargebeeCustomerId(previousState, action.payload);
    case actionTypes.SET_IS_ONBOARDED: return setUserIsOnboarded(previousState, action.payload);
    case actionTypes.UPLOAD_IMAGE_SUCCESS: return setImageUrl(previousState, action.payload);
    default: return previousState
  };
}

/**
 * Save the user document.
 */
const saveUserDocument = (previousState: UserState, payload): UserState => {
  return {
    ...previousState,
    userDocument: payload.userDocument,
    isReady: true,
    imageUrl: payload.imageUrl || previousState.imageUrl,
  };
}

/**
 * See `getLocationSuccess` action creator.
 *
 * Set user's location.
 */
const getLocationSuccess = (previousState: UserState, payload): UserState => {
  let location = payload.location.some((coordinate) => !Number.isFinite(coordinate)) ? null : payload.location;

  return {
    ...previousState,
    location: location,
  };
}

/**
 * See `getLocationError` action creator.
 *
 * Set user's location to null.
 */
const getLocationError = (previousState: UserState, payload): UserState => {
  return {
    ...previousState,
    location: null,
  };
}

/**
 * See `updateChargebeeCustomerId` action creator.
 *
 * Update the user's Chargebee customer id.
 */
const updateChargebeeCustomerId = (previousState: UserState, payload): UserState => {
  if (!previousState.userDocument) return previousState;

  return {
    ...previousState,
    userDocument: {
      ...previousState.userDocument,
      chargebeeCustomerId: payload.customer.id,
    },
  };
}

/**
 * See `setUserIsOnboarded` action creator.
 *
 * Set user's isOnboarded flag to true.
 */
const setUserIsOnboarded = (previousState: UserState, payload): UserState => {
  if (!previousState.userDocument) return previousState;

  return {
    ...previousState,
    userDocument: {
      ...previousState.userDocument,
      isOnboarded: true,
    },
  };
}

/**
 * Set user's image url.
 */
const setImageUrl = (previousState, payload): UserState => {
  return {
    ...previousState,
    imageUrl: payload.imageUrl,
  }
}

export default reducer;
