import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { subscriptionSelectors } from "../../../../state/subscription";
import { explorerActions, explorerSelectors } from "../../../../state/ui/explorer";
import { mapsSelectors } from "../../../../state/ui/shared/maps";
import { Tier } from "../../../../types/Tier";
import layerHelper from "../../../../utils/mapbox/layerHelper";
import MapLegendPanel from "../../../sharedComponents/MapLegendPanel";
import ParcelInformationTable from "../../../sharedComponents/ParcelInformationTable";
import Tabs from "../../../sharedComponents/Tabs";
import Tab from "../../../sharedComponents/Tabs/Tab";
import TabContainer from "../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../sharedComponents/Tabs/TabPanel";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import { ParentPage } from "views/sharedComponents/ParcelInformationTable/types";

const mapStateToProps = (state) => {
  const development = developmentSelectors.getDevelopment(state);
  return {
    parcel: developmentAccessors.getParcel(development),
    propertyInfoPanelIsOpen: explorerSelectors.getPropertyInfoPanelIsOpen(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setPropertyInfoPanelIsOpen: explorerActions.setPropertyInfoPanelIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const TAB_CONFIG = {
  propertyData: {
    label: "Property Data",
    id: "propertyData",
  },
  mapLegend: {
    label: "Map Legend",
    id: "mapLegend",
  },
};

class PropertyInfoPanel extends React.Component<Props, {}> {
  render() {
    const { tier, parcel, propertyInfoPanelIsOpen, setPropertyInfoPanelIsOpen, layerConfigurations } = this.props;
    const tierIsFree = tier === Tier.Free;
    const tierIsDeveloper = tier === Tier.Developer;
    const tierIsDemo = tier === Tier.None;
    const displayLegendPanel = !tierIsFree && layerHelper.shouldDisplayLegend(layerConfigurations);

    return (
      <WithPanelHeader
        title="Property Info"
        isActive={propertyInfoPanelIsOpen}
        onStateChange={setPropertyInfoPanelIsOpen}
      >
        <div className="component--property-info-panel">
          <Tabs defaultTab={TAB_CONFIG.propertyData.id}>
            {(onTabClick, activeTab) => {
              activeTab =
                displayLegendPanel || activeTab !== TAB_CONFIG.mapLegend.id ? activeTab : TAB_CONFIG.propertyData.id;
              return (
                <>
                  <TabContainer>
                    <Tab
                      label={TAB_CONFIG.propertyData.label}
                      tabId={TAB_CONFIG.propertyData.id}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                    />
                    {!tierIsDeveloper && displayLegendPanel && !tierIsDemo && (
                      <Tab
                        label={TAB_CONFIG.mapLegend.label}
                        tabId={TAB_CONFIG.mapLegend.id}
                        onTabClick={onTabClick}
                        activeTab={activeTab}
                      />
                    )}
                  </TabContainer>
                  <TabPanel tabId={TAB_CONFIG.propertyData.id} activeTab={activeTab}>
                    <ParcelInformationTable parcelFeature={parcel} parentPage={ParentPage.Explorer} />
                  </TabPanel>
                  {!tierIsDeveloper && (
                    <TabPanel tabId={TAB_CONFIG.mapLegend.id} activeTab={activeTab}>
                      <MapLegendPanel />
                    </TabPanel>
                  )}
                </>
              );
            }}
          </Tabs>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInfoPanel);
