import { useEffect } from "react";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { getCameraFromUrl } from "../Helpers/NewprojectMapHelpers";
import { useLocation } from "react-router-dom";
import {
  SetDisplayPinActionFunction,
  SetDrawnParcelsFunction,
  SetQueryViewportFunction,
  SetSmartSearchIsOpenFunction,
} from "state/newDevelopment/types";
import { DynamicLayerConfigurationsLayerIds, LayerConfigurationsLayerIds } from "utils/mapbox/layerHelper";
import { DynamicLayerConfiguration, LayerConfiguration } from "state/ui/shared/maps/reducers";
import { Map, Point } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Props } from "../NewProjectMap";

type LayerConfigurationProp = {
  [key in LayerConfigurationsLayerIds]: LayerConfiguration;
};

type DynamicLayerConfigurationProp = {
  [key in DynamicLayerConfigurationsLayerIds]: DynamicLayerConfiguration;
};

const CAMERA_SELECT_PARCEL_ZOOM: number = 16.1;

export const useNewProjectMapConstructor = (
  cameraString: string | undefined,
  userLocation: [number, number] | null | undefined,
  setCameraCenter: React.Dispatch<React.SetStateAction<[number, number]>>,
  setCameraZoom: React.Dispatch<React.SetStateAction<[number]>>,
  setQueryViewport: SetQueryViewportFunction,
  setSmartSearchIsOpen: SetSmartSearchIsOpenFunction,
  setDisplayPin: SetDisplayPinActionFunction,
  configurationsLayerIds: React.MutableRefObject<MapStyleProperties.LayerId[]>,
  layerConfigurations: LayerConfigurationProp,
  configurationsDynamicLayerIds: React.MutableRefObject<MapStyleProperties.LayerId[]>,
  dynamicLayerConfigurations: DynamicLayerConfigurationProp,
  setDrawnParcels: SetDrawnParcelsFunction,
  map: React.MutableRefObject<Map | undefined>,
  mapboxDraw: React.MutableRefObject<MapboxDraw | null>,
  setModeToDrawingOnKeyDown: React.MutableRefObject<boolean>,
  contextMenuClickPoint: React.MutableRefObject<[number, number] | null>,
  parcelIdBeingEdited: React.MutableRefObject<string | null>,
  parcelPointToBeSelected: React.MutableRefObject<Point | null>,
  prevProps: React.MutableRefObject<Props | undefined>
) => {
  const location = useLocation();
  useEffect(() => {
    let cameraCenter = MapStyleProperties.camera.center;
    let cameraZoom = MapStyleProperties.camera.zoom;

    const cameraFromUrl = getCameraFromUrl(cameraString);

    if (cameraFromUrl) {
      cameraCenter = cameraFromUrl.center;
      cameraZoom = cameraFromUrl.zoom;
    } else if (location.state && location.state.pinPosition) {
      cameraCenter = location.state.pinPosition;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    } else if (userLocation) {
      cameraCenter = userLocation;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    }

    setCameraCenter(cameraCenter);
    setCameraZoom([cameraZoom]);

    if (Boolean(location.state && location.state.smartSearchIsOpen)) {
      setQueryViewport(true);
    }

    if (location.state && location.state.smartSearchIsOpen) {
      setSmartSearchIsOpen(true);
    }
    if (location.state && location.state.displayPin !== undefined) {
      setDisplayPin(location.state.displayPin);
    }
    configurationsLayerIds.current = Object.keys(layerConfigurations) as Array<LayerConfigurationsLayerIds>;
    configurationsDynamicLayerIds.current = Object.keys(
      dynamicLayerConfigurations
    ) as Array<DynamicLayerConfigurationsLayerIds>;

    return () => {
      setCameraCenter(MapStyleProperties.camera.center);
      setCameraZoom([MapStyleProperties.camera.zoom]);
      setDrawnParcels([]);

      map.current = undefined;
      mapboxDraw.current = null;
      setModeToDrawingOnKeyDown.current = false;
      contextMenuClickPoint.current = null;
      parcelIdBeingEdited.current = null;
      parcelPointToBeSelected.current = null;
      configurationsLayerIds.current = [];
      configurationsDynamicLayerIds.current = [];
      prevProps.current = undefined;
    };
  }, []);
};
