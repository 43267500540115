import developmentMigrations from "./developments";
import userMigrations from "./users";
import Firebase from "../../../../types/Service/Firebase";

export type Migration = (data: firebase.firestore.DocumentData) => void;

/**
 * Return the correct array of Migrations depending on the given collection.
 *
 * If the given collection does not have any migrations, the empty array is
 * returned.
 */
const migrationsFor = (collection: Firebase.Collection): Array<Migration> => {
  switch (collection) {
    case Firebase.Collection.Developments:  return developmentMigrations;
    case Firebase.Collection.Users:         return userMigrations;
    default:                                return [];
  }
}

/**
 * Apply the proper migrations depending on the collection the document is in.
 */
const migrateData = (data: firebase.firestore.DocumentData, collection: Firebase.Collection) => {
  let schemaVersion = data.schemaVersion || 0;

  migrationsFor(collection)
    .slice(schemaVersion)
    .forEach((migration) => migration(data));

  versionData(data, collection);
}

/**
 * Apply the proper versioning depending on the collection the document is in.
 */
const versionData = (data: firebase.firestore.DocumentData, collection: Firebase.Collection) => {
  data.schemaVersion = migrationsFor(collection).length;
}

/**
 * Determine if a given data from a specific collection needs to be migrated.
 *
 * If there are no migrations defined for the given collection, returns `false`.
 * This is important to maintain the "opt-in" behavior of migrations, and
 * helps prevent migrations from running on documents needlessly or improperly.
 */
const requiresMigration = (data: firebase.firestore.DocumentData, collection: Firebase.Collection) => {
  return (
    migrationsFor(collection).length > 0 && (
      data.schemaVersion === undefined ||
      data.schemaVersion < migrationsFor(collection).length
    )
  );
}

export default {
  migrateData,
  versionData,
  requiresMigration
};
