import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";
import { SalesUseType } from "./utils/salesModel";

/**
 * Generate the condo buildables presets. Only one bedroom units will be initialized to non zero values.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.CondoLossFactor) as number;
  const unitArea = developmentAccessors.getInputValue(development, VariableId.CondoTwoBedArea) as number;

  const microUnitBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoMicrounitArea) as number;
  const studioBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoStudioArea) as number;
  const oneBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoOneBedArea) as number;
  const twoBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoTwoBedArea) as number;
  const threeBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoThreeBedArea) as number;

  const usableArea = (targetArea * (1 - lossFactor));
  let amenitiesArea = usableArea * 0.05;

  // Guard against amenities consuming all of the usable area.
  if (usableArea - amenitiesArea < unitArea) amenitiesArea = 0;

  const areaAvailableForUnits = usableArea ? usableArea - amenitiesArea : 0;
  const unitQuantity = unitArea ? Math.floor(areaAvailableForUnits / unitArea) : 0;
  const leftoverArea = areaAvailableForUnits - (unitQuantity * unitArea);
  amenitiesArea += leftoverArea;

  const previousMaximumUnits = developmentAccessors.getInputMaximum(development,  VariableId.CondoOneBedQuantityToggleable);
  const previousMaximumAmenities = developmentAccessors.getInputMaximum(development,  VariableId.CondoAmenitiesAreaToggleable);

  return {
    [VariableId.CondoOneBedQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoMicrounitQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoStudioQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoTwoBedQuantityToggleable]: {
      "minimum":  0,
      "value":    unitQuantity,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoThreeBedQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoAmenitiesAreaToggleable]: {
      "minimum":  0,
      "value": amenitiesArea,
      "maximum": 10 * amenitiesArea || previousMaximumAmenities
    },
    [VariableId.CondoSalePricePerMicrounit]: await generatorFromModel.generateSalesPreset(development, VariableId.CondoSalePricePerMicrounit, SalesUseType.MicroUnit, microUnitBedroomArea),
    [VariableId.CondoSalePricePerStudio]: await generatorFromModel.generateSalesPreset(development, VariableId.CondoSalePricePerStudio, SalesUseType.Studio, studioBedroomArea),
    [VariableId.CondoSalePricePerOneBed]: await generatorFromModel.generateSalesPreset(development, VariableId.CondoSalePricePerOneBed, SalesUseType.OneBedroom, oneBedroomArea),
    [VariableId.CondoSalePricePerTwoBed]: await generatorFromModel.generateSalesPreset(development, VariableId.CondoSalePricePerTwoBed, SalesUseType.ThreeBedroom, twoBedroomArea),
    [VariableId.CondoSalePricePerThreeBed]: await generatorFromModel.generateSalesPreset(development, VariableId.CondoSalePricePerThreeBed, SalesUseType.ThreeBedroom, threeBedroomArea),
    [VariableId.CondoHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.CondoHardCostPerArea, BuildingUse.Condo),
  };
}

export default generatePresets;
