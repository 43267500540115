import * as firebase from "firebase/app";
import "firebase/functions";

/**
 * Send another user a subscription invitation.
 */
const invite = async (userEmail, userName, discountCode, ownerUserId, planId): Promise<any> => {
  const callable = firebase.functions().httpsCallable("subscriptionInvitation-invite");
  const result = await callable({ userEmail, userName, discountCode, ownerUserId, planId });
  return result;
}

export default {
  invite,
}
