import React from "react";
import { connect } from "react-redux";
import getFormattedValuesForPresentation from "utils/parcel/parcelDataPresentationHelper";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import Cell from "../../../Cell";
import CellRow from "../../../CellRow";
import CellText from "../../../CellText";

type OwnProps = {
  parcelFeature: any;
}

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render unit density ratio section.
 */
const UnitDensityRatio = (props: Props) => {
  const { parcelFeature, tier } = props;
  const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);
  const livingDensityRatio = parcelAccessors.getLivingDensityRatio(parcelFeature);
  const hotelDensityRatio = parcelAccessors.getHotelDensityRatio(parcelFeature);
  const hiddenMap: Format.FormattingMap = (value) => ({ value, formatOptions: { hidden: !userHasAccess } });

  if (livingDensityRatio.length === 0 && hotelDensityRatio.length === 0) return null;

  const formattedLivingDensityRatio = getFormattedValuesForPresentation(livingDensityRatio);
  const formattedHotelDensityRatio = getFormattedValuesForPresentation(hotelDensityRatio);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}> Unit Density</th>
        </tr>
      </thead>
      <tbody>
        {livingDensityRatio.length > 0
          ? <CellRow>
              <CellText text="Residential" hiddenText={!userHasAccess} />
              <Cell getter={valueGetters.generic} value={formattedLivingDensityRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
            </CellRow>
          : null
        }
        {hotelDensityRatio.length > 0
          ? <CellRow>
              <CellText text="Hotel" hiddenText={!userHasAccess} />
              <Cell getter={valueGetters.generic} value={formattedHotelDensityRatio} styleVariation="bold capitalize" formattingMap={hiddenMap} />
            </CellRow>
          : null
        }
      </tbody>
    </table>
  );
}

export default connect(
  mapStateToProps
)(UnitDensityRatio);
