import React from "react";
import { connect } from "react-redux";
import CopyProjectPopup from "./CopyProjectPopup";
import { explorerActions, explorerSelectors } from "../../../state/ui/explorer";
import authentication from "../../../utils/authentication";
import UnauthenticatedCopyProjectPopup from "./UnauthenticatedCopyProjectPopup";

const mapStateToProps = (state) => {
  return {
    copyProjectPopupIsOpen: explorerSelectors.getCopyProjectPopupIsOpen(state),
  }
}

const mapDispatchToProps = {
  toggleCopyProjectPopup: explorerActions.toggleCopyProjectPopup
}

interface OwnProps {
  classes?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class CopyProject extends React.PureComponent<Props, {}> {
  render() {
    const { toggleCopyProjectPopup, copyProjectPopupIsOpen, classes } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <>
        <div className={`component--copy-project ${classes || ""}`} onClick={toggleCopyProjectPopup}>
          <div className="duplicate-project" />
          <div className="text">Copy Project</div>
        </div>
        {copyProjectPopupIsOpen
            ? userIsAuthenticated
                ? <CopyProjectPopup onStateChange={toggleCopyProjectPopup} />
                : <UnauthenticatedCopyProjectPopup onStateChange={toggleCopyProjectPopup} />
            : null
        }
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CopyProject)
