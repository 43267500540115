import React from "react";
import SiteAnalysisButton from "../../sharedComponents/SiteAnalysisButton";

class SiteAnalysisLink extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="application-link">
        <div className="site-analysis-icon" />
        <h5>Site Selection</h5>
        <p>Source neighborhoods using data map layers, historical graphs, and parcel search engine.</p>
        <SiteAnalysisButton text="SEARCH ENGINE"/>
      </div>
    )
  }
}

export default SiteAnalysisLink;
