import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import analytics from "../../../../utils/analytics";
import authentication, {
  AuthenticationProviderId,
} from "../../../../utils/authentication";
import { Path } from "../../../../types/Path";
import FederatedAuthenticationProviders from "../../FederatedAuthenticationProviders";
import companyInfo from "../../../../utils/companyInfo";
import Authentication from "../../../../types/Authentication";
import AuthenticationError from "../../../../errors/AuthenticationError";
import SignUpLink from "./SignUpLink";

interface State {
  email: string;
  password: string;
  errorMessage: string;
  termsOfServiceAccepted: boolean;
}

interface OwnProps {
  closePopup(): void;
  onLogIn?(): void;
}

type Props = OwnProps;

const LogIn: React.FC<Props> = ({ closePopup, onLogIn }) => {
  const [state, setState] = useState<State>({
    email: "",
    password: "",
    errorMessage: "",
    termsOfServiceAccepted: false,
  });

  const emailInput = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const renderSignUpButton = !location.pathname.includes(Path.Subscribe);

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
    }
  }, []);

  /**
   * Throws error when the form required fields are not all filled or the terms of service is not checked.
   */
  const verifyRequiredFields = (state) => {
    let errorCode;
    if (!state.email || !state.password) {
      errorCode = Authentication.CustomErrorCode.MissingFields;
    } else if (!state.termsOfServiceAccepted) {
      errorCode = Authentication.CustomErrorCode.TermsOfServiceNotAccepted;
    }

    if (errorCode) throw new AuthenticationError(errorCode);
  };

  /**
   * Update the error message to the given value.
   */
  const setErrorMessage = (errorMessage: string) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: errorMessage,
    }));
  };

  /**
   * Updates the state based on whether the user was able to sign in or not.
   */
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      verifyRequiredFields(state);

      await authentication.signInWithEmailAndPassword(
        state.email,
        state.password
      );
      analytics.trackLogIn(AuthenticationProviderId.EmailAndPassword);
      if (onLogIn) onLogIn();
      closePopup();
    } catch (error) {
      const errorObject: any = error;
      setErrorMessage(authentication.getErrorMessage(errorObject.code));
      analytics.trackAuthenticationError(
        errorObject,
        AuthenticationProviderId.EmailAndPassword,
        "Log In"
      );
    }
  };

  /**
   * Updates the state when the email changes.
   */
  const handleEmailChange = (event) => {
    if (event && event.target) {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        email: event.target.value,
      }));
    }
  };

  /**
   * Updates the state when the password changes.
   */
  const handlePasswordChange = (event) => {
    if (event && event.target) {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        password: event.target.value,
      }));
    }
  };
  /**
   * Updates the state when the user clicks the terms of service checkbox.
   */
  const handleTermsOfServiceAcceptedChange = () => {
    setState((prevState) => ({
      ...prevState,
      termsOfServiceAccepted: !state.termsOfServiceAccepted,
    }));
  };

  return (
    <div className="component--log-in">
      <div className="header">Welcome Back</div>
      <form onSubmit={handleFormSubmit}>
        <div className="input-section">
          <div className="input-field">
            <input
              type="email"
              name="email"
              placeholder="Email address"
              autoComplete="username"
              ref={emailInput}
              onChange={handleEmailChange}
            />
          </div>
        </div>

        <div className="input-section">
          <div className="input-field">
            <input
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
          </div>
        </div>

        <div className="error-container">
          <p>{state.errorMessage}</p>
        </div>

        <div className="tos-agreement">
          <input
            type="checkbox"
            className="tos"
            name="tos"
            onChange={handleTermsOfServiceAcceptedChange}
          />
          <div className="text-box">
            I agree to the{" "}
            <a
              href={companyInfo.TERMS_OF_SERVICE_URL}
              className="underlined-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
          <Link className="forgot-password" to={Path.ForgotPassword}>
            Forgot Password
          </Link>
        </div>
        <button type="submit" className="submit">
          Log In
        </button>
      </form>

      {renderSignUpButton && <SignUpLink linkLabel="Create New Account" />}

      <FederatedAuthenticationProviders
        onError={setErrorMessage}
        termsOfServiceAccepted={state.termsOfServiceAccepted}
      />
    </div>
  );
};

export default LogIn;
