import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class WatershedsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Watersheds}
        classes="watersheds"
        legendRows={[
          <LegendRow
            color="#0c91ea"
            text="Watershed"
          />
        ]}
      />
    );
  }
}

export default WatershedsLegend;
