import React from "react";
import { Path } from "../../../../types/Path";
import RedirectButton from "../../RedirectButton";
import wrapComponentWithPopup from "../../wrapComponentWithPopup";
import { PopupProps } from "../../wrapComponentWithPopup/wrapComponentWithPopup";

interface OwnProps {
  closePopup(): void;
}

type Props = OwnProps & PopupProps;

class UnauthenticatedCopyProjectPopup extends React.PureComponent<Props, {}> {
  render() {
    const { closePopup } = this.props;

    return (
      <div className="component--unauthenticated-copy-project-popup">
        <div className="top">
          <div className="message">The copy feature requires that you create an account, in order to save the current project.</div>
        </div>
        <div className="bottom">
          <button className="cancel" onClick={closePopup}>
            Cancel
          </button>
          <RedirectButton path={Path.Subscribe} text="Create Account" classes="create-account" />
        </div>
      </div>
    );
  }
}

export default wrapComponentWithPopup(UnauthenticatedCopyProjectPopup);
