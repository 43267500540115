import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class BusinessesLegend extends React.PureComponent<{}, {}> {

  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Businesses}
        classes="businesses"
        legendRows={[
          <LegendRow
            color="#ed7d4b"
            text="Local Businesses"
            circle
          />,
        ]}
      />
    );
  }
}

export default BusinessesLegend;
