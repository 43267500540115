import { Config } from "../../../../../../types/Config";
import { VariableId } from "../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";

const config: Config = {
  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Unit Mix",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyMicrounitQuantityToggleable]: {
    variableId: VariableId.MultifamilyMicrounitQuantityToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "Micro-Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyStudioQuantityToggleable]: {
    variableId: VariableId.MultifamilyStudioQuantityToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "Studios",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyOneBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyOneBedQuantityToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "One Bedrooms",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyTwoBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyTwoBedQuantityToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "Two Bedrooms",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyThreeBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyThreeBedQuantityToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "Three Bedrooms",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyGrossBuildableArea]: {
    variableId: VariableId.MultifamilyGrossBuildableArea,
    text: "Multifamily Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyMicrounitArea]: {
    variableId: VariableId.MultifamilyMicrounitArea,
    buildingUse: BuildingUse.Multifamily,
    text: "Micro-Unit",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyStudioArea]: {
    variableId: VariableId.MultifamilyStudioArea,
    buildingUse: BuildingUse.Multifamily,
    text: "Studio",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyOneBedArea]: {
    variableId: VariableId.MultifamilyOneBedArea,
    buildingUse: BuildingUse.Multifamily,
    text: "One Bedroom",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyTwoBedArea]: {
    variableId: VariableId.MultifamilyTwoBedArea,
    buildingUse: BuildingUse.Multifamily,
    text: "Two Bedroom",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyThreeBedArea]: {
    variableId: VariableId.MultifamilyThreeBedArea,
    buildingUse: BuildingUse.Multifamily,
    text: "Three Bedroom",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyLossFactor]: {
    variableId: VariableId.MultifamilyLossFactor,
    buildingUse: BuildingUse.Multifamily,
    text: "Loss Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.MultifamilyAmenitiesAreaToggleable]: {
    variableId: VariableId.MultifamilyAmenitiesAreaToggleable,
    buildingUse: BuildingUse.Multifamily,
    text: "Amenities",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    text: "Multifamily Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerMicrounit,
    buildingUse: BuildingUse.Multifamily,
    text: "Micro-Unit",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/mo"
    }
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerStudio,
    buildingUse: BuildingUse.Multifamily,
    text: "Studio",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/mo"
    }
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerOneBed,
    buildingUse: BuildingUse.Multifamily,
    text: "One Bedroom",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/mo"
    }
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerTwoBed,
    buildingUse: BuildingUse.Multifamily,
    text: "Two Bedroom",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/mo"
    }
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerThreeBed,
    buildingUse: BuildingUse.Multifamily,
    text: "Three Bedroom",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/mo"
    }
  },

  [VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Multifamily NOI",
    formatOptions: { type: Format.Type.Currency, }
  },

  [VariableId.MultifamilyStabilizedVacancyPercentage]: {
    variableId: VariableId.MultifamilyStabilizedVacancyPercentage,
    buildingUse: BuildingUse.Multifamily,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1
    }
  },

  [VariableId.MultifamilyOperatingExpensePercentage]: {
    variableId: VariableId.MultifamilyOperatingExpensePercentage,
    buildingUse: BuildingUse.Multifamily,
    text: "Opex Percent",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1
    }
  }
};

export default config;
