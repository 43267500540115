import React from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { Path } from "../../../../types/Path";
import { newDevelopmentUiSelectors } from "../../../../state/ui/newDevelopment";

const DemoOnboarding = () => {
  const homeRouteMatch = useMatch({ path: Path.Home, end: true });
  const explorerRouteMatch = useMatch(`${Path.Explorer}/*`);

  const newProjectRouteMatch = useMatch(`${Path.NewProject}/*`);
  const subscribeRouteMatch = useMatch(Path.Subscribe);
  const initialValuesPanelIsOpen = useSelector(
    newDevelopmentUiSelectors.getInitialValuesPanelIsOpen
  );

  const onboardingPageIsActive =
    homeRouteMatch ||
    explorerRouteMatch ||
    newProjectRouteMatch ||
    subscribeRouteMatch;

  if (!onboardingPageIsActive) return null;

  const stepOneIsActive = homeRouteMatch !== null;
  const stepTwoIsActive =
    newProjectRouteMatch !== null && !initialValuesPanelIsOpen;
  const stepThreeIsActive =
    newProjectRouteMatch !== null && initialValuesPanelIsOpen;
  const stepFourIsActive = explorerRouteMatch !== null;
  const stepFiveIsActive = subscribeRouteMatch !== null;

  return (
    <div className="component--demo-onboarding">
      <div className="process">
        <div className={`step-box ${stepOneIsActive && "active"}`}>
          <div className="icon step-one" />
          <p className="label">Select City</p>
        </div>

        <div className={`step-box ${stepTwoIsActive && "active"}`}>
          <div className="icon step-two" />
          <p className="label">Draw Parcel</p>
        </div>

        <div className={`step-box ${stepThreeIsActive && "active"}`}>
          <div className="icon step-three" />
          <p className="label">Select Uses</p>
        </div>

        <div className={`step-box ${stepFourIsActive && "active"}`}>
          <div className="icon step-four" />
          <p className="label">Underwriting</p>
        </div>

        <div className={`step-box ${stepFiveIsActive && "active"}`}>
          <div className="icon step-five" />
          <p className="label">Sign Up</p>
        </div>
      </div>
    </div>
  );
};

export default DemoOnboarding;
