import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../state/newDevelopment";
import MapboxGeocoder from "../../../sharedComponents/MapboxGeocoder";
import UnitSystemInput from "../../../sharedComponents/UnitSystemInput";
import StartProjectButton from "../StartProjectButton";
import StreetViewButton from "../StreetViewButton";

const mapStateToProps = (state) => {
  return {
    newDevelopmentUnitSystem: newDevelopmentSelectors.getUnitSystem(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
  };
};

const mapDispatchToProps = {
  setNewDevelopmentUnitSystem: newDevelopmentActions.setUnitSystem,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class SearchBar extends React.PureComponent<Props, {}> {
  render() {
    const { setNewDevelopmentUnitSystem, selectedFeature, newDevelopmentUnitSystem } = this.props;

    return (
      <div className="component--search-bar">
        <div className="geocoder-container">
          <MapboxGeocoder />
          <UnitSystemInput setUnitSystem={setNewDevelopmentUnitSystem} unitSystem={newDevelopmentUnitSystem} />
        </div>
        {selectedFeature && <StreetViewButton />}
        {selectedFeature && <StartProjectButton />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
