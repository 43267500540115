import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import { allTogglesDisabled } from "../../../../utils/uiToggleHelper";
import PanelSectionTitle from "../../../../sharedComponents/PanelSectionTitle";
import SliderBox from "../../sharedComponents/SliderBox";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state)
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class ProjectCost extends React.Component<Props, {}>  {
  /**
   * Render Project Cost section.
   */
  renderProjectCostSection = () => {
    let anyTogglesEnabled = !allTogglesDisabled(this.props.toggles);

    return (
      <>
        <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.parcelPurchasePrice} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.existingStructureArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.existingStructureDemolitionCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />

          {anyTogglesEnabled && <ConfigurationHandler config={config.softCostPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />}
          {anyTogglesEnabled && <ConfigurationHandler config={config.contingencyCostPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />}
        </div>
      </>
    );
  }

  /**
   * Render Hard Cost section.
   */
  renderHardCostSection = () => {
    if (allTogglesDisabled(this.props.toggles)) return null;

    return (
      <>
        <ConfigurationHandler config={config.projectHardCostPerGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.condoHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.hotelHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.officeHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.retailHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.industrialHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingHardCostPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="panel-content">
        {this.renderProjectCostSection()}
        {this.renderHardCostSection()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(ProjectCost);
