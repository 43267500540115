import sleep from "./sleep";

/**
 * @class A small utility object for running asynchronous tasks one at a time.
 */
class AsynchronousTaskQueue {
  queue: any[];

  constructor() {
    this.queue = [];
  }

  /**
   * Add the given function to the queue and return once it has been run.
   */
  push = async (task) => {
    this.queue.push(task);
    while (this.queue[0] !== task) {
      await sleep(50);
    }
    let result = await task();
    this.queue.shift();
    return result;
  }
}

const taskQueue = new AsynchronousTaskQueue();

export default taskQueue;
