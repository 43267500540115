import actionTypes from "./actionTypes";

export interface AuthenticationState {
  userIsAuthenticated: boolean | null;
}

const INITIAL_STATE: AuthenticationState = {
  userIsAuthenticated: null,
};

const reducer = (previousState = INITIAL_STATE, action): AuthenticationState => {
  switch (action.type) {
    case actionTypes.SIGN_IN_SUCCESS:
      return signInSuccess(previousState, action.payload);
    case actionTypes.SIGN_OUT_SUCCESS:
      return signOutSuccess(previousState, action.payload);
    default:
      return previousState;
  }
};

/**
 * See `signInSuccess` action creator.
 *
 * Set userIsAuthenticated flag.
 */
const signInSuccess = (previousState: AuthenticationState, payload): AuthenticationState => {
  return {
    ...previousState,
    userIsAuthenticated: true,
  };
};

/**
 * See `signOutSuccess` action creator.
 *
 * Set userIsAuthenticated flag.
 */
const signOutSuccess = (previousState: AuthenticationState, payload): AuthenticationState => {
  return {
    ...previousState,
    userIsAuthenticated: false,
  };
};

export default reducer;
