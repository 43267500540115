import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class ParksLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Parks}
        classes="parks"
        legendRows={[
          <LegendRow
            color="#1f53b4"
            text="County Park"
          />,
          <LegendRow
            color="#88ebdc"
            text="Local Park"
          />,
          <LegendRow
            color="#8f11e9"
            text="National Park or Forest"
          />,
          <LegendRow
            color="#d4f517"
            text="Regional Park"
          />,
          <LegendRow
            color="#dc4299"
            text="State Park or Forest"
          />,
          <LegendRow
            color="#ffa200"
            text="Other"
          />,
        ]}
      />
    );
  }
}

export default ParksLegend;
