import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { developmentSelectors } from "state/development";
import { newDevelopmentSelectors } from "state/newDevelopment";
import parcelAccessors from "utils/parcel/parcelAccessors";
import { Path } from "../../../../types/Path";

const mapStateToProps = (state) => ({
  selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
  selectedFeatureMembers: newDevelopmentSelectors.getSelectedFeatureMembers(state),
  parcel: developmentSelectors.getParcel(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const StreetViewButton: React.FC<Props> = ({ selectedFeatureMembers, selectedFeature, parcel }) => {
  const location = useLocation();
  const isExplorerPage = location.pathname.includes(Path.Explorer);

  /**
   * Get the coordinates of the parcel to build the Street View URL
   * In the New Project Page, if there are more than one parcel, we use the first one in the array.
   * Open Street View in a new window.
   */
  const onCLickHandler = useCallback(() => {
    let coordinates;

    if (isExplorerPage) {
      const isAnAssembly = parcelAccessors.getIsAnAssembly(parcel);
      coordinates = isAnAssembly ? parcel.geometry.coordinates[0][0][0] : parcel.geometry.coordinates[0][0];
    } else {
      const numberOfParcels = Object.keys(selectedFeatureMembers).length;
      coordinates =
        numberOfParcels === 1
          ? selectedFeature.geometry.coordinates[0][0]
          : selectedFeature.geometry.coordinates[0][0][0];
    }

    const long = coordinates[0];
    const lat = coordinates[1];
    const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${long}&heading=-45&pitch=10&fov=100`;

    window.open(streetViewURL, "_blank", "width=700, height=700");
  }, [isExplorerPage, parcel, selectedFeatureMembers, selectedFeature]);

  const className = isExplorerPage ? "explorer" : "";

  return (
    <div className={`component--street-view-button ${className}`}>
      <button className={`button ${className}`} onClick={onCLickHandler}>
        Street View
      </button>
    </div>
  );
};

export default connect(mapStateToProps)(StreetViewButton);
