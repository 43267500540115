import React from "react";
import PdfContext from "../../PdfContext";
import StandardPage from "../sharedComponents/StandardPage";
import Header from "../sharedComponents/Header";
import Images from "./Images";
import Summary from "./Summary";
import FromContactDetails from "./FromContactDetails";
import ToContactDetails from "./ToContactDetails";
import { View, StyleSheet } from "@react-pdf/renderer";

const LEFT_PADDING = 41;

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
  },
  leftContainer: {
    height: "100%",
    width: 269,
  },
  rightContainer: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contactsContainer: {
    height: 109,
    paddingLeft: LEFT_PADDING,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderTop: 1,
    borderColor: "#fff",
  },
});

const OverviewPage = () => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <StandardPage backgroundColor="#FFFFFF">
        <View style={styles.content}>
          <View style={styles.leftContainer}>
            <Images />
          </View>

          <View style={styles.rightContainer}>
            <Header leftPadding={LEFT_PADDING} />
            <View style={[styles.contactsContainer, { backgroundColor: colorPalette.primary }]}>
              <FromContactDetails />
              <ToContactDetails />
            </View>
            <Summary />
          </View>
        </View>
      </StandardPage>
    )}
  </PdfContext.Consumer>
);

export default OverviewPage;
