import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Dropdown from "../../../sharedComponents/Dropdown";
import developmentUiHelper from "../../../utils/developmentUiHelper";
import { useDispatch, useSelector } from "react-redux";
import { developmentActions, developmentSelectors } from "../../../../state/development";
import DeleteDevelopment from "../../../sharedComponents/DeleteDevelopment";
import analytics from "../../../../utils/analytics";
import { userSelectors } from "../../../../state/user";
import authentication from "../../../../utils/authentication";
import StreetViewButton from "views/pages/NewProjectPage/StreetViewButton";

interface Development {
  id: string;
  name: string;
}

const DevelopmentsDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const developmentName = useSelector(developmentSelectors.getName);
  const developmentIsEdited = useSelector(developmentSelectors.getIsEdited);
  const developmentsList = useSelector(userSelectors.getDevelopments);

  const [projectsMenuIsActive, setProjectsMenuIsActive] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const prevDevelopmentIsEdited = useRef(developmentIsEdited);

  useEffect(() => {
    if (prevDevelopmentIsEdited.current && !developmentIsEdited) {
      setShowSavedMessage(true);
    } else if (!prevDevelopmentIsEdited.current && developmentIsEdited) {
      setShowSavedMessage(false);
    }

    prevDevelopmentIsEdited.current = developmentIsEdited;
  }, [developmentIsEdited, setShowSavedMessage]);

  const switchProject = async (projectId: string) => {
    const user = authentication.getCurrentUser();
    dispatch(developmentActions.switchDevelopment(user, projectId, developmentIsEdited));
    analytics.trackOpenProject(projectId);
  };

  const handleDeleteDevelopment = () => {
    setProjectsMenuIsActive(false);
  };

  const developmentList: Development[] = developmentsList.filter((development) => development.id !== projectId);

  return (
    <div className="component--developments-dropdown">
      <div className="title-wrapper">
        <div className="title">Current Project</div>
        {showSavedMessage && <span className="saved-message">Saved</span>}
      </div>
      <div className="project-header-container">
        <div className="projects-container">
          <Dropdown
            isActive={projectsMenuIsActive}
            onClick={() => setProjectsMenuIsActive(!projectsMenuIsActive)}
            onBlur={() => setProjectsMenuIsActive(false)}
            toggleButton={
              <div className="project-name-container">
                <span className="project-name"> {developmentUiHelper.getDisplayName(developmentName)} </span>
                {developmentsList.length > 1 && (
                  <div className={`dropdown-icon ${projectsMenuIsActive ? "active" : ""}`} />
                )}
              </div>
            }
          >
            {developmentList.map((development) => (
              <li key={`development_${development.id}`}>
                <div className="button" onClick={() => switchProject(development.id)}>
                  <span className="project-name">{developmentUiHelper.getDisplayName(development.name)}</span>

                  <DeleteDevelopment
                    developmentId={development.id}
                    developmentName={developmentUiHelper.getDisplayName(development.name)}
                    onDeleteDevelopment={handleDeleteDevelopment}
                  />
                </div>
              </li>
            ))}
          </Dropdown>
        </div>
        <div className="street-view-button">
          <StreetViewButton />
        </div>
      </div>
    </div>
  );
};

export default DevelopmentsDropdown;
