import arrayHelper from "utils/arrayHelper";

const DATA_DELIMETER = "@, ";

/**
 *
 * @param values
 * @returns styledArrayOfValues
 */
export const getFormattedValuesForPresentation = (values) => {
  if (!Array.isArray(values)) {
    values = [values];
  }

  return values.flatMap((valuesToFormat, i) => {
    if (typeof valuesToFormat === "number") {
      valuesToFormat = valuesToFormat.toString();
    }
    if (valuesToFormat && valuesToFormat !== "") {
      if (valuesToFormat.indexOf(DATA_DELIMETER) !== -1) {
        let formattedValues: Array<string> = valuesToFormat.split(DATA_DELIMETER);
        formattedValues = arrayHelper.styleValuesForPresentation(formattedValues);
        if (i !== 0) {
          formattedValues.unshift(" ");
        }
        return formattedValues;
      } else {
        if (values.length === 1) {
          return [valuesToFormat]; // wrap in array to ensure flatMap works correctly
        } else {
          if (i !== 0) {
            return [" ", valuesToFormat]; // wrap in array to ensure flatMap works correctly
          } else {
            return [valuesToFormat]; // wrap in array to ensure flatMap works correctly
          }
        }
      }
    }
    return []; // return empty array for flatMap
  });
};

export default getFormattedValuesForPresentation;
