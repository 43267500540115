import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../state/development";
import { setbackModeActions, setbackModeSelectors } from "../../../../state/setbackMode";
import { SetbackType } from "../../../../types/Setback";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";

const SETBACK_LABELS = {
  A: "Setback A - Primary",
  B: "Setback B - Secondary",
  C: "Setback C - Side",
  D: "Setback D - Rear",
}

const mapStateToProps = (state) => {
  const selectedPolygonIndex = setbackModeSelectors.getSelectedPolygonIndex(state);
  const selectedEdgeIndex = setbackModeSelectors.getSelectedEdgeIndex(state);
  const areaLabelVisibility = setbackModeSelectors.getAreaLabelVisibility(state);
  const setbackTypeMarkerVisibility = setbackModeSelectors.getSetbackTypeMarkerVisibility(state);
  let selectedSetbackType;
  if (selectedPolygonIndex >= 0 && selectedEdgeIndex >= 0) {
    selectedSetbackType = developmentSelectors.getSetbackType(state, selectedPolygonIndex, selectedEdgeIndex);
  }

  return {
    selectedSetbackType: selectedSetbackType,
    selectedPolygonIndex: selectedPolygonIndex,
    selectedEdgeIndex: selectedEdgeIndex,
    parcelGeoJson: developmentSelectors.getParcel(state),
    areaLabelVisibility,
    setbackTypeMarkerVisibility,
  };
};

const mapDispatchToProps = {
  setSetbackType: developmentActions.setSetbackType,
  setAreaLabelVisibility: setbackModeActions.setAreaLabelVisibility,
  setSetbackTypeMarkerVisibility: setbackModeActions.setSetbackTypeMarkerVisibility,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class SetbackTools extends React.PureComponent<Props, {}> {
  /**
   * Handle click on the the setback radio buttons.
   */
  handleClick = (event) => {
    let selectedSetbackType = event.target.value;
    this.props.setSetbackType(this.props.selectedPolygonIndex, this.props.selectedEdgeIndex, selectedSetbackType);
  }

  /**
   * Render setback type label.
   */
  renderSetbackLabel = (setbackType: SetbackType) => {
    const { selectedPolygonIndex, selectedEdgeIndex } = this.props;

    return (
      <label key={setbackType} className="setback-row">
        <input
          type="radio"
          value={setbackType}
          checked={this.props.selectedSetbackType === setbackType}
          onClick={this.handleClick}
          disabled={selectedPolygonIndex < 0 || selectedEdgeIndex < 0}
          readOnly
        />
        <span className="text">{SETBACK_LABELS[setbackType]}</span>
      </label>
    );
  }

  /**
   * Render the Select Setback list.
   */
  renderSelectSetbackList = () => {
    const { selectedPolygonIndex, selectedEdgeIndex } = this.props;

    return (
      <div className={`setback-list-container ${selectedPolygonIndex < 0 || selectedEdgeIndex < 0 ? "disabled" : ""}`}>
        <div className="column">
          {this.renderSetbackLabel(SetbackType.A)}
          {this.renderSetbackLabel(SetbackType.B)}
        </div>
        <div className="column">
          {this.renderSetbackLabel(SetbackType.C)}
          {this.renderSetbackLabel(SetbackType.D)}
        </div>
      </div>
    );
  }

  /**
   * Set the Area Label Visibility. This will show/hide the Area Label depending on the previous value.
   */
   setAreaLabelVisibility = () => {
    const { areaLabelVisibility, setAreaLabelVisibility } = this.props;
    return (
      setAreaLabelVisibility(!areaLabelVisibility)
    )
   }

  /**
   * Set the Setback Type Markers Visibility. This will show/hide the Setback Types Markers depending on the previous value.
   */
   setSetbackTypeMarkerVisibility = () => {
    const { setbackTypeMarkerVisibility, setSetbackTypeMarkerVisibility } = this.props;
    return (
      setSetbackTypeMarkerVisibility(!setbackTypeMarkerVisibility)
    )
   }

   /**
    * Render generic button
    */
   renderGenericCheck = (element, onClick, buttonLabel) => {
     return (
      <div className="check">
        <div
          className={`checkmark ${element ? "selected" : ""}`}
          onClick={onClick}
        />
        <p className="options-label">{buttonLabel}</p>
      </div>
     )
   }

   /**
    * Render `Show Building Area` checkmark
    */
   renderAreaVisibilityCheck = () => {
     const { areaLabelVisibility } = this.props;
     return (
      this.renderGenericCheck(areaLabelVisibility, this.setAreaLabelVisibility, " Show Building Area")
     )
   }

   /**
    * Render `Show Setback Type` checkmack
    */
   renderSetbackTypeMarkersVisibilityCheck = () => {
     const { setbackTypeMarkerVisibility } = this.props;
     return (
      this.renderGenericCheck(setbackTypeMarkerVisibility, this.setSetbackTypeMarkerVisibility, "Show Setback Types")
     )
   }

  render() {
    const isOneParcel = this.props.parcelGeoJson.geometry.coordinates.length === 1;
    return (
      <WithPanelHeader title="Setback Tools">
        <div className="component--setback-tools">
          <div className="content">
            <div className="header">Assign setback type:</div>
            {this.renderSelectSetbackList()}
          </div>
          <div className="visibility-box">
            {isOneParcel && this.renderAreaVisibilityCheck()}
            {this.renderSetbackTypeMarkersVisibilityCheck()}
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetbackTools);
