import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class HazardousSitesLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.HazardousSites}
        classes="hazardous-sites"
        legendRows={[
          <LegendRow
            color="#c623ef"
            text="Hazardous Sites"
          />
        ]}
      />
    );
  }
}

export default HazardousSitesLegend;
