import { LayerSector } from "types/LayerSector";

enum LAYER_NAMES {
  ReitsProjects = "REITs Projects",
  CapRates = "Cap Rates",
  SmartSearchResults = "Search Engine Results",
  Zoning = "Zoning Map",
  Population = "Population",
  EmploymentPerPopulation = "Employment",
  MedianRent = "Median Rent",
  MedianIncome = "Median Income",
  HotelAverageDailyRate = "Hotel ADR",
  IndustrialRate = "Industrial Rates",
  MultifamilyRent = "Resi Rates",
  OfficeRate = "Office Rates",
  RetailRate = "Retail Rates",
  HistoricBuildings = "Historic Buildings",
  Businesses = "Businesses",
  EducationFacilities = "Education Facilities",
  Hotels = "Hotels",
  MultifamilyBuildingPermits2019 = "Multi Permits",
  MultifamilyBuildingPermitsChange20182019 = "Multi Permits (% Change)",
  ResidentialBuildingPermits2019 = "Resi Permits",
  ResidentialBuildingPermitsChange20182019 = "Resi Permits (% Change)",
  FederalOwnedLands = "Federal Owned Land",
  HistoricSites = "Historic Sites",
  BayAreaHousingSites = "Bay Area Housing Sites",
  LIHTC_ExistingPropertyInventory = "LIHTC Existing Inventory",
  NaturalAndMarineProtectedLands = "Protected Areas",
  Forests = "Forests",
  HazardousSites = "Hazardous Sites",
  Watersheds = "Watersheds",
  Parks = "Parks",
  IndianReservations = "Indian Reservations",
  CensusTracts = "Census Tracts",
  OpportunityZone = "Opportunity Zone",
  DifficultDevelopmentAreas = "DDA",
  QualifiedCensusTracts = "QCT",
  CityBoundaries = "City Boundaries",
  CountyBoundaries = "Areas with Parcels",
  TransitOrientedCommunity = "TOC",
  Hospitals = "Hospitals",
  RoadType = "Road Type",
  TrafficDailyAverage2019 = "Traffic Count",
  SeaLevelRise = "Sea Level Rise",
  Waterlands = "Waterlands",
}

const config = {
  [LayerSector.Zoning]: {
    buttons: [LAYER_NAMES.TransitOrientedCommunity, LAYER_NAMES.SmartSearchResults, LAYER_NAMES.Zoning],
  },

  [LayerSector.Census]: {
    buttons: [
      LAYER_NAMES.Population,
      LAYER_NAMES.EmploymentPerPopulation,
      LAYER_NAMES.MedianIncome,
      LAYER_NAMES.MedianRent,
    ],
  },

  [LayerSector.Rates]: {
    buttons: [
      LAYER_NAMES.CapRates,
      LAYER_NAMES.MultifamilyRent,
      LAYER_NAMES.HotelAverageDailyRate,
      LAYER_NAMES.OfficeRate,
      LAYER_NAMES.RetailRate,
      LAYER_NAMES.IndustrialRate,
    ],
  },

  [LayerSector.Location]: {
    buttons: [
      LAYER_NAMES.EducationFacilities,
      LAYER_NAMES.HistoricBuildings,
      LAYER_NAMES.HistoricSites,
      LAYER_NAMES.Hospitals,
      LAYER_NAMES.Hotels,
      LAYER_NAMES.Businesses,
      LAYER_NAMES.LIHTC_ExistingPropertyInventory,
      LAYER_NAMES.ReitsProjects,
    ],
  },

  [LayerSector.Environmental]: {
    buttons: [
      LAYER_NAMES.FederalOwnedLands,
      LAYER_NAMES.Forests,
      LAYER_NAMES.HazardousSites,
      LAYER_NAMES.IndianReservations,
      LAYER_NAMES.Parks,
      LAYER_NAMES.NaturalAndMarineProtectedLands,
      LAYER_NAMES.SeaLevelRise,
      LAYER_NAMES.Watersheds,
      LAYER_NAMES.Waterlands,
    ],
  },

  [LayerSector.Borders]: {
    buttons: [
      LAYER_NAMES.CensusTracts,
      LAYER_NAMES.CityBoundaries,
      LAYER_NAMES.CountyBoundaries,
      LAYER_NAMES.TrafficDailyAverage2019,
      LAYER_NAMES.RoadType,
    ],
  },

  [LayerSector.Incentives]: {
    buttons: [
      LAYER_NAMES.DifficultDevelopmentAreas,
      LAYER_NAMES.BayAreaHousingSites,
      LAYER_NAMES.OpportunityZone,
      LAYER_NAMES.QualifiedCensusTracts,
    ],
  },
};

export default config;
