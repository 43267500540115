import React from "react";
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper";
import { connect } from "react-redux";
import { userSelectors } from "../../../../../../state/user";
import { subscriptionSelectors, subscriptionActions } from "../../../../../../state/subscription";
import chargebee from "../../../../../../utils/functions/chargebee";
import wrapComponentWithPopup, { PopupProps } from "../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../../sharedComponents/WithPanelHeader";
import BusySpinner from "../../../../../sharedComponents/BusySpinner";

const STYLES = {
  base: {
    color: "#000",
    fontSize: "12px",
    fontSmoothing: "antialiased",

    "::placeholder": {
      color: "#999999",
    },
  },
  invalid: {
    color: "red",
  },
}

const CLASSES = {
  "focus": "focus-css-class",
  "complete": "complete-css-class",
  "invalid": "invalid-css-class",
  "empty": "empty-css-class",
}

const mapStateToProps = (state) => {
  return {
    userDocument: userSelectors.getUserDocument(state),
    creditCard: subscriptionSelectors.getCreditCard(state),
  };
};

const mapDispatchToProps = {
  updateCreditCard: subscriptionActions.updateCreditCard,
}

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = PopupProps & StateProps & DispatchProps;

type Errors = {
  [field in string]: { message: string }
}

const MISSING_NAME_ERROR_MESSAGE = "First and last name are required."

interface State {
  firstName: string;
  lastName: string;
  errorMessage: string;
  errors: Errors,
  submittingCreditCard: boolean;
};

class CreditCardPopup extends React.PureComponent<Props, State>  {
  cardReference: any;
  submittingTimeoutId: any;

  constructor(props) {
    super(props);

    this.cardReference = React.createRef();

    this.state = {
      firstName: "",
      lastName: "",
      errorMessage: "",
      errors: {},
      submittingCreditCard: false,
    };
  }

  /**
   * Handle first and last name change inputs.
   */
  onNameChange = (event, fieldName) => {
    const value = event.target.value && event.target.value.trim();

    let status = {
      field: fieldName,
    }

    if (!value) {
      status["error"] = { message: MISSING_NAME_ERROR_MESSAGE };
    }

    this.handleCardChange(status, { [fieldName]: value });
  }

  /**
   * Returns true when the form required fields are filled or false otherwise.
   */
  hasRequiredFields = () => {
    let errors: Errors = {
      ...this.state.errors
    };

    if (!this.state.firstName) errors["firstName"] = { message: MISSING_NAME_ERROR_MESSAGE };
    if (!this.state.lastName) errors["lastName"] = { message: MISSING_NAME_ERROR_MESSAGE };

    this.updateStateWithErrorMessage(errors);

    return this.state.firstName && this.state.lastName;
  }

  /**
   * Submit payment method update.
   */
  submit = async () => {
    const { userDocument } = this.props;
    if (!userDocument
      || !this.hasRequiredFields()
      || this.state.submittingCreditCard
    ) return;

    const { firstName, lastName } = this.state;
    try {
      this.setState({ submittingCreditCard: true });
      let paymentIntent = await chargebee.createPaymentIntent(100);

      const additionalData = { firstName, lastName };
      paymentIntent = await this.cardReference.current.authorizeWith3ds(paymentIntent, additionalData);

      const chargebeeCustomerId = userDocument.chargebeeCustomerId;
      this.props.updateCreditCard(firstName, lastName, paymentIntent, chargebeeCustomerId);
      this.props.closePopup();
    } catch (error) {
      const errorObject: any = error
      this.setState({ submittingCreditCard: false, errorMessage: errorObject.message });
    }
  }

  /**
   * Handle card fields value change.
   */
  handleCardChange = (status, partialState = {}) => {
    const errors = {
      ...this.state.errors,
      [status.field]: status.error
    };

    this.updateStateWithErrorMessage(errors, partialState);
  }

  /**
   * Update state and error message.
   */
  updateStateWithErrorMessage = (errors: Errors, partialState = {}) => {
    const errorMessages = Object.values(errors).filter(message => Boolean(message));
    const errorMessage = errorMessages[errorMessages.length - 1];

    this.setState({
      ...partialState,
      errors,
      errorMessage: (errorMessage && errorMessage.message) || "",
    })
  }

  render() {
    const { creditCard } = this.props;
    const { firstName, lastName, errorMessage, errors, submittingCreditCard } = this.state;

    return (
      <WithPanelHeader title="Payment Method" hideControls>
        <div className="component--credit-card-popup">
          {creditCard &&
              <div className="section">
                <div className="header">Current Payment Method</div>
                <div className="section-content">
                  <div className="text">
                    <span className="capitalize bold">{creditCard.card_type}</span>
                    <span> ending in </span>
                    <span className="bold">{creditCard.last4}</span>
                  </div>
                </div>
              </div>
          }

          <div className="section">
            <div className="header">New Payment Method</div>
            <div className="section-content">
              Please enter the payment method details.
              <div className="input-row">
                <input
                  placeholder="First name"
                  value={firstName}
                  autoComplete="ccname"
                  className={`field ${errors["firstName"] ? "error" : ""}`}
                  onChange={(event) => this.onNameChange(event, "firstName")}
                />
                <input
                  placeholder="Last name"
                  value={lastName}
                  autoComplete="ccname"
                  className={`field ${errors["lastName"] ? "error" : ""}`}
                  onChange={(event) => this.onNameChange(event, "lastName")}
                />
              </div>

              <CardComponent
                ref={this.cardReference}
                classes={CLASSES}
                styles={STYLES}
              >
                <div className="input-row">
                  <CardNumber
                    placeholder="Card number"
                    className="field empty"
                    onChange={this.handleCardChange}
                  />
                </div>
                <div className="input-row">
                  <CardExpiry
                    placeholder="MM / YY"
                    className="field empty"
                    onChange={this.handleCardChange}
                  />
                  <CardCVV
                    placeholder="CVV"
                    className="field empty"
                    onChange={this.handleCardChange}
                  />
                </div>
              </CardComponent>

              <div className="error-container">
                {errorMessage}
              </div>
            </div>
          </div>

          <div className="buttons-wrapper">
            <button onClick={this.props.closePopup}>Close</button>
            <button onClick={this.submit} className={submittingCreditCard ? "submitting" : ""}>
              {submittingCreditCard
                  ? <BusySpinner classes="white-spinner" />
                  : creditCard
                      ? "Update"
                      : "Add"
              }
            </button>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreditCardPopup)
);
