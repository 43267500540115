import React from "react";
import { Layer } from "react-mapbox-gl";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties";
import { RawDynamicLayersFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { connect } from "react-redux";
import { paintDemographicDynamicLayerFill } from "utils/mapbox/layerPaintersHelper";

const FIELD_TO_FILL_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianGrossRentTotal2013]: MapStyleProperties.LayerId.DynamicMedianRentFill_2013,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2014]: MapStyleProperties.LayerId.DynamicMedianRentFill_2014,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2015]: MapStyleProperties.LayerId.DynamicMedianRentFill_2015,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2016]: MapStyleProperties.LayerId.DynamicMedianRentFill_2016,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2017]: MapStyleProperties.LayerId.DynamicMedianRentFill_2017,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2018]: MapStyleProperties.LayerId.DynamicMedianRentFill_2018,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2019]: MapStyleProperties.LayerId.DynamicMedianRentFill_2019,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2020]: MapStyleProperties.LayerId.DynamicMedianRentFill_2020,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2021]: MapStyleProperties.LayerId.DynamicMedianRentFill_2021,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2022]: MapStyleProperties.LayerId.DynamicMedianRentFill_2022,
};

const FIELD_TO_BORDER_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianGrossRentTotal2013]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2013,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2014]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2014,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2015]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2015,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2016]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2016,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2017]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2017,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2018]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2018,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2019]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2019,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2020]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2020,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2021]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2021,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2022]: MapStyleProperties.LayerId.DynamicMedianRentBorder_2022,
};

const FIELD_TO_SYMBOL_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianGrossRentTotal2013]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2014]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2015]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2016]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2017]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2018]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2019]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2020]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2021]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021,
  [RawDynamicLayersFieldId.MedianGrossRentTotal2022]: MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022,
};

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DynamicMedianRentLayer extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer) return null;
    if (getSelectedDynamicLayer !== MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent) return null;
    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    return (
      <>
        <Layer
          id={FIELD_TO_FILL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={paintDemographicDynamicLayerFill(
            layerSelected,
            MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
          )}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
        <Layer
          id={FIELD_TO_BORDER_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={mapboxPresentationProperties.demographicsLinePaint(layerSelected)}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={10}
        />
        <Layer
          id={FIELD_TO_SYMBOL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          layout={mapboxPresentationProperties.demographicsLabelsSymbolLayout(layerSelected, { prefix: "$" })}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          before={MapStyleProperties.LayerId.UsaBuildings}
          minZoom={12}
        />
      </>
    );
  }
}

export default connect(mapStateToProps)(DynamicMedianRentLayer);
