import { GeoJSON } from "geojson";
import produce from "immer";
import log from "loglevel";
import { Values } from "../../types/Development/Values";
import { Filters } from "../../types/Filter";
import { ZoningFilters } from "../../types/ZoningFilters";
import { KeyCode } from "../../types/KeyCodes";
import { GeocodePlaceType } from "../../types/Mapbox/Mapbox";
import { ParcelTool } from "../../types/ParcelTool";
import Unit from "../../types/Unit";
import { VariableId } from "../../types/VariableId";
import filterHelper from "../../utils/filterHelper";
import geometry from "../../utils/geometry";
import { MapStyleProperties } from "../../utils/mapbox/mapStyleProperties";
import assembleParcels from "../../utils/parcel/assembleParcels";
import parcelAccessors from "../../utils/parcel/parcelAccessors";
import turf from "../../utils/turf";
import { listingsActionsTypes } from "../listings";
import actionTypes from "./actionTypes";
import actions from "./actions";
import { AllowedUsesFilters } from "types/AllowedUsesFilter";
import { OwnerNamesFilter } from "types/OwnerNamesFilter";
import { OwnerAddressesFilter } from "types/OwnerAddressesFilter";
import { LandUseFilter } from "types/LandUseFilter";

export type InitialValues = { [key in VariableId]?: Values[key] };

export interface NewDevelopment {
  searchAddress: string;
  suggestedFeaturePlaceType: Array<GeocodePlaceType>;
  hoveredFeature: any;
  selectedFeature: any;
  selectedFeatureMembers: { [featureId: number]: GeoJSON };
  drawnParcels: Array<GeoJSON>;
  suggestedFeatures: Array<any>;
  suggestedFeaturesSelectionIndex: number;
  userIsTyping: boolean;
  geocoderIsQuerying: boolean;
  geocoderProximityCenter: [number, number] | null;
  pinPosition?: [number, number] | null;
  displayPin?: boolean;
  parcelTool: ParcelTool;
  parcelToolFromToolbar: ParcelTool;
  polygonIsBeingChanged: boolean;
  polygonArea: number;
  polygonPerimeter: number;
  unitSystem: Unit.System;
  smartSearchIsOpen: boolean;
  parcelsInViewport: Array<any>;
  smartSearchResult: Array<any>;
  filters: Filters;
  zoningFilters: ZoningFilters;
  allowedUsesFilters: AllowedUsesFilters;
  ownerNamesFilters: OwnerNamesFilter;
  ownerAddressesFilters: OwnerAddressesFilter;
  landUseFilters: LandUseFilter;
  initialValues: InitialValues;
  parcelDataInViewport: boolean | null;
  zoningDataInViewport: boolean | null;
  queryViewport: boolean;
}

const DEFAULT_INPUT_VALUE = "";
const DEFAULT_FAR_VALUE = 6;

const INITIAL_STATE: NewDevelopment = {
  searchAddress: DEFAULT_INPUT_VALUE,
  suggestedFeaturePlaceType: [],
  hoveredFeature: null,
  selectedFeature: null,
  selectedFeatureMembers: {},
  drawnParcels: [],
  suggestedFeatures: [],
  suggestedFeaturesSelectionIndex: -1,
  userIsTyping: false,
  geocoderIsQuerying: false,
  geocoderProximityCenter: null,
  parcelTool: ParcelTool.SelectParcel,
  parcelToolFromToolbar: ParcelTool.SelectParcel,
  polygonIsBeingChanged: false,
  polygonArea: 0,
  polygonPerimeter: 0,
  unitSystem: Unit.System.Imperial,
  smartSearchIsOpen: true,
  queryViewport: true,
  parcelsInViewport: [],
  smartSearchResult: [],
  filters: filterHelper.initializeFilters(),
  zoningFilters: {},
  allowedUsesFilters: {},
  ownerNamesFilters: {},
  ownerAddressesFilters: {},
  landUseFilters: {},
  initialValues: {
    floorAreaRatio: DEFAULT_FAR_VALUE,
  },
  parcelDataInViewport: null,
  zoningDataInViewport: null,
};

const reducer = (previousState = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE:
      return initialize(previousState);
    case actionTypes.ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START:
      return addressKeystrokeAndForwardGeocodeStart(previousState, action.payload);
    case actionTypes.ADDRESS_SUBMIT:
      return addressSubmit(previousState, action.payload);
    case listingsActionsTypes.SET_SELECTED_LISTING:
      return setSelectedListing(previousState, action.payload);
    case actionTypes.CLEAR_FEATURE_SELECTION:
      return clearFeatureSelection(previousState, action.payload);
    case actionTypes.FORWARD_GEOCODE_SUCCESS:
      return forwardGeocodeSuccess(previousState, action.payload);
    case actionTypes.GEOCODE_ERROR:
      return geocodeError(previousState, action.payload);
    case actionTypes.HOVER_FEATURE:
      return hoverFeature(previousState, action.payload);
    case actionTypes.SELECT_PARCEL_SUCCESS:
      return selectParcelSuccess(previousState, action.payload);
    case actionTypes.COMBINE_PARCELS_SUCCESS:
      return combineParcelsSuccess(previousState, action.payload);
    case actionTypes.SET_PROXIMITY_CENTER:
      return setProximityCenter(previousState, action.payload);
    case actionTypes.SUGGESTED_FEATURE_NEXT:
      return suggestedFeatureNext(previousState, action.payload);
    case actionTypes.SUGGESTED_FEATURE_PREVIOUS:
      return suggestedFeaturePrevious(previousState, action.payload);
    case actionTypes.SET_POLYGON_MEASUREMENTS:
      return setPolygonMeasurements(previousState, action.payload);
    case actionTypes.RESET_POLYGON_MEASUREMENTS:
      return resetPolygonMeasurements(previousState, action.payload);
    case actionTypes.SET_POLYGON_IS_BEING_CHANGED:
      return setPolygonIsBeingChanged(previousState, action.payload);
    case actionTypes.SET_PARCEL_TOOL:
      return setParcelTool(previousState, action.payload);
    case actionTypes.MODIFIER_KEY_DOWN:
      return modifierKeyDown(previousState, action.payload);
    case actionTypes.MODIFIER_KEY_UP:
      return modifierKeyUp(previousState, action.payload);
    case actionTypes.SET_DRAWN_PARCELS:
      return setDrawnParcels(previousState, action.payload);
    case actionTypes.SET_UNIT_SYSTEM:
      return setUnitSystem(previousState, action.payload);
    case actionTypes.SET_SMART_SEARCH_IS_OPEN:
      return setSmartSearchIsOpen(previousState, action.payload);
    case actionTypes.SET_DISPLAY_PIN:
      return setDisplayPin(previousState, action.payload);
    case actionTypes.SET_PARCELS_IN_VIEWPORT:
      return setParcelsInViewport(previousState, action.payload);
    case actionTypes.UPDATE_FILTER:
      return updateFilter(previousState, action.payload);
    case actionTypes.UPDATE_ZONING_FILTER_VALUES:
      return updateZoningFilterValues(previousState, action);
    case actionTypes.UPDATE_ALLOWED_USES_FILTER_VALUES:
      return updateAllowedUsesFilterValues(previousState, action);
    case actionTypes.UPDATE_OWNER_NAMES_FILTER_VALUES:
      return updateOwnerNameFilterValues(previousState, action);
    case actionTypes.UPDATE_OWNER_ADDRESSES_FILTER_VALUES:
      return updateOwnerAddressFilterValues(previousState, action);
    case actionTypes.UPDATE_LAND_USE_FILTER_VALUES:
      return updateLandUsesFilterValues(previousState, action);
    case actionTypes.UPDATE_INITIAL_VALUES:
      return updateInitialValues(previousState, action.payload);
    case actionTypes.SET_DATA_IN_VIEWPORT:
      return setDataInViewport(previousState, action.payload);
    case actionTypes.SET_QUERY_VIEWPORT:
      return setQueryViewport(previousState, action.payload);
    default:
      return previousState;
  }
};

/**
 * See `initialize` action creator.
 *
 * Returns initial state with default values.
 */
const initialize = (previousState: NewDevelopment): NewDevelopment => {
  return {
    ...INITIAL_STATE,
    drawnParcels: previousState.drawnParcels,
    filters: previousState.filters,
    pinPosition: previousState.pinPosition,
    displayPin: previousState.displayPin,
    suggestedFeaturePlaceType: previousState.suggestedFeaturePlaceType,
  };
};

/**
 * See `addressKeystrokeAndForwardGeocodeStart` action creator.
 *
 * Returns a new state object with the input value updated.
 */
const addressKeystrokeAndForwardGeocodeStart = (previousState: NewDevelopment, payload): NewDevelopment => {
  // TODO: Clean up handling of minimum address length.
  // See https://deepblocks.tpondemand.com/entity/4076-clean-up-handling-of-minimum-address
  return {
    ...previousState,
    searchAddress: payload.searchAddress,
    userIsTyping: true,
    pinPosition: null,
    suggestedFeaturesSelectionIndex: -1,
    geocoderIsQuerying: payload.geocoderIsQuerying,
  };
};

/**
 * See `addressSubmit` action creator.
 *
 * Returns a new state with the properties set to move to the suggested feature.
 */
const addressSubmit = (previousState: NewDevelopment, payload): NewDevelopment => {
  if (previousState.geocoderIsQuerying) {
    return previousState;
  }

  return {
    ...previousState,
    pinPosition: payload.suggestedFeature.center,
    displayPin: true,
    searchAddress: payload.suggestedFeature.place_name,
    suggestedFeaturePlaceType: payload.suggestedFeature.place_type || INITIAL_STATE.suggestedFeaturePlaceType,
    selectedFeature: null,
    selectedFeatureMembers: {},
    userIsTyping: false,
  };
};

/**
 * See `forwardGeocodeSuccess` action creator.
 *
 * Returns a new state object with suggestion list updated.
 */
const forwardGeocodeSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let newState = {
    suggestedFeatures: [],
    geocoderIsQuerying: false,
  };

  if (payload.results) {
    newState.suggestedFeatures = payload.results.features;
  }

  return {
    ...previousState,
    ...newState,
  };
};

/**
 * See `geocodeError` action creator.
 *
 * Returns the same state object after logging the error.
 */
const geocodeError = (previousState: NewDevelopment, payload): NewDevelopment => {
  log.error("Error:", payload.error);
  return previousState;
};

/**
 * See `hoverFeature` action creator
 *
 * Returns a new state object with the hovered feature updated.
 */
const hoverFeature = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    hoveredFeature: payload.hoveredFeature,
  };
};

/**
 * See `listingsActions.setSelectedListing` action creator.
 *
 * Returns a new state with search panel closed.
 */
const setSelectedListing = (previousState: NewDevelopment, payload): NewDevelopment => {
  const newState = clearFeatureSelection(previousState, payload);

  return {
    ...newState,
    smartSearchIsOpen: false,
  };
};

/**
 * See `clearFeatureSelection` action creator.
 *
 * Returns a new state with the parcel search, hover and selection properties reset
 * to their initial values.
 */
const clearFeatureSelection = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    selectedFeatureMembers: {},
    selectedFeature: INITIAL_STATE.selectedFeature,
    hoveredFeature: INITIAL_STATE.hoveredFeature,
    searchAddress: INITIAL_STATE.searchAddress,
    suggestedFeatures: INITIAL_STATE.suggestedFeatures,
    suggestedFeaturesSelectionIndex: INITIAL_STATE.suggestedFeaturesSelectionIndex,
    userIsTyping: INITIAL_STATE.userIsTyping,
    initialValues: INITIAL_STATE.initialValues,
  };
};

/**
 * See `selectParcelSuccess` action creator.
 *
 * Returns a new state object with the selected feature updated.
 */
const selectParcelSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let selectedParcel = payload.selectedParcel;
  let geocodedFeature = payload.geocodedFeature;

  let selectedFeatureMembers = {};
  const parcelId = parcelAccessors.getParcelId(selectedParcel);
  if (parcelId) {
    selectedFeatureMembers[parcelId] = selectedParcel;
  }

  const floorAreaRatio = parcelAccessors.getMinimumFloorAreaRatio(selectedParcel);

  return {
    ...previousState,
    searchAddress: geocodedFeature && geocodedFeature.place_name,
    suggestedFeaturePlaceType: INITIAL_STATE.suggestedFeaturePlaceType,
    selectedFeature: selectedParcel,
    selectedFeatureMembers: selectedFeatureMembers,
    userIsTyping: false,
    pinPosition: null,
    displayPin: false,
    suggestedFeaturesSelectionIndex: -1,
    smartSearchIsOpen: false,
    queryViewport: false,
    parcelsInViewport: [],
    initialValues: {
      ...previousState.initialValues,
      floorAreaRatio: floorAreaRatio || DEFAULT_FAR_VALUE,
    },
  };
};

/**
 * See `combineParcelsSuccess` action creator.
 *
 * Returns a new state object with the selected feature updated.
 */
const combineParcelsSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let geocodedFeature = payload.geocodedFeature;

  // Adjust the array of selected features.
  const clickedParcel = payload.clickedParcel;
  let selectedFeatureMembers = { ...previousState.selectedFeatureMembers };
  let parcelId = parcelAccessors.getParcelId(clickedParcel);
  if (parcelId && selectedFeatureMembers[parcelId]) {
    delete selectedFeatureMembers[parcelId];
  } else if (parcelId) {
    selectedFeatureMembers[parcelId] = clickedParcel;
  }

  const selectedFeature = assembleParcels(Object.values(selectedFeatureMembers));

  const floorAreaRatio = parcelAccessors.getMinimumFloorAreaRatio(selectedFeature);

  return {
    ...previousState,
    suggestedFeaturePlaceType: INITIAL_STATE.suggestedFeaturePlaceType,
    searchAddress: geocodedFeature && geocodedFeature.place_name,
    selectedFeature: selectedFeature,
    selectedFeatureMembers: selectedFeatureMembers,
    userIsTyping: false,
    pinPosition: null,
    displayPin: false,
    suggestedFeaturesSelectionIndex: -1,
    smartSearchIsOpen: false,
    queryViewport: false,
    parcelsInViewport: [],
    initialValues: {
      ...previousState.initialValues,
      floorAreaRatio: floorAreaRatio || DEFAULT_FAR_VALUE,
    },
  };
};

/**
 * See `setProximityCenter` action creator.
 *
 * Returns a new state object with the geocoderProximityCenter updated.
 */
const setProximityCenter = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    geocoderProximityCenter: payload.geocoderProximityCenter,
  };
};

/**
 * Calculates next index when down arrow key is pressed.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const suggestedFeatureNext = (previousState: NewDevelopment, payload): NewDevelopment => {
  let previousIndex = previousState.suggestedFeaturesSelectionIndex;
  let maxIndex = previousState.suggestedFeatures.length;
  let nextIndex = (previousIndex + 1) % maxIndex;

  return setActiveSuggestionIndex(previousState, nextIndex);
};

/**
 * Calculates next index when up arrow key is pressed.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const suggestedFeaturePrevious = (previousState: NewDevelopment, payload): NewDevelopment => {
  let previousIndex = previousState.suggestedFeaturesSelectionIndex;
  let maxIndex = previousState.suggestedFeatures.length;
  let nextIndex = (previousIndex - 1 + maxIndex) % maxIndex;

  return setActiveSuggestionIndex(previousState, nextIndex);
};

/**
 * Sets the suggestedFeaturesSelectionIndex which highlights the suggestion in the suggestion dropdown.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const setActiveSuggestionIndex = (previousState, nextIndex) => {
  if (nextIndex >= 0) {
    return {
      ...previousState,
      suggestedFeaturesSelectionIndex: nextIndex,
    };
  }

  return previousState;
};

/**
 * See `setParcelTool` action creator.
 *
 * Returns a new state object with the parcelTool updated.
 */
const setParcelTool = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    parcelTool: payload.parcelTool,
    parcelToolFromToolbar: payload.parcelTool,
  };
};

/**
 * See `setPolygonMeasurements` action creator.
 *
 * Returns a new state object with the polygonArea and polygonPerimeter updated.
 */
const setPolygonMeasurements = (previousState: NewDevelopment, payload): NewDevelopment => {
  const { feature } = payload;
  const polygonArea = turf.area(feature);
  const polygonPerimeter = geometry.perimeter(feature);

  return {
    ...previousState,
    polygonArea,
    polygonPerimeter,
  };
};

/**
 * See `resetPolygonMeasurements` action creator.
 *
 * Returns new state with default polygon values.
 */
const resetPolygonMeasurements = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    polygonArea: INITIAL_STATE.polygonArea,
    polygonPerimeter: INITIAL_STATE.polygonPerimeter,
    polygonIsBeingChanged: false,
  };
};

/**
 * See `modifierKeyDown` action creator.
 *
 * Returns a new state object with the appropriate parcelTool updated.
 */
const modifierKeyDown = (previousState: NewDevelopment, payload): NewDevelopment => {
  if (payload.keyCode !== KeyCode.Shift || previousState.parcelToolFromToolbar !== ParcelTool.SelectParcel)
    return previousState;

  return {
    ...previousState,
    parcelTool: ParcelTool.CombineParcels,
  };
};

/**
 * See `setPolygonIsBeingChanged` action creator.
 *
 * Returns a new state object with the polygonIsBeingChanged updated.
 */
const setPolygonIsBeingChanged = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    polygonIsBeingChanged: payload.polygonIsBeingChanged,
  };
};

/**
 * See `modifierKeyUp` action creator.
 *
 * Returns a new state object with the appropriate parcelTool updated.
 */
const modifierKeyUp = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    parcelTool: previousState.parcelToolFromToolbar,
  };
};

/**
 * See `setDrawnParcels` action creator.
 *
 * Returns a new state object with the drawnParcels updated.
 */
const setDrawnParcels = (previousState: NewDevelopment, payload): NewDevelopment => {
  let drawnParcels: any = {};
  payload.drawnParcels.forEach((parcel) => {
    let parcelId = parcel.properties[MapStyleProperties.RawParcelFieldId.Id];
    // Remove id property of feature that gets added by mapbox-draw.
    delete parcel.id;
    drawnParcels[parcelId] = parcel;
  });

  return { ...previousState, drawnParcels };
};

/**
 * See `setUnitSystem` action creator.
 *
 * Returns a new state object with the unit system updated.
 */
const setUnitSystem = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    unitSystem: payload.unitSystem,
  };
};

/**
 * See `setSmartSearchIsOpen` action creator.
 *
 * Returns a new state object with the smartSearchIsOpen updated.
 */
const setSmartSearchIsOpen = (previousState: NewDevelopment, payload): NewDevelopment => {
  const newState = clearFeatureSelection(previousState, payload);

  return {
    ...newState,
    smartSearchIsOpen: payload.smartSearchIsOpen,
    queryViewport: payload.smartSearchIsOpen,
    parcelsInViewport: [],
  };
};

/**
 * See `setDisplayPin` action creator.
 *
 * Returns a new state object with the displayPin updated.
 */
const setDisplayPin = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    displayPin: payload.displayPin,
  };
};

/**
 * See `setParcelsInViewport` action creator.
 *
 * Returns a new state object with the parcelsInViewport updated.
 */
const setParcelsInViewport = (previousState: NewDevelopment, payload): NewDevelopment => {
  const newState = clearFeatureSelection(previousState, payload);

  return produce(newState, (newStateDraft) => {
    const parcelsInViewport = payload;
    filterHelper.updateFiltersToDisplay(parcelsInViewport, newStateDraft.filters);
    const zoningFilters = filterHelper.updateZoningFiltersToDisplay(parcelsInViewport, previousState.zoningFilters);
    const allowedUsesFilters = filterHelper.updateAllowedUsesFiltersToDisplay(
      parcelsInViewport,
      previousState.allowedUsesFilters
    );
    const ownerNameFilters = filterHelper.updateOwnerNameFiltersToDisplay(
      parcelsInViewport,
      previousState.ownerNamesFilters
    );
    const ownerAddressFilter = filterHelper.updateOwnerAddressFiltersToDisplay(
      parcelsInViewport,
      previousState.ownerAddressesFilters
    );
    const landUseFilters = filterHelper.updateLandUseFiltersToDisplay(parcelsInViewport, previousState.landUseFilters);

    newStateDraft.parcelsInViewport = parcelsInViewport;
    newStateDraft.zoningFilters = zoningFilters;
    newStateDraft.allowedUsesFilters = allowedUsesFilters;
    newStateDraft.ownerNamesFilters = ownerNameFilters;
    newStateDraft.ownerAddressesFilters = ownerAddressFilter;
    newStateDraft.landUseFilters = landUseFilters;
    newStateDraft.smartSearchResult = filterHelper.applyFilters(
      parcelsInViewport,
      newStateDraft.filters,
      zoningFilters,
      allowedUsesFilters,
      ownerNameFilters,
      ownerAddressFilter,
      landUseFilters
    );
    newStateDraft.queryViewport = false;
  });
};

/**
 * See `setQueryViewport` action creator.
 *
 * Returns a new state object with the queryViewport updated.
 */
const setQueryViewport = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    queryViewport: payload.queryViewport,
  };
};

/**
 * See `updateFilter` action creator.
 *
 * Returns a new state object with the filters array updated.
 */
const updateFilter = (previousState: NewDevelopment, payload): NewDevelopment => {
  const parcelsInViewport = previousState.parcelsInViewport;

  return produce(previousState, (nextStateDraft) => {
    nextStateDraft.filters[payload.filterId].isActive = payload.isActive;
    nextStateDraft.filters[payload.filterId].value = payload.value;
    nextStateDraft.filters[payload.filterId].max = Math.max(payload.value[1], payload.max);

    nextStateDraft.smartSearchResult = filterHelper.applyFilters(
      parcelsInViewport,
      nextStateDraft.filters,
      nextStateDraft.zoningFilters,
      nextStateDraft.allowedUsesFilters,
      nextStateDraft.ownerNamesFilters,
      nextStateDraft.ownerAddressesFilters,
      nextStateDraft.landUseFilters
    );
  });
};

/**
 * See `updateZoningFilterValues` action creator.
 *
 * Returns a new state object with the zoning filter values applied and the resulting search results updated.
 */
const updateZoningFilterValues = (
  previousState: NewDevelopment,
  action: ReturnType<typeof actions.updateZoningFilterValues>
): NewDevelopment => {
  const newZoningFilters = {
    ...previousState.zoningFilters,
    ...action.payload,
  };

  return {
    ...previousState,
    zoningFilters: newZoningFilters,
    smartSearchResult: filterHelper.applyFilters(
      previousState.parcelsInViewport,
      previousState.filters,
      newZoningFilters,
      previousState.allowedUsesFilters,
      previousState.ownerNamesFilters,
      previousState.ownerAddressesFilters,
      previousState.landUseFilters
    ),
  };
};

/**
 * See `updateOwnerNameFilterValues` action creator.
 *
 * Returns a new state object with the OwnerName filter values applied and the resulting search results updated.
 */
const updateOwnerNameFilterValues = (
  previousState: NewDevelopment,
  action: ReturnType<typeof actions.updateOwnerNamesFilterValues>
): NewDevelopment => {
  const newOwnerNameFilters = {
    ...previousState.ownerNamesFilters,
    ...action.payload,
  };

  return {
    ...previousState,
    ownerNamesFilters: newOwnerNameFilters,
    smartSearchResult: filterHelper.applyFilters(
      previousState.parcelsInViewport,
      previousState.filters,
      previousState.zoningFilters,
      previousState.allowedUsesFilters,
      newOwnerNameFilters,
      previousState.ownerAddressesFilters,
      previousState.landUseFilters
    ),
  };
};

/**
 * See `updateOwnerAddressFilterValues` action creator.
 *
 * Returns a new state object with the OwnerAddress filter values applied and the resulting search results updated.
 */
const updateOwnerAddressFilterValues = (
  previousState: NewDevelopment,
  action: ReturnType<typeof actions.updateOwnerAddressesFilterValues>
): NewDevelopment => {
  const newOwnerAddressesFilters = {
    ...previousState.ownerAddressesFilters,
    ...action.payload,
  };

  return {
    ...previousState,
    ownerAddressesFilters: newOwnerAddressesFilters,
    smartSearchResult: filterHelper.applyFilters(
      previousState.parcelsInViewport,
      previousState.filters,
      previousState.zoningFilters,
      previousState.allowedUsesFilters,
      previousState.ownerNamesFilters,
      newOwnerAddressesFilters,
      previousState.landUseFilters
    ),
  };
};

/**
 * See `updateAllowedUsesFilterValues` action creator.
 *
 * Returns a new state object with the allowed uses filter values applied and the resulting search results updated.
 */
const updateAllowedUsesFilterValues = (
  previousState: NewDevelopment,
  action: ReturnType<typeof actions.updateAllowedUsesFilterValues>
): NewDevelopment => {
  const newAllowedUsesFilters = {
    ...previousState.allowedUsesFilters,
    ...action.payload,
  };

  return {
    ...previousState,
    allowedUsesFilters: newAllowedUsesFilters,
    smartSearchResult: filterHelper.applyFilters(
      previousState.parcelsInViewport,
      previousState.filters,
      previousState.zoningFilters,
      newAllowedUsesFilters,
      previousState.ownerNamesFilters,
      previousState.ownerAddressesFilters,
      previousState.landUseFilters
    ),
  };
};

/**
 * See `updateLandUseFilterValues` action creator.
 *
 * Returns a new state object with the land use filter values applied and the resulting search results updated.
 */
const updateLandUsesFilterValues = (
  previousState: NewDevelopment,
  action: ReturnType<typeof actions.updateLandUseFilterValues>
): NewDevelopment => {
  const newLandUseFilters = {
    ...previousState.landUseFilters,
    ...action.payload,
  };

  return {
    ...previousState,
    landUseFilters: newLandUseFilters,
    smartSearchResult: filterHelper.applyFilters(
      previousState.parcelsInViewport,
      previousState.filters,
      previousState.zoningFilters,
      previousState.allowedUsesFilters,
      previousState.ownerNamesFilters,
      previousState.ownerAddressesFilters,
      newLandUseFilters
    ),
  };
};

/**
 * See `updateInitialValues` action creator.
 *
 * Returns a new state with initial values updated.
 */
const updateInitialValues = (previousState: NewDevelopment, payload): NewDevelopment => {
  let floorAreaRatio = Number(payload.values.floorAreaRatio);
  if (isNaN(floorAreaRatio)) {
    floorAreaRatio = previousState.initialValues.floorAreaRatio || DEFAULT_FAR_VALUE;
  }

  return {
    ...previousState,
    initialValues: {
      ...previousState.initialValues,
      ...payload.values,
      floorAreaRatio,
    },
  };
};

/**
 * See `setDataInViewport` action creator.
 *
 * Returns a new state object with the parcelDataInViewport and zoningDataInViewport flags updated.
 */
const setDataInViewport = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    zoningDataInViewport: payload.zoningDataInViewport,
    parcelDataInViewport: payload.parcelDataInViewport,
  };
};

export default reducer;
