import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../state/development";
import { explorerActions, explorerSelectors } from "../../../../state/ui/explorer";
import { ToggleButton, InputField } from "../../../utils/forms/fieldRenderers";
import { usageColor } from "../../../utils/buildingUsageProperties";
import developmentUiHelper from "../../../utils/developmentUiHelper";
import analytics from "../../../../utils/analytics";
import { BuildingUse } from "../../../../types/BuildingUse";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import UnitSystemInput from "../../../sharedComponents/UnitSystemInput";
import RecalculatePopup from "./RecalculatePopup";
import HeightAndSetbacks from "./HeightAndSetbacks";

const mapStateToProps = (state) => {
  return {
    developmentName: developmentSelectors.getName(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    values: developmentSelectors.getValues(state),
    defineBuildingPanelIsOpen: explorerSelectors.getDefineBuildingPanelIsOpen(state),
  };
};

const mapDispatchToProps = {
  setDevelopmentName: developmentActions.setName,
  setDevelopmentUnitSystem: developmentActions.setUnitSystem,
  toggleBooleanInput: developmentActions.toggleBooleanInput,
  setDefineBuildingPanelIsOpen: explorerActions.setDefineBuildingPanelIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  recalculatePopupIsOpen: boolean;
}

class DefineBuildingPanel extends React.Component<Props, State>  {
  constructor(props: Props) {
    super(props);

    this.state = {
      recalculatePopupIsOpen: false,
    }
  }

  /**
   * Retrieve toggle value based on usage_group.
   */
  getToggleValue = (variableId) => {
    return this.props.values[variableId];
  };

  /**
   * Updates the store with a new development name on every keystroke.
   */
  handleDevelopmentNameChange = (event) => {
    let newDevelopmentName = event.target.value;
    this.props.setDevelopmentName(newDevelopmentName);
  };

  /**
   * Updates the store value for the toggle based on the variableId.
   */
  handleToggleChange = (variableId) => {
    analytics.trackChangeToggle(variableId, !this.getToggleValue(variableId));
    this.props.toggleBooleanInput(variableId);
  }

  /**
   * Toggle recalculatePanelIsOpen flag.
   */
  toggleRecalculatePanelIsOpen = () => {
    this.setState({ recalculatePopupIsOpen: !this.state.recalculatePopupIsOpen })
  }

  render() {
    const {
      unitSystem,
      setDevelopmentUnitSystem,
      developmentName,
      defineBuildingPanelIsOpen,
      setDefineBuildingPanelIsOpen,
    } = this.props;

    return (
      <WithPanelHeader
        title="Define Building"
        isActive={defineBuildingPanelIsOpen}
        onStateChange={setDefineBuildingPanelIsOpen}
      >
        <div className="component--define-building-panel">
          <UnitSystemInput unitSystem={unitSystem} setUnitSystem={setDevelopmentUnitSystem} />
          <div className="panel-content">
            <InputField
              label={"Project Name"}
              type="text"
              placeholder={developmentUiHelper.EMPTY_NAME_PLACEHOLDER}
              inputElementProps={{
                value: developmentName,
                onChange: this.handleDevelopmentNameChange,
                className: "project-name"
              }}
            />
            <label className="top-label">Select Uses</label>
            <div className="uses-container">
              <div>
                <ToggleButton
                  label="Condo"
                  color={usageColor(BuildingUse.Condo)}
                  isActive={this.getToggleValue("condoToggle")}
                  onClick={() => this.handleToggleChange("condoToggle")}
                  classes="condo"
                />
              </div>
              <div>
                <ToggleButton
                  label="Multifamily"
                  color={usageColor(BuildingUse.Multifamily)}
                  isActive={this.getToggleValue("multifamilyToggle")}
                  onClick={() => this.handleToggleChange("multifamilyToggle")}
                  classes="multifamily"
                />
              </div>
              <div>
                <ToggleButton
                  label="Hotel"
                  color={usageColor(BuildingUse.Hotel)}
                  isActive={this.getToggleValue("hotelToggle")}
                  onClick={() => this.handleToggleChange("hotelToggle")}
                  classes="hotel"
                />
              </div>
              <div>
                <ToggleButton
                  label="Office"
                  color={usageColor(BuildingUse.Office)}
                  isActive={this.getToggleValue("officeToggle")}
                  onClick={() => this.handleToggleChange("officeToggle")}
                  classes="office"
                />
              </div>
              <div>
                <ToggleButton
                  label="Retail"
                  color={usageColor(BuildingUse.Retail)}
                  isActive={this.getToggleValue("retailToggle")}
                  onClick={() => this.handleToggleChange("retailToggle")}
                  classes="retail"
                />
              </div>
              <div>
                <ToggleButton
                  label="Industrial"
                  color={usageColor(BuildingUse.Industrial)}
                  isActive={this.getToggleValue("industrialToggle")}
                  onClick={() => this.handleToggleChange("industrialToggle")}
                  classes="industrial"
                />
              </div>
            </div>

            <div className="recalculate-button-container">
              <button className="recalculate-button" onClick={this.toggleRecalculatePanelIsOpen}>recalculate project</button>
              {this.state.recalculatePopupIsOpen && <RecalculatePopup onStateChange={this.toggleRecalculatePanelIsOpen} />}
            </div>

            <HeightAndSetbacks />
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefineBuildingPanel);
