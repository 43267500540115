import { Map } from "mapbox-gl";
import geometry from "../geometry";
import { MapStyleProperties } from "./mapStyleProperties";
import { RawDemographicsFieldId } from "./mapStyleProperties/mapStyleProperties";

/**
 * Calculate and return bounding box of current map viewport.
 */
const calculateCurrentBoundingBox = (map: Map | undefined) => {
  if (!map) return;
  // Calculate viewport bounding box in lat,long.
  const canvas = map.getCanvas();
  const w = canvas.width;
  const h = canvas.height;
  const upperRight = map.unproject([w, 0]).toArray();
  const lowerLeft = map.unproject([0, h]).toArray();

  return [...lowerLeft, ...upperRight] as [number, number, number, number];
};

/**
 * Update rent features.
 */
const updateRentFeatures = (map: Map | undefined, callback: (reducedDemographicFeatures) => void) => {
  if (!map) return;
  const demographicsFeatures = map.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaDemographicsQuery],
  });

  let reducedDemographicsFeatures: any = {};
  demographicsFeatures.forEach((demographicFeature) => {
    if (!reducedDemographicsFeatures[demographicFeature.properties![RawDemographicsFieldId.Id]]) {
      reducedDemographicsFeatures[demographicFeature.properties![RawDemographicsFieldId.Id]] = [];
    }
    reducedDemographicsFeatures[demographicFeature.properties![RawDemographicsFieldId.Id]].push(demographicFeature);
  });

  reducedDemographicsFeatures = Object.values(reducedDemographicsFeatures)
    .map((features) => geometry.featuresUnion(features))
    .filter((feature) => feature);

  callback(reducedDemographicsFeatures);
};

export default {
  calculateCurrentBoundingBox,
  updateRentFeatures,
};
