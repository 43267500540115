import React from "react";
import wrapComponentWithPopup, { PopupProps } from "../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../../sharedComponents/WithPanelHeader";
import BusySpinner from "../../../../../sharedComponents/BusySpinner";

type Props = PopupProps;

class SendingInvitationPopup extends React.PureComponent<Props, {}>  {
  render() {
    return (
      <WithPanelHeader title="Sending Invitation" hideControls>
        <div className="component--send-invitation-popup">
          <BusySpinner />
          <div className="text">
            Sending the invitation. Please do not reload the page.
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(SendingInvitationPopup);
