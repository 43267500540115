const SET_SETBACK_MODE = "setbackMode/SET_SETBACK_MODE";
const SET_INDEX_VALUES = "setbackMode/SET_INDEX_VALUES";
const SET_MEASUREMENT_TOOL = "setbackMode/SET_MEASUREMENT_TOOL";
const SET_SHAPE_IS_BEING_CHANGED = "setbackMode/SET_SHAPE_IS_BEING_CHANGED";
const SET_SHAPE_MEASUREMENTS = "setbackMode/SET_SHAPE_MEASUREMENTS";
const RESET_SHAPE_MEASUREMENTS = "setbackMode/RESET_SHAPE_MEASUREMENTS";
const SET_AREA_LABEL_VISIBILITY = "setbackMode/SET_AREA_LABEL_VISIBILITY";
const SET_SETBACK_TYPE_MARKER_VISIBILITY = "setbackMode/SET_SETBACK_TYPE_MARKER_VISIBILITY";

export default {
  SET_INDEX_VALUES,
  SET_SETBACK_MODE,
  SET_MEASUREMENT_TOOL,
  SET_SHAPE_IS_BEING_CHANGED,
  SET_SHAPE_MEASUREMENTS,
  RESET_SHAPE_MEASUREMENTS,
  SET_AREA_LABEL_VISIBILITY,
  SET_SETBACK_TYPE_MARKER_VISIBILITY
};
