export enum Path {
  AuthAction = "/auth/action",
  Dashboard = "/dashboard",
  Explorer = "/explorer",
  ForgotPassword = "/forgot-password",
  Home = "/",
  NewProject = "/new-project",
  ResetPassword = "/reset-password",
  Subscribe = "/subscribe",
  SubscriptionManagement = "/subscription-management",
  VerifyEmail = "/verify-email",
  SignUp = "/sign-up"
}
