import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UnblockRouteFunction } from "state/ui/shared/route/types";

export const useExplorerRouting = (
  routeIsBlocked: boolean,
  projectIsEdited: boolean,
  blockedRoutePathName: string,
  unblockRoute: UnblockRouteFunction
) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (routeIsBlocked && !projectIsEdited && blockedRoutePathName) {
      navigate(blockedRoutePathName);
      unblockRoute();
    }
  }, [routeIsBlocked, projectIsEdited, blockedRoutePathName]);
};
