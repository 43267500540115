import { createSelector } from "@reduxjs/toolkit";

/**
 * Select the new development UI state.
 */
const getNewDevelopmentUiState = (state) => state.ui.newDevelopment;

/**
 * Select the flag indicating whether the initial values panel is currently open.
 */
const getInitialValuesPanelIsOpen = createSelector(
  getNewDevelopmentUiState,
  (newDevelopmentUiState) => newDevelopmentUiState.initialValuesPanelIsOpen,
)

/**
 * Select the flag indicating whether the initial values panel is currently open.
 */
const getDevelopmentIsBeingCreated = createSelector(
  getNewDevelopmentUiState,
  (newDevelopmentUiState) => newDevelopmentUiState.developmentIsBeingCreated,
)

export default { getInitialValuesPanelIsOpen, getDevelopmentIsBeingCreated }
