import analytics from "../utils/analytics";
import { setbackModeActions, setbackModeActionTypes } from "./setbackMode";

/**
 * The middleware function. Detects actions that set setback mode and tracks
 * changes for analytic purposes.
 *
 * TODO: Move every analytics tracking to this function and document all the ones that
 * can't be moved here.
 *
 */
const analyticsMiddleware = (store) => (next) => (action) => {
  if (action.type === setbackModeActionTypes.SET_SETBACK_MODE) {
    const setbackModeIsActive = (action as ReturnType<typeof setbackModeActions.setSetbackMode>).payload.setbackModeIsActive;
    analytics.trackChangeToggle("setbackMode", setbackModeIsActive);
  }

  return next(action);
}

export default analyticsMiddleware;
