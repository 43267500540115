import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import { FilterId } from "../../../../../types/Filter";
import authentication from "../../../../../utils/authentication";
import { LayerId } from "../../../../../utils/mapbox/mapStyleProperties/mapStyleProperties";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import { TooltipType } from "../../../../sharedComponents/Tooltip/config";
import AllowedUsesFiltersContainer from "./AllowedUsesFiltersContainer";
import config from "./config";
import OwnerAddressFiltersContainer from "./OwnerAddressFiltersContainer";
import OwnerNameFiltersContainer from "./OwnerNameFiltersContainer";
import SearchEngineHeader from "./SearchEngineHeader";
import SearchRow from "./SearchRow";
import ZoningFiltersContainer from "./ZoningFiltersContainer";
import LandUseFiltersContainer from "./LandUseFiltersContainer";

const mapStateToProps = (state) => {
  return {
    displayableFilters: newDevelopmentSelectors.getDisplayableFilters(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
    searchResults: newDevelopmentSelectors.getSmartSearchResult(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  };
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateProps;

class SearchPanel extends React.Component<Props, {}> {
  /**
   * Display tooltip if user is not authenticated.
   */
  getTooltipType = (id) => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (userIsAuthenticated) return;
    if (id === FilterId.AreaPublished) return;
    return TooltipType.SmartSearch;
  };

  /**
   * Render search panel content.
   */
  renderContent = (displayableFilters) => (
    <>
      <ZoningFiltersContainer />
      <AllowedUsesFiltersContainer />
      <LandUseFiltersContainer />
      <OwnerNameFiltersContainer />
      <OwnerAddressFiltersContainer />

      <div className="count-wrapper section-divisor">
        <label>Refine Search</label>
      </div>

      {displayableFilters.map((filter) => (
        <ConfigurationHandler
          config={config[filter.id]}
          component={SearchRow}
          unitSystem={this.props.unitSystem}
          filter={filter}
          key={filter.id}
          dataTip={this.getTooltipType(filter.id)}
        />
      ))}
    </>
  );

  /**
   * Render missing data message.
   */
  renderMissingData = () => {
    return (
      <div className="missing-data-container">
        <div className="request-icon" />
        <p>
          Please zoom in to verify if we have data in this city. If we don’t have data and you’d like to request it,
          please click the button below.
        </p>
        <a href="http://bit.ly/2Eo90tz" target="_blank" rel="noopener noreferrer" className="request-button">
          Request Data
        </a>
      </div>
    );
  };

  render() {
    const { displayableFilters, layerConfigurations } = this.props;

    return (
      <div className="component--search-panel">
        <SearchEngineHeader />
        {layerConfigurations[LayerId.SmartSearchResultsFill].isActive
          ? displayableFilters.length === 0
            ? this.renderMissingData()
            : this.renderContent(displayableFilters)
          : null}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchPanel);
