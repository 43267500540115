import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../rootReducer";
import { AuthenticationState } from "./reducers";

/**
 * Select the whole authentication state.
 */
const getAuthenticationState = (state: AppState): AuthenticationState => {
  return state.authentication;
}

/**
 * Select a boolean indicating whether user authentication state has been initialized.
 */
const getAuthenticationIsInitialized = createSelector(
  getAuthenticationState,
  (authenticationState) => !(authenticationState.userIsAuthenticated === null)
)

/**
 * Get the userIsAuthenticated flag.
 */
const getUserIsAuthenticated = createSelector(
  getAuthenticationState,
  (authenticationState) => authenticationState.userIsAuthenticated
)

export default {
  getAuthenticationIsInitialized,
  getUserIsAuthenticated,
}
