import React from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";

const DemoButton: React.FC = () => {
  const user = authentication.getCurrentUser();
  const navigate = useNavigate();

  /**
   * Redirect home.
   */
  const redirectHome = () => {
    navigate(Path.Home);
  };

  if (user) return null;

  return (
    <div className="component--button demo" onClick={redirectHome}>
      <div className="button" />
      <div className="text">Demo</div>
    </div>
  );
};

export default DemoButton;
