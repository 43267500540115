/**
 * This utility function compares two arrays.
 * NOTE: This current implementation only works for scalar values.
 * Also assumes that both arrays have the same order. (i.e. [1,2,3] != [2,1,3])
 */
const equals = (array1: Array<any>, array2: Array<any>): boolean => {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

/**
 *
 * @param formattedValues
 * @returns styledArray
 */
const styleValuesForPresentation = (formattedValues) => {
  // Add an empty string (" ") after each element of the array.
  // This is done for presentation and a better user experience when reading the data.
  let index = 1
  let styledValues = [...formattedValues]
  let currentPosition = 0
  for (let i = 0; i < formattedValues.length - 1; i++) {
    currentPosition+=index
    styledValues.splice(currentPosition + i, 0, ' ')
  }
  return styledValues;
}

export default {
  equals,
  styleValuesForPresentation,
}
