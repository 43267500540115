import React, { ReactElement } from "react";

interface OwnProps {
  tabId: string;
  activeTab: string;
  label?: string;
  isDisabled?: boolean;
  onTabClick(tabId: string): void;
  button?: ReactElement;
}

type Props = OwnProps;

export class Tab extends React.PureComponent<Props, {}> {
  render() {
    const { onTabClick, label, tabId, activeTab, isDisabled, button } = this.props;
    const currentTabIsActive = (tabId === activeTab);
    const className = `component--tab ${currentTabIsActive ? "active" : ""} ${isDisabled ? "disabled" : ""}`;

    return (
      <div className={className} onClick={() => (!isDisabled && onTabClick(tabId))}>
        {button || label}
      </div>
    );
  }
}

export default Tab;
