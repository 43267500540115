import React from "react";
import log from "loglevel";
import { Map as MapboxMap } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import ReactMapboxGl, { GeoJSONLayer, Marker } from "react-mapbox-gl";
import Measure from "react-measure";
import { connect } from "react-redux";
import { developmentActions, developmentSelectors } from "../../../../state/development";
import { setbackModeActions, setbackModeSelectors } from "../../../../state/setbackMode";
import turf from "../../../../utils/turf";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";
import SetbackIcon from "./SetbackIcon";
import { MeasurementTool } from "types/MeasurementTools";
import { DrawingMode, hiddenMode } from "types/DrawingModes";
import Format from "types/Format";
import geometry from "utils/geometry";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import unitConversions from "utils/unitConversions";
import Unit from "types/Unit";
import valueFormatter from "utils/valueFormatter";
import { KeyCode } from "types/KeyCodes";
import SetbacksFloorArea from "../SetbacksFloorArea";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";

/**
 * @fileoverview This container wraps the Mapbox map for setback type selection and manages
 *  any required data and functionality.
 */

const PARCEL_SELECTION_ZOOM = 18;
const TOP_VIEW = 0;
const NORTH = 0;
const TOOL_TIP_OFFSET: [number, number] = [40, 30];

const MEASUREMENT_TOOL_TO_DRAWING_MODE = {
  [MeasurementTool.Measurement]: DrawingMode.DrawPolygon,
  [MeasurementTool.EditShape]: DrawingMode.SimpleSelect,
  [MeasurementTool.DeleteShape]: DrawingMode.SimpleSelect,
  [MeasurementTool.CopyShape]: DrawingMode.SimpleSelect,
  [MeasurementTool.SelectShape]: DrawingMode.SimpleSelect,
}

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  dragRotate: false,
  touchZoomRotate: false,
});

const mapStateToProps = (state) => {
  const setbackSchedule = developmentSelectors.getBuildingModel(state).setbackSchedule;
  const selectedSetbackFloorIndex = developmentSelectors.getSelectedSetbackFloorIndex(state);
  const floor = developmentSelectors.getSelectedSetbackFloor(state);
  const floorPlan = developmentSelectors.getFloorPlan(state, floor);

  return {
    setbackList: setbackSchedule[selectedSetbackFloorIndex].setbacks,
    footprint: setbackSchedule[selectedSetbackFloorIndex].footprint,
    parcelGeoJson: developmentSelectors.getParcel(state),
    parcelCenterOfMass: developmentSelectors.getParcelCenterOfMass(state),
    selectedSetbackFloor: developmentSelectors.getSelectedSetbackFloor(state),
    selectedPolygonIndex: setbackModeSelectors.getSelectedPolygonIndex(state),
    selectedEdgeIndex: setbackModeSelectors.getSelectedEdgeIndex(state),
    measurementTool: setbackModeSelectors.getMeasurementTool(state),
    measurementToolFromToolbar: setbackModeSelectors.getMeasurementToolFromToolbar(state),
    shapeIsBeingChanged: setbackModeSelectors.getShapeIsBeingChanged(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
    shapePerimeter: setbackModeSelectors.getShapePerimeter(state),
    shapeArea: setbackModeSelectors.getShapeArea(state),
    floor,
    floorPlan,
    areaLabelVisibility: setbackModeSelectors.getAreaLabelVisibility(state),
    setbackTypeMarkerVisibility: setbackModeSelectors.getSetbackTypeMarkerVisibility(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setIndexValues: setbackModeActions.setIndexValues,
  setShapeIsBeingChanged: setbackModeActions.setShapeIsBeingChanged,
  setShapeMeasurements: setbackModeActions.setShapeMeasurements,
  setFloorPlan: developmentActions.setFloorPlan,
  resetShapeMeasurements: setbackModeActions.resetShapeMeasurements,
  setMeasurementTool: setbackModeActions.setMeasurementTool,
}

interface OwnProps {
  setMap(map: MapboxMap);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

interface State {
  distanceToLastPolygonVertex: number | null;
  drawingMode: boolean;
  cursorCoordinates: [number, number];
}

class SetbackSelectionMap extends React.PureComponent<Props, State> {
  cameraZoom: [number];
  cameraPitch: [number];
  cameraBearing: [number];
  cameraCenter: [number, number];
  Mapbox: any;
  map: any;
  mapboxDraw: any;
  setModeToDrawingOnKeyDown: boolean;
  setbackCoordinates: any;
  setbackGeoJson: any;
  contextMenuClickPoint: [number, number] | null;
  shapeIdBeingEdited: string | null;
  shapePointToBeSelected: [number, number] | null;

  constructor(props) {
    super(props);

    this.cameraZoom = [PARCEL_SELECTION_ZOOM];
    this.cameraPitch = [TOP_VIEW];
    this.cameraBearing = [NORTH];
    this.cameraCenter = this.props.parcelCenterOfMass as [number, number];
    this.contextMenuClickPoint = null;
    this.shapeIdBeingEdited = null;
    this.shapePointToBeSelected = null;

    this.state = {
      distanceToLastPolygonVertex: null,
      drawingMode: false,
      cursorCoordinates: [0, 0],
    }
    this.setModeToDrawingOnKeyDown = false;

  }

  /**
   * Attach the map drawing object to the map and set its event listeners.
   */
  attachMapDraw = () => {
    if (!this.mapboxDraw) {
      this.mapboxDraw = new MapboxDraw({
        displayControlsDefault: false,
        styles: mapboxPresentationProperties.drawingShapeStyle,
        modes: Object.assign({ [DrawingMode.HiddenMode]: hiddenMode }, MapboxDraw.modes),
        defaultMode: DrawingMode.HiddenMode
      });

      this.map.addControl(this.mapboxDraw);
    }

    if(this.props.floorPlan[0]){
      this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
      Object.values(this.props.floorPlan[0]).forEach((currentFeature) => this.mapboxDraw.add(currentFeature));
    }
    this.cleanDrawnShapes(this.mapboxDraw.getAll().features);
    this.updateDrawnShapes();

    // Handle changes on the drawing mode.
    this.map.on("draw.modechange",
      () => {
        this.cleanEditedShape();

        if (this.props.measurementTool === MeasurementTool.Measurement) {
          if (this.contextMenuClickPoint || this.setModeToDrawingOnKeyDown) {
            this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
            this.setModeToDrawingOnKeyDown = false;
            this.contextMenuClickPoint = null;
          }

          this.setState({ distanceToLastPolygonVertex: null });
        } else if((this.props.measurementTool === MeasurementTool.EditShape || this.props.measurementTool === MeasurementTool.SelectShape) && this.contextMenuClickPoint){
          this.selectShapeToEdit(this.contextMenuClickPoint);
          this.contextMenuClickPoint = null;
        }
      }
    );

    // Handle update to drawn shapes.
    this.map.on("draw.update", () => this.shapeIdBeingEdited = this.mapboxDraw.getSelectedIds()[0]);

    // Handle drawn shape creation.
    this.map.on("draw.create", (data) => {
      this.cleanDrawnShapes(data.features);
      this.updateDrawnShapes();

      const features = this.mapboxDraw.getAll().features;

      if (features.length === 0) return;

      const lastFeature = features[features.length - 1];
      this.setShapePointToBeSelected(lastFeature);
      this.props.resetShapeMeasurements();
    });

    // Handle changes on the drawing features currently selected.
    this.map.on("draw.selectionchange",
      (data) => {
        switch (this.props.measurementTool) {
          case MeasurementTool.EditShape:
            if (data.features.length === 0) {
              this.setState({ distanceToLastPolygonVertex: null });
            } else if (this.mapboxDraw.getMode() === DrawingMode.DirectSelect) {
              this.updateShapeInformation();
              this.props.setShapeIsBeingChanged(true);
            }
            break;
        }
      }
    );
  }

  componentDidUpdate(previousProps: Props) {
    if (!this.mapboxDraw) return;

    // handle when user changes the floor
    if(previousProps.selectedSetbackFloor !== this.props.selectedSetbackFloor){

      //this line handles the corner case when users are drawing and they change floors without finishing the draw.
      if(this.props.measurementTool === MeasurementTool.Measurement && this.state.distanceToLastPolygonVertex !== null){
        this.setState({distanceToLastPolygonVertex: null})
      }

      this.mapboxDraw.deleteAll();
      if(this.props.floorPlan.length > 0){
        this.setDrawnShapesInFloor();
        this.cleanDrawnShapes(this.mapboxDraw.getAll().features);
        this.updateDrawnShapes();
      }
      if( this.props.measurementTool === MeasurementTool.Measurement){
        this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
      }
      return;
    }

    if (previousProps.measurementToolFromToolbar === this.props.measurementToolFromToolbar) return;
    this.resetDrawingInformation();

    // Temporarily change mode to `DrawPolygon` to force finalization of unfinished shapes being drawn.
    this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
    this.cleanDrawnShapes(this.mapboxDraw.getAll().features);
    this.updateDrawnShapes();

    if (!this.state.drawingMode) {
      this.setState({ drawingMode: true });
    }

    this.mapboxDraw.changeMode(MEASUREMENT_TOOL_TO_DRAWING_MODE[this.props.measurementToolFromToolbar]);
  }

  /**
   * Handle click on the map depending on measurement tool.
   */
  handleMapClick = (map, event) => {
    if (!this.map) return;
    if (event.type === "contextmenu") {
      this.contextMenuClickPoint = (this.props.measurementTool === MeasurementTool.Measurement) || (this.props.measurementTool === MeasurementTool.EditShape) || (this.props.measurementTool === MeasurementTool.SelectShape)
        ? event.point
        : null;
    }

    switch (this.props.measurementTool) {
      case MeasurementTool.Measurement:
        this.deselectEdges();
        this.handleDrawingShapeClick(event.point);
        break;
      case MeasurementTool.DeleteShape:
        this.deselectEdges();
        this.deleteShapeAtScreenPoint(event.point)
        break;
      case MeasurementTool.EditShape:
        this.deselectEdges();
        this.selectShapeToEdit(event.point)
        break;
      case MeasurementTool.SelectShape:
      case MeasurementTool.CopyShape:
        this.deselectEdges();
        this.selectShapeToCopyOrDrag(event.point)
        break;
    }
  }

  /**
   * Handle mouse move over the map depending on map mode.
   */
  handleMouseMove = (map, event) => {
    if (!this.map || this.map.isMoving()) return;

    switch (this.props.measurementTool) {
      case MeasurementTool.Measurement:
        if (this.props.shapeIsBeingChanged) {
          this.updateCursorCoordinates(event.point);
          this.updateShapeInformation();
        }
        break;
      case MeasurementTool.EditShape:
        if (this.props.shapeIsBeingChanged) {
          this.updateShapeInformation();
          this.updateDrawnShapes();
        }
        break;
      case MeasurementTool.SelectShape:
      case MeasurementTool.CopyShape:
        this.updateShapeInformation();
        this.updateDrawnShapes();
        break;
      default:
        break;
    }
  }

    /**
   * Handle key down when map is on measiurement shape mode.
   */
     handleKeyDown = (event) => {
      switch (event.key) {
        case KeyCode.Enter:
        case KeyCode.Esc:
          if (this.props.measurementTool === MeasurementTool.Measurement) this.setModeToDrawingOnKeyDown = true;
          this.props.resetShapeMeasurements();
          break;
        case KeyCode.Delete:
          if (this.props.measurementTool === MeasurementTool.EditShape) {
            this.contextMenuClickPoint = null;
            this.mapboxDraw.trash();
          }
          break;
      }
    }

  /**
   * Update the polygon of information, specifically:
   *
   * - Area.
   * - Perimeter.
   * - Distance to last point added (if we are in measurement mode).
   */
  updateShapeInformation = () => {
  if (this.props.measurementTool === MeasurementTool.Measurement && this.props.shapeIsBeingChanged) {
    let features = this.mapboxDraw.getAll().features;

    if (!features || features.length === 0) return;

    let currentFeature = features[features.length - 1];
    try {
      let ring = turf.getCoords(currentFeature)[0];
      let currentPoint = ring[ring.length - 2];
      let lastPolygonPoint = ring[ring.length - 3];
      this.setState({
        distanceToLastPolygonVertex: unitConversions.convert(turf.distance(currentPoint, lastPolygonPoint), Unit.Type.KiloMeters, Unit.Type.Meters)
      });

      this.props.setShapeMeasurements(currentFeature);
    } catch (error) {
      log.warn(error);
    }
  } else if ((this.props.measurementTool === MeasurementTool.EditShape) || (this.props.measurementTool === MeasurementTool.SelectShape) || (this.props.measurementTool === MeasurementTool.CopyShape) ) {
      let features = this.mapboxDraw.getSelected().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[0];
      this.props.setShapeMeasurements(currentFeature);
  }
}

  /**
   * Copy or Drag drawn shapes.
   */
  selectShapeToCopyOrDrag = (point) => {
    if (!this.mapboxDraw || this.mapboxDraw.getMode() === DrawingMode.DirectSelect) return;
    let clickedFeatureIds = this.mapboxDraw.getFeatureIdsAt(point);
    let idOfFeatureToCopy = clickedFeatureIds[0];

    if (idOfFeatureToCopy) {
      let feature = this.mapboxDraw.getAll().features.reduce((obj, feature) => {
        if (feature.id === idOfFeatureToCopy) return feature;
        return obj;
      }, null)

      if (!feature) return;

      if(this.props.measurementTool === MeasurementTool.CopyShape){
        let featureClone = geometry.clone(feature, 0.01);
        this.mapboxDraw.add(featureClone);
        const featureId = featureClone.properties[MapStyleProperties.FloorPlanFields.FloorPlanShapeId];
        this.mapboxDraw.changeMode(DrawingMode.DirectSelect, { featureId });
      } else if( this.props.measurementTool === MeasurementTool.SelectShape ) {
        const featureId = feature.properties[MapStyleProperties.FloorPlanFields.FloorPlanShapeId];
        this.mapboxDraw.changeMode(DrawingMode.DirectSelect, { featureId });
      }
    }
    this.updateDrawnShapes();
  }

/**
 * Clean drawn shapes of shape being edited.
 */
  cleanEditedShape = () => {
    if (this.shapeIdBeingEdited) {
      const editedFeature = this.mapboxDraw.get(this.shapeIdBeingEdited);
      if (editedFeature) this.cleanDrawnShapes([editedFeature]);
      this.shapeIdBeingEdited = null;
    }
  }

  /**
   * Clean drawn shapes
   */
  cleanDrawnShapes = (features) => {
    if (features.length > 0) {
      this.deleteDrawnShapes(features);
      // This line is necessary because for some reason the mapboxDraw is initialized with a feature
      // which contains a bad geometry, so this line will clean it up and delete it.
      features = features.filter((feature) => feature.geometry.coordinates[0].every(Boolean));

      let cleanFeatures = geometry.cleanDrawnPolygons(features);
      cleanFeatures.forEach((feature) => this.mapboxDraw.add(feature));
    }
  }

  /**
   * Set DrawnShapes in the current floor.
   */
  setDrawnShapesInFloor = () => {
    if(this.props.floorPlan[0]){
      this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
      Object.values(this.props.floorPlan[0]).forEach((currentFeature) => this.mapboxDraw.add(currentFeature));
    }
  }

  /**
   * Delete drawn shapes.
   */
  deleteDrawnShapes = (features) => {
    if (features.length > 0) {
      let featureIds = features.map((feature) => feature.id);
      this.mapboxDraw.delete(featureIds);
    }
    this.updateDrawnShapes();
  }

  /**
   * Delete drawn shape at screen point.
   */
  deleteShapeAtScreenPoint = (point) => {
    if (!this.mapboxDraw || this.mapboxDraw.getMode() === DrawingMode.DirectSelect) return;

    let clickedFeatureIds = this.mapboxDraw.getFeatureIdsAt(point);
    this.mapboxDraw.delete(clickedFeatureIds[0]);
    this.updateDrawnShapes();
  }

  /**
   * Select the shape to be edited.
   */
  selectShapeToEdit = (point) => {
    if (!this.mapboxDraw) return;

    let clickedFeatureIds = this.mapboxDraw.getFeatureIdsAt(point);

    if (clickedFeatureIds.length > 0) {
      for (const featureId of clickedFeatureIds) {
        if (featureId) {
          this.mapboxDraw.changeMode(DrawingMode.DirectSelect, { featureId });
          break;
        }
      }
    }
    this.updateDrawnShapes();
  }

  /**
   * Update the state with the current drawn shapes.
   */
  updateDrawnShapes = () => {
    const { floor } = this.props;
    const features = this.mapboxDraw.getAll().features;

    this.props.setFloorPlan(features, floor);
  }

  /**
   * Set shape point to be selected or deleted.
   */
  setShapePointToBeSelected = (feature) => {
    const center = turf.getCoord(turf.center(feature));
    this.shapePointToBeSelected = this.map.project(center);
  }

  /**
   * Reset drawing information about the shape being drawn or edited.
   */
  resetDrawingInformation = () => {
    this.props.resetShapeMeasurements();
    this.setState({ distanceToLastPolygonVertex: null });
  }

  /**
   * Add identifying icons/markers to the middle point of each segment of external rings of the parcel polygon(s).
   */
  getMarkers = () => {
    let coordinates = turf.getCoords(this.props.parcelGeoJson);

    if (turf.getType(this.props.parcelGeoJson) === "Polygon") {
      return this.getPolygonMarkers(0, coordinates[0]);
    } else { // geometryType === "MultiPolygon"
      return (
        coordinates
          .map((polygon) => polygon[0])
          .map(
            (polygonCoordinates, polygonIndex) => {
              return this.getPolygonMarkers(polygonIndex, polygonCoordinates);
            }
          )
      );
    }
  }

   /**
   * Add Area label to the middle of the parcel polygon.
   */
  getArea = () => {
    const center = turf.getCoords(this.props.parcelCenterOfMass)

          return (
            <Marker
              key="setbackAreaMarker"
              coordinates={center}
              anchor="center"
            >
              <SetbacksFloorArea />
            </Marker>
          );
        }

  /**
   * Add identifying icons/markers to the middle point of each segment of external rings of an individual polygon.
   */
  getPolygonMarkers = (polygonIndex, coordinates) => {
    const { selectedPolygonIndex, selectedEdgeIndex } = this.props;

    return (
      coordinates.slice(0, -1).map(
        (point, edgeIndex, array) => {
          let nextPoint = array[(edgeIndex + 1) % array.length];
          let midPoint = turf.getCoords(turf.midpoint(point, nextPoint));
          return (
            <Marker
              key={`setbackMarker_${polygonIndex}_${edgeIndex}`}
              coordinates={midPoint}
              anchor="center"
              onClick={() => this.props.setIndexValues(polygonIndex, edgeIndex)}
            >
              <SetbackIcon
                setbackType={this.props.setbackList[polygonIndex][edgeIndex]}
                selected={selectedPolygonIndex === polygonIndex && selectedEdgeIndex === edgeIndex}
              />
            </Marker>
          );
        }
      )
    );
  }

  /**
   * Deactivate all edges from editing mode.
   */
  deselectEdges = () => {
    this.props.setIndexValues(-1, -1);
  }

  /**
   * Initialize the shape being drawn.
   */
  initializeShapeIsBeingDrawn = () => {
    this.props.setShapeIsBeingChanged(true);
    this.setState({ distanceToLastPolygonVertex: 0 });
  }

  /**
   * Handle click when the map is on draw shape mode.
   */
  handleDrawingShapeClick = (point) => {
    if (!this.mapboxDraw) return;

    if (this.mapboxDraw.getMode() !== DrawingMode.DrawPolygon) {
      this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
    } else if (!this.props.shapeIsBeingChanged) {
      this.initializeShapeIsBeingDrawn();
    }

    this.updateCursorCoordinates(point);
  }

  /**
   * Set cursor coordinates from map point.
   */
  updateCursorCoordinates = (point) => {
    let cursorCoordinates = this.map.unproject(point);
    this.setState({ cursorCoordinates: [cursorCoordinates.lng, cursorCoordinates.lat] });
  }

  /**
   * Render a tool tip next to the cursor showing of the distance to the last point added to the shape.
   */
  renderToolTip = (): any => {
    if (this.state.distanceToLastPolygonVertex === null) return;

    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.Meters, " m"] : [Unit.Type.Feet, " FT"];
    let convertedValue = unitConversions.convertFromBase(this.state.distanceToLastPolygonVertex, unitTarget);

    let formattedValue = valueFormatter.format(convertedValue, { type: Format.Type.Number, suffix: suffix });
    return (
      <Marker coordinates={this.state.cursorCoordinates} offset={TOOL_TIP_OFFSET}>
        <div className="tool-tip">
          {formattedValue}
        </div>
      </Marker>
    );
  }

  /**
   * Conditionally render the parcel and setback polygons.
   */
  renderSetback = () => {
    if (!this.props.footprint || this.props.footprint.area === 0) {
      return (
        <div>
          <GeoJSONLayer
            data={this.props.parcelGeoJson}
            fillPaint={mapboxPresentationProperties.setbackParcelFillPaint}
            linePaint={mapboxPresentationProperties.setbackLinePaint}
          />
        </div>
      );
    }

    return (
      <div>
        <GeoJSONLayer
          data={this.props.parcelGeoJson}
          fillPaint={mapboxPresentationProperties.setbackParcelFillPaint}
          linePaint={mapboxPresentationProperties.setbackLinePaint}
        />

        <GeoJSONLayer
          data={this.props.footprint.polygons}
          fillPaint={mapboxPresentationProperties.setbackBuildingFillPaint}
          linePaint={mapboxPresentationProperties.setbackLinePaint}
        />
      </div>
    );
  }

  resizeMap = () => {
    if (this.map) {
      this.map.resize();
    }
  }

  /**
   * Handle map style load.
   */
  handleStyleLoad = (map: MapboxMap) => {
    this.map = map;
    if (this.props.setMap) this.props.setMap(map);
    this.attachMapDraw();
  }

  render() {
    const {parcelGeoJson, areaLabelVisibility, setbackTypeMarkerVisibility, tier } = this.props;
    const isOneParcel = parcelGeoJson.geometry.coordinates.length === 1;
    const showAreaLabel = areaLabelVisibility && isOneParcel;
    const isDeveloperTier = tier === Tier.Developer;
    const mapStyleUrl = isDeveloperTier ? MapStyleProperties.DeveloperTierSetbackModeMap.SetbackMapUrl : MapStyleProperties.SetbackMode.SetbackMapUrl;
    return (
      <Measure onResize={this.resizeMap} >
        {
          ({ measureRef }) =>
            <div ref={measureRef} className="component--map" onKeyDown={this.handleKeyDown}>
              <Mapbox
                // eslint-disable-next-line
                style={mapStyleUrl}
                center={this.cameraCenter}
                zoom={this.cameraZoom}
                pitch={this.cameraPitch}
                bearing={this.cameraBearing}
                onStyleLoad={this.handleStyleLoad}
                onMouseMove={this.handleMouseMove}
                onClick={this.handleMapClick}
                onContextMenu={this.handleMapClick}
                containerStyle={{
                  height: "100%",
                  width: "100%",
                  margin: "0 auto"
                }}
                movingMethod="jumpTo"
              >
                {showAreaLabel && this.getArea()}
                {this.renderSetback()}
                {setbackTypeMarkerVisibility && this.getMarkers()}
                {this.renderToolTip()}
              </Mapbox>
            </div>
        }
      </Measure>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetbackSelectionMap);
