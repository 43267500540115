import React from "react";
import Unit from "types/Unit";
import Pdf from "types/Pdf";
import { Tier } from "types/Tier";

export type PdfContextValue = {
  development: any;
  mapImages: Object;
  coverImages: Object;
  graphImages?: Pdf.GraphImageUrls;
  unitSystem: Unit.System;
  title?: string;
  summary?: string;
  fromContactDetails: Pdf.ContactDetails;
  toContactDetails: Pdf.ContactDetails;
  colorPalette: {
    primary: string;
    secondary: string;
    text: string;
  };
  address: Pdf.Address;
  projectIsForSale: boolean;
  tier?: Tier;
}

const PdfContext = React.createContext<PdfContextValue>({
  development: {},
  mapImages: {},
  coverImages: {},
  unitSystem: Unit.System.Imperial,
  colorPalette: {
    primary: "",
    secondary: "",
    text: "",
  },
  fromContactDetails: {},
  toContactDetails: {},
  address: {},
  projectIsForSale: false,
});

export default PdfContext;
