import { useEffect } from "react";
import { Map } from "mapbox-gl";
import { getCameraFromUrl } from "../Helpers/NewprojectMapHelpers";
import { Props } from "../NewProjectMap";
import { deepClone } from "./hooksUtils";

export const useMapPositionEffect = (
  map: React.MutableRefObject<Map | undefined>,
  props: Props,
  cameraString: string | undefined,
  prevProps: React.MutableRefObject<Props | undefined>,
  jumpToCoordinates: (center: any, customZoom?: number) => void,
  selectParcelIfAvailable: (position: [number, number]) => boolean
) => {
  useEffect(() => {
    if (!map.current) return;
    if (!prevProps.current) {
      prevProps.current = props;
      return;
    }
    const previousProps = deepClone(prevProps.current);
    prevProps.current = deepClone(props);

    const cameraFromUrl = getCameraFromUrl(cameraString);

    if (map.current && props.pinPosition && previousProps.pinPosition !== props.pinPosition) {
      if (!props.selectedFeature && !selectParcelIfAvailable(props.pinPosition)) {
        jumpToCoordinates(props.pinPosition);
      }
    } else if (previousProps.userLocation === undefined && props.userLocation && !cameraFromUrl) {
      jumpToCoordinates(props.userLocation);
    }
  }, [map.current, props.pinPosition, props.selectedFeature, props.userLocation]);
};
