import numeral from "numeral";

/**
 * Get a date combination given a unix timestamp.
 */
const getDate = (timeStamp: number | undefined) => {
  if (!timeStamp) return "";

  const date = new Date(timeStamp);
  const day = numeral(date.getDate()).format('00');
  const month = numeral(date.getMonth() + 1).format('00');
  const year = date.getFullYear();
  return `${month} / ${day} / ${year}`;
}

export default {
  getDate,
}
