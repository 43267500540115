import React from "react";
import DashboardButton from "../Buttons/DashboardButton";
import LogInButton from "../Buttons/LogInButton";
import LogOutButton from "../Buttons/LogOutButton";
import SignUpButton from "../Buttons/SignUpButton";

class AuthenticationButtons extends React.PureComponent<{}, {}> {

  render() {

    return (
      <div className="component--authentication-buttons">
        <LogInButton />
        <SignUpButton />
        <DashboardButton />
        <LogOutButton />
      </div>
    );
  }
}

export default AuthenticationButtons;
