import { colorFor } from "./colors";

const searchResultsFillPaint = {
  "fill-color": colorFor("smart-search-fill"),
  "fill-opacity": 0.65,
}

const searchResultsLinePaint = {
  "line-color": colorFor("smart-search-fill"),
  "line-width": 2.0
}

const parcelHoverFillPaint = {
  "fill-color": colorFor("parcel-hover-fill"),
  "fill-opacity": 0.6,
}

const parcelHoverLinePaint = {
  "line-color": colorFor("parcel-hover-line"),
  "line-width": 2.0
}

const graphFeatureHoverLinePaint = {
  "line-color": colorFor("graph-hovered-feature"),
  "line-width": 2.0,
  "line-opacity": 0.8,
}

const graphFeatureHoverFillPaint = {
  "fill-color": colorFor("graph-hovered-feature"),
  "fill-opacity": 0.6,
}

const parcelSelectFillPaint = {
  "fill-color": colorFor("parcel-select-fill"),
  "fill-opacity": 0.8,
}

const parcelSelectPdfFillPaint = {
  "fill-color": colorFor("parcel-select-fill"),
  "fill-opacity": 0.5,
}

const parcelSelectLinePaint = {
  "line-color": colorFor("parcel-select-line"),
  "line-width": 4.0
}

const setbackParcelFillPaint = {
  "fill-color": colorFor("setback-selection-parcel-fill"),
  "fill-opacity": 1.0,
}

const setbackBuildingFillPaint = {
  "fill-color": colorFor("setback-selection-fill"),
  "fill-opacity": 1.0,
}

const setbackLinePaint = {
  "line-color": colorFor("setback-selection-line"),
  "line-width": 4.0,
}

const buildingFillExtrusionPaint = {
  "fill-extrusion-color": {
    type: "identity",
    property: "color"
  },
  "fill-extrusion-height": {
    type: "identity",
    property: "height"
  },
  "fill-extrusion-base": {
    type: "identity",
    property: "base_height"
  },
  "fill-extrusion-opacity": 1.0,
  "fill-extrusion-vertical-gradient": false,
};

const parcelFootprintFillPaint = {
  "fill-color": colorFor("parcel-footprint"),
  "fill-opacity": 1.0,
};

const parcelFootprintLinePaint = {
  "line-blur": 1.0,
  "line-color": colorFor("parcel-footprint-line"),
  "line-dasharray": [3, 1, 1, 1],
  "line-width": 4,
};

const mapPinLayout = {
  "icon-image": "pin-map",
  "icon-anchor": "bottom"
}

const mapSmartSearchLayout = {
  "icon-image": "pin-map",
  "icon-anchor": "bottom",
  "icon-allow-overlap": true,
}

const drawnParcelFillPaint = {
  "fill-color": colorFor("drawn-parcel-fill"),
  "fill-opacity": 0.7
};

const drawnParcelLinePaint = {
  "line-color": colorFor("drawn-parcel-line"),
  "line-width": 2
};

const drawingPolygonStyle = [
  {
    "id": "fill",
    "type": "fill",
    "filter": [
      "any",
      ["!=", "mode", "draw_polygon"],
      ["==", "active", "false"]
    ],
    "paint": {
      "fill-color": colorFor("drawing-parcel-fill"),
      "fill-opacity": 0.6
    }
  },
  {
    "id": "border",
    "type": "line",
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": colorFor("drawing-parcel-line"),
      "line-width": 2
    }
  },
  {
    "id": "vertex-halo",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 7,
      "circle-color": colorFor("drawing-parcel-vertex-halo")
    }
  },
  {
    "id": "vertex",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 3.5,
      "circle-color": colorFor("drawn-parcel-fill")
    }
  },
  {
    "id": "vertex-inactive",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      ["==", "$type", "Point"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 3.5,
      "circle-color": colorFor("drawing-parcel-vertex-inactive")
    }
  },
  {
    "id": "midpoint-halo",
    "type": "circle",
    "filter": ["==", "meta", "midpoint"],
    "paint": {
      "circle-radius": 5,
      "circle-color": colorFor("drawing-parcel-line")
    }
  },
  {
    "id": "midpoint",
    "type": "circle",
    "filter": ["==", "meta", "midpoint"],
    "paint": {
      "circle-radius": 2.5,
      "circle-color": colorFor("drawn-parcel-fill")
    }
  },
];

const drawingShapeStyle = [
  {
    "id": "fill",
    "type": "fill",
    "filter": [
      "any",
      ["!=", "mode", "draw_polygon"],
      ["==", "active", "false"]
    ],
    "paint": {
      "fill-color": colorFor("drawing-parcel-fill"),
      "fill-opacity": 0.4
    }
  },
  {
    "id": "border",
    "type": "line",
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": colorFor("drawing-setbacks-line"),
      "line-width": 3
    }
  },
  {
    "id": "vertex-halo",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 9,
      "circle-color": colorFor("drawing-parcel-vertex-halo")
    }
  },
  {
    "id": "vertex",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 7,
      "circle-color": colorFor("drawn-parcel-fill")
    }
  },
  {
    "id": "vertex-inactive",
    "type": "circle",
    "filter": [
      "all",
      ["==", "active", "true"],
      ["==", "$type", "Point"],
      [
        "any",
        ["==", "mode", "draw_polygon"],
        ["==", "mode", "direct_select"],
        ["==", "meta", "vertex"],
      ]
    ],
    "paint": {
      "circle-radius": 3.5,
      "circle-color": colorFor("drawing-parcel-vertex-inactive")
    }
  },
  {
    "id": "midpoint-halo",
    "type": "circle",
    "filter": ["==", "meta", "midpoint"],
    "paint": {
      "circle-radius": 8,
      "circle-color": colorFor("drawing-parcel-line")
    }
  },
  {
    "id": "midpoint",
    "type": "circle",
    "filter": ["==", "meta", "midpoint"],
    "paint": {
      "circle-radius": 5,
      "circle-color": colorFor("drawn-parcel-fill")
    }
  },
];

/**
 * Returns line layout.
 *
 * @param fieldId - Map layer field raw ID
 */
const demographicsLinePaint = (fieldId) => ({
  "line-opacity": [
    "case",
    [
      "has",
      fieldId,
    ],
    [
      "match",
      [
        "get",
        fieldId,
      ],
      0, // If fieldId property == 0
      0, // then opacity: 0
      1  // else opacity: 1
    ],
    0 // If fieldId does not exist on properties => opacity: 0
  ],
  "line-color": colorFor("rent-layers-border"),
  "line-width": [
    "interpolate",
    ["exponential", 0.71],
    ["zoom"],
    11,
    0.5,
    20,
    1.5
  ],
})

const rentLayersLinePaint = {
  "line-opacity": 1,
  "line-color": colorFor("rent-layers-border"),
  "line-width": [
    "interpolate",
    ["exponential", 0.71],
    ["zoom"],
    11,
    0.5,
    20,
    1.5
  ],
}

/**
 * Returns Symbol layout for the labels.
 *
 * @param fieldId - Map layer field raw ID
 * @param options - Optional options for the label.
 * @param options.prefix - Prefix for the label.
 * @param options.suffix - Suffix for the label.
 */
const rentLabelsSymbolLayout = (fieldId, options?: { prefix?: string, suffix?: string }) => ({
  "text-field": [
    "concat",
    options?.prefix || "",
    [
      "number-format",
      [
        "get", fieldId
      ],
      { "max-fraction-digits": 2 }
    ],
    options?.suffix || "",
  ],
  "text-font": [
    "Roboto Condensed Bold",
    "Arial Unicode MS Regular"
  ],
  "text-size": [
    "interpolate",
    ["exponential", 0.4],
    ["zoom"],
    13,
    14,
    22,
    17
  ],
  "text-allow-overlap": true,
})

/**
 * Returns Symbol layout for the labels.
 *
 * @param fieldId - Map layer field raw ID
 * @param options - Optional options for the label.
 * @param options.prefix - Prefix for the label.
 * @param options.suffix - Suffix for the label.
 */
const demographicsLabelsSymbolLayout = (fieldId, options?: { prefix?: string, suffix?: string }) => ({
  "text-field": [
    "case",
    [
      ">",
      [
        "get",
        fieldId
      ],
      0
    ],
    [
      "concat",
      options?.prefix || "",
      [
        "number-format",
        [
          "get", fieldId
        ],
        { "max-fraction-digits": 2 }
      ],
      options?.suffix || "",
    ],
    ""
  ],
  "text-font": [
    "Roboto Condensed Bold",
    "Arial Unicode MS Regular"
  ],
  "text-size": [
    "interpolate",
    ["exponential", 0.4],
    ["zoom"],
    13,
    14,
    22,
    17
  ],
  "text-allow-overlap": true,
})

const rentLayersSymbolPaint = {
  "text-opacity": 1,
}

export default {
  parcelHoverFillPaint,
  parcelHoverLinePaint,
  parcelSelectFillPaint,
  parcelSelectPdfFillPaint,
  parcelSelectLinePaint,
  setbackLinePaint,
  setbackParcelFillPaint,
  setbackBuildingFillPaint,
  buildingFillExtrusionPaint,
  parcelFootprintFillPaint,
  parcelFootprintLinePaint,
  mapPinLayout,
  drawingPolygonStyle,
  drawingShapeStyle,
  drawnParcelFillPaint,
  drawnParcelLinePaint,
  mapSmartSearchLayout,
  searchResultsFillPaint,
  searchResultsLinePaint,
  graphFeatureHoverLinePaint,
  graphFeatureHoverFillPaint,
  rentLayersLinePaint,
  demographicsLinePaint,
  rentLabelsSymbolLayout,
  demographicsLabelsSymbolLayout,
  rentLayersSymbolPaint,
}
