import { useDispatch, useSelector } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "state/newDevelopment";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import { TooltipType } from "views/sharedComponents/Tooltip/config";
import { Multiselect } from "multiselect-react-dropdown";

const STYLE = {
  chips: {
    background: "#3A4276",
    borderRadius: "0",
    textTransform: "capitalize",
  },
  searchBox: {
    border: "none",
    overflowX: "scroll",
  },
  multiselectContainer: {
    color: "FFFFFF",
  },
  optionContainer: {
    background: "#3A4276",
    color: "FFFFFF",
  },
  option: {
    color: "white",
    font: "Bold",
    textTransform: "capitalize",
  },
};

const LandUseFilters = () => {
  const dispatch = useDispatch();
  const enabledLandUseFilter = useSelector(newDevelopmentSelectors.getEnabledLandUseFilter);
  const disabledLandUseFilter = useSelector(newDevelopmentSelectors.getDisabledLandUseFilter);
  const tier = useSelector(subscriptionSelectors.getTier);
  const isDemoUser = tier === Tier.None;
  const dataTip = isDemoUser ? TooltipType.SmartSearch : null;

  return (
    <div className="component--land-use-filters">
      <div className="count-wrapper section-divisor">
        <label>Land Use</label>
      </div>

      <div className="land-use-dropdown-box" data-for="click-right" data-tip={dataTip}>
        <Multiselect
          options={disabledLandUseFilter}
          selectedValues={enabledLandUseFilter}
          onSelect={(selectedList, landUse) =>
            dispatch(newDevelopmentActions.updateLandUseFilterValues({ [landUse]: true }))
          }
          onRemove={(selectedList, landUse) =>
            dispatch(newDevelopmentActions.updateLandUseFilterValues({ [landUse]: false }))
          }
          isObject={false}
          placeholder="Click here to search for land use."
          style={STYLE}
          avoidHighlightFirstOption={true}
          closeIcon="cancel"
          id="allowed uses search input"
          disable={isDemoUser}
        />
      </div>
    </div>
  );
};

export default LandUseFilters;
