import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class SmartSearchResultsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.SmartSearchResults}
        classes="smart-search-results"
        legendRows={[
          <LegendRow
            color="#25F8C8"
            text="Search Engine Results"
          />,
        ]}
      />
    );
  }
}

export default SmartSearchResultsLegend;
