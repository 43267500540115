import React, { useEffect, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import log from "loglevel";
import { LngLatLike, Map, Point, LngLat } from "mapbox-gl";
import { useParams, useNavigate } from "react-router-dom";
import ReactMapboxGl, { Marker, RotationControl } from "react-mapbox-gl";
import { ConnectedProps, connect } from "react-redux";
import dynamicLayersConfig from "views/sharedComponents/DynamicLayerSelection/dynamicLayersConfig";
import { graphsActions } from "../../../../state/graphs";
import { layersActions } from "../../../../state/layers";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../state/newDevelopment";
import { subscriptionSelectors } from "../../../../state/subscription";
import { mapsActions, mapsSelectors } from "../../../../state/ui/shared/maps";
import { userSelectors } from "../../../../state/user";
import { DrawingMode, hiddenMode } from "../../../../types/DrawingModes";
import { GraphDataSources } from "../../../../types/Graph";
import { KeyCode } from "../../../../types/KeyCodes";
import { ParcelTool } from "../../../../types/ParcelTool";
import { Path } from "../../../../types/Path";
import { Tier } from "../../../../types/Tier";
import analytics from "../../../../utils/analytics";
import authentication from "../../../../utils/authentication";
import geometry from "../../../../utils/geometry";
import layerHelper, {
  DynamicLayerConfigurationsLayerIds,
  LayerConfigurationsLayerIds,
} from "../../../../utils/mapbox/layerHelper";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import {
  DynamicLayerId,
  LayerId,
  RawDynamicLayersFieldId,
  RawParcelFieldId,
} from "../../../../utils/mapbox/mapStyleProperties/mapStyleProperties";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import populateParcelProperties from "../../../../utils/parcel/populateParcelProperties";
import roundToDecimal from "../../../../utils/roundToDecimal";
import turf from "../../../../utils/turf";
import BusyPopup from "../../../sharedComponents/BusyPopup";
import { getRandomColor } from "../../../utils/colors";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";
import SmartSearchLayer from "./SmartSearchLayer/SmartSearchLayer";
import DrawingInformation from "./DrawingInformation/DrawingInformation";
import DrawnFeatureLayer from "./DrawnFeatureLayer/DrawnFeatureLayer";
import GraphHighlightedFeatureLayer from "./GraphHighlightedFeatureLayer/GraphHighlightedFeatureLayer";
import HoveredFeatureLayer from "./HoveredFeatureLayer/HoveredFeatureLayer";
import SelectedFeatureLayer from "./SelectedFeatureLayer/SelectedFeatureLayer";
import ParcelsZoningQueryFeatureLayer from "./ParcelsZoningQueryFeatureLayer/ParcelsZoningQueryFeatureLayer";
import unitConversions from "utils/unitConversions";
import Unit from "types/Unit";
import valueFormatter from "utils/valueFormatter";
import Format from "types/Format";
import DynamicPopulationLayer from "./DynamicMapLayers/DynamicPopulationLayer/DynamicPopulationLayer";
import DynamicMedianRentLayer from "./DynamicMapLayers/DynamicMedianRentLayer/DynamicMedianRentLayer";
import DynamicEmploymentPerPopLayer from "./DynamicMapLayers/DynamicEmploymentPerPopLayer/DynamicEmploymentPerPopLayer";
import DynamicMedianIncomeLayer from "./DynamicMapLayers/DynamicMedianIncomeLayer/DynamicMedianIncomeLayer";
import DynamicSeaLevelRiseLayer from "./DynamicMapLayers/DynamicSeaLevelRiseLayer/DynamicSeaLevelRiseLayer";
import { useNewProjectMapConstructor } from "./NewProjectHooks/useNewProjectMapConstructor";
import { drawOnCreate } from "../utils/customMapHooks/drawOnCreate";
import { drawOnModeChange } from "../utils/customMapHooks/drawOnModeChange";
import { drawOnUpdate } from "../utils/customMapHooks/drawOnUpdate";
import { drawOnSelectionChange } from "../utils/customMapHooks/drawOnSelectionChange";
import HotelAverageDailyRateFeatureLayer from "./RentFeatureLayers/HotelAverageDailyRateFeatureLayer";
import IndustrialRateFeatureLayer from "./RentFeatureLayers/IndustrialRateFeatureLayer";
import MultifamilyRentFeatureLayer from "./RentFeatureLayers/MultifamilyRentFeatureLayer";
import OfficeRateFeatureLayer from "./RentFeatureLayers/OfficeRateFeatureLayer";
import RetailRateFeatureLayer from "./RentFeatureLayers/RetailRateFeatureLayer";
import { useQueryViewportEffect } from "./NewProjectHooks/useQueryViewportEffect";
import { useMapPositionEffect } from "./NewProjectHooks/useMapPositionEffect";
import { useLayerConfigurationsEffect } from "./NewProjectHooks/useLayerConfigurationsEffect";
import { getCameraFromUrl } from "./Helpers/NewprojectMapHelpers";
import ExistingBuildingLayer from "./ExistingBuildingLayer/ExistingBuildingLayer";
import { useProMapOnIdle } from "./NewProjectHooks/MapEvents/useProMapOnIdle";

/**
 * @fileoverview This container wraps the Mapbox map for new project creation and manages
 *  any required data and functionality.
 */

const CAMERA_INITIAL_PITCH: [number] = [0];
const CAMERA_INITIAL_BEARING: [number] = [0];
const TOOL_TIP_OFFSET: [number, number] = [40, 30];
const CAMERA_SELECT_PARCEL_ZOOM: number = 16.1;
const SMART_SEARCH_TIMEOUT = 2000; // 2 seconds
let smartSearchTimeoutId: any;

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  dragRotate: false,
  touchZoomRotate: false,
  boxZoom: false,
  transformRequest: (url, resourceType) => {
    const currentUser = authentication.getCurrentUser();
    if (currentUser) {
      const fbToken = authentication.getFirebaseId();
      if (
        resourceType === MapStyleProperties.MapResourceType.TileServer &&
        !url.includes(MapStyleProperties.BaseUrlsForRequest.BaseMapboxURL)
      ) {
        return {
          url,
          headers: { authHeader: fbToken },
        };
      } else {
        return undefined;
      }
    } else {
      //Remove the cache for demo users
      if (
        resourceType === MapStyleProperties.MapResourceType.TileServer &&
        !url.includes(MapStyleProperties.BaseUrlsForRequest.BaseMapboxURL)
      ) {
        return {
          url,
          headers: { "cache-control": "max-age=0" },
        };
      } else {
        return undefined;
      }
    }
  },
});

const VISIBLE_LAYERS: Array<LayerConfigurationsLayerIds> = [
  MapStyleProperties.LayerId.UsaDemographicsQuery,
  MapStyleProperties.LayerId.UsaParcelsQuery,
  MapStyleProperties.LayerId.UsaParcelsSymbol,
  MapStyleProperties.LayerId.UsaParcelsBorder,
  MapStyleProperties.LayerId.UsaParcelsZoningQuery,
  MapStyleProperties.LayerId.UsaGraphsQuery,
  MapStyleProperties.LayerId.UsaBuildingsQuery,
  MapStyleProperties.LayerId.SeaLevelRiseQuery,
];

const DYNAMIC_VISIBLE_LAYERS: Array<DynamicLayerConfigurationsLayerIds> = [
  MapStyleProperties.LayerId.SeaLevelRiseQuery,
];

const PARCEL_TOOL_TO_DRAWING_MODE = {
  [ParcelTool.DrawParcel]: DrawingMode.DrawPolygon,
  [ParcelTool.EditParcel]: DrawingMode.SimpleSelect,
  [ParcelTool.DeleteParcel]: DrawingMode.SimpleSelect,
  [ParcelTool.CopyParcel]: DrawingMode.SimpleSelect,
};

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    parcelTool: newDevelopmentSelectors.getParcelTool(state),
    parcelToolFromToolbar: newDevelopmentSelectors.getParcelToolFromToolbar(state),
    polygonIsBeingChanged: newDevelopmentSelectors.getPolygonIsBeingChanged(state),
    drawnParcels: newDevelopmentSelectors.getDrawnParcels(state),
    userLocation: userSelectors.getLocation(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    selectedFeatureMembers: newDevelopmentSelectors.getSelectedFeatureMembers(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
    smartSearchIsOpen: newDevelopmentSelectors.getSmartSearchIsOpen(state),
    displayPin: newDevelopmentSelectors.getDisplayPin(state),
    mapStyleUrl: mapsSelectors.getMapStyleUrl(state),
    mapIsReady: mapsSelectors.getMapIsReady(state),
    queryViewport: newDevelopmentSelectors.getQueryViewport(state),
    tier: subscriptionSelectors.getTier(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
    styleChanged: mapsSelectors.getStyleChanged(state),
  };
};

const mapDispatchToProps = {
  setProximityCenter: newDevelopmentActions.setProximityCenter,
  combineParcelsStart: newDevelopmentActions.combineParcelsStart,
  selectParcelStart: newDevelopmentActions.selectParcelStart,
  hoverFeature: newDevelopmentActions.hoverFeature,
  setPolygonMeasurements: newDevelopmentActions.setPolygonMeasurements,
  resetPolygonMeasurements: newDevelopmentActions.resetPolygonMeasurements,
  setPolygonIsBeingChanged: newDevelopmentActions.setPolygonIsBeingChanged,
  clearFeatureSelection: newDevelopmentActions.clearFeatureSelection,
  setDrawnParcels: newDevelopmentActions.setDrawnParcels,
  setParcelsInViewport: newDevelopmentActions.setParcelsInViewport,
  setSmartSearchIsOpen: newDevelopmentActions.setSmartSearchIsOpen,
  setDisplayPin: newDevelopmentActions.setDisplayPin,
  setDataInViewport: newDevelopmentActions.setDataInViewport,
  setParcelTool: newDevelopmentActions.setParcelTool,
  initializeLayers: mapsActions.initializeLayers,
  initializeLayersOnStyleChange: mapsActions.initializeLayersOnStyleChange,
  setQueryViewport: newDevelopmentActions.setQueryViewport,
  updateGraphDataSources: graphsActions.updateGraphDataSources,
  setAvailableLayers: mapsActions.setAvailableLayers,
  setAvailableDynamicLayers: mapsActions.setAvailableDynamicLayers,
  resetDynamicLayers: mapsActions.resetDynamicLayers,
  generateRentFeaturesStart: layersActions.generateRentFeaturesStart,
};

interface OwnProps {
  slideshowDataIsReady: boolean;
  setMap(map: Map);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & OwnProps;

const NewProjectMap: React.FC<Props> = (props) => {
  // Authentication
  const userIsAuthenticated = authentication.isUserAuthenticated();

  // Routing
  const navigate = useNavigate();
  const { camera: cameraString } = useParams<{ camera: string | undefined }>();
  const cameraFromUrl = getCameraFromUrl(cameraString);
  const zoomFromUrl = cameraFromUrl ? cameraFromUrl.zoom : MapStyleProperties.camera.zoom;

  // Component State Management
  const [distanceToLastPolygonVertex, setDistanceToLastPolygonVertex] = useState<number | null>(null);
  const [drawingMode, setDrawingMode] = useState<boolean>(false);
  const [cameraCenter, setCameraCenter] = useState<[number, number]>(
    cameraFromUrl ? cameraFromUrl.center : MapStyleProperties.camera.center
  );
  const [cameraZoom, setCameraZoom] = useState<[number]>([zoomFromUrl]);
  const [cursorCoordinates, setCursorCoordinates] = useState<[number, number]>([0, 0]);
  const [mapIsLoaded, setMapIsLoaded] = useState<boolean>(false);
  const [boundingBox, setBoundingBox] = useState<[number, number, number, number] | undefined>(undefined);
  const [prevParcelTool, setPrevParcelTool] = useState<ParcelTool>(ParcelTool.SelectParcel);

  // Mutbale properties
  const map = useRef<Map | undefined>(undefined);
  const mapboxDraw = useRef<MapboxDraw | null>(null);
  const setModeToDrawingOnKeyDown = useRef<boolean>(false);
  const contextMenuClickPoint = useRef<[number, number] | null>(null);
  const parcelIdBeingEdited = useRef<string | null>(null);
  const parcelPointToBeSelected = useRef<Point | null>(null);
  const configurationsLayerIds = useRef<Array<MapStyleProperties.LayerId>>([]);
  const configurationsDynamicLayerIds = useRef<Array<MapStyleProperties.LayerId>>([]);
  const prevProps = useRef<Props | undefined>();

  // Props
  const {
    mapStyleUrl,
    queryViewport,
    slideshowDataIsReady,
    mapIsReady,
    layerConfigurations,
    dynamicLayerConfigurations,
    smartSearchIsOpen,
    tier,
    drawnParcels,
    parcelTool,
    polygonIsBeingChanged,
    selectedFeatureMembers,
    pinPosition,
    displayPin,
    unitSystem,
    parcelToolFromToolbar,
    selectedFeature,
    styleChanged,
    userLocation,
    setDisplayPin,
    setQueryViewport,
    setProximityCenter,
    selectParcelStart,
    combineParcelsStart,
    resetPolygonMeasurements,
    setDrawnParcels,
    setMap,
    setPolygonMeasurements,
    setPolygonIsBeingChanged,
    clearFeatureSelection,
    hoverFeature,
    setParcelTool,
    setParcelsInViewport,
    initializeLayers,
    resetDynamicLayers,
    setDataInViewport,
    updateGraphDataSources,
    setAvailableLayers,
    setAvailableDynamicLayers,
    initializeLayersOnStyleChange,
    setSmartSearchIsOpen,
    generateRentFeaturesStart,
  } = props;

  /** --------------------------------------------------------------
   *
   *
   *  Functions that need to be passed
   *  to the custom hooks.
   *
   *
   * -------------------------------------------------------------- /*

  /** Update proximity and camera zoom when camera stops moving. */
  const handleMoveEnd = () => {
    updateGeocoderProximity();

    if (
      layerConfigurations[MapStyleProperties.LayerId.SmartSearchResultsFill].isActive &&
      smartSearchIsOpen &&
      tier === Tier.Pro
    ) {
      // Timeout is needed to debounce the smart search allowing the user to pan and zoom without
      // constantly being interrupted by the search.
      smartSearchTimeoutId = setTimeout(() => {
        setQueryViewport(true);
      }, SMART_SEARCH_TIMEOUT);
    }
  };

  /** Jump to the selected parcel. */
  const jumpToCoordinates = (center, customZoom?: number) => {
    let useZoom;
    if (!customZoom) {
      const mapZoom = map.current ? map.current.getZoom() : 0;
      useZoom = Math.max(mapZoom, CAMERA_SELECT_PARCEL_ZOOM);
    } else {
      useZoom = customZoom;
    }
    setCameraCenter(center);
    setCameraZoom([useZoom]);
  };

  /** Select parcel if it exists at coordinates. */
  const selectParcelIfAvailable = (coordinates) => {
    if (!map.current) return false;
    const point = map.current.project(coordinates);
    let [selectedParcel, selectedCoordinates] = getParcel(point);
    if (selectedParcel && "type" in selectedParcel) {
      selectParcelStart(selectedParcel, selectedCoordinates as [number, number]);
      jumpToCoordinates(selectedCoordinates);
      return true;
    }

    return false;
  };

  /** Clean drawn parcels */
  const cleanDrawnParcels = (features) => {
    if (features.length > 0) {
      deleteDrawnParcels(features);
      // This line is necessary because for some reason the mapboxDraw is initialized with a feature
      // which contains a bad geometry, so this line will clean it up and delete it.
      features = features.filter((feature) => feature.geometry.coordinates[0].every(Boolean));

      let cleanFeatures = geometry.cleanDrawnPolygons(features);
      cleanFeatures.forEach((feature) => mapboxDraw.current.add(feature));
    }
  };

  /** Update the state with the current drawn features. */
  const updateDrawnParcels = () => {
    const features = mapboxDraw.current.getAll().features;
    setDrawnParcels(features);
  };

  /** Set parcel point to be selected or deleted. */
  const setParcelPointToBeSelected = (feature) => {
    if (!map.current) return;
    const center = turf.getCoord(turf.center(feature));
    parcelPointToBeSelected.current = map.current.project(center as LngLatLike);
  };

  /** Clean drawn parcels of parcel being edited. */
  const cleanEditedParcel = () => {
    if (!mapboxDraw.current) return;
    if (parcelIdBeingEdited.current) {
      const editedFeature = mapboxDraw.current.get(parcelIdBeingEdited.current);
      if (editedFeature) cleanDrawnParcels([editedFeature]);
      parcelIdBeingEdited.current = null;
    }
  };

  /** Select the parcel to be edited. */
  const selectParcelToEdit = (point) => {
    if (!mapboxDraw.current) return;

    let clickedFeatureIds = mapboxDraw.current.getFeatureIdsAt(point);

    if (clickedFeatureIds.length > 0) {
      for (const featureId of clickedFeatureIds) {
        if (featureId) {
          mapboxDraw.current.changeMode(DrawingMode.DirectSelect, {
            featureId,
          });
          break;
        }
      }
    }
  };

  /** Delete drawn parcels. */
  const deleteDrawnParcels = (features) => {
    if (features.length > 0) {
      let featureIds = features.map((feature) => feature.id);
      mapboxDraw.current.delete(featureIds);
    }
  };

  /** Update the polygon information, specifically:
   * - Area.
   * - Perimeter.
   * - Distance to last point added (if we are in draw parcel mode).
   */
  const updatePolygonInformation = () => {
    if (!mapboxDraw.current) return;
    if (parcelTool === ParcelTool.DrawParcel && polygonIsBeingChanged) {
      let features = mapboxDraw.current.getAll().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[features.length - 1];
      try {
        let ring = turf.getCoords(currentFeature)[0];

        let currentPoint = ring[ring.length - 2];
        let lastPolygonPoint = ring[ring.length - 3];

        const distance = turf.distance(currentPoint, lastPolygonPoint);
        const convertedValue = unitConversions.convert(distance, Unit.Type.KiloMeters, Unit.Type.Meters);
        setDistanceToLastPolygonVertex(convertedValue);

        setPolygonMeasurements(currentFeature);
      } catch (error) {
        log.warn(error);
      }
    } else if (mapboxDraw.current !== null && parcelTool === ParcelTool.EditParcel) {
      let features = mapboxDraw.current.getSelected().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[0];
      setPolygonMeasurements(currentFeature);
    }
  };

  /** Update the url. */
  const updateUrl = () => {
    if (!map.current) return;

    const zoom = roundToDecimal(map.current.getZoom(), 2);
    const center = map.current.getCenter().wrap();
    const centerCoordinates = [roundToDecimal(center.lng, 7), roundToDecimal(center.lat, 7)];

    const cameraString = centerCoordinates.join(",") + "," + zoom;
    navigate(`${Path.NewProject}/${cameraString}`, { replace: true });
  };

  /** Get the parcel at point position. */
  const getParcel = (point) => {
    if (!map.current) return [null, null];
    let selectedFeatureUnion = getCompleteParcelFeatureAtScreenPoint(point);

    if (selectedFeatureUnion === null) return [null, null];

    const latLngFromMap: LngLat = map.current.unproject(point);
    const clickedCoordinates = [latLngFromMap.lng, latLngFromMap.lat];

    return [selectedFeatureUnion, clickedCoordinates];
  };

  /** Check if there are visible features of the parcel and/or zoning layers in the viewport. */
  const checkDataInViewport = () => {
    if (!map.current) return;

    let parcelDataFeature = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
    })[0];

    let zoningDataFeature = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
    })[0];

    setDataInViewport(Boolean(parcelDataFeature), Boolean(zoningDataFeature));
    if (!mapIsLoaded) {
      if (Boolean(zoningDataFeature)) {
        analytics.trackMapLoadZoningData();
      } else if (Boolean(parcelDataFeature)) {
        analytics.trackMapLoadParcelData();
      } else {
        analytics.trackMapLoadDrawParcels();
      }
      setMapIsLoaded(true);
    }
  };

  /** Update graph data from features rendered in current viewport. */
  const updateGraphData = () => {
    if (!map.current) return;
    if (map.current.getZoom() < 15) {
      updateGraphDataSources({});
      return;
    }

    const graphFeatures = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaGraphsQuery],
    });

    const graphDataSources: GraphDataSources = {};
    graphFeatures.forEach((graphFeature) => {
      if (graphFeature.properties) {
        const geoId = graphFeature.properties[MapStyleProperties.RawGraphingFieldId.GeoId];

        let features = graphFeatures.filter((graphFeatureToFilter) => {
          if (graphFeatureToFilter.properties)
            graphFeatureToFilter.properties[MapStyleProperties.RawGraphingFieldId.GeoId] === geoId;
        });

        if (features.length > 1) {
          graphDataSources[geoId] = {
            color: getRandomColor(),
            feature: geometry.featuresUnion(features),
          };
        } else {
          graphDataSources[geoId] = {
            color: getRandomColor(),
            feature: graphFeature,
          };
        }
      }
    });

    updateGraphDataSources(graphDataSources);
  };

  /** Update available layers currently in the viewport. */
  const updateAvailableLayers = () => {
    if (map.current) {
      const availableLayers: Set<LayerConfigurationsLayerIds> = (
        map.current.queryRenderedFeatures(undefined, {
          layers: configurationsLayerIds.current.filter((layerId) => map.current && map.current.getLayer(layerId)),
        }) as Array<any>
      ).reduce((layerIdsSet, featureLayer) => {
        layerIdsSet.add(featureLayer.layer.id);

        return layerIdsSet;
      }, new Set());

      setAvailableLayers(availableLayers);
    }
  };

  /** Update available dynamic layers currently in the viewport. */
  const updateAvailableDynamicLayers = () => {
    if (!map.current) return;
    if (map.current.getZoom() < 10) {
      setAvailableDynamicLayers([]);
      return;
    }

    let dynamicLayerIds: Array<LayerId> = [];

    const seaLevelRiseFeatures = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.SeaLevelRiseQuery],
    });

    const demographicFeatures = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaGraphsQuery],
    });

    const isSeaLevelDataAvailable = layerHelper.isSeaLevelDataAvailable(seaLevelRiseFeatures);
    const isPopulationDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.Population2022,
      demographicFeatures
    );
    const isMedianRentDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.MedianGrossRentTotal2022,
      demographicFeatures
    );
    const isEmploymentDataAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
      demographicFeatures
    );
    const isMedianIncomeAvailable = layerHelper.isDataAvailable(
      RawDynamicLayersFieldId.MedianHouseholdIncome2022,
      demographicFeatures
    );

    if (isSeaLevelDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Sea_Level_Rise].layers);
    if (isPopulationDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Population].layers);
    if (isMedianRentDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_MedianRent].layers);
    if (isEmploymentDataAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(
        dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Employment_Per_Population].layers
      );
    if (isMedianIncomeAvailable)
      dynamicLayerIds = dynamicLayerIds.concat(
        dynamicLayersConfig[DynamicLayerId.Dynamic_Layer_Household_Income].layers
      );

    setAvailableDynamicLayers(dynamicLayerIds);
  };

  /** --------------------------------------------------------------
   *
   *
   *  Custom Hooks for the Component
   *
   *
   * -------------------------------------------------------------- /*

  /** Constructor */
  useNewProjectMapConstructor(
    cameraString,
    userLocation,
    setCameraCenter,
    setCameraZoom,
    setQueryViewport,
    setSmartSearchIsOpen,
    setDisplayPin,
    configurationsLayerIds,
    layerConfigurations,
    configurationsDynamicLayerIds,
    dynamicLayerConfigurations,
    setDrawnParcels,
    map,
    mapboxDraw,
    setModeToDrawingOnKeyDown,
    contextMenuClickPoint,
    parcelIdBeingEdited,
    parcelPointToBeSelected,
    prevProps
  );

  /** Logic from componentDidUpdate for layerConfigurations */
  useLayerConfigurationsEffect(props, prevProps, handleMoveEnd);

  /** Logic from componentDidUpdate for pinPosition, selectedFeature, userLocation, and getCameraFromUrl */
  useMapPositionEffect(map, props, cameraString, prevProps, jumpToCoordinates, selectParcelIfAvailable);

  /** Logic from componentDidUpdate for queryViewport and mapIsReady */
  useQueryViewportEffect(props, prevProps, map, setParcelsInViewport);

  /** Logic from componentDidUpdate for parcelToolFromToolbar */
  useEffect(() => {
    if (!prevProps.current) {
      prevProps.current = props;
      return;
    }

    if (prevParcelTool === parcelToolFromToolbar || !map.current) {
      return;
    }
    resetPolygonMeasurements();
    setDistanceToLastPolygonVertex(null);

    if (mapboxDraw && mapboxDraw.current) {
      // Temporarily change mode to `DrawPolygon` to force finalization of unfinished parcels being drawn.
      mapboxDraw.current.changeMode(DrawingMode.DrawPolygon);
      cleanDrawnParcels(mapboxDraw.current.getAll().features);

      switch (parcelToolFromToolbar) {
        case ParcelTool.EditParcel:
        case ParcelTool.DeleteParcel:
        case ParcelTool.CopyParcel:
        case ParcelTool.DrawParcel:
          if (parcelToolFromToolbar !== ParcelTool.DrawParcel) {
            setDrawnParcels([]);
            clearFeatureSelection();
          }

          if (!drawingMode) {
            setDrawingMode(true);
          }

          mapboxDraw.current.changeMode(PARCEL_TOOL_TO_DRAWING_MODE[props.parcelToolFromToolbar]);
          break;
        default: // Non drawing parcel tools.
          if (drawingMode) {
            updateDrawnParcels();
            mapboxDraw.current.changeMode(DrawingMode.HiddenMode);
            setDrawingMode(false);
          }
          break;
      }
    }

    setPrevParcelTool(parcelToolFromToolbar);
  }, [parcelToolFromToolbar, setParcelTool]);

  /**-------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * Map Event listeners and Drawing functionalities
   * -------------------------------------------------------------------------
   * -------------------------------------------------------------------------
   * ------------------------------------------------------------------------*/

  useProMapOnIdle(
    map,
    boundingBox,
    setBoundingBox,
    updateUrl,
    pinPosition,
    selectedFeature,
    smartSearchIsOpen,
    selectParcelIfAvailable,
    parcelPointToBeSelected,
    parcelTool,
    getParcel,
    combineParcelsStart,
    selectedFeatureMembers,
    checkDataInViewport,
    updateGraphData,
    generateRentFeaturesStart,
    styleChanged,
    updateAvailableLayers,
    updateAvailableDynamicLayers
  );

  drawOnCreate(
    map,
    mapboxDraw,
    parcelToolFromToolbar,
    cleanDrawnParcels,
    updateDrawnParcels,
    setParcelPointToBeSelected,
    setPolygonIsBeingChanged,
    setDistanceToLastPolygonVertex
  );

  drawOnSelectionChange(
    map,
    mapboxDraw,
    props.parcelToolFromToolbar,
    props.setPolygonIsBeingChanged,
    setDistanceToLastPolygonVertex,
    deleteDrawnParcels,
    updatePolygonInformation
  );

  drawOnUpdate(map, mapboxDraw, parcelIdBeingEdited);

  drawOnModeChange(
    map,
    mapboxDraw,
    parcelTool,
    cleanEditedParcel,
    setModeToDrawingOnKeyDown,
    contextMenuClickPoint,
    setDistanceToLastPolygonVertex,
    selectParcelToEdit
  );

  /** --------------------------------------------------------------
   *
   *
   *  2. Map Event Handlers
   *
   *
   * -------------------------------------------------------------- /*

  /** Set up map listeners */
  const handleStyleLoad = (mapboxMap: Map) => {
    /**
     * Set up all the map dependent sources, listeners and bindings after it is loaded.
     * NOTE: This handler is only called once in the lifetime of the map component.
     * This means that changing the style will not trigger this event.
     */
    map.current = mapboxMap;
    if (setMap) setMap(map.current);

    updateGeocoderProximity();
    attachMapDraw();

    initializeMapLayersVisibility();
    // Set initial configurations on the store for the opacity.
    initializeLayers(VISIBLE_LAYERS);
    resetDynamicLayers();

    map.current.on("error", (error) => {
      if (!error.error.message.includes(MapStyleProperties.LayerId.UsaParcelsZoningQuery)) {
        console.error(error);
      }
    });

    map.current.on("style.load", () => {
      // NOTE: For the reason stated on the function description above, initializing the map
      // after a style change has to be done on 'style.load' event.
      initializeMapLayersVisibility();
      // Set initial configurations on the store for the opacity.
      initializeLayersOnStyleChange(VISIBLE_LAYERS);
      resetDynamicLayers();
    });
  };

  /** Initialize layers visibility layout property on the map. */
  const initializeMapLayersVisibility = () => {
    if (!map.current) return;
    layerHelper.initializeMapLayers(map.current, layerConfigurations, VISIBLE_LAYERS);
    layerHelper.initializeDynamicMapLayers(map.current, dynamicLayerConfigurations, DYNAMIC_VISIBLE_LAYERS);
  };

  /** Handle mouse move over the map depending on map mode. */
  const handleMouseMove = (map, event) => {
    if (!map || map.isMoving()) return;

    switch (parcelTool) {
      case ParcelTool.SelectParcel:
      case ParcelTool.CombineParcels:
      case ParcelTool.CopyParcel:
        hoverOverParcel(event.point);
        break;
      case ParcelTool.DrawParcel:
      case ParcelTool.EditParcel:
        if (polygonIsBeingChanged) {
          updateCursorCoordinates(event.point);
          updatePolygonInformation();
        }
        break;
      default:
        break;
    }
  };

  /** Handle the hovering over the parcels. */
  const hoverOverParcel = (point) => {
    if (!map.current) return;
    let feature = getDetiledFeatureAtScreenPoint(point);
    hoverFeature(feature);
    feature && map.current.getCanvas().classList.add("hover-parcel");
  };

  /** Handle click on the map depending on parcel tool. */
  const handleMapClick = (map, event) => {
    if (!map) return;
    if (event.type === "contextmenu") {
      contextMenuClickPoint.current =
        parcelTool === ParcelTool.DrawParcel || parcelTool === ParcelTool.EditParcel ? event.point : null;
    }

    switch (parcelTool) {
      case ParcelTool.SelectParcel:
      case ParcelTool.CombineParcels:
        clearTimeout(smartSearchTimeoutId);
        handleClickedParcel(event.point);
        break;
      case ParcelTool.DrawParcel:
        handleDrawingPolygonClick(event.point);
        break;
      case ParcelTool.EditParcel:
        selectParcelToEdit(event.point);
        break;
      case ParcelTool.CopyParcel:
        selectParcelToCopy(event.point);
        break;
    }
  };

  /** Set cursor coordinates from map point. */
  const updateCursorCoordinates = (point) => {
    if (!map.current) return;
    let cursorCoordinates = map.current.unproject(point);
    setCursorCoordinates([cursorCoordinates.lng, cursorCoordinates.lat]);
  };

  /** Render the map pin. */
  const renderPin = (): any => {
    if (!pinPosition || !displayPin) return;

    return <Marker coordinates={pinPosition} className="map-pin" />;
  };

  /** Render a tool tip next to the cursor showing of the distance to the last point added to the polygon. */
  const renderToolTip = (): any => {
    if (distanceToLastPolygonVertex === null) return;

    let [unitTarget, suffix] = unitSystem === Unit.System.Metric ? [Unit.Type.Meters, " m"] : [Unit.Type.Feet, " FT"];
    let convertedValue = unitConversions.convertFromBase(distanceToLastPolygonVertex, unitTarget);

    let formattedValue = valueFormatter.format(convertedValue, {
      type: Format.Type.Number,
      suffix: suffix,
    });
    return (
      <Marker coordinates={cursorCoordinates} offset={TOOL_TIP_OFFSET}>
        <div className="tool-tip">{formattedValue}</div>
      </Marker>
    );
  };

  /**
   *
   *
   *  3. Parcel Handlers
   *
   */

  /** Get the parcel from raw Mapbox features. */
  const getCompleteParcelFeatureAtScreenPoint = (point) => {
    /**
     * Generate a complete parcel from raw Mapbox features. The returned feature
     * uses the raw parcel feature's geometry, and its properties are populated
     * with sanitized data values, part of which is sourced from the additional raw
     * Mapbox features.
     */
    if (!map.current) return null;
    let parcelFeature = getDetiledFeatureAtScreenPoint(point);
    if (!parcelFeature) return null;
    let parcelId = parcelFeature.properties[RawParcelFieldId.Id];

    let center = turf.getCoord(turf.centerOfMass(parcelFeature));
    let centerPoint = map.current.project(center as LngLatLike);

    let zoningFeature = map.current.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
      filter: ["in", MapStyleProperties.RawParcelFieldId.Id, parcelId],
    })[0];

    let opportunityZoneFeature = map.current.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaOpportunityZoneFill],
    })[0];

    let demographicsFeature = map.current.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaDemographicsQuery],
    })[0];

    let buildingFeature = map.current.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaBuildingsQuery],
    })[0];

    let existingBuildingData = map.current.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.ParcelsWithExistingBuildingQuery],
    })[0];

    return populateParcelProperties({
      parcelFeature,
      zoningFeature,
      demographicsFeature,
      opportunityZoneFeature,
      buildingFeature,
      existingBuildingData,
    });
  };

  /** Get the parcel features at a given point in the screen. */
  const getDetiledFeatureAtScreenPoint = (point) => {
    if (!map.current) return null;
    let { id, isDrawn } = getParcelIdFromScreenPoint(point);
    if (!id) return null;

    if (isDrawn) {
      return drawnParcels[id];
    }

    let features = map.current.queryRenderedFeatures(undefined, {
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery, MapStyleProperties.LayerId.DrawnParcels],
      filter: ["in", MapStyleProperties.RawParcelFieldId.Id, id],
    });

    return features.length === 0 ? null : geometry.featuresUnion(features);
  };

  /** Get the id of the parcel that includes a given screen point in the current viewport. */
  const getParcelIdFromScreenPoint = (point) => {
    if (!map.current) return {};

    let drawnFeature = map.current.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.DrawnParcels],
    })[0];

    if (drawnFeature && drawnFeature.properties) {
      return {
        isDrawn: true,
        id: drawnFeature.properties[MapStyleProperties.RawParcelFieldId.Id],
      };
    }

    let initialFeature = map.current.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
    })[0];

    return initialFeature
      ? {
          isDrawn: false,
          id: initialFeature.properties![MapStyleProperties.RawParcelFieldId.Id],
        }
      : {};
  };

  /** Handle what action to dispatch depending on the parcelTool and if a parcel was clicked or not. */
  const handleClickedParcel = (point) => {
    if (!map.current) return;

    let queryPoint = point;

    let [clickedParcel, clickedCoordinates] = getParcel(queryPoint);

    if (clickedParcel === null) {
      clearFeatureSelection();
      return;
    }

    const parcelId = parcelAccessors.getParcelId(clickedParcel);
    if (parcelId) analytics.trackParcelClick(parcelId, clickedCoordinates as [number, number]);

    if (parcelTool === ParcelTool.SelectParcel) {
      if (clickedParcel && "type" in clickedParcel) {
        selectParcelStart(clickedParcel, clickedCoordinates as [number, number]);
      }
    } else if (parcelTool === ParcelTool.CombineParcels) {
      if (clickedParcel && "type" in clickedParcel) {
        combineParcelsStart(clickedParcel, clickedCoordinates as [number, number], selectedFeatureMembers);
      }
    }
  };

  /**
   *
   *
   *  4. Geocoder Handlers
   *
   */

  /** Allow the geocoder to recommend Addresses/Places by proximity. */
  const updateGeocoderProximity = () => {
    if (map.current) {
      let center = map.current.getCenter().wrap();
      if (center.lng && center.lat) setProximityCenter([center.lng, center.lat]);
    }
  };

  /**
   *
   *
   *  5. Drawing Handlers
   *
   */

  /** Attach the map drawing object to the map and set its event listeners. */
  const attachMapDraw = () => {
    if (!map.current) return;
    if (!mapboxDraw.current) {
      mapboxDraw.current = new MapboxDraw({
        displayControlsDefault: false,
        styles: mapboxPresentationProperties.drawingPolygonStyle,
        modes: Object.assign({ [DrawingMode.HiddenMode]: hiddenMode }, MapboxDraw.modes),
        defaultMode: DrawingMode.HiddenMode,
      });

      map.current.addControl(mapboxDraw.current);
    }

    if (mapboxDraw.current && props.drawnParcels.length > 0) {
      Object.values(drawnParcels).forEach((currentFeature) => mapboxDraw.current.add(currentFeature));
    }
  };

  /** Handle key down when map is on draw parcel mode. */
  const handleKeyDown = (event) => {
    if (!mapboxDraw.current) return;
    switch (event.key) {
      case KeyCode.Enter:
      case KeyCode.Esc:
        if (parcelTool === ParcelTool.DrawParcel) {
          resetPolygonMeasurements();
          setDistanceToLastPolygonVertex(null);
          setModeToDrawingOnKeyDown.current = true;
        }
        break;
      case KeyCode.Delete:
        if (parcelTool === ParcelTool.EditParcel) {
          contextMenuClickPoint.current = null;
          mapboxDraw.current.trash();
        }
        break;
    }
  };

  /** Handle click when the map is on draw parcel mode. */
  const handleDrawingPolygonClick = (point) => {
    if (!mapboxDraw.current) return;

    if (mapboxDraw.current.getMode() !== DrawingMode.DrawPolygon) {
      mapboxDraw.current.changeMode(DrawingMode.DrawPolygon);
    } else if (!polygonIsBeingChanged) {
      initializePolygonIsBeingDrawn();
    }

    updateCursorCoordinates(point);
  };

  /** Initialize the polygon being drawn. */
  const initializePolygonIsBeingDrawn = () => {
    setPolygonIsBeingChanged(true);
    setDistanceToLastPolygonVertex(0);
  };

  /** Copy drawn parcels. */
  const selectParcelToCopy = (point) => {
    if (!mapboxDraw.current || mapboxDraw.current.getMode() === DrawingMode.DirectSelect) return;
    let clickedFeatureIds = mapboxDraw.current.getFeatureIdsAt(point);
    let idOfFeatureToCopy = clickedFeatureIds[0];

    if (idOfFeatureToCopy) {
      let feature = mapboxDraw.current.getAll().features.reduce((obj, feature) => {
        if (feature.id === idOfFeatureToCopy) return feature;
        return obj;
      }, null);

      if (!feature) return;

      let featureClone = geometry.clone(feature, 0.02);
      mapboxDraw.current.add(featureClone);
      const featureId = featureClone.properties[MapStyleProperties.RawParcelFieldId.Id];
      mapboxDraw.current.changeMode(DrawingMode.DirectSelect, { featureId });
    }
  };

  return (
    <div className={`component--new-project-map ${props.parcelTool}`} onKeyDown={handleKeyDown}>
      <Mapbox
        style={mapStyleUrl}
        center={cameraCenter}
        zoom={cameraZoom}
        pitch={CAMERA_INITIAL_PITCH}
        bearing={CAMERA_INITIAL_BEARING}
        onStyleLoad={handleStyleLoad}
        onClick={handleMapClick}
        onContextMenu={handleMapClick}
        onMouseMove={handleMouseMove}
        onMove={() => clearInterval(smartSearchTimeoutId)}
        onMoveEnd={handleMoveEnd}
        containerStyle={{
          height: "100%",
          width: "100%",
          margin: "0 auto",
        }}
        movingMethod="jumpTo"
      >
        {/* New Project Page Specific Layers */}
        <SmartSearchLayer />

        <DrawingInformation />
        <DrawnFeatureLayer />
        <GraphHighlightedFeatureLayer />
        <HoveredFeatureLayer />
        <SelectedFeatureLayer />

        <ParcelsZoningQueryFeatureLayer />
        <RotationControl position="bottom-right" className="rotation-control" />
        {renderPin()}
        {renderToolTip()}

        {/* Generic Generated Layers */}
        <HotelAverageDailyRateFeatureLayer />
        <IndustrialRateFeatureLayer />
        <MultifamilyRentFeatureLayer />
        <OfficeRateFeatureLayer />
        <RetailRateFeatureLayer />

        {/* Dynamic Layers */}
        <DynamicPopulationLayer />
        <DynamicMedianRentLayer />
        <DynamicEmploymentPerPopLayer />
        <DynamicMedianIncomeLayer />
        <DynamicSeaLevelRiseLayer />

        {/* Existing Building Layer */}
        <ExistingBuildingLayer />
      </Mapbox>
      {mapIsReady && queryViewport && (userIsAuthenticated || slideshowDataIsReady) && (
        <BusyPopup text="Gathering parcels data..." />
      )}
    </div>
  );
};

export default connector(NewProjectMap);
