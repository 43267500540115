import React, { useState } from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import subscriptionHelper from "../../../../state/utils/subscriptionHelper";
import { Path } from "../../../../types/Path";
import { Chargebee } from "../../../../types/Service/Chargebee";
import { Tier } from "../../../../types/Tier";
import SignUpFlowPopup from "../../../pages/SubscriptionRequiredPage/SignUpFlowPopup";
import TypeOfUserPopup from "../TypeOfUserPopup";
import { useNavigate } from "react-router-dom";

export enum UserType {
  Individual = "individual",
  Team = "team",
}

const mapStateToProps = (state) => {
  return {
    subscriptionId: subscriptionSelectors.getId(state),
    currentPlanId: subscriptionSelectors.getPlanId(state),
  };
};

type OwnProps = {
  paymentPlanId?: Chargebee.PlanId;
  text: string;
  disable?: boolean;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

const SubscribeButton = (props: Props) => {
  const [userType, setUserType] = useState<UserType | null>(null);
  const [selectUserTypeIsOpen, setSelectUserTypeIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubscribeClick = (): void => {
    if (
      props.paymentPlanId === Chargebee.PlanId.Free ||
      props.paymentPlanId === Chargebee.PlanId.DeveloperMonthly ||
      props.paymentPlanId === Chargebee.PlanId.DeveloperYearly
    ) {
      setUserType(UserType.Individual);
    } else {
      setSelectUserTypeIsOpen(true);
    }
  };

  const closeSignUpFlowPopup = () => {
    if (props.subscriptionId) {
      navigate(Path.Dashboard);
    } else {
      setUserType(null);
    }
  };

  const getIsCurrentSubscription = () => {
    const { currentPlanId, paymentPlanId } = props;
    if (currentPlanId === paymentPlanId) return true;

    const panelTier = subscriptionHelper.getPlanTier(paymentPlanId);
    const userTier = subscriptionHelper.getPlanTier(currentPlanId);
    if (userTier === Tier.Free && panelTier === Tier.Free) return true;

    return false;
  };

  if (getIsCurrentSubscription() && !userType) {
    return <div className="selected-plan">Current Subscription</div>;
  }

  return (
    <>
      <button
        disabled={props.disable}
        className={!props.disable ? "component--subscribe-button" : "disabled-button"}
        onClick={handleSubscribeClick}
      >
        {props.text}
      </button>
      {selectUserTypeIsOpen && (
        <TypeOfUserPopup setUserType={setUserType} onStateChange={() => setSelectUserTypeIsOpen(false)} />
      )}
      {userType && (
        <SignUpFlowPopup
          subscriptionPlanId={props.paymentPlanId}
          onStateChange={closeSignUpFlowPopup}
          userType={userType}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps)(SubscribeButton);
