import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../../state/newDevelopment";
import OwnerAddressFilters from "./OwnerAddressFilters/OwnerAddressFilters";

const OwnerAddressFiltersContainer = () => {
  const shouldDisplay = useSelector(newDevelopmentSelectors.getOwnerAddressFiltersShouldDisplay);

  return shouldDisplay
    ? <>
        <OwnerAddressFilters />
        <div className="divisor" />
      </>
    : null;
}

export default OwnerAddressFiltersContainer;
