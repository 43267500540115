import defaultImageUrl from "assets/images/default-user-thumbnail.svg";
import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsActions, subscriptionInvitationsSelectors } from "../../../../state/subscriptionInvitations";
import { subscriptionManagementActions, subscriptionManagementSelectors } from "../../../../state/ui/subscriptionManagement";
import { ManagedSubscriptionStatus } from "../../../../types/SubscriptionInvitations";
import authentication from "../../../../utils/authentication";
import dateHelper from "../../../../utils/dateHelper";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import CancelInvitationPopup from "./CancelInvitationPopup";
import DeleteInvitationPopup from "./DeleteInvitationPopup";
import ReinvitePopup from "./ReinvitePopup";

enum StatusType {
  Pending = "pending",
  Active = "active",
  Cancelled = "cancelled",
}

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    managedSubscriptionsImages: subscriptionInvitationsSelectors.getManagedSubscriptionsImages(state),
    deleteInvitationPopupIsOpen: subscriptionManagementSelectors.getDeleteInvitationPopupIsOpen(state),
    invitationIsBeingDeleted: subscriptionManagementSelectors.getInvitationIsBeingDeleted(state),
    cancelInvitationPopupIsOpen: subscriptionManagementSelectors.getCancelInvitationPopupIsOpen(state),
    invitationIsBeingCancelled: subscriptionManagementSelectors.getInvitationIsBeingCancelled(state),
    cancelSubscriptionPopupIsOpen: subscriptionManagementSelectors.getCancelSubscriptionPopupIsOpen(state),
  }
}

const mapDispatchToProps = {
  cancelInvitation: subscriptionInvitationsActions.cancelStart,
  toggleDeleteInvitationPopupIsOpen: subscriptionManagementActions.toggleDeleteInvitationPopupIsOpen,
  toggleCancelInvitationPopupIsOpen: subscriptionManagementActions.toggleCancelInvitationPopupIsOpen,
  toggleCancelSubscriptionPopupIsOpen: subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  selectedIndex: number;
  reinvitePopupIsOpen: boolean;
  userIdToDelete: string;
}

class SubscriptionRequiredPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      reinvitePopupIsOpen: false,
      userIdToDelete: "",
    }
  }

  /**
   * Fix selected index when removing the last row.
   */
  componentDidUpdate() {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return;

    const length = Object.keys(managedSubscriptions).length;
    if (this.state.selectedIndex >= length) {
      this.setState({ selectedIndex: length - 1 })
    }
  }

  /**
   * Render the action buttons on the managed user details.
   */
  renderActionButton = () => {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return null;

    const selectedId = Object.keys(managedSubscriptions)[this.state.selectedIndex];
    const status = managedSubscriptions[selectedId].status

    switch (status) {
      case ManagedSubscriptionStatus.Pending:
        return (
          <>
            <button onClick={this.toggleCancelInvitationPopupIsOpen}>Cancel Invitation</button>
          </>
        );
      case ManagedSubscriptionStatus.Active:
        return (
          <>
            <button onClick={this.toggleCancelSubscriptionPopupIsOpen}>Cancel Subscription</button>
          </>
        );
      case ManagedSubscriptionStatus.Expiring:
      case ManagedSubscriptionStatus.Rejected:
      case ManagedSubscriptionStatus.PaymentFailed:
      case ManagedSubscriptionStatus.Cancelled:
        return (
          <>
            <button onClick={this.toggleReinvitationPopupIsOpen}>Resend Invitation</button>
            {this.state.reinvitePopupIsOpen && <ReinvitePopup onStateChange={this.toggleReinvitationPopupIsOpen} userId={selectedId} />}
          </>
        );
      default:
        return null;
    }
  }

  /**
   * Get number of days in the current subscription cycle.
   */
  getDaysInCycle = () => {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return null;

    const selectedId = Object.keys(managedSubscriptions)[this.state.selectedIndex];
    const currentSubscription = managedSubscriptions[selectedId];

    if (!currentSubscription || !currentSubscription.currentTermEnd || !currentSubscription.currentTermStart) return null;
    const daysInCycle = (currentSubscription.currentTermEnd - currentSubscription.currentTermStart) / (24 * 60 * 60 * 1000);
    return daysInCycle;
  }

  /**
   * Get number of days until the next renewal.
   */
  getDaysToNextRenewal = () => {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return null;

    const selectedId = Object.keys(managedSubscriptions)[this.state.selectedIndex];
    const currentSubscription = managedSubscriptions[selectedId];

    if (!currentSubscription || !currentSubscription.currentTermEnd) return null;
    const daysToNextRenewal = Math.floor((currentSubscription.currentTermEnd - Date.now()) / (24 * 60 * 60 * 1000));
    return daysToNextRenewal;
  }

  /**
   * Get the percentage of the current subscription cycle that has already passed.
   */
  getPercentageOfCurrentCycle = () => {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return null;

    const selectedId = Object.keys(managedSubscriptions)[this.state.selectedIndex];
    const currentSubscription = managedSubscriptions[selectedId];
    if (!currentSubscription || !currentSubscription.currentTermStart) return null;

    const daysInCycle = this.getDaysInCycle();
    if (!daysInCycle) return null;

    const daysSoFar = (Date.now() - currentSubscription.currentTermStart) / (24 * 60 * 60 * 1000);
    return Math.ceil((daysSoFar / daysInCycle) * 100);
  }

  /**
   * Get the type of the subscription invitation status.
   */
  getStatusType = (userId) => {
    const { managedSubscriptions } = this.props;
    if (!managedSubscriptions) return;

    const status = managedSubscriptions[userId].status;

    switch (status) {
      case ManagedSubscriptionStatus.Pending:
        return StatusType.Pending;
      case ManagedSubscriptionStatus.Active:
      case ManagedSubscriptionStatus.Expiring:
        return StatusType.Active;
      default:
        return StatusType.Cancelled;
    }
  }

  /**
   * Toggle the `reinvitePopupIsOpen` state.
   */
  toggleReinvitationPopupIsOpen = () => {
    this.setState({ reinvitePopupIsOpen: !this.state.reinvitePopupIsOpen });
  }

  /**
   * Toggle the `deleteInvitationPopupIsOpen` state.
   */
  toggleDeleteInvitationPopupIsOpen = (userId?: string) => {
    this.props.toggleDeleteInvitationPopupIsOpen(!this.props.deleteInvitationPopupIsOpen);
    this.setState({ userIdToDelete: (userId || "") });
  }

  /**
   * Toggle the `cancelSubscriptionPopupIsOpen` state.
   */
  toggleCancelSubscriptionPopupIsOpen = () => {
    const { managedSubscriptions } = this.props;
    const { selectedIndex } = this.state;

    if (!managedSubscriptions
      || Object.keys(managedSubscriptions).length === 0
      || Object.keys(managedSubscriptions).length <= selectedIndex
    ) return;

    const selectedId = Object.keys(managedSubscriptions)[selectedIndex];
    this.props.toggleCancelSubscriptionPopupIsOpen(!this.props.cancelSubscriptionPopupIsOpen, selectedId);
  }

  /**
   * Toggle the `cancelInvitationPopupIsOpen` state.
   */
  toggleCancelInvitationPopupIsOpen = () => {
    this.props.toggleCancelInvitationPopupIsOpen(!this.props.cancelInvitationPopupIsOpen);
  }

  render() {
    const user = authentication.getCurrentUser();
    const { managedSubscriptions,
      managedSubscriptionsImages,
      deleteInvitationPopupIsOpen,
      cancelInvitationPopupIsOpen,
      invitationIsBeingCancelled,
      invitationIsBeingDeleted,
    } = this.props;
    const { selectedIndex, userIdToDelete } = this.state;
    if (!user
      || !managedSubscriptions
      || Object.keys(managedSubscriptions).length === 0
      || Object.keys(managedSubscriptions).length <= selectedIndex
    ) return null;

    const selectedId = Object.keys(managedSubscriptions)[selectedIndex];
    const imageUrl = (managedSubscriptionsImages && managedSubscriptionsImages[selectedId]) || "";

    const daysToNextRenewal = this.getDaysToNextRenewal();
    const percentageOfCycle = this.getPercentageOfCurrentCycle();

    return (
      <WithPanelHeader title="Team Settings" hideControls >
        <div className="component--team-member-settings">
          <div className="header">Team Member Information</div>
          <div className="information-container">
            <div className="thumbnail-container">
              <div
                className="thumbnail"
                style={{ backgroundImage: `url(${imageUrl ? `${imageUrl}` : `${defaultImageUrl}`})` }}
              />
            </div>

            <div className="user-container">
              <div className="selected-name">
                <div className="bold">{managedSubscriptions[selectedId].name}</div>
                {managedSubscriptions[selectedId].email}
              </div>
              {this.renderActionButton()}
            </div>

            <div className="subscription-container">
              <div className="top">
                <div className="section">
                  <div className="section-header">Subscription Status</div>
                  <div className="section-content">
                    <div className={`marker ${this.getStatusType(selectedId)}`} />
                    {managedSubscriptions[selectedId].status}
                  </div>
                </div>
                <div className="section">
                  <div className="section-header">Member Since</div>
                  <div className="section-content">
                    {dateHelper.getDate(managedSubscriptions[selectedId].memberSince)}
                  </div>
                </div>
              </div>
              <div className="bottom">
                {daysToNextRenewal && percentageOfCycle && this.getStatusType(selectedId) === StatusType.Active
                  ? <>
                    <span>
                      {`${managedSubscriptions[selectedId].status === ManagedSubscriptionStatus.Active
                        ? "Next Auto-Renewal"
                        : "Subscription ends"
                        } in ${daysToNextRenewal} days`
                      }
                    </span>
                    <div className="bar" />
                    <div className="bar active" style={{ width: `${percentageOfCycle}%` }} />
                  </>
                  : null
                }
              </div>
            </div>
          </div>

          <div className="list-container">
            <div className="row top">
              <div className="check" />
              <div className="name">Name</div>
              <div className="email">Email Address</div>
              <div className="subscription">Subscription</div>
              <div className="renewal">Next Renewal</div>
              <div className="since">Team Member Since</div>
            </div>

            <div className="rows-container">
              {Object.keys(managedSubscriptions).map(
                (userId, index) => {
                  const statusType = this.getStatusType(userId);

                  return (
                    <div className={`row ${userId === selectedId ? "selected" : ""}`} key={userId}>
                      <div className="check">
                        <div
                          className={`checkmark ${userId === selectedId ? "selected" : ""}`}
                          onClick={() => this.setState({ selectedIndex: index })}
                        />
                      </div>
                      <div className="name">{managedSubscriptions[userId].name}</div>
                      <div className="email">{managedSubscriptions[userId].email}</div>
                      <div className="subscription">
                        <div className={`marker ${statusType}`} />
                        {managedSubscriptions[userId].status}
                      </div>
                      <div className="renewal">
                        {(statusType === StatusType.Active && dateHelper.getDate(managedSubscriptions[userId].currentTermEnd)) || "—"}
                      </div>
                      <div className="since">
                        {dateHelper.getDate(managedSubscriptions[userId].memberSince) || "—"}
                        {statusType === StatusType.Cancelled && <div className="delete" onClick={() => this.toggleDeleteInvitationPopupIsOpen(userId)} />}
                      </div>
                    </div>
                  )
                })}
            </div>
            {deleteInvitationPopupIsOpen && userIdToDelete
              && <DeleteInvitationPopup onStateChange={this.toggleDeleteInvitationPopupIsOpen} userId={userIdToDelete} preventClose={invitationIsBeingDeleted} />
            }
            {cancelInvitationPopupIsOpen
              && <CancelInvitationPopup onStateChange={this.toggleCancelInvitationPopupIsOpen} userId={selectedId} preventClose={invitationIsBeingCancelled} />
            }
            <div className="bottom" />
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionRequiredPage);
