import subscriptionInvitations from "../utils/database/subscriptionInvitations";
import { subscriptionInvitationsActions, subscriptionInvitationsActionTypes } from "./subscriptionInvitations";

/**
 * @fileoverview This middleware is responsible for managing database listeners on specific actions
 * regarding the invitations.
 */

/**
 * Invitations middleware responsible for managing listeners depending on certain actions.
 */
const invitationsListenerMiddleware = (store) => (next) => (action) => {
  if (action.type === subscriptionInvitationsActionTypes.LOAD_SUCCESS) {
    startListening(store.dispatch, action);
  }

  return next(action);
}

/**
 * Start listening for changes on the subscription invitations document.
 */
const startListening = (dispatch, action: ReturnType<typeof subscriptionInvitationsActions.loadSuccess>) => {
  const { managedSubscriptions } = action.payload;

  for (const userId of Object.keys(managedSubscriptions)) {
    subscriptionInvitations.startListening(dispatch, userId);
  }
}

export default invitationsListenerMiddleware;
