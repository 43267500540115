import React from "react";
import { Layer, Source } from "react-mapbox-gl";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";

const LAYER_FILL_PAINT = { "fill-opacity": 0, "fill-color": "hsla(0, 0%, 0%, 0)" };

class ExistingBuildingLayer extends React.PureComponent<{}> {
  render() {
    return (
      <>
        <Source
          id={MapStyleProperties.SourceId.ExistingBuildingArea}
          tileJsonSource={{
            type: MapStyleProperties.SourceType.Vector,
            tiles: MapStyleProperties.SOURCE_ID_TO_URL_MAP[MapStyleProperties.SourceId.ExistingBuildingArea],
            minzoom: 15,
            maxzoom: 15,
          }}
        />
        <Layer
          id={MapStyleProperties.LayerId.ParcelsWithExistingBuildingQuery}
          sourceId={MapStyleProperties.SourceId.ExistingBuildingArea}
          sourceLayer={MapStyleProperties.SourceLayerId.ExistingBuildingArea}
          type={MapStyleProperties.LayerType.Fill}
          paint={LAYER_FILL_PAINT}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    );
  }
}

export default ExistingBuildingLayer;
