import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors } from "../../../../state/newDevelopment";
import analytics from "../../../../utils/analytics";
import ParcelInformationTable from "../../../sharedComponents/ParcelInformationTable";
import Tabs from "../../../sharedComponents/Tabs";
import Tab from "../../../sharedComponents/Tabs/Tab";
import TabContainer from "../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../sharedComponents/Tabs/TabPanel";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import { ParentPage } from "views/sharedComponents/ParcelInformationTable/types";

const TAB_CONFIG = {
  propertyInfo: {
    label: "Property Data",
    id: "propertyInfo",
  },
  propertyListing: {
    label: "Listing",
    id: "propertyListing",
  },
};

const mapStateToProps = (state) => {
  return {
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
  };
};

type MapStateProps = ReturnType<typeof mapStateToProps>;

class ParcelInformationPanel extends React.PureComponent<MapStateProps, {}> {
  render() {
    const { selectedFeature } = this.props;
    const defaultTab = selectedFeature ? TAB_CONFIG.propertyInfo.id : TAB_CONFIG.propertyListing.id;

    return (
      <WithPanelHeader title="Property Info">
        <div className="component--parcel-information-panel" onScroll={analytics.trackParcelInfoPanelScroll}>
          <Tabs defaultTab={defaultTab}>
            {(onTabClick, activeTab) => (
              <>
                <TabContainer>
                  {selectedFeature && (
                    <Tab
                      label={TAB_CONFIG.propertyInfo.label}
                      tabId={TAB_CONFIG.propertyInfo.id}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                    />
                  )}
                </TabContainer>
                <TabPanel tabId={TAB_CONFIG.propertyInfo.id} activeTab={activeTab}>
                  <ParcelInformationTable parcelFeature={selectedFeature} parentPage={ParentPage.NewProject} />
                </TabPanel>
              </>
            )}
          </Tabs>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(mapStateToProps)(ParcelInformationPanel);
