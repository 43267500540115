import React from "react";
import analytics from "../../../../utils/analytics";
import authentication from "../../../../utils/authentication";
import BusySpinner from "../../../sharedComponents/BusySpinner";

interface State {
  emailAddress: string;
  errorMessage: string;
  emailIsSending: boolean;
}

interface Props {
  onEmailSent: () => void;
}

export default class ForgotPasswordForm extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      errorMessage: "",
      emailIsSending: false,
    };
  }

  /**
   * Send a reset password email to the provided email address.
   */
  sendForgotPasswordEmail = async (event) => {
    if (this.state.emailIsSending) return;

    try {
      this.setState({ emailIsSending: true });
      event.preventDefault();
      await authentication.sendResetPasswordEmail(this.state.emailAddress);
      analytics.trackRequestPasswordChange(this.state.emailAddress);
      this.setState({ emailIsSending: false });
      this.props.onEmailSent();
    } catch (error: any) {
      this.setState({ errorMessage: authentication.getErrorMessage(error.code) });
      this.setState({ emailIsSending: false });
    }
  };

  /**
   * Updates the state when the email changes.
   */
  handleEmailChange = (event) => {
    this.setState({ emailAddress: event.target.value });
  };

  /**
   * Check if the email address field has data or not.
   */
  buttonIsDisabled = () => this.state.emailAddress === "";

  render() {
    const { emailIsSending } = this.state;

    return (
      <>
        <div className="header">Reset your password.</div>
        <div className="text-box">
          Please enter the email address you used to register your account. We will send you a link with instructions to
          reset your password.
        </div>

        <div className="form-container">
          <form onSubmit={this.sendForgotPasswordEmail}>
            <div className="input-section">
              <div className="input-field">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  autoComplete="username"
                  onChange={this.handleEmailChange}
                />
              </div>
            </div>

            <button
              className={`submit ${this.buttonIsDisabled() ? "disabled" : ""}`}
              type="submit"
              disabled={this.buttonIsDisabled()}
            >
              {emailIsSending ? <BusySpinner classes="white-spinner" /> : "Send"}
            </button>
          </form>
          <div className="error-container">
            <p>{this.state.errorMessage}</p>
          </div>
        </div>
      </>
    );
  }
}
