import React from "react";
import { connect } from "react-redux";
import { mapsActions, mapsSelectors } from "state/ui/shared/maps";
import DynamicLayerButton from "../DynamicLayerButton";
import dynamicLayersConfig from "../dynamicLayersConfig";

interface ownProps {
  layerSector: string;
}

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
})

const mapDispatchToProps = {
  activateDynamicLayers: mapsActions.activateDynamicLayers,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = ownProps & StateProps & DispatchProps;

export class DynamicLayerTabs extends React.PureComponent<Props, {}> {

renderDynamicLayersTabs = (layerGroup, index) => {

if (!dynamicLayersConfig[layerGroup].layers.some((layerId) =>
  this.props.dynamicLayerConfigurations[layerId].isEnabled
  && this.props.dynamicLayerConfigurations[layerId].isAvailable)
) {
  return null;
}

return <DynamicLayerButton key={index} layerGroup={layerGroup}/>
}

render (){
  const { layerSector } = this.props;

  return (
    <div className="component--dynamic-layer-tabs">
      {
        Object.keys(dynamicLayersConfig)
          .filter((layerGroup) => dynamicLayersConfig[layerGroup].layerSector === layerSector)
          .map((layerGroup, index) => (
            this.renderDynamicLayersTabs(layerGroup, index)
          ))
      }
    </div>
  )
}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicLayerTabs);
