import DynamicLayerGroup from "types/DynamicLayerGroup";
import { DynamicLayerGroupConfiguration } from "types/DynamicLayerGroupConfiguration";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";

const config: DynamicLayerGroupConfiguration = {
  [DynamicLayerGroup.Population2010]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2010,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2010,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2010,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2010,
  },

  [DynamicLayerGroup.Population2011]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2011,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2011,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2011,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2011,
  },

  [DynamicLayerGroup.Population2012]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2012,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2012,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2012,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2012,
  },

  [DynamicLayerGroup.Population2013]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2013,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2013,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2013,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2013,
  },

  [DynamicLayerGroup.Population2014]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2014,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2014,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2014,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2014,
  },

  [DynamicLayerGroup.Population2015]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2015,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2015,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2015,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2015,
  },

  [DynamicLayerGroup.Population2016]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2016,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2016,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2016,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2016,
  },

  [DynamicLayerGroup.Population2017]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2017,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2017,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2017,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2017,
  },

  [DynamicLayerGroup.Population2018]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2018,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2018,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2018,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2018,
  },

  [DynamicLayerGroup.Population2019]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2019,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2019,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2019,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2019,
  },

  [DynamicLayerGroup.Population2020]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2020,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2020,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2020,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2020,
  },

  [DynamicLayerGroup.Population2021]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2021,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2021,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2021,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2021,
  },

  [DynamicLayerGroup.Population2022]: {
    layers: [
      MapStyleProperties.LayerId.DynamicPopulationFill_2022,
      MapStyleProperties.LayerId.DynamicPopulationBorder_2022,
      MapStyleProperties.LayerId.DynamicPopulationSymbol_2022,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.Population2022,
  },

  [DynamicLayerGroup.MedianRent2013]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2013,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2013,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2013,
  },

  [DynamicLayerGroup.MedianRent2014]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2014,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2014,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2014,
  },

  [DynamicLayerGroup.MedianRent2015]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2015,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2015,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2015,
  },

  [DynamicLayerGroup.MedianRent2016]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2016,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2016,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2016,
  },

  [DynamicLayerGroup.MedianRent2017]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2017,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2017,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2017,
  },

  [DynamicLayerGroup.MedianRent2018]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2018,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2018,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2018,
  },

  [DynamicLayerGroup.MedianRent2019]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2019,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2019,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2019,
  },

  [DynamicLayerGroup.MedianRent2020]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2020,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2020,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2020,
  },

  [DynamicLayerGroup.MedianRent2021]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2021,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2021,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2021,
  },

  [DynamicLayerGroup.MedianRent2022]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianRentFill_2022,
      MapStyleProperties.LayerId.DynamicMedianRentBorder_2022,
      MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2022,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2013]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2013,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2014]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2015]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2016]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2017]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2018]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2019]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2020]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2021]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
  },

  [DynamicLayerGroup.EmploymentPerPopRatio2022]: {
    layers: [
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022,
      MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
  },

  [DynamicLayerGroup.MedianIncome2013]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2013,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2013,
  },

  [DynamicLayerGroup.MedianIncome2014]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2014,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2014,
  },

  [DynamicLayerGroup.MedianIncome2015]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2015,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2015,
  },

  [DynamicLayerGroup.MedianIncome2016]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2016,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2016,
  },

  [DynamicLayerGroup.MedianIncome2017]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2017,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2017,
  },

  [DynamicLayerGroup.MedianIncome2018]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2018,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2018,
  },

  [DynamicLayerGroup.MedianIncome2019]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2019,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2019,
  },

  [DynamicLayerGroup.MedianIncome2020]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2020,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2020,
  },

  [DynamicLayerGroup.MedianIncome2021]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2021,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2021,
  },

  [DynamicLayerGroup.MedianIncome2022]: {
    layers: [
      MapStyleProperties.LayerId.DynamicMedianIncomeFill2022,
      MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022,
      MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022,
    ],
    fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2022,
  },
};

export default config;
