import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import Format from "types/Format";
import Unit from "types/Unit";
import unitConversions from "utils/unitConversions";
import valueFormatter from "utils/valueFormatter";

const mapStateToProps = (state) => {
  const setbackSchedule = developmentSelectors.getBuildingModel(state).setbackSchedule;
  const selectedSetbackFloorIndex = developmentSelectors.getSelectedSetbackFloorIndex(state);

  return {
    area: setbackSchedule[selectedSetbackFloorIndex].footprint?.area,
    unitSystem: developmentSelectors.getUnitSystem(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class SetbacksFloorArea extends React.PureComponent<Props, {}> {

  displayArea = () => {
    const { unitSystem, area } = this.props;
    if (!area) return;

    let areaValueToDisplay = area;
    let unitSystemToDisplay = Unit.AreaType.SquareMeters;

    if (unitSystem === Unit.System.Imperial){
      areaValueToDisplay = unitConversions.convertFromBase(area, Unit.Type.SquareFeet);
      unitSystemToDisplay = Unit.AreaType.SquareFeet;
    }

    areaValueToDisplay = valueFormatter.format(areaValueToDisplay, { type: Format.Type.Number });

    return (
      <p className="area-value">{`${areaValueToDisplay} ${unitSystemToDisplay}`}</p>
    )
  }

  render() {
    return (
      <div className="component--setbacks-floor-area">
        {this.displayArea()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(SetbacksFloorArea);
