import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Map } from "mapbox-gl";
import { MapContext } from "react-mapbox-gl";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../state/newDevelopment";
import { userActions, userSelectors } from "../../../state/user";
import { KeyCode } from "../../../types/KeyCodes";
import { Path } from "../../../types/Path";
import analytics from "../../../utils/analytics";
import authentication from "../../../utils/authentication";
import { authenticationSelectors } from "../../../state/authentication";
import { developmentActions } from "../../../state/development";
import { mapsActions, mapsSelectors } from "../../../state/ui/shared/maps";
import { navigationActions } from "../../../state/navigation";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import { useNavigate } from "react-router-dom";
import ActivityFeedback from "views/sharedComponents/ActivityFeedback/ActivityFeedback";
import ParcelTools from "views/sharedComponents/ParcelTools/ParcelTools";
import ParcelFinderPanel from "./ParcelFinderPanel/ParcelFinderPanel";
import ParcelInformationPanel from "./ParcelInformationPanel/ParcelInformationPanel";
import LayerSelection from "views/sharedComponents/LayerSelection/LayerSelection";
import MarketGraphsPanel from "./MarketGraphsPanel/MarketGraphsPanel";
import SearchBar from "./SearchBar/SearchBar";
import SearchBarLoadingComponent from "views/sharedComponents/SearchBarLoadingComponent/SearchBarLoadingComponent";
import NewProjectToolbar from "./NewProjectToolbar/NewProjectToolbar";
import WelcomeSlideshow from "./WelcomeSlideshow/WelcomeSlideshow";
import BusyPopup from "views/sharedComponents/BusyPopup/BusyPopup";
import { SlideshowType } from "types/Slideshow";
import NewProjectDeveloperMap from "./NewProjectDeveloperMap/NewProjectDeveloperMap";
import NewProjectMap from "./NewProjectMap/NewProjectMap";

const mapStateToProps = (state) => {
  return {
    userLocation: userSelectors.getLocation(state),
    zoningDataInViewport: newDevelopmentSelectors.getZoningDataInViewport(state),
    parcelDataInViewport: newDevelopmentSelectors.getParcelDataInViewport(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    mapIsReady: mapsSelectors.getMapIsReady(state),
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  initializeNewDevelopment: newDevelopmentActions.initialize,
  modifierKeyDown: newDevelopmentActions.modifierKeyDown,
  modifierKeyUp: newDevelopmentActions.modifierKeyUp,
  getUserLocation: userActions.getLocationStart,
  resetDevelopment: developmentActions.resetStateStart,
  resetFlags: mapsActions.resetFlags,
  leaveNewProjectPage: navigationActions.leaveNewProjectPage,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const NewProjectPage: React.FC<Props> = (props) => {
  const [map, setMap] = useState<Map | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    props.initializeNewDevelopment();
    analytics.trackPageview(Path.NewProject);
    if (!props.userIsAuthenticated) props.resetDevelopment();

    if (props.userLocation === undefined) {
      props.getUserLocation();
    }

    return () => {
      props.resetFlags();
      props.leaveNewProjectPage();
    };
  }, []);

  /**
   * Render left side panels.
   */
  const renderLeftPanels = useCallback(() => {
    const { selectedFeature, mapIsReady, tier } = props;
    const isDeveloperTier = tier === Tier.Developer;

    return (
      <>
        {isDeveloperTier ? <ParcelTools mapIsReady={true} /> : mapIsReady && <ParcelTools mapIsReady={mapIsReady} />}
        {isDeveloperTier ? <ParcelFinderPanel /> : mapIsReady && <ParcelFinderPanel />}

        {selectedFeature && <ParcelInformationPanel />}
      </>
    );
  }, [props]);

  /**
   * Render right side panels.
   */
  const renderRightPanels = useCallback(() => {
    const { tier, mapIsReady } = props;
    const tierIsDemo = tier === Tier.None;
    const tierIsDeveloper = tier === Tier.Developer;
    const showMapLayersPanel = !tierIsDeveloper && !tierIsDemo && mapIsReady;
    const showGraphsPanel = !tierIsDemo && !tierIsDeveloper && mapIsReady;
    return (
      <MapContext.Consumer>
        {(map) => (
          <>
            {showMapLayersPanel && <LayerSelection map={map} />}
            {showGraphsPanel && <MarketGraphsPanel />}
          </>
        )}
      </MapContext.Consumer>
    );
  }, [props]);

  /**
   *
   * @param zoningDataInViewport
   * @param parcelDataInViewport
   * @returns
   */
  const determineSlideshowType = (zoningDataInViewport, parcelDataInViewport) => {
    if (zoningDataInViewport) {
      return SlideshowType.WithZoning;
    } else if (parcelDataInViewport) {
      return SlideshowType.WithoutZoning;
    } else {
      return SlideshowType.OnlyDrawing;
    }
  };

  /**
   * Render map loading message.
   */
  const renderMapLoading = useCallback(
    () => (
      <div className="map-loading-container">
        <ActivityFeedback message="Loading Data..." displaySpinner />
      </div>
    ),
    []
  );

  /**
   * Redirect the user to the Subscription Management.
   */
  if (props.tier === Tier.Free) {
    navigate(Path.SubscriptionManagement);
    return null;
  }

  const { zoningDataInViewport, parcelDataInViewport, mapIsReady, tier } = props;
  const isDeveloperTier = tier === Tier.Developer;
  const isProTier = tier === Tier.Pro;

  const userIsAuthenticated = authentication.isUserAuthenticated();
  const slideshowDataIsReady = zoningDataInViewport !== null && parcelDataInViewport !== null;
  const slideshowType = determineSlideshowType(zoningDataInViewport, parcelDataInViewport);
  const searchBar = !mapIsReady ? <SearchBarLoadingComponent /> : <SearchBar />;

  return (
    <MapContext.Provider value={map}>
      <div
        className="component--new-project-page"
        tabIndex={-1}
        onKeyDown={(event) => props.modifierKeyDown(event.key as KeyCode)}
        onKeyUp={(event) => props.modifierKeyUp(event.key as KeyCode)}
      >
        {!mapIsReady && slideshowDataIsReady && renderMapLoading()}
        <div className="content">
          <div className="top-menus-container">
            {isDeveloperTier ? <SearchBar /> : searchBar}
            <NewProjectToolbar isProTier={isProTier} />
          </div>
          <div className="panels-container">
            <div className="left-panel-wrapper">{renderLeftPanels()}</div>
            <div className="right-panel-wrapper">{renderRightPanels()}</div>
          </div>
        </div>
        {isDeveloperTier ? (
          <NewProjectDeveloperMap setMap={setMap} />
        ) : (
          <NewProjectMap slideshowDataIsReady={slideshowDataIsReady} setMap={setMap} />
        )}

        {!userIsAuthenticated ? (
          slideshowDataIsReady ? (
            <WelcomeSlideshow slideshowType={slideshowType} />
          ) : (
            <BusyPopup text="Loading Data..." preventClose />
          )
        ) : null}
      </div>
    </MapContext.Provider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectPage);
