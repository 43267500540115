import { connect } from "react-redux";
import valueGetters from "state/development/utils/valueGetters";
import { subscriptionSelectors } from "state/subscription";
import Format from "types/Format";
import authentication from "utils/authentication";
import parcelAccessors from "utils/parcel/parcelAccessors";
import userHelper from "utils/userHelper";
import Cell from "views/sharedComponents/Cell/Cell";
import CellRow from "views/sharedComponents/CellRow/CellRow";
import CellText from "views/sharedComponents/CellText/CellText";

const ESTIMATED_UNITS_ALLOWED_FOOTNOTE =
  "This value is generated using the unit density ratio. If there is no ratio,  we divide the approximate buildable area by 1,000 SF";
const APPROXIMATE_BUILDABLE_AREA_FOOTNOTE =
  "This value is generated using available zoning regulations. We first use FAR. If there is no FAR, we use height and lot coverage.";

type OwnProps = {
  parcelFeature: any;
};

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

function DeepblocksFootnotes(props: Props) {
  const renderDeepblocksFootnotes = (parcelFeature, index, isAssembly = false) => {
    const address = parcelAccessors.getAddress(parcelFeature) || `Property ${index + 1}`;
    const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), props.tier);
    const estimatedUnitsAllowed = parcelAccessors.getNumberOfUnitsAllowed(parcelFeature);
    const parcelHasEstimatedUnitsAllowed = estimatedUnitsAllowed && estimatedUnitsAllowed.length > 0;
    const buildableArea = parcelAccessors.getAllowedBuildableAreaQuery(parcelFeature);
    const hasApproximateBuildableArea = buildableArea && buildableArea > 0;
    return (
      <table key={`table_${index}`}>
        <thead>
          <tr>
            <th colSpan={2}>{`NOTES${isAssembly ? ` for ${address}` : ""}`}</th>
          </tr>
        </thead>
        <tbody>
          {parcelHasEstimatedUnitsAllowed ? (
            <CellRow classes="sub-section">
              <CellText text="• Estimated Units Allowed" />
              <Cell
                getter={valueGetters.generic}
                value={ESTIMATED_UNITS_ALLOWED_FOOTNOTE}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
              />
            </CellRow>
          ) : null}
          {hasApproximateBuildableArea ? (
            <CellRow classes="sub-section">
              <CellText text="• Approx. Buildable Area" />
              <Cell
                getter={valueGetters.generic}
                value={APPROXIMATE_BUILDABLE_AREA_FOOTNOTE}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
              />
            </CellRow>
          ) : null}
        </tbody>
      </table>
    );
  };

  const { parcelFeature } = props;
  return (
    <>
      {parcelAccessors.getIsAnAssembly(parcelFeature)
        ? parcelAccessors
            .getAssemblyParcels(parcelFeature)
            .map((parcel, index) => renderDeepblocksFootnotes(parcel, index, true))
        : renderDeepblocksFootnotes(parcelFeature, 0)}
    </>
  );
}

export default connect(mapStateToProps)(DeepblocksFootnotes);
