import { colorFor } from "./colors";
import { BuildingUse } from "../../types/BuildingUse";

const USAGE_PROPERTIES: {[key in BuildingUse]?: any} = {
  [BuildingUse.Condo]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-condo"),
  },
  [BuildingUse.Multifamily]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-multifamily"),
  },
  [BuildingUse.Hotel]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-hotel"),
  },
  [BuildingUse.Office]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-office"),
  },
  [BuildingUse.Industrial]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-industrial"),
  },
  [BuildingUse.Parking]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-parking"),
  },
  [BuildingUse.Retail]: {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-retail"),
  },
  [BuildingUse.Project]: {                      // "General" or "default" usage.
    "display":          false,
    "displayColorRGB":  colorFor("building-usage-project"),
  },
}

/**
 * The list of building usages that are displayable
 *
 * @returns - An array containing the names of usages that are displayable.
 */
const displayableUsages = (): Array<BuildingUse> =>
  Object
    .keys(USAGE_PROPERTIES)
    .filter((usage) => USAGE_PROPERTIES[usage]["display"]) as Array<BuildingUse>;

/**
 * The RGB color string for the given usage.
 *
 * @param usage - The name of a building usage.
 *
 * @returns - A string representation of an RGB color value, including a
 *    leading poundsign (for example "#9C2706"), or null if the requested
 *    building usage is not found in the usage list.
 */
const usageColor = (usage) => {
  const usages = Object.keys(USAGE_PROPERTIES);

  if (usages.includes(usage)) {
    return USAGE_PROPERTIES[usage]["displayColorRGB"];
  } else {
    return null;
  }
};

export {
  displayableUsages,
  usageColor,
};
