import React from "react";
import companyInfo from "../../../utils/companyInfo";

interface Tooltip {
  className: string;
  thinHeader: string;
  boldHeader: string;
  contentText: any;
  buttonText: string;
}

export enum TooltipType {
  BackOfEnvelope,
  Dashboard,
  InformationPanel,
  PdfPanel,
  SmartSearch,
  MarketInsightPanel,
  LayerSelectionPanel,
}

const layerSelectionPanelTooltip: Tooltip = {
  className: "tooltip-layer-selection-panel",
  thinHeader: "Map Layers",
  boldHeader: "Visibility Into Markets",
  contentText: "Instant snapshots of \ngeographical market data provide \nvisibility into hidden opportunities.",
  buttonText: "Subscribe",
};

const marketInsightTooltip: Tooltip = {
  className: "tooltip-market-insight-panel",
  thinHeader: "Market Insights",
  boldHeader: "Historical Data by Neighborhood",
  contentText: "Compare recent neighborhood \ngrowth with census tract data.",
  buttonText: "Subscribe",
};

const informationPanelTooltip: Tooltip = {
  className: "tooltip-information-panel",
  thinHeader: "Property Data",
  boldHeader: "All the info in one place",
  contentText: (
    <>
      In{" "}
      <a href={companyInfo.WEBSITE_URL} target="_blank" rel="noopener noreferrer">
        selected cities
      </a>
      {
        " get a quick understanding \nof what you can do with a site. In other cities, \ndraw your parcel and model return."
      }
    </>
  ),
  buttonText: "Subscribe",
};

const dashboardTooltip: Tooltip = {
  className: "tooltip-dashboard",
  thinHeader: "Save your Projects",
  boldHeader: "Increase transactions",
  contentText:
    "Speed up the process of getting information \nto the right people. Spend less time evaluating \nand underwriting, and more time on calls.",
  buttonText: "Subscribe",
};

const pdfTooltip: Tooltip = {
  className: "tooltip-pdf",
  thinHeader: "Branded Report",
  boldHeader: "Increase deal-flow",
  contentText:
    "Distinguish yourself while reducing the \nburden of creating a presentation. \nProvide a professional summary.",
  buttonText: "Subscribe",
};

const backOfEnvelopeTooltip: Tooltip = {
  className: "tooltip-back-of-envelope",
  thinHeader: "Financial Assumptions",
  boldHeader: "Faster than building a proforma",
  contentText: "Save up to 30% of your time when evaluating \na deal and forecasting an investment \napproach.",
  buttonText: "Subscribe",
};

const smartSearchTooltip: Tooltip = {
  className: "tooltip-smart-search",
  thinHeader: "Identify Properties",
  boldHeader: "Match your search criteria",
  contentText: "Search by uses allowed, zoning district, lot size, and more. Export CSV with search results.",
  buttonText: "Subscribe",
};

const config = {
  [TooltipType.BackOfEnvelope]: backOfEnvelopeTooltip,
  [TooltipType.Dashboard]: dashboardTooltip,
  [TooltipType.InformationPanel]: informationPanelTooltip,
  [TooltipType.PdfPanel]: pdfTooltip,
  [TooltipType.SmartSearch]: smartSearchTooltip,
  [TooltipType.MarketInsightPanel]: marketInsightTooltip,
  [TooltipType.LayerSelectionPanel]: layerSelectionPanelTooltip,
};

export default config;
