import { call, take, takeEvery } from "redux-saga/effects";
import authentication from "../../utils/authentication";
import { developmentActionTypes } from "../development";
import actions from "./actions";
import actionTypes from "./actionTypes";

/**
 * Sign out user. If current development is edited, wait for saveSuccess to continue sign out process.
 */
function* signOutStart(action: ReturnType<typeof actions.signOutStart>) {
  try {
    const { routeOptions, currentDevelopmentIsEdited } = action.payload;

    if (currentDevelopmentIsEdited) {
      yield take(developmentActionTypes.SAVE_SUCCESS);
    }

    yield call(authentication.signOut);

    if (routeOptions) {
      routeOptions.navigate(routeOptions.pathName);
    }
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Watcher for the `signOutStart` action.
 */
function* watchSignOutStart() {
  yield takeEvery(actionTypes.SIGN_OUT_START, signOutStart);
}

export default {
  watchSignOutStart,
};
