import { MapsState } from "./reducers";

/**
 * Get the getMapsState object.
 */
const getMapsState = (state): MapsState => {
  let mapsState = state.ui.shared.maps;
  return mapsState;
}

/**
 * Get the layer configurations object.
 */
const getLayerConfigurations = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.layerConfigurations;
}

/**
 * Get the map style url value.
 */
const getMapStyleUrl = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.mapStyleUrl;
}

/**
 * Get the developer map style url value.
 */
const getDeveloperMapStyleUrl = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.developerTierMapStyleUrl;
}

/**
 * Get the map is ready flag.
 */
const getMapIsReady = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.mapIsReady;
}

/**
 * Get the style change flag.
 */
const getStyleChanged = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.styleChanged;
}

/**
 * Get the Selected Dynamic Layer Group.
 */
const getSelectedDynamicLayerGroup = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.selectedDynamicLayerGroup;
}

/**
 * Get Selected Dynamic Layer Sets
 */
const getSelectedDynamicLayerSet = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.selectedIndividualDynamicLayers;
}

/**
 * Get the dynamic layer configurations object.
 */
 const getDynamicLayerConfigurations = (state) => {
  let mapsState = getMapsState(state);
  return mapsState.dynamicLayerConfigurations;
}

export default {
  getLayerConfigurations,
  getMapStyleUrl,
  getMapIsReady,
  getStyleChanged,
  getDeveloperMapStyleUrl,
  getSelectedDynamicLayerGroup,
  getSelectedDynamicLayerSet,
  getDynamicLayerConfigurations,
}
