import React from "react";
import { connect } from "react-redux";
import { userSelectors } from "../../../../../../../state/user";
import { subscriptionActions, subscriptionSelectors } from "../../../../../../../state/subscription";
import { Chargebee } from "../../../../../../../types/Service";
import chargebee from "../../../../../../../utils/functions/chargebee";
import wrapComponentWithPopup, { PopupProps } from "../../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../../../sharedComponents/WithPanelHeader";
import valueFormatter from "../../../../../../../utils/valueFormatter";
import Format from "../../../../../../../types/Format";
import BusySpinner from "views/sharedComponents/BusySpinner";
import { Tier } from "types/Tier";

const TIMEOUT = 1000;

const mapStateToProps = (state) => {
  return {
    planPrices: subscriptionSelectors.getPlanPrices(state),
    userDocument: userSelectors.getUserDocument(state),
    paymentStarted: subscriptionSelectors.getIsPaymentStarted(state),
    paymentSuccess: subscriptionSelectors.getIsPaymentSuccess(state),
    isPaymentError: subscriptionSelectors.getIsPaymentError(state),
    errorMessage: subscriptionSelectors.getErrorMessage(state),
    tier: subscriptionSelectors.getTier(state),
  }
}

const mapDispatchToProps = {
  createSubscription: subscriptionActions.createStart,
  paymentStart: subscriptionActions.paymentStart,
  resetErrorFlags: subscriptionActions.resetErrorFlags,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & StateProps & DispatchProps;

interface State {
  planPeriodIsYearly: boolean;
  discountCode: string;
  couponIsValid: boolean | null;
  priceEstimate?: number;
}

class UpgradePopup extends React.PureComponent<Props, State>  {
  fetchCouponTimeout: any;

  constructor(props: Props) {
    super(props);

    const isDeveloperSubscription = props.tier === Tier.Developer;
    const priceEstimate = isDeveloperSubscription ? Chargebee.PlanId.DeveloperMonthly : Chargebee.PlanId.ProMonthly;

    this.state = {
      planPeriodIsYearly: false,
      discountCode: "",
      couponIsValid: null,
      priceEstimate: props.planPrices[priceEstimate],
    }
  }

  /**
   * Check validity of Coupon when plan duration is changed.
   * Check submitting payment was succesfully and close the popUp.
   */
  componentDidUpdate(previousProps, previousState: State) {
    if (previousState.planPeriodIsYearly !== this.state.planPeriodIsYearly) {
      if (this.fetchCouponTimeout) clearInterval(this.fetchCouponTimeout);
      this.fetchCoupon();
    }
    if(previousProps.paymentStarted === true && this.props.paymentSuccess === true){
      this.props.resetErrorFlags();
      this.props.closePopup();
    }
  }

  /**
   * Reset error flags when popUp is closed.
   */
  componentWillUnmount() {
    this.props.resetErrorFlags();
  }

  /**
   * Updates the state when the discount changes.
   */
  handleDiscountCodeChange = (event) => {
    const discountCode = event.target.value && event.target.value.replace(" ", "").toUpperCase();
    this.setState({ discountCode: discountCode, couponIsValid: null });

    if (this.fetchCouponTimeout) clearInterval(this.fetchCouponTimeout);
    this.fetchCouponTimeout = setTimeout(this.fetchCoupon, TIMEOUT);
  }

  /**
   * Get coupon object if it exists.
   */
  fetchCoupon = async () => {
    const { discountCode, planPeriodIsYearly } = this.state;
    const { planPrices, tier } = this.props;
    const isProTier = tier === Tier.Pro;
    const isFreeTier = tier === Tier.Free;

    const paymentPlan = isProTier || isFreeTier
                          ? planPeriodIsYearly ? Chargebee.PlanId.ProYearly : Chargebee.PlanId.ProMonthly
                          : planPeriodIsYearly ? Chargebee.PlanId.DeveloperYearly : Chargebee.PlanId.DeveloperMonthly

    let coupon: Chargebee.Coupon | null = null;
    if (discountCode) {
      try {
        coupon = await chargebee.getCoupon(discountCode);

        if (
          coupon
          && coupon.status === Chargebee.CouponStatus.Active
          && coupon.plan_ids.includes(paymentPlan)
        ) {
          const priceEstimate = await this.getEstimate(paymentPlan, discountCode);
          if (priceEstimate !== null) {
            this.setState({ couponIsValid: true, priceEstimate: priceEstimate });
          } else {
            this.setState({ couponIsValid: true, priceEstimate: planPrices[paymentPlan] });
          }
        } else {
          this.setState({ couponIsValid: false, priceEstimate: planPrices[paymentPlan] });
        }
      } catch (error) {
        console.warn(error);
        this.setState({ couponIsValid: false, priceEstimate: planPrices[paymentPlan] });
      }
    } else {
      this.setState({ couponIsValid: null, priceEstimate: planPrices[paymentPlan] });
    }

    this.fetchCouponTimeout = null;
  }

    /**
   * Handle upgrade to PRO.
   */
     handleUpgrade = async () => {
      this.props.resetErrorFlags();
      const { planPeriodIsYearly } = this.state;
      const { userDocument, tier, createSubscription, paymentStart } = this.props;
      paymentStart();
      await this.fetchCoupon();
      if (!userDocument || this.state.couponIsValid === false) return;
      const discountCode = this.state.discountCode ? [this.state.discountCode] : [];

      const isProTier = tier === Tier.Pro;
      const isFreeTier = tier === Tier.Free;

      const subscriptionPlanId = isProTier || isFreeTier
                          ? planPeriodIsYearly ? Chargebee.PlanId.ProYearly : Chargebee.PlanId.ProMonthly
                          : planPeriodIsYearly ? Chargebee.PlanId.DeveloperYearly : Chargebee.PlanId.DeveloperMonthly

      createSubscription(userDocument, subscriptionPlanId, discountCode);
    }

    /**
     * Handle Cancel button click.
     */
    handleCancelButton = () => {
      this.props.resetErrorFlags();
      this.props.closePopup();
    }

  /**
   * Update estimate for current plan and coupons if available.
   */
  getEstimate = async (planId: Chargebee.PlanId, coupon) => {
    try {
      const estimate = await chargebee.getEstimate(planId, [coupon]);
      return estimate.invoice_estimate.sub_total / 100;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  render() {
    const { planPeriodIsYearly, couponIsValid, priceEstimate } = this.state;
    const { isPaymentError, errorMessage, paymentStarted, paymentSuccess, tier } = this.props;
    const submittingPayment = paymentStarted && !paymentSuccess && !isPaymentError;
    const isDeveloperTier = tier === Tier.Developer;
    const subscriptionTypeLabel = isDeveloperTier ? "Developer" : "PRO";

    return (
      <WithPanelHeader title={`Activate ${subscriptionTypeLabel}`} hideControls>
        <div className="component--upgrade-popup">
          <div className="top">
            <div className="text">
            Welcome to <strong>{subscriptionTypeLabel}</strong>. Please select your billing plan.
            </div>

            <div className="toggle-wrapper">
              <div
                className={`toggle-button ${planPeriodIsYearly ? "" : "selected"}`}
                onClick={() => this.setState({ planPeriodIsYearly: false })}
              >
                Bill Monthly
              </div>
              <div
                className={`toggle-button ${planPeriodIsYearly ? "selected" : ""}`}
                onClick={() => this.setState({ planPeriodIsYearly: true })}
              >
                Bill Yearly
              </div>
            </div>

            <div className="text price">
              {`Your ${subscriptionTypeLabel} Subscription will be ${valueFormatter.format(priceEstimate, { type: Format.Type.Currency })}${planPeriodIsYearly ? "/year" : "/month"}`}
            </div>

            <div className={`input-field ${couponIsValid === false ? "invalid" : ""}`}>
              <input
                type="text"
                name="discountCode"
                placeholder="Discount Code"
                onChange={this.handleDiscountCodeChange}
              />
            </div>
          </div>

          {
            isPaymentError &&
            <div className="payment-error-container">
              <p>{errorMessage}</p>
            </div>
          }

          <div className="bottom">
            <div className="buttons-container">
              <button onClick={this.handleCancelButton}>Cancel</button>
              <button
                className={submittingPayment ? "upgrading" : "upgrade"}
                onClick={this.handleUpgrade}
              >
                {submittingPayment ? <BusySpinner classes="white-spinner" /> : "Activate"}
              </button>
            </div>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpgradePopup)
);
