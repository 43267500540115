import React from "react";
import { connect } from "react-redux";
import MapboxGeocoder from "../../../../../sharedComponents/MapboxGeocoder";
import wrapComponentWithPopup from "../../../../../sharedComponents/wrapComponentWithPopup";
import RedirectButton from "../../../../../sharedComponents/RedirectButton";
import { Path } from "../../../../../../types/Path";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../../../../state/newDevelopment";
import { GeocodePlaceType } from "../../../../../../types/Mapbox/Mapbox";

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    searchAddress: newDevelopmentSelectors.getSearchAddress(state),
    suggestedFeaturePlaceType: newDevelopmentSelectors.getSuggestedFeaturePlaceType(state),
  }
}

const mapDispatchToProps = {
  initializeNewDevelopment: newDevelopmentActions.initialize,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  errorMessage: string;
}

class SiteAnalysisPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: "",
    }

    props.initializeNewDevelopment();
  }

  /**
   * Clear the error message when a valid address gets selected.
   */
  componentDidUpdate(previousProps: Props) {
    if (!previousProps.pinPosition && this.props.pinPosition) {
      this.setState({ errorMessage: "" })
    }
  }

  /**
   * Check if user has selected item from the dropdown.
   */
  inputIsValid = () => {
    if (!Boolean(this.props.pinPosition) || !this.props.searchAddress) {
      this.setState({ errorMessage: "Please enter a city or zip code and select from the dropdown list." });
      return false;
    }

    return true;
  }

  render() {
    const { pinPosition, suggestedFeaturePlaceType } = this.props;
    const path = pinPosition ? `${Path.NewProject}/${pinPosition[0]},${pinPosition[1]},16.1` : "";

    return (
      <div className="component--site-analysis-popup">
        <div className="site-analysis-icon" />
        <p className="header">Canvass the City</p>
        <p>
          Enter a zip code or city. On the next screen, you’ll be able to adjust search criteria and
          see the matching properties.
        </p>
        <div className={`geocoder-container ${this.state.errorMessage ? "error" : ""}`}>
          <MapboxGeocoder placeholder="Enter zip code or city to begin" />
          <RedirectButton
            text="SEARCH ENGINE"
            path={path}
            state={{
              smartSearchIsOpen: true,
              displayPin: suggestedFeaturePlaceType.includes(GeocodePlaceType.Address),
            }}
            shouldContinue={this.inputIsValid}
          />
        </div>
        <div className="error-container">
          <p>{this.state.errorMessage}</p>
        </div>
      </div>
    )
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SiteAnalysisPopup)
);
