import React from "react";
import wrapComponentWithPopup from "../../wrapComponentWithPopup";
import { UserType } from "../SubscribeButton/SubscribeButton";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";

interface Props {
  setUserType(userType: UserType): void;
}

interface State {
  userType: UserType | null;
}

class TypeOfUserPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userType: null,
    }
  }

  /**
   * Handle click.
   */
  handleClick = () => {
    if (!this.state.userType) return;
    this.props.setUserType(this.state.userType);
  }

  render() {
    return (
      <WithPanelHeader title="Select Account Type" hideControls>
        <div className="component-type-of-user">
          <div className="selection-container">
            <div className="user-type-box">
              <input
                type="checkbox"
                checked={this.state.userType === UserType.Individual}
                onChange={() => this.setState({ userType: UserType.Individual })}
              />
              <div className="individual icon" />
              Individual
            </div>
            <div className="user-type-box">
              <input
                type="checkbox"
                checked={this.state.userType === UserType.Team}
                onChange={() => this.setState({ userType: UserType.Team })}
              />
              <div className="team icon" />
              Team
            </div>
          </div>

          <button onClick={this.handleClick}>Continue</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(TypeOfUserPopup);
