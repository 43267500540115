import { createAction } from "@reduxjs/toolkit";
import { InvitationError } from "../../../types/InvitationError";
import { Invitation } from "../../subscriptionInvitations/reducers";

/**
 * Toggle tiers panel is active flag.
 */
const toggleTiersPanelIsActive = createAction("ui/subscriptionManagement/TOGGLE_TIERS_PANEL_IS_ACTIVE",
  (value?: boolean) => ({
    payload: { value }
  })
);

/**
 * Toggle delete invitation popup flag.
 */
const toggleDeleteInvitationPopupIsOpen = createAction("ui/subscriptionManagement/TOGGLE_DELETE_INVITATION_POPUP_IS_OPEN",
  (value: boolean) => ({
    payload: { value }
  })
);

/**
 * Toggle cancel invitation popup flag.
 */
const toggleCancelInvitationPopupIsOpen = createAction("ui/subscriptionManagement/TOGGLE_CANCEL_INVITATION_POPUP_IS_OPEN",
  (value: boolean) => ({
    payload: { value }
  })
);

/**
 * Toggle cancel subscription popup flag.
 */
const toggleCancelSubscriptionPopupIsOpen = createAction("ui/subscriptionManagement/TOGGLE_CANCEL_SUBSCRIPTION_POPUP_IS_OPEN",
  (value: boolean, userIdToBeCancelled?: string) => ({
    payload: { value, userIdToBeCancelled }
  })
);

/**
 * Set error message.
 */
const setInvitationError = createAction("ui/subscriptionManagement/SET_INVITATION_ERROR",
  (invitationError: InvitationError, invitations?: { [ownerId: string]: Invitation }) => ({
    payload: { invitationError, invitations }
  })
);

/**
 * Clear error message.
 */
const clearInvitationError = createAction("ui/subscriptionManagement/CLEAR_INVITATION_ERROR");

export default {
  clearInvitationError,
  setInvitationError,
  toggleTiersPanelIsActive,
  toggleDeleteInvitationPopupIsOpen,
  toggleCancelInvitationPopupIsOpen,
  toggleCancelSubscriptionPopupIsOpen,
}
