import React from "react";
import { LayerDisclaimerObject } from "types/LayerDisclaimer";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

const HISTORIC_BUILDING_DISCLAIMER_TEXT = "Properties listed on the National Register which are less than 10 acres are represented as points, while properties or districts which are more than 10 acres are delineated as polygons. All points and polygons are generated from coordinate pairs submitted with each individual National Register nomination. At this time, the quality and accuracy of the data are variable. Generally, listed historic properties represented as points (locations) are accurate to being placed in the appropriate county. Listed historic properties represented as polygons are far more problematic. Transcription errors and incorrect sequencing of bounding coordinates permeate this part of the dataset. For this reason Deepblocks is currently only displaying historic properties represented as points. Potential users are advised to carefully review the quality of the location of listed historic properties of interest to them before using this data. Subsequent releases of this dataset will seek to address these issues as time and funds are made available.";
const disclaimer: LayerDisclaimerObject = { disclaimerText: HISTORIC_BUILDING_DISCLAIMER_TEXT };

class HistoricBuildingsLegend extends React.PureComponent<{}, {}> {

  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.HistoricBuildings}
        disclaimer={disclaimer}
        classes="historic-buildings"
        legendRows={[
          <LegendRow
            color="#101dd5"
            text="Historic Buildings"
            circle
          />,
        ]}
      />
    );
  }
}

export default HistoricBuildingsLegend;
