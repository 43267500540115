import React from "react";
import { connect } from "react-redux";
import { userSelectors, userActions } from "../../../../../../state/user";
import defaultImageUrl from "assets/images/default-user-thumbnail.svg";
import authentication from "../../../../../../utils/authentication";
import { UserDocument } from "../../../../../../types/UserDocument";

const mapStateToProps = (state) => {
  return {
    userDocument: userSelectors.getUserDocument(state),
    imageUrl: userSelectors.getImageUrl(state),
  };
};

const mapDispatchToProps = {
  uploadImage: userActions.uploadImageStart,
  updateUser: userActions.updateStart,
};

interface OwpProps {
  closeEditing(): void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwpProps & StateProps & DispatchProps;

interface State {
  firstName: string;
  lastName: string;
  companyName: string;
  profileImage: File | null;
}

class EditInformation extends React.PureComponent<Props, State> {
  inputReference: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: (props.userDocument && props.userDocument.firstName) || "",
      lastName: (props.userDocument && props.userDocument.lastName) || "",
      companyName: (props.userDocument && props.userDocument.companyName) || "",
      profileImage: null,
    };
  }

  /**
   * Updates the state when the first name changes.
   */
  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value });
  };

  /**
   * Updates the state when the last name changes.
   */
  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value });
  };

  /**
   * Updates the state when the company name changes.
   */
  handleCompanyNameChange = (event) => {
    this.setState({ companyName: event.target.value });
  };

  /**
   * Upload user image.
   */
  uploadImage = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const user = authentication.getCurrentUser();
    if (!user) return;

    if (event.target.files.length > 0)
      this.props.uploadImage(user.uid, event.target.files[0]);
  };

  /**
   * Open browsers file selection window.
   */
  openFileSelect = () => {
    if (this.inputReference) this.inputReference.click();
  };

  /**
   * Update the user and close the editing mode.
   */
  updateUser = () => {
    const { userDocument } = this.props;
    if (!userDocument) return;

    const user = authentication.getCurrentUser();
    if (!user) return;

    const newUserDocument: UserDocument = {
      ...userDocument,
      firstName: this.state.firstName || userDocument.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
    };

    this.props.updateUser(user.uid, newUserDocument);
    this.props.closeEditing();
  };

  render() {
    const { closeEditing, imageUrl, userDocument } = this.props;
    if (!userDocument) return null;

    return (
      <div className="component--edit-information">
        <div className="information-container">
          <div className="thumbnail-container">
            <div
              className="thumbnail edit"
              style={{
                backgroundImage: `url(${
                  imageUrl ? `${imageUrl}` : `${defaultImageUrl}`
                })`,
              }}
            />
            <div className="text" onClick={this.openFileSelect}>
              Upload (200 KB)
              <input
                ref={(ref) => (this.inputReference = ref)}
                type="file"
                accept="image/png, image/jpeg"
                onChange={this.uploadImage}
                hidden
              />
            </div>
          </div>
          <div className="data-container">
            <div className="input-field">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={this.state.firstName}
                autoComplete="name"
                onChange={this.handleFirstNameChange}
              />
            </div>

            <div className="input-field">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={this.state.lastName}
                autoComplete="name"
                onChange={this.handleLastNameChange}
              />
            </div>

            <div className="input-field">
              <input
                type="text"
                name="company"
                placeholder="Company Name"
                value={this.state.companyName}
                onChange={this.handleCompanyNameChange}
              />
            </div>
          </div>
        </div>
        <div className="buttons-wrapper">
          <button onClick={closeEditing}>Close</button>
          <button onClick={this.updateUser}>Save Changes</button>
          <div className="space" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInformation);
