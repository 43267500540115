import React from "react";
import Pdf from "../../../../../../../types/Pdf";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const DEFAULT_FONT_SIZE = 11;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  name: {
    fontSize: DEFAULT_FONT_SIZE,
    fontFamily: "Roboto Bold",
    marginTop: 4,
    color: "#000000",
  },
  company: {
    fontWeight: "bold",
    fontSize: DEFAULT_FONT_SIZE,
    fontFamily: "Roboto",
    marginBottom: 8,
    color: "#000000",
  },
  detail: {
    fontSize: DEFAULT_FONT_SIZE - 3,
    color: "#000000",
  },
  label: {
    color: "#000000",
    paddingBottom: 4,
    marginBottom: 2,
    borderBottom: "1px",
    borderColor: "#000000",
    fontSize: DEFAULT_FONT_SIZE,
    width: 100,
    fontFamily: "Roboto Condensed",
  },
});

interface Props {
  label: string;
  labelHasBorder?: boolean;
  contactDetails: Pdf.ContactDetails;
  textColor: string;
  labelColor: string;
  fontSize?: number;
}

const ContactDetails = (props: Props) => (
  <View style={styles.container}>
    <Text
      style={[
        styles.label,
        {
          fontSize: props.fontSize || DEFAULT_FONT_SIZE,
          color: props.labelColor,
          borderBottom: props.labelHasBorder ? 1 : 0,
        },
      ]}
    >
      {props.label}
    </Text>
    <Text style={[styles.name, { fontSize: props.fontSize || DEFAULT_FONT_SIZE, color: props.textColor }]}>
      {props.contactDetails.name || " "}
    </Text>
    <Text style={[styles.company, { fontSize: props.fontSize || DEFAULT_FONT_SIZE, color: props.textColor }]}>
      {props.contactDetails.companyName || " "}
    </Text>
    <Text style={[styles.detail, { fontSize: (props.fontSize || DEFAULT_FONT_SIZE) - 3, color: props.textColor }]}>
      {props.contactDetails.email || " "}
    </Text>
    <Text style={[styles.detail, { fontSize: (props.fontSize || DEFAULT_FONT_SIZE) - 3, color: props.textColor }]}>
      {props.contactDetails.phoneNumber || " "}
    </Text>
  </View>
);

export default ContactDetails;
