import { call, put, takeEvery, select, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import actionTypes from "./actionTypes";
import development from "../../utils/database/development";
import thumbnails from "../../utils/storage/developments/thumbnails";
import pdfCovers from "../../utils/storage/developments/pdfCovers";
import pdfLogos from "../../utils/storage/developments/pdfLogos";
import developmentAccessors from "./utils/developmentAccessors";
import { jsonDeepCopy } from "../../utils/deepCopy";
import sharedProjects from "../../utils/database/sharedProjects";
import { developmentActionTypes, developmentSelectors } from ".";
import { userSelectors } from "../user";

/**
 * Load a development from the database.
 */
function* load(action: ReturnType<typeof actions.loadStart>) {
  try {
    const { user, projectId, userDocument } = action.payload;

    let userAttributes: any = null;
    if (user) {
      userAttributes = {
        data: userDocument,
        id: user.uid,
      };
    }

    const result = yield call(development.read, projectId, userAttributes);
    if (result) {
      yield put(actions.loadSuccess(result.projectData, result.userData, result.newProjectId));
    } else {
      yield put(actions.loadFailure());
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Util function to save development to the database.
 */
function* saveDevelopment(user, developmentId, developmentDocument, userDocument, pdfDocument, thumbnail) {
  let userAttributes: any = null;
  if (user) {
    userAttributes = {
      data: userDocument,
      id: user.uid,
    };
  }

  let result = null;
  try {
    result = yield call(development.update, userAttributes, developmentId, developmentDocument, pdfDocument);
    if (result) {
      yield call(thumbnails.upload, thumbnail, developmentId);
    }
  } catch (error) {
    console.error(error);
  }

  return result;
}

/**
 * Save a development to the database.
 */
function* save(action: ReturnType<typeof actions.saveStart>) {
  try {
    const { user, developmentId, developmentDocument, userDocument, pdfDocument, thumbnail } = action.payload;
    if (!developmentId) {
      console.warn(`Development not saved. Invalid ID:${developmentId}`);
      return;
    }

    const result = yield call(
      saveDevelopment,
      user,
      developmentId,
      developmentDocument,
      userDocument,
      pdfDocument,
      thumbnail
    );
    if (result) yield put(actions.saveSuccess(result.userData));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Create a development and save it to the database.
 */
function* create(action: ReturnType<typeof actions.createStart>) {
  try {
    const { user, developmentDocument, userDocument, pdfDocument } = action.payload;

    let userAttributes: any = null;
    if (user) {
      userAttributes = {
        data: userDocument,
        id: user.uid,
      };
    }

    const createResult = yield call(development.create, developmentDocument, userAttributes, pdfDocument);
    if (createResult) {
      if (userAttributes) userAttributes.data = createResult.userData;
      const result = yield call(development.read, createResult.newProjectId, userAttributes);
      yield put(actions.createSuccess(result.projectData, result.userData, result.newProjectId));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Duplicate a development and save it to the database.
 */
function* duplicate(action: ReturnType<typeof actions.duplicateStart>) {
  try {
    const { user, developmentDocument, developmentName, pdfDocument, currentDevelopmentIsEdited } = action.payload;
    if (!user) return;

    let userData;
    if (currentDevelopmentIsEdited) {
      const saveSuccessAction: ReturnType<typeof actions.saveSuccess> = yield take(developmentActionTypes.SAVE_SUCCESS);
      userData = saveSuccessAction.payload.userDocument;
    } else {
      userData = yield select(userSelectors.getUserDocument);
    }

    const userAttributes = {
      data: userData,
      id: user.uid,
    };

    const developmentCopy = jsonDeepCopy(developmentDocument);
    developmentAccessors.setName(developmentCopy, developmentName);

    const createResult = yield call(development.create, developmentCopy, userAttributes, pdfDocument);
    if (!createResult) return;

    yield put(actions.loadStart(user, createResult.newProjectId, createResult.userData));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Remove a development from the database.
 */
function* remove(action: ReturnType<typeof actions.removeStart>) {
  try {
    const { user, userDocument, projectId } = action.payload;
    if (!user) return;
    const userAttributes = {
      data: userDocument,
      id: user.uid,
    };

    const result = yield call(development.remove, projectId, userAttributes);
    if (result) {
      yield call(thumbnails.remove, projectId);
      yield call(pdfCovers.remove, projectId);
      yield call(pdfLogos.remove, projectId);
      yield put(actions.removeSuccess(result.userData));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Set development as shared.
 */
function* setAsShared(action: ReturnType<typeof actions.setProjectIsShared>) {
  try {
    yield call(sharedProjects.create, action.payload.developmentId);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Switch developments.
 */
function* switchDevelopment(action: ReturnType<typeof actions.switchDevelopment>) {
  try {
    const { user, developmentId, currentDevelopmentIsEdited } = action.payload;
    if (!user) return;

    let userData;
    if (currentDevelopmentIsEdited) {
      const saveSuccessAction: ReturnType<typeof actions.saveSuccess> = yield take(developmentActionTypes.SAVE_SUCCESS);
      userData = saveSuccessAction.payload.userDocument;
    } else {
      userData = yield select(userSelectors.getUserDocument);
    }

    yield put(actions.loadStart(user, developmentId, userData));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Reset state.
 */
function* resetState(action: ReturnType<typeof actions.resetStateStart>) {
  const projectIsEdited = yield select(developmentSelectors.getIsEdited);
  if (projectIsEdited) {
    yield take(actionTypes.SAVE_SUCCESS);
  }

  yield put(actions.resetStateContinue());
}

/**
 * Watcher for `switchDevelopment` action.
 */
function* watchSwitchDevelopment() {
  yield takeEvery(actionTypes.SWITCH_DEVELOPMENT, switchDevelopment);
}

/**
 * Watcher for `setProjectIsShared` action.
 */
function* watchSetProjectIsShared() {
  yield takeEvery(actionTypes.SET_PROJECT_IS_SHARED, setAsShared);
}

/**
 * Watcher for `removeStart` action.
 */
function* watchRemove() {
  yield takeEvery(actionTypes.REMOVE_START, remove);
}

/**
 * Watcher for `duplicateStart` action.
 */
function* watchDuplicate() {
  yield takeEvery(actionTypes.DUPLICATE_START, duplicate);
}

/**
 * Watcher for `createStart` action.
 */
function* watchCreate() {
  yield takeEvery(actionTypes.CREATE_START, create);
}

/**
 * Watcher for `saveStart` action.
 */
function* watchSave() {
  yield takeEvery(actionTypes.SAVE_START, save);
}

/**
 * Watcher for `loadStart` action.
 */
function* watchLoad() {
  yield takeLatest(actionTypes.LOAD_START, load);
}

/**
 * Watcher for the `resetStateStart` action.
 */
function* watchResetState() {
  yield takeEvery(actionTypes.RESET_STATE_START, resetState);
}

export default {
  watchLoad,
  watchSave,
  watchCreate,
  watchDuplicate,
  watchRemove,
  watchSetProjectIsShared,
  watchSwitchDevelopment,
  watchResetState,
};
