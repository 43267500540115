import React from "react";
import { Page, View, Link, StyleSheet } from "@react-pdf/renderer";
import companyInfo from "utils/companyInfo";
import Footer from "../Footer";

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "762px",
  },
  footerLink: {
    fontFamily: "Roboto Condensed Bold",
    color: "white",
  },
});

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  wrap?: boolean;
}

const StandardPage = ({ children, backgroundColor, wrap }: Props) => (
  <Page size="LETTER" wrap={Boolean(wrap)}>
    <View style={[styles.content, { backgroundColor }]}>{children}</View>

    <Footer>
      Prepared with Deepblocks. Find out more at{" "}
      <Link style={styles.footerLink} src={companyInfo.WEBSITE_URL}>
        deepblocks.com
      </Link>
      .
    </Footer>
  </Page>
);

export default StandardPage;
