import React from "react";
import WithPanelHeader from "../../sharedComponents/WithPanelHeader";
import wrapComponentWithPopup, { PopupProps } from "../wrapComponentWithPopup/wrapComponentWithPopup";
import { subscriptionSelectors } from "../../../state/subscription";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    creditCard: subscriptionSelectors.getCreditCard(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = PopupProps & StateProps;

class BusyPopup extends React.PureComponent<Props, {}> {
  render() {
    const { creditCard } = this.props;
    return (
      <WithPanelHeader title={`${creditCard ? "Expired" : "No"} Payment Method`} hideControls>
        <div className="component--no-credit-card-popup">
          <div className="text">
            {`Please ${creditCard ? "update your" : "add a"} payment method and try again.`}
          </div>

          <button onClick={this.props.closePopup}>Close</button>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
  )(BusyPopup)
);
