import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { authenticationSelectors } from "state/authentication";
import { subscriptionSelectors } from "state/subscription";
import { userSelectors } from "state/user";
import { Path } from "types/Path";
import { UserStatus } from "types/UserStatus";
import authentication from "utils/authentication";
import userHelper from "utils/userHelper";
import UserLoadingFeedback from "views/sharedComponents/UserLoadingFeedback";
import RedirectUnsupportedBrowsers from "../RedirectUnsupportedBrowsers";
import { BrowserSupportRequirement } from "types/BrowserSupportRequirement";

const DEFAULT_PATH = {
  [UserStatus.NotAuthenticated]: Path.Home,
  [UserStatus.Authenticated]: Path.Subscribe,
  [UserStatus.Verified]: Path.Dashboard,
  [UserStatus.Subscribed]: Path.Dashboard,
};

const mapStateToProps = (state) => {
  return {
    authenticationIsInitialized:
      authenticationSelectors.getAuthenticationIsInitialized(state),
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
    subscriptionStatus: subscriptionSelectors.getStatus(state),
    userDocumentIsReady: userSelectors.getIsReady(state),
  };
};

interface RouteComponentProps {
  component: React.ElementType;
  allow: UserStatus[];
  browserSupportRequirement?: BrowserSupportRequirement;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & RouteComponentProps;

const RouteComponent: React.FC<Props> = (props) => {
  const {
    authenticationIsInitialized,
    subscriptionStatus,
    userDocumentIsReady,
    component,
    allow,
    userIsAuthenticated,
    browserSupportRequirement,
  } = props;

  const navigate = useNavigate();
  const user = authentication.getCurrentUser();
  const userIsVerified = Boolean(user && user.emailVerified);
  const currentUserStatus = userHelper.getUserStatus(
    userIsAuthenticated,
    userIsVerified,
    subscriptionStatus
  );

  useEffect(() => {
    if (
      userHelper.userStatusIsReady(
        authenticationIsInitialized,
        userIsVerified,
        subscriptionStatus,
        userDocumentIsReady
      )
    ) {
      if (!allow.includes(currentUserStatus)) {
        navigate(DEFAULT_PATH[currentUserStatus], { replace: true });
      }
    }
  }, [
    allow,
    authenticationIsInitialized,
    currentUserStatus,
    navigate,
    userDocumentIsReady,
    userIsVerified,
    subscriptionStatus,
  ]);

  if (
    !userHelper.userStatusIsReady(
      authenticationIsInitialized,
      userIsVerified,
      subscriptionStatus,
      userDocumentIsReady
    )
  ) {
    // We are fetching the current user status
    // Let's render a user loading feedback while we wait.
    return <UserLoadingFeedback />;
  }

  if (!allow.includes(currentUserStatus)) {
    return <Navigate replace to={DEFAULT_PATH[currentUserStatus]} />;
  }

  return (
    <RedirectUnsupportedBrowsers
      requiredSupportLevel={browserSupportRequirement}
    >
      {React.createElement(component)}
    </RedirectUnsupportedBrowsers>
  );
};

export default connect(mapStateToProps)(RouteComponent);
