/**
 * Format the allowed uses string for the CSV.
 */
const formatAllowedUsesForCsv = (value: string | null | undefined) => {
  // NOTE: The data consumed by this formatter uses overloaded separators, complicating the
  // implementation. When there are only top-level use categories, they are separated by commas:
  //
  //    "use1, use2, use3"
  //
  // When all top-level uses have sub-uses, the top-level uses are separated by periods, and the
  // subuses are separated by commas:
  //
  //    "use1: subuseA, subuseB. use2: subuseC, subuseD"
  //
  // In some cases, these formats are mixed, with some top-level uses having no subuses, and some
  // having them. The lone top-level uses are separated by commas in this case:
  //
  //    "use1, use2, use3: subuseA, subuseB. use4: subuseC, subuseD"

  if (!value) return "";

  const tentativeGroups = value
      .split(".")
      .filter((str) => str !== "");

  const constructedStrings = tentativeGroups.map((tentativeGroup) => {
    let [categories, subcategories] = tentativeGroup.split(":");

    return subcategories ? `${categories}, ${subcategories}` : categories;
  })

  return constructedStrings.join(",");
}

export default formatAllowedUsesForCsv;
