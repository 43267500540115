import React from "react";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerLegend from "../sharedComponents/DynamicLayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class DynamicPopulationLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <DynamicLayerLegend
        layerGroup={DynamicLayerId.Dynamic_Layer_Population}
        classes="population"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< 1,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fd0d0d 0%, #fff305 100%)"
            text="1,000 - 2,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fff305 0%, #0fe600 100%)"
            text="2,000 - 3,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #0fe600 0%, #16e7fe 100%)"
            text="3,000 - 5,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #16e7fe 0%, #024be8 100%)"
            text="5,000 - 12,000"
            wide
          />,
          <LegendRow
            color="#024be8"
            text="> 12,000"
            wide
          />,
        ]}
      />
    );
  }
}

export default DynamicPopulationLegend;
