import React from "react";
import BusySpinner from "../BusySpinner";
import CancelLoadingButton from "../CancelLoadingButton";
import wrapComponentWithPopup from "../wrapComponentWithPopup";

interface Props {
  text: string;
  cancelButtonLabel: string;
  cancelButtonClick(): void;
}

class BusyPopup extends React.PureComponent<Props, {}> {

  render() {
    const { cancelButtonLabel, cancelButtonClick } = this.props;
    const renderCancelButton = cancelButtonLabel && cancelButtonClick;
    return (
      <div className="component--busy-popup">
        <div className="text-box">{this.props.text}</div>
        <BusySpinner />
        {renderCancelButton && <CancelLoadingButton label={cancelButtonLabel} onClick={cancelButtonClick}/>}
      </div>
    );
  }
}

export default wrapComponentWithPopup(BusyPopup);
