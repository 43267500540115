import React from "react"

interface OwnProps {
  children(
    onTabClick: (tabId: string) => void,
    activeTab: string,
  );
  defaultTab: string;
}

interface State {
  activeTab: string;
}

type Props = OwnProps;

class Tabs extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: props.defaultTab,
    }
  }

  /**
   * Set active tab id.
   */
  onTabClick = (tabId: string) => {
    this.setState({ activeTab: tabId });
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className="component--tabs">
        {this.props.children(this.onTabClick, activeTab)}
      </div>
    )
  }
}

export default Tabs;
