import { SetbackModeState } from "./reducers";

/**
 * Select the whole setbackMode state.
 */
const getSetbackModeState = (state): SetbackModeState => {
  return state.setbackMode;
}

/**
 * Get the setback mode flag.
 */
const getSetbackModeIsActive = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.setbackModeIsActive;
}

/**
 * Get the selectedPolygonIndex value.
 */
const getSelectedPolygonIndex = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.selectedPolygonIndex;
}

/**
 * Get the selectedEdgeIndex value.
 */
const getSelectedEdgeIndex = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.selectedEdgeIndex;
}

/*
 * Get the measurementTool value.
 */
const getMeasurementTool = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.measurementTool;
}

/**
 * Get the measurementToolFromToolbar value.
 */
 const getMeasurementToolFromToolbar = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.measurementToolFromToolbar;
}

/**
 * Get the shapeIsBeingChanged value.
 */
const getShapeIsBeingChanged = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.shapeIsBeingChanged;
}

/**
 * Get the shapeArea value.
 */
const getShapeArea = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.shapeArea;
}

/**
 * Get the shapePerimeter value.
 */
const getShapePerimeter = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.shapePerimeter;
}

/**
 * Get the visibility of the `Area Label` in Setback mode.
 */
 const getAreaLabelVisibility = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.areaLabelVisibility;
}

/**
 * Get the visibility of the `Setback Type Markers` in Setback mode.
 */
 const getSetbackTypeMarkerVisibility = (state) => {
  let setbackModeState = getSetbackModeState(state);
  return setbackModeState.setbackTypeMarkerVisibility;
}

export default {
  getSetbackModeIsActive,
  getSelectedPolygonIndex,
  getSelectedEdgeIndex,
  getMeasurementTool,
  getMeasurementToolFromToolbar,
  getShapeIsBeingChanged,
  getShapeArea,
  getShapePerimeter,
  getAreaLabelVisibility,
  getSetbackTypeMarkerVisibility,
}
