import React from "react";
import { connect } from "react-redux";
import { pdfActions, pdfSelectors } from "../../../../../state/pdf";
import Pdf from "../../../../../types/Pdf";
import analytics from "../../../../../utils/analytics";
import ColorPalette from "./ColorPalette";
import FromContactDetails from "./FromContactDetails";
import ImageUpload from "./ImageUpload";
import Summary from "./Summary";
import ToContactDetails from "./ToContactDetails";
import authentication from "utils/authentication";

const mapStateToProps = (state) => {
  return {
    pdfIsActive: pdfSelectors.getIsActive(state),
    title: pdfSelectors.getTitle(state),
    address: pdfSelectors.getAddress(state),
  };
};

const mapDispatchToProps = {
  startPdf: pdfActions.start,
  setTitle: pdfActions.setTitle,
  setAddress: pdfActions.setAddress,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class PdfPanel extends React.PureComponent<Props, {}> {
  /**
   * Start the generation of the pdf document if there isn't one already being generated.
   */
  startPdf = () => {
    if (!this.props.pdfIsActive) {
      analytics.trackGeneratePdfReport();
      this.props.startPdf();
    }
  };

  /**
   * Save report title in the store.
   */
  updateReportTitle = (event) => {
    this.props.setTitle(event.target.value);
  };

  /**
   * Save address details changes in the store.
   */
  handleAddressChanges = (value, addressKey: keyof Pdf.Address) => {
    this.props.setAddress({ [addressKey]: value });
  };

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    return (
      <div className="component--pdf-panel">
        <div className="panel-inner-wrapper">
          <div className="export-button-box">
            <button id="export-pdf-button" onClick={this.startPdf}>
              Export PDF Report
            </button>
          </div>
          <input
            className="report-title"
            value={this.props.title}
            onChange={this.updateReportTitle}
            autoComplete="is-off"
            placeholder="Enter Report Title"
            maxLength={30}
          />
          <input
            className="address"
            value={this.props.address.address}
            onChange={(event) =>
              this.handleAddressChanges(event.target.value, "address")
            }
            autoComplete="is-off"
            placeholder="Property Address"
            maxLength={50}
          />
          <div className="address-container">
            <input
              className="city"
              value={this.props.address.city}
              onChange={(event) =>
                this.handleAddressChanges(event.target.value, "city")
              }
              autoComplete="is-off"
              placeholder="City"
              maxLength={10}
            />
            <input
              className="state"
              value={this.props.address.state}
              onChange={(event) =>
                this.handleAddressChanges(event.target.value, "state")
              }
              autoComplete="is-off"
              placeholder="State"
              maxLength={10}
            />
            <input
              className="zip-code"
              value={this.props.address.zipCode}
              onChange={(event) =>
                this.handleAddressChanges(event.target.value, "zipCode")
              }
              autoComplete="is-off"
              placeholder="Zip Code"
              maxLength={8}
            />
          </div>
          <FromContactDetails />
          <ToContactDetails />
          <Summary />
          {userIsAuthenticated && (
            <>
              <ImageUpload
                classPrefix="cover"
                labelText="Choose Cover Image"
                imageType={Pdf.ImageType.Cover}
              />
              <span className="dimension-message">
                826px by 566px (recommended)
              </span>
            </>
          )}
          {userIsAuthenticated && (
            <>
              <ImageUpload
                classPrefix="logo"
                labelText="Choose Your Company Logo"
                imageType={Pdf.ImageType.Logo}
              />
              <span className="dimension-message">
                152px by 29px (recommended)
              </span>
            </>
          )}
          <ColorPalette />
          <div className="end" />{" "}
          {/* HAck for safari to respect the bottom margin of button above */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfPanel);
