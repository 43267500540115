import { createReducer } from "@reduxjs/toolkit";
import { GeoJSON } from "geojson";
import actions from "./actions";

interface LayersState {
  hotelAverageDailyRateFeatures: Array<GeoJSON>;
  industrialYearlyRentFeatures: Array<GeoJSON>;
  multifamilyMonthlyRentFeatures: Array<GeoJSON>;
  officeYearlyRentFeatures: Array<GeoJSON>;
  retailYearlyRentFeatures: Array<GeoJSON>;
}

const DEFAULT_STATE: LayersState = {
  hotelAverageDailyRateFeatures: [],
  industrialYearlyRentFeatures: [],
  multifamilyMonthlyRentFeatures: [],
  officeYearlyRentFeatures: [],
  retailYearlyRentFeatures: [],
}

const reducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(actions.generateRentFeaturesSuccess, (state, action) => {
      state.hotelAverageDailyRateFeatures = action.payload.hotelAverageDailyRateFeatures;
      state.industrialYearlyRentFeatures = action.payload.industrialRateFeatures;
      state.multifamilyMonthlyRentFeatures = action.payload.multifamilyRentFeatures;
      state.officeYearlyRentFeatures = action.payload.officeRateFeatures;
      state.retailYearlyRentFeatures = action.payload.retailRateFeatures;
    })
})

export default reducer;
