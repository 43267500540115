import { ParcelProperty } from "utils/parcel/ParcelProperty";

export enum FilterId {
  ExistingStructureArea = "existingStructureArea",
  ExistingStructureAreaOpenSource = "existingStructureAreaOpenSource",
  MedianIncomeTotal = "medianIncomeTotal",
  GrossMedianRent = "grossMedianRent",
  FloorAreaRatio = "floorAreaRatio",
  ExistingYearBuilt = "existingYearBuilt",
  NumberOfUnitsAllowed = "numberOfUnitsAllowed",
  NumberOfResidentialUnits = "numberOfResidentialUnits",
  BuildingHeight = "buildingHeight",
  BuildableArea = "buildableArea",
  AreaPublished = "areaPublished",
  ExistingBuildingHeight = "existingBuildingHeight",
}

export type Filters = {
  [key in FilterId]: Filter;
};

export interface Filter {
  id: FilterId;
  variableId: ParcelProperty;
  isActive: boolean;
  shouldDisplay: boolean;
  max?: number;
  value: Array<number>;
  allowUnauthenticated?: boolean;
}
