import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the industrial buildables presets.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.IndustrialLossFactor) as number;
  const netLeasableArea = targetArea * (1 - lossFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development, VariableId.IndustrialNetLeasableAreaToggleable);

  return {
    [VariableId.IndustrialNetLeasableAreaToggleable]: {
      "minimum": 0,
      "maximum": 2 * netLeasableArea || previousMaximum,
      "value": netLeasableArea
    },
    [VariableId.IndustrialYearOneRentPerArea]: await generatorFromModel.generateRentPreset(development, VariableId.IndustrialYearOneRentPerArea, BuildingUse.Industrial),
    [VariableId.IndustrialHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.IndustrialHardCostPerArea, BuildingUse.Industrial),
  };
}

export default generatePresets;
