import { ChargeBeeErrorCode, UserMappingError } from "types/ChargeBeeErrors";

/**
 * Map the error coming from chargeBee when the crad is declined.
 * @param errorMessage
 * @returns error message to show to the user.
 */
const handleMappingError = (errorMessage: string) => {
  if(errorMessage.includes(ChargeBeeErrorCode.Declined)) {
    return UserMappingError.CardDeclined
  }
  return errorMessage;
}

export default handleMappingError;