import React from "react";
import { Document } from "@react-pdf/renderer";
import CoverPage from "./CoverPage";
import OverviewPage from "./OverviewPage";
// import GraphsPage from "./GraphsPage";
// import AnalysisPage from "./AnalysisPage";

const PdfDocument = (props) => (
  <Document title="Deepblocks Property Report" onRender={props.onRender}>
    <CoverPage />
    <OverviewPage />
    {/* <GraphsPage /> */}
    {/* <AnalysisPage /> */}
  </Document>
);

export default PdfDocument;
