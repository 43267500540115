import developmentAccessors from "../../../state/development/utils/developmentAccessors";
import parcelAccessors from "../../parcel/parcelAccessors";
import { Development } from "../../../types/Development/Development";
import { VariableId } from "../../../types/VariableId";
import salesModel, { SalesUseType } from "./generatePresetsForBuildables/utils/salesModel";
import unitConversions from "../../unitConversions";
import Unit from "../../../types/Unit";

/**
 * Generate a JSON object with the purchase price presets.
 *
 * This implementation applies a simple price-per-square-meter formula for the
 * land.
 */
const generatePresetsForPurchasePrice = async (development: Development) => {
  const parcel = developmentAccessors.getParcel(development);
  const parcelArea = parcelAccessors.getAreaPreferPublished(parcel);
  const existingStructureArea = parcelAccessors.getExistingStructureArea(parcel)

  try {
    const assemblyParcels = parcelAccessors.getIsAnAssembly(parcel) ? parcelAccessors.getAssemblyParcels(parcel) : [parcel];

    let purchasePrice = 0;
    let combinedArea = 0;
    for (let assemblyParcel of assemblyParcels) {
      const buildingFeatureModels = parcelAccessors.getBuildingFeatureModel(assemblyParcel);
      const salesUseType = existingStructureArea || buildingFeatureModels.length > 0
        ? SalesUseType.Building
        : SalesUseType.Land;

      const features = salesModel.buildFeatures(development, salesUseType);
      const pricePerSquareFeet = await salesModel.predict(features);
      const pricePerSquareMeter = unitConversions.convert(pricePerSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters, true);

      const area = parcelAccessors.getAreaForPurchasePrice(assemblyParcel);
      purchasePrice += pricePerSquareMeter * area;
      combinedArea += area;
    }

    return {
      [VariableId.ParcelPurchasePrice]: {
        "minimum": Math.round(purchasePrice / 2),
        "maximum": Math.round(purchasePrice * 2),
        "value": Math.round(purchasePrice)
      },
      [VariableId.ParcelPurchasePriceArea]: {
        "value": combinedArea,
      }
    }
  } catch (error) {
    console.warn(error);
    console.warn("ERROR in presets for purchase price, falling back to default.");

    return {
      [VariableId.ParcelPurchasePrice]: {
        "minimum": 0,
        "maximum": Math.round(parcelArea * 1500.00),
        "value": Math.round(parcelArea * 600.00)
      }
    };
  }
}

export default generatePresetsForPurchasePrice;
