import valueGetters from "state/development/utils/valueGetters";
import { BuildingUse } from "types/BuildingUse";
import { Config } from "types/Config";
import Format from "types/Format";
import Unit from "types/Unit";
import { VariableId } from "types/VariableId";
import valueFormatter from "utils/valueFormatter";


export interface CsvRows {
  rowName: string,
  variableId: VariableId;
  usageGroup: BuildingUse;
  parentCustomName?: VariableId;
  suffix?: string;
  formatter: (value: any, unitSystem?: any) => string;
};

interface UnitProps {
  unitTarget: Unit.Type;
  unitIsInverse?: boolean;
};

export enum ProjectData {
  Name = "Project Name",
  Address = "Address",
  City = "City",
  State = "State"
};

export type CSVConfig = {
  [key in Unit.System]?: UnitProps;
};


const config: Config = {

  /**
   * CSV Export for Assumptions.
   */

  // <Property>

  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Total Lot SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Building SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    },
    getter: valueGetters.forExistingBuildingAreaInAssumptions,
  },

   // <Building Program>

  [VariableId.ParkingGrossBuildableArea]: {
    variableId: VariableId.ParkingGrossBuildableArea,
    text: "Parking GSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.RetailGrossBuildableArea]: {
    variableId: VariableId.RetailGrossBuildableArea,
    text: "Retail GSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
    }
  },

  [VariableId.MultifamilyNetLeasableArea]: {
    variableId: VariableId.MultifamilyNetLeasableArea,
    text: "Multifamily NSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.MultifamilyAmenitiesAreaToggleable]: {
    variableId: VariableId.MultifamilyAmenitiesAreaToggleable,
    text: "Multifamily Amenity NSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.CondoNetSellableArea]: {
    variableId: VariableId.CondoNetSellableArea,
    text: "Condo NSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.CondoAmenitiesAreaToggleable]: {
    text: "Condo Amenities",
    variableId: VariableId.CondoAmenitiesAreaToggleable,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.HotelNetBookableArea]: {
    variableId: VariableId.HotelNetBookableArea,
    text: "Hotel NSF",
    formatOptions: { type: Format.Type.Number, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.HotelAmenitiesAreaToggleable]: {
    text: "Hotel Amenities",
    variableId: VariableId.HotelAmenitiesAreaToggleable,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    text: "Office GSF",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.IndustrialGrossBuildableArea]: {
    variableId: VariableId.IndustrialGrossBuildableArea,
    text: "Industrial GSF",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  // <Income Assumptions>
  [VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    text: "Project Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    text: "Multifamily Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    text: "Retail Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope,
    text: "Hotel Gross Income",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    text: "Office Gross Income",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    text: "Office Gross Income",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope,
    text: "Project Total Vacancy",
  },

  [VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    text: "Project Effective Income",
  },

  [VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope,
    text: "Project Total OpEx",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Net Operating Income",
    formatOptions: {
      type: Format.Type.Currency,
    },
  },

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Total Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.SaleReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.SaleReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  // <Sales Assumptions>
  [VariableId.ExitCapRate]: {
    variableId: VariableId.ExitCapRate,
    text: "Exit Cap Rate",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    },
  },

  [VariableId.CondoGrossSales]: {
    variableId: VariableId.CondoGrossSales,
    text: "Condo Gross Sales",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope,
    text: "Estimated Value",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.TotalProjectSaleForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleForBackOfEnvelope,
    text: "Total Project Sale",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.ProjectSaleCommissionPercentage]: {
    variableId: VariableId.ProjectSaleCommissionPercentage,
    text: "Sales Commission",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    }
  },

  [VariableId.TotalProjectSalesCommissions]: {
    variableId: VariableId.TotalProjectSalesCommissions,
    text: "Sales Cost",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  [VariableId.TotalProjectSaleProfitForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleProfitForBackOfEnvelope,
    text: "Total Profit",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  // <Cost Assumptions>
  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
  },

  [VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      text: "Avg All-In Cost PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "Avg All-In Cost PSM",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.ExistingStructureDemolitionCost]: {
    variableId: VariableId.ExistingStructureDemolitionCost,
    text: "Total Demolition Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ExistingStructureDemolitionCostPerArea]: {
    variableId: VariableId.ExistingStructureDemolitionCostPerArea,
    text: "Demolition Cost PSF",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.ProjectHardCost]: {
    variableId: VariableId.ProjectHardCost,
    text: "Total Hard Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectHardCostPerGrossBuildableArea]: {
    variableId: VariableId.ProjectHardCostPerGrossBuildableArea,
    text: "Total Hard Cost",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.CondoHardCostPerArea]: {
    variableId: VariableId.CondoHardCostPerArea,
    text: "Condo Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.MultifamilyHardCostPerArea]: {
    variableId: VariableId.MultifamilyHardCostPerArea,
    text: "Multifamily Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.RetailHardCostPerArea]: {
    variableId: VariableId.RetailHardCostPerArea,
    text: "Retail Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.HotelHardCostPerArea]: {
    variableId: VariableId.HotelHardCostPerArea,
    text: "Hotel Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.OfficeHardCostPerArea]: {
    variableId: VariableId.OfficeHardCostPerArea,
    text: "Office Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.IndustrialHardCostPerArea]: {
    variableId: VariableId.IndustrialHardCostPerArea,
    text: "Industrial Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.ParkingHardCostPerArea]: {
    variableId: VariableId.ParkingHardCostPerArea,
    text: "Parking Hard Cost Per Area",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.ProjectHardCostPerGrossBuildableArea]: {
    variableId: VariableId.ProjectHardCostPerGrossBuildableArea,
    text: "",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    }
  },

  [VariableId.SoftCostPercentage]: {
    variableId: VariableId.SoftCostPercentage,
    text: "",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.ProjectSoftCost]: {
    variableId: VariableId.ProjectSoftCost,
    text: "",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ContingencyCostPercentage]: {
    variableId: VariableId.ContingencyCostPercentage,
    text: "",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.ProjectContingencyCost]: {
    variableId: VariableId.ProjectContingencyCost,
    text: "",
    formatOptions: { type: Format.Type.Currency },
  },

  // <Multifamily Assumptions>
  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.MultifamilyMicrounitQuantityToggleable]: {
    variableId: VariableId.MultifamilyMicrounitQuantityToggleable,
    text: "Bedroom type 1 name",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyStudioQuantityToggleable]: {
    variableId: VariableId.MultifamilyStudioQuantityToggleable,
    text: "Bedroom type 2 name",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyOneBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyOneBedQuantityToggleable,
    text: "Bedroom type 3 name",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyTwoBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyTwoBedQuantityToggleable,
    text: "Bedroom type 4 name",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyThreeBedQuantityToggleable]: {
    variableId: VariableId.MultifamilyThreeBedQuantityToggleable,
    text: "Bedroom type 5 name",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units"
    }
  },

  [VariableId.MultifamilyGrossBuildableArea]: {
    variableId: VariableId.MultifamilyGrossBuildableArea,
    text: "Multifamily Gross Buildable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyMicrounitArea]: {
    variableId: VariableId.MultifamilyMicrounitArea,
    text: "Bedroom type 1 name",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyStudioArea]: {
    variableId: VariableId.MultifamilyStudioArea,
    text: "Bedroom type 2 name",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyOneBedArea]: {
    variableId: VariableId.MultifamilyOneBedArea,
    text: "Bedroom type 3 name",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyTwoBedArea]: {
    variableId: VariableId.MultifamilyTwoBedArea,
    text: "Bedroom type 4 name",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyThreeBedArea]: {
    variableId: VariableId.MultifamilyThreeBedArea,
    text: "Bedroom type 5 name",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyAmenitiesAreaToggleable]: {
    variableId: VariableId.MultifamilyAmenitiesAreaToggleable,
    text: "Multifamily Amenity GSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyLossFactor]: {
    variableId: VariableId.MultifamilyLossFactor,
    text: "Multifamily Loss Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    text: "Multifamily Gross Income",
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerMicrounit,
    text: "Bedroom type 1 Rent",
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerStudio,
    text: "Bedroom type 2 Rent",
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerOneBed,
    text: "Bedroom type 3 Rent",
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerTwoBed,
    text: "Bedroom type 4 Rent",
  },

  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: {
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerThreeBed,
    text: "Bedroom type 3 Rent",
  },

  [VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Multifamily NOI",
  },

  [VariableId.MultifamilyStabilizedVacancyPercentage]: {
    variableId: VariableId.MultifamilyStabilizedVacancyPercentage,
    text: "Multifamily Vacancy",
  },

  [VariableId.MultifamilyOperatingExpensePercentage]: {
    variableId: VariableId.MultifamilyOperatingExpensePercentage,
    text: "Multifamily OpEx",
  },

  // <Retail Assumptions>
  [VariableId.RetailGrossBuildableArea]: {
    variableId: VariableId.RetailGrossBuildableArea,
    text: "Retail Gross Buildable",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.RetailNetLeasableAreaToggleable]: {
    variableId: VariableId.RetailNetLeasableAreaToggleable,
    text: "Retail Net Leasable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.RetailLossFactor]: {
    variableId: VariableId.RetailLossFactor,
    text: "Retail Loss Factor",
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    text: "Retail Gross Income",
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    text: "Retail Lease Income PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.RetailYearOneExpenseReimbursementFeePerArea,
    text: "Retail Expense Reimbursement PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", decimalPlaces: 2 },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", decimalPlaces: 2 },
    }
  },

  [VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Retail NOI",
  },

  [VariableId.RetailStabilizedVacancyPercentage]: {
    variableId: VariableId.RetailStabilizedVacancyPercentage,
    text: "Retail Vacancy",
  },

  [VariableId.RetailYearOneOperatingExpensePerArea]: {
    variableId: VariableId.RetailYearOneOperatingExpensePerArea,
    text: "Retail OpEx PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  // <Hotel Assumptions>
  [VariableId.HotelGrossBuildableArea]: {
    variableId: VariableId.HotelGrossBuildableArea,
    text: "Hotel Gross Buildable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.HotelRoomQuantityToggleable]: {
    variableId: VariableId.HotelRoomQuantityToggleable,
    text: "Hotel Gross Buildable SF",
  },

  [VariableId.HotelAverageRoomArea]: {
    variableId: VariableId.HotelAverageRoomArea,
    text: "Average Room Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelLossFactor]: {
    variableId: VariableId.HotelLossFactor,
    text: "Average Room Area",
  },

  [VariableId.HotelAmenitiesAreaToggleable]: {
    variableId: VariableId.HotelAmenitiesAreaToggleable,
    text: "Hotel Amenities",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope,
    text: "Hotel Gross Income",
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    text: "ADR (Average Daily Rate)",
  },

  [VariableId.HotelOtherRevenuePercentage]: {
    variableId: VariableId.HotelOtherRevenuePercentage,
    text: "Other Revenue (Percent of Hotel Income)",
  },

  [VariableId.HotelAnnualRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRevenueForBackOfEnvelope,
    text: "Hotel Effective Income",
  },

  [VariableId.HotelStabilizedOccupancyPercentage]: {
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    text: "Hotel Occupancy",
  },

  [VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Hotel NOI",
  },

  [VariableId.HotelOperatingExpensePercentage]: {
    variableId: VariableId.HotelOperatingExpensePercentage,
    text: "Hotel OpEx",
  },

  // <Office Assumptions>
  [VariableId.OfficeGrossBuildableArea]: {
    variableId: VariableId.OfficeGrossBuildableArea,
    text: "Office Gross Buildable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.OfficeNetUsableAreaToggleable]: {
    variableId: VariableId.OfficeNetUsableAreaToggleable,
    text: "Office Usable Area SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.OfficeCommonAreaFactor]: {
    variableId: VariableId.OfficeCommonAreaFactor,
    text: "Common Area Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    text: "Office Gross Income",
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    text: "Office Lease Income PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.OfficeYearOneExpenseReimbursementFeePerArea,
    text: "Office Expense Reimbursement PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF", decimalPlaces: 2 }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²", decimalPlaces: 2 }
    }
  },

  [VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Office NOI",
  },

  [VariableId.OfficeStabilizedVacancyPercentage]: {
    variableId: VariableId.OfficeStabilizedVacancyPercentage,
    text: "Office Vacancy",
  },

  [VariableId.OfficeYearOneOperatingExpensePerArea]: {
    variableId: VariableId.OfficeYearOneOperatingExpensePerArea,
    text: "Office Expense Reimbursement PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  // <Industrial Assumptions>
  [VariableId.IndustrialGrossBuildableArea]: {
    variableId: VariableId.IndustrialGrossBuildableArea,
    text: "Industrial Gross Buildable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.IndustrialNetLeasableAreaToggleable]: {
    variableId: VariableId.IndustrialNetLeasableAreaToggleable,
    text: "Industrial Net Leasable",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.IndustrialLossFactor]: {
    variableId: VariableId.IndustrialLossFactor,
    text: "Industrial Loss Factor",
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    text: "Industrial Gross Income",
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    text: "Industrial Lease Income PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    },
  },

  [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.IndustrialYearOneExpenseReimbursementFeePerArea,
    text: "Industrial Expense Reimbursement",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF", decimalPlaces: 2 }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²", decimalPlaces: 2 }
    },
  },

  [VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Industrial NOI",
  },

  [VariableId.IndustrialStabilizedVacancyPercentage]: {
    variableId: VariableId.IndustrialStabilizedVacancyPercentage,
    text: "Industrial Vacancy",
  },

  [VariableId.IndustrialYearOneOperatingExpensePerArea]: {
    variableId: VariableId.IndustrialYearOneOperatingExpensePerArea,
    text: "OpEx PSF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    },
  },

  // <Condo Assumptions>
  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Total Condo Units",
  },
  [VariableId.CondoMicrounitQuantityToggleable]: {
    text: "Bedroom type 1 name",
    variableId: VariableId.CondoMicrounitQuantityToggleable,
  },

  [VariableId.CondoStudioQuantityToggleable]: {
    text: "Bedroom type 2 name",
    variableId: VariableId.CondoStudioQuantityToggleable,
  },

  [VariableId.CondoOneBedQuantityToggleable]: {
    text: "Bedroom type 3 name",
    variableId: VariableId.CondoOneBedQuantityToggleable,
  },

  [VariableId.CondoTwoBedQuantityToggleable]: {
    text: "Bedroom type 4 name",
    variableId: VariableId.CondoTwoBedQuantityToggleable,
  },

  [VariableId.CondoThreeBedQuantityToggleable]: {
    text: "Bedroom type 5 name",
    variableId: VariableId.CondoThreeBedQuantityToggleable,
  },

  [VariableId.CondoGrossBuildableArea]: {
    variableId: VariableId.CondoGrossBuildableArea,
    text: "Condo Gross Buildable SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.CondoMicrounitArea]: {
    text: "Bedroom type 1 SF",
    variableId: VariableId.CondoMicrounitArea,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoStudioArea]: {
    text: "Bedroom type 2 SF",
    variableId: VariableId.CondoStudioArea,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoOneBedArea]: {
    variableId: VariableId.CondoOneBedArea,
    text: "Bedroom type 3 SF",
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoTwoBedArea]: {
    text: "Bedroom type 4 SF",
    variableId: VariableId.CondoTwoBedArea,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoThreeBedArea]: {
    text: "Bedroom type 5 SF",
    variableId: VariableId.CondoThreeBedArea,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoAmenitiesAreaToggleable]: {
    text: "Condo Amenities GSF",
    variableId: VariableId.CondoAmenitiesAreaToggleable,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", }
    },
  },

  [VariableId.CondoLossFactor]: {
    variableId: VariableId.CondoLossFactor,
    text: "Condo Loss Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.CondoGrossSales]: {
    text: "Condo Sales",
    variableId: VariableId.CondoGrossSales,
  },

  [VariableId.CondoSalePricePerMicrounit]: {
    text: "Bedroom type 1 Sale",
    variableId: VariableId.CondoSalePricePerMicrounit,
  },

  [VariableId.CondoSalePricePerStudio]: {
    text: "Bedroom type 2 Sale",
    variableId: VariableId.CondoSalePricePerStudio,
  },

  [VariableId.CondoSalePricePerOneBed]: {
    text: "Bedroom type 3 Sale",
    variableId: VariableId.CondoSalePricePerOneBed,
  },

  [VariableId.CondoSalePricePerTwoBed]: {
    text: "Bedroom type 4 Sale",
    variableId: VariableId.CondoSalePricePerTwoBed,
  },

  [VariableId.CondoSalePricePerThreeBed]: {
    text: "Bedroom type 5 Sale",
    variableId: VariableId.CondoSalePricePerThreeBed,
  },
};





export const PROPERTY_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Total Lot SF",
    variableId: VariableId.ParcelArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Existing Building SF",
    variableId: VariableId.ExistingStructureArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
];

export const BUILDING_PROGRAM_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Parking GSF",
    variableId: VariableId.ParkingGrossBuildableArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Retail GSF",
    variableId: VariableId.RetailGrossBuildableArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Multifamily NSF",
    variableId: VariableId.MultifamilyNetLeasableArea,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Multifamily Amenity GSF",
    variableId: VariableId.MultifamilyAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Condo NSF",
    variableId: VariableId.CondoNetSellableArea,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Condo Amenities SF",
    variableId: VariableId.CondoAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Hotel NSF",
    variableId: VariableId.HotelNetBookableArea,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Hotel Amenities SF",
    variableId: VariableId.HotelAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Office GSF",
    variableId: VariableId.OfficeGrossLeasableArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
  {
    rowName: "Industrial GSF",
    variableId: VariableId.IndustrialGrossBuildableArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number}),
  },
];

export const INCOME_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Project Gross Income",
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Multifamily Gross Income",
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Retail Gross Income",
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Hotel Gross Income",
    variableId: VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Office Gross Income",
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Industrial Gross Income",
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Project Total Vacancy",
    variableId: VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Project Total OpEx",
    variableId: VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Net Operating Income",
    variableId: VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Total Project Cost",
    variableId: VariableId.ProjectTotalDevelopmentCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Return on Cost",
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2}),
  },
];

export const SALES_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Exit Cap Rate",
    variableId: VariableId.ExitCapRate,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2}),
  },
  {
    rowName: "Condo Sales",
    variableId: VariableId.CondoGrossSales,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Estimated Income Value",
    variableId: VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Total Project Sale",
    variableId: VariableId.TotalProjectSaleForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Sales Commission",
    variableId: VariableId.ProjectSaleCommissionPercentage,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2}),
  },
  {
    rowName: "Sales Cost",
    variableId: VariableId.TotalProjectSalesCommissions,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Total Project Cost",
    variableId: VariableId.ProjectTotalDevelopmentCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Total Profit",
    variableId: VariableId.TotalProjectSaleProfitForBackOfEnvelope,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
];

 export const COST_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Total Project Cost",
    variableId: VariableId.ProjectTotalDevelopmentCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency}),
  },
  {
    rowName: "Property Purchase Price",
    variableId: VariableId.ParcelPurchasePrice,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Demolition Cost PSF",
    variableId: VariableId.ExistingStructureDemolitionCostPerArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Total Demolition Cost",
    variableId: VariableId.ExistingStructureDemolitionCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Total Hard Cost",
    variableId: VariableId.ProjectHardCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Average Hard Cost PSF",
    variableId: VariableId.ProjectHardCostPerGrossBuildableArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Condo Hard Cost PSF",
    variableId: VariableId.CondoHardCostPerArea,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Multifamily Hard Cost PSF",
    variableId: VariableId.MultifamilyHardCostPerArea,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Retail Hard Cost PSF",
    variableId: VariableId.RetailHardCostPerArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Hotel Hard Cost PSF",
    variableId: VariableId.HotelHardCostPerArea,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Office Hard Cost PSF",
    variableId: VariableId.OfficeHardCostPerArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Industrial Hard Cost PSF",
    variableId: VariableId.IndustrialHardCostPerArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Parking Hard Cost PSF",
    variableId: VariableId.ParkingHardCostPerArea,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Soft Cost",
    variableId: VariableId.SoftCostPercentage,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Total Soft Cost",
    variableId: VariableId.ProjectSoftCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Contingencies",
    variableId: VariableId.ContingencyCostPercentage,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Total Contingencies",
    variableId: VariableId.ProjectContingencyCost,
    usageGroup: BuildingUse.Project,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
];

export const MULTIFAMILY_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Total Multifamily Units",
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Microunits",
    variableId: VariableId.MultifamilyMicrounitQuantityToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.MultifamilyStudioQuantityToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.MultifamilyOneBedQuantityToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.MultifamilyTwoBedQuantityToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.MultifamilyThreeBedQuantityToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Multifamily Gross Buildable SF",
    variableId: VariableId.MultifamilyGrossBuildableArea,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "MicroUnits",
    variableId: VariableId.MultifamilyMicrounitArea,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyMicrounitQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.MultifamilyStudioArea,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyStudioQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.MultifamilyOneBedArea,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyOneBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.MultifamilyTwoBedArea,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyTwoBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.MultifamilyThreeBedArea,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyThreeBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Multifamily Amenity GSF",
    variableId: VariableId.MultifamilyAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Multifamily Loss Factor",
    variableId: VariableId.MultifamilyLossFactor,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Multifamily Gross Income",
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Microunits",
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerMicrounit,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyMicrounitQuantityToggleable,
    suffix: " Rent",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerStudio,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyStudioQuantityToggleable,
    suffix: " Rent",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerOneBed,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyOneBedQuantityToggleable,
    suffix: " Rent",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerTwoBed,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyTwoBedQuantityToggleable,
    suffix: " Rent",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.MultifamilyYearOneMonthlyRentPerThreeBed,
    usageGroup: BuildingUse.Multifamily,
    parentCustomName: VariableId.MultifamilyThreeBedQuantityToggleable,
    suffix: " Rent",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Multifamily NOI",
    variableId: VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Multifamily Vacancy",
    variableId: VariableId.MultifamilyStabilizedVacancyPercentage,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 }),
  },
  {
    rowName: "Multifamily OpEx",
    variableId: VariableId.MultifamilyOperatingExpensePercentage,
    usageGroup: BuildingUse.Multifamily,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 }),
  },
];

export const RETAIL_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Retail Gross Buildable SF",
    variableId: VariableId.RetailGrossBuildableArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },

  {
    rowName: "Retail Net Leasable SF",
    variableId: VariableId.RetailNetLeasableAreaToggleable,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Retail Loss Factor",
    variableId: VariableId.RetailLossFactor,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Retail Gross Income",
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Retail Lease Income PSF",
    variableId: VariableId.RetailYearOneRentPerArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Retail Expense Reimbursement PSF",
    variableId: VariableId.RetailYearOneExpenseReimbursementFeePerArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Retail NOI",
    variableId: VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Retail Vacancy",
    variableId: VariableId.RetailStabilizedVacancyPercentage,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 }),
  },
  {
    rowName: "Retail OpEx PSF",
    variableId: VariableId.RetailYearOneOperatingExpensePerArea,
    usageGroup: BuildingUse.Retail,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
];

export const HOTEL_ASSUMPTIONS_SECTION_CSV: Array<CsvRows> = [
  {
    rowName: "Hotel Gross Buildable SF",
    variableId: VariableId.HotelGrossBuildableArea,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },

  {
    rowName: "Hotel Keys",
    variableId: VariableId.HotelRoomQuantityToggleable,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },

  {
    rowName: "Average Key SF",
    variableId: VariableId.HotelAverageRoomArea,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Hotel Loss Factor",
    variableId: VariableId.HotelLossFactor,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Hotel Amenities",
    variableId: VariableId.HotelAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Hotel Gross Income",
    variableId: VariableId.HotelAnnualPotentialRevenueForBackOfEnvelope,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Hotel ADR",
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Hotel Other Revenue",
    variableId: VariableId.HotelOtherRevenuePercentage,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Hotel Effective Income",
    variableId: VariableId.HotelAnnualRevenueForBackOfEnvelope,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Hotel Occupancy",
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Hotel NOI",
    variableId: VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Hotel OpEx",
    variableId: VariableId.HotelOperatingExpensePercentage,
    usageGroup: BuildingUse.Hotel,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
];

export const OFFICE_ASSUMPTIONS_SECTION: Array<CsvRows> = [
  {
    rowName: "Office Gross Buildable SF",
    variableId: VariableId.OfficeGrossBuildableArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Office Usable Area SF",
    variableId: VariableId.OfficeNetUsableAreaToggleable,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Common Area Factor",
    variableId: VariableId.OfficeCommonAreaFactor,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Office Gross Income",
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Office Lease Income PSF",
    variableId: VariableId.OfficeYearOneRentPerArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Office Expense Reimbursement PSF",
    variableId: VariableId.OfficeYearOneExpenseReimbursementFeePerArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Office NOI",
    variableId: VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Office Vacancy",
    variableId: VariableId.OfficeStabilizedVacancyPercentage,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 }),
  },
  {
    rowName: "Office OpEx PSF",
    variableId: VariableId.OfficeYearOneOperatingExpensePerArea,
    usageGroup: BuildingUse.Office,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
];

export const INDUSTRIAL_ASSUMPTIONS_SECTION: Array<CsvRows> = [
  {
    rowName: "Industrial Gross Buildable SF",
    variableId: VariableId.IndustrialGrossBuildableArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Industrial Net Leasable SF",
    variableId: VariableId.IndustrialNetLeasableAreaToggleable,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Industrial Loss Factor",
    variableId: VariableId.IndustrialLossFactor,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Industrial Gross Income",
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Industrial Lease Income PSF",
    variableId: VariableId.IndustrialYearOneRentPerArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Industrial Expense Reimbursement PSF",
    variableId: VariableId.IndustrialYearOneExpenseReimbursementFeePerArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
  {
    rowName: "Industrial NOI",
    variableId: VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Industrial Vacancy",
    variableId: VariableId.IndustrialStabilizedVacancyPercentage,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 }),
  },
  {
    rowName: "Industrial OpEx PSF",
    variableId: VariableId.IndustrialYearOneOperatingExpensePerArea,
    usageGroup: BuildingUse.Industrial,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency, decimalPlaces: 2 }),
  },
];

export const CONDO_ASSUMPTIONS_SECTION: Array<CsvRows> = [
  {
    rowName: "Total Condo Units",
    variableId: VariableId.CondoTotalUnitQuantity,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Microunits",
    variableId: VariableId.CondoMicrounitQuantityToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.CondoStudioQuantityToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.CondoOneBedQuantityToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.CondoTwoBedQuantityToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.CondoThreeBedQuantityToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Condo Gross Buildable SF",
    variableId: VariableId.CondoGrossBuildableArea,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Microunits",
    variableId: VariableId.CondoMicrounitArea,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoMicrounitQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.CondoStudioArea,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoStudioQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.CondoOneBedArea,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoOneBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.CondoTwoBedArea,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoTwoBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.CondoThreeBedArea,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoThreeBedQuantityToggleable,
    suffix: " SF",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Condo Amenity GSF",
    variableId: VariableId.CondoAmenitiesAreaToggleable,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  {
    rowName: "Condo Loss Factor",
    variableId: VariableId.CondoLossFactor,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Percentage }),
  },
  {
    rowName: "Condo Sales",
    variableId: VariableId.CondoGrossSales,
    usageGroup: BuildingUse.Condo,
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Microunits",
    variableId: VariableId.CondoSalePricePerMicrounit,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoMicrounitQuantityToggleable,
    suffix: " Sale",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Studios",
    variableId: VariableId.CondoSalePricePerStudio,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoStudioQuantityToggleable,
    suffix: " Sale",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "One Bedrooms",
    variableId: VariableId.CondoSalePricePerOneBed,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoOneBedQuantityToggleable,
    suffix: " Sale",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Two Bedrooms",
    variableId: VariableId.CondoSalePricePerTwoBed,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoTwoBedQuantityToggleable,
    suffix: " Sale",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  {
    rowName: "Three Bedrooms",
    variableId: VariableId.CondoSalePricePerThreeBed,
    usageGroup: BuildingUse.Condo,
    parentCustomName: VariableId.CondoThreeBedQuantityToggleable,
    suffix: " Sale",
    formatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
]

export default config;
