import actionTypes from "./actionTypes";
import { VariableId } from "../../types/VariableId";
import Unit from "../../types/Unit";
import Firebase from "../../types/Service/Firebase";
import { Development } from "../../types/Development/Development";
import { GeoJSON } from "geojson";
import { SliderBox } from "types/Development/SliderBox";

/**
 * Load from the database the project with the given projectId.
 */
const loadStart = (user: Firebase.User, projectId, userDocument) => {
  return {
    type: actionTypes.LOAD_START,
    payload: { user, projectId, userDocument },
  };
};

/**
 * Save a new development state to the store.
 */
const loadSuccess = (development, userDocument, projectId) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { development, userDocument, projectId },
  };
};

/**
 * Flag the that the development failed to load.
 */
const loadFailure = () => {
  return {
    type: actionTypes.LOAD_FAILURE,
    payload: {},
  };
};

/**
 * Save the project to the database,
 */
const saveStart = (developmentId?, developmentDocument?, userDocument?, pdfDocument?, thumbnail?, user?: Firebase.User,) => {
  return {
    type: actionTypes.SAVE_START,
    payload: { user, developmentId, developmentDocument, userDocument, pdfDocument, thumbnail },
  };
};

/**
 * Update the user concern and flag the project as saved.
 */
const saveSuccess = (userDocument) => {
  return {
    type: actionTypes.SAVE_SUCCESS,
    payload: { userDocument },
  }
}

/**
 * Create a project and save it to the database,
 */
const createStart = (user: Firebase.User, developmentDocument, userDocument, pdfDocument?) => {
  return {
    type: actionTypes.CREATE_START,
    payload: { user, developmentDocument, userDocument, pdfDocument },
  };
};

/**
 * Save a new development state to the store.
 */
const createSuccess = (development, userDocument, projectId) => {
  return {
    type: actionTypes.CREATE_SUCCESS,
    payload: { development, userDocument, projectId },
  }
}

/**
 * Duplicate a project and save it to the database,
 */
const duplicateStart = (
  user: Firebase.User,
  developmentDocument: Development,
  developmentName: string,
  currentDevelopmentIsEdited: boolean,
  pdfDocument?
) => {
  return {
    type: actionTypes.DUPLICATE_START,
    payload: { user, developmentDocument, currentDevelopmentIsEdited, developmentName, pdfDocument },
  };
};

/**
 * Remove from the database the project with the given projectId.
 */
const removeStart = (user: Firebase.User, projectId, userDocument) => {
  return {
    type: actionTypes.REMOVE_START,
    payload: { user, projectId, userDocument },
  };
};

/**
 * Update the user concern with the new user document.
 */
const removeSuccess = (userDocument) => {
  return {
    type: actionTypes.REMOVE_SUCCESS,
    payload: { userDocument },
  };
};

/**
 * Recalculate the development state.
 *
 * @param {Object} newDevelopment - A complete development state object with which
 *  to re-initialize the platform.
 */
const recalculate = (newDevelopment) => {
  return {
    type: actionTypes.RE_CALCULATE,
    payload: { newDevelopment },
  };
};

/**
 * Set the value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputValue - The value of the input.
 */
const setRangedInput = (inputId: VariableId, inputValue: number, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT,
    payload: { inputId, inputValue, index },
  };
};

/**
 * Set the maximum value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputMaximum - The maximum value of the input.
 */
const setRangedInputMaximum = (inputId: VariableId, inputMaximum, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT_MAXIMUM,
    payload: { inputId, inputMaximum, index },
  };
};

/**
 * Set the minimum value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputMinimum - The minimum value of the input.
 */
const setRangedInputMinimum = (inputId: VariableId, inputMinimum, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT_MINIMUM,
    payload: { inputId, inputMinimum, index },
  };
};

/**
 * Set the development's name.
 *
 * @param {string} name - The new name of the development.
 */
const setName = (name) => {
  return {
    type: actionTypes.SET_NAME,
    payload: { name },
  };
};

/**
 * Set the type of a given setback.
 *
 * @param {number} polygonIndex - The index of the polygon.
 * @param {number} setbackIndex - The index of the setback.
 * @param {string} setbackType - The setback type.
 */
const setSetbackType = (polygonIndex, setbackIndex, setbackType) => {
  return {
    type: actionTypes.SET_SETBACK_TYPE,
    payload: { polygonIndex, setbackIndex, setbackType },
  };
};

/**
 * Set the camera values.
 *
 * @param camera - Camera object
 */
const setCamera = (camera) => {
  return {
    type: actionTypes.SET_CAMERA,
    payload: { camera },
  }
};

/**
 * Toggle the value of a boolean development input.
 *
 * @param {string} inputId - The ID of the input.
 */
const toggleBooleanInput = (inputId: VariableId) => {
  return {
    type: actionTypes.TOGGLE_BOOLEAN_INPUT,
    payload: { inputId },
  };
};

/**
 * Set the unit system.
 *
 * @param unitSystem - Unit system.
 */
const setUnitSystem = (unitSystem: Unit.System) => {
  return {
    type: actionTypes.SET_UNIT_SYSTEM,
    payload: { unitSystem },
  }
}

/**
 * Reset the development concern to its initial state.
 */
const resetStateStart = () => {
  return {
    type: actionTypes.RESET_STATE_START
  }
}

/**
 * Continue Reset action to reset the development concern to its initial state.
 */
const resetStateContinue = () => {
  return {
    type: actionTypes.RESET_STATE_CONTINUE
  }
}

/**
 * Set the isFromShared flag to false.
 */
const keepProject = () => {
  return {
    type: actionTypes.KEEP_PROJECT
  }
}

/**
 * Add a setback to the given floor.
 */
const addSetbackFloor = (floor: number) => {
  return {
    type: actionTypes.ADD_SETBACK_FLOOR,
    payload: { floor },
  }
}

/**
 * Remove the setback at the given floor.
 */
const removeSetbackFloor = (floor: number) => {
  return {
    type: actionTypes.REMOVE_SETBACK_FLOOR,
    payload: { floor },
  }
}

/**
 * Reset values for selected setback floor variables.
 */
const resetSelectedSetbackFloor = () => {
  return {
    type: actionTypes.RESET_SELECTED_SETBACK_FLOOR
  }
}

/**
 * Flag the project as shared.
 */
const setProjectIsShared = (developmentId: string) => {
  return {
    type: actionTypes.SET_PROJECT_IS_SHARED,
    payload: { developmentId },
  }
}

/**
 * Set the project is ready flag.
 */
const setProjectIsReady = (value: boolean) => {
  return {
    type: actionTypes.SET_PROJECT_IS_READY,
    payload: { value },
  }
}

/**
 * Switch project.
 */
const switchDevelopment = (
  user: Firebase.User,
  developmentId: string,
  currentDevelopmentIsEdited: boolean,
) => {
  return {
    type: actionTypes.SWITCH_DEVELOPMENT,
    payload: { user, developmentId, currentDevelopmentIsEdited },
  }
}

/**
 * Set projectIsForSale flag.
 */
const setProjectIsForSale = (value: boolean) => {
  return {
    type: actionTypes.SET_PROJECT_IS_FOR_SALE,
    payload: { value },
  }
}

/**
 * Set the floorPlan for the given floor.
 *
 * @param {Array<GeoJSON>} floorPlan
 */
 const setFloorPlan = (floorPlan: Array<GeoJSON>, floor: Number) => {
  return {
    type: actionTypes.SET_FLOOR_PLAN,
    payload: {
      floorPlan,
      floor,
    },
  };
}

/**
 * Set the customSliderName for the given SliderBox
 */
const setCustomSliderName = (sliderBox: SliderBox) => {
  return {
    type: actionTypes.SET_CUSTOM_SLIDER_NAMES,
    payload: sliderBox,
  }
}

export default {
  loadStart,
  loadSuccess,
  loadFailure,
  saveStart,
  saveSuccess,
  createStart,
  createSuccess,
  duplicateStart,
  removeStart,
  removeSuccess,
  recalculate,
  setRangedInput,
  setRangedInputMaximum,
  setRangedInputMinimum,
  setName,
  setSetbackType,
  setCamera,
  toggleBooleanInput,
  setUnitSystem,
  resetStateStart,
  resetStateContinue,
  keepProject,
  addSetbackFloor,
  removeSetbackFloor,
  resetSelectedSetbackFloor,
  setProjectIsShared,
  switchDevelopment,
  setProjectIsForSale,
  setProjectIsReady,
  setFloorPlan,
  setCustomSliderName,
};
