import { developmentActionTypes } from "../../../development";
import { newDevelopmentActionTypes } from "../../../newDevelopment";
import actionTypes from "./actionTypes";

export enum Accordions {
  BuildingUses = "buildingUses",
  Demographics = "demographics",
  PropertyInfo = "propertyInfo",
  Zoning = "zoning",
  Property = "property",
  SiteBuilding = "siteBuilding",
  Areas = "areas",
  Economics = "economics",
  ProjectCost = "projectCost",
  GrossIncome = "grossIncome",
  Vacancy = "vacancy",
  OperatingExpense = "operatingExpense",
  Sale = "sale",
  Profit = "profit",
}

export interface AccordionSectionState {
  staticAccordionsAreOpen: {
    [Accordions.BuildingUses]: boolean;
    [Accordions.Demographics]: boolean;
    [Accordions.PropertyInfo]: boolean;
    [Accordions.Zoning]: boolean;
  },
  boeAccordionsAreOpen: {
    [Accordions.Property]: boolean;
    [Accordions.SiteBuilding]: boolean;
    [Accordions.Areas]: boolean;
    [Accordions.Economics]: boolean;
    [Accordions.ProjectCost]: boolean;
    [Accordions.GrossIncome]: boolean;
    [Accordions.Vacancy]: boolean;
    [Accordions.OperatingExpense]: boolean;
    [Accordions.Sale]: boolean;
    [Accordions.Profit]: boolean;
  },
  listingAccordionsAreOpen: {
    [key: string]: boolean
  }
}

export const INITIAL_STATE: AccordionSectionState = {
  staticAccordionsAreOpen: {
    buildingUses: true,
    demographics: true,
    propertyInfo: true,
    zoning: true,
  },
  boeAccordionsAreOpen: {
    [Accordions.Property]: true,
    [Accordions.SiteBuilding]: true,
    [Accordions.Areas]: true,
    [Accordions.Economics]: true,
    [Accordions.ProjectCost]: true,
    [Accordions.GrossIncome]: true,
    [Accordions.Vacancy]: true,
    [Accordions.OperatingExpense]: true,
    [Accordions.Sale]: true,
    [Accordions.Profit]: true,
  },
  listingAccordionsAreOpen: {},
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): AccordionSectionState => {
  switch (action.type) {
    case actionTypes.SET_STATIC_ACCORDION_IS_OPEN: return setStaticAccordion(previousState, action.payload);
    case actionTypes.SET_LISTING_ACCORDION_IS_OPEN: return setListingAccordions(previousState, action.payload);
    case actionTypes.SET_BOE_ACCORDION_IS_OPEN: return setBoeAccordions(previousState, action.payload);
    case newDevelopmentActionTypes.CLEAR_FEATURE_SELECTION:
    case developmentActionTypes.LOAD_SUCCESS:
    case actionTypes.RESET: return reset(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * Set Listings accordion is open flag.
 */
const setListingAccordions = (previousState: AccordionSectionState, payload): AccordionSectionState => {
  return {
    ...previousState,
    listingAccordionsAreOpen: {
      ...previousState.listingAccordionsAreOpen,
      [payload.accordionId]: payload.value,
    },
  }
}

/**
 * Set BOE accordion is open flag.
 */
const setBoeAccordions = (previousState: AccordionSectionState, payload): AccordionSectionState => {
  return {
    ...previousState,
    boeAccordionsAreOpen: {
      ...previousState.boeAccordionsAreOpen,
      [payload.accordionId]: payload.value,
    },
  }
}

/**
 * See `ui/shared/accordionSection/setAccordion` action creator.
 *
 * Returns a new state with the given accordion open state updated.
 */
const setStaticAccordion = (previousState, payload): AccordionSectionState => {
  return {
    ...previousState,
    staticAccordionsAreOpen: {
      ...previousState.staticAccordionsAreOpen,
      [payload.accordion]: payload.value,
    },
  }
}

/**
 * See `ui/shared/accordionSection/reset` action creator.
 *
 * Returns the initial state.
 */
const reset = (previousState, payload): AccordionSectionState => {
  return INITIAL_STATE;
}

export default reducer;
