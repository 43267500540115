
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the condo buildables presets. Only one bedroom units will be initialized to non zero values.
 */
const generatePresets = async (development: Development): Promise<object> => {
  return {
    [VariableId.ParkingHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.ParkingHardCostPerArea, BuildingUse.Parking),
  };
}

export default generatePresets;
