import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../state/subscription";
import authentication from "../../../utils/authentication";
import { Path } from "../../../types/Path";
import { Tier } from "../../../types/Tier";
import RedirectComponent from "../RedirectComponent";

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

interface OwnProps {
  onClick?(): void;
}

const TIER_CSS_MAP = {
  [Tier.Pro]: "pro",
  [Tier.Free]: "free",
  [Tier.Developer]: "developer",
  [Tier.None]: "none",
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

class Logo extends React.PureComponent<Props, {}> {
  render() {
    let { tier, onClick } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated) tier = Tier.None;

    return (
      <div className="component--logo">
        <RedirectComponent path={Path.Home} onClick={onClick}>
          {({ redirect }) => <div onClick={redirect} className={`logo ${tier !== undefined ? TIER_CSS_MAP[tier] : ""}`} />}
        </RedirectComponent>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Logo);
