import { Feature } from "geojson";
import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import valueGetters from "../../../../state/development/utils/valueGetters";
import { newDevelopmentSelectors } from "../../../../state/newDevelopment";
import { subscriptionSelectors } from "../../../../state/subscription";
import { accordionSectionActions, accordionSectionSelectors } from "../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../state/ui/shared/accordionSection/reducers";
import Format from "../../../../types/Format";
import authentication from "../../../../utils/authentication";
import configHelper from "../../../../utils/configHelper";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import unitConversions from "../../../../utils/unitConversions";
import userHelper from "../../../../utils/userHelper";
import valueFormatter from "../../../../utils/valueFormatter";
import AccordionSection from "../../AccordionSection";
import Cell from "../../Cell";
import CellRow from "../../CellRow";
import CellsContainer from "../../CellsContainer";
import CellText from "../../CellText";
import ConfigurationHandler from "../../ConfigurationHandler";
import ConditionalCellRow from "../ConditionalCellRow";
import config from "./config";
import { ParentPage } from "../types";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state) || newDevelopmentSelectors.getUnitSystem(state),
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.PropertyInfo),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
};

type OwnProps = {
  parcelFeature: Feature;
  parentPage?: ParentPage;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;

class PropertyInformation extends React.PureComponent<Props, {}> {
  /**
   * Display existing building area.
   */
  displayExistingBuildingArea = () => {
    const unitSpecificConfig = configHelper.getConfigForUnitSystem(config.existingStructureArea, this.props.unitSystem);
    // Even though this value will be ignored by the valueGetter in the Cell component,
    // This is used here to either display the whole row or nothing at all.

    const value = valueGetters.forExistingBuildingAreaInPropertyInfo(
      "",
      unitSpecificConfig.formatOptions,
      undefined,
      unitSpecificConfig.unitTarget,
      unitSpecificConfig.unitIsInverse,
      this.props.parcelFeature
    );
    if (value === null || value === undefined) return null;

    return (
      <CellRow>
        <ConfigurationHandler
          config={config.existingStructureArea}
          component={CellsContainer}
          unitSystem={this.props.unitSystem}
        />
      </CellRow>
    );
  };

  /**
   * Display existing building height.
   */
  displayExistingBuildingHeight = () => {
    const unitSpecificConfig = configHelper.getConfigForUnitSystem(
      config.existingBuildingHeight,
      this.props.unitSystem
    );
    // Even though this value will be ignored by the valueGetter in the Cell component,
    // This is used here to either display the whole row or nothing at all.
    const value = valueGetters.forExistingBuildingHeightInPropertyInfo(
      "",
      unitSpecificConfig.formatOptions,
      undefined,
      unitSpecificConfig.unitTarget,
      unitSpecificConfig.unitIsInverse,
      this.props.parcelFeature
    );
    if (value === null || value === undefined) return null;

    return (
      <CellRow>
        <ConfigurationHandler
          config={config.existingBuildingHeight}
          component={CellsContainer}
          unitSystem={this.props.unitSystem}
        />
      </CellRow>
    );
  };

  /**
   * Display existing building area.
   */
  displayExistingBuildingAreaOpenSource = () => {
    const unitSpecificConfig = configHelper.getConfigForUnitSystem(
      config.existingStructureAreaOpenSource,
      this.props.unitSystem
    );
    // Even though this value will be ignored by the valueGetter in the Cell component,
    // This is used here to either display the whole row or nothing at all.
    const value = valueGetters.forExistingBuildingAreaOpenSourceInPropertyInfo(
      "",
      unitSpecificConfig.formatOptions,
      undefined,
      unitSpecificConfig.unitTarget,
      unitSpecificConfig.unitIsInverse,
      this.props.parcelFeature
    );
    if (value === null || value === undefined) return null;

    return (
      <CellRow>
        <ConfigurationHandler
          config={config.existingStructureAreaOpenSource}
          component={CellsContainer}
          unitSystem={this.props.unitSystem}
        />
      </CellRow>
    );
  };

  /**
   * Display an array as comma separated strings.
   */
  displayArray = (array) => {
    return array && array.length > 0 ? array.join(", ") : null;
  };

  /**
   * Display the published area.
   */
  displayPublishedArea = () => {
    const parcel = this.props.parcelFeature;
    let areaIsComputed = false;
    let area: number | string | null = 0;

    if (parcelAccessors.getIsAnAssembly(parcel)) {
      for (const member of parcelAccessors.getAssemblyParcels(parcel)) {
        let memberArea = parcelAccessors.getAreaPublished(member);
        if (!memberArea) {
          memberArea = parcelAccessors.getAreaComputed(member);
          areaIsComputed = true;
        }
        area += memberArea;
      }
    } else {
      area = parcelAccessors.getAreaPublished(parcel);
      if (!area) {
        area = parcelAccessors.getAreaComputed(parcel);
        areaIsComputed = true;
      }
    }

    const unitSpecificConfig = configHelper.getConfigForUnitSystem(config.parcelArea, this.props.unitSystem);
    area = unitConversions.convertFromBase(area, unitSpecificConfig.unitTarget);

    if (areaIsComputed) {
      area = `Approx. ${valueFormatter.format(area, unitSpecificConfig.formatOptions)}`;
    } else {
      area = valueFormatter.format(area, unitSpecificConfig.formatOptions);
    }

    return area;
  };

  /**
   * Render table section if all fields are available.
   */
  renderConditionalTableSection = (sectionHeader: string, ...components) => {
    const componentToRender = components.filter(Boolean);
    if (componentToRender.length === 0) return null;

    return (
      <table>
        <thead>
          <tr>
            <th colSpan={2}>{sectionHeader}</th>
          </tr>
        </thead>
        <tbody>
          {componentToRender.map((Component, index) => (
            <Component.type key={`sectionHeader_${index}`} {...Component.props} />
          ))}
        </tbody>
      </table>
    );
  };

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.PropertyInfo, !this.props.accordionIsOpen);
  };

  render() {
    const { parcelFeature, tier } = this.props;
    const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);
    const hiddenMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: { hidden: !userHasAccess },
    });

    return (
      <AccordionSection
        title="Property Info"
        iconClass="property-info"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
      >
        {this.renderConditionalTableSection(
          "Property",

          ConditionalCellRow(
            parcelAccessors.getAddress(parcelFeature),
            <CellText text="Legal Address" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getCity(parcelFeature),
            <CellText text="City" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getState(parcelFeature),
            <CellText text="State" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getZipCode(parcelFeature),
            <CellText text="Zip Code" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            this.displayPublishedArea(),
            <CellText text="Lot Size" />,
            <Cell getter={valueGetters.generic} />
          ),
          ConditionalCellRow(
            parcelAccessors.getBasicStratum(parcelFeature),
            <CellText text="Current Use Definition" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getLandUseCode(parcelFeature),
            <CellText text="Land Use" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getPublicLand(parcelFeature),
            <CellText text="Public Land" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getParcelOfficialId(parcelFeature),
            <CellText text="Folio Number" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          )
        )}
        {this.renderConditionalTableSection(
          "Building",

          this.displayExistingBuildingArea(),

          this.displayExistingBuildingAreaOpenSource(),

          this.displayExistingBuildingHeight(),

          ConditionalCellRow(
            this.displayArray(parcelAccessors.getExistingStructureYearBuilt(parcelFeature)),
            <CellText text="Year Built" />,
            <Cell getter={valueGetters.generic} formatOptions={{ hidden: !userHasAccess }} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getNumberOfUnits(parcelFeature),
            <CellText text="Number of Units" />,
            <Cell getter={valueGetters.generic} formatOptions={{ hidden: !userHasAccess, suffix: " Units" }} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getNumberOfBuildings(parcelFeature),
            <CellText text="Number of Buildings" />,
            <Cell getter={valueGetters.generic} formatOptions={{ hidden: !userHasAccess, suffix: " Buildings" }} />,
            tier
          ),
          ConditionalCellRow(
            this.displayArray(parcelAccessors.getConstructionClass(parcelFeature)),
            <CellText text="Construction Type" />,
            <Cell getter={valueGetters.generic} formatOptions={{ hidden: !userHasAccess }} />,
            tier
          ),
          ConditionalCellRow(
            this.displayArray(parcelAccessors.getImprovementQuality(parcelFeature)),
            <CellText text="Structure Quality" />,
            <Cell getter={valueGetters.generic} formatOptions={{ hidden: !userHasAccess }} />,
            tier
          )
        )}
        {this.renderConditionalTableSection(
          "Value",
          ConditionalCellRow(
            parcelAccessors.getPurchasePrice(parcelFeature),
            <CellText text="Market Value" />,
            <Cell
              getter={valueGetters.generic}
              formatOptions={{
                hidden: !userHasAccess,
                type: Format.Type.Currency,
              }}
            />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getLandValue(parcelFeature),
            <CellText text="Land Value" />,
            <Cell
              getter={valueGetters.generic}
              formatOptions={{
                hidden: !userHasAccess,
                type: Format.Type.Currency,
              }}
            />,
            tier
          )
        )}
        {this.renderConditionalTableSection(
          "Sales",
          ConditionalCellRow(
            parcelAccessors.getSalePrice(parcelFeature),
            <CellText text="Sales Price" />,
            <Cell
              getter={valueGetters.generic}
              formatOptions={{
                hidden: !userHasAccess,
                type: Format.Type.Currency,
              }}
            />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getSaleDate(parcelFeature),
            <CellText text="Sales Date" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getMultiParcelSale(parcelFeature),
            <CellText text="Assembly Sale" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          )
        )}
        {this.renderConditionalTableSection(
          "Owner",
          ConditionalCellRow(
            parcelAccessors.getOwnerName(parcelFeature),
            <CellText text="Owner's Name" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          ),
          ConditionalCellRow(
            parcelAccessors.getOwnerAddress(parcelFeature),
            <CellText text="Owner's Mailing Address" />,
            <Cell getter={valueGetters.generic} formattingMap={hiddenMap} />,
            tier
          )
        )}
      </AccordionSection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInformation);
