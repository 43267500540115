import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";

class CensusTractsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.CensusTracts}
        classes="census-tracts"
      />
    );
  }
}

export default CensusTractsLegend;
