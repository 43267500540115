const MISSING_EMAIL = null; // Flag indicating that the user document still doesn't have an email.

/**
 * Add user emails and managed users to the user document.
 */
const addUserEmailAndManagedUsers = (user) => {
  if (user.email === undefined) user.email = MISSING_EMAIL;
  if (!user.managedSubscriptions) user.managedSubscriptions = [];
}

export default addUserEmailAndManagedUsers;
