import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import listings from "../../utils/functions/listings";
import listingsHelper from "../../utils/listingsHelper";
import turf from "../../utils/turf";
import { developmentActions, developmentActionTypes } from "../development";
import actions from "./actions";
import actionTypes from "./actionTypes";

/**
 * Update the address in the search bar and trigger a forward geocode lookup for that address.
 */
function* getListingsStart(action: ReturnType<typeof actions.getListingsStart>) {
  try {
    const result = yield call(listings.getListings, action.payload.boundingBox);

    yield put(actions.getListingsSuccess(result));
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Fetch listing for current development.
 */
function* getListingForDevelopment(action: ReturnType<typeof developmentActions.loadSuccess>) {
  const parcel = action.payload.development.parcel;
  const boundingBox = turf.bbox(parcel);
  const result = yield call(listings.getListings, boundingBox as [number, number, number, number]);

  const matchingListings = listingsHelper.getListingsForParcel(result, parcel);

  yield put(actions.setSelectedListing(matchingListings.length > 0 ? matchingListings : null));
}

/**
 * Watcher for getListingsStart action.
 */
function* watchGetListingsStart() {
  yield takeLatest(actionTypes.GET_LISTINGS_START, getListingsStart);
}

/**
 * Watcher for development loadSuccess action.
 */
function* watchDevelopmentLoadSuccess() {
  yield takeEvery(developmentActionTypes.LOAD_SUCCESS, getListingForDevelopment);
}

export default {
  watchGetListingsStart,
  watchDevelopmentLoadSuccess,
}
