import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class OfficeRateLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.OfficeRate}
        classes="office-rate"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< $10"
          />,
          <LegendRow
            color="#fff305"
            text="$10 - $30"
          />,
          <LegendRow
            color="#0fe600"
            text="$30 - $40"
          />,
          <LegendRow
            color="#16e7fe"
            text="$40 - $50"
          />,
          <LegendRow
            color="#024be8"
            text="> $50"
          />,
        ]}
      />
    );
  }
}

export default OfficeRateLegend;
