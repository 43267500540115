import React from "react";
import logo from "../../../../../../../assets/images/branding-watermark.png";
import { View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  logo: {
    width: 67,
  },
});

const Logo = () => (
  <View style={styles.container}>
    <Image style={styles.logo} src={logo} />
  </View>
);

export default Logo;
