import React from "react";
import { Layer } from "react-mapbox-gl";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties";
import { RawDynamicLayersFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { connect } from "react-redux";
import { paintDemographicDynamicLayerFill } from "utils/mapbox/layerPaintersHelper";

const FIELD_TO_FILL_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianHouseholdIncome2013]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2013,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2014]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2014,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2015]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2015,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2016]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2016,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2017]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2017,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2018]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2018,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2019]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2019,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2020]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2020,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2021]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2021,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2022]: MapStyleProperties.LayerId.DynamicMedianIncomeFill2022,
};

const FIELD_TO_BORDER_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianHouseholdIncome2013]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2014]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2015]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2016]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2017]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2018]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2019]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2020]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2021]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2022]: MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022,
};

const FIELD_TO_SYMBOL_LAYER_ID = {
  [RawDynamicLayersFieldId.MedianHouseholdIncome2013]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2014]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2015]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2016]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2017]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2018]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2019]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2020]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2021]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021,
  [RawDynamicLayersFieldId.MedianHouseholdIncome2022]: MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022,
};

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DynamicMedianIncomeLayer extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer) return null;
    if (getSelectedDynamicLayer !== MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income) return null;

    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    return (
      <>
        <Layer
          id={FIELD_TO_FILL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={paintDemographicDynamicLayerFill(
            layerSelected,
            MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
          )}
          minZoom={10}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={FIELD_TO_BORDER_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={mapboxPresentationProperties.demographicsLinePaint(layerSelected)}
          minZoom={10}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={FIELD_TO_SYMBOL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          layout={mapboxPresentationProperties.demographicsLabelsSymbolLayout(layerSelected, { prefix: "$" })}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          minZoom={12}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    );
  }
}

export default connect(mapStateToProps)(DynamicMedianIncomeLayer);
