import React from "react";
import { connect } from "react-redux";
import { LayerDisclaimerObject } from "types/LayerDisclaimer";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import LayerGroup from "../../../../../types/LayerGroup";
import config from "../../../LayerSelection/config";
import LayerDisclaimer from "../LayerDisclaimer";
import Legend from "../Legend";
import LayerToggle from "./LayerToggle";

const mapStateToProps = (state) => {
  return {
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  };
};

interface OwnProps {
  classes?: string;
  layerGroup: LayerGroup;
  legendRows?: Array<JSX.Element>;
  disclaimer?: LayerDisclaimerObject;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

interface State {
  legendIsActive: boolean;
}

class LayerLegend extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      legendIsActive: false,
    };
  }

  /**
   * Get layer is active flag.
   */
  getLayerIsActive = () => {
    return config[this.props.layerGroup].layers.some(
      (layerId) => this.props.layerConfigurations[layerId].isActive
    );
  };

  /**
   * Get layer is enabled flag.
   */
  getLayerIsEnabled = () => {
    return config[this.props.layerGroup].layers.some(
      (layerId) =>
        this.props.layerConfigurations[layerId].isEnabled &&
        this.props.layerConfigurations[layerId].isAvailable
    );
  };

  /**
   * Toggle legend.
   */
  toggleLegend = () => {
    this.setState({ legendIsActive: !this.state.legendIsActive });
  };

  render() {
    const {
      classes,
      legendRows: LegendRows,
      layerGroup,
      disclaimer,
    } = this.props;
    if (!this.getLayerIsEnabled()) return null;

    const title = config[layerGroup].legendTitle;
    const layerIds = config[layerGroup].layers;
    const text = config[layerGroup].layerDescription;
    const layerIsActive = this.getLayerIsActive();
    const displayLegend = layerIsActive && this.state.legendIsActive;

    return (
      <div className={`component--layer-legend ${classes}`}>
        <div className="icon" />
        <div className="legend-content">
          <div className="content-wrapper">
            <div className="title-container">
              <div className="title">{title}</div>
              <div className="text">{text}</div>
            </div>
            <LayerToggle layerIds={layerIds} />
          </div>

          {LegendRows && (
            <>
              <button
                className={`legend-button ${layerIsActive ? "" : "disabled"}`}
                onClick={this.toggleLegend}
              >
                {displayLegend ? "Hide Legend" : "Show Legend"}{" "}
                <span className={displayLegend ? "active" : "inactive"} />
              </button>

              {displayLegend && (
                <div>
                  <Legend>
                    {LegendRows.map((LegendComponent, index) => (
                      <LegendComponent.type
                        {...LegendComponent.props}
                        key={`legend_${index}`}
                      />
                    ))}
                  </Legend>
                  {disclaimer && <LayerDisclaimer disclaimer={disclaimer} />}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  LayerLegend
) as React.ComponentType<OwnProps>;
