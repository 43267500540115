import { GraphDataSource, GraphDataSources } from "types/Graph";
import actionTypes from "./actionTypes";

/**
 * Update the graph data sources.
 */
const updateGraphDataSources = (graphDataSources: GraphDataSources) => ({
  type: actionTypes.UPDATE_GRAPH_DATA_SOURCES,
  payload: graphDataSources,
})

/**
 * Set the highlighted graph data source.
 */
const setHighlightedGraphDataSource = (graphDataSource: GraphDataSource) => ({
  type: actionTypes.SET_HIGHLIGHTED_GRAPH_DATA_SOURCE,
  payload: graphDataSource
})

/**
 * Clear the highlighted graph data source.
 */
const clearHighlightedGraphDataSource = () => ({
  type: actionTypes.CLEAR_HIGHLIGHTED_GRAPH_DATA_SOURCE,
})

export default {
  updateGraphDataSources,
  setHighlightedGraphDataSource,
  clearHighlightedGraphDataSource,
}
