import React from "react";

interface OwnProps {
  children: Array<JSX.Element>;
}

type Props = OwnProps;

class Legend extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--legend">
        {this.props.children}
      </div>
    );
  }
}

export default Legend;
