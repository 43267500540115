import { DynamicLayerId, LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { LayerConfigurationsLayerIds } from "../../../../utils/mapbox/layerHelper";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import actionTypes from "./actionTypes";

/**
 * Toggle layers visibility.
 */
const toggleLayers = (layerIds: Array<MapStyleProperties.LayerId>) => {
  return {
    type: actionTypes.TOGGLE_LAYERS,
    payload: {
      layerIds,
    }
  }
}

/**
 * Toggle layers on to be visible in the map.
 */
const initializeLayers = (visibleLayerIds: Array<LayerConfigurationsLayerIds>) => {
  return {
    type: actionTypes.INITIALIZE_LAYERS,
    payload: {
      visibleLayerIds,
    }
  }
}

/**
 * Reset state to INITIAL_STATE.
 */
const resetFlags = () => {
  return {
    type: actionTypes.RESET_FLAGS,
  }
}

/**
 * Toggle layers on to be visible in the map when style changes.
 */
const initializeLayersOnStyleChange = (visibleLayerIds: Array<LayerConfigurationsLayerIds>) => {
  return {
    type: actionTypes.INITIALIZE_LAYERS_ON_STYLE_CHANGE,
    payload: {
      visibleLayerIds,
    }
  }
}

/**
 * Toggle map style.
 */
const toggleMapStyle = () => {
  return {
    type: actionTypes.TOGGLE_MAP_STYLE,
  }
}

/**
 * Toggle map style.
 */
const toggleDeveloperMapStyle = () => {
  return {
    type: actionTypes.TOGGLE_DEVELOPER_MAP_STYLE,
  }
}

/**
 * Set available layers in the viewport.
 */
const setAvailableLayers = (availableLayers: Set<LayerConfigurationsLayerIds>) => {
  return {
    type: actionTypes.SET_AVAILABLE_LAYERS,
    payload: { availableLayers }
  }
}

/**
 * Toggle dynamic layers visibility.
 */
 const toggleDynamicLayers = (dynamicLayerIds: Array<MapStyleProperties.LayerId>, selectedLayer: MapStyleProperties.RawDynamicLayersFieldId, layerGroup: DynamicLayerId) => {
  return {
    type: actionTypes.TOGGLE_DYNAMIC_LAYERS,
    payload: {
      dynamicLayerIds,
      selectedLayer,
      layerGroup,
    }
  }
}

/**
 * Reset dynamic layers visibility.
 */
const activateDynamicLayers = (dynamicLayer: DynamicLayerId ,initialYear: MapStyleProperties.RawDynamicLayersFieldId, layerGroup: DynamicLayerId) => {
  return {
    type: actionTypes.ACTIVATE_DYNAMIC_LAYERS,
    payload:{
      dynamicLayer,
      initialYear,
      layerGroup,
    }
  }
}

/**
 * Set selected Dynamic Layer Group.
 */
const setSelectedDynamicLayerGroup = (dynamicLayerId: DynamicLayerId) => {
  return {
    type: actionTypes.SET_SELECTED_DYNAMIC_LAYER,
    payload: {
      dynamicLayerId,
    }
  }
}

/**
 * Set available dynamic layers in the viewport.
 */
 const setAvailableDynamicLayers = (availableDynamicLayers: LayerId[]) => {
  return {
    type: actionTypes.SET_AVAILABLE_DYNAMIC_LAYERS,
    payload: { availableDynamicLayers }
  }
}

/**
 * Reset dynamic layers visibility.
 */
const resetDynamicLayers = () => {
  return {
    type: actionTypes.RESET_DYNAMIC_LAYERS,
  }
}

export default {
  resetFlags,
  toggleLayers,
  initializeLayersOnStyleChange,
  initializeLayers,
  toggleMapStyle,
  setAvailableLayers,
  toggleDeveloperMapStyle,
  toggleDynamicLayers,
  activateDynamicLayers,
  setSelectedDynamicLayerGroup,
  setAvailableDynamicLayers,
  resetDynamicLayers,
}
