import * as firebase from "firebase/app";
import "firebase/functions";
import { Chargebee } from "../../types/Service/Chargebee";

/**
 * Get the identified Chargebee subscription object.
 *
 * @param {string} subscriptionId - The ID of the Chargebee subscription to retrieve.
 *
 * @returns {Promise} - A promise containing a Chargebee subscription object.
 */
const getSubscription = async (subscriptionId: string): Promise<Chargebee.Subscription> => {
  const callable = firebase.functions().httpsCallable("chargebee-getSubscription");
  const result = await callable(subscriptionId);

  return result.data;
}

/**
 * Get the price of a payment plan.
 *
 * @param {PlanId} paymentPlanId
 *
 * @returns {Promise} - A promise containing the price of the payment plan.
 */
const getPaymentPlan = async (paymentPlanId: Chargebee.PlanId): Promise<Chargebee.Plan> => {
  const callable = firebase.functions().httpsCallable("chargebee-getPaymentPlan");
  const result = await callable(paymentPlanId);

  return result.data.plan;
}

/**
 * Create a customer in Chargebee.
 *
 * @param {Customer} customer
 * @param {string} paymentIntentId
 *
 * @returns {Promise} - A promise containing a Chargebee create-customer result object.
 */
const createCustomer = async (customer: Chargebee.Customer, paymentIntentId: string): Promise<any> => {
  const callable = firebase.functions().httpsCallable("chargebee-createCustomer");
  const result = await callable({ customer, paymentIntentId });

  if (result.data.error_code) throw Error(result.data.message);
  return result.data;
}

/**
 * Create a subscription and a customer in Chargebee.
 *
 * @param {PlanId} paymentPlanId
 * @param {Customer} customer
 * @param {string} paymentTokenId
 * @param {Array<string>} couponIds
 *
 * @returns {Promise} - A promise containing a Chargebee create-subscription result object.
 */
const createSubscriptionAndCustomer = async (paymentPlanId: Chargebee.PlanId, customer: Chargebee.Customer, paymentIntentId: string, couponIds: Array<string>): Promise<Chargebee.CreateSubscriptionResult> => {
  const callable = firebase.functions().httpsCallable("chargebee-createSubscriptionAndCustomer");
  const result = await callable({ paymentPlanId, customer, paymentIntentId, couponIds });

  if (result.data.error_code) throw Error(result.data.message);
  return result.data;
}

/**
 * Create a subscription for a customer in Chargebee.
 *
 * @param {PlanId} paymentPlanId
 * @param {string} customerId
 * @param {Array<string>} couponIds
 *
 * @returns {Promise} - A promise containing a Chargebee create-subscription result object.
 */
const createSubscriptionForCustomer = async (paymentPlanId: Chargebee.PlanId, customerId: string, couponIds: Array<string>): Promise<Chargebee.CreateSubscriptionResult> => {
  const callable = firebase.functions().httpsCallable("chargebee-createSubscriptionForCustomer");
  const result = await callable({ paymentPlanId, customerId, couponIds });

  if (result.data.error_code) throw result.data;
  return result.data;
}

/**
 * Get coupon object by coupon ID.
 *
 * @param {string} couponId
 *
 * @returns {Promise} - A promise containing a Chargebee coupon result object.
 */
const getCoupon = async (couponId: string): Promise<Chargebee.Coupon | null> => {
  const callable = firebase.functions().httpsCallable("chargebee-getCoupon");
  const result = await callable(couponId);

  return result.data.coupon;
}

/**
 * Get payment estimate for the payment plan ID with potential discounts.
 *
 * @param {string} paymentPlanId - payment plan ID
 * @param {Array<string>} couponIds - coupon IDs
 *
 * @returns {Promise} - A promise containing a Chargebee estimate result object.
 */
const getEstimate = async (paymentPlanId: string, couponIds: Array<string>): Promise<Chargebee.Estimate> => {
  const callable = firebase.functions().httpsCallable("chargebee-getEstimate");
  const result = await callable({ paymentPlanId, couponIds });

  return result.data.estimate;
}

/**
 * Get payment intent for 3Ds authorization.
 *
 * @param {number} amount - amount to be authorized.
 *
 * @returns {Promise} - A promise containing a Chargebee payment intent result object.
 */
const createPaymentIntent = async (amount: number): Promise<Chargebee.PaymentIntent> => {
  const callable = firebase.functions().httpsCallable("chargebee-createPaymentIntent");
  const result = await callable(amount);

  return result.data.payment_intent;
}

/**
 * Get Credit Card information.
 *
 * @param {object} customerId - Customer Id.
 * @param {object} paymentIntentId - Payment Intent Id.
 *
 * @returns {Promise} - A promise containing a Chargebee card result object.
 */
const addCard = async (customerId: string, paymentIntentId: string): Promise<any> => {
  const callable = firebase.functions().httpsCallable("chargebee-addCard");
  const result = await callable({ customerId, paymentIntentId });

  return result.data;
}

/**
 * Get Credit Card information.
 *
 * @param {object} customerId - Customer Id.
 *
 * @returns {Promise} - A promise containing a Chargebee card result object.
 */
const getCard = async (customerId: string): Promise<any> => {
  const callable = firebase.functions().httpsCallable("chargebee-getCard");
  const result = await callable(customerId);

  return result.data;
}

/**
 * Cancel subscription.
 *
 * @param {object} subscriptionId - Subscription Id.
 * @param {boolean} refund - Flag if subscription should be refunded.
 *
 * @returns {Promise} - A promise containing a customer and subscription object.
 */
const cancelSubscription = async (subscriptionId: string, refund: boolean): Promise<any> => {
  const callable = firebase.functions().httpsCallable("chargebee-cancelSubscription");
  const result = await callable({ subscriptionId, refund });

  return result.data;
}

/**
 * Get the customer latest updated subscription.
 *
 * @param {object} customerId - Customer Id.
 *
 * @returns {Promise} - A promise containing a Chargebee subscription result object.
 */
const getCustomerNewestSubscription = async (customerId: string): Promise<any> => {
  const callable = firebase.functions().httpsCallable("chargebee-getCustomerNewestSubscription");
  const result = await callable(customerId);

  return result.data;
}

export default {
  getSubscription,
  cancelSubscription,
  getPaymentPlan,
  createCustomer,
  createSubscriptionAndCustomer,
  createSubscriptionForCustomer,
  getCoupon,
  getEstimate,
  createPaymentIntent,
  getCard,
  addCard,
  getCustomerNewestSubscription,
}
