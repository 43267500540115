import React from "react";
import { connect } from "react-redux";
import { authenticationActions, authenticationSelectors } from "../../../../state/authentication";
import { developmentSelectors } from "../../../../state/development";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import { NavigateFunction, useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    developmentIsEdited: developmentSelectors.getIsEdited(state),
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
  };
};

const mapDispatchToProps = {
  signOutStart: authenticationActions.signOutStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & StateProps;

const LogOutButton: React.FC<Props> = ({ developmentIsEdited, signOutStart, userIsAuthenticated }) => {
  const navigate: NavigateFunction = useNavigate();

  /**
   * Log out and redirect home.
   */
  const logOut = async () => {
    signOutStart(developmentIsEdited, {
      navigate,
      pathName: Path.Home,
    });
    // navigate(Path.Home);
  };

  const user = authentication.getCurrentUser();
  if (!user) return null;

  return (
    <div className="component--button log-out" onClick={logOut}>
      <div className="button" />
      <div className="text">Log out</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogOutButton);
