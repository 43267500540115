const INITIALIZE = "newDevelopment/INITIALIZE";
const ADDRESS_KEYSTROKE = "newDevelopment/ADDRESS_KEYSTROKE";
const ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START = "newDevelopment/ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START";
const ADDRESS_SUBMIT = "newDevelopment/ADDRESS_SUBMIT";
const CLEAR_FEATURE_SELECTION = "newDevelopment/CLEAR_FEATURE_SELECTION";
const FORWARD_GEOCODE_SUCCESS = "newDevelopment/FORWARD_GEOCODE_SUCCESS";
const GEOCODE_ERROR = "newDevelopment/GEOCODE_ERROR";
const HOVER_FEATURE = "newDevelopment/HOVER_FEATURE";
const SELECT_PARCEL_START = "newDevelopment/SELECT_PARCEL_START";
const SELECT_PARCEL_SUCCESS = "newDevelopment/SELECT_PARCEL_SUCCESS";
const COMBINE_PARCELS_START = "newDevelopment/COMBINE_PARCELS_START";
const COMBINE_PARCELS_SUCCESS = "newDevelopment/COMBINE_PARCELS_SUCCESS";
const SET_PROXIMITY_CENTER = "newDevelopment/SET_PROXIMITY_CENTER";
const SUGGESTED_FEATURE_NEXT = "newDevelopment/SUGGESTED_FEATURE_NEXT";
const SUGGESTED_FEATURE_PREVIOUS = "newDevelopment/SUGGESTED_FEATURE_PREVIOUS";
const SET_POLYGON_MEASUREMENTS = "newDevelopment/SET_POLYGON_MEASUREMENTS";
const RESET_POLYGON_MEASUREMENTS = "newDevelopment/RESET_POLYGON_MEASUREMENTS";
const SET_POLYGON_IS_BEING_CHANGED = "newDevelopment/SET_POLYGON_IS_BEING_CHANGED";
const SET_PARCEL_TOOL = "newDevelopment/SET_PARCEL_TOOL";
const SET_MAP_IS_READY = "newDevelopment/SET_MAP_IS_READY";
const MODIFIER_KEY_DOWN = "newDevelopment/MODIFIER_KEY_DOWN";
const MODIFIER_KEY_UP = "newDevelopment/MODIFIER_KEY_UP";
const SET_DRAWN_PARCELS = "newDevelopment/SET_DRAWN_PARCELS";
const SET_UNIT_SYSTEM = "newDevelopment/SET_UNIT_SYSTEM";
const SET_SMART_SEARCH_IS_OPEN = "newDevelopment/SET_SMART_SEARCH_IS_OPEN";
const SET_PARCELS_IN_VIEWPORT = "newDevelopment/SET_PARCELS_IN_VIEWPORT";
const UPDATE_FILTER = "newDevelopment/UPDATE_FILTER";
const UPDATE_ZONING_FILTER_VALUES = "newDevelopment/UPDATE_ZONING_FILTER_VALUES";
const UPDATE_ALLOWED_USES_FILTER_VALUES = "newDevelopment/UPDATE_ALLOWED_USES_FILTER_VALUES";
const UPDATE_OWNER_NAMES_FILTER_VALUES = "newDevelopment/UPDATE_OWNER_NAMES_FILTER_VALUES";
const UPDATE_OWNER_ADDRESSES_FILTER_VALUES = "newDevelopment/UPDATE_OWNER_ADDRESSES_FILTER_VALUES";
const UPDATE_LAND_USE_FILTER_VALUES = "newDevelopment/UPDATE_LAND_USE_FILTER_VALUES";

const UPDATE_INITIAL_VALUES = "newDevelopment/UPDATE_INITIAL_VALUES";
const SET_DATA_IN_VIEWPORT = "newDevelopment/SET_DATA_IN_VIEWPORT";
const SET_DISPLAY_PIN = "newDevelopment/SET_DISPLAY_PIN";
const SET_QUERY_VIEWPORT = "newDevelopment/SET_QUERY_VIEWPORT";

export default {
  INITIALIZE,
  ADDRESS_KEYSTROKE,
  ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START,
  ADDRESS_SUBMIT,
  CLEAR_FEATURE_SELECTION,
  FORWARD_GEOCODE_SUCCESS,
  GEOCODE_ERROR,
  HOVER_FEATURE,
  SELECT_PARCEL_START,
  SELECT_PARCEL_SUCCESS,
  COMBINE_PARCELS_START,
  COMBINE_PARCELS_SUCCESS,
  SET_PROXIMITY_CENTER,
  SUGGESTED_FEATURE_NEXT,
  SUGGESTED_FEATURE_PREVIOUS,
  SET_POLYGON_MEASUREMENTS,
  RESET_POLYGON_MEASUREMENTS,
  SET_POLYGON_IS_BEING_CHANGED,
  SET_PARCEL_TOOL,
  SET_MAP_IS_READY,
  MODIFIER_KEY_DOWN,
  MODIFIER_KEY_UP,
  SET_DRAWN_PARCELS,
  SET_UNIT_SYSTEM,
  SET_SMART_SEARCH_IS_OPEN,
  SET_PARCELS_IN_VIEWPORT,
  UPDATE_FILTER,
  UPDATE_ZONING_FILTER_VALUES,
  UPDATE_ALLOWED_USES_FILTER_VALUES,
  UPDATE_OWNER_NAMES_FILTER_VALUES,
  UPDATE_OWNER_ADDRESSES_FILTER_VALUES,
  UPDATE_LAND_USE_FILTER_VALUES,
  UPDATE_INITIAL_VALUES,
  SET_DATA_IN_VIEWPORT,
  SET_DISPLAY_PIN,
  SET_QUERY_VIEWPORT,
};
