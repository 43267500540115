import React from "react"

export class TabContainer extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--tab-container">
        {this.props.children}
      </div>
    )
  }
}

export default TabContainer
