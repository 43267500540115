import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class QualifiedCensusTractsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.QualifiedCensusTracts}
        classes="qct"
        legendRows={[
          <LegendRow
            color="#987db7"
            text="QCT (Qualified Census Tracts)"
          />
        ]}
      />
    );
  }
}

export default QualifiedCensusTractsLegend;