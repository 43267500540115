import { ParcelProperty } from "./ParcelProperty";
import valueFormatter from "../../utils/valueFormatter";
import { AllowedUses } from "../../types/AllowedUses";
import addressHelper from "../addressHelper";
import BuildingFeatureModel from "../../types/BuildingFeatureModel";
import Format from "types/Format";
import numeral from "numeral";

/**
 * Get property of selected parcel.
 * Returns null for missing data.
 * This is a private helper function.
 */
const getProperty = (parcel, property: ParcelProperty): any => {
  if (!parcel || !parcel.properties || parcel.properties[property] === undefined) return null;

  return parcel.properties[property];
};

/**
 * Get boolean of whether any of the parcels in the list contains defined data for passed in property.
 * This is a private helper function.
 */
const anyParcelHasValidProperty = (parcelList, property: ParcelProperty): boolean => {
  return parcelList.some((assemblyParcel) => getProperty(assemblyParcel, property) !== null);
};

/**
 * Get property value from largest parcel by area.
 * This is a private helper function.
 */
const getPropertyFromLargestAssemblyParcel = (parcel, property: ParcelProperty): any => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, property);

  let largestParcel;
  let largestParcelArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let parcelArea = getAreaPreferPublished(assemblyParcel);
    if (parcelArea > largestParcelArea) {
      largestParcelArea = parcelArea;
      largestParcel = assemblyParcel;
    }
  }

  return getProperty(largestParcel, property);
};

/**
 * Get the parcel address.
 * For assemblies, a list of addresses is returned.
 */
const getAddress = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const address = getProperty(parcel, ParcelProperty.Address);
    return address ? [address] : [];
  }

  let combinedAddresses: Array<string> = [];

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    const parcelAddress = getProperty(assemblyParcel, ParcelProperty.Address);
    if (parcelAddress) combinedAddresses.push(parcelAddress);
  }

  return combinedAddresses;
};

/**
 * Get the parcel area from published data.
 * For assemblies, the sum of all the published areas is returned.
 */
const getAreaPublished = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.AreaPublished);

  let combinedArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedArea += getProperty(assemblyParcel, ParcelProperty.AreaPublished) || 0;
  }

  return combinedArea;
};

/**
 * Get the parcel computed area.
 * For assemblies, the sum of all the computed areas is returned.
 */
const getAreaComputed = (parcel): number => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.AreaComputed);

  // The computed area of assemblies is the sum of those of the members, rather
  // than the computed area of the union of members. At the time this function
  // was written, the union feature includes a small amount of "buffering" in
  // order to avoid spike and pit artifacts in the unified polygon. This buffer
  // adds roughly 0.5% to the union's computed area, which can be considered
  // "error". Thus, the sum of the members' computed areas is more accurate for
  // the intended purpose.
  let combinedArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedArea += getProperty(assemblyParcel, ParcelProperty.AreaComputed) || 0;
  }

  return combinedArea;
};

/**
 * Get the area to calculate the purchase price.
 */
const getAreaForPurchasePrice = (parcel): number => {
  if (!getIsAnAssembly(parcel)) {
    const parcelArea = getAreaPreferPublished(parcel);
    const existingStructureArea = getExistingStructureArea(parcel);
    const buildingFeatureModels = getBuildingFeatureModel(parcel);
    const buildingFeatureModel = buildingFeatureModels.length > 0 ? buildingFeatureModels[0] : undefined;
    const buildingFeatureModelArea = getAreaForBuildingFeatureModel(buildingFeatureModel);

    const area = existingStructureArea
      ? existingStructureArea
      : buildingFeatureModelArea
      ? buildingFeatureModelArea
      : parcelArea;

    return area;
  }

  let combinedArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedArea += getAreaForPurchasePrice(assemblyParcel);
  }

  return combinedArea;
};

/**
 * Get the area from the building feature model.
 */
const getAreaForBuildingFeatureModel = (buildingFeatureModel?: BuildingFeatureModel): number | null => {
  if (!buildingFeatureModel) return null;
  const metersPerFloor = 3.6576; // 12 FT
  const floors = Math.max(Math.round(buildingFeatureModel.height / metersPerFloor), 1);
  return floors * buildingFeatureModel.footprintArea;
};

/**
 * Get the parcel's published area if it exists. Otherwise get the computed
 * area.
 * For assemblies, sums the areas of each parcel, using the published area if
 * it exists, and otherwise using the computed area.
 */
const getAreaPreferPublished = (parcel) => {
  if (!getIsAnAssembly(parcel)) return getAreaPublished(parcel) || getAreaComputed(parcel);

  let combinedArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedArea +=
      getProperty(assemblyParcel, ParcelProperty.AreaPublished) ||
      getProperty(assemblyParcel, ParcelProperty.AreaComputed);
  }

  return combinedArea;
};

/**
 * Get the parcel's purchase price.
 * For assemblies, parcels with no purchase price data will be assigned
 * the average per-area price of the ones that do have it.
 */
const getPurchasePrice = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.PurchasePrice) || null;

  let combinedPurchasePrice = 0;
  let nonPurchasePriceArea = 0;
  let purchasePriceArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let parcelArea = getAreaPreferPublished(assemblyParcel);
    let parcelPurchasePrice = getProperty(assemblyParcel, ParcelProperty.PurchasePrice) || 0;
    combinedPurchasePrice += parcelPurchasePrice;

    if (parcelPurchasePrice === 0) {
      nonPurchasePriceArea += parcelArea;
    } else {
      purchasePriceArea += parcelArea;
    }
  }

  const averagePurchasePricePerAreaUnit = (purchasePriceArea && combinedPurchasePrice / purchasePriceArea) || 0;
  combinedPurchasePrice += nonPurchasePriceArea * averagePurchasePricePerAreaUnit;
  return combinedPurchasePrice || null;
};

/**
 * Get the parcel's existing structure area.
 * For assemblies, the sum of all the structure areas is returned.
 */
const getExistingStructureArea = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) {
    return getProperty(parcel, ParcelProperty.ExistingStructureArea);
  }
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.ExistingStructureArea)) return null;

  let combinedExistingStructureArea = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedExistingStructureArea += getProperty(assemblyParcel, ParcelProperty.ExistingStructureArea) || 0;
  }

  return combinedExistingStructureArea;
};

/**
 * Get the parcel's existing structure area.
 * For assemblies, the sum of all the structure areas is returned.
 */
const getExistingStructureAreaOpenSource = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.ExistingStructureAreaOpenSource);
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.ExistingStructureAreaOpenSource))
    return null;

  let combinedExistingStructureArea = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedExistingStructureArea += getProperty(assemblyParcel, ParcelProperty.ExistingStructureAreaOpenSource) || 0;
  }

  return combinedExistingStructureArea;
};

/**
 * Get the parcel's unique id.
 * For assemblies, `null` is returned.
 */
const getParcelId = (parcel): string | null => {
  return getProperty(parcel, ParcelProperty.Id);
};

/**
 * Get the parcel's existing structure year built.
 * For assemblies, a de-duplicated, sorted array of all the years built is returned.
 */
const getExistingStructureYearBuilt = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const existingStructureYearBuilt = getProperty(parcel, ParcelProperty.ExistingStructureYearBuilt);
    return existingStructureYearBuilt ? [existingStructureYearBuilt] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.ExistingStructureYearBuilt).sort();
};

/**
 * Get the parcel's existing structure year built.
 * For assemblies, a de-duplicated, sorted array of all the years built is returned.
 */
const getExistingBuildingHeight = (parcel): number => {
  if (!getIsAnAssembly(parcel)) {
    return getProperty(parcel, ParcelProperty.ExistingBuildingHeight);
  }
  let maxBuildingHeight = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    maxBuildingHeight = Math.max(getProperty(assemblyParcel, ParcelProperty.ExistingStructureArea), maxBuildingHeight);
  }

  return maxBuildingHeight;
};

/**
 * Get the parcel's zone id.
 * For assemblies, a de-duplicated, sorted array of all the zone IDs is returned.
 */
const getZoneId = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const zoneId = getProperty(parcel, ParcelProperty.ZoneId);
    return zoneId ? [zoneId] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.ZoneId).sort();
};

/**
 * Get the parcel's URL link depending on the specified URL number.
 */
const getProjectURL = (parcel, urlIndex: ParcelProperty): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const project_URL = getProperty(parcel, urlIndex);
    return project_URL ? [project_URL] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), urlIndex).sort();
};

/**
 * Get the parcel's overlays values.
 */
const getOverlays = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const overlays = getProperty(parcel, ParcelProperty.Overlays);
    return overlays ? [overlays] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.Overlays).sort();
};

/**
 * Get the parcel's allowed buildable area.
 * For assemblies, parcels with no buildable area data will be assigned
 * the average buildable area to parcel area ratio from the parcels that do have data.
 */
const getAllowedBuildableAreaQuery = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.BuildableAreaQuery);
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.BuildableAreaQuery)) return null;

  let combinedBuildableArea = 0;
  let existingBuildableArea = 0;
  let existingParcelArea = 0;
  let nonexistentParcelArea = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let assemblyParcelBuildableArea = getProperty(assemblyParcel, ParcelProperty.BuildableAreaQuery);
    if (assemblyParcelBuildableArea === null) {
      nonexistentParcelArea += getAreaPreferPublished(assemblyParcel);
    } else {
      existingBuildableArea += assemblyParcelBuildableArea;
      existingParcelArea += getAreaPreferPublished(assemblyParcel);
    }

    combinedBuildableArea += assemblyParcelBuildableArea || 0;
  }

  const averageBuildableAreaToParcelAreaRatio = (existingParcelArea && existingBuildableArea / existingParcelArea) || 0;
  combinedBuildableArea += nonexistentParcelArea * averageBuildableAreaToParcelAreaRatio;
  return combinedBuildableArea;
};

/**
 * Get the parcel Buildable Area by FAR.
 * For assemblies, a list of string values is returned.
 */
const getBuildableAreaByFAR = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const buildableAreaByFAR = getProperty(parcel, ParcelProperty.BuildableAreaByFAR);
    return buildableAreaByFAR ? [buildableAreaByFAR] : [];
  }

  let combinedBuildableAreas: Array<string> = [];

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    const parcelBuildableArea = getProperty(assemblyParcel, ParcelProperty.BuildableAreaByFAR);
    if (parcelBuildableArea) combinedBuildableAreas.push(parcelBuildableArea);
  }

  return combinedBuildableAreas;
};

/**
 * Build allowed uses from `allowedDetailedUses` or `allowedUses`
 * and return in object interface `AllowedUses`.
 */
const buildAllowedUses = (parcel): AllowedUses => {
  const allowedDetailedUses = getProperty(parcel, ParcelProperty.AllowedDetailedUses);
  let allowedUses = allowedDetailedUses || getProperty(parcel, ParcelProperty.AllowedUses);
  if (!allowedUses || Object.keys(allowedUses).length === 0) return {};

  if (!allowedDetailedUses) {
    return allowedUses.reduce((uses, category) => Object.assign(uses, { [category]: [] }), {});
  }

  const usesGroup = allowedUses.split(".").filter((group: string) => group !== "");
  if (usesGroup.length === 1 && !usesGroup[0].includes(":")) {
    // Case 1 - Uses comes in the following format:
    // "subcategory1, subcategory2, subcategory3, etc."
    // Subcategories in this case become main categories with empty subcategory array.
    allowedUses = usesGroup[0]
      .split(",")
      .reduce((uses, category) => Object.assign(uses, { [category.trim()]: [] }), {});
  } else if (usesGroup.length > 0) {
    // Case 2 - Uses comes in the following format:
    // "category1: sub1, sub2. category2: sub3. category3."
    // Main categories may not have subcategories (e.g. category3 in example above), in which case
    // subcategories get assigned an empty array.
    allowedUses = usesGroup.reduce((uses, currentGroup) => {
      const groupSplit = currentGroup.split(":");
      uses[groupSplit[0].trim()] =
        groupSplit.length > 1 ? groupSplit[1].split(",").map((subCategory) => subCategory.trim()) : [];
      return uses;
    }, {});
  } else {
    allowedUses = {};
  }

  return allowedUses;
};

/**
 * Get the parcel's allowed uses.
 * For assemblies, a de-duplicated, sorted array of uses is returned.
 */
const getAllowedUses = (parcel): AllowedUses => {
  if (!getIsAnAssembly(parcel)) {
    return buildAllowedUses(parcel);
  }

  let combinedUses: AllowedUses = {};
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let assemblyParcelAllowedUses = buildAllowedUses(assemblyParcel);
    for (let mainCategory of Object.keys(assemblyParcelAllowedUses)) {
      const combinedSubcategories = combinedUses[mainCategory] || [];
      combinedUses[mainCategory] = Array.from(
        new Set([...combinedSubcategories, ...assemblyParcelAllowedUses[mainCategory]])
      ).sort();
    }
  }

  return combinedUses;
};

/**
 * Get parcels architect data.
 * For assemblies, the architect name and url will be returned from the first parcel that has valid data.
 */
const getArchitectData = (parcel): { name: string | null; url: string | null } => {
  let parcelArchitectName;
  let parcelArchitectUrl;

  if (!getIsAnAssembly(parcel)) {
    parcelArchitectUrl = getProperty(parcel, ParcelProperty.ArchitectUrl);
    parcelArchitectName = getProperty(parcel, ParcelProperty.ArchitectName);
  } else {
    for (let assemblyParcel of getAssemblyParcels(parcel)) {
      parcelArchitectName = getProperty(assemblyParcel, ParcelProperty.ArchitectName);
      parcelArchitectUrl = getProperty(assemblyParcel, ParcelProperty.ArchitectUrl);
      if (parcelArchitectName && parcelArchitectUrl) break;
    }
  }

  return {
    name: parcelArchitectName,
    url: parcelArchitectUrl,
  };
};

/**
 * Get boolean of if parcel is in opportunity zone.
 * For assemblies, an array of all the opportunity zone values is returned.
 */
const getIsInOpportunityZone = (parcel): Array<boolean> | null => {
  if (!getIsAnAssembly(parcel)) {
    const isInOpportunityZone = getProperty(parcel, ParcelProperty.IsInOpportunityZone);
    return isInOpportunityZone === null ? null : [isInOpportunityZone];
  }

  let combinedIsInOpportunityZone: Array<boolean> = [];

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let isInOpportunityZone = getProperty(assemblyParcel, ParcelProperty.IsInOpportunityZone);
    if (isInOpportunityZone !== null) combinedIsInOpportunityZone.push(isInOpportunityZone);
  }

  return combinedIsInOpportunityZone;
};

/**
 * Get the assembly parcels.
 */
const getAssemblyParcels = (parcel): Array<any> => {
  return getProperty(parcel, ParcelProperty.AssemblyParcels) || [];
};

/**
 * Get the parcel boolean of if parcel is an assembly.
 */
const getIsAnAssembly = (parcel): boolean => {
  return getProperty(parcel, ParcelProperty.IsAnAssembly);
};

/**
 * Return array of unique parcel property values.
 */
const getUniqueArray = <Type>(parcelFeatures: Array<any> | null, parcelProperty: ParcelProperty): Array<Type> => {
  if (!parcelFeatures) return [];

  let combinedValues = new Set<Type>();

  for (let assemblyFeature of parcelFeatures) {
    const value = getProperty(assemblyFeature, parcelProperty);
    if (value !== undefined && value !== null) combinedValues.add(value);
  }

  return Array.from(combinedValues);
};

/**
 * Return array of parcel property values.
 */
const getArray = <Type>(parcelFeatures: Array<any> | null, parcelProperty: ParcelProperty): Array<Type> => {
  if (!parcelFeatures) return [];

  let combinedValues: Array<Type> = [];

  for (let assemblyFeature of parcelFeatures) {
    const value = getProperty(assemblyFeature, parcelProperty);
    combinedValues.push(value);
  }

  return combinedValues;
};

/**
 * Get median income total.
 */
const getMedianIncomeTotal = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const medianIncomeTotal = getProperty(parcel, ParcelProperty.MedianIncomeTotal);
    return medianIncomeTotal ? [medianIncomeTotal] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.MedianIncomeTotal);
};

/**
 * Get median income total from fields that are not generated.
 */
const getNonGeneratedMedianIncomeTotal = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const medianIncomeTotal = getProperty(parcel, ParcelProperty.MedianIncomeTotal);
    const medianIncomeTotalGenerated = getProperty(parcel, ParcelProperty.MedianIncomeTotalGenerated);
    return medianIncomeTotal && !medianIncomeTotalGenerated ? [medianIncomeTotal] : [];
  }

  let medianIncomeTotalGeneratedArray = getArray<boolean>(
    getAssemblyParcels(parcel),
    ParcelProperty.MedianIncomeTotalGenerated
  );
  let medianIncomeTotalArray = getArray<number>(getAssemblyParcels(parcel), ParcelProperty.MedianIncomeTotal);

  if (medianIncomeTotalGeneratedArray.length !== medianIncomeTotalArray.length) {
    console.warn("Median Income Array from assembly is not the same length as Median Income Generated Array");
    return [];
  }

  return medianIncomeTotalArray.reduce((validValues: Array<number>, medianIncomeTotal, currentIndex) => {
    if (!medianIncomeTotalGeneratedArray[currentIndex]) {
      validValues.push(medianIncomeTotal);
    }

    return validValues;
  }, []);
};

/**
 * Get gdp.
 */
const getGdp = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const gdp = getProperty(parcel, ParcelProperty.Gdp);
    return gdp ? [gdp] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.Gdp);
};

/**
 * Get gross median rent.
 */
const getGrossMedianRent = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const grossMedianRent = getProperty(parcel, ParcelProperty.GrossMedianRent);
    return grossMedianRent ? [grossMedianRent] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.GrossMedianRent);
};

/**
 * Get gross median rent from fields that are not generated.
 */
const getNonGeneratedGrossMedianRent = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const grossMedianRent = getProperty(parcel, ParcelProperty.GrossMedianRent);
    const grossMedianRentGenerated = getProperty(parcel, ParcelProperty.GrossMedianRentGenerated);
    return grossMedianRent && !grossMedianRentGenerated ? [grossMedianRent] : [];
  }

  let grossMedianRentGeneratedArray = getArray<boolean>(
    getAssemblyParcels(parcel),
    ParcelProperty.GrossMedianRentGenerated
  );
  let grossMedianRentArray = getArray<number>(getAssemblyParcels(parcel), ParcelProperty.GrossMedianRent);

  if (grossMedianRentGeneratedArray.length !== grossMedianRentArray.length) {
    console.warn("Gross median rent from assembly is not the same length as gross median rent generated Array");
    return [];
  }

  return grossMedianRentArray.reduce((validValues: Array<number>, grossMedianRent, currentIndex) => {
    if (!grossMedianRentGeneratedArray[currentIndex]) {
      validValues.push(grossMedianRent);
    }

    return validValues;
  }, []);
};

/**
 * Get employment per population.
 */
const getEmploymentPerPopulation = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const employmentPerPopulation = getProperty(parcel, ParcelProperty.EmploymentPerPopulation);
    return employmentPerPopulation ? [employmentPerPopulation] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.EmploymentPerPopulation);
};

/**
 * Get the type of market.
 */
const getMarket = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const market = getProperty(parcel, ParcelProperty.Market);
    return market ? [market] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.Market);
};

/**
 * Get the type of market.
 */
const getBuildingFeatureModel = (parcel): Array<BuildingFeatureModel> => {
  if (!getIsAnAssembly(parcel)) {
    const buildingModel = getProperty(parcel, ParcelProperty.BuildingFeatureModel);
    return buildingModel ? [buildingModel] : [];
  }

  return getUniqueArray<BuildingFeatureModel>(getAssemblyParcels(parcel), ParcelProperty.BuildingFeatureModel);
};

/**
 * Get population density.
 */
const getPopulationDensity = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const populationDensity = getProperty(parcel, ParcelProperty.PopulationDensity);
    return populationDensity ? [populationDensity] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.PopulationDensity);
};

/**
 * Get population density from fields that are not generated.
 */
const getNonGeneratedPopulationDensity = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    const populationDensity = getProperty(parcel, ParcelProperty.PopulationDensity);
    const populationDensityGenerated = getProperty(parcel, ParcelProperty.PopulationDensityGenerated);
    return populationDensity && !populationDensityGenerated ? [populationDensity] : [];
  }

  let populationDensityGeneratedArray = getArray<boolean>(
    getAssemblyParcels(parcel),
    ParcelProperty.PopulationDensityGenerated
  );
  let populationDensityArray = getArray<number>(getAssemblyParcels(parcel), ParcelProperty.PopulationDensity);

  if (populationDensityGeneratedArray.length !== populationDensityArray.length) {
    console.warn("Population density from assembly is not the same length as population density generated Array");
    return [];
  }

  return populationDensityArray.reduce((validValues: Array<number>, populationDensity, currentIndex) => {
    if (!populationDensityGeneratedArray[currentIndex]) {
      validValues.push(populationDensity);
    }

    return validValues;
  }, []);
};

/**
 * Get floor area ratio.
 */
const getFloorAreaRatio = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const floorAreaRatio = getProperty(parcel, ParcelProperty.FloorAreaRatio);
    return floorAreaRatio ? [floorAreaRatio] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.FloorAreaRatio);
};

/**
 * Get Building Height.
 */
const getBuildingHeight = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const buildingHeight = getProperty(parcel, ParcelProperty.BuildingHeight);
    return buildingHeight ? [buildingHeight] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.BuildingHeight);
};

/**
 * Get Lot Coverage.
 */
const getLotCoverage = (parcel): Array<number> => {
  if (!getIsAnAssembly(parcel)) {
    let lotCoverage = getProperty(parcel, ParcelProperty.LotCoverage);
    if (lotCoverage && valueFormatter.isNumeric(lotCoverage)) lotCoverage /= 100;
    return lotCoverage ? [lotCoverage] : [];
  }

  return getUniqueArray<number>(getAssemblyParcels(parcel), ParcelProperty.LotCoverage).map((value) =>
    valueFormatter.isNumeric(value) ? value / 100 : value
  );
};

/**
 * Get minimum lot size.
 */
const getMinimumLotSize = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const minimumLotSize = getProperty(parcel, ParcelProperty.MinimumLotSize);
    return minimumLotSize ? [minimumLotSize] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.MinimumLotSize);
};

/**
 * Get number of floors.
 */
const getNumberOfFloors = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numberOfFloors = getProperty(parcel, ParcelProperty.NumberOfFloors);
    return numberOfFloors ? [numberOfFloors] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.NumberOfFloors);
};

/**
 * Get number of units allowed.
 */
const getNumberOfUnitsAllowed = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numberOfUnitsAllowed = getProperty(parcel, ParcelProperty.NumberOfUnitsAllowed);
    return numberOfUnitsAllowed ? [numberOfUnitsAllowed] : [];
  }

  let combinedUnitsAllowed: Array<string> = [];

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let unitsAllowed = getProperty(assemblyParcel, ParcelProperty.NumberOfUnitsAllowed);
    if (unitsAllowed) combinedUnitsAllowed.push(unitsAllowed);
  }

  return combinedUnitsAllowed;
};

/**
 * Get the total of numbers of units allowed when assemblages
 */
const getTotalNumberOfUnitsAllowed = (units: Array<String>) => {
  if (!Array.isArray(units)) return null;

  let totlOfUnits: number = 0;
  for (let unit of units) {
    let formattedValue = valueFormatter.format(Number(unit), {
      type: Format.Type.Number,
      decimalPlaces: 2,
    });
    //This formatting using numeral is necessary because sometimes the units number comes with commas
    // and if we don't do the formatting it trhows NaN as the total of units allowed
    totlOfUnits += numeral(formattedValue).value();
  }
  return totlOfUnits;
};

/**
 * Combine fields for density.
 */
const combineDensityFields = (numerator, denominator, unitOfMeasure, context) => {
  if (!numerator) return null;

  let prefix = `${numerator} ${context}${numerator > 1 && context ? "s" : ""}`;
  let suffix = "";
  if (denominator && unitOfMeasure) {
    suffix = `/ ${denominator > 1 ? denominator : ""} ${unitOfMeasure}`;
  } else if (!denominator && !unitOfMeasure) {
    suffix = "Total";
  } else if (unitOfMeasure) {
    prefix = numerator;
    suffix = unitOfMeasure;
  } else {
    return null;
  }

  return `${prefix} ${suffix}`.trim();
};

/**
 * Get living density ratio.
 */
const getLivingDensityRatio = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.LivingUnitDensityNumerator);
    const denominator = getProperty(parcel, ParcelProperty.LivingUnitDensityDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.LivingUnitDensityUnitOfMeasure);

    let combinedDensityFields = combineDensityFields(numerator, denominator, unitOfMeasure, "unit");
    return combinedDensityFields ? [combinedDensityFields] : [];
  }

  let assemblyDensityRatio = new Set<string>();
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let livingDensityRatio = getLivingDensityRatio(assemblyParcel);
    if (livingDensityRatio.length > 0) assemblyDensityRatio.add(livingDensityRatio[0]);
  }

  return Array.from(assemblyDensityRatio);
};

/**
 * Get hotel density ratio.
 */
const getHotelDensityRatio = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.HotelUnitDensityNumerator);
    const denominator = getProperty(parcel, ParcelProperty.HotelUnitDensityDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.HotelUnitDensityUnitOfMeasure);

    let combinedDensityFields = combineDensityFields(numerator, denominator, unitOfMeasure, "key");
    return combinedDensityFields ? [combinedDensityFields] : [];
  }

  let assemblyDensityRatio = new Set<string>();
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let hotelDensityRatio = getHotelDensityRatio(assemblyParcel);
    if (hotelDensityRatio.length > 0) assemblyDensityRatio.add(hotelDensityRatio[0]);
  }

  return Array.from(assemblyDensityRatio);
};

/**
 * Combine fields for parking.
 */
const combineParkingFields = (numerator, denominator, unitOfMeasure, context) => {
  if (!numerator) return null;

  let prefix = valueFormatter.isNumeric(numerator) ? Number(numerator) : numerator;
  let suffix = "";
  if (denominator && unitOfMeasure) {
    prefix = `${prefix} ${context}${numerator > 1 && context ? "s" : ""}`;
    suffix = `/ ${denominator > 1 ? denominator : ""} ${unitOfMeasure}`;
  } else if (denominator || unitOfMeasure) {
    return null;
  }

  return `${prefix} ${suffix}`.trim();
};

/**
 * Get parking ratio for parcel assemblies.
 */
const getAssemblyParkingRatio = (parcel, getParkingRatio: (parcel) => Array<string>): Array<string> => {
  let assemblyParkingRatio = new Set<string>();
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let ratio = getParkingRatio(assemblyParcel);
    if (ratio.length > 0) assemblyParkingRatio.add(ratio[0]);
  }

  return Array.from(assemblyParkingRatio);
};

/**
 * Get residential parking ratio.
 */
const getResidentialParkingRatio = (parcel): Array<string> => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesResidentialNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesResidentialDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesResidentialUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getResidentialParkingRatio);
};

/**
 * Get single family parking ratio
 */
const getSingleFamilyParkingRatio = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesSingleFamilyNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesSingleFamilyDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesSingleFamilyUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getSingleFamilyParkingRatio);
};

/**
 * Get hotel parking ratio.
 */
const getHotelParkingRatio = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesHotelNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesHotelDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesHotelUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getHotelParkingRatio);
};

/**
 * Get retail parking ratio.
 */
const getRetailParkingRatio = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesRetailNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesRetailDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesRetailUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getRetailParkingRatio);
};

/**
 * Get office parking ratio.
 */
const getOfficeParkingRatio = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesOfficeNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesOfficeDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesOfficeUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getOfficeParkingRatio);
};

/**
 * Get industrial parking ratio.
 */
const getIndustrialParkingRatio = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const numerator = getProperty(parcel, ParcelProperty.ParkingSpacesIndustrialNumerator);
    const denominator = getProperty(parcel, ParcelProperty.ParkingSpacesIndustrialDenominator);
    const unitOfMeasure = getProperty(parcel, ParcelProperty.ParkingSpacesIndustrialUnitOfMeasure);

    let combinedParkingFields = combineParkingFields(numerator, denominator, unitOfMeasure, "space");
    return combinedParkingFields ? [combinedParkingFields] : [];
  }

  return getAssemblyParkingRatio(parcel, getIndustrialParkingRatio);
};

/**
 * Get primary setbacks.
 */
const getSetbacksPrimary = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksPrimary);
};

/**
 * Get side street setbacks.
 */
const getSetbacksSideStreet = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksSideStreet);
};

/**
 * Get side interior setbacks.
 */
const getSetbacksSideInterior = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksSideInterior);
};

/**
 * Get rear setbacks.
 */
const getSetbacksRear = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksRear);
};

/**
 * Get Building Height Footnotes.
 */
const getBuildingHeightFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.BuildingHeightFootnote);
};

/**
 * Get FAR Footnotes.
 */
const getFarFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.FarFootnote);
};

/**
 * Get Lot Coverage Footnotes.
 */
const getLotCoverageFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.LotCoverageFootnote);
};

/**
 * Get Minimum Lot Size Footnotes.
 */
const getMinimumLotSizeFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.MinimumLotSizeFootnote);
};

/**
 * Get Number of Floors Footnotes.
 */
const getNumberOfFloorsFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.NumberOfFloorsFootnote);
};

/**
 * Get Unit Density Footnotes.
 */
const getUnitDensityFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.UnitDensityFootnote);
};

/**
 * Get Hotel Unit Density Footnotes.
 */
const getHotelDensityFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.HotelDensityFootnote);
};

/**
 * Get Setbacks Footnotes.
 */
const getSetbacksFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbackFootnote);
};

/**
 * Get Uses Allowed Footnotes.
 */
const getUsesFootnotes = (parcel) => {
  return getProperty(parcel, ParcelProperty.UsesFootnote);
};

/**
 * Get Parking Multifamily Footnotes.
 */
const getParkingMultifamilyFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingMultifamilyFootnote);
};

/**
 * Get Parking Single Family Footnotes.
 */
const getParkingSingleFamilyFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingSingleFamilyFootnote);
};

/**
 * Get Parking Hotel Footnotes.
 */
const getParkingHotelFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingHotelFootnote);
};

/**
 * Get Parking Office Footnotes.
 */
const getParkingOfficeFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingOfficeFootnote);
};

/**
 * Get Parking Retail Footnotes.
 */
const getParkingRetailFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingRetailFootnote);
};

/**
 * Get Parking Industrial Footnotes.
 */
const getParkingIndustrialFootnote = (parcel) => {
  return getProperty(parcel, ParcelProperty.ParkingIndustrialFootnote);
};

/**
 * Get numeric value of FAR.
 */
const getFloorAreaRatioQuery = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const floorAreaRatio = getProperty(parcel, ParcelProperty.FloorAreaRatioQuery);
    return floorAreaRatio === null ? [] : [floorAreaRatio];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.FloorAreaRatioQuery).filter(
    (value) => value !== null
  );
};

/**
 * Return the minimum floor area ratio from the  array.
 */
const getMinimumFloorAreaRatio = (parcel) => {
  let floorAreaRatio = getFloorAreaRatioQuery(parcel);

  let minFar;
  for (let far of floorAreaRatio) {
    const farNumber = Number(far);
    if (isNaN(farNumber)) continue;
    minFar = minFar !== undefined && minFar < farNumber ? minFar : farNumber;
  }
  return minFar;
};

/**
 * Get Active Stratum.
 */
const getBasicStratum = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const basicStratum = getProperty(parcel, ParcelProperty.BasicStratum);
    return basicStratum ? [basicStratum] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.BasicStratum).filter((value) => !!value);
};

/**
 * Get Land Use Code.
 */
const getLandUseCode = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const landUseCode = getProperty(parcel, ParcelProperty.LandUseCode);
    return landUseCode ? [landUseCode] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.LandUseCode).filter((value) => !!value);
};

/**
 * Get Public Land.
 */
const getPublicLand = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const publicLand = getProperty(parcel, ParcelProperty.PublicLand);
    return publicLand ? [publicLand] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.PublicLand).filter((value) => !!value);
};

/**
 * Get Parcel Id.
 */
const getParcelOfficialId = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const parcelId = getProperty(parcel, ParcelProperty.ParcelId);
    return parcelId ? [parcelId] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.ParcelId).filter((value) => !!value);
};

/**
 * Get Number of Units.
 */
const getNumberOfUnits = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.NumberOfResidentialUnits) || null;
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.NumberOfResidentialUnits)) return null;

  let combinedNumberOfResidentialUnits = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedNumberOfResidentialUnits += getProperty(assemblyParcel, ParcelProperty.NumberOfResidentialUnits) || 0;
  }

  return combinedNumberOfResidentialUnits || null;
};

/**
 * Get Number of Buildings.
 */
const getNumberOfBuildings = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.NumberOfBuildings) || null;
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.NumberOfBuildings)) return null;

  let combinedNumberOfBuildings = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedNumberOfBuildings += getProperty(assemblyParcel, ParcelProperty.NumberOfBuildings) || 0;
  }

  return combinedNumberOfBuildings || null;
};

/**
 * Get Construction Class.
 */
const getConstructionClass = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const constructionClass = getProperty(parcel, ParcelProperty.ConstructionClass);
    return constructionClass ? [constructionClass] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.ConstructionClass).filter(
    (value) => !!value
  );
};

/**
 * Get Improvement Quality.
 */
const getImprovementQuality = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const improvementQuality = getProperty(parcel, ParcelProperty.ImprovementQuality);
    return improvementQuality ? [improvementQuality] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.ImprovementQuality).filter(
    (value) => !!value
  );
};

/**
 * Get the parcel's land value.
 * For assemblies, parcels with no land value data will be assigned
 * the average per-area price of the ones that do have it.
 */
const getLandValue = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.LandValue) || null;

  let combinedLandValue = 0;
  let nonLandValueArea = 0;
  let landValueArea = 0;
  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    let parcelArea = getAreaPreferPublished(assemblyParcel);
    let parcelLandValue = getProperty(assemblyParcel, ParcelProperty.LandValue) || 0;
    combinedLandValue += parcelLandValue;

    if (parcelLandValue === 0) {
      nonLandValueArea += parcelArea;
    } else {
      landValueArea += parcelArea;
    }
  }

  const averageLandValuePerAreaUnit = (landValueArea && combinedLandValue / landValueArea) || 0;
  combinedLandValue += nonLandValueArea * averageLandValuePerAreaUnit;
  return combinedLandValue || null;
};

/**
 * Get Sale Price.
 */
const getSalePrice = (parcel): number | null => {
  if (!getIsAnAssembly(parcel)) return getProperty(parcel, ParcelProperty.SalePrice) || null;
  if (!anyParcelHasValidProperty(getAssemblyParcels(parcel), ParcelProperty.SalePrice)) return null;

  let combinedSalePrice = 0;

  for (let assemblyParcel of getAssemblyParcels(parcel)) {
    combinedSalePrice += getProperty(assemblyParcel, ParcelProperty.SalePrice) || 0;
  }

  return combinedSalePrice || null;
};

/**
 * Get sale date.
 */
const getSaleDate = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const saleMonth = getProperty(parcel, ParcelProperty.SaleMonth);
    const saleYear = getProperty(parcel, ParcelProperty.SaleYear);
    return saleMonth && saleYear ? [`${saleMonth}/${saleYear}`] : [];
  }

  let datesArray: Array<String> = [];

  const monthArray = getArray(getAssemblyParcels(parcel), ParcelProperty.SaleMonth);
  const yearArray = getArray(getAssemblyParcels(parcel), ParcelProperty.SaleYear);

  monthArray.forEach((month, index) => {
    if (month && yearArray[index]) datesArray.push(`${month}/${yearArray[index]}`);
  });

  return datesArray;
};

/**
 * Get Multi-parcel Sale.
 */
const getMultiParcelSale = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const multiParcelSale = getProperty(parcel, ParcelProperty.MultiParcelSale);
    return multiParcelSale ? [multiParcelSale] : [];
  }

  return getUniqueArray<string>(getAssemblyParcels(parcel), ParcelProperty.MultiParcelSale).filter((value) => !!value);
};

/**
 * Get Owner Name.
 */
const getOwnerName = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const ownerName = getProperty(parcel, ParcelProperty.OwnerName);
    return ownerName ? [ownerName] : [];
  }

  return getArray<string>(getAssemblyParcels(parcel), ParcelProperty.OwnerName).filter((value) => !!value);
};

/**
 * Get Owner Address.
 */
const getOwnerAddress = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const address = getProperty(parcel, ParcelProperty.OwnerAddress);
    const city = getProperty(parcel, ParcelProperty.OwnerCity);
    const state = getProperty(parcel, ParcelProperty.OwnerState);
    const zipCode = getProperty(parcel, ParcelProperty.OwnerZipCode);
    const fullAddress = addressHelper.buildFullAddress(address, city, state, zipCode);
    return fullAddress ? [fullAddress] : [];
  }

  let fullAddressArray: Array<String> = [];

  const addressArray = getArray(getAssemblyParcels(parcel), ParcelProperty.OwnerAddress);
  const cityArray = getArray(getAssemblyParcels(parcel), ParcelProperty.OwnerCity);
  const stateArray = getArray(getAssemblyParcels(parcel), ParcelProperty.OwnerState);
  const zipCodeArray = getArray(getAssemblyParcels(parcel), ParcelProperty.OwnerZipCode);

  addressArray.forEach((address, index) => {
    const fullAddress = addressHelper.buildFullAddress(
      address,
      cityArray[index],
      stateArray[index],
      zipCodeArray[index]
    );
    if (fullAddress) fullAddressArray.push(fullAddress);
  });

  return fullAddressArray;
};

/**
 * Get city from a parcel
 */
const getCity = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const city = getProperty(parcel, ParcelProperty.City);
    return city ? [city] : [];
  } else {
    const cityArray: string[] = getArray(getAssemblyParcels(parcel), ParcelProperty.City);
    return cityArray;
  }
};

/**
 * Get state from a parcel
 */
const getState = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const state = getProperty(parcel, ParcelProperty.State);
    return state ? [state] : [];
  } else {
    const stateArray: string[] = getArray(getAssemblyParcels(parcel), ParcelProperty.State);
    return stateArray;
  }
};

/**
 * Get zip code from a parcel
 */
const getZipCode = (parcel) => {
  if (!getIsAnAssembly(parcel)) {
    const zipCode = getProperty(parcel, ParcelProperty.ZipCode);
    return zipCode ? [zipCode] : [];
  } else {
    const zipCodeArray: string[] = getArray(getAssemblyParcels(parcel), ParcelProperty.ZipCode);
    return zipCodeArray;
  }
};

/**
 * Get Primary Setback Above First Floor.
 */
const getSetbacksPrimaryAboveFirstFloor = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksPrimaryAboveFirstFloor);
};

/**
 * Get Side Street Setback Above First Floor.
 */
const getSetbacksSideStreetAboveFirstFloor = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksSideStreetAboveFirstFloor);
};

/**
 * Get Interior Side Setback Above First Floor.
 */
const getSetbacksSideInteriorAboveFirstFloor = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksSideInteriorAboveFirstFloor);
};

/**
 * Get Rear Setback Above First Floor.
 */
const getSetbacksRearAboveFirstFloor = (parcel) => {
  return getProperty(parcel, ParcelProperty.SetbacksRearAboveFirstFloor);
};

export default {
  getAddress,
  getAreaPublished,
  getAreaComputed,
  getAreaPreferPublished,
  getPurchasePrice,
  getExistingStructureArea,
  getParcelId,
  getExistingStructureYearBuilt,
  getZoneId,
  getAllowedUses,
  getAllowedBuildableAreaQuery,
  getBuildableAreaByFAR,
  getArchitectData,
  getIsInOpportunityZone,
  getAssemblyParcels,
  getIsAnAssembly,
  getEmploymentPerPopulation,
  getMedianIncomeTotal,
  getNonGeneratedMedianIncomeTotal,
  getGdp,
  getGrossMedianRent,
  getNonGeneratedGrossMedianRent,
  getPopulationDensity,
  getNonGeneratedPopulationDensity,
  getFloorAreaRatio,
  getBuildingHeight,
  getLotCoverage,
  getNumberOfFloors,
  getNumberOfUnitsAllowed,
  getTotalNumberOfUnitsAllowed,
  getResidentialParkingRatio,
  getSingleFamilyParkingRatio,
  getHotelParkingRatio,
  getRetailParkingRatio,
  getOfficeParkingRatio,
  getIndustrialParkingRatio,
  getLivingDensityRatio,
  getHotelDensityRatio,
  getSetbacksPrimary,
  getSetbacksSideStreet,
  getSetbacksSideInterior,
  getSetbacksRear,
  getBuildingHeightFootnotes,
  getFarFootnotes,
  getLotCoverageFootnotes,
  getMinimumLotSizeFootnote,
  getMinimumLotSize,
  getNumberOfFloorsFootnotes,
  getUnitDensityFootnotes,
  getHotelDensityFootnotes,
  getSetbacksFootnotes,
  getUsesFootnotes,
  getParkingMultifamilyFootnote,
  getParkingSingleFamilyFootnote,
  getParkingHotelFootnote,
  getParkingOfficeFootnote,
  getParkingRetailFootnote,
  getParkingIndustrialFootnote,
  getMinimumFloorAreaRatio,
  getPropertyFromLargestAssemblyParcel,
  getBasicStratum,
  getLandUseCode,
  getPublicLand,
  getParcelOfficialId,
  getNumberOfUnits,
  getNumberOfBuildings,
  getConstructionClass,
  getImprovementQuality,
  getLandValue,
  getSalePrice,
  getSaleDate,
  getMultiParcelSale,
  getOwnerName,
  getOwnerAddress,
  getSetbacksPrimaryAboveFirstFloor,
  getSetbacksSideStreetAboveFirstFloor,
  getSetbacksSideInteriorAboveFirstFloor,
  getSetbacksRearAboveFirstFloor,
  getMarket,
  getBuildingFeatureModel,
  getAreaForPurchasePrice,
  getAreaForBuildingFeatureModel,
  getProjectURL,
  getOverlays,
  getExistingStructureAreaOpenSource,
  getExistingBuildingHeight,
  getCity,
  getState,
  getZipCode,
};
