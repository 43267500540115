import React from "react";
import { useSelector } from "react-redux";
import { GraphId } from "types/Graph";
import { pdfSelectors } from "state/pdf";
import PdfGraphGenerator from "./PdfGraphGenerator";

const PdfGraphGenerators = () => {
  const graphDataIsAvailable = useSelector(pdfSelectors.getGraphDataIsAvailable);

  if (!graphDataIsAvailable) return null;

  return (
    <div className="component--pdf-graph-generators">
      <PdfGraphGenerator graphId={GraphId.Population} />
      <PdfGraphGenerator graphId={GraphId.EmploymentPerPopulation} />
      <PdfGraphGenerator graphId={GraphId.MedianGrossRent} />
      <PdfGraphGenerator graphId={GraphId.MedianHouseholdIncome} />
    </div>
  )
}

export default PdfGraphGenerators;
