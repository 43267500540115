import React from "react";
import { connect } from "react-redux";
import { GeoJsonObject } from "geojson";
import Format from "../../../../types/Format";
import Unit from "../../../../types/Unit";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import valueFormatter from "../../../../utils/valueFormatter";
import unitConversions from "../../../../utils/unitConversions";
import authentication from "../../../../utils/authentication";
import Cell from "../../Cell";
import ConditionalCellRow from "../ConditionalCellRow";
import Setbacks from "./Setbacks";
import UnitDensityRatio from "./UnitDensityRatio";
import ParkingRatio from "./ParkingRatio";
import AllowedUses from "./AllowedUses";
import Footnotes from "./Footnotes";
import AccordionSection from "../../AccordionSection";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../state/ui/shared/accordionSection/reducers";
import { subscriptionSelectors } from "../../../../state/subscription";
import CellText from "../../CellText";
import valueGetters from "../../../../state/development/utils/valueGetters";
import { Type } from "types/Format/Format";
import userHelper from "utils/userHelper";
import getFormattedValuesForPresentation from "utils/parcel/parcelDataPresentationHelper";
import { Tier } from "types/Tier";
import ProjectLinks from "./ProjectLinks/ProjectLinks";
import { newDevelopmentSelectors } from "state/newDevelopment";
import { System } from "types/Unit/Unit";
import { ParentPage } from "../types";
import { developmentSelectors } from "state/development";
import DeepblocksFootnotes from "./DeepblocksFootnotes";

type OwnProps = {
  parcelFeature: GeoJsonObject;
  parentPage?: ParentPage;
};

const mapStateToProps = (state) => {
  return {
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.Zoning),
    tier: subscriptionSelectors.getTier(state),
    newDevelopmentUnitSystem: newDevelopmentSelectors.getUnitSystem(state),
    explorerUnitSystem: developmentSelectors.getUnitSystem(state),
  };
};

const mapDispatchToProps = {
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class Zoning extends React.PureComponent<Props, {}> {
  /**
   * Render architect firm and link when available.
   */
  renderCollaborationMessage = () => {
    const { parcelFeature } = this.props;
    const architectFirmData = parcelAccessors.getArchitectData(parcelFeature);
    if (!architectFirmData.name) return null;

    let url = architectFirmData.url;
    if (!url) return <p className="collaboration-container">In collaboration with {architectFirmData.name}</p>;

    return (
      <p className="collaboration-container">
        In collaboration with{" "}
        <a href={url} target="_blank" rel="noopener noreferrer">
          {architectFirmData.name}
        </a>
      </p>
    );
  };

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.Zoning, !this.props.accordionIsOpen);
  };

  /**
   * Add total to the `Units Allowed` array
   */
  addTotalOfUnitsAllowed = (numberOfUnitsAllowed) => {
    if (numberOfUnitsAllowed) {
      const totalOfUnitsAllowed = parcelAccessors.getTotalNumberOfUnitsAllowed(numberOfUnitsAllowed);
      const formattedValue = valueFormatter.format(totalOfUnitsAllowed, {
        suffix: " units",
        type: Type.Number,
        decimalPlaces: 2,
      });

      numberOfUnitsAllowed.push("__________________");
      numberOfUnitsAllowed.push(`Total: ${formattedValue}`);
      return numberOfUnitsAllowed;
    }
  };

  render() {
    const { parcelFeature, tier, newDevelopmentUnitSystem, explorerUnitSystem, parentPage } = this.props;
    const { isNumeric } = valueFormatter;
    const isDeveloperTier = tier === Tier.Developer;
    const isDemoTier = tier === Tier.None;
    if (isDeveloperTier || isDemoTier) return null;
    const hasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);
    const isAnAssembly = parcelAccessors.getIsAnAssembly(parcelFeature);
    const isMetricSystem =
      parentPage === ParentPage.Explorer
        ? explorerUnitSystem === System.Metric
        : newDevelopmentUnitSystem === System.Metric;

    const buildableAreaQuery = isMetricSystem
      ? parcelAccessors.getAllowedBuildableAreaQuery(parcelFeature)
      : unitConversions.convert(
          parcelAccessors.getAllowedBuildableAreaQuery(parcelFeature),
          Unit.Type.SquareMeters,
          Unit.Type.SquareFeet
        );

    const buildableAreaByFAR = parcelAccessors.getBuildableAreaByFAR(parcelFeature);
    const formattedBuildableAreaByFAR = getFormattedValuesForPresentation(buildableAreaByFAR);

    const floorAreaRatio = parcelAccessors.getFloorAreaRatio(parcelFeature);
    const formattedFloorAreaRatio = getFormattedValuesForPresentation(floorAreaRatio);

    const numberOfFloors = parcelAccessors.getNumberOfFloors(parcelFeature);

    let formattedNumberOfFloors = getFormattedValuesForPresentation(numberOfFloors);
    const numberOfFloorsMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: {
        suffix: " stories",
        type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText,
        hidden: !hasAccess,
      },
    });

    let numberOfUnitsAllowed = parcelAccessors.getNumberOfUnitsAllowed(parcelFeature);
    const numberOfUnitsAllowedMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: {
        suffix: " units",
        type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText,
        decimalPlaces: 2,
        hidden: !hasAccess,
      },
    });
    //Add the total of Units Allowed to the array when assemblages.
    if (isAnAssembly && numberOfUnitsAllowed.length > 0)
      numberOfUnitsAllowed = this.addTotalOfUnitsAllowed(numberOfUnitsAllowed);

    const buildingHeight = parcelAccessors.getBuildingHeight(parcelFeature);
    const formattedBuildingHeight = getFormattedValuesForPresentation(buildingHeight);
    const buildingHeightMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: {
        suffix: " FT",
        type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText,
        hidden: !hasAccess,
      },
    });

    const lotCoverage = parcelAccessors.getLotCoverage(parcelFeature);
    const formattedLotCoverage = getFormattedValuesForPresentation(lotCoverage);
    const lotCoverageMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: {
        type: isNumeric(value) ? Format.Type.Percentage : Format.Type.PlainText,
        hidden: !hasAccess,
      },
    });

    const minimumLotSize = parcelAccessors.getMinimumLotSize(parcelFeature);
    const formattedMinimumLotSize = getFormattedValuesForPresentation(minimumLotSize);
    const minimumLotSizeMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: {
        type: Format.Type.PlainText,
        hidden: !hasAccess,
      },
    });

    const zoneId = parcelAccessors.getZoneId(parcelFeature);
    const hiddenMap: Format.FormattingMap = (value) => ({
      value,
      formatOptions: { hidden: !hasAccess },
    });

    const overlays = parcelAccessors.getOverlays(parcelFeature);
    const formattedOverlays = getFormattedValuesForPresentation(overlays);
    if (zoneId.length === 0) return null;

    return (
      <AccordionSection
        title="Zoning Limits"
        iconClass="zoning"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
      >
        {this.renderCollaborationMessage()}
        <table>
          <tbody>
            {ConditionalCellRow(
              buildableAreaQuery,
              <CellText text="Approximate Buildable Area" />,
              <Cell
                getter={valueGetters.generic}
                formatOptions={{
                  type: isNumeric(buildableAreaQuery) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: isMetricSystem ? " m²" : " SF",
                  hidden: !hasAccess,
                }}
                styleVariation="bold"
              />
            )}
            {ConditionalCellRow(
              formattedBuildableAreaByFAR,
              <CellText text="Buildable Area by FAR" />,
              <Cell
                getter={valueGetters.generic}
                formatOptions={{
                  type: isNumeric(formattedBuildableAreaByFAR) ? Format.Type.Number : Format.Type.PlainText,
                  suffix: isMetricSystem ? " m²" : " SF",
                  hidden: !hasAccess,
                }}
                styleVariation="bold"
              />
            )}
            {ConditionalCellRow(
              formattedFloorAreaRatio,
              <CellText text="FAR" />,
              <Cell getter={valueGetters.generic} styleVariation="bold capitalize" formattingMap={hiddenMap} />
            )}
            {ConditionalCellRow(
              formattedNumberOfFloors,
              <CellText text="Number of Floors" />,
              <Cell getter={valueGetters.generic} styleVariation="bold capitalize" formattingMap={numberOfFloorsMap} />
            )}
            {ConditionalCellRow(
              numberOfUnitsAllowed,
              <CellText text="Estimated Units Allowed" />,
              <Cell
                getter={valueGetters.generic}
                styleVariation="bold capitalize"
                formattingMap={numberOfUnitsAllowedMap}
              />
            )}
            {ConditionalCellRow(
              formattedBuildingHeight,
              <CellText text="Building Height Allowed" />,
              <Cell getter={valueGetters.generic} styleVariation="bold capitalize" formattingMap={buildingHeightMap} />
            )}
            {ConditionalCellRow(
              formattedLotCoverage,
              <CellText text="Lot Coverage" />,
              <Cell getter={valueGetters.generic} styleVariation="bold capitalize" formattingMap={lotCoverageMap} />
            )}

            {ConditionalCellRow(
              formattedMinimumLotSize,
              <CellText text="Minimum Lot Size" />,
              <Cell getter={valueGetters.generic} styleVariation="bold capitalize" formattingMap={minimumLotSizeMap} />
            )}
            {ConditionalCellRow(
              zoneId,
              <CellText text="Zoning District" />,
              <Cell getter={valueGetters.generic} styleVariation="bold uppercase" formattingMap={hiddenMap} />
            )}
            {ConditionalCellRow(
              formattedOverlays,
              <CellText text="Overlays" />,
              <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} />
            )}
          </tbody>
        </table>
        <UnitDensityRatio parcelFeature={parcelFeature} />
        <AllowedUses parcelFeature={parcelFeature} />
        <Setbacks parcelFeature={parcelFeature} />
        <ParkingRatio parcelFeature={parcelFeature} />
        <ProjectLinks parcelFeature={parcelFeature} />
        <Footnotes parcelFeature={parcelFeature} />
        <DeepblocksFootnotes parcelFeature={parcelFeature} />
      </AccordionSection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Zoning);
