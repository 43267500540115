import React from "react";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerLegend from "../sharedComponents/DynamicLayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class DynamicMedianRentLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <DynamicLayerLegend
        layerGroup={DynamicLayerId.Dynamic_Layer_MedianRent}
        classes="median-rent"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< $500"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fd0d0d 0%, #fff305 100%)"
            text="$500 - $1,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fff305 0%, #0fe600 100%)"
            text="$1,000 - $1,500"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #0fe600 0%, #16e7fe 100%)"
            text="$1,500 - $2,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #16e7fe 0%, #024be8 100%)"
            text="$2,000 - $2,500"
            wide
          />,
          <LegendRow
            color="#024be8"
            text="> $2,500"
            wide
          />,
        ]}
      />
    );
  }
}

export default DynamicMedianRentLegend;
