import React from "react";
import { connect } from "react-redux";
import { Layer, Source } from "react-mapbox-gl";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import { ParcelTool } from "../../../../../types/ParcelTool";

const DRAWN_PARCELS_SOURCE = "drawn-parcels-source";

interface Props {
  // Redux props.
  drawnParcels: any;
  parcelTool: ParcelTool;
}

class DrawnFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Source
          id={DRAWN_PARCELS_SOURCE}
          geoJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: Object.values(this.props.drawnParcels)
            }
          }}
        />
        <Layer
          id={MapStyleProperties.LayerId.DrawnParcels}
          type="fill"
          sourceId={DRAWN_PARCELS_SOURCE}
          paint={mapboxPresentationProperties.drawnParcelFillPaint}
        />
        <Layer
          id={MapStyleProperties.LayerId.DrawnParcelsBorder}
          type="line"
          sourceId={DRAWN_PARCELS_SOURCE}
          paint={mapboxPresentationProperties.drawnParcelLinePaint}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    drawnParcels: newDevelopmentSelectors.getDrawnParcels(state),
    parcelTool: newDevelopmentSelectors.getParcelTool(state),
  }
}

export default connect(mapStateToProps)(DrawnFeatureLayer);
