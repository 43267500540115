import { BrowserSupportRequirement } from "types/BrowserSupportRequirement";
import browser from "utils/browser";
import companyInfo from "utils/companyInfo";

const RedirectUnsupportedBrowsers = ({ requiredSupportLevel, children }) => {
  if (requiredSupportLevel === undefined) return children;

  const isBrowserSupported = () => {
    switch (requiredSupportLevel) {
      case BrowserSupportRequirement.Full:
        return browser.isFullySupported;
      case BrowserSupportRequirement.Partial:
        return browser.isPartiallySupported;
      default:
        return false;
    }
  };

  const browserIsSupported = isBrowserSupported();

  // Check if there is no support for the browser
  // and redirect to the unsupported browser page
  if (!browserIsSupported) {
    window.location.href = companyInfo.UNSUPPORTED_BROWSER_URL;
  }

  return browserIsSupported && children;
};

export default RedirectUnsupportedBrowsers;
