import React from "react";
import { Path } from "../../../types/Path";
import analytics from "../../../utils/analytics";
import GeocoderSearch from "../../sharedComponents/GeocoderSearch";
import LogInButton from "../../sharedComponents/Buttons/LogInButton";
import SignUpButton from "../../sharedComponents/Buttons/SignUpButton";
import companyInfo from "../../../utils/companyInfo";
import ZoningReportButtonLink from "views/sharedComponents/ZoningReportButtonLink/ZoningReportButtonLink";

class HomePage extends React.PureComponent<{}, {}> {
  constructor(props) {
    super(props);

    analytics.trackPageview(Path.Home);
  }

  render() {
    return (
      <div className="component--home-page">
        <div className="buttons-container">
          <div className="zoning-report-box">
            <ZoningReportButtonLink />
          </div>
          <SignUpButton />
          <LogInButton />
        </div>

        <GeocoderSearch />

        <p className="disclaimer">
          By entering the demo, you are agreeing to the{" "}
          <a href={companyInfo.TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">
            Terms of Use.
          </a>
        </p>
      </div>
    );
  }
}

export default HomePage;
