import developmentAccessors from "../developmentAccessors";
import floor from "../buildingModel/floor";
import { Values } from  "../../../../types/Development/Values";
import { Development } from  "../../../../types/Development/Development";

/**
 * Modify the development object by updating the measurements of the
 * constructed building for the given development.
 */
const update = (development: Development) => {
  updateNumberOfFloors(development.values, development);
  updateBuildingHeight(development.values, development);
  updateFloorAreaRatio(development.values);
  updateUnitDensityRatio(development.values);
  updateSiteCoveragePercentage(development.values, development);
};

/**
 * Update the number of floors.
 */
const updateNumberOfFloors = (values: Values, development: Development) => {
  values.projectNumberOfFloors =
    development.buildingModel.floors.length;
};

/**
 * Update the building height.
 */
const updateBuildingHeight = (values: Values, development: Development) => {
  let floors = developmentAccessors.getFloors(development);
  let topFloor = floors[floors.length - 1];

  if (topFloor !== undefined) {
    values.buildingHeight =
        topFloor.baseElevation
      + topFloor.height;
  } else {
    values.buildingHeight = 0;
  }
};

/**
 * Update the Floor Area Ratio.
 */
const updateFloorAreaRatio = (values: Values) => {
  values.floorAreaRatio =
    values.parcelArea === 0
      ? 0 // Guard against divide-by-zero errors
      : (
          values.projectGrossBuildableArea
          /
          values.parcelArea
        );

  const parkingOffset =
    values.parcelArea === 0
      ? 0 // Guard against divide-by-zero errors
      : (
          values.parkingGrossBuildableArea
          /
          values.parcelArea
        );

  values.floorAreaRatioWithoutParking = values.floorAreaRatio - parkingOffset;
};

/**
 * Update the unit density per acre.
 */
const updateUnitDensityRatio = (values: Values) => {
  values.projectTotalUnitQuantity =
      values.condoTotalUnitQuantity
    + values.multifamilyTotalUnitQuantity
    + values.hotelRoomQuantity;

  values.unitsPerParcelArea =
    values.parcelArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.projectTotalUnitQuantity
          /
          values.parcelArea
        );
};

/**
 * Update the site coverage percentage.
 */
const updateSiteCoveragePercentage = (values: Values, development: Development) => {
  let groundFloor = development.buildingModel.floors[0];

  let groundFloorArea =
    groundFloor === undefined
      ? 0
      : floor.buildableArea(groundFloor);

  values.siteCoveragePercentage =
    values.parcelArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (groundFloorArea / values.parcelArea);

  values.siteCoverageArea = groundFloorArea;
  values.openSpaceArea = (values.parcelArea - groundFloorArea) | 0;
};

export default {
  update
}
