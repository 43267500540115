import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { newDevelopmentActions, newDevelopmentSelectors } from "state/newDevelopment";
import { TooltipType } from "../../../../../../sharedComponents/Tooltip/config";
import { subscriptionSelectors } from "../../../../../../../state/subscription";
import { Tier } from "../../../../../../../types/Tier";

const STYLE = {
  chips: {
    background: "#3A4276",
    borderRadius: "0",
    textTransform: "uppercase",
  },
  searchBox: {
    border: "none",
  },
  multiselectContainer: {
    color: "FFFFFF",
  },
  optionContainer: {
    background: "#3A4276",
    color: "FFFFFF",
  },
  option: {
    color: "white",
    font: "Bold",
    textTransform: "uppercase",
  },
};

const OwnerNameFilters = () => {
  const dispatch = useDispatch()
  const enabledOwnerNameFilter = useSelector(newDevelopmentSelectors.getEnabledOwnerNameFilter);
  const disabledOwnerNameFilter = useSelector(newDevelopmentSelectors.getDisabledOwnerNameFilter);
  const tier = useSelector(subscriptionSelectors.getTier);
  const isDemoUser = tier === Tier.None;
  const dataTip = isDemoUser ? TooltipType.SmartSearch : null;

  return (
    <div className="component--owner-name-filters">
      <div className="count-wrapper section-divisor">
        <label>Owner Name</label>
      </div>

      <div className="owner-name-dropdown-box" data-for="click-right" data-tip={dataTip}>
        <Multiselect
          options={disabledOwnerNameFilter}
          selectedValues={enabledOwnerNameFilter}
          onSelect={(selectedList, selectedOwnerName) => dispatch(newDevelopmentActions.updateOwnerNamesFilterValues({ [selectedOwnerName]: true }))}
          onRemove={(selectedList, selectedOwnerName) => dispatch(newDevelopmentActions.updateOwnerNamesFilterValues({ [selectedOwnerName]: false }))}
          isObject={false}
          placeholder="Click here to search for owners."
          style={STYLE}
          avoidHighlightFirstOption={true}
          closeIcon="cancel"
          disable={isDemoUser}
        />
      </div>
    </div>
  );
}

export default OwnerNameFilters;
