import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { connect } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";

const mapStateToProps = (state) => {
  return ({
    smartSearchResult: newDevelopmentSelectors.getSmartSearchResult(state),
  });
}

type MapStateProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateProps;

class SmartSearchLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Layer
          id={MapStyleProperties.LayerId.SmartSearchResultsFill}
          type="fill"
          paint={mapboxPresentationProperties.searchResultsFillPaint}
          before={MapStyleProperties.LayerId.UsaParcelsBorder}
        >
          {this.props.smartSearchResult.map((resultFeature) =>
            <Feature coordinates={resultFeature.geometry.coordinates} key={resultFeature.properties.id} />
          )}
        </Layer>
      </>
    )
  }
}

export default connect(mapStateToProps)(SmartSearchLayer);
