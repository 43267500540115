import actionTypes from "./actionTypes"

/**
 * Toggle copy project popup.
 */
const toggleCopyProjectPopup = () => {
  return {
    type: actionTypes.TOGGLE_COPY_PROJECT_POPUP,
  }
}

/**
 * Set assumptionPanelsIsOpen flag.
 */
const setAssumptionPanelIsOpen = (value: boolean) => {
  return {
    type: actionTypes.SET_ASSUMPTIONS_PANEL_IS_OPEN,
    payload: { value },
  }
}

/**
 * Set defineBuildingPanelIsOpen flag.
 */
const setDefineBuildingPanelIsOpen = (value: boolean) => {
  return {
    type: actionTypes.SET_DEFINE_BUILDING_PANEL_IS_OPEN,
    payload: { value },
  }
}

/**
 * Set returnAnalysisPanelIsOpen flag.
 */
const setReturnAnalysisPanelIsOpen = (value: boolean) => {
  return {
    type: actionTypes.SET_RETURN_ANALYSIS_PANEL_IS_OPEN,
    payload: { value },
  }
}

/**
 * Set propertyInfoPanelIsOpen flag.
 */
const setPropertyInfoPanelIsOpen = (value: boolean) => {
  return {
    type: actionTypes.SET_PROPERTY_INFO_PANEL_IS_OPEN,
    payload: { value },
  }
}

export default {
  toggleCopyProjectPopup,
  setAssumptionPanelIsOpen,
  setDefineBuildingPanelIsOpen,
  setReturnAnalysisPanelIsOpen,
  setPropertyInfoPanelIsOpen,
}
