import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../types/Path";

interface OwnProps {
  path: Path | string;
  state?: object;
  children({ redirect }): React.ReactElement;
  shouldContinue?(): boolean;
  onClick?(): void;
}

type Props = OwnProps;

const RedirectComponent: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const { shouldContinue, onClick, path, state } = props;
  const redirectToPath = useCallback(async () => {
    if (onClick) await onClick();
    if (shouldContinue && !shouldContinue()) return;
    navigate(path, { state: state });
  }, [shouldContinue, onClick, navigate, path, state]);

  return (
    <>
      {props.children({
        redirect: redirectToPath,
      })}
    </>
  );
};

export default RedirectComponent;
