export enum VariableId {
  BuildingHeight = "buildingHeight",
  ExistingBuildingHeight = "existingBuildingHeight",
  CondoAmenitiesArea = "condoAmenitiesArea",
  CondoAmenitiesAreaToggleable = "condoAmenitiesAreaToggleable",
  CondoAverageAreaPerUnit = "condoAverageAreaPerUnit",
  CondoAverageGrossSalesPerSellableArea = "condoAverageGrossSalesPerSellableArea",
  CondoAverageGrossSalesPerUnit = "condoAverageGrossSalesPerUnit",
  CondoConstructionCostNotIncludingParking = "condoConstructionCostNotIncludingParking",
  CondoContingencyCost = "condoContingencyCost",
  CondoDevelopmentCostPerAreaIncludingParking = "condoDevelopmentCostPerAreaIncludingParking",
  CondoFractionOfNonParkingGrossBuildableArea = "condoFractionOfNonParkingGrossBuildableArea",
  CondoGrossBuildableArea = "condoGrossBuildableArea",
  CondoGrossBuildableAreaIncludingParking = "condoGrossBuildableAreaIncludingParking",
  CondoGrossSales = "condoGrossSales",
  CondoHardCost = "condoHardCost",
  CondoHardCostPerArea = "condoHardCostPerArea",
  CondoLossFactor = "condoLossFactor",
  CondoMicrounitArea = "condoMicrounitArea",
  CondoMicrounitQuantity = "condoMicrounitQuantity",
  CondoMicrounitQuantityToggleable = "condoMicrounitQuantityToggleable",
  CondoNetProceeds = "condoNetProceeds",
  CondoNetSellableArea = "condoNetSellableArea",
  CondoNonMicrounitQuantity = "condoNonMicrounitQuantity",
  CondoOneBedArea = "condoOneBedArea",
  CondoOneBedQuantity = "condoOneBedQuantity",
  CondoOneBedQuantityToggleable = "condoOneBedQuantityToggleable",
  CondoProfit = "condoProfit",
  CondoSalePricePerMicrounit = "condoSalePricePerMicrounit",
  CondoSalePricePerOneBed = "condoSalePricePerOneBed",
  CondoSalePricePerStudio = "condoSalePricePerStudio",
  CondoSalePricePerThreeBed = "condoSalePricePerThreeBed",
  CondoSalePricePerTwoBed = "condoSalePricePerTwoBed",
  CondoSoftCost = "condoSoftCost",
  CondoStudioArea = "condoStudioArea",
  CondoStudioQuantity = "condoStudioQuantity",
  CondoStudioQuantityToggleable = "condoStudioQuantityToggleable",
  CondoThreeBedArea = "condoThreeBedArea",
  CondoThreeBedQuantity = "condoThreeBedQuantity",
  CondoThreeBedQuantityToggleable = "condoThreeBedQuantityToggleable",
  CondoToggle = "condoToggle",
  CondoTotalDevelopmentCostIncludingParking = "condoTotalDevelopmentCostIncludingParking",
  CondoTotalMicrounitArea = "condoTotalMicrounitArea",
  CondoTotalOneBedArea = "condoTotalOneBedArea",
  CondoTotalStudioArea = "condoTotalStudioArea",
  CondoTotalThreeBedArea = "condoTotalThreeBedArea",
  CondoTotalTwoBedArea = "condoTotalTwoBedArea",
  CondoTotalUnitQuantity = "condoTotalUnitQuantity",
  CondoTwoBedArea = "condoTwoBedArea",
  CondoTwoBedQuantity = "condoTwoBedQuantity",
  CondoTwoBedQuantityToggleable = "condoTwoBedQuantityToggleable",
  ContingencyCostPercentage = "contingencyCostPercentage",
  ExistingStructureArea = "existingStructureArea",
  ExistingStructureAreaOpenSource = "existingStructureAreaOpenSource",
  ExistingStructureDemolitionCost = "existingStructureDemolitionCost",
  ExistingStructureDemolitionCostAttributedToCondo = "existingStructureDemolitionCostAttributedToCondo",
  ExistingStructureDemolitionCostAttributedToHotel = "existingStructureDemolitionCostAttributedToHotel",
  ExistingStructureDemolitionCostAttributedToRentalUses = "existingStructureDemolitionCostAttributedToRentalUses",
  ExistingStructureDemolitionCostPerArea = "existingStructureDemolitionCostPerArea",
  FloorAreaRatio = "floorAreaRatio",
  FloorAreaRatioWithoutParking = "floorAreaRatioWithoutParking",
  HeightOfGroundFloor = "heightOfGroundFloor",
  HeightOfTypicalFloor = "heightOfTypicalFloor",
  HotelAmenitiesArea = "hotelAmenitiesArea",
  HotelAmenitiesAreaToggleable = "hotelAmenitiesAreaToggleable",
  HotelAnnualNetOperatingIncomeForBackOfEnvelope = "hotelAnnualNetOperatingIncomeForBackOfEnvelope",
  HotelAnnualOperatingExpenseForBackOfEnvelope = "hotelAnnualOperatingExpenseForBackOfEnvelope",
  HotelAnnualRoomOperatingExpenseForBackOfEnvelope = "hotelAnnualRoomOperatingExpenseForBackOfEnvelope",
  HotelAnnualOtherOperatingExpenseForBackOfEnvelope = "hotelAnnualOtherOperatingExpenseForBackOfEnvelope",
  HotelAnnualOtherRevenueForBackOfEnvelope = "hotelAnnualOtherRevenueForBackOfEnvelope",
  HotelAnnualPotentialOtherRevenueForBackOfEnvelope = "hotelAnnualPotentialOtherRevenueForBackOfEnvelope",
  HotelAnnualPotentialRevenueForBackOfEnvelope = "hotelAnnualPotentialRevenueForBackOfEnvelope",
  HotelAnnualPotentialRoomRevenueForBackOfEnvelope = "hotelAnnualPotentialRoomRevenueForBackOfEnvelope",
  HotelAnnualReturnOnCostForBackOfEnvelope = "hotelAnnualReturnOnCostForBackOfEnvelope",
  HotelAnnualRevenueForBackOfEnvelope = "hotelAnnualRevenueForBackOfEnvelope",
  HotelAnnualRoomRevenueForBackOfEnvelope = "hotelAnnualRoomRevenueForBackOfEnvelope",
  HotelAnnualUnrealizedRevenueForBackOfEnvelope = "hotelAnnualUnrealizedRevenueForBackOfEnvelope",
  HotelAnnualRoomUnrealizedRevenueForBackOfEnvelope = "hotelAnnualRoomUnrealizedRevenueForBackOfEnvelope",
  HotelAnnualOtherUnrealizedRevenueForBackOfEnvelope = "hotelAnnualOtherUnrealizedRevenueForBackOfEnvelope",
  HotelAverageRoomArea = "hotelAverageRoomArea",
  HotelConstructionCostNotIncludingParking = "hotelConstructionCostNotIncludingParking",
  HotelContingencyCost = "hotelContingencyCost",
  HotelDevelopmentCostPerAreaIncludingParking = "hotelDevelopmentCostPerAreaIncludingParking",
  HotelFractionOfNonParkingGrossBuildableArea = "hotelFractionOfNonParkingGrossBuildableArea",
  HotelGrossBuildableArea = "hotelGrossBuildableArea",
  HotelGrossBuildableAreaIncludingParking = "hotelGrossBuildableAreaIncludingParking",
  HotelHardCost = "hotelHardCost",
  HotelHardCostPerArea = "hotelHardCostPerArea",
  HotelLossFactor = "hotelLossFactor",
  HotelNetBookableArea = "hotelNetBookableArea",
  HotelOperatingExpensePercentage = "hotelOperatingExpensePercentage",
  HotelOtherRevenuePercentage = "hotelOtherRevenuePercentage",
  HotelRoomQuantity = "hotelRoomQuantity",
  HotelRoomQuantityToggleable = "hotelRoomQuantityToggleable",
  HotelSoftCost = "hotelSoftCost",
  HotelStabilizedAverageDailyRate = "hotelStabilizedAverageDailyRate",
  HotelStabilizedOccupancyPercentage = "hotelStabilizedOccupancyPercentage",
  HotelStabilizedRevenuePerAvailableRoom = "hotelStabilizedRevenuePerAvailableRoom",
  HotelToggle = "hotelToggle",
  HotelTotalDevelopmentCostIncludingParking = "hotelTotalDevelopmentCostIncludingParking",
  IncomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope = "incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope",
  IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope = "incomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope",
  IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope = "incomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope",
  IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope = "incomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope",
  IncomeProducingUsesTotalDevelopmentCostIncludingParking = "incomeProducingUsesTotalDevelopmentCostIncludingParking",
  IndustrialAnnualEffectiveGrossIncomeForBackOfEnvelope = "industrialAnnualEffectiveGrossIncomeForBackOfEnvelope",
  IndustrialAnnualNetOperatingIncomeForBackOfEnvelope = "industrialAnnualNetOperatingIncomeForBackOfEnvelope",
  IndustrialAnnualVacancyExpenseForBackOfEnvelope = "industrialAnnualVacancyExpenseForBackOfEnvelope",
  IndustrialConstructionCostNotIncludingParking = "industrialConstructionCostNotIncludingParking",
  IndustrialContingencyCost = "industrialContingencyCost",
  IndustrialGrossBuildableArea = "industrialGrossBuildableArea",
  IndustrialHardCost = "industrialHardCost",
  IndustrialHardCostPerArea = "industrialHardCostPerArea",
  IndustrialLossFactor = "industrialLossFactor",
  IndustrialNetLeasableArea = "industrialNetLeasableArea",
  IndustrialNetLeasableAreaToggleable = "industrialNetLeasableAreaToggleable",
  IndustrialSoftCost = "industrialSoftCost",
  IndustrialStabilizedVacancyPercentage = "industrialStabilizedVacancyPercentage",
  IndustrialToggle = "industrialToggle",
  IndustrialYearOneExpenseReimbursementFeePerArea = "industrialYearOneExpenseReimbursementFeePerArea",
  IndustrialYearOneOperatingExpense = "industrialYearOneOperatingExpense",
  IndustrialYearOneOperatingExpensePerArea = "industrialYearOneOperatingExpensePerArea",
  IndustrialYearOnePotentialGrossExpenseReimbursementIncome = "industrialYearOnePotentialGrossExpenseReimbursementIncome",
  IndustrialYearOnePotentialGrossIncome = "industrialYearOnePotentialGrossIncome",
  IndustrialYearOnePotentialGrossRentalIncome = "industrialYearOnePotentialGrossRentalIncome",
  IndustrialYearOneRentPerArea = "industrialYearOneRentPerArea",
  MultifamilyAmenitiesArea = "multifamilyAmenitiesArea",
  MultifamilyAmenitiesAreaToggleable = "multifamilyAmenitiesAreaToggleable",
  MultifamilyAnnualEffectiveGrossIncomeForBackOfEnvelope = "multifamilyAnnualEffectiveGrossIncomeForBackOfEnvelope",
  MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope = "multifamilyAnnualNetOperatingIncomeForBackOfEnvelope",
  MultifamilyAnnualVacancyExpenseForBackOfEnvelope = "multifamilyAnnualVacancyExpenseForBackOfEnvelope",
  MultifamilyAverageAreaPerUnit = "multifamilyAverageAreaPerUnit",
  MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea = "multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea",
  MultifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea = "multifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea",
  MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit = "multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit",
  MultifamilyConstructionCostNotIncludingParking = "multifamilyConstructionCostNotIncludingParking",
  MultifamilyContingencyCost = "multifamilyContingencyCost",
  MultifamilyGrossBuildableArea = "multifamilyGrossBuildableArea",
  MultifamilyHardCost = "multifamilyHardCost",
  MultifamilyHardCostPerArea = "multifamilyHardCostPerArea",
  MultifamilyLossFactor = "multifamilyLossFactor",
  MultifamilyMicrounitArea = "multifamilyMicrounitArea",
  MultifamilyMicrounitQuantity = "multifamilyMicrounitQuantity",
  MultifamilyMicrounitQuantityToggleable = "multifamilyMicrounitQuantityToggleable",
  MultifamilyNetLeasableArea = "multifamilyNetLeasableArea",
  MultifamilyNonMicrounitQuantity = "multifamilyNonMicrounitQuantity",
  MultifamilyOneBedArea = "multifamilyOneBedArea",
  MultifamilyOneBedQuantity = "multifamilyOneBedQuantity",
  MultifamilyOneBedQuantityToggleable = "multifamilyOneBedQuantityToggleable",
  MultifamilyOperatingExpensePercentage = "multifamilyOperatingExpensePercentage",
  MultifamilySoftCost = "multifamilySoftCost",
  MultifamilyStabilizedVacancyPercentage = "multifamilyStabilizedVacancyPercentage",
  MultifamilyStudioArea = "multifamilyStudioArea",
  MultifamilyStudioQuantity = "multifamilyStudioQuantity",
  MultifamilyStudioQuantityToggleable = "multifamilyStudioQuantityToggleable",
  MultifamilyThreeBedArea = "multifamilyThreeBedArea",
  MultifamilyThreeBedQuantity = "multifamilyThreeBedQuantity",
  MultifamilyThreeBedQuantityToggleable = "multifamilyThreeBedQuantityToggleable",
  MultifamilyToggle = "multifamilyToggle",
  MultifamilyTotalMicrounitArea = "multifamilyTotalMicrounitArea",
  MultifamilyTotalOneBedArea = "multifamilyTotalOneBedArea",
  MultifamilyTotalStudioArea = "multifamilyTotalStudioArea",
  MultifamilyTotalThreeBedArea = "multifamilyTotalThreeBedArea",
  MultifamilyTotalTwoBedArea = "multifamilyTotalTwoBedArea",
  MultifamilyTotalUnitQuantity = "multifamilyTotalUnitQuantity",
  MultifamilyTwoBedArea = "multifamilyTwoBedArea",
  MultifamilyTwoBedQuantity = "multifamilyTwoBedQuantity",
  MultifamilyTwoBedQuantityToggleable = "multifamilyTwoBedQuantityToggleable",
  MultifamilyYearOneMonthlyRentPerMicrounit = "multifamilyYearOneMonthlyRentPerMicrounit",
  MultifamilyYearOneMonthlyRentPerOneBed = "multifamilyYearOneMonthlyRentPerOneBed",
  MultifamilyYearOneMonthlyRentPerStudio = "multifamilyYearOneMonthlyRentPerStudio",
  MultifamilyYearOneMonthlyRentPerThreeBed = "multifamilyYearOneMonthlyRentPerThreeBed",
  MultifamilyYearOneMonthlyRentPerTwoBed = "multifamilyYearOneMonthlyRentPerTwoBed",
  MultifamilyYearOneOperatingExpense = "multifamilyYearOneOperatingExpense",
  MultifamilyYearOnePotentialGrossIncome = "multifamilyYearOnePotentialGrossIncome",
  MultifamilyYearOnePotentialGrossMonthlyRentalIncome = "multifamilyYearOnePotentialGrossMonthlyRentalIncome",
  NonParkingGrossBuildableArea = "nonParkingGrossBuildableArea",
  OfficeAnnualEffectiveGrossIncomeForBackOfEnvelope = "officeAnnualEffectiveGrossIncomeForBackOfEnvelope",
  OfficeAnnualNetOperatingIncomeForBackOfEnvelope = "officeAnnualNetOperatingIncomeForBackOfEnvelope",
  OfficeAnnualVacancyExpenseForBackOfEnvelope = "officeAnnualVacancyExpenseForBackOfEnvelope",
  OfficeCommonAreaFactor = "officeCommonAreaFactor",
  OfficeConstructionCostNotIncludingParking = "officeConstructionCostNotIncludingParking",
  OfficeContingencyCost = "officeContingencyCost",
  OfficeGrossBuildableArea = "officeGrossBuildableArea",
  OfficeGrossLeasableArea = "officeGrossLeasableArea",
  OfficeHardCost = "officeHardCost",
  OfficeHardCostPerArea = "officeHardCostPerArea",
  OfficeNetUsableArea = "officeNetUsableArea",
  OfficeNetUsableAreaToggleable = "officeNetUsableAreaToggleable",
  OfficeSoftCost = "officeSoftCost",
  OfficeStabilizedVacancyPercentage = "officeStabilizedVacancyPercentage",
  OfficeToggle = "officeToggle",
  OfficeYearOneExpenseReimbursementFeePerArea = "officeYearOneExpenseReimbursementFeePerArea",
  OfficeYearOneOperatingExpense = "officeYearOneOperatingExpense",
  OfficeYearOneOperatingExpensePerArea = "officeYearOneOperatingExpensePerArea",
  OfficeYearOnePotentialGrossExpenseReimbursementIncome = "officeYearOnePotentialGrossExpenseReimbursementIncome",
  OfficeYearOnePotentialGrossIncome = "officeYearOnePotentialGrossIncome",
  OfficeYearOnePotentialGrossRentalIncome = "officeYearOnePotentialGrossRentalIncome",
  OfficeYearOneRentPerArea = "officeYearOneRentPerArea",
  ParcelArea = "parcelArea",
  ParcelPurchasePriceArea = "parcelPurchasePriceArea",
  ParcelPurchasePrice = "parcelPurchasePrice",
  ParcelPurchasePriceAttributedToCondo = "parcelPurchasePriceAttributedToCondo",
  ParcelPurchasePriceAttributedToHotel = "parcelPurchasePriceAttributedToHotel",
  ParcelPurchasePriceAttributedToRentalUses = "parcelPurchasePriceAttributedToRentalUses",
  ParcelPurchasePricePerArea = "parcelPurchasePricePerArea",
  ParkingConstructionCost = "parkingConstructionCost",
  ParkingConstructionCostAttributedToCondo = "parkingConstructionCostAttributedToCondo",
  ParkingConstructionCostAttributedToHotel = "parkingConstructionCostAttributedToHotel",
  ParkingConstructionCostAttributedToRentalUses = "parkingConstructionCostAttributedToRentalUses",
  ParkingContingencyCost = "parkingContingencyCost",
  ParkingContingencyCostAttributedToCondo = "parkingContingencyCostAttributedToCondo",
  ParkingContingencyCostAttributedToHotel = "parkingContingencyCostAttributedToHotel",
  ParkingContingencyCostAttributedToRentalUses = "parkingContingencyCostAttributedToRentalUses",
  ParkingGrossBuildableArea = "parkingGrossBuildableArea",
  ParkingGrossBuildableAreaForCondo = "parkingGrossBuildableAreaForCondo",
  ParkingGrossBuildableAreaForHotel = "parkingGrossBuildableAreaForHotel",
  ParkingGrossBuildableAreaForRentalUses = "parkingGrossBuildableAreaForRentalUses",
  ParkingHardCost = "parkingHardCost",
  ParkingHardCostAttributedToCondo = "parkingHardCostAttributedToCondo",
  ParkingHardCostAttributedToHotel = "parkingHardCostAttributedToHotel",
  ParkingHardCostAttributedToRentalUses = "parkingHardCostAttributedToRentalUses",
  ParkingHardCostPerSpace = "parkingHardCostPerSpace",
  ParkingHardCostPerArea = "parkingHardCostPerArea",
  ParkingRatioCondo = "parkingRatioCondo",
  ParkingRatioHotel = "parkingRatioHotel",
  ParkingRatioIndustrial = "parkingRatioIndustrial",
  ParkingRatioMultifamily = "parkingRatioMultifamily",
  ParkingRatioOffice = "parkingRatioOffice",
  ParkingRatioRetail = "parkingRatioRetail",
  ParkingReductionFactor = "parkingReductionFactor",
  ParkingReductionFee = "parkingReductionFee",
  ParkingReductionFeeAttributedToCondo = "parkingReductionFeeAttributedToCondo",
  ParkingReductionFeeAttributedToHotel = "parkingReductionFeeAttributedToHotel",
  ParkingReductionFeeAttributedToRentalUses = "parkingReductionFeeAttributedToRentalUses",
  ParkingReductionFeePerSpace = "parkingReductionFeePerSpace",
  ParkingRequiredSpacesAfterReduction = "parkingRequiredSpacesAfterReduction",
  ParkingRequiredSpacesAfterReductionCondo = "parkingRequiredSpacesAfterReductionCondo",
  ParkingRequiredSpacesAfterReductionHotel = "parkingRequiredSpacesAfterReductionHotel",
  ParkingRequiredSpacesAfterReductionIndustrial = "parkingRequiredSpacesAfterReductionIndustrial",
  ParkingRequiredSpacesAfterReductionMultifamily = "parkingRequiredSpacesAfterReductionMultifamily",
  ParkingRequiredSpacesAfterReductionOffice = "parkingRequiredSpacesAfterReductionOffice",
  ParkingRequiredSpacesAfterReductionRentalUses = "parkingRequiredSpacesAfterReductionRentalUses",
  ParkingRequiredSpacesAfterReductionRetail = "parkingRequiredSpacesAfterReductionRetail",
  ParkingRequiredSpacesBeforeReduction = "parkingRequiredSpacesBeforeReduction",
  ParkingRequiredSpacesBeforeReductionCondo = "parkingRequiredSpacesBeforeReductionCondo",
  ParkingRequiredSpacesBeforeReductionHotel = "parkingRequiredSpacesBeforeReductionHotel",
  ParkingRequiredSpacesBeforeReductionIndustrial = "parkingRequiredSpacesBeforeReductionIndustrial",
  ParkingRequiredSpacesBeforeReductionMultifamily = "parkingRequiredSpacesBeforeReductionMultifamily",
  ParkingRequiredSpacesBeforeReductionOffice = "parkingRequiredSpacesBeforeReductionOffice",
  ParkingRequiredSpacesBeforeReductionRentalUses = "parkingRequiredSpacesBeforeReductionRentalUses",
  ParkingRequiredSpacesBeforeReductionRetail = "parkingRequiredSpacesBeforeReductionRetail",
  ParkingSoftCost = "parkingSoftCost",
  ParkingSoftCostAttributedToCondo = "parkingSoftCostAttributedToCondo",
  ParkingSoftCostAttributedToHotel = "parkingSoftCostAttributedToHotel",
  ParkingSoftCostAttributedToRentalUses = "parkingSoftCostAttributedToRentalUses",
  ParkingSpaceArea = "parkingSpaceArea",
  ParkingSpacesEliminatedByReduction = "parkingSpacesEliminatedByReduction",
  ParkingSpacesEliminatedByReductionCondo = "parkingSpacesEliminatedByReductionCondo",
  ParkingSpacesEliminatedByReductionHotel = "parkingSpacesEliminatedByReductionHotel",
  ParkingSpacesEliminatedByReductionIndustrial = "parkingSpacesEliminatedByReductionIndustrial",
  ParkingSpacesEliminatedByReductionMultifamily = "parkingSpacesEliminatedByReductionMultifamily",
  ParkingSpacesEliminatedByReductionOffice = "parkingSpacesEliminatedByReductionOffice",
  ParkingSpacesEliminatedByReductionRentalUses = "parkingSpacesEliminatedByReductionRentalUses",
  ParkingSpacesEliminatedByReductionRetail = "parkingSpacesEliminatedByReductionRetail",
  ProjectAnnualNetOperatingIncomeForBackOfEnvelope = "projectAnnualNetOperatingIncomeForBackOfEnvelope",
  ProjectConstructionCost = "projectConstructionCost",
  ProjectConstructionCostIncludingDemolition = "projectConstructionCostIncludingDemolition",
  ProjectConstructionCostIncludingDemolitionPerBuildableArea = "projectConstructionCostIncludingDemolitionPerBuildableArea",
  ProjectContingencyCost = "projectContingencyCost",
  ProjectGrossBuildableArea = "projectGrossBuildableArea",
  ProjectHardCost = "projectHardCost",
  ProjectHardCostPerGrossBuildableArea = "projectHardCostPerGrossBuildableArea",
  ProjectNetLeasableOrSellableArea = "projectNetLeasableOrSellableArea",
  ProjectSaleCommissionPercentage = "projectSaleCommissionPercentage",
  ProjectSoftCost = "projectSoftCost",
  ProjectTotalDevelopmentCost = "projectTotalDevelopmentCost",
  ProjectTotalUnitQuantity = "projectTotalUnitQuantity",
  PublishedDataAreaUnitsPerGeometricAreaUnit = "publishedDataAreaUnitsPerGeometricAreaUnit",
  PublishedDataDistanceUnitsPerGeometricDistanceUnit = "publishedDataDistanceUnitsPerGeometricDistanceUnit",
  RentalUsesAnnualNetOperatingIncomeForBackOfEnvelope = "rentalUsesAnnualNetOperatingIncomeForBackOfEnvelope",
  RentalUsesAnnualReturnOnCostForBackOfEnvelope = "rentalUsesAnnualReturnOnCostForBackOfEnvelope",
  RentalUsesAnnualVacancyExpenseForBackOfEnvelope = "rentalUsesAnnualVacancyExpenseForBackOfEnvelope",
  RentalUsesConstructionCostNotIncludingParking = "rentalUsesConstructionCostNotIncludingParking",
  RentalUsesDevelopmentCostPerAreaIncludingParking = "rentalUsesDevelopmentCostPerAreaIncludingParking",
  RentalUsesFractionOfNonParkingGrossBuildableArea = "rentalUsesFractionOfNonParkingGrossBuildableArea",
  RentalUsesGrossBuildableArea = "rentalUsesGrossBuildableArea",
  RentalUsesGrossBuildableAreaIncludingParking = "rentalUsesGrossBuildableAreaIncludingParking",
  RentalUsesTotalDevelopmentCostIncludingParking = "rentalUsesTotalDevelopmentCostIncludingParking",
  RentalUsesYearOneOperatingExpense = "rentalUsesYearOneOperatingExpense",
  RentalUsesYearOnePotentialGrossIncome = "rentalUsesYearOnePotentialGrossIncome",
  RetailAnnualEffectiveGrossIncomeForBackOfEnvelope = "retailAnnualEffectiveGrossIncomeForBackOfEnvelope",
  RetailAnnualNetOperatingIncomeForBackOfEnvelope = "retailAnnualNetOperatingIncomeForBackOfEnvelope",
  RetailAnnualVacancyExpenseForBackOfEnvelope = "retailAnnualVacancyExpenseForBackOfEnvelope",
  RetailConstructionCostNotIncludingParking = "retailConstructionCostNotIncludingParking",
  RetailContingencyCost = "retailContingencyCost",
  RetailGrossBuildableArea = "retailGrossBuildableArea",
  RetailHardCost = "retailHardCost",
  RetailHardCostPerArea = "retailHardCostPerArea",
  RetailLossFactor = "retailLossFactor",
  RetailNetLeasableArea = "retailNetLeasableArea",
  RetailNetLeasableAreaToggleable = "retailNetLeasableAreaToggleable",
  RetailSoftCost = "retailSoftCost",
  RetailStabilizedVacancyPercentage = "retailStabilizedVacancyPercentage",
  RetailToggle = "retailToggle",
  RetailYearOneExpenseReimbursementFeePerArea = "retailYearOneExpenseReimbursementFeePerArea",
  RetailYearOneOperatingExpense = "retailYearOneOperatingExpense",
  RetailYearOneOperatingExpensePerArea = "retailYearOneOperatingExpensePerArea",
  RetailYearOnePotentialGrossExpenseReimbursementIncome = "retailYearOnePotentialGrossExpenseReimbursementIncome",
  RetailYearOnePotentialGrossIncome = "retailYearOnePotentialGrossIncome",
  RetailYearOnePotentialGrossRentalIncome = "retailYearOnePotentialGrossRentalIncome",
  RetailYearOneRentPerArea = "retailYearOneRentPerArea",
  SetbackA = "setbackA",
  SetbackB = "setbackB",
  SetbackC = "setbackC",
  SetbackD = "setbackD",
  SiteCoveragePercentage = "siteCoveragePercentage",
  SoftCostPercentage = "softCostPercentage",
  UnitsPerParcelArea = "unitsPerParcelArea",
  ProjectNumberOfFloors = "projectNumberOfFloors",
  SiteCoverageArea = "siteCoverageArea",
  OpenSpaceArea = "openSpaceArea",
  IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope = "incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope",
  ExitCapRate = "exitCapRate",
  IncomeProducingUsesSaleValueForBackOfEnvelope = "incomeProducingUsesSaleValueForBackOfEnvelope",
  TotalProjectSaleForBackOfEnvelope = "totalProjectSaleForBackOfEnvelope",
  TotalProjectSalesCommissions = "totalProjectSalesCommissions",
  TotalProjectSaleProfitForBackOfEnvelope = "totalProjectSaleProfitForBackOfEnvelope",
  SaleReturnOnInvestmentForBackOfEnvelope = "saleReturnOnInvestmentForBackOfEnvelope",
}
