import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardContent from "./DashboardContent";
import DashboardSidebar from "./DashboardSidebar";
import DashboardToolbar from "./DashboardToolbar";
import { userActions } from "../../../state/user";
import analytics from "../../../utils/analytics";
import { Path } from "../../../types/Path";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  getUserLocation: userActions.getLocationStart,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & StateProps;

const DashboardPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.getUserLocation();
    analytics.trackPageview(Path.Dashboard);
  }, []);

  useEffect(() => {
    if (props.tier === Tier.Free) {
      navigate(Path.SubscriptionManagement);
    }
  }, [props.tier]);

  return (
    <div className="component--dashboard-page">
      <DashboardToolbar />
      <DashboardSidebar />
      <DashboardContent />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
