import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class TransitOrientedCommunityLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.TransitOrientedCommunity}
        classes="toc"
        legendRows={[
          <LegendRow
            color="#bdc9e1"
            text="Tier 1"
          />,
          <LegendRow
            color="#67a9cf"
            text="Tier 2"
          />,
          <LegendRow
            color="#1c9099"
            text="Tier 3"
          />,
          <LegendRow
            color="#016c59"
            text="Tier 4"
          />,
        ]}
      />
    );
  }
}

export default TransitOrientedCommunityLegend;