import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../state/newDevelopment";
import { developmentSelectors, developmentActions } from "../../../../state/development";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Path } from "../../../../types/Path";
import { VariableId } from "../../../../types/VariableId";
import { usageColor } from "../../../utils/buildingUsageProperties";
import { initializeNewDevelopment } from "../utils/initializeNewDevelopment";
import wrapComponentWithPopup from "../../../sharedComponents/wrapComponentWithPopup";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import { PopupProps } from "../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import { userSelectors } from "../../../../state/user";
import analytics from "../../../../utils/analytics";
import RedirectComponent from "../../../sharedComponents/RedirectComponent";
import authentication from "../../../../utils/authentication";
import { ToggleButton } from "../../../utils/forms/fieldRenderers";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import { newDevelopmentUiSelectors } from "../../../../state/ui/newDevelopment";
import BusySpinner from "../../../sharedComponents/BusySpinner";

const mapStateToProps = (state) => {
  return {
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    newDevelopmentUnitSystem: newDevelopmentSelectors.getUnitSystem(state),
    initialValues: newDevelopmentSelectors.getInitialValues(state),
    projectId: developmentSelectors.getProjectId(state),
    userDocument: userSelectors.getUserDocument(state),
    developmentIsBeingCreated: newDevelopmentUiSelectors.getDevelopmentIsBeingCreated(state),
  };
};

const mapDispatchToProps = {
  updateInitialValues: newDevelopmentActions.updateInitialValues,
  createProject: developmentActions.createStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & PopupProps;

interface State {
  floorAreaRatioValue: any;
}

class InitialValuesPanel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      floorAreaRatioValue: props.initialValues.floorAreaRatio,
    };
  }

  /**
   * Update the floorAreaRatioValue state.
   */
  componentDidUpdate(previousProps: Props) {
    if (this.props.initialValues.floorAreaRatio !== previousProps.initialValues.floorAreaRatio) {
      this.setState({ floorAreaRatioValue: this.props.initialValues.floorAreaRatio });
    }
  }

  /**
   * Create a new project.
   */
  initializeProject = async () => {
    const { selectedFeature, newDevelopmentUnitSystem, initialValues, developmentIsBeingCreated } = this.props;
    if (!selectedFeature || developmentIsBeingCreated) return;

    const newDevelopmentData = await initializeNewDevelopment(selectedFeature, newDevelopmentUnitSystem, initialValues);
    const user = authentication.getCurrentUser();
    this.props.createProject(user, newDevelopmentData, this.props.userDocument);
  };

  /**
   * Handle toggle change
   */
  handleToggleChange = (toggle: VariableId) => {
    this.props.updateInitialValues({
      [toggle]: !this.props.initialValues[toggle],
    });
  };

  /**
   * Change local floor area ratio value.
   */
  handleInputChange = (event) => {
    if (!isNaN(event.target.value) || event.target.value === ".")
      this.setState({ floorAreaRatioValue: event.target.value });
  };

  /**
   * Select input value when clicking on it.
   */
  handleInputFocus = (event) => {
    event.target.select();
  };

  /**
   * Change floor area ratio value on the store.
   */
  handleInputBlur = (event) => {
    this.props.updateInitialValues({ floorAreaRatio: Number(this.state.floorAreaRatioValue) });
    if (this.state.floorAreaRatioValue.length === 0) this.setState({ floorAreaRatioValue: 0 });
  };

  /**
   * Render floor area ratio section.
   */
  renderFloorAreaRatioContainer = () => {
    let farIsDefault = !Boolean(parcelAccessors.getMinimumFloorAreaRatio(this.props.selectedFeature));

    return (
      <div className="far-container">
        <p className="section-header">Number of floors (Property FAR)</p>
        <div className="input-container">
          <input
            name="far"
            value={this.state.floorAreaRatioValue}
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
          />
          <div className="note-container">
            <p className="note">Projects start with 0 setbacks, therefore FAR and number of floors are equal.</p>
            {farIsDefault && <p className="default-far-note">This value does not come from the zoning code.</p>}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Render architect firm and link when available.
   */
  renderUsesContainer = () => {
    const { initialValues } = this.props;

    return (
      <>
        <p className="section-header">Select one or more property uses</p>
        <div className="uses-container">
          <ToggleButton
            label="Condo"
            color={usageColor(BuildingUse.Condo)}
            isActive={initialValues[VariableId.CondoToggle]}
            onClick={() => this.handleToggleChange(VariableId.CondoToggle)}
            classes="condo"
          />
          <ToggleButton
            label="Multifamily"
            color={usageColor(BuildingUse.Multifamily)}
            isActive={initialValues[VariableId.MultifamilyToggle]}
            onClick={() => this.handleToggleChange(VariableId.MultifamilyToggle)}
            classes="multifamily"
          />
          <ToggleButton
            label="Hotel"
            color={usageColor(BuildingUse.Hotel)}
            isActive={initialValues[VariableId.HotelToggle]}
            onClick={() => this.handleToggleChange(VariableId.HotelToggle)}
            classes="hotel"
          />
          <ToggleButton
            label="Office"
            color={usageColor(BuildingUse.Office)}
            isActive={initialValues[VariableId.OfficeToggle]}
            onClick={() => this.handleToggleChange(VariableId.OfficeToggle)}
            classes="office"
          />
          <ToggleButton
            label="Retail"
            color={usageColor(BuildingUse.Retail)}
            isActive={initialValues[VariableId.RetailToggle]}
            onClick={() => this.handleToggleChange(VariableId.RetailToggle)}
            classes="retail"
          />
          <ToggleButton
            label="Industrial"
            color={usageColor(BuildingUse.Industrial)}
            isActive={initialValues[VariableId.IndustrialToggle]}
            onClick={() => this.handleToggleChange(VariableId.IndustrialToggle)}
            classes="industrial"
          />
        </div>
      </>
    );
  };

  /**
   * Render buttons.
   */
  renderButtons = () => {
    const { initialValues, closePopup, developmentIsBeingCreated } = this.props;

    const allTogglesAreDisabled = !Object.values(BuildingUse).some(
      (buildingUse) => initialValues[`${buildingUse}Toggle`]
    );

    return (
      <div className="buttons-container">
        <button className="back" onClick={closePopup}>
          BACK
        </button>
        <button className={allTogglesAreDisabled ? "disabled" : "continue"} onClick={this.initializeProject}>
          {developmentIsBeingCreated ? <BusySpinner classes="white-spinner" /> : "CONTINUE"}
        </button>
      </div>
    );
  };

  render() {
    return (
      <RedirectComponent path={`${Path.Explorer}/${this.props.projectId}`}>
        {({ redirect }) => {
          if (this.props.projectId) {
            analytics.trackCreateNewProject(this.props.projectId, { unitSystem: this.props.newDevelopmentUnitSystem });
            redirect();
          }

          return (
            <WithPanelHeader title="Select Property Uses" hideControls hideBottomDivisor>
              <div className={`component--initial-values-panel`}>
                {this.renderUsesContainer()}
                {this.renderFloorAreaRatioContainer()}
                {this.renderButtons()}
              </div>
            </WithPanelHeader>
          );
        }}
      </RedirectComponent>
    );
  }
}

export default wrapComponentWithPopup(connect(mapStateToProps, mapDispatchToProps)(InitialValuesPanel));
