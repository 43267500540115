import React from "react";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerLegend from "../sharedComponents/DynamicLayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class SeaLevelRiseLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <DynamicLayerLegend
        layerGroup={DynamicLayerId.Dynamic_Layer_Sea_Level_Rise}
        classes="sea-level"
        legendRows={[
          <LegendRow
            color="#0057EB"
            text="Sea Level Rise"
          />,
        ]}
      />
    );
  }
}

export default SeaLevelRiseLegend;
