import React from "react";
import CellRow from "../../CellRow";
import { Tier } from "types/Tier";

/**
 * Render Cell row if value is not null.
 */
const ConditionalCellRow = (value, Cell1, Cell2, tier?) => {
  if (
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0)
  )
    return null;
  if (tier === Tier.None) return null;

  return (
    <CellRow>
      <Cell1.type {...Cell1.props} />
      <Cell2.type value={value} {...Cell2.props} />
    </CellRow>
  );
};

export default ConditionalCellRow;
