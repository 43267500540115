import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class LihtcExistingPropertyInventoryLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.LIHTC_ExistingPropertyInventory}
        classes="lihtc-inventory"
        legendRows={[
          <LegendRow
            color="#bdbdbd"
            text="No Data"
          />,
          <LegendRow
            color="#fee5d9"
            text="0"
          />,
          <LegendRow
            color="#fcbba1"
            text="1 - 7"
          />,
          <LegendRow
            color="#fc9272"
            text="8 - 28"
          />,
          <LegendRow
            color="#fb6a4a"
            text="29 - 48"
          />,
          <LegendRow
            color="#de2d26"
            text="49 - 85"
          />,
          <LegendRow
            color="#a50f15"
            text="86+"
          />,
        ]}
      />
    );
  }
}

export default LihtcExistingPropertyInventoryLegend;
