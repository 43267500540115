import * as firebase from "firebase/app";
import "firebase/auth";
import { UserDocument } from "../../../../../types/UserDocument";

const MISSING_EMAIL = null; // Flag indicating that the user document still doesn't have an email.

/**
 * Add user email to the user document and create a subscription document.
 */
const userEmailAndSubscription = async (userData: UserDocument, userId: string) => {
  const user = firebase.auth().currentUser;
  if (!user || user.uid !== userId) return false;

  let userModified = false;
  if (userData.email === MISSING_EMAIL) {
    userData.email = user.email;
    userModified = true;
  }

  return userModified;
}

export default userEmailAndSubscription;
