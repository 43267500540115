import React from "react";
import { connect } from "react-redux";
import { graphsSelectors } from "../../../../state/graphs";
import { AppState } from "../../../../state/rootReducer";
import { subscriptionSelectors } from "../../../../state/subscription";
import { GraphId } from "../../../../types/Graph";
import { Tier } from "../../../../types/Tier";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import LineGraph from "./LineGraph";

const mapStateToProps = (state: AppState) => {
  return {
    graphDataSources: graphsSelectors.getGraphDataSources(state),
    tier: subscriptionSelectors.getTier(state),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class MarketGraphsPanel extends React.Component<Props, {}> {
  /**
   * Render missing data message.
   */
  renderMissingData = () => {
    return (
      <div className="missing-data-container">
        <div className="request-icon" />
        <p>Please zoom in to verify if we have data in this city. If we don’t have data and you’d like to request it, please click the button below.</p>
        <a href="http://bit.ly/2Eo90tz" target="_blank" rel="noopener noreferrer" className="request-button">Request Data</a>
      </div>
    );
  }

  /**
   * Render Line graphs.
   */
  renderLineGraphs = () => {
    return (
      <div className="graphs-wrapper">
        <LineGraph graphId={GraphId.Population} />
        <LineGraph graphId={GraphId.MedianHouseholdIncome} requiresAccess />
        <LineGraph graphId={GraphId.MedianGrossRent} requiresAccess />
        <LineGraph graphId={GraphId.EmploymentPerPopulation} requiresAccess />
      </div>
    )
  }

  render() {
    if (this.props.tier === Tier.Free) return null;

    return (
      <WithPanelHeader title="Demographic Trends">
        <div className="component--market-graphs-panel">
          {Object.keys(this.props.graphDataSources).length === 0
            ? this.renderMissingData()
            : this.renderLineGraphs()
          }
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
)(MarketGraphsPanel);
