import { Map as MapboxMap } from "mapbox-gl";
import React from "react";
import ReactMapboxGl, { RotationControl } from "react-mapbox-gl";
import Measure from "react-measure";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import { pdfSelectors } from "state/pdf";
import { subscriptionSelectors } from "state/subscription";
import { layersActions } from "../../../../state/layers";
import { pdfActions } from "../../../../state/pdf";
import { mapsActions, mapsSelectors } from "../../../../state/ui/shared/maps";
import { ReactMapboxGlCamera } from "../../../../types/ReactMapboxGlCamera";
import arrayHelper from "../../../../utils/arrayHelper";
import mapboxHelper from "../../../../utils/mapbox/mapboxHelper";
import BuildingLayer from "../Map/BuildingLayer";

const mapStateToProps = (state) => {
  return {
    mapStyleUrl: mapsSelectors.getMapStyleUrl(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    styleChanged: mapsSelectors.getStyleChanged(state),
    parcelCenterOfMass: developmentSelectors.getParcelCenterOfMass(state),
    pdfGraphDataSourcesAreInitialized: pdfSelectors.getGraphDataSourcesAreInitialized(state),
    tier: subscriptionSelectors.getTier(state),
    developerMapStyleUrl: mapsSelectors.getDeveloperMapStyleUrl(state),
  };
};

const mapDispatchToProps = {
  setCameraForPdf: pdfActions.setCamera,
  initializeLayers: mapsActions.initializeLayers,
  initializeLayersOnStyleChange: mapsActions.initializeLayersOnStyleChange,
  setAvailableLayers: mapsActions.setAvailableLayers,
  generateRentFeaturesStart: layersActions.generateRentFeaturesStart,
  initializePdfGraphDataSources: pdfActions.initializeGraphDataSources,
};

interface OwnProps {
  camera: any;
  setMap(map: MapboxMap);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

interface State {
  boundingBox?: [number, number, number, number];
}

/**
 * @fileoverview This container wraps the Mapbox map component and manages
 *  any data and functionality it requires.
 */

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
});

class DeveloperExplorerMap extends React.Component<Props, State> {
  map: any;
  camera: ReactMapboxGlCamera;

  constructor(props) {
    super(props);

    // This attribute helps us to manage the quirky behavior of the `<Mapbox>`
    // component, implemented by `react-mapbox-gl`.
    // See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
    // TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
    this.camera = {
      zoom: [props.camera.zoom],
      bearing: [props.camera.bearing],
      pitch: [props.camera.pitch],
      center: props.camera.target,
    };

    this.state = {};

    this.props.setCameraForPdf(this.camera);
  }

  /**
   * Resize the Map when component updates.
   */
  componentDidUpdate() {
    if (this.map) {
      this.map.resize();
    }
  }

  /**
   * Resize the Map.
   */
  resizeMap = () => {
    if (this.map) {
      this.map.resize();
    }
  };

  /**
   * The `<Mapbox>` component, implemented by `react-mapbox-gl`, requires the
   * camera props to be wrapped in arrays, and will jump the camera to the
   * indicated position if the array objects that are passed change (NOT if the
   * values they contain change). In other words, in order to tell the camera
   * to jump to a new location, it is necessary to break the array equality, and
   * replace the old array objects with new array objects.
   *
   * See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
   *
   * This method helps work around this behavior, by ensuring that the array
   * objects that are passed to the `<Mapbox>` props are only replaced with a
   * new array objects at the appropriate time.
   *
   * TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
   */
  updateCameraArrays = () => {
    let cameraIsDifferent =
      this.props.camera.zoom !== this.camera.zoom[0] ||
      this.props.camera.pitch !== this.camera.pitch[0] ||
      this.props.camera.bearing !== this.camera.bearing[0] ||
      this.props.camera.target[0] !== this.camera.center[0] ||
      this.props.camera.target[1] !== this.camera.center[1];

    if (cameraIsDifferent) {
      this.camera.zoom = [this.props.camera.zoom];
      this.camera.pitch = [this.props.camera.pitch];
      this.camera.bearing = [this.props.camera.bearing];
      this.camera.center = this.props.camera.target.slice();
    }
  };

  /**
   * Set the camera for PDF generation.
   */
  handleMoveEnd = () => {
    if (this.map) {
      let mapCenter = this.map.getCenter();
      this.props.setCameraForPdf({
        zoom: [this.map.getZoom()],
        pitch: [this.map.getPitch()],
        bearing: [this.map.getBearing()],
        center: [mapCenter.lng, mapCenter.lat],
      });
    }
  };

  /**
   * Handle map style load.
   * NOTE: This handler is only called once in the lifetime of the map component.
   * This means that changing the style will not trigger this event.
   */
  handleStyleLoad = (map: MapboxMap) => {
    this.map = map;
    if (this.props.setMap) this.props.setMap(map);

    map.on("idle", () => {
      const currentBoundingBox = mapboxHelper.calculateCurrentBoundingBox(this.map);
      if (currentBoundingBox) {
        if (!this.state.boundingBox || !arrayHelper.equals(this.state.boundingBox, currentBoundingBox)) {
          this.setState({ boundingBox: currentBoundingBox });
        }
      }
    });

    map.on("style.load", () => {
      // NOTE: For the reason stated on the function description above, initializing the map
      // after a style change has to be done on 'style.load' event.
    });
  };

  render() {
    this.updateCameraArrays();
    const { developerMapStyleUrl } = this.props;

    return (
      <Measure onResize={this.resizeMap}>
        {({ measureRef }) => (
          <div ref={measureRef} className="component--map">
            <Mapbox
              style={developerMapStyleUrl}
              zoom={this.camera.zoom}
              pitch={this.camera.pitch}
              bearing={this.camera.bearing}
              center={this.camera.center}
              onStyleLoad={this.handleStyleLoad}
              onMoveEnd={this.handleMoveEnd}
              containerStyle={{
                height: "100%",
                width: "100%",
                margin: "0 auto",
              }}
              movingMethod="jumpTo"
            >
              {/* Explorer Page Specific Layers */}
              <BuildingLayer />
              <RotationControl position="bottom-right" className="rotation-control" />
            </Mapbox>
          </div>
        )}
      </Measure>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperExplorerMap);
