import { FilterId } from "../../../../../types/Filter";
import { SearchConfig } from "../../../../../types/Config";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: SearchConfig = {
  [FilterId.ExistingStructureArea]: {
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 139354.56,
    absoluteMax: 929031.32,
    [Unit.System.Imperial]: {
      step: 1000,
      label: "Existing Structure Data (SF)",
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      step: 500,
      label: "Existing Structure Data (m²)",
      unitTarget: Unit.Type.SquareMeters,
    },
  },
  [FilterId.ExistingStructureAreaOpenSource]: {
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 139354.56,
    absoluteMax: 929031.32,
    [Unit.System.Imperial]: {
      step: 1000,
      label: "Existing Structure Data Open Source (SF)",
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      step: 500,
      label: "Existing Structure Data Open Source (m²)",
      unitTarget: Unit.Type.SquareMeters,
    },
  },
  [FilterId.BuildingHeight]: {
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 60.96,
    absoluteMax: 1000,
    [Unit.System.Imperial]: {
      step: 5,
      label: "Building Height Allowed (FT)",
      unitTarget: Unit.Type.Feet,
    },
    [Unit.System.Metric]: {
      step: 10,
      label: "Building Height Allowed (m)",
      unitTarget: Unit.Type.Meters,
    },
  },
  [FilterId.ExistingBuildingHeight]: {
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 60.96,
    absoluteMax: 1000,
    [Unit.System.Imperial]: {
      step: 5,
      label: "Existing Building Height (FT)",
      unitTarget: Unit.Type.Feet,
    },
    [Unit.System.Metric]: {
      step: 10,
      label: "Existing Building Height (m)",
      unitTarget: Unit.Type.Meters,
    },
  },
  [FilterId.BuildableArea]: {
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 92903.04,
    absoluteMax: 139354560,
    [Unit.System.Imperial]: {
      step: 1000,
      label: "Approximate Buildable Area (SF)",
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      step: 100,
      label: "Buildable Area (m²)",
      unitTarget: Unit.Type.SquareMeters,
    },
  },
  [FilterId.MedianIncomeTotal]: {
    label: "Median Income ($)",
    min: 0,
    max: 1000000,
    step: 5000,
    absoluteMax: 100000000,
    formatOptions: { type: Format.Type.Number },
  },
  [FilterId.GrossMedianRent]: {
    label: "Median Gross Rent ($)",
    min: 0,
    max: 10000,
    step: 100,
    absoluteMax: 100000,
    formatOptions: { type: Format.Type.Number },
  },
  [FilterId.AreaPublished]: {
    min: 0,
    max: 13935.46,
    absoluteMax: 929031.32,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      step: 5000,
      label: "Lot Size (SF)",
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      step: 1000,
      label: "Lot Size (m²)",
      unitTarget: Unit.Type.SquareMeters,
    },
  },
  [FilterId.ExistingYearBuilt]: {
    label: "Year Built",
    min: 1900,
    max: 2020,
    absoluteMax: 2021,
    step: 1,
  },
  [FilterId.FloorAreaRatio]: {
    label: "Floor Area Ratio",
    min: 0,
    max: 100,
    absoluteMax: 1000,
    step: 1,
  },
  [FilterId.NumberOfUnitsAllowed]: {
    label: "Estimated Units Allowed",
    min: 0,
    max: 1500,
    absoluteMax: 10000,
    step: 10,
    formatOptions: { type: Format.Type.Number },
  },
  [FilterId.NumberOfResidentialUnits]: {
    label: "Existing Units",
    min: 0,
    max: 1000,
    absoluteMax: 100000,
    step: 10,
    formatOptions: { type: Format.Type.Number },
  },
};

export default config;
