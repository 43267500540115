export enum PopulationDataYear {
  Population2010 = "2010",
  Population2011 = "2011",
  Population2012 = "2012",
  Population2013 = "2013",
  Population2014 = "2014",
  Population2015 = "2015",
  Population2016 = "2016",
  Population2017 = "2017",
  Population2018 = "2018",
  Population2019 = "2019",
  Population2020 = "2020",
  Population2021 = "2021",
  Population2022 = "2022",
}

export enum MedianRentDataYear {
  MedianRent2013 = "2013",
  MedianRent2014 = "2014",
  MedianRent2015 = "2015",
  MedianRent2016 = "2016",
  MedianRent2017 = "2017",
  MedianRent2018 = "2018",
  MedianRent2019 = "2019",
  MedianRent2020 = "2020",
  MedianRent2021 = "2021",
  MedianRent2022 = "2022",
}

export enum EmploymentPerPopulationDataYear {
  EmploymentPerPopulation2013 = "2013",
  EmploymentPerPopulation2014 = "2014",
  EmploymentPerPopulation2015 = "2015",
  EmploymentPerPopulation2016 = "2016",
  EmploymentPerPopulation2017 = "2017",
  EmploymentPerPopulation2018 = "2018",
  EmploymentPerPopulation2019 = "2019",
  EmploymentPerPopulation2020 = "2020",
  EmploymentPerPopulation2021 = "2021",
  EmploymentPerPopulation2022 = "2022",
}

export enum MedianIncomeDataYear {
  MedianIncome2013 = "2013",
  MedianIncome2014 = "2014",
  MedianIncome2015 = "2015",
  MedianIncome2016 = "2016",
  MedianIncome2017 = "2017",
  MedianIncome2018 = "2018",
  MedianIncome2019 = "2019",
  MedianIncome2020 = "2020",
  MedianIncome2021 = "2021",
  MedianIncome2022 = "2022",
}

export enum SeaLevelRiseData {
  Level_0 = 0,
  Level_1 = 1,
  Level_2 = 2,
  Level_3 = 3,
  Level_4 = 4,
  Level_5 = 5,
  Level_6 = 6,
  Level_7 = 7,
  Level_8 = 8,
  Level_9 = 9,
  Level_10 = 10,
}
