import React from "react";
import { ConfigBase } from "../../../types/Config";
import Unit from "../../../types/Unit";
import configHelper from "../../../utils/configHelper";

interface OwnProps {
  component: any;
  config: any;
  unitSystem: Unit.System;
  textModifier?(): string;
  [x: string]: any;
}

type Props = OwnProps;

class ConfigurationHandler extends React.Component<Props, {}> {
  render() {
    const { config, unitSystem, textModifier, ...rest } = this.props;
    if (!config) {
      console.warn("No config provided");
      return null;
    }

    let currentConfig: ConfigBase = config;
    if (currentConfig.requiresAuthentication) {
      currentConfig = configHelper.hydrateHiddenValue(currentConfig);
    }

    if (!currentConfig.getter) {
      currentConfig = configHelper.hydrateGetter(currentConfig);
    }

    let unitSpecificConfig = configHelper.getConfigForUnitSystem(currentConfig, unitSystem);

    return (
      <this.props.component {...{ ...unitSpecificConfig, ...rest }} />
    )
  }
}

export default ConfigurationHandler;
