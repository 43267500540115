import { Map as MapboxMap } from "mapbox-gl";
import Map from "./Map";
import React, { useRef, useState } from "react";
import { MapContext } from "react-mapbox-gl";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import { authenticationSelectors } from "../../../state/authentication";
import { developmentActions, developmentSelectors } from "../../../state/development";
import { pdfActions } from "../../../state/pdf";
import { accordionSectionActions } from "../../../state/ui/shared/accordionSection";
import { mapsActions, mapsSelectors } from "../../../state/ui/shared/maps";
import { routeActions, routeSelectors } from "../../../state/ui/shared/route";
import { userSelectors } from "../../../state/user";
import { Path } from "../../../types/Path";
import authentication from "../../../utils/authentication";
import UserLoadingFeedback from "../../sharedComponents/UserLoadingFeedback";
import { setbackModeActions, setbackModeSelectors } from "state/setbackMode";
import SetbackClosePanel from "./SetbackClosePanel/SetbackClosePanel";
import KeepProject from "./KeepProject/KeepProject";
import DevelopmentsDropdown from "./DevelopmentsDropdown/DevelopmentsDropdown";
import developmentAccessors from "state/development/utils/developmentAccessors";
import ExplorerToolbar from "./ExplorerToolbar/ExplorerToolbar";
import ParcelTools from "views/sharedComponents/ParcelTools/ParcelTools";
import SetbackSelectionMap from "./SetbackSelectionMap/SetbackSelectionMap";
import DefineBuildingPanel from "./DefineBuildingPanel/DefineBuildingPanel";
import AssumptionsPanel from "./AssumptionsPanel/AssumptionsPanel";
import PropertyInfoPanel from "./PropertyInfoPanel/PropertyInfoPanel";
import ReturnAnalysis from "./ReturnAnalysis/ReturnAnalysis";
import ActivityFeedback from "views/sharedComponents/ActivityFeedback/ActivityFeedback";
import DeveloperExplorerMap from "./DeveloperExplorerMap/DeveloperExplorerMap";
import BusyPopup from "views/sharedComponents/BusyPopup/BusyPopup";
import PdfGenerator from "views/sharedComponents/PdfGenerator/PdfGenerator";
import ThumbnailMap from "./ThumbnailMap/ThumbnailMap";
import SetbackToolsWrapper from "./SetbackToolsWrapper/SetbackToolsWrapper";
import LayerSelection from "views/sharedComponents/LayerSelection/LayerSelection";
import { useExplorerPageConstructor } from "./ExplorerCustomHooks/useExplorerPageConstructor";
import { useExplorerRouting } from "./ExplorerCustomHooks/useExplorerRouting";
import { allTogglesDisabled } from "views/utils/uiToggleHelper";
import { usePrevPropsProjectId } from "./ExplorerCustomHooks/usePrevPropsProjectId";
import { useExplorerReplaceProjectRoute } from "./ExplorerCustomHooks/useExplorerReplaceProjectRoute";
import { useExplorerBuildingAlert } from "./ExplorerCustomHooks/useExplorerBuildingAlert";
import { getBuildingIsReduced } from "./ExplorerUtils/utils";
import KpiPanelVisibility from "./KpiPanelVisibility/KpiPanelVisibility";

const mapStateToProps = (state) => {
  let toggleValues = developmentSelectors.getBuildingUsageToggles(state);
  let development = developmentSelectors.getDevelopment(state);

  return {
    project: development,
    projectIsReady: developmentSelectors.getIsReady(state),
    projectIsEdited: developmentSelectors.getIsEdited(state),
    projectId: developmentSelectors.getProjectId(state),
    toggles: toggleValues,
    allTogglesDisabled: allTogglesDisabled(toggleValues),
    setbackMode: developmentSelectors.getValue(state, "setbackMode"),
    values: developmentAccessors.getValues(development),
    buildingModel: developmentSelectors.getBuildingModel(state),
    userDocument: userSelectors.getUserDocument(state),
    accessRestricted: developmentSelectors.getAccessRestricted(state),
    setbackModeIsActive: setbackModeSelectors.getSetbackModeIsActive(state),
    authenticationIsInitialized: authenticationSelectors.getAuthenticationIsInitialized(state),
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
    routeIsBlocked: routeSelectors.getRouteIsBlocked(state),
    blockedRoutePathName: routeSelectors.getPathName(state),
    mapIsReady: mapsSelectors.getMapIsReady(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  loadDevelopment: developmentActions.loadStart,
  clearPdfState: pdfActions.clearState,
  resetDevelopmentState: developmentActions.resetStateStart,
  resetProjectInfoPanelUi: accordionSectionActions.reset,
  setSetbackMode: setbackModeActions.setSetbackMode,
  setDevelopmentIsReady: developmentActions.setProjectIsReady,
  blockRoute: routeActions.blockRoute,
  unblockRoute: routeActions.unblockRoute,
  resetFlags: mapsActions.resetFlags,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux;

const ExplorerPage: React.FC<Props> = (props) => {
  const { projectId: urlProjectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  // State Management
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(true);
  const [map, setMap] = useState<MapboxMap | undefined>(undefined);

  const prevProps = useRef<Props | undefined>();

  // Props
  const {
    project,
    projectIsReady,
    projectIsEdited,
    projectId,
    values,
    buildingModel,
    userDocument,
    accessRestricted,
    setbackModeIsActive,
    authenticationIsInitialized,
    userIsAuthenticated,
    routeIsBlocked,
    blockedRoutePathName,
    mapIsReady,
    tier,
    loadDevelopment,
    clearPdfState,
    resetDevelopmentState,
    resetProjectInfoPanelUi,
    setSetbackMode,
    setDevelopmentIsReady,
    unblockRoute,
    resetFlags,
  } = props;

  const initialize = async () => {
    if (!authenticationIsInitialized) {
      return;
    }

    if (userIsAuthenticated && !userDocument) {
      return;
    }

    const id = projectId || urlProjectId;
    const user = authentication.getCurrentUser();
    loadDevelopment(user, id, userDocument);
  };

  /** --------------------------------------------------------------
   *
   *
   *  Custom hooks for the component
   *
   *
   * -------------------------------------------------------------- */

  useExplorerPageConstructor(
    unblockRoute,
    clearPdfState,
    resetFlags,
    resetProjectInfoPanelUi,
    userIsAuthenticated,
    resetDevelopmentState,
    setDevelopmentIsReady,
    initialize,
    setSetbackMode,
    setbackModeIsActive
  );

  useExplorerRouting(routeIsBlocked, projectIsEdited, blockedRoutePathName, unblockRoute);

  if (prevProps.current) {
    usePrevPropsProjectId(prevProps.current.projectId, projectId, setSetbackMode);
  }

  useExplorerReplaceProjectRoute(projectId, urlProjectId);

  useExplorerBuildingAlert(getBuildingIsReduced, prevProps, props, setAlertIsOpen);

  const renderBuildingReducedAlert = () => {
    if (!alertIsOpen) return null;

    const buildingIsReduced = getBuildingIsReduced(buildingModel, values);
    if (!buildingIsReduced) return null;

    return (
      <div className="building-alert-wrapper">
        <div className="building-alert">
          <div className="icon-info" />
          Your setbacks are too big, or your building is too tall. Please reduce setback values or building areas.
          <div className="icon-close" onClick={() => setAlertIsOpen(false)} />
        </div>
      </div>
    );
  };

  const renderMapLoading = () => (
    <div className="map-loading-container">
      <ActivityFeedback message="Loading 3D Model..." displaySpinner />
    </div>
  );

  /**
   * Render the map depending on the type of subscription of the user
   */
  const renderMap = () => {
    const isDeveloperTier = tier === Tier.Developer;
    return !isDeveloperTier ? (
      <Map camera={project.camera} setMap={setMap} />
    ) : (
      <DeveloperExplorerMap camera={project.camera} setMap={setMap} />
    );
  };

  if (tier === Tier.Free) {
    navigate(Path.SubscriptionManagement);
    return null;
  }
  if (accessRestricted) {
    navigate(Path.Dashboard);
    return null;
  }
  if (!projectIsReady && !projectIsEdited) return <UserLoadingFeedback />;

  const isDeveloperTier = tier === Tier.Developer;
  const isDemoTier = tier === Tier.None;
  const isProTier = tier === Tier.Pro;

  return (
    <MapContext.Provider value={map}>
      {routeIsBlocked && <BusyPopup text="Saving Project..." preventClose />}
      <div className="component--explorer-page">
        <div className="explorer-content">
          <div className="top-menus-container">
            <div className="projects-wrapper">
              <DevelopmentsDropdown />
              {setbackModeIsActive ? <SetbackClosePanel /> : <KeepProject />}
              {renderBuildingReducedAlert()}
            </div>
            <ExplorerToolbar />
          </div>
          <div className={`panels-container ${setbackModeIsActive ? "setback-mode" : ""}`}>
            <div className="left-panel-wrapper">
              {!setbackModeIsActive && <ParcelTools mapIsReady={Boolean(map)} />}
              <DefineBuildingPanel />
              <AssumptionsPanel />
            </div>
            <div className="right-panel-wrapper">
              {setbackModeIsActive ? <SetbackToolsWrapper /> : isProTier && <LayerSelection map={map} />}
              {!setbackModeIsActive && <ReturnAnalysis />}
              {!setbackModeIsActive && <PropertyInfoPanel />}
            </div>
          </div>
          <div className="kpi-container">{<KpiPanelVisibility />}</div>
        </div>
        {!isDeveloperTier && !isDemoTier && !mapIsReady && renderMapLoading()}
        {setbackModeIsActive ? <SetbackSelectionMap setMap={setMap} /> : renderMap()}

        <PdfGenerator />
        <ThumbnailMap />
      </div>
    </MapContext.Provider>
  );
};

export default connector(ExplorerPage);
