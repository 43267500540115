import React from "react";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerLegend from "../sharedComponents/DynamicLayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class DynamicMedianIncomeLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <DynamicLayerLegend
        layerGroup={DynamicLayerId.Dynamic_Layer_Household_Income}
        classes="median-income"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< $25,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fd0d0d 0%, #fff305 100%)"
            text="$25,000 - $50,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fff305 0%, #0fe600 100%)"
            text="$50,000 - $75,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #0fe600 0%, #16e7fe 100%)"
            text="$75,000 - $100,000"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #16e7fe 0%, #024be8 100%)"
            text="$100,000 - $150,000"
            wide
          />,
          <LegendRow
            color="#024be8"
            text="> $150,000"
            wide
          />,
        ]}
      />
    );
  }
}

export default DynamicMedianIncomeLegend;
