import React from "react";
import { MapStyleProperties } from "../../../utils/mapbox/mapStyleProperties";
import { connect } from "react-redux";
import { mapsSelectors, mapsActions } from "../../../state/ui/shared/maps";

const mapStateToProps = (state) => {
  return  {
    developerTierMapStyleUrl: mapsSelectors.getDeveloperMapStyleUrl(state),
  }
}

const mapDispatchToProps = {
  toggleMapStyle: mapsActions.toggleDeveloperMapStyle
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class DeveloperMapStyleButton extends React.PureComponent<Props, {}>{
  /**
   * Toggle map style and update state.
   */
  toggleMapStyle = (event) => {
    this.props.toggleMapStyle();
  }

  render() {
    const mapStyleIsStreet = this.props.developerTierMapStyleUrl === MapStyleProperties.DeveloperTierStyleUrl.DeveloperTierStreets;

    return (
      <div
        onClick={this.toggleMapStyle}
        className={`component--map-style-button ${mapStyleIsStreet ? "satellite" : "streets"}-map-button`}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeveloperMapStyleButton)
