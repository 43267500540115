import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { DynamicLayerId, LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerToggleBase from "views/sharedComponents/DynamicLayerToggleBase";
import { subscriptionSelectors } from "../../../../../../state/subscription";
import { mapsSelectors } from "../../../../../../state/ui/shared/maps";
import { Path } from "../../../../../../types/Path";
import layerHelper from "../../../../../../utils/mapbox/layerHelper";
import { colorFor } from "../../../../../utils/colors";
import { ToggleField } from "../../../../../utils/forms/fieldRenderers";
import { TooltipType } from "../../../../Tooltip/config";

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  };
};

interface OwnProps {
  layerIds: Array<LayerId>;
  layerGroup: DynamicLayerId;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const DynamicLayerToggle: React.FC<Props> = ({ layerIds, tier, dynamicLayerConfigurations, layerGroup }) => {
  const location = useLocation();
  const side = location.pathname.includes(Path.Explorer) ? "left" : "right";
  const disableForDemo = layerHelper.disableDynamicLayersForDemo(tier, layerIds, dynamicLayerConfigurations);

  return (
    <DynamicLayerToggleBase layerIds={layerIds} layerGroup={layerGroup}>
      {(isActive, onClick) => (
        <ToggleField
          classes={disableForDemo ? "disable-for-demo" : ""}
          color={colorFor("legend-toggle")}
          toggleElementProps={{
            value: isActive,
            onChange: disableForDemo ? undefined : onClick,
          }}
          tooltipId={`click-${side}`}
          tooltipType={disableForDemo ? TooltipType.LayerSelectionPanel : null}
        />
      )}
    </DynamicLayerToggleBase>
  );
};

export default connect(mapStateToProps)(DynamicLayerToggle);
