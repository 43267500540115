import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../../state/ui/shared/accordionSection/reducers";
import { BuildingUse } from "../../../../../types/BuildingUse";
import AccordionSection from "../../../../sharedComponents/AccordionSection";
import CellRow from "../../../../sharedComponents/CellRow";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import CellsContainer from "../../../../sharedComponents/CellsContainer";
import config from "../config";
import { incomeProducingUsesIsEnabled } from "../../../../utils/uiToggleHelper";
import AssumptionsCsvExportButton from "./AssumptionsCsvExportButton";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    boeAccordionsAreOpen: accordionSectionSelectors.getBoeAccordionsAreOpen(state),
  };
};

const mapDispatchToProps = {
  setBoeAccordionIsOpen: accordionSectionActions.setBoeAccordionIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class Assumptions extends React.PureComponent<Props, {}> {
  /**
   * Toggle the accordions.
   */
  toggleAccordionIsOpen = (accordionId: Accordions) => {
    const accordionIsOpen = this.props.boeAccordionsAreOpen[accordionId];
    this.props.setBoeAccordionIsOpen(accordionId, !accordionIsOpen);
  }

  render() {
    const { boeAccordionsAreOpen, toggles } = this.props;
    const hasIncomeProducingUses = incomeProducingUsesIsEnabled(toggles);

    return (
      <div className="panel-content">
        <AssumptionsCsvExportButton />
        <AccordionSection
          title="Property"
          isOpen={boeAccordionsAreOpen[Accordions.Property]}
          toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Property)}
        >
          <table>
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.parcelArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.existingStructureArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.parcelPurchasePricePerArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.parcelPurchasePrice} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        </AccordionSection>

        <AccordionSection
          title="Building"
          isOpen={boeAccordionsAreOpen[Accordions.SiteBuilding]}
          toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.SiteBuilding)}
        >
          <table>
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.floorAreaRatio} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.floorAreaRatioWithoutParking} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.unitsPerParcelArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectNumberOfFloors} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.buildingHeight} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.siteCoveragePercentage} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.siteCoverageArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.openSpaceArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        </AccordionSection>

        <AccordionSection
          title="Areas"
          isOpen={boeAccordionsAreOpen[Accordions.Areas]}
          toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Areas)}
        >
          <table>
            <tbody>
              <CellRow usageGroup={BuildingUse.Multifamily}>
                <ConfigurationHandler config={config.multifamilyTotalUnitQuantity} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow usageGroup={BuildingUse.Condo}>
                <ConfigurationHandler config={config.condoTotalUnitQuantity} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow usageGroup={BuildingUse.Retail}>
                <ConfigurationHandler config={config.retailNetLeasableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow usageGroup={BuildingUse.Office}>
                <ConfigurationHandler config={config.officeGrossLeasableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow usageGroup={BuildingUse.Industrial}>
                <ConfigurationHandler config={config.industrialNetLeasableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow usageGroup={BuildingUse.Hotel}>
                <ConfigurationHandler config={config.hotelRoomQuantity} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.parkingRequiredSpacesAfterReduction} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.parkingGrossBuildableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        </AccordionSection>

        {hasIncomeProducingUses &&
          <AccordionSection
            title="Economics"
            isOpen={boeAccordionsAreOpen[Accordions.Economics]}
            toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.Economics)}
          >
            <table>
              <tbody>
                <CellRow usageGroup={BuildingUse.Multifamily}>
                  <ConfigurationHandler config={config.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Multifamily}>
                  <ConfigurationHandler config={config.multifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Retail}>
                  <ConfigurationHandler config={config.retailYearOneRentPerArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Office}>
                  <ConfigurationHandler config={config.officeYearOneRentPerArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Industrial}>
                  <ConfigurationHandler config={config.industrialYearOneRentPerArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Hotel}>
                  <ConfigurationHandler config={config.hotelStabilizedAverageDailyRate} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Hotel}>
                  <ConfigurationHandler config={config.hotelStabilizedOccupancyPercentage} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Hotel}>
                  <ConfigurationHandler config={config.hotelStabilizedRevenuePerAvailableRoom} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
                <CellRow usageGroup={BuildingUse.Hotel}>
                  <ConfigurationHandler config={config.hotelOtherRevenuePercentage} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
                </CellRow>
              </tbody>
            </table>
          </AccordionSection>
        }

        <AccordionSection
          title="Project Cost"
          isOpen={boeAccordionsAreOpen[Accordions.ProjectCost]}
          toggleIsOpen={() => this.toggleAccordionIsOpen(Accordions.ProjectCost)}
        >
          <table>
            <tbody>
              <CellRow>
                <ConfigurationHandler config={config.projectGrossBuildableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.existingStructureDemolitionCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectHardCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectSoftCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectContingencyCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectConstructionCostIncludingDemolition} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.projectConstructionCostIncludingDemolitionPerBuildableArea} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
              <CellRow>
                <ConfigurationHandler config={config.parcelPurchasePrice} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
              </CellRow>
            </tbody>
          </table>
        </AccordionSection>
        <table className="section-end">
          <tbody>
            <CellRow>
              <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={CellsContainer} valueStyleVariation="aligned-to-right" unitSystem={this.props.unitSystem} />
            </CellRow>
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Assumptions);
