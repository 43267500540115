import { useEffect } from "react";
import { Map, Point } from "mapbox-gl";
import mapboxHelper from "utils/mapbox/mapboxHelper";
import arrayHelper from "utils/arrayHelper";
import { ParcelTool } from "types/ParcelTool";
import { Feature, GeoJSON, GeoJsonProperties, Geometry } from "geojson";
import { CombineParcelsStartFunction } from "state/newDevelopment/types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

export const useProMapOnIdle = (
  map: React.MutableRefObject<Map | undefined>,
  boundingBox: [number, number, number, number] | undefined,
  setBoundingBox: React.Dispatch<React.SetStateAction<[number, number, number, number] | undefined>>,
  updateUrl: () => void,
  pinPosition: [number, number] | null | undefined,
  selectedFeature: any,
  smartSearchIsOpen: boolean,
  selectParcelIfAvailable: (coordinates: any) => boolean,
  parcelPointToBeSelected: React.MutableRefObject<Point | null>,
  parcelTool: ParcelTool,
  getParcel: (point: Point) => null[] | (number[] | GeoJSON)[],
  combineParcelsStart: CombineParcelsStartFunction,
  selectedFeatureMembers: { [featureId: number]: GeoJSON },
  checkDataInViewport: () => void,
  updateGraphData: () => void,
  generateRentFeaturesStart: ActionCreatorWithPayload<Feature<Geometry, GeoJsonProperties>[], string>,
  styleChanged: boolean,
  updateAvailableLayers: () => void,
  updateAvailableDynamicLayers: () => void
) => {
  useEffect(() => {
    if (map.current) {
      const onIdle = () => {
        const currentBoundingBox = mapboxHelper.calculateCurrentBoundingBox(map.current);
        if (currentBoundingBox) {
          if (!boundingBox || !arrayHelper.equals(boundingBox, currentBoundingBox)) {
            checkDataInViewport();
            setBoundingBox(currentBoundingBox);
            updateUrl();
            updateGraphData();
            mapboxHelper.updateRentFeatures(map.current, generateRentFeaturesStart);
          }
        }

        if (pinPosition && !selectedFeature && smartSearchIsOpen === false) {
          selectParcelIfAvailable(pinPosition);
        }

        if (parcelPointToBeSelected.current && parcelTool === ParcelTool.DrawParcel) {
          let [parcel, coordinates] = getParcel(parcelPointToBeSelected.current);
          if (parcel && "type" in parcel) {
            combineParcelsStart(parcel, coordinates as [number, number], selectedFeatureMembers);
            parcelPointToBeSelected.current = null;
          }
        }
        if (!styleChanged) updateAvailableLayers();
        if (!styleChanged) updateAvailableDynamicLayers();
      };

      if (!styleChanged) updateAvailableLayers();
      if (!styleChanged) updateAvailableDynamicLayers();

      map.current.on("idle", onIdle);

      return () => {
        if (map.current) {
          map.current.off("idle", onIdle);
        }
      };
    } else {
      return () => {}; // Return a no-op function when map.current is not set
    }
  }, [
    boundingBox,
    setBoundingBox,
    updateUrl,
    pinPosition,
    selectedFeature,
    smartSearchIsOpen,
    selectParcelIfAvailable,
    parcelPointToBeSelected,
    parcelTool,
    getParcel,
    combineParcelsStart,
    selectedFeatureMembers,
    styleChanged,
  ]);
};
