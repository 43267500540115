import React from "react";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import DynamicLayerLegend from "../sharedComponents/DynamicLayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class DynamicEmploymentPerPopulationLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <DynamicLayerLegend
        layerGroup={DynamicLayerId.Dynamic_Layer_Employment_Per_Population}
        classes="employment-per-population"
        legendRows={[
          <LegendRow
            color="#fd0d0d"
            text="< 10%"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fd0d0d 0%, #fff305 100%)"
            text="10% - 30%"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #fff305 0%, #0fe600 100%)"
            text="30% - 50%"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #0fe600 0%, #16e7fe 100%)"
            text="50% - 70%"
            wide
          />,
          <LegendRow
            background="linear-gradient(to right, #16e7fe 0%, #024be8 100%)"
            text="70% - 90%"
            wide
          />,
          <LegendRow
            color="#024be8"
            text="> 90%"
            wide
          />,
        ]}
      />
    );
  }
}

export default DynamicEmploymentPerPopulationLegend;
