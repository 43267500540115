import React from "react";

class DrawParcelInformationPanel extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--draw-parcel-information-panel">
        <div className="header">Draw Parcel Instructions</div>
        <div className="blocks-container">
          <div className="block">
            <div className="number">1.</div>
            <div className="image draw-parcel" />
            <div className="text">
              <div className="text-header">Draw Parcel</div>
              Select the pen icon to draw a parcel over the map.
            </div>
          </div>
          <div className="block">
            <div className="number">2.</div>
            <div className="image edit-parcel" />
            <div className="text">
              <div className="text-header">Edit and Move Drawn Parcels</div>
              The parcel edit icon allows you to adjust the shape.
            </div>
          </div>
          <div className="block">
            <div className="number">3.</div>
            <div className="image delete-parcel" />
            <div className="text">
              <div className="text-header">Delete Drawn Parcels</div>
              The delete drawn parcel icon allows you to remove drawn parcels on the map.
            </div>
          </div>
          <div className="block">
            <div className="number">4.</div>
            <div className="image select-parcel" />
            <div className="text">
              <div className="text-header">Select Parcel</div>
              With the arrow icon, you can select the drawn parcel.
            </div>
          </div>
          <div className="block">
            <div className="number">5.</div>
            <div className="image preliminary-underwriting">Feasibility Study</div>
            <div className="text">
              <div className="text-header">Model Project</div>
              3D Model, Financial Analysis, and PDF Report.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DrawParcelInformationPanel;
