import React from "react";
import propertyInfoIcon from "../../../../../../../assets/images/pdf-icon-project-summary.png";
import stackingIcon from "../../../../../../../assets/images/pdf-icon-stacking.png";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import Logo from "../../sharedComponents/Logo";
import Row from "./Row";
import config from "./config";
import Section from "./Section";
import Disclaimer from "./Disclaimer";
import Zoning from "./Zoning";
import { View, StyleSheet } from "@react-pdf/renderer";
import valueGetters from "state/development/utils/valueGetters";
import developmentAccessors from "state/development/utils/developmentAccessors";
import parcelAccessors from "../../../../../../../utils/parcel/parcelAccessors";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: "0 38px 100px",
    maxHeight: 605,
  },
});

/**
 * Render Row if value is not null.
 */
const ConditionalRow = (value, Row) => {
  if (
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0)
  )
    return null;

  return <Row.type value={value} {...Row.props} />;
};

const Summary = (props) => (
  <PdfContext.Consumer>
    {({ unitSystem, development }) => {
      const address = developmentAccessors.getAddress(development);
      const parcel = developmentAccessors.getParcel(development);
      const yearBuilt = parcelAccessors.getExistingStructureYearBuilt(parcel);
      const numberOfUnits = parcelAccessors.getNumberOfUnits(parcel);

      return (
        <View style={styles.container}>
          <Section title={"PROPERTY INFO"} titleIcon={propertyInfoIcon}>
            {ConditionalRow(
              address,
              <Row text="Legal Address" getter={valueGetters.generic} />
            )}
            <ConfigurationHandler
              config={config.parcelArea}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.existingStructureArea}
              component={Row}
              unitSystem={unitSystem}
            />
            {ConditionalRow(
              yearBuilt,
              <Row text="Year Built" getter={valueGetters.generic} />
            )}
            {ConditionalRow(
              numberOfUnits,
              <Row
                text="Number of Units"
                getter={valueGetters.generic}
                formatOptions={{ suffix: " Units" }}
              />
            )}
          </Section>

          <Zoning unitSystem={unitSystem} />

          <Section
            title={"STACKING PLAN"}
            titleIcon={stackingIcon}
            requiresUsages
          >
            <ConfigurationHandler
              config={config.condoTotalUnitQuantity}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.multifamilyTotalUnitQuantity}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.hotelRoomQuantity}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.officeGrossLeasableArea}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.retailNetLeasableArea}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.industrialNetLeasableArea}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.parkingRequiredSpacesAfterReduction}
              component={Row}
              unitSystem={unitSystem}
            />
            <ConfigurationHandler
              config={config.projectNumberOfFloors}
              component={Row}
              unitSystem={unitSystem}
            />
          </Section>

          <Disclaimer />
          <Logo />
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default Summary;
