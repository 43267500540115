import React from "react";
import { connect } from "react-redux";
import { explorerActions, explorerSelectors } from "../../../../state/ui/explorer";
import Tabs from "../../../sharedComponents/Tabs";
import Tab from "../../../sharedComponents/Tabs/Tab";
import TabContainer from "../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../sharedComponents/Tabs/TabPanel";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import BackOfEnvelope from "./BackOfEnvelope";
import Assumptions from "./Assumptions";
import PdfPanel from "./PdfPanel";

const mapStateToProps = (state) => {
  return {
    returnAnalysisPanelIsOpen: explorerSelectors.getReturnAnalysisPanelIsOpen(state),
  };
};

const mapDispatchToProps = {
  setReturnAnalysisPanelIsOpen: explorerActions.setReturnAnalysisPanelIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const TAB_CONFIG = {
  backOfEnvelope: {
    label: "Back of the Envelope",
    id: "backOfEnvelope",
  },
  assumptions: {
    label: "Assumptions",
    id: "assumptions"
  },
  pdfExport: {
    label: "PDF Report",
    id: "pdfExport"
  },
}

class ReturnAnalysisPanel extends React.PureComponent<Props, {}>  {
  render() {
    const { returnAnalysisPanelIsOpen, setReturnAnalysisPanelIsOpen } = this.props;

    return (
      <WithPanelHeader
        title="Return Analysis"
        isActive={returnAnalysisPanelIsOpen}
        onStateChange={setReturnAnalysisPanelIsOpen}
      >
        <div className="component--return-analysis-panel">
          <Tabs defaultTab={TAB_CONFIG.backOfEnvelope.id}>
            {(onTabClick, activeTab) =>
              <>
                <TabContainer>
                  <Tab
                    label={TAB_CONFIG.backOfEnvelope.label}
                    tabId={TAB_CONFIG.backOfEnvelope.id}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                  />
                  <Tab
                    label={TAB_CONFIG.assumptions.label}
                    tabId={TAB_CONFIG.assumptions.id}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                  />
                  <Tab
                    label={TAB_CONFIG.pdfExport.label}
                    tabId={TAB_CONFIG.pdfExport.id}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                  />
                </TabContainer>
                <TabPanel tabId={TAB_CONFIG.backOfEnvelope.id} activeTab={activeTab}>
                  <BackOfEnvelope />
                </TabPanel>
                <TabPanel tabId={TAB_CONFIG.assumptions.id} activeTab={activeTab}>
                  <Assumptions />
                </TabPanel>
                <TabPanel tabId={TAB_CONFIG.pdfExport.id} activeTab={activeTab}>
                  <PdfPanel />
                </TabPanel>
              </>
            }
          </Tabs>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnAnalysisPanel);
