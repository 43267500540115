import newDevelopmentActions from "./actions";
import { takeEvery, takeLatest, delay, call, put } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import geocoder from "../../utils/mapbox/geocoder";
import Mapbox from "../../types/Mapbox";
import actions from "./actions";

const DEBOUNCE_TIME_OUT = 300;

/**
 * Update the address in the search bar and trigger a forward geocode lookup for that address.
 */
function* addressKeystroke(action) {
  // TODO: Clean up handling of minimum address length.
  // See https://deepblocks.tpondemand.com/entity/4076-clean-up-handling-of-minimum-address
  yield put(
    newDevelopmentActions.addressKeystrokeAndForwardGeocodeStart(
      action.payload.searchAddress,
      action.payload.proceedToQuery
    )
  );

  if (!action.payload.proceedToQuery) {
    return;
  }

  yield delay(DEBOUNCE_TIME_OUT);

  try {
    let options: Mapbox.ForwardGeocodeOptions = {
      query: action.payload.searchAddress,
      proximity: action.payload.proximityCenter,
    };

    let response = yield call(geocoder.getForwardGeocodingData, options);
    yield put(newDevelopmentActions.forwardGeocodeSuccess(response));
  } catch (error: any) {
    yield put(newDevelopmentActions.geocodeError(error));
  }
}

/**
 * Reverse geocode the clicked feature and dispatch the selectParcelSuccess action.
 */
function* selectParcelStart(action) {
  try {
    const geocodedFeature = yield call(geocoder.getGeocodedFeature, action.payload.clickedCoordinates);
    yield put(newDevelopmentActions.selectParcelSuccess(action.payload.selectedParcel, geocodedFeature));
  } catch (error: any) {
    yield put(newDevelopmentActions.geocodeError(error));
  }
}

/**
 * Reverse geocode the clicked feature and dispatch the combineParcelSuccess action.
 */
function* combineParcelStart(action: ReturnType<typeof actions.combineParcelsStart>) {
  try {
    const geocodedFeature = yield call(geocoder.getGeocodedFeature, action.payload.clickedCoordinates);
    yield put(
      newDevelopmentActions.combineParcelsSuccess(
        action.payload.clickedParcel,
        geocodedFeature,
        action.payload.selectedFeatureMembers
      )
    );
  } catch (error: any) {
    yield put(newDevelopmentActions.geocodeError(error));
  }
}

/**
 * Watcher for geocodeSelectParcel action.
 */
function* watchSelectParcelStart() {
  yield takeEvery(actionTypes.SELECT_PARCEL_START, selectParcelStart);
}

/**
 * Watcher for geocodeCombineParcels action.
 */
function* watchCombineParcelsStart() {
  yield takeEvery(actionTypes.COMBINE_PARCELS_START, combineParcelStart);
}

/**
 * Watcher for addressKeystroke action.
 */
function* watchAddressKeystroke() {
  yield takeLatest(actionTypes.ADDRESS_KEYSTROKE, addressKeystroke);
}

export default {
  watchAddressKeystroke,
  watchSelectParcelStart,
  watchCombineParcelsStart,
};
