import { VariableId } from "../../../types/VariableId";

const defaultsInitialOnly = {
  [VariableId.SetbackA]: {
    "minimum": [0],
    "maximum": [100],
    "value": [0]
  },
  [VariableId.SetbackB]: {
    "minimum": [0],
    "maximum": [100],
    "value": [0]
  },
  [VariableId.SetbackC]: {
    "minimum": [0],
    "maximum": [100],
    "value": [0]
  },
  [VariableId.SetbackD]: {
    "minimum": [0],
    "maximum": [100],
    "value": [0]
  },
  [VariableId.ExistingStructureArea]: {
    "minimum": 0,
    "maximum": 92903.04,
    "value": 0
  },
  [VariableId.ParkingReductionFactor]: {
    "minimum": 0,
    "value": 0,
    "maximum": 1
  },
  [VariableId.ParkingSpaceArea]: {
    "minimum": 18.58,
    "value": 32.52,
    "maximum": 46.45
  },
  [VariableId.ParkingHardCostPerArea]: {
    "minimum": 301.39,
    "value": 484.38,
    "maximum": 667.36
  },
  [VariableId.ParkingRatioMultifamily]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingRatioCondo]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingRatioHotel]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingRatioOffice]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingRatioRetail]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingRatioIndustrial]: {
    "minimum": 0,
    "value": 0,
    "maximum": 5
  },
  [VariableId.ParkingReductionFeePerSpace]: {
    "minimum": 0,
    "value": 0,
    "maximum": 100000
  },
  [VariableId.ExitCapRate]: {
    "minimum": 0,
    "value": 0.045,
    "maximum": 0.25,
  },
}

export default defaultsInitialOnly;
