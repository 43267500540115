import React from "react";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import { useNavigate } from "react-router-dom";

const DashboardButton: React.FC = () => {
  const user = authentication.getCurrentUser();
  const navigate = useNavigate();

  /**
   * Redirect to the dashboard.
   */
  const redirectToDashboard = async () => {
    navigate(Path.Dashboard);
  };

  if (!user) return null;

  return (
    <div className="component--button dashboard" onClick={redirectToDashboard}>
      <div className="button" />
      <div className="text">Dashboard</div>
    </div>
  );
};

export default DashboardButton;
