import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "state/subscription";
import LayerGroup from "types/LayerGroup";
import { LayerSector } from "types/LayerSector";
import { Tier } from "types/Tier";
import config from "../config";
import DemoMapLayerButton from "../DemoMapLayerButton";
import LayerButton from "../LayerButton";
import configForDemo from "./config";

interface OwnProps {
  layerSector: string;
}

const mapStateToProps = (state) => ({
  tier: subscriptionSelectors.getTier(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

export class DemoStaticLayerPanel extends React.PureComponent<Props, {}> {
  renderDemoButtons = (layerSector: string) => {
    const sectorObject = configForDemo[layerSector];
    const layerButtons = sectorObject && sectorObject.buttons;
    if (layerButtons) {
      return (
        <div className="component--layer-sector-panel">
          {layerSector === LayerSector.Zoning && (
            <LayerButton
              layerIds={config[LayerGroup.Buildings].layers}
              text={config[LayerGroup.Buildings].buttonText}
              key={`layer_button_buildings`}
              disableForDemo={false}
            />
          )}
          {layerButtons.map((layerName) => (
            <DemoMapLayerButton key={layerName} text={layerName} />
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { tier, layerSector } = this.props;
    const isDemo = tier === Tier.None;

    return <>{isDemo && this.renderDemoButtons(layerSector)}</>;
  }
}

export default connect(mapStateToProps)(DemoStaticLayerPanel);
