import { useEffect } from "react";
import { Map } from "mapbox-gl";
import { Props } from "../NewProjectMap";
import { getParcelsInViewport } from "../Helpers/NewprojectMapHelpers";
import { SetParcelsInViewportFunction } from "state/newDevelopment/types";
import { deepClone } from "./hooksUtils";

export const useQueryViewportEffect = (
  props: Props,
  prevProps: React.MutableRefObject<Props | undefined>,
  map: React.MutableRefObject<Map | undefined>,
  setParcelsInViewport: SetParcelsInViewportFunction
) => {
  useEffect(() => {
    if (!map.current) return;
    if (!prevProps.current) {
      prevProps.current = deepClone(props);
      return;
    }

    const previousProps = deepClone(prevProps.current);
    prevProps.current = deepClone(props);

    if (
      map.current &&
      (props.queryViewport || (!previousProps.mapIsReady && props.mapIsReady))
    ) {
      setTimeout(() => {
        getParcelsInViewport(map, setParcelsInViewport);
      }, 100);
    }
  }, [props.queryViewport, props.mapIsReady]);
};
