import React from "react";
import { useSelector } from "react-redux";
import { GraphId } from "types/Graph";
import { pdfSelectors } from "state/pdf";
import PdfGraphRenderAndCapture from "./PdfGraphRenderAndCapture";

interface Props {
  graphId: GraphId;
}

const PdfGraphGenerator = (props: Props) => {
  const imageExists = useSelector(pdfSelectors.graphImageExistsSelectorFor(props.graphId));
  return imageExists ? null : <PdfGraphRenderAndCapture {...props} />;
}

export default PdfGraphGenerator
