import log from "loglevel";
import turf from "./turf";
import unitConversions from "./unitConversions";
import { randomString } from "./stringHelper";
import { MapStyleProperties } from "./mapbox/mapStyleProperties";
import Unit from "../types/Unit";
import { GeoJSONObject } from "@turf/turf";
import { jsonDeepCopy } from "./deepCopy";

const NEARBY_COORDINATES_EPSILON = 0.0002032;   // 8 inches in kilometers.
const COLINEAR_COORDINATES_EPSILON = 0.0002032; // 8 inches in kilometers.
const AREA_EPSILON = 5 // 5 square meters.

/**
 * "Clean" a feature by:
 * - Merging clusters of points into a single point.
 * - Removing points that are almost colinear with their predecessor and successor.
 * - Removing kinks if the feature has any.
 *
 * @param {Feature} feature - The GeoJSON feature whose coordinates to clean.
 * @returns {Feature | null} - A new feature generated from cleaning up the
 *  coordinates of the input feature, or null if there are no clean results.
 */
const cleanFeature = (feature) => {
  if (feature === null) return null;

  // Clone the given feature to keep this function pure.
  feature = turf.clone(feature);

  // Clean the coordinates for each polygon in the feature.
  let coordinates = turf.getCoords(feature);

  if (turf.getType(feature) === "Polygon") {
    cleanCoordinates(coordinates);
  } else if (turf.getType(feature) === "MultiPolygon") {
    for (let index = 0; index < coordinates.length; index++) {
      cleanCoordinates(coordinates[index]);
      if (coordinates[index].length === 0) {
        coordinates.splice(index, 1);
        index -= 1;
      }
    }
  }

  // In the case that both polygons and multi polygons had "bad" geometries they would end up with
  // empty arrays as coordinates. In this case we will return null instead of a feature with empty
  // coordinates array, we are doing it because turf library does not work well with that type of
  // geometries.
  return turf.getCoords(feature).length > 0
    ? feature
    : null;
}

/**
 * Clean the coordinates array of a GeoJSON polygon geometry.
 */
const cleanCoordinates = (coordinates) => {
  for (let index = 0; index < coordinates.length; index++) {
    let ring = coordinates[index];
    removeClustersOfCoordinates(ring);
    removeQuasiColinearCoordinates(ring);

    if (ring.length < 4) {
      if (index > 0) { // Remove degenerated holes.
        coordinates.splice(index, 1);
        index -= 1;
      } else { // Remove entire polygon if outer ring is degenerated.
        coordinates.splice(0);
      }
    } else if (index > 0) {
      let holeArea = turf.area(turf.polygon([ring]));
      if (holeArea < AREA_EPSILON) { // Remove holes with a "too small" area.
        coordinates.splice(index, 1);
        index -= 1;
      }
    }
  }
}

/**
 * Merge very tight clusters of points in a Linear Ring into a single point.
 */
const removeClustersOfCoordinates = (ring) => {
  ring.pop();

  for (let index = 0; index < ring.length - 1; index++) {
    removeClusterStartingAtIndex(ring, index);
  }

  // Removing possible cluster between last and first positions of the ring.
  let lastPoint = ring[ring.length - 1];
  let firstPoint = ring[0];
  if (turf.distance(lastPoint, firstPoint) < NEARBY_COORDINATES_EPSILON) {
    let middlePoint = turf.getCoord(turf.midpoint(lastPoint, firstPoint));
    ring[0] = middlePoint;
    ring[ring.length - 1] = middlePoint;
  } else {
    ring.push(ring[0]);
  }
}

/**
 * Remove the cluster of points starting at the given index and replace it with its centroid.
 */
const removeClusterStartingAtIndex = (ring, index) => {
  let cluster = [ring[index]];
  let nextIndex = index + 1;
  while (nextIndex < ring.length && turf.distance(ring[index], ring[nextIndex]) < NEARBY_COORDINATES_EPSILON) {
    cluster.push(ring[nextIndex]);
    nextIndex++;
  }

  let clusterCenter = turf.getCoord(turf.centroid(turf.multiPoint(cluster)));
  ring.splice(index, cluster.length, clusterCenter);
}

/**
 * Remove points that are almost colinear with their predecessor and successor.
 */
const removeQuasiColinearCoordinates = (ring) => {
  // Check initial point.
  while (initialPointIsQuasiColinear(ring) && ring.length >= 4) {
    ring.shift();
    ring.pop();
    ring.push(ring[0]);
  }

  // Check intermediate points.
  for (let index = 1; index < ring.length - 2; index++) {
    if (pointsAreQuasiColinear(ring[index - 1], ring[index], ring[index + 1])) {
      ring.splice(index, 1);
      index--;
    }
  }
}

/**
 * Verify if the initial point, its predecessor and its successor are almost colinear.
 */
const initialPointIsQuasiColinear = (ring) => {
  return pointsAreQuasiColinear(ring[ring.length - 2], ring[0], ring[1]);
}

/**
 * Verify if out of three given points one is "too" close to the segment defined by the other two.
 */
const pointsAreQuasiColinear = (pointA, pointB, pointC) => {
  let points = [pointA, pointB, pointC];
  for (let index = 0; index < points.length; index++) {
    let line = turf.lineString([points[(index + 1) % points.length], points[(index + 2) % points.length]]);
    if (turf.pointToLineDistance(points[index], line) < COLINEAR_COORDINATES_EPSILON) {
      return true;
    }
  }

  return false;
}

/**
 * Buffers each feature on a given array and compute its featuresUnion.
 */
const featuresUnionWithBuffering = (features): { featuresUnion, bufferedFeatures } => {
  // Buffer the polygons by less than half of the nearby coordinates epsilon is required for
  // all of the introduced round corners to be a cluster and get removed by cleanFeature.
  let bufferedFeatures = features.map((feature) => turf.buffer(feature, NEARBY_COORDINATES_EPSILON / 2));
  return { featuresUnion: featuresUnion(bufferedFeatures), bufferedFeatures };
}

/**
 * Compute the union of an array of features containing either Polygon or MultiPolygon geometries
 * and clean the resulting feature.
 */
const featuresUnion = (features) => {
  let polygonsArray: any[] = [];
  features.forEach(
    (outerFeature) => {
      try {
        turf.flattenEach(
          outerFeature,
          (innerFeature) => {
            // Filter out polygons with empty coordinates array as turf.union do not accept them.
            if (turf.getCoords(innerFeature).length > 0) {
              polygonsArray.push(innerFeature);
            }
          }
        )
      } catch (error) {
        log.warn(error, outerFeature);
      }
    }
  );

  try {
    if (polygonsArray.length > 0) {
      let union = turf.union(...polygonsArray);
      return cleanFeature(union);
    }
    return null;
  } catch (error) {
    log.warn(error, polygonsArray);
    return null;
  }
}

/**
 * Calculate the perimeter of a GeoJSON object in meters.
 */
const perimeter = (feature) => {
  let polygonLengthInKilometers = turf.length(feature);
  return unitConversions.convert(polygonLengthInKilometers, Unit.Type.KiloMeters, Unit.Type.Meters);
}

/**
 * Clean polygons coming form the mapbox drawing tool.
 */
const cleanDrawnPolygons = (rawDrawnPolygons) => {
  let validPolygons = new Array<any>();
  rawDrawnPolygons.forEach((currentFeature) => {
    try {
      if (turf.getCoords(currentFeature)[0].length > 3) {
        let kinks = turf.kinks(currentFeature);

        if (kinks.features.length > 0) {
          let unkinkedPolygons = turf.unkinkPolygon(currentFeature);

          turf.featureEach(unkinkedPolygons, (unkinkedPolygon) => {
            unkinkedPolygon.properties = { [MapStyleProperties.RawParcelFieldId.Id]: randomString(32) };
            validPolygons.push(unkinkedPolygon);
          });
        } else {
          currentFeature.properties = {
            ...currentFeature.properties,
            [MapStyleProperties.RawParcelFieldId.Id]: currentFeature.id
          }

          validPolygons.push(currentFeature);
        }
      }
    } catch (error) {
      log.warn(error);
    }
  });
  return validPolygons;
}

/**
 *
 * Clone feature and update the position of the feature.
 *
 * @param feature
 */
const clone = (feature: GeoJSONObject, distance: number) => {
  let featureClone = jsonDeepCopy(feature);
  let idLength = featureClone.properties[MapStyleProperties.RawParcelFieldId.Id].length;
  const newId = randomString(idLength);
  featureClone.properties[MapStyleProperties.RawParcelFieldId.Id] = newId;
  featureClone.id = newId;

  featureClone = turf.transformTranslate(featureClone, distance, 90)

  return featureClone;
}

export default {
  cleanFeature,
  featuresUnion,
  featuresUnionWithBuffering,
  cleanDrawnPolygons,
  perimeter,
  clone,
}
