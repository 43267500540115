import { Feature } from "geojson";
import { Getter } from "../../../types/Config";
import Format from "../../../types/Format";
import Unit from "../../../types/Unit";
import parcelAccessors from "../../../utils/parcel/parcelAccessors";
import unitConversions from "../../../utils/unitConversions";
import valueFormatter from "../../../utils/valueFormatter";

/**
 * Display existing building area in the assumptions panel and the assumptions section in the PDF.
 */
const forExistingBuildingAreaInAssumptions: Getter = (
  existingBuildingArea: any,
  formatOptions: Format.Options | undefined,
  formattingMap: Format.FormattingMap | undefined,
  unitTarget: Unit.Type | undefined,
  unitIsInverse: boolean | undefined,
  parcel: Feature
) => {
  if (existingBuildingArea === 0) {
    return formatOptions?.hidden
      ? valueFormatter.format(existingBuildingArea, formatOptions) // So we can get the placeholder for hidden values.
      : "Vacant";
  } else if (existingBuildingArea) {
    const convertedValue = unitTarget
      ? unitConversions.convertFromBase(existingBuildingArea, unitTarget, unitIsInverse)
      : existingBuildingArea;

    return valueFormatter.format(convertedValue, formatOptions);
  }
};

/**
 * Display existing building area in the property info panel and the property info section in the PDF.
 */
const forExistingBuildingAreaInPropertyInfo: Getter = (
  value: any,
  formatOptions: Format.Options | undefined,
  formattingMap: Format.FormattingMap | undefined,
  unitTarget: Unit.Type | undefined,
  unitIsInverse: boolean | undefined,
  parcel: Feature
) => {
  if (!unitTarget) return null; //TODO Look into this

  let existingBuildingArea = parcelAccessors.getExistingStructureArea(parcel);
  if (existingBuildingArea === 0) {
    if (formatOptions?.hidden) return valueFormatter.format("", formatOptions); // So that we can use the placeholder for the hidden values.
    return "Vacant";
  }
  if (!existingBuildingArea) return "No Data";

  let area = unitConversions.convertFromBase(existingBuildingArea, unitTarget, unitIsInverse);
  return valueFormatter.format(area, formatOptions);
};

/**
 * Display existing building height in the property info panel
 */
const forExistingBuildingHeightInPropertyInfo: Getter = (
  value: any,
  formatOptions: Format.Options | undefined,
  formattingMap: Format.FormattingMap | undefined,
  unitTarget: Unit.Type | undefined,
  unitIsInverse: boolean | undefined,
  parcel: Feature
) => {
  if (!unitTarget) return null; //TODO Look into this

  let existingBuildingHeight = parcelAccessors.getExistingBuildingHeight(parcel);
  if (existingBuildingHeight === 0 || !existingBuildingHeight) return null;
  let height = unitConversions.convertFromBase(existingBuildingHeight, unitTarget, unitIsInverse);
  return valueFormatter.format(height, formatOptions);
};

/**
 * Display existing building area (coming from the open source layer `usa-buildings-query`) in the property info panel.
 */
const forExistingBuildingAreaOpenSourceInPropertyInfo: Getter = (
  value: any,
  formatOptions: Format.Options | undefined,
  formattingMap: Format.FormattingMap | undefined,
  unitTarget: Unit.Type | undefined,
  unitIsInverse: boolean | undefined,
  parcel: Feature
) => {
  if (!unitTarget) return null; //TODO Look into this

  let existingBuildingArea = parcelAccessors.getExistingStructureAreaOpenSource(parcel);
  if (existingBuildingArea === 0) {
    if (formatOptions?.hidden) return valueFormatter.format("", formatOptions); // So that we can use the placeholder for the hidden values.
    return "Vacant";
  }
  if (!existingBuildingArea) return "No Data";

  let area = unitConversions.convertFromBase(existingBuildingArea, unitTarget, unitIsInverse);
  return valueFormatter.format(area, formatOptions);
};

/**
 * Generic getter to get the value for a specific variable ID passing through the pipeline of formatting and
 * converting the value based on the configuration parameters.
 */
const generic: Getter = (
  value: any,
  formatOptions: Format.Options | undefined,
  formattingMap: Format.FormattingMap | undefined,
  unitTarget: Unit.Type | undefined,
  unitIsInverse: boolean | undefined,
  parcel: Feature
) => {
  let array;
  if (Array.isArray(value)) array = value;

  let formattedValue;
  if (array) {
    formattedValue = formattingMap
      ? array.map(formattingMap).map((item) => valueFormatter.format(item.value, item.formatOptions))
      : array;
  } else {
    const convertedValue = unitTarget ? unitConversions.convertFromBase(value, unitTarget, unitIsInverse) : value;

    formattedValue = formatOptions ? valueFormatter.format(convertedValue, formatOptions) : convertedValue;
  }

  return formattedValue;
};

export default {
  forExistingBuildingAreaInAssumptions,
  forExistingBuildingAreaInPropertyInfo,
  forExistingBuildingAreaOpenSourceInPropertyInfo,
  forExistingBuildingHeightInPropertyInfo,
  generic,
};
