import React from "react"
import { Layer, Source } from "react-mapbox-gl"
import { connect } from "react-redux"
import { layersSelectors } from "../../../../../state/layers"
import { subscriptionSelectors } from "../../../../../state/subscription"
import { Tier } from "../../../../../types/Tier"
import authentication from "../../../../../utils/authentication"
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties"

const mapStateToProps = (state) => ({
  hotelFeatures: layersSelectors.selectHotelAverageDailyRateFeatures(state),
  tier: subscriptionSelectors.getTier(state),
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const LAYER_FILL_PAINT = {
  "fill-color": "hsla(0, 0%, 0%, 0)",
  "fill-opacity": 0
}

const LAYER_LAYOUT = {
  "visibility": "none",
}

class ParcelsZoningQueryFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    if(!authentication.getCurrentUser() || this.props.tier !== Tier.Pro) return null;

    return (
      <>
        <Source
          id={MapStyleProperties.SourceId.ParcelsZoningQuery}
          tileJsonSource={{
            type: "vector",
            tiles: MapStyleProperties.SOURCE_ID_TO_URL_MAP[MapStyleProperties.SourceId.ParcelsZoningQuery]
          }}
        />
        <Layer
          id={MapStyleProperties.LayerId.UsaParcelsZoningQuery}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.SourceId.ParcelsZoningQuery}
          sourceLayer={MapStyleProperties.SourceLayerId.ParcelsWithZoningQuery}
          paint={LAYER_FILL_PAINT}
          layout={LAYER_LAYOUT}
          before={MapStyleProperties.LayerId.UsaParcelsQuery}
        />
      </>
    )
  }
}

export default connect(
  mapStateToProps
)(ParcelsZoningQueryFeatureLayer);
