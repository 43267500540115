import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";
import { Tier } from "../../types/Tier";
import subscriptionHelper from "../utils/subscriptionHelper";
import { userActionTypes } from "../user";
import { subscriptionManagementActions } from "../ui/subscriptionManagement";

export interface SubscriptionState {
  planId?: Chargebee.PlanId;
  id?: string;
  status?: Chargebee.SubscriptionStatus;
  creditCard?: Chargebee.Card;
  memberSince?: number;
  currentTermStart?: number;
  currentTermEnd?: number;
  planPrices: Chargebee.PlanPrices;
  tier: Tier;
  userIdToBeCancelled?: string;
  isPaymentStarted?: boolean;
  isPaymentSuccess?: boolean;
  isError?: boolean;
  errorMessage?: undefined;
}

const INITIAL_STATE: SubscriptionState = {
  planPrices: {},
  tier: Tier.None,
};

const reducer = (previousState = INITIAL_STATE, action): SubscriptionState => {
  switch (action.type) {
    case userActionTypes.CREATE_SUCCESS:
    case actionTypes.CREATE_SUCCESS:
    case userActionTypes.EMAIL_VERIFIED:
    case actionTypes.LOAD_SUCCESS: return loadSuccess(previousState, action.payload);
    case actionTypes.SET_PLAN_PRICES: return setPlanPrices(previousState, action.payload);
    case actionTypes.LOAD_CREDIT_CARD_SUCCESS: return setCard(previousState, action.payload);
    case actionTypes.SET_PAYMENT_ERROR: return setPaymentError(previousState, action.payload);
    case actionTypes.PAYMENT_PROCESS_START: return setPaymentStart(previousState);
    case actionTypes.PAYMENT_PROCESS_SUCCESS: return setPaymentSuccess(previousState);
    case actionTypes.RESET_ERROR_FLAGS: return resetErrorFlags(previousState);
    case subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen.toString(): return setUserIdToBeCancelled(previousState, action);
    default: return previousState;
  }
}

/**
 * See `subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen` action creator.
 *
 * Returns state with updated user id to be cancelled.
 */
const setUserIdToBeCancelled = (previousState: SubscriptionState, action: ReturnType<typeof subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen>): SubscriptionState => {
  return {
    ...previousState,
    userIdToBeCancelled: action.payload.userIdToBeCancelled,
  }
}

/**
 * See `loadSuccess` action creator.
 *
 * Returns state with updated subscription information.
 */
const loadSuccess = (previousState: SubscriptionState, payload): SubscriptionState => {
  let id, planId, memberSince, currentTermStart, currentTermEnd;
  let status = Chargebee.SubscriptionStatus.None;

  if (payload.subscription) {
    id = payload.subscription.id;
    status = payload.subscription.status;
    planId = payload.subscription.plan_id;
    memberSince = payload.subscription.created_at * 1000;
    currentTermEnd = payload.subscription.current_term_end * 1000;
    currentTermStart = payload.subscription.current_term_start * 1000;
  }

  return {
    ...previousState,
    planId,
    id,
    status,
    memberSince,
    currentTermStart,
    currentTermEnd,
    tier: subscriptionHelper.getTier(payload.subscription),
  };
}

/**
 * See `setPlanPrices` action creator.
 *
 * Returns state with updated plan prices information.
 */
const setPlanPrices = (previousState: SubscriptionState, payload): SubscriptionState => {
  return {
    ...previousState,
    planPrices: payload.planPrices,
  };
}

/**
 * See `setPlanPrices` action creator.
 *
 * Returns state with updated credit card information.
 */
const setCard = (previousState: SubscriptionState, payload): SubscriptionState => {
  return {
    ...previousState,
    creditCard: payload.creditCard,
  };
}

/**
 * See `setPaymentError` action creator.
 *
 * Returns state with the paymentError flag activated and the errorMessage information updated.
 */
const setPaymentError = (previousState: SubscriptionState, payload): SubscriptionState => {
  return {
    ...previousState,
    isError: true,
    errorMessage: payload.errorMessage,
  };
}

/**
 * See `setPaymentStart` action creator.
 *
 * Returns state with the paymentStarted flag activated.
 */
const setPaymentStart = (previousState: SubscriptionState): SubscriptionState => {
  return {
    ...previousState,
    isPaymentStarted: true,
  };
}

/**
 * See `setPaymentSuccess` action creator.
 *
 * Returns state with the paymentSuccess flag activated.
 */
const setPaymentSuccess = (previousState: SubscriptionState): SubscriptionState => {
  return {
    ...previousState,
    isPaymentSuccess: true,
  };
}

/**
 * See `resetErrorFlags` action creator.
 *
 * Returns state with all the error handling flags deactivated.
 */
const resetErrorFlags = (previousState: SubscriptionState): SubscriptionState => {
  return {
    ...previousState,
    isPaymentStarted: undefined,
    isPaymentSuccess: undefined,
    isError: undefined,
    errorMessage: undefined
  };
}

export default reducer;
