import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class IndianReservationsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.IndianReservations}
        classes="indian-reservations"
        legendRows={[
          <LegendRow
            color="#cc98f3"
            text="Alaska Native Village"
          />,
          <LegendRow
            color="#75eff8"
            text="American Indian Reservation"
          />,
          <LegendRow
            color="#fea59e"
            text="Federally Recognized Tribal Entity"
          />,
          <LegendRow
            color="#ccf2af"
            text="Other"
          />,
        ]}
      />
    );
  }
}

export default IndianReservationsLegend;
