import React from "react";
import { connect } from "react-redux";
import { subscriptionInvitationsSelectors } from "../../../../state/subscriptionInvitations";
import { SubscriptionInvitationStatus } from "../../../../types/SubscriptionInvitations";
import authentication from "../../../../utils/authentication";
import InvitationsPopup from "./InvitationsPopup";

const mapStateToProps = (state) => {
  return {
    invitations: subscriptionInvitationsSelectors.getSubscriptionInvitations(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

interface State {
  invitationsAreOpen: boolean;
}

class InvitationsButton extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      invitationsAreOpen: false,
    }
  }

  /**
   * Toggle invitationsAreOpen state.
   */
  toggleInvitationsAreOpen = () => {
    this.setState({ invitationsAreOpen: !this.state.invitationsAreOpen });
  }

  render() {
    const { invitations } = this.props;
    const pendingInvitations = Object.keys(invitations).filter((ownerId) => invitations[ownerId].status === SubscriptionInvitationStatus.Pending).length;
    if (!authentication.isUserAuthenticated() || pendingInvitations === 0) return null;

    const { invitationsAreOpen } = this.state;
    return (
      <>
        <div className={`component--button invitations ${invitationsAreOpen ? "active" : ""}`} onClick={this.toggleInvitationsAreOpen}>
          <div className="number">{pendingInvitations}</div>
          <div className="button" />
          <div className="text">Invitations</div>
        </div>
        {invitationsAreOpen && <InvitationsPopup onStateChange={this.toggleInvitationsAreOpen} />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(InvitationsButton);
