const INVITE_START = "subscriptionInvitations/INVITE_START";
const INVITE_SUCCESS = "subscriptionInvitations/INVITE_SUCCESS";
const INVITE_FAILURE = "subscriptionInvitations/INVITE_FAILURE";
const RESEND_START = "subscriptionInvitations/RESEND_START";
const CANCEL_START = "subscriptionInvitations/CANCEL_START";
const CANCEL_SUCCESS = "subscriptionInvitations/CANCEL_SUCCESS";
const LOAD_START = "subscriptionInvitations/LOAD_START";
const LOAD_SUCCESS = "subscriptionInvitations/LOAD_SUCCESS";
const ACCEPT_START = "subscriptionInvitations/ACCEPT_START";
const ACCEPT_SUCCESS = "subscriptionInvitations/ACCEPT_SUCCESS";
const REJECT_START = "subscriptionInvitations/REJECT_START";
const REJECT_SUCCESS = "subscriptionInvitations/REJECT_SUCCESS";
const DELETE_START = "subscriptionInvitations/DELETE_START";
const DELETE_SUCCESS = "subscriptionInvitations/DELETE_SUCCESS";
const UPDATE_MANAGED_SUBSCRIPTION_STATUS = "subscriptionInvitations/UPDATE_MANAGED_SUBSCRIPTION_STATUS";

export default {
  INVITE_START,
  INVITE_SUCCESS,
  INVITE_FAILURE,
  RESEND_START,
  CANCEL_START,
  CANCEL_SUCCESS,
  LOAD_START,
  LOAD_SUCCESS,
  ACCEPT_START,
  ACCEPT_SUCCESS,
  REJECT_START,
  REJECT_SUCCESS,
  DELETE_START,
  DELETE_SUCCESS,
  UPDATE_MANAGED_SUBSCRIPTION_STATUS,
};
