import React from "react";
import BusySpinner from "../BusySpinner";

const SearchBarLoadingComponent = () => {
  return (
      <div className="search-loading-component">
        <BusySpinner />
        <p className="search-loading-label">Loading Map Layers...</p>
      </div>
  )
}

export default SearchBarLoadingComponent;
