import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import { UserStatus } from "../../../../types/UserStatus";
import authentication from "../../../../utils/authentication";
import userHelper from "../../../../utils/userHelper";
import SignUp from "./SignUp";
import VerifyEmail from "./VerifyEmail";
import wrapComponentWithPopup from "../../../sharedComponents/wrapComponentWithPopup";
import Subscribe from "./Subscribe";
import Welcome from "./Welcome";
import { PopupProps } from "../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import { UserType } from "../../../sharedComponents/TiersPanel/SubscribeButton/SubscribeButton";
import { userActions } from "../../../../state/user";

const HEADER_TEXT_MAP = {
  [UserStatus.NotAuthenticated]: "Sign up",
  [UserStatus.Authenticated]: "Email Verification",
  [UserStatus.Verified]: "Checkout",
  [UserStatus.Subscribed]: "Welcome To Deepblocks",
}

const AT_LEAST_AUTHENTICATED = [UserStatus.Authenticated, UserStatus.Verified];

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state),
  }
}

const mapDispatchToProps = {
  emailVerified: userActions.emailVerified,
}

interface OwnProps {
  subscriptionPlanId: Chargebee.PlanId;
  userType: UserType;
}

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps & PopupProps & DispatchProps;

interface State {
  userIsAuthenticated: boolean | null;
  userIsVerified: boolean;
}

class SignUpFlowPopup extends React.PureComponent<Props, State>  {
  unsubscribeFromAuthenticationStateChange?: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      // This value will be null while loading the user authentication state
      // and it will be true or false once the state is loaded.
      userIsAuthenticated: null,
      userIsVerified: false,
    }
  }

  /**
   * Adds listener to authentication.onStateChange
   * to update the state of the component when the
   * authentication state change.
   */
  componentDidMount() {
    this.unsubscribeFromAuthenticationStateChange = authentication.onStateChange(this.updateState);
  }

  /**
   * Unsubscribe from authentication onStateChange when component will unmount.
   */
  componentWillUnmount() {
    if (this.unsubscribeFromAuthenticationStateChange) {
      this.unsubscribeFromAuthenticationStateChange();
    }
  }

  /**
   * Updates userIsAuthenticated and userIsVerified states when the
   * authentication state changes.
   */
  updateState = (user) => {
    let userIsAuthenticated = (user !== null);
    this.setState({
      userIsAuthenticated: userIsAuthenticated,
      userIsVerified: userIsAuthenticated && user.emailVerified,
    });
  }

  /**
   * Set user is verified flag.
   */
  setEmailVerified = () => {
    this.props.emailVerified();
    this.setState({ userIsVerified: true });
  }

  /**
   * Render header section.
   */
  renderHeader = () => {
    let userStatus = this.getUserStatus();

    return <div className="header-container">{HEADER_TEXT_MAP[userStatus]}</div>;
  }

  /**
   * Get the last label.
   */
  getFinalLabel = () => {
    if (this.props.subscriptionPlanId === Chargebee.PlanId.Free) return "Welcome";
    return (this.props.userType === UserType.Team) ? "Subscription" : "Checkout";
  }

  /**
   * Render navigation icons.
   */
  renderIcons = () => {
    let userStatus = this.getUserStatus();
    const userIsManager = this.props.userType === UserType.Team;
    if (userStatus === UserStatus.Subscribed) return null;

    return (
      <div className="navigation">
        <div className="icon-wrapper">
          <div className="section active">
            <div className="icon sign-up " />
          </div>
          <div className={`section ${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`}>
            <div className={`line-divider ${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`} />
            <div className="icon email-verification " />
          </div>
          <div className={`section ${userStatus === UserStatus.Verified ? "active" : ""}`}>
            <div className={`line-divider ${userStatus === UserStatus.Verified ? "active" : ""}`} />
            <div className="icon checkout" />
          </div>
        </div>
        <div className="label-wrapper">
          <p className="active">Sign Up</p>
          <p className={`${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`}>Email Verification</p>
          <p className={`${userStatus === UserStatus.Verified ? "active" : ""} ${userIsManager ? "right-adjust" : ""}`}>
            {this.getFinalLabel()}
          </p>
        </div>
      </div>
    );
  }

  /**
   * Get current user status.
   */
  getUserStatus = () => {
    const { userIsAuthenticated, userIsVerified } = this.state;
    const { subscriptionStatus, subscriptionPlanId, userType } = this.props;

    if (userIsVerified && userType === UserType.Team) return UserStatus.Subscribed;

    const userStatus = userHelper.getUserStatus(userIsAuthenticated, userIsVerified, subscriptionStatus, subscriptionPlanId);
    return userStatus;
  }

  /**
   * Render content based on current user status.
   */
  renderContent = () => {
    const { subscriptionPlanId, userType, closePopup } = this.props;
    const userStatus = this.getUserStatus();

    switch (userStatus) {
      case UserStatus.NotAuthenticated: return <SignUp closePopup={closePopup} />;
      case UserStatus.Authenticated: return <VerifyEmail onEmailVerified={this.setEmailVerified} />;
      case UserStatus.Verified: return <Subscribe subscriptionPlanId={subscriptionPlanId} />;
      case UserStatus.Subscribed: return <Welcome closePopup={closePopup} userType={userType} />;
      default: return null;
    }
  }

  render() {
    return (
      <div className="component--sign-up-flow-popup">
        {this.renderHeader()}
        {this.renderIcons()}
        {this.renderContent()}
      </div>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUpFlowPopup)
);
