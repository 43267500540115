import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { thumbnailSelectors } from "../../../../../../state/thumbnails/thumbnail";
import { userSelectors } from "../../../../../../state/user";
import { Path } from "../../../../../../types/Path";
import analytics from "../../../../../../utils/analytics";
import DeleteDevelopment from "../../../../../sharedComponents/DeleteDevelopment";
import ReturnOnCost from "../../../../../sharedComponents/ReturnOnCost";
import ShareProject from "../../../../../sharedComponents/ShareProject";
import developmentUiHelper from "../../../../../utils/developmentUiHelper";
import Thumbnail from "./Thumbnail";

const mapStateToProps = (state, ownProps) => {
  return {
    thumbnailUrl: thumbnailSelectors.getImageUrl(state, ownProps.development.id),
  };
};

type UserProject = ReturnType<typeof userSelectors.getDevelopments>[0];

interface OwnProps {
  development: UserProject;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const DevelopmentCard = (props: Props) => {
  const [redirectToExplorer, setRedirectToExplorer] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * Load the development and redirect to the explorer page.
   */
  const loadDevelopment = async () => {
    analytics.trackOpenProject(props.development.id);
    setRedirectToExplorer(true);
  };

  if (redirectToExplorer) {
    navigate(`${Path.Explorer}/${props.development.id}`, { replace: true });
  }

  const { development } = props;
  const developmentName = developmentUiHelper.getDisplayName(development.name);

  return (
    <div className="component--development-card">
      <Thumbnail developmentId={development.id} onClick={loadDevelopment} />
      <ReturnOnCost
        saleReturnOnCost={development.saleReturnOnCost}
        leaseUsesReturnOnCost={development.leaseUsesReturnOnCost}
        projectIsForSale={development.isForSale}
      />

      {development.isFromShared && <div className="shared">Shared with me</div>}
      <div className="card-content">
        <div className="development-name" onClick={loadDevelopment}>
          {developmentName}
        </div>
        <div className="last-opened">{developmentUiHelper.getLastModifiedString(development.timeModified)}</div>

        <div className="buttons-container">
          <ShareProject developmentId={development.id} developmentName={developmentName} text="Share" />

          <DeleteDevelopment developmentId={development.id} developmentName={developmentName} showText />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(DevelopmentCard);
