enum LayerGroup {
  BuildableAreaRatio = "buildableAreaRatio",
  HistoricBuildings = "historicBuildings",
  Businesses = "businesses",
  Buildings = "buildings",
  CapRates = "capRates",
  CensusTracts = "censusTracts",
  EducationFacilities = "educationFacilities",
  HotelAverageDailyRate = "hotelAverageDailyRate",
  Hotels = "hotels",
  Hospitals = "hospitals",
  IndustrialRate = "industrialRate",
  MultifamilyRent = "multifamilyRent",
  OfficeRate = "officeRate",
  OpportunityZone = "opportunityZone",
  ReitsProjects = "reitsProjects",
  RetailRate = "retailRate",
  RoadType = "roadType",
  SmartSearchResults = "smartSearchResults",
  TrafficDailyAverage2019 = "dailyAverageTraffic2019",
  Zoning = "zoning",
  FederalOwnedLands = "federalOwnedLands",
  NaturalAndMarineProtectedLands = "naturalAndMarineProtectedLands",
  Forests = "forests",
  HazardousSites = "hazardousSites",
  Watersheds = "watersheds",
  HistoricSites = "historicSites",
  Parks = "parks",
  IndianReservations = "indianReservations",
  Wetlands = "wetlands",
  BayAreaHousingSites = "bayAreaHousingSites",
  LIHTC_ExistingPropertyInventory = "lihtc_ExistingPropertyInventory",
  DifficultDevelopmentAreas = "difficultDevelopmentAreas",
  QualifiedCensusTracts = "qualifiedCensusTracts",
  CityBoundaries = "cityBoundaries",
  TransitOrientedCommunity = "transitOrientedCommunityFill",
  CountyBoundaries = "countyBoundaries",
  IndustrialSites = "industrialSites",
  MultifamilySites = "multifamilySites",
}

export default LayerGroup;
