import { BuildingUse } from "../../../../../types/BuildingUse"

const tabConfig = {
  [BuildingUse.Condo]: {
    class: "condo",
    label: "condo",
  },
  [BuildingUse.Hotel]: {
    class: "hotel",
    label: "hotel"
  },
  [BuildingUse.Industrial]: {
    class: "industrial",
    label: "industrial",
  },
  [BuildingUse.Multifamily]: {
    class: "multifamily",
    label: "multifamily",
  },
  [BuildingUse.Office]: {
    class: "office",
    label: "office",
  },
  [BuildingUse.Retail]: {
    class: "retail",
    label: "retail",
  },
  [BuildingUse.Parking]: {
    class: "parking",
    label: "parking",
  },
}

export default tabConfig
