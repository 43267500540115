import * as firebase from "firebase/app";
import "firebase/firestore";
import Firebase from "../../types/Service/Firebase";
import { Chargebee } from "../../types/Service";
import { ManagedSubscriptionStatus, SubscriptionInvitationStatus } from "../../types/SubscriptionInvitations";
import databaseHelper from "./utils/databaseHelper";
import chargebee from "../functions/chargebee";
import DocumentNotFoundError from "../../errors/DocumentNotFoundError";
import userThumbnails from "../storage/developments/userThumbnails";
import users from "./users";
import subscriptions from "./subscriptions";
import authentication from "../authentication";
import { subscriptionInvitationsActions } from "../../state/subscriptionInvitations";
import { Invitation, ManagedSubscription, ManagedSubscriptions } from "../../state/subscriptionInvitations/reducers";

let documentListener = {};
let initializedListeners = {};

/**
 * This listener is meant to listen for changes in the database for `userSession\{userId}` documents.
 * See: https://firebase.google.com/docs/firestore/query-data/listen
 */
const invitationsSnapshotListener = (dispatch) => async (snapshot) => {
  if (!initializedListeners[snapshot.id]) {
    initializedListeners[snapshot.id] = true;
    return;
  }

  const currentUser = authentication.getCurrentUser();
  if (!currentUser) return;

  const userBeingManagedId = snapshot.id;

  const data = snapshot.data();
  const userInvitation: Invitation = data.invitations[currentUser.uid];
  if (!userInvitation) return;

  let subscriptionData: Partial<ManagedSubscription> = {};
  const subscriptionDocument = await subscriptions.read(userBeingManagedId);
  if (subscriptionDocument && subscriptionDocument.ownerId === currentUser.uid && subscriptionDocument.subscriptionId) {
    const subscription: Chargebee.Subscription = await chargebee.getSubscription(subscriptionDocument.subscriptionId);

    if (subscription) {
      if (userInvitation.status === SubscriptionInvitationStatus.Accepted) {
        userInvitation.status = (subscription.status === Chargebee.SubscriptionStatus.NonRenewing)
          ? ManagedSubscriptionStatus.Expiring
          : subscription.status;
      }

      subscriptionData.subscriptionId = subscription.id;
      subscriptionData.currentTermEnd = subscription.current_term_end * 1000;
      subscriptionData.currentTermStart = subscription.current_term_start * 1000;
      subscriptionData.memberSince = (subscription.created_at * 1000);
    }
  }

  dispatch(subscriptionInvitationsActions.updateManagedSubscriptionStatus(
    userInvitation.status,
    userBeingManagedId,
    subscriptionData
  ));
}

/**
 * A boolean indicating whether there is currently an active document listener.
 */
const isListening = (userId: string): boolean => {
  return Boolean(documentListener[userId]);
}

/**
 * Start listening to invitation updates for the given user.
 */
const startListening = (dispatch, userId: string) => {
  // Make sure there are no redundant listeners.
  if (isListening(userId)) return;

  let invitationsDocumentReference = getInvitationsReference(userId);
  documentListener[userId] = invitationsDocumentReference.onSnapshot(invitationsSnapshotListener(dispatch));
}

/**
 * Stop listening to invitation updates for the given user.
 */
const stopListening = () => {
  for (const userId of Object.keys(documentListener)) {
    if (isListening(userId)) {
      documentListener[userId]();
      delete documentListener[userId];
    }
  }
}

/**
 * Create invitations document for the given user id.
 */
const create = async (userId: string, invitationDocument: object) => {
  const invitationsReference = getInvitationsReference(userId);
  await databaseHelper.createDocument(invitationsReference, invitationDocument);
}

/**
 * Read and return invitations document.
 */
const read = async (userId: string): Promise<any> => {
  try {
    const invitationsReference = getInvitationsReference(userId);
    return await databaseHelper.getDocument(invitationsReference);
  } catch (error) {
    if (!(error instanceof DocumentNotFoundError)) {
      console.warn(error);
    }
  }

  return {};
}

/**
 * Update an invitations document.
 */
const update = async (userId: string, invitationDocument: object) => {
  const invitationsReference = getInvitationsReference(userId);
  await databaseHelper.updateDocument(invitationsReference, invitationDocument);
}

/**
 * Get the list of users managed by the owner.
 */
const listManagedUsersForOwner = async (ownerId: string) => {
  const invitationDocuments = await firebase
    .firestore()
    .collection("invitations")
    .orderBy(`invitations.${ownerId}.userName`, "asc").get();

  return invitationDocuments.docs.map((doc) => doc.id);
}

/**
 * Get the subscriptions managed by the user.
 */
const getManagedSubscriptions = async (ownerId: string) => {
  let managedSubscriptions: ManagedSubscriptions = {};
  let managedSubscriptionsImages: any = {};

  const managedUsers = await listManagedUsersForOwner(ownerId);

  if (!managedUsers) return {
    managedSubscriptions,
    managedSubscriptionsImages,
  };

  for (const userId of managedUsers) {
    const invitations = await read(userId);
    if (!invitations || !invitations.invitations || !invitations.invitations[ownerId]) {
      console.warn(`No invitation exists for user ID:${userId}`)
      continue;
    }

    const userInvitation: Invitation = invitations.invitations[ownerId];

    const userDocument = await users.read(userId);
    if (!userDocument) continue;

    managedSubscriptions[userId] = {
      name: userInvitation.userName,
      email: userDocument.email || userInvitation.userEmail,
      status: userInvitation.status,
      memberSince: userDocument.timeCreated,
      discountCode: userInvitation.discountCode,
      planId: userInvitation.planId,
      subscriptionId: "",
    };

    let subscriptionDocument = await subscriptions.read(userId);
    if (subscriptionDocument && subscriptionDocument.ownerId === ownerId) {
      const subscription: Chargebee.Subscription = await chargebee.getSubscription(subscriptionDocument.subscriptionId);

      if (managedSubscriptions[userId].status === SubscriptionInvitationStatus.Accepted) {
        managedSubscriptions[userId].status = (subscription.status === Chargebee.SubscriptionStatus.NonRenewing)
          ? ManagedSubscriptionStatus.Expiring
          : subscription.status;
      }

      managedSubscriptions[userId].subscriptionId = subscription.id;
      managedSubscriptions[userId].currentTermEnd = subscription.current_term_end * 1000;
      managedSubscriptions[userId].currentTermStart = subscription.current_term_start * 1000;
      managedSubscriptions[userId].memberSince = (subscription.created_at * 1000) || managedSubscriptions[userId].memberSince;
    } else {
      managedSubscriptions[userId].status = managedSubscriptions[userId].status === ManagedSubscriptionStatus.Pending
        ? managedSubscriptions[userId].status
        : ManagedSubscriptionStatus.Cancelled;
    }

    try {
      const imageUrl = await userThumbnails.download(userId);
      managedSubscriptionsImages[userId] = imageUrl;
    } catch (error) {
      console.warn(error);
      managedSubscriptionsImages[userId] = null;
    }
  }

  return {
    managedSubscriptions,
    managedSubscriptionsImages,
  };
}

/**
 * Remove a subscription invitation.
 */
const remove = async (ownerId: string, userId: string) => {
  let managedSubscriptions: any = {};
  let managedSubscriptionsImages: any = {};

  let userInvitationsDocument = await read(userId);
  if (!userInvitationsDocument || !userInvitationsDocument.invitations) {
    return {
      managedSubscriptions,
      managedSubscriptionsImages,
    };
  }

  delete userInvitationsDocument.invitations[ownerId];
  const invitationsReference = firebase.firestore().doc(`invitations/${userId}`);
  const updateInvitations = {
    type: Firebase.OperationType.Update,
    documentReference: invitationsReference,
    data: userInvitationsDocument,
  }

  await databaseHelper.batchWrite([updateInvitations]);
  return await getManagedSubscriptions(ownerId);
}

/**
 * Return reference to the invitations collection.
 */
const getInvitationsReference = (userId) => {
  return firebase.firestore().doc(`invitations/${userId}`);
}

export default {
  create,
  read,
  update,
  remove,
  getManagedSubscriptions,
  startListening,
  stopListening,
}
