import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../rootReducer";
import { GraphsState } from "./reducers";

/**
 * Select the graphs state.
 */
const getGraphsState = (state: AppState): GraphsState => {
  return state.graphs;
}

/**
 * Select the graph data sources lookup table.
 */
const getGraphDataSources = createSelector(
  getGraphsState,
  (graphsState) => graphsState.graphDataSources
)

/**
 * Select an unsorted array of the graph data sources from the lookup table.
 */
const getGraphDataSourceList = createSelector(
  getGraphDataSources,
  (graphDataSources) => Object.values(graphDataSources)
)

/**
 * Select the highlighted graph data source.
 */
const getHighlightedGraphDataSource = createSelector(
  getGraphsState,
  (graphsState) => graphsState.highlightedGraphDataSource
)

export default {
  getGraphDataSources,
  getGraphDataSourceList,
  getHighlightedGraphDataSource,
}
