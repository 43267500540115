import React from "react";
import addressIcon from "../../../../../../../assets/images/pdf-icon-property-info.png";
import PdfContext from "../../../PdfContext";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    height: 30,
    width: 180,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addressIcon: {
    height: 15,
    marginRight: 10,
  },
  addressContainer: {
    height: "100%",
    flex: 1,
    color: "#FFFFFF",
  },
  addressText: {
    fontFamily: "Roboto Bold",
    fontSize: 9,
    textTransform: "uppercase",
    letterSpacing: 0.25,
    margin: "auto 0",
  },
  addressSecondaryText: {
    fontFamily: "Roboto Bold",
    fontSize: 7,
    textTransform: "uppercase",
    letterSpacing: 0.25,
    marginTop: 6,
  },
});

interface Props {
  textColor: string;
}

const AddressBar = (props: Props) => (
  <PdfContext.Consumer>
    {({ address }) => {
      const streetAddress = address.address ? `${address.address}\n` : "";
      const city = address.city ? `${address.city}, ` : "";
      const state = address.state || "";
      const zipCode = address.zipCode || "";

      return (
        <View style={styles.container}>
          <Image style={styles.addressIcon} src={addressIcon} />
          <View style={[styles.addressContainer, { color: props.textColor }]}>
            <Text style={styles.addressText}>{`${streetAddress}`}</Text>
            <Text
              style={[styles.addressSecondaryText, { marginTop: 6 }]}
            >{`${city}${state} ${zipCode}`}</Text>
          </View>
        </View>
      );
    }}
  </PdfContext.Consumer>
);

export default AddressBar;
