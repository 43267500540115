import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";
import { connect } from "react-redux";
import { graphsSelectors } from "../../../../../state/graphs";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";

const mapStateToProps = (state) => ({
  highlightedGraphDataSource: graphsSelectors.getHighlightedGraphDataSource(state)
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class GraphHighlightedFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <GeoJSONLayer
        data={{
          type: "FeatureCollection",
          features: this.props.highlightedGraphDataSource
            ? [this.props.highlightedGraphDataSource.feature]
            : []
        }}
        fillPaint={{
          "fill-color": (this.props.highlightedGraphDataSource && this.props.highlightedGraphDataSource.color) || mapboxPresentationProperties.graphFeatureHoverFillPaint["fill-color"],
          "fill-opacity": this.props.highlightedGraphDataSource ? mapboxPresentationProperties.graphFeatureHoverFillPaint["fill-opacity"] : 0,
        }}
        linePaint={{
          ...mapboxPresentationProperties.graphFeatureHoverLinePaint,
          "line-color": (this.props.highlightedGraphDataSource && this.props.highlightedGraphDataSource.color) || mapboxPresentationProperties.graphFeatureHoverLinePaint["line-color"],
          "line-opacity": this.props.highlightedGraphDataSource ? mapboxPresentationProperties.graphFeatureHoverLinePaint["line-opacity"] : 0,
        }}
      />
    )
  }
}

export default connect(
  mapStateToProps
)(GraphHighlightedFeatureLayer);
