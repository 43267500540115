export enum ParcelProperty {
  IsInOpportunityZone = "isInOpportunityZone",
  AreaComputed = "areaComputed",
  AssemblyParcels = "assemblyParcels",
  IsAnAssembly = "isAnAssembly",
  // Parcel Layer
  Id = "id",
  Address = "address",
  City = "city",
  State = "state",
  ZipCode = "zipCode",
  AreaPublished = "areaPublished",
  PurchasePrice = "purchasePrice",
  ExistingStructureArea = "existingStructureArea",
  ExistingStructureYearBuilt = "existingStructureYearBuilt",
  ExistingStructureAreaOpenSource = "existingStructureAreaOpenSource",
  BasicStratum = "basicStratum",
  LandUseCode = "landUseCode",
  PublicLand = "publicLand",
  ParcelId = "parcelId",
  NumberOfResidentialUnits = "numberOfResidentialUnits",
  NumberOfBuildings = "numberOfBuildings",
  ConstructionClass = "constructionClass",
  ImprovementQuality = "improvementQuality",
  LandValue = "landValue",
  SalePrice = "salePrice",
  SaleYear = "saleYear",
  SaleMonth = "saleMonth",
  MultiParcelSale = "multiParcelSale",
  OwnerName = "ownerName",
  OwnerAddress = "ownerAddress",
  OwnerCity = "ownerCity",
  OwnerState = "ownerState",
  OwnerZipCode = "ownerZipCode",
  // Zoning layer
  BuildableAreaByFAR = "buildableAreaByFAR",
  BuildableAreaQuery = "buildableAreaQuery",
  AllowedUses = "allowedUses",
  ArchitectName = "architectName",
  ArchitectUrl = "architectUrl",
  BuildingHeight = "buildingHeight",
  BuildingHeightQuery = "buildingHeightQuery",
  LotCoverage = "lotCoverage",
  MinimumLotSize = "minimumLotSize",
  FloorAreaRatio = "floorAreaRatio",
  FloorAreaRatioQuery = "floorAreaRatioQuery",
  NumberOfFloors = "numberOfFloors",
  NumberOfUnitsAllowed = "numberOfUnitsAllowed",
  NumberOfUnitsAllowedQuery = "numberOfUnitsAllowedQuery",
  AllowedDetailedUses = "allowedDetailedUses",
  SetbacksPrimary = "setbacksPrimary",
  SetbacksSideStreet = "setbacksSideStreet",
  SetbacksSideInterior = "setbacksSideInterior",
  SetbacksRear = "setbacksRear",
  SetbacksSideInteriorAboveFirstFloor = "setbacksSideInteriorAboveFirstFloor",
  SetbacksPrimaryAboveFirstFloor = "setbacksPrimaryAboveFirstFloor",
  SetbacksSideStreetAboveFirstFloor = "setbacksSideStreetAboveFirstFloor",
  SetbacksRearAboveFirstFloor = "setbacksRearAboveFirstFloor",
  LivingUnitDensityNumerator = "livingUnitDensityNumerator",
  LivingUnitDensityDenominator = "livingUnitDensityDenominator",
  LivingUnitDensityUnitOfMeasure = "livingUnitDensityUnitOfMeasure",
  HotelUnitDensityNumerator = "hotelUnitDensityNumerator",
  HotelUnitDensityDenominator = "hotelUnitDensityDenominator",
  HotelUnitDensityUnitOfMeasure = "hotelUnitDensityUnitOfMeasure",
  ParkingSpacesResidentialNumerator = "parkingSpacesResidentialNumerator",
  ParkingSpacesResidentialDenominator = "parkingSpacesResidentialDenominator",
  ParkingSpacesResidentialUnitOfMeasure = "parkingSpacesResidentialUnitOfMeasure",
  ParkingSpacesSingleFamilyNumerator = "parkingSpacesSingleFamilyNumerator",
  ParkingSpacesSingleFamilyDenominator = "parkingSpacesSingleFamilyDenominator",
  ParkingSpacesSingleFamilyUnitOfMeasure = "parkingSpacesSingleFamilyUnitOfMeasure",
  ParkingSpacesHotelNumerator = "parkingSpacesHotelNumerator",
  ParkingSpacesHotelDenominator = "parkingSpacesHotelDenominator",
  ParkingSpacesHotelUnitOfMeasure = "parkingSpacesHotelUnitOfMeasure",
  ParkingSpacesOfficeNumerator = "parkingSpacesOfficeNumerator",
  ParkingSpacesOfficeDenominator = "parkingSpacesOfficeDenominator",
  ParkingSpacesOfficeUnitOfMeasure = "parkingSpacesOfficeUnitOfMeasure",
  ParkingSpacesRetailNumerator = "parkingSpacesRetailNumerator",
  ParkingSpacesRetailDenominator = "parkingSpacesRetailDenominator",
  ParkingSpacesRetailUnitOfMeasure = "parkingSpacesRetailUnitOfMeasure",
  ParkingSpacesIndustrialNumerator = "parkingSpacesIndustrialNumerator",
  ParkingSpacesIndustrialDenominator = "parkingSpacesIndustrialDenominator",
  ParkingSpacesIndustrialUnitOfMeasure = "parkingSpacesIndustrialUnitOfMeasure",
  ZoneId = "zoneId",
  ZoneIdTruncated = "zoneIdTruncated",
  // Demographics Layer
  Market = "market",
  MedianIncomeTotal = "medianIncomeTotal",
  MedianIncomeTotalGenerated = "medianIncomeTotalGenerated",
  GrossMedianRent = "grossMedianRent",
  GrossMedianRentGenerated = "grossMedianRentGenerated",
  PopulationDensity = "populationDensity",
  PopulationDensityGenerated = "populationDensityGenerated",
  EmploymentPerPopulation = "employmentPerPopulation",
  EmploymentPerPopulationGenerated = "employmentPerPopulationGenerated",
  Gdp = "gdp",
  // Buildings Layer
  BuildingFeatureModel = "buildingFeatureModel",
  // Links
  ProjectURL_1 = "other_URL_1",
  ProjectURL_2 = "other_URL_2",
  ProjectURL_3 = "other_URL_3",
  ProjectURL_4 = "other_URL_4",
  PD_URL_1 = "PD_URL_1",
  PD_URL_2 = "PD_URL_2",
  PD_URL_3 = "PD_URL_3",
  PD_URL_4 = "PD_URL_4",
  // Overlays
  Overlays = "Overlays",
  //Footnotes
  BuildingHeightFootnote = "buildingHeightFootnote",
  UnitDensityFootnote = "unitDensityFootnote",
  HotelDensityFootnote = "hotelDensityFootnote",
  FarFootnote = "farFootnote",
  LotCoverageFootnote = "lotCoverageFootnote",
  MinimumLotSizeFootnote = "minimumLotSizeFootnote",
  NumberOfFloorsFootnote = "numberOfFloorsFootnote",
  SetbackFootnote = "setbackFootnote",
  UsesFootnote = "usesFootnote",
  ParkingMultifamilyFootnote = "parkingMultifamilyFootnote",
  ParkingSingleFamilyFootnote = "parkingSingleFamilyFootnote",
  ParkingHotelFootnote = "parkingHotelFootnote",
  ParkingOfficeFootnote = "parkingOfficeFootnote",
  ParkingRetailFootnote = "parkingRetailFootnote",
  ParkingIndustrialFootnote = "parkingIndustrialFootnote",
  // Exising Building
  ExistingBuildingHeight = "existingBuildingHeight",
}
