import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import genericLinearRegressionModel, { RegressionConstants } from "./utils/genericLinearRegressionModel";
import hardCostModel from "./utils/hardCostModel";
import rentModel from "./utils/rentModel";
import salesModel, { SalesUseType } from "./utils/salesModel";

/**
 * Generate the market rent data presets. If anything fails within the `rentModel` module, linear regression is used instead.
 */
const generateRentPreset = async (development: Development, variableId: keyof RegressionConstants, buildingUse: BuildingUse, area?: number, unitDivisor?: number) => {
  try {
    return await rentModel.generatePreset(development, buildingUse, area, unitDivisor)
  } catch (error) {
    console.warn(error);
    console.warn(`Falling back to linear regression for rent presets for variableID: ${variableId}.`);

    return genericLinearRegressionModel.generatePreset(development, variableId, area);
  }
}

/**
 * Generate the market hard cost data presets. If anything fails within the `hardCostModel` module, linear regression is used instead.
 */
const generateHardCostPreset = async (development: Development, variableId: keyof RegressionConstants, buildingUse: BuildingUse) => {
  try {
    return await hardCostModel.generatePreset(development, buildingUse)
  } catch (error) {
    console.warn(error);
    console.warn(`Falling back to linear regression for hard cost presets for variableID: ${variableId}.`);

    return genericLinearRegressionModel.generatePreset(development, variableId);
  }
}

/**
 * Generate the market sales data presets. If anything fails within the `salesModel` module, linear regression is used instead.
 */
const generateSalesPreset = async (development: Development, variableId: keyof RegressionConstants,  useType: SalesUseType, area?: number) => {
  try {
    return await salesModel.generatePreset(development, useType, area);
  } catch(error) {
    console.warn(error);
    console.warn(`Falling back to linear regression for sales presets for variableID: ${variableId}.`);

    return genericLinearRegressionModel.generatePreset(development, variableId, area);
  }
}

export default {
  generateRentPreset,
  generateSalesPreset,
  generateHardCostPreset,
};
