import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "state/newDevelopment";
import { mapsSelectors } from "state/ui/shared/maps";
import { LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";

const ParcelCounter = () => {
  const searchResultsCount = useSelector(newDevelopmentSelectors.getSmartSearchResultCount);
  const searchResultsExist = searchResultsCount > 0;
  const layerConfigurations = useSelector(mapsSelectors.getLayerConfigurations);
  const parcelSearchLayerIsActive = layerConfigurations[LayerId.SmartSearchResultsFill].isActive;

  return (
    (searchResultsExist && parcelSearchLayerIsActive)
      ? <div className="component--parcel-counter">{searchResultsCount} Parcels Matched</div>
      : <div className="component--parcel-counter"> No Parcels Matched</div>
  )
}

export default ParcelCounter;
