import actionTypes from "./actionTypes";
import { UnblockRouteFunction } from "./types";

/**
 * Block route from routing to passed in path name.
 */
const blockRoute = (routePathName: string) => {
  return {
    type: actionTypes.BLOCK_ROUTE,
    payload: {
      routePathName,
    },
  };
};

/**
 * Clear flags blocking the route.
 */
const unblockRoute: UnblockRouteFunction = () => {
  return {
    type: actionTypes.UNBLOCK_ROUTE,
  };
};

export default {
  blockRoute,
  unblockRoute,
};
