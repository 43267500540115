import { VariableId } from "../../../types/VariableId";

const defaults = {
  [VariableId.CondoMicrounitQuantityToggleable]: {
    "minimum": 0,
    "value": 0,
    "maximum": 100
  },
  [VariableId.CondoStudioQuantityToggleable]: {
    "minimum": 0,
    "value": 25,
    "maximum": 100
  },
  [VariableId.CondoOneBedQuantityToggleable]: {
    "minimum": 0,
    "value": 50,
    "maximum": 100
  },
  [VariableId.CondoTwoBedQuantityToggleable]: {
    "minimum": 0,
    "value": 25,
    "maximum": 100
  },
  [VariableId.CondoThreeBedQuantityToggleable]: {
    "minimum": 0,
    "value": 0,
    "maximum": 100
  },
  [VariableId.CondoMicrounitArea]: {
    "minimum": 13.94,
    "value": 38.09,
    "maximum": 68.75
  },
  [VariableId.CondoStudioArea]: {
    "minimum": 22.3,
    "value": 47.38,
    "maximum": 95.69
  },
  [VariableId.CondoOneBedArea]: {
    "minimum": 33.45,
    "value": 68.75,
    "maximum": 148.64
  },
  [VariableId.CondoTwoBedArea]: {
    "minimum": 44.59,
    "value": 96.62,
    "maximum": 205.32
  },
  [VariableId.CondoThreeBedArea]: {
    "minimum": 48.31,
    "value": 119.84,
    "maximum": 302.86
  },
  [VariableId.CondoLossFactor]: {
    "minimum": 0.1,
    "value": 0.2,
    "maximum": 0.4
  },
  [VariableId.CondoAmenitiesAreaToggleable]: {
    "minimum": 0,
    "value": 900.00,
    "maximum": 3251.61
  },
  [VariableId.CondoSalePricePerMicrounit]: {
    "minimum": 130000,
    "value": 220000,
    "maximum": 420000
  },
  [VariableId.CondoSalePricePerStudio]: {
    "minimum": 170400,
    "value": 337374,
    "maximum": 621720
  },
  [VariableId.CondoSalePricePerOneBed]: {
    "minimum": 201840,
    "value": 401862,
    "maximum": 695880
  },
  [VariableId.CondoSalePricePerTwoBed]: {
    "minimum": 247200,
    "value": 506238,
    "maximum": 889560
  },
  [VariableId.CondoSalePricePerThreeBed]: {
    "minimum": 277920,
    "value": 595293,
    "maximum": 1172160
  },
  [VariableId.CondoHardCostPerArea]: {
    "minimum": 1227.09,
    "value": 1453.13,
    "maximum": 1689.93
  },
  [VariableId.ProjectSaleCommissionPercentage]: {
    "minimum": 0,
    "value": .06,
    "maximum": .1
  },
  [VariableId.MultifamilyMicrounitQuantityToggleable]: {
    "minimum": 0,
    "value": 0,
    "maximum": 100
  },
  [VariableId.MultifamilyStudioQuantityToggleable]: {
    "minimum": 0,
    "value": 25,
    "maximum": 100
  },
  [VariableId.MultifamilyOneBedQuantityToggleable]: {
    "minimum": 0,
    "value": 50,
    "maximum": 100
  },
  [VariableId.MultifamilyTwoBedQuantityToggleable]: {
    "minimum": 0,
    "value": 25,
    "maximum": 100
  },
  [VariableId.MultifamilyThreeBedQuantityToggleable]: {
    "minimum": 0,
    "value": 0,
    "maximum": 100
  },
  [VariableId.MultifamilyMicrounitArea]: {
    "minimum": 13.94,
    "value": 38.09,
    "maximum": 68.75
  },
  [VariableId.MultifamilyStudioArea]: {
    "minimum": 22.3,
    "value": 47.38,
    "maximum": 95.69
  },
  [VariableId.MultifamilyOneBedArea]: {
    "minimum": 33.45,
    "value": 68.75,
    "maximum": 148.64
  },
  [VariableId.MultifamilyTwoBedArea]: {
    "minimum": 44.59,
    "value": 96.62,
    "maximum": 205.32
  },
  [VariableId.MultifamilyThreeBedArea]: {
    "minimum": 48.31,
    "value": 119.84,
    "maximum": 302.86
  },
  [VariableId.MultifamilyLossFactor]: {
    "minimum": 0.1,
    "value": 0.2,
    "maximum": 0.4
  },
  [VariableId.MultifamilyAmenitiesAreaToggleable]: {
    "minimum": 0,
    "value": 400,
    "maximum": 800
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: {
    "minimum": 640,
    "value": 1000,
    "maximum": 1360
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: {
    "minimum": 710,
    "value": 1120,
    "maximum": 1730
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: {
    "minimum": 840,
    "value": 1340,
    "maximum": 1930
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: {
    "minimum": 1030,
    "value": 1690,
    "maximum": 2470
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: {
    "minimum": 1160,
    "value": 1980,
    "maximum": 3260
  },
  [VariableId.MultifamilyStabilizedVacancyPercentage]: {
    "minimum": 0,
    "value": 0.04,
    "maximum": 0.1
  },
  [VariableId.MultifamilyHardCostPerArea]: {
    "minimum": 1022.57,
    "value": 1216.32,
    "maximum": 1410.07
  },
  [VariableId.MultifamilyOperatingExpensePercentage]: {
    "minimum": 0.25,
    "value": 0.35,
    "maximum": 0.65
  },
  [VariableId.HotelRoomQuantityToggleable]: {
    "minimum": 0,
    "value": 50,
    "maximum": 100
  },
  [VariableId.HotelAverageRoomArea]: {
    "minimum": 13.94,
    "value": 23.23,
    "maximum": 35.3
  },
  [VariableId.HotelLossFactor]: {
    "minimum": 0.1,
    "value": 0.2,
    "maximum": 0.4
  },
  [VariableId.HotelAmenitiesAreaToggleable]: {
    "minimum": 0,
    "value": 900.00,
    "maximum": 3251.61
  },
  [VariableId.HotelStabilizedAverageDailyRate]: {
    "minimum": 120,
    "value": 180,
    "maximum": 280
  },
  [VariableId.HotelOtherRevenuePercentage]: {
    "minimum": 0,
    "value": 0.3,
    "maximum": 0.6
  },
  [VariableId.HotelHardCostPerArea]: {
    "minimum": 1291.67,
    "value": 2206.6,
    "maximum": 3121.53
  },
  [VariableId.HotelOperatingExpensePercentage]: {
    "minimum": 0.25,
    "value": 0.7,
    "maximum": 0.9
  },
  [VariableId.HotelStabilizedOccupancyPercentage]: {
    "minimum": 0.7,
    "value": 0.75,
    "maximum": 0.85
  },
  [VariableId.OfficeNetUsableAreaToggleable]: {
    "minimum": 0,
    "value": 371.61,
    "maximum": 464.52
  },
  [VariableId.OfficeCommonAreaFactor]: {
    "minimum": 0,
    "value": 0.12,
    "maximum": 0.2
  },
  [VariableId.OfficeYearOneRentPerArea]: {
    "minimum": 161.46,
    "value": 269.1,
    "maximum": 538.2
  },
  [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: {
    "minimum": 0,
    "value": 0,
    "maximum": 645.83
  },
  [VariableId.OfficeStabilizedVacancyPercentage]: {
    "minimum": 0,
    "value": 0.1,
    "maximum": 0.2
  },
  [VariableId.OfficeYearOneOperatingExpensePerArea]: {
    "minimum": 53.82,
    "value": 86.11,
    "maximum": 161.46
  },
  [VariableId.OfficeHardCostPerArea]: {
    "minimum": 807.29,
    "value": 1119.45,
    "maximum": 1442.36
  },
  [VariableId.RetailNetLeasableAreaToggleable]: {
    "minimum": 0,
    "value": 371.61,
    "maximum": 464.52
  },
  [VariableId.RetailLossFactor]: {
    "minimum": 0,
    "value": 0.12,
    "maximum": 0.2
  },
  [VariableId.RetailYearOneRentPerArea]: {
    "minimum": 376.74,
    "value": 699.65,
    "maximum": 3282.99
  },
  [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: {
    "minimum": 0,
    "value": 129.17,
    "maximum": 3282.99
  },
  [VariableId.RetailStabilizedVacancyPercentage]: {
    "minimum": 0,
    "value": 0.06,
    "maximum": 0.15
  },
  [VariableId.RetailYearOneOperatingExpensePerArea]: {
    "minimum": 53.82,
    "value": 129.17,
    "maximum": 322.92
  },
  [VariableId.RetailHardCostPerArea]: {
    "minimum": 602.78,
    "value": 893.4,
    "maximum": 1184.03
  },
  [VariableId.IndustrialNetLeasableAreaToggleable]: {
    "minimum": 0,
    "value": 371.61,
    "maximum": 464.52
  },
  [VariableId.IndustrialLossFactor]: {
    "minimum": 0,
    "value": 0,
    "maximum": 0.1
  },
  [VariableId.IndustrialYearOneRentPerArea]: {
    "minimum": 107.64,
    "value": 161.46,
    "maximum": 269.1
  },
  [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: {
    "minimum": 0,
    "value": 0,
    "maximum": 161.46
  },
  [VariableId.IndustrialStabilizedVacancyPercentage]: {
    "minimum": 0,
    "value": 0.04,
    "maximum": 0.15
  },
  [VariableId.IndustrialHardCostPerArea]: {
    "minimum": 441.32,
    "value": 645.83,
    "maximum": 850.35
  },
  [VariableId.IndustrialYearOneOperatingExpensePerArea]: {
    "minimum": 21.53,
    "value": 53.82,
    "maximum": 161.46
  },
  [VariableId.ExistingStructureDemolitionCostPerArea]: {
    "minimum": 32.29,
    "value": 96.88,
    "maximum": 215.28
  },
  [VariableId.ContingencyCostPercentage]: {
    "minimum": 0,
    "value": 0.06,
    "maximum": 0.25
  },
  [VariableId.SoftCostPercentage]: {
    "minimum": 0,
    "value": 0.15,
    "maximum": 0.4
  },
  [VariableId.HeightOfGroundFloor]: {
    "minimum": 2.5,
    "value": 4.27,
    "maximum": 7.5
  },
  [VariableId.HeightOfTypicalFloor]: {
    "minimum": 1,
    "value": 3.05,
    "maximum": 5
  }
}

export default defaults;
