import * as firebase from "firebase/app";
import "firebase/auth";
import userSessions from "../database/userSessions";
import Authentication from "../../types/Authentication";
import companyInfo from "../companyInfo";
import Firebase from "../../types/Service/Firebase";

let FIREBASE_TOKEN;
const INTERNAL_ERROR_STRING = "Internal error.";
const USER_FACING_ERROR_MESSAGE_MAP = {
  [Authentication.CustomErrorCode.MissingFields]: "You must provide all fields.",
  [Authentication.CustomErrorCode.TermsOfServiceNotAccepted]: "You must accept the Terms of Service to continue.",
  [Authentication.FirebaseErrorCode.ExpiredActionCode]: "The link has expired.",
  [Authentication.FirebaseErrorCode.InvalidActionCode]: "The link is invalid.",
  [Authentication.FirebaseErrorCode
    .UserDisabled]: `Your account has been disabled. Please get in touch with us at ${companyInfo.CONTACT_EMAIL}.`,
  [Authentication.FirebaseErrorCode.UserNotFound]:
    "This email did not match any existing accounts. Please check spelling.",
  [Authentication.FirebaseErrorCode.WrongPassword]: "Invalid password.",
  [Authentication.FirebaseErrorCode.WeakPassword]: "Please use a stronger password.",
  [Authentication.FirebaseErrorCode.AccountExistsWithDifferentCredential]:
    "An account already exists for this email address using a different log in method. Please log in using the method with which the account was created.",
  [Authentication.FirebaseErrorCode.AuthDomainConfigRequired]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.CredentialAlreadyInUse]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.EmailAlreadyInUse]: "An account already exists with this email address.",
  [Authentication.FirebaseErrorCode.OperationNotAllowed]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.OperationNotSupportedInThisEnvironment]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.Timeout]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidEmail]: "Please enter a valid email address.",
  [Authentication.FirebaseErrorCode.MissingAndroidPkgName]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.MissingContinueUri]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.MissingIosBundleId]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidContinueUri]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.UnauthorizedContinueUri]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.ArgumentError]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidPersistenceType]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.UnsupportedPersistenceType]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidCredential]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidVerificationCode]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidVerificationId]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.CustomTokenMismatch]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidCustomToken]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.CaptchaCheckFailed]: "CAPTCHA validation failed.",
  [Authentication.FirebaseErrorCode.InvalidPhoneNumber]: "Please provide a valid phone number.",
  [Authentication.FirebaseErrorCode.MissingPhoneNumber]: "Please provide a valid phone number.",
  [Authentication.FirebaseErrorCode.QuotaExceeded]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.CancelledPopupRequest]:
    "This log in process was cancelled because it was started multiple times.",
  [Authentication.FirebaseErrorCode.PopupBlocked]: "The popup was blocked by your browser.",
  [Authentication.FirebaseErrorCode.PopupClosedByUser]: "The popup was closed before completing log in.",
  [Authentication.FirebaseErrorCode.UnauthorizedDomain]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.InvalidUserToken]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.UserTokenExpired]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.NullUser]: INTERNAL_ERROR_STRING,
  [Authentication.FirebaseErrorCode.NetworkRequestFailed]: "Could not connect.",
  [Authentication.FirebaseErrorCode.TooManyRequests]: "Too many requests. Try again in a few minutes",
};

export enum AuthenticationProviderId {
  EmailAndPassword = "email and password",
  Facebook = "facebook.com",
  Google = "google.com",
}

/**
 * Maps error code to error message.
 */
const getErrorMessage = (errorCode: Authentication.ErrorCode): string => {
  return USER_FACING_ERROR_MESSAGE_MAP[errorCode] || "Unknown error";
};

/**
 * Signs in using an email and password. Sign in errors are handled by the
 * caller.
 */
const signInWithEmailAndPassword = async (email, password) => {
  await firebase.auth().signInWithEmailAndPassword(email, password);
};

/**
 * Authenticate the user using Facebook Login via a popup window. If there is
 * no existing account associated with the Facebook account's email address,
 * one is created.
 */
const signInWithFacebookPopup = async (): Promise<firebase.auth.UserCredential> => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  return firebase.auth().signInWithPopup(facebookProvider);
};

/**
 * Authenticate the user using Google Sign-In via a popup window. If there is no
 * existing account associated with the Gmail address used, one is created.
 */
const signInWithGooglePopup = async (): Promise<firebase.auth.UserCredential> => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(googleProvider);
};

/**
 * Returns a Promise containing void.
 */
const signOut = async () => {
  try {
    await userSessions.deleteUserSession(getCurrentUser());
    return firebase.auth().signOut();
  } catch (error) {
    console.error("Error signing out:", error);
    throw error;
  }
};

/**
 * Returns the unsubscribe function for the observer.
 */
const onStateChange = (callback, error?) => {
  return firebase.auth().onAuthStateChanged(callback, error);
};

/**
 * Returns the currrentUser firebase token.
 */
const fetchFirebaseId = async () => {
  FIREBASE_TOKEN = await getCurrentUserIdToken();
};

/**
 * Returns the currrentUser firebase token variable.
 */
const getFirebaseId = () => FIREBASE_TOKEN;

/**
 * Returns the currently signed-in user (or null).
 */
const getCurrentUser = (): Firebase.User => {
  return firebase.auth().currentUser;
};

/**
 * @returns the current user's idToken
 */
const getCurrentUserIdToken = async () => {
  return firebase
    .auth()
    .currentUser?.getIdToken(true)
    .then((idToken) => {
      return idToken;
    });
};

/**
 * Create a new user given the email and password. Be aware that Firebase's
 * `createUserWithEmailAndPassword()` can throw exceptions for several reasons.
 * See: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createUserWithEmailAndPassword
 */
const createUserWithEmailAndPassword = async (email, password) => {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

/**
 * Send a verification email. Errors are handled on caller method.
 */
const sendEmailVerification = () => {
  const user = firebase.auth().currentUser;
  if (user !== null) {
    user.sendEmailVerification();
  }
};

/**
 * Check if the current user's email is verified.
 */
const userEmailIsVerified = async () => {
  const user = firebase.auth().currentUser;

  if (user === null) {
    return false;
  } else {
    await user.reload();
    return user.emailVerified;
  }
};

/**
 * Verifies user email. Errors are handled on caller method.
 */
const verifyEmail = async (actionCode) => {
  await firebase.auth().applyActionCode(actionCode);
};

/**
 * Send a reset password email to the provided email address.
 */
const sendResetPasswordEmail = async (emailAddress) => {
  await firebase.auth().sendPasswordResetEmail(emailAddress);
};

/**
 * Verify is the password reset code is valid. Errors are handled on caller method.
 */
const verifyPasswordResetCode = async (actionCode) => {
  await firebase.auth().verifyPasswordResetCode(actionCode);
};

/**
 * Reset the user's password. Errors are handled on caller method.
 */
const confirmPasswordReset = async (actionCode, newPassword) => {
  await firebase.auth().confirmPasswordReset(actionCode, newPassword);
};

/**
 * Returns true if the user is authenticated, false otherwise.
 * It might return false while the auth object is initializing
 * (e.g when the page is refreshed), for that reason the recommended
 * approach is to use create observer using onStateChange to keep
 * track to the authentication state
 */
const isUserAuthenticated = () => {
  return firebase.auth().currentUser !== null;
};

export default {
  signInWithEmailAndPassword,
  signInWithFacebookPopup,
  signInWithGooglePopup,
  signOut,
  onStateChange,
  getCurrentUser,
  isUserAuthenticated,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  verifyEmail,
  userEmailIsVerified,
  sendResetPasswordEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  getErrorMessage,
  getCurrentUserIdToken,
  getFirebaseId,
  fetchFirebaseId,
};
