import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import { Type } from "../../../../types/Format/Format";
import valueFormatter from "../../../../utils/valueFormatter";
import SubscribeButton from "../SubscribeButton";
import BusySpinner from "../../BusySpinner";

const LIST_OF_FEATURES = [
  "Base Map",
  "Satellite Map",
  "Draw Parcels",
  "Rent Financial Model",
  "Sale Financial Model",
  "3D Massing Model",
  "",
  "Multifamily Assumptions",
  "Condominium Assumptions",
  "Retail Assumptions",
  "Office Assumptions",
  "Hotel Assumptions",
  "Industrial Assumptions",
  "Export Financials to CSV",
  "",
  "Area Sliders",
  "Setback Sliders",
  "Unit Mix Sliders",
  "Income Sliders",
  "Expenses Sliders",
  "Vacancy Sliders",
  "Construction Cost Sliders",
  "",
  "Branded PDF Report",
];

const mapStateToProps = (state) => {
  return {
    planPrices: subscriptionSelectors.getPlanPrices(state),
  };
};

interface OwnProps {
  planPeriodIsYearly: boolean;
  presentationOnly?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

class DeveloperTierPanel extends React.PureComponent<Props, {}> {
  /**
   * Render the plan price.
   */
  renderPrice = () => {
    const { planPeriodIsYearly, planPrices } = this.props;
    const price = planPeriodIsYearly
      ? planPrices[Chargebee.PlanId.DeveloperYearly]
      : planPrices[Chargebee.PlanId.DeveloperMonthly];

    return (
      <div className="price">
        {price === undefined ? (
          <BusySpinner />
        ) : (
          <>
            {valueFormatter.format(price, { type: Type.Currency, decimalPlaces: 2 })}
            <div className="suffix">{planPeriodIsYearly ? "/yr" : "/mo"}</div>
          </>
        )}
      </div>
    );
  };

  /**
   * Render the subscribe button.
   */
  renderButton = () => {
    const { planPeriodIsYearly, presentationOnly } = this.props;
    const text = "Sign Up for Developer";

    return presentationOnly === true ? (
      <div className="no-button-wrapper"></div>
    ) : (
      this.renderButtonBox(text, planPeriodIsYearly)
    );
  };

  /**
   * Render the list of features.
   */
  renderListOfFeatures = () => {
    return (
      <div className="list-wrapper">
        {LIST_OF_FEATURES.map((feature, index) => (
          <div className={`list-item`} key={`feature_${index}`}>
            {feature}
          </div>
        ))}
      </div>
    );
  };

  renderButtonBox = (text: string, planPeriodIsYearly) => {
    return (
      <div className="button-wrapper">
        <SubscribeButton
          text={text}
          paymentPlanId={planPeriodIsYearly ? Chargebee.PlanId.DeveloperYearly : Chargebee.PlanId.DeveloperMonthly}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="component--tier-panel">
        <div className="plan-name">Developer</div>
        <p className="tier-sub-description">The fastest way to due diligence.</p>
        {this.renderPrice()}
        {this.renderButton()}
        {this.renderListOfFeatures()}
        <div className="bottom" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(DeveloperTierPanel);
