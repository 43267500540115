import { ThumbnailsState, ThumbnailsCollectionState } from "./reducers";

/**
 * Select the whole thumbnails state.
 */
const getThumbnails = (state): ThumbnailsState => {
  return state.thumbnails;
}

/**
 * Select the collection object from the thumbnails state.
 */
const getCollection = (state): ThumbnailsCollectionState => {
  let thumbnails = getThumbnails(state);
  return thumbnails.collection;
}

/**
 * Select the current thumbnail.
 */
const getCurrentThumbnail = (state): Blob | null => {
  let thumbnails = getThumbnails(state);
  return thumbnails.currentThumbnail;
}

export default {
  getCollection,
  getCurrentThumbnail,
}
