import React from "react";
import { useSelector } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../../state/newDevelopment";
import AllowedUsesFilters from "./AllowedUsesFilters";

const AllowedUsesFiltersContainer = () => {
  const shouldDisplay = useSelector(newDevelopmentSelectors.getAllowedUsesFiltersShouldDisplay);

  return shouldDisplay
    ? <>
        <AllowedUsesFilters />
        <div className="divisor" />
      </>
    : null;
}

export default AllowedUsesFiltersContainer;
