/**
 * Download a file with the given filename and containing the contents of the given blob.
 *
 * NOTE: This method of downloading a blob should only be used in cases where generating and
 * downloading the blob must all be done synchronously and just-in-time. If the file contents can be
 * saved to a server, or if the blob can be generated ahead of time, it may be better to use a
 * different method, such as having the user interact directly with an `<a>` element.
 *
 * @param {Blob} blob - The blob whose contents to download.
 * @param {string} filename - The default filename with which to populate the filesystem save
 *   diaglog.
 */
const downloadBlobAsFile = (blob: Blob, filename: string): void => {
  const downloadUrl = URL.createObjectURL(blob);

  // NOTE: This method of appending an `<a>` element to the document and then simulating a click is
  // super ugly, but appears to be the only way to download a local blob with a specified filename.
  const downloadElement = document.createElement("a");
  downloadElement.href = downloadUrl;
  downloadElement.download = filename;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
}

export default downloadBlobAsFile;
