/**
 * Sanitize value to safely convert to a number.
 * Strings will be casted into numbers and returned if casted value is a valid number.
 * Any other type will return null.
 */
const forNumber = (value) => {
  if (typeof value === "number" && !Number.isNaN(value)) return value;

  if (typeof value === "string" && value !== "") {
    const valueAsNumber = Number(value);
    if (!Number.isNaN(valueAsNumber)) return valueAsNumber;
  }

  return null;
}

/**
 * Sanitize value to convert to a String.
 * Returns null if passed in value is undefined or null.
 */
const forString = (value) => {
  return value === undefined || value === null
    ? null
    : String(value).trim();
}

/**
 * Sanitize value to convert to a String and lowercases it.
 * Returns null if passed in value is undefined or null.
 */
const forLowerCaseString = (value) => {
  let string = forString(value);
  return (string && string.toLowerCase()) || null;
}

/**
 * Sanitize value to convert to an url.
 * Returns null if passed in value is undefined or null.
 */
const forUrl = (value) => {
  let url = forLowerCaseString(value);
  if (!url) return null;

  if (!url.startsWith("http")) url = "http://" + url;
  return url.toLowerCase();
}

/**
 * Sanitize value to convert to an url.
 * Returns null if passed in value is undefined or null.
 * This is a similar method than the one above but without
 *  converting the URL to lower case. For example, Municode's links
 *  need to be shown as they come (mixing lower and upper cases).
 */
const forDataUrl = (value) => {
  let url = value;
  if (!url) return null;

  if (!url.startsWith("http")) url = "http://" + url;
  return url;
}

/**
 * Sanitize uses string to an array of uses.
 * Returns empty array if the given value cannot be represented as sanitized string.
 */
const forAllowedUses = (allowedUses) => {
  const sanitizedUses = forString(allowedUses);
  if (!sanitizedUses) return [];

  return sanitizedUses.split(",").map((use) => use.trim().toLowerCase());
}

/**
 * Sanitize existing structure year built.
 * Returns year casted to string if it is a valid integer, null otherwise.
 */
const forExistingStructureYearBuilt = (value) => {
  const valueAsNumber = Number(value);
  return Number.isInteger(valueAsNumber)
    ? String(valueAsNumber)
    : null;
}

/**
 * Sanitize value to convert to boolean.
 */
const forBoolean = (value) => {
  return Boolean(Number(value));
}

export default {
  forNumber,
  forString,
  forLowerCaseString,
  forUrl,
  forDataUrl,
  forAllowedUses,
  forExistingStructureYearBuilt,
  forBoolean,
}
