import { call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import actionTypes from "./actionTypes";
import intercom from "../../utils/intercom";
import { authenticationActionTypes, authenticationActions } from "../authentication";
import { UserDocument } from "../../types/UserDocument";
import authentication from "../../utils/authentication";
import userThumbnails from "../../utils/storage/developments/userThumbnails";
import users from "../../utils/database/users";

const DEFAULT_USER_DOCUMENT: UserDocument = {
  firstName: null,
  middleName: null,
  lastName: null,
  email: null,
  companyName: null,
  companySize: null,
  isOnboarded: false,
  chargebeeCustomerId: null,
  projects: {},
};

/**
 * Fetch user location.
 */
function* getUserLocationStart(action) {
  try {
    const { longitude, latitude } = yield call(getUserCurrentLocation);
    yield put(actions.getLocationSuccess([longitude, latitude]));
  } catch (error: any) {
    console.warn(error.message);
    yield put(actions.getLocationError());
  }
}

/**
 * Get user geolocation. This is a wrapper that returns a promise.
 */
const getUserCurrentLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => resolve(location.coords),
        (error) => reject(error)
      );
    } else {
      reject({ message: "Geolocation is not available in this browser." });
    }
  });

/**
 * Load the user document and thumbnail image.
 */
function* load(action: ReturnType<typeof authenticationActions.signInSuccess>) {
  const user = authentication.getCurrentUser();
  if (!user) return;

  try {
    const userDocument = yield call(users.read, user.uid);
    intercom.shutdown();
    intercom.initialize(userDocument.firstName, user.email || "");

    let imageUrl = "";
    try {
      imageUrl = yield call(userThumbnails.download, user.uid);
    } catch (error: any) {
      console.warn(error.message);
    }

    yield put(actions.loadSuccess(userDocument, imageUrl));
  } catch (error: any) {
    console.warn("Error Here", error.message);
  }
}

/**
 * Create a user document and save it to the database.
 */
function* create(action: ReturnType<typeof actions.createStart>) {
  const { user } = action.payload;
  if (!user) return;

  try {
    const { userData } = action.payload;
    let userDocument = { ...DEFAULT_USER_DOCUMENT };
    userDocument.firstName = userData.firstName || DEFAULT_USER_DOCUMENT.firstName;
    userDocument.middleName = userData.middleName || DEFAULT_USER_DOCUMENT.middleName;
    userDocument.lastName = userData.lastName || DEFAULT_USER_DOCUMENT.lastName;
    userDocument.companyName = userData.companyName || DEFAULT_USER_DOCUMENT.companyName;
    userDocument.companySize = userData.companySize || DEFAULT_USER_DOCUMENT.companySize;

    yield call(users.create, user.uid, userDocument);

    const userEmailIsVerified = yield call(authentication.userEmailIsVerified);
    if (!userEmailIsVerified) authentication.sendEmailVerification();

    userDocument = yield call(users.read, user.uid);
    yield put(actions.createSuccess(userDocument));
  } catch (error: any) {
    console.warn(error.message);
  }
}

/**
 * Upload user image.
 */
function* uploadImage(action: ReturnType<typeof actions.uploadImageStart>) {
  const payload = action.payload;

  try {
    yield call(userThumbnails.upload, payload.imageFile, payload.userId);

    const imageUrl = URL.createObjectURL(payload.imageFile);
    yield put(actions.uploadImageSuccess(imageUrl));
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Update user document.
 */
function* update(action: ReturnType<typeof actions.updateStart>) {
  const payload = action.payload;

  try {
    yield call(users.update, payload.userId, payload.userData);

    const userDocument = yield call(users.read, payload.userId);
    yield put(actions.updateSuccess(userDocument));
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Watcher for the updateStart action.
 */
function* watchUpdateStart() {
  yield takeEvery(actionTypes.UPDATE_START, update);
}

/**
 * Watcher for the uploadImageStart action.
 */
function* watchUploadImageStart() {
  yield takeEvery(actionTypes.UPLOAD_IMAGE_START, uploadImage);
}

/**
 * Watcher for user createStart action.
 */
function* watchCreateStart() {
  yield takeEvery(actionTypes.CREATE_START, create);
}

/**
 * Watcher for user loadStart action.
 */
function* watchLoadStart() {
  yield takeEvery([authenticationActionTypes.SIGN_IN_SUCCESS, actionTypes.LOAD_START], load);
}

/**
 * Watcher for user getLocationStart action.
 */
function* watchGetUserLocationStart() {
  yield takeEvery(actionTypes.GET_LOCATION_START, getUserLocationStart);
}

export default {
  watchGetUserLocationStart,
  watchLoadStart,
  watchCreateStart,
  watchUpdateStart,
  watchUploadImageStart,
};
