import React from "react"
import { Layer, Source } from "react-mapbox-gl"
import { connect } from "react-redux"
import { layersSelectors } from "../../../../../../state/layers"
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties"
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties"

const mapStateToProps = (state) => ({
  officeFeatures: layersSelectors.selectOfficeYearlyRentFeatures(state),
})

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const OFFICE_RATE_SOURCE_ID = "office-rate-source"

const LAYER_FILL_PAINT = {
  "fill-opacity": 0.5,
  "fill-color": [
    "interpolate",
    ["linear"],
    [
      "get",
      MapStyleProperties.RentFeatureFieldId.Value
    ],
    10,
    "#fd0d0d",
    20,
    "#fff305",
    30,
    "#0fe600",
    40,
    "#16e7fe",
    50,
    "#024be8",
  ]
}

class OfficeRateFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Source
          id={OFFICE_RATE_SOURCE_ID}
          geoJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.props.officeFeatures
            }
          }}
        />
        <Layer
          id={MapStyleProperties.LayerId.OfficeRateFill}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={OFFICE_RATE_SOURCE_ID}
          paint={LAYER_FILL_PAINT}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={MapStyleProperties.LayerId.OfficeRateBorder}
          type={MapStyleProperties.LayerType.Line}
          sourceId={OFFICE_RATE_SOURCE_ID}
          paint={mapboxPresentationProperties.rentLayersLinePaint}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={MapStyleProperties.LayerId.OfficeRateSymbol}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={OFFICE_RATE_SOURCE_ID}
          layout={mapboxPresentationProperties.rentLabelsSymbolLayout(MapStyleProperties.RentFeatureFieldId.Value, { prefix: "$" })}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          minZoom={13}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    )
  }
}

export default connect(
  mapStateToProps
)(OfficeRateFeatureLayer);
