import React from "react";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";
import zoningIcon from "../../../../../../../../assets/images/pdf-icon-zoning.png";
import Format from "../../../../../../../../types/Format";
import Unit from "../../../../../../../../types/Unit";
import parcelAccessors from "../../../../../../../../utils/parcel/parcelAccessors";
import unitConversions from "../../../../../../../../utils/unitConversions";
import valueFormatter from "../../../../../../../../utils/valueFormatter";
import PdfContext from "../../../../PdfContext";
import Row from "../Row";
import Section from "../Section";
import authentication from "../../../../../../../../utils/authentication";
import { Tier } from "../../../../../../../../types/Tier";
import valueGetters from "../../../../../../../../state/development/utils/valueGetters";
import { System } from "types/Unit/Unit";

/**
 * Render Row if value is not null.
 */
const ConditionalRow = (value, Row) => {
  if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) return null;

  return <Row.type value={value} {...Row.props} />;
};

const Zoning = ({ unitSystem }: { unitSystem: System }) => (
  <PdfContext.Consumer>
    {({ development, tier }) => {
      const zoningDataIsAllowed = authentication.isUserAuthenticated() && tier === Tier.Pro;

      const { isNumeric } = valueFormatter;
      const parcelFeature = developmentAccessors.getParcel(development);
      const zoneId = parcelAccessors.getZoneId(parcelFeature);
      if (zoneId.length === 0) return null;

      const isMetricSystem = unitSystem === System.Metric;
      const buildableAreaValue = parcelAccessors.getAllowedBuildableAreaQuery(parcelFeature);

      const buildableArea = isMetricSystem
        ? buildableAreaValue
        : unitConversions.convert(buildableAreaValue, Unit.Type.SquareMeters, Unit.Type.SquareFeet);
      const livingDensityRatio = parcelAccessors.getLivingDensityRatio(parcelFeature);

      let numberOfUnitsAllowed = parcelAccessors.getNumberOfUnitsAllowed(parcelFeature);
      // Add number values, and keep the strings in the array.
      let totalUnitsAllowedNumber = 0;
      numberOfUnitsAllowed = numberOfUnitsAllowed.filter((value) => {
        if (isNumeric(value)) {
          totalUnitsAllowedNumber += Number(value);
          return false;
        }
        return true;
      });

      if (totalUnitsAllowedNumber) numberOfUnitsAllowed.unshift(totalUnitsAllowedNumber);
      const numberOfUnitsAllowedMap: Format.FormattingMap = (value) => ({
        value,
        formatOptions: {
          suffix: " units",
          type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText,
          hidden: !zoningDataIsAllowed,
          decimalPlaces: 2,
        },
      });
      const hiddenMap: Format.FormattingMap = (value) => ({
        value,
        formatOptions: { hidden: !zoningDataIsAllowed },
      });

      return (
        <Section title={"ZONING LIMITS"} titleIcon={zoningIcon}>
          {ConditionalRow(
            zoneId,
            <Row
              getter={valueGetters.generic}
              text="Zoning District"
              styleVariation="uppercase"
              formattingMap={hiddenMap}
            />
          )}
          {ConditionalRow(
            numberOfUnitsAllowed,
            <Row getter={valueGetters.generic} text="Estimated Units Allowed" formattingMap={numberOfUnitsAllowedMap} />
          )}
          {ConditionalRow(
            buildableArea,
            <Row
              getter={valueGetters.generic}
              text="Approximate Buildable Area"
              formatOptions={{
                type: isNumeric(buildableArea) ? Format.Type.Number : Format.Type.PlainText,
                suffix: isMetricSystem ? " m²" : " SF",
                hidden: !zoningDataIsAllowed,
              }}
            />
          )}
          {ConditionalRow(
            livingDensityRatio,
            <Row
              getter={valueGetters.generic}
              text="Residential Density"
              styleVariation="capitalize"
              formattingMap={hiddenMap}
            />
          )}
        </Section>
      );
    }}
  </PdfContext.Consumer>
);

export default Zoning;
