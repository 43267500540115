import { authenticationActions, authenticationActionTypes } from "../../../authentication";
import { developmentActions, developmentActionTypes } from "../../../development";
import actions from "./actions";
import actionTypes from "./actionTypes";

export interface RouteState {
  routeIsBlocked: boolean;
  pathName: string;
}

export const INITIAL_STATE: RouteState = {
  routeIsBlocked: false,
  pathName: "",
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: RouteState = INITIAL_STATE, action): RouteState => {
  switch (action.type) {
    case actionTypes.BLOCK_ROUTE:
      return blockRoute(previousState, action);
    case developmentActionTypes.SWITCH_DEVELOPMENT:
      return onSwitchDevelopment(previousState, action);
    case developmentActionTypes.DUPLICATE_START:
      return onDuplicateStart(previousState, action);
    case authenticationActionTypes.SIGN_OUT_START:
      return onSignOutStart(previousState, action);
    case authenticationActionTypes.SIGN_OUT_SUCCESS:
    case developmentActionTypes.LOAD_SUCCESS:
    case actionTypes.UNBLOCK_ROUTE:
      return unblockRoute(previousState, action);
    default:
      return previousState;
  }
};

/**
 * See `blockRoute` action creator.
 *
 * Set flag for blocking route and set original path.
 */
const blockRoute = (previousState, action: ReturnType<typeof actions.blockRoute>): RouteState => {
  return {
    ...previousState,
    routeIsBlocked: true,
    pathName: action.payload.routePathName,
  };
};

/**
 * See `unblockRoute` action creator.
 *
 * Set flag for blocking route and clear path name.
 */
const unblockRoute = (previousState: RouteState, action: ReturnType<typeof actions.unblockRoute>): RouteState => {
  return {
    ...previousState,
    routeIsBlocked: false,
    pathName: "",
  };
};

/**
 * Helper function to block router depending on the current value of project is edited
 */
const blockRouteOnDevelopmentEdit = (previousState: RouteState, currentProjectIsEdited: boolean): RouteState => {
  return {
    ...previousState,
    routeIsBlocked: currentProjectIsEdited,
  };
};

/**
 * See `developmentActions.switchDevelopment` action creator.
 *
 * Block route if development is edited on development switch action.
 */
const onSwitchDevelopment = (
  previousState: RouteState,
  action: ReturnType<typeof developmentActions.switchDevelopment>
): RouteState => {
  return blockRouteOnDevelopmentEdit(previousState, action.payload.currentDevelopmentIsEdited);
};

/**
 * See `developmentActions.duplicateStart` action creator.
 *
 * Block route if development is edited on development duplication action.
 */
const onDuplicateStart = (
  previousState: RouteState,
  action: ReturnType<typeof developmentActions.duplicateStart>
): RouteState => {
  return blockRouteOnDevelopmentEdit(previousState, action.payload.currentDevelopmentIsEdited);
};

/**
 * See `authenticationActions.signOutStart` action creator.
 *
 * Block route if development is edited on sign out action.
 */
const onSignOutStart = (
  previousState: RouteState,
  action: ReturnType<typeof authenticationActions.signOutStart>
): RouteState => {
  return blockRouteOnDevelopmentEdit(previousState, action.payload.currentDevelopmentIsEdited);
};

export default reducer;
