import defaultImageUrl from "assets/images/default-user-thumbnail.svg";
import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../../state/subscription";
import { subscriptionManagementActions, subscriptionManagementSelectors } from "../../../../../../state/ui/subscriptionManagement";
import { userSelectors } from "../../../../../../state/user";
import { Chargebee } from "../../../../../../types/Service/Chargebee";
import { Tier } from "../../../../../../types/Tier";
import authentication from "../../../../../../utils/authentication";
import dateHelper from "../../../../../../utils/dateHelper";
import NoCreditCardPopup from "../../../../../sharedComponents/NoCreditCardPopup";
import ResetPasswordButton from "./ResetPasswordButton";
import UpgradePopup from "./UpgradePopup";

const mapStateToProps = (state) => {
  return {
    userDocument: userSelectors.getUserDocument(state),
    imageUrl: userSelectors.getImageUrl(state),
    tier: subscriptionSelectors.getTier(state),
    planId: subscriptionSelectors.getPlanId(state),
    memberSince: subscriptionSelectors.getMemberSince(state),
    currentTermStart: subscriptionSelectors.getCurrentTermStart(state),
    currentTermEnd: subscriptionSelectors.getCurrentTermEnd(state),
    creditCard: subscriptionSelectors.getCreditCard(state),
    subscriptionStatus: subscriptionSelectors.getStatus(state),
    cancelSubscriptionPopupIsOpen: subscriptionManagementSelectors.getCancelSubscriptionPopupIsOpen(state),
  }
}

const mapDispatchToProps = {
  toggleCancelSubscriptionPopupIsOpen: subscriptionManagementActions.toggleCancelSubscriptionPopupIsOpen,
}

interface OwpProps {
  openEditing(): void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwpProps & StateProps & DispatchProps;

interface State {
  noCreditCardPopupIsOpen: boolean;
  upgradePopupIsOpen: boolean;
}

class DisplayInformation extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      noCreditCardPopupIsOpen: false,
      upgradePopupIsOpen: false,
    }
  }

  /**
   * Upgrade to PRO.
   */
  upgrade = async () => {
    const { creditCard } = this.props;

    if (!creditCard || creditCard.status === Chargebee.CardStatus.Expired) {
      this.toggleNoCreditCardPopupIsOpen();
      return;
    }

    this.toggleUpgradePopupIsOpen();
  }

  /**
   * Toggle the `noCreditCardPopupIsOpen` state.
   */
  toggleNoCreditCardPopupIsOpen = () => {
    this.setState({ noCreditCardPopupIsOpen: !this.state.noCreditCardPopupIsOpen });
  }

  /**
   * Toggle the `upgradePopupIsOpen` state.
   */
  toggleUpgradePopupIsOpen = () => {
    this.setState({ upgradePopupIsOpen: !this.state.upgradePopupIsOpen });
  }

  /**
   * Toggle the `cancelSubscriptionPopupIsOpen` state.
   */
  toggleCancelSubscriptionPopupIsOpen = () => {
    const user = authentication.getCurrentUser();
    if (!user) return;

    this.props.toggleCancelSubscriptionPopupIsOpen(!this.props.cancelSubscriptionPopupIsOpen, user.uid);
  }

  /**
   * Get number of days in the current subscription cycle.
   */
  getDaysInCycle = () => {
    const { currentTermStart, currentTermEnd } = this.props;
    if (!currentTermEnd || !currentTermStart) return null;

    const daysInCycle = (currentTermEnd - currentTermStart) / (24 * 60 * 60 * 1000);
    return daysInCycle;
  }

  /**
   * Get number of days until the next renewal.
   */
  getDaysToNextRenewal = () => {
    const { currentTermEnd } = this.props;
    if (!currentTermEnd) return null;

    return Math.floor((currentTermEnd - Date.now()) / (24 * 60 * 60 * 1000));
  }

  /**
   * Get the percentage of the current subscription cycle that has already passed.
   */
  getPercentageOfCurrentCycle = () => {
    const { currentTermStart } = this.props;
    if (!currentTermStart) return null;

    const daysInCycle = this.getDaysInCycle();
    if (!daysInCycle) return null;

    const daysSoFar = (Date.now() - currentTermStart) / (24 * 60 * 60 * 1000);
    return Math.ceil((daysSoFar / daysInCycle) * 100);
  }

  /**
   * Render the Type of Subscription.
   */
  renderTypeOfAccount = (subscriptionPeriod, tier) => {
    const isDeveloperTier = tier === Tier.Developer;
    const subcriptionType = isDeveloperTier ? "DEV" : "PRO"
    return <p>{`${subcriptionType} ${subscriptionPeriod}`}</p>
  }

  /**
   * Render the Subscription Status.
   */
  renderSubscriptionStatus = (daysToNextRenewal: number, percentageOfCycle: number) => {
    const {subscriptionStatus, tier} = this.props;
    const isProSubscription = tier === Tier.Pro;
    const subscriptionType = isProSubscription ? "PRO" : "Developer";
    return (
      <>
        <span>
          {`${subscriptionStatus === Chargebee.SubscriptionStatus.Active
            ? "Next Auto-Renewal"
            : `${subscriptionType} subscription expires`
            } in ${daysToNextRenewal} days`
          }
        </span>
        <div className="bar" />
        <div className="bar active" style={{ width: `${percentageOfCycle}%` }} />
      </>
    )
  }

  render() {
    const { userDocument, tier, planId, imageUrl, memberSince, subscriptionStatus, openEditing } = this.props;
    const { noCreditCardPopupIsOpen, upgradePopupIsOpen } = this.state;
    const isFreeTier = tier === Tier.Free;
    const user = authentication.getCurrentUser();
    let subscriptionPeriod = "";
    if (planId) {
      subscriptionPeriod = planId.includes("annual") ? "Yearly" : "Monthly";
    }
    if (!userDocument || !user) return null;

    const isProTier = tier === Tier.Pro;
    const fullName = userDocument.firstName + `${userDocument.lastName ? ` ${userDocument.lastName}` : ""}`;
    const daysToNextRenewal = this.getDaysToNextRenewal();
    const percentageOfCycle = this.getPercentageOfCurrentCycle();
    return (
      <div className="component--display-information">
        <div className="information-container">
          <div className="thumbnail-container">
            <div
              className="thumbnail"
              style={{ backgroundImage: `url(${imageUrl ? `${imageUrl}` : `${defaultImageUrl}`})` }}
            />
          </div>
          <div className="data-container">
            <div className="name">
              <div className="bold">{fullName}</div>
              {userDocument.email}
            </div>
            <div className="subscription-container">
              <div className="section">
                <div className="section-header">Subscription</div>
                <div className="section-content">
                  {!(tier === Tier.Free) && <div className={`marker ${subscriptionStatus}`} />}
                  {tier === Tier.Free
                    ? <p className="subscription-status-label">INACTIVE ACCOUNT</p>
                    : this.renderTypeOfAccount(subscriptionPeriod, tier)
                  }
                </div>
              </div>
              <div className="section">
                <div className="section-header">Member Since</div>
                <div className="section-content">
                  {dateHelper.getDate(memberSince || userDocument.timeCreated)}
                </div>
              </div>
            </div>
           <div className="bottom">
              {(daysToNextRenewal && percentageOfCycle && !isFreeTier) ? this.renderSubscriptionStatus(daysToNextRenewal, percentageOfCycle) : null}
            </div>
          </div>
        </div>
        <div className="buttons-wrapper">
          <button onClick={openEditing}>Edit Information</button>
          <ResetPasswordButton />

          {tier === Tier.Free
            ? <button className="upgrade" onClick={this.upgrade}>Activate PRO</button>
            : subscriptionStatus === Chargebee.SubscriptionStatus.NonRenewing
              ? <button className="upgrade" onClick={this.upgrade}>{`Activate ${isProTier ? "PRO" : "Developer"}`}</button>
              : <button onClick={this.toggleCancelSubscriptionPopupIsOpen}>{`Cancel ${isProTier ? "PRO" : "Developer"}`}</button>
          }
        </div>

        {noCreditCardPopupIsOpen && <NoCreditCardPopup onStateChange={this.toggleNoCreditCardPopupIsOpen} />}
        {upgradePopupIsOpen && <UpgradePopup onStateChange={this.toggleUpgradePopupIsOpen} />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayInformation);
