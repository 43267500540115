import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { developmentActions } from "../../../../state/development";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";

const mapDispatchToProps = {
  saveDevelopment: developmentActions.saveStart,
};

type DispatchProps = typeof mapDispatchToProps;

const SignUpButton: React.FC<DispatchProps> = ({ saveDevelopment }) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Save current project and redirect to subscribe.
   */
  const redirectToSubscribe = async () => {
    if (location.pathname === Path.Explorer) saveDevelopment();

    navigate(Path.Subscribe);
  };

  const userIsAuthenticated = authentication.isUserAuthenticated();
  if (userIsAuthenticated) return null;

  return (
    <div className="component--button sign-up" onClick={redirectToSubscribe}>
      <div className="button" />
      <div className="text">Sign Up</div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(SignUpButton);
