import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the hotel buildables presets.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.HotelLossFactor) as number;
  const unitArea = developmentAccessors.getInputValue(development, VariableId.HotelAverageRoomArea) as number;

  const usableArea = (targetArea * (1 - lossFactor));
  let amenitiesArea = usableArea * 0.05;

  // Guard against amenities consuming all of the usable area.
  if (usableArea - amenitiesArea < unitArea) amenitiesArea = 0;

  const areaAvailableForUnits = usableArea ? usableArea - amenitiesArea : 0;
  const unitQuantity = unitArea ? Math.floor(areaAvailableForUnits / unitArea) : 0;
  const leftoverArea = areaAvailableForUnits - (unitQuantity * unitArea);
  amenitiesArea += leftoverArea;

  const previousMaximumUnits = developmentAccessors.getInputMaximum(development, VariableId.HotelRoomQuantityToggleable);
  const previousMaximumAmenities = developmentAccessors.getInputMaximum(development, VariableId.HotelAmenitiesAreaToggleable);

  return {
    [VariableId.HotelRoomQuantityToggleable]: {
      "minimum": 0,
      "value": unitQuantity,
      "maximum": 2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.HotelAmenitiesAreaToggleable]: {
      "minimum": 0,
      "value": amenitiesArea,
      "maximum": 10 * amenitiesArea || previousMaximumAmenities
    },
    [VariableId.HotelStabilizedAverageDailyRate]: await generatorFromModel.generateRentPreset(development, VariableId.HotelStabilizedAverageDailyRate, BuildingUse.Hotel, unitArea, 365),
    [VariableId.HotelHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.HotelHardCostPerArea, BuildingUse.Hotel), // TODO
  };
}

export default generatePresets;
