import React from "react";
import { connect } from "react-redux";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import Cell from "../../../Cell";
import CellRow from "../../../CellRow";
import CellText from "../../../CellText";

type OwnProps = {
  parcelFeature: any;
}

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render Allowed Uses Details.
 */
const renderDetails = (parcelFeature, userHasAccess) => {
  const allowedUses = parcelAccessors.getAllowedUses(parcelFeature);
  const mainUseCategories = Object.keys(allowedUses).sort();
  const hasDetailedUses = mainUseCategories.some((mainCategory) => allowedUses[mainCategory].length > 0);
  const hiddenMap: Format.FormattingMap = (value) => ({ value, formatOptions: { hidden: !userHasAccess } });

  if (!hasDetailedUses) return null;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Uses Breakdown</th>
        </tr>
      </thead>
      <tbody>
        {mainUseCategories.map((mainCategory) => {
          if (allowedUses[mainCategory].length === 0) return null;
          return (
            <CellRow key={mainCategory}>
              <CellText text={`${mainCategory}`} styleVariation="capitalize" hiddenText={!userHasAccess} />
              <Cell getter={valueGetters.generic} value={allowedUses[mainCategory].sort()} styleVariation="bold capitalize" formattingMap={hiddenMap} />
            </CellRow>
          );
        })}
      </tbody>
    </table>
  );
}

/**
 * Render Allowed uses section.
 */
const AllowedUses = (props: Props) => {
  const { parcelFeature, tier } = props;
  if (!parcelFeature) return null;
  const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);
  const allowedUses = parcelAccessors.getAllowedUses(parcelFeature);
  const mainUseCategories = Object.keys(allowedUses).sort();

  if (mainUseCategories.length === 0) return null;

  return (
    <>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>Uses Allowed (by right)</th>
          </tr>
        </thead>
        <tbody>
          {mainUseCategories.map((mainUse, index) => (
            <CellRow classes="center" key={index}>
              <Cell getter={valueGetters.generic} value={mainUse} styleVariation="bold capitalize" tdElementProps={{ colSpan: 2 }} formatOptions={{ hidden: !userHasAccess }} />
            </CellRow>
          ))}
        </tbody>
      </table>
      {renderDetails(parcelFeature, userHasAccess)}
    </>
  );
}

export default connect(
  mapStateToProps,
)(AllowedUses);
