import * as firebase from "firebase/app";
import "firebase/firestore";
import databaseHelper from "./utils/databaseHelper";
import DocumentNotFoundError from "../../errors/DocumentNotFoundError";

/**
 * Create sharedProject document for the given project id.
 */
const create = async (projectId: string) => {
  const sharedProjectReference = getSharedProjectsReference(projectId);
  await databaseHelper.createDocument(sharedProjectReference, {});
}

/**
 * Read and return if the sharedProject exists.
 */
const read = async (projectId: string): Promise<object | null> => {
  try {
    const sharedProjectReference = getSharedProjectsReference(projectId);
    return await databaseHelper.getDocument(sharedProjectReference);
  } catch (error) {
    if (error instanceof DocumentNotFoundError) return null;
    console.error(error);
  }

  return null;
}

/**
 * Return reference to the sharedProject collection.
 */
const getSharedProjectsReference = (projectId) => {
  return firebase.firestore().doc(`sharedProjects/${projectId}`);
}

export default {
  create,
  read,
}
