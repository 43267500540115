import React from "react";
import { connect } from "react-redux";
import { authenticationActions } from "../../../../state/authentication";
import { developmentSelectors } from "../../../../state/development";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import { NavigateFunction, useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    developmentIsEdited: developmentSelectors.getIsEdited(state),
  };
};

const mapDispatchToProps = {
  signOutStart: authenticationActions.signOutStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & StateProps;

const SignOutDropdownButton: React.FC<Props> = ({ developmentIsEdited, signOutStart }) => {
  const navigate: NavigateFunction = useNavigate();
  /**
   * Signs out the user.
   */
  const handleSignOutClick = async () => {
    signOutStart(developmentIsEdited, {
      navigate,
      pathName: Path.Home,
    });
  };

  const userIsAuthenticated = authentication.isUserAuthenticated();

  return userIsAuthenticated ? (
    <li key="sign-out" onClick={handleSignOutClick}>
      <div className="button">Log Out</div>
    </li>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOutDropdownButton);
