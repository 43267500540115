import React from "react";
import { connect } from "react-redux";
import { setbackModeSelectors } from "state/setbackMode";
import { authenticationSelectors } from "../../../../state/authentication";
import { developmentActions } from "../../../../state/development";
import { explorerActions } from "../../../../state/ui/explorer";
import { Path } from "../../../../types/Path";
import AuthenticationButtons from "../../../sharedComponents/AuthenticationButtons";
import Logo from "../../../sharedComponents/Logo";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import { subscriptionSelectors } from "state/subscription";
import ZoningReportButtonLink from "views/sharedComponents/ZoningReportButtonLink/ZoningReportButtonLink";
import { Tier } from "types/Tier";

const mapStateToProps = (state) => {
  return {
    userIsAuthenticated: authenticationSelectors.getUserIsAuthenticated(state),
    setbackModeIsActive: setbackModeSelectors.getSetbackModeIsActive(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  saveDevelopment: developmentActions.saveStart,
  toggleCopyProjectPopup: explorerActions.toggleCopyProjectPopup,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & StateProps;

class ExplorerToolbar extends React.Component<Props, {}> {
  /**
   * Render the copy project dropdown button.
   */
  renderCopyProjectDropdownButton = () => (
    <li key="copy-project" onClick={this.props.toggleCopyProjectPopup}>
      <div className="button">Copy Project</div>
    </li>
  );

  render() {
    const { userIsAuthenticated, setbackModeIsActive, tier } = this.props;
    const renderCopyProjectOption = userIsAuthenticated && !setbackModeIsActive;
    const isProTier = tier === Tier.Pro;

    return (
      <div className="component--explorer-toolbar">
        {!isProTier && <ZoningReportButtonLink />}
        <div className="explorer-toolbar">
          <Logo />
          <AuthenticationButtons />

          <ToolbarSettings>
            {userIsAuthenticated && (
              <RedirectDropdownButton path={Path.NewProject} text="New Project" onClick={this.props.saveDevelopment} />
            )}
            {renderCopyProjectOption && this.renderCopyProjectDropdownButton()}
            {userIsAuthenticated && (
              <RedirectDropdownButton
                path={Path.Dashboard}
                shouldContinue={() => userIsAuthenticated}
                text="Dashboard"
                onClick={this.props.saveDevelopment}
              />
            )}
          </ToolbarSettings>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerToolbar);
