import React from 'react';
import { SlideshowType } from '../../../../types/Slideshow';
import config from './config';
import wrapComponentWithPopup from '../../../sharedComponents/wrapComponentWithPopup';
import { PopupProps } from '../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup';

interface OwnProps {
  slideshowType: SlideshowType;
}

type Props = OwnProps & PopupProps;

interface State {
  index: number;
  isActive: boolean;
}

class WelcomeSlideshow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
      isActive: true,
    };
  }

  /**
   * Render content section.
   */
  renderCurrentSlideshow = (label, text, className) => {
    return (
      <div className="content">
        <div className={`image ${className}`} />
        <div>
          <label>{label}</label>
          <p>{text}</p>
        </div>
      </div>
    );
  }

  /**
   * Render Current slideshow feedback dots.
   */
  renderCurrentSlideshowDots = () => {
    return (
      <div className="dots-container">
        {config[this.props.slideshowType].map((value, index) =>
          <div key={index} onClick={() => this.setIndex(index)} className={`dot ${index === this.state.index ? "active" : ""}`} />
        )}
      </div>
    );
  }

  /**
   * Set the index to the specified value.
   */
  setIndex = (index: number) => {
    this.setState({ index });
  }

  /**
   * Increase index to show next slider.
   */
  showNextSlider = () => {
    if (this.state.index === config[this.props.slideshowType].length - 1) {
      this.props.closePopup();
    } else {
      this.setState({ index: this.state.index + 1 });
    }
  }

  /**
   * Render navigation buttons.
   */
  renderButtons = () => {
    let isLastIndex = this.state.index === config[this.props.slideshowType].length - 1;

    return (
      <>
        <div className="buttons-container">
          <button className="next-button" onClick={this.showNextSlider}>{isLastIndex ? "Start" : "Next"}</button>
          {!isLastIndex && <p className="skip" onClick={this.props.closePopup}>Skip</p>}
        </div>
      </>
    );
  }

  render() {
    if (!this.state.isActive) return null;
    const { label, text, className } = config[this.props.slideshowType][this.state.index];

    return (
      <div className="component--welcome-slideshow">
        <div className="header">Available in this city</div>
        {this.renderCurrentSlideshow(label, text, className)}
        {this.renderCurrentSlideshowDots()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default wrapComponentWithPopup(WelcomeSlideshow);
