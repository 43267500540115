import { Map } from "mapbox-gl";
import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../state/subscription";
import { mapsSelectors } from "../../../state/ui/shared/maps";
import { BuildingUse } from "../../../types/BuildingUse";
import { LayerSector } from "../../../types/LayerSector";
import { Tier } from "../../../types/Tier";
import layerHelper, {
  LayerConfigurationsLayerIds,
} from "../../../utils/mapbox/layerHelper";
import { MapStyleProperties } from "../../../utils/mapbox/mapStyleProperties";
import { usageColor } from "../../utils/buildingUsageProperties";
import { colorFor } from "../../utils/colors";
import BusySpinner from "../BusySpinner";
import DynamicLayerPanel from "../DynamicLayerSelection/DynamicLayerPanel";
import dynamicLayersConfig from "../DynamicLayerSelection/dynamicLayersConfig";
import EmploymentPerPopulationDynamicLayerSlider from "../DynamicLayerSelection/DynamicSliders/EmploymentPerPopulationDynamicLayerSlider";
import MedianIncomeDynamicSlider from "../DynamicLayerSelection/DynamicSliders/MedianIncomeDynamicSlider";
import MedianRentDynamicLayerSlider from "../DynamicLayerSelection/DynamicSliders/MedianRentDynamicLayerSlider";
import PopulationDynamicLayerSlider from "../DynamicLayerSelection/DynamicSliders/PopulationDynamicLayerSlider";
import SeaLevelRiseDynamicLayerSlider from "../DynamicLayerSelection/DynamicSliders/SeaLevelRiseDynamicLayerSlider";
import Tabs from "../Tabs";
import Tab from "../Tabs/Tab";
import TabContainer from "../Tabs/TabContainer";
import WithPanelHeader from "../WithPanelHeader";
import DemoStaticLayerPanel from "./DemoStaticLayerPanel";
import StaticLayerPanel from "./StaticLayerPanel";

const mapStateToProps = (state) => {
  return {
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    mapIsReady: mapsSelectors.getMapIsReady(state),
    tier: subscriptionSelectors.getTier(state),
    getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  };
};

interface OwnProps {
  map: Map | undefined;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

const LAYER_SECTOR_ORDER = [
  LayerSector.Zoning,
  LayerSector.Census,
  LayerSector.Rates,
  LayerSector.Location,
  LayerSector.Environmental,
  LayerSector.Borders,
  LayerSector.Incentives,
];

const LAYER_SECTOR_CSS_MAP = {
  [LayerSector.Zoning]: "zoning",
  [LayerSector.Census]: "census",
  [LayerSector.Rates]: "rates",
  [LayerSector.Location]: "local",
  [LayerSector.Incentives]: "incentives",
  [LayerSector.Borders]: "borders",
  [LayerSector.Environmental]: "environmental",
};

const LAYER_SECTOR_LABEL_MAP = {
  [LayerSector.Zoning]: "zoning",
  [LayerSector.Census]: "census",
  [LayerSector.Rates]: "rates",
  [LayerSector.Location]: "location",
  [LayerSector.Incentives]: "incentives",
  [LayerSector.Borders]: "borders",
  [LayerSector.Environmental]: "env.",
};

const LAYER_SECTOR_COLOR_MAP = {
  [LayerSector.Zoning]: colorFor("layer-sector-general"),
  [LayerSector.Census]: colorFor("layer-sector-people"),
  [LayerSector.Rates]: usageColor(BuildingUse.Multifamily),
  [LayerSector.Location]: usageColor(BuildingUse.Hotel),
  [LayerSector.Incentives]: usageColor(BuildingUse.Office),
  [LayerSector.Borders]: usageColor(BuildingUse.Retail),
  [LayerSector.Environmental]: usageColor(BuildingUse.Industrial),
};

class LayerSelection extends React.PureComponent<Props, {}> {
  onStyleLoadIsInitialized: boolean;

  constructor(props: Props) {
    super(props);

    this.onStyleLoadIsInitialized = false;

    if (props.map) {
      this.addOnStyleDataListener();
    }
  }

  /**
   * Remove listener from maps.
   */
  componentWillUnmount() {
    if (this.props.map) {
      this.props.map.off("styledata", this.updateMapLayersOpacity);
    }
  }

  /**
   * Add listener to maps for styledata event.
   * This event fires when the map's style loads or change.
   */
  addOnStyleDataListener = () => {
    if (this.props.map) {
      this.props.map.on("styledata", this.updateMapLayersOpacity);
      this.onStyleLoadIsInitialized = true;
    }
  };

  /**
   * Update visibility of layers on the map based on current layer configurations.
   */
  componentDidUpdate() {
    const { map } = this.props;

    if (map) {
      if (!this.onStyleLoadIsInitialized) {
        this.addOnStyleDataListener();
      }

      this.updateMapLayersOpacity();
    }
  }

  /**
   * Update layers visibility based on current layer configurations.
   */
  updateMapLayersOpacity = () => {
    const { map, layerConfigurations } = this.props;

    if (map) {
      Object.keys(layerConfigurations).forEach((layerId) => {
        if (!map.getLayer(layerId)) return;

        const castedLayerId = layerId as LayerConfigurationsLayerIds;
        const layerConfiguration = layerConfigurations[castedLayerId];

        const currentLayerPaint = layerHelper.getLayerOpacity(
          map,
          castedLayerId,
          layerConfigurations[castedLayerId].layerType
        );

        if (
          layerConfiguration.layerType === MapStyleProperties.LayerType.Symbol
        ) {
          // Special logic for when feature type is Symbol, since there are two potential values to lookout for (i.e. iconOpacity and textOpacity).
          if (layerConfiguration.isActive) {
            // Symbol layer is active
            if (
              (layerConfiguration.paint.iconOpacity !== undefined &&
                layerConfiguration.paint.iconOpacity !==
                  currentLayerPaint.iconOpacity) ||
              (layerConfiguration.paint.textOpacity !== undefined &&
                layerConfiguration.paint.textOpacity !==
                  currentLayerPaint.textOpacity)
            ) {
              layerHelper.setLayerOpacity(
                map,
                castedLayerId,
                layerConfiguration.layerType,
                {
                  ...layerConfiguration.paint,
                }
              );
            }
          } else {
            if (
              (layerConfiguration.paint.iconOpacity !== undefined &&
                layerConfiguration.paint.iconOpacity !== 0) ||
              (layerConfiguration.paint.textOpacity !== undefined &&
                layerConfiguration.paint.textOpacity !== 0)
            ) {
              // Symbol layer is not active
              layerHelper.setLayerOpacity(
                map,
                castedLayerId,
                layerConfiguration.layerType,
                {
                  textOpacity: 0,
                  iconOpacity: 0,
                }
              );
            }
          }
        } else {
          // Common logic for all other layer types.
          if (
            layerConfiguration.isActive &&
            layerConfiguration.paint.opacity !== currentLayerPaint.opacity
          ) {
            layerHelper.setLayerOpacity(
              map,
              castedLayerId,
              layerConfiguration.layerType,
              {
                opacity: layerConfiguration.paint.opacity,
                strokeOpacity: layerConfiguration.paint.strokeOpacity,
              }
            );
          } else if (
            !layerConfiguration.isActive &&
            currentLayerPaint.opacity !== 0
          ) {
            layerHelper.setLayerOpacity(
              map,
              castedLayerId,
              layerConfiguration.layerType,
              {
                opacity: 0,
                strokeOpacity: 0,
              }
            );
          }
        }
      });
    }
  };

  /**
   * Render the tab button for the layer sector.
   */
  renderTabButton = (layerSector: LayerSector, activeTab: LayerSector) => {
    let color =
      layerSector === activeTab
        ? LAYER_SECTOR_COLOR_MAP[layerSector]
        : undefined;

    return (
      <div
        className={`sector-icon ${LAYER_SECTOR_CSS_MAP[layerSector]}`}
        style={{ backgroundColor: color }}
      />
    );
  };

  /**
   * Render Dynamic Layer Sliders depending on the selected layer.
   */
  renderDynamicLayerSlider = () => {
    const { getSelectedDynamicLayer } = this.props;
    if (getSelectedDynamicLayer) {
      switch (getSelectedDynamicLayer) {
        case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population:
          return <PopulationDynamicLayerSlider />;
        case MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent:
          return <MedianRentDynamicLayerSlider />;
        case MapStyleProperties.DynamicLayerId
          .Dynamic_Layer_Employment_Per_Population:
          return <EmploymentPerPopulationDynamicLayerSlider />;
        case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income:
          return <MedianIncomeDynamicSlider />;
        case MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise:
          return <SeaLevelRiseDynamicLayerSlider />;
        default:
          return;
      }
    }
    return;
  };

  /**
   * Render main content of the component.
   */
  renderContent = () => {
    const { getSelectedDynamicLayer, tier } = this.props;
    const isDemo = tier === Tier.None;
    const isDynamicLayerActivated = getSelectedDynamicLayer !== null;
    let selectedDynamicLayerSector: LayerSector | undefined = undefined;
    getSelectedDynamicLayer !== null &&
      (selectedDynamicLayerSector =
        dynamicLayersConfig[getSelectedDynamicLayer].layerSector);

    return (
      <>
        <Tabs defaultTab={LayerSector.Zoning}>
          {(onTabClick, activeTab) => (
            <>
              <TabContainer>
                {LAYER_SECTOR_ORDER.map((layerSector) => (
                  <div className="sector-box" key={`sector_${layerSector}`}>
                    <Tab
                      tabId={layerSector}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                      button={this.renderTabButton(
                        layerSector,
                        activeTab as LayerSector
                      )}
                      key={`layer_sector_${layerSector}`}
                    />
                    <p className="sector-label">
                      {LAYER_SECTOR_LABEL_MAP[layerSector]}
                    </p>
                  </div>
                ))}
              </TabContainer>

              {!isDemo ? (
                layerHelper.containsAvaliableStaticLayers(activeTab) && (
                  <StaticLayerPanel layerSector={activeTab as LayerSector} />
                )
              ) : (
                <DemoStaticLayerPanel layerSector={activeTab} />
              )}

              {layerHelper.sectorContainsDynamicLayers(activeTab) &&
                !isDemo && (
                  <>
                    <DynamicLayerPanel layerSector={activeTab} />
                    {isDynamicLayerActivated &&
                      selectedDynamicLayerSector &&
                      selectedDynamicLayerSector === activeTab &&
                      this.renderDynamicLayerSlider()}
                  </>
                )}
            </>
          )}
        </Tabs>
      </>
    );
  };

  render() {
    if (!this.props.map) return null;
    if (this.props.tier === Tier.Developer) return null;
    const displayLegend = layerHelper.shouldDisplayLegend(
      this.props.layerConfigurations
    );
    if (!displayLegend) return null;

    return (
      <WithPanelHeader title="Map Layers" classes="first-child">
        <div className="component--layer-selection">
          {this.props.mapIsReady ? this.renderContent() : <BusySpinner />}
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(mapStateToProps)(LayerSelection);
