import { useEffect } from "react";
import {
  ResetDevelopmentStateFunction,
  SetDevelopmentIsReadyFunction,
} from "state/development/types";
import { ClearPdfStateFunction } from "state/pdf/types";
import { SetSetbackModeFunction } from "state/setbackMode/types";
import { ResetProjectInfoPanelUiFunction } from "state/ui/shared/accordionSection/types";
import { ResetFlagsFunction } from "state/ui/shared/maps/types";
import { UnblockRouteFunction } from "state/ui/shared/route/types";
import { Path } from "types/Path";
import analytics from "utils/analytics";

export const useExplorerPageConstructor = (
  unblockRoute: UnblockRouteFunction,
  clearPdfState: ClearPdfStateFunction,
  resetFlags: ResetFlagsFunction,
  resetProjectInfoPanelUi: ResetProjectInfoPanelUiFunction,
  userIsAuthenticated: boolean | null,
  resetDevelopmentState: ResetDevelopmentStateFunction,
  setDevelopmentIsReady: SetDevelopmentIsReadyFunction,
  initialize: () => Promise<void>,
  setSetbackMode: SetSetbackModeFunction,
  setbackModeIsActive: boolean
) => {
  useEffect(() => {
    const historyUnblock = () => {
      unblockRoute();
      clearPdfState();
      resetFlags();
      resetProjectInfoPanelUi();
    };
    analytics.trackPageview(Path.Explorer);
    initialize();

    return () => {
      historyUnblock;
      unblockRoute();
      clearPdfState();
      resetFlags();
      resetProjectInfoPanelUi();
      setSetbackMode(false);
      if (userIsAuthenticated) {
        resetDevelopmentState();
      } else {
        setDevelopmentIsReady(false);
      }
    };
  }, []);
};
