import React from "react";
import { Layer } from "react-mapbox-gl";
import { mapsSelectors } from "state/ui/shared/maps";
import { MapStyleProperties } from "../../../../../../utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "../../../../../utils/mapboxPresentationProperties";
import { RawDynamicLayersFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { connect } from "react-redux";
import { paintDemographicDynamicLayerFill } from "utils/mapbox/layerPaintersHelper";

const FIELD_TO_FILL_LAYER_ID = {
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2013]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2014]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2015]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2016]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2017]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2018]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2019]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2020]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2021]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2022]: MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022,
};

const FIELD_TO_BORDER_LAYER_ID = {
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2013]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2014]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2015]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2016]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2017]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2018]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2019]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2020]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2021]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2022]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022,
};

const FIELD_TO_SYMBOL_LAYER_ID = {
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2013]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2013,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2014]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2015]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2016]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2017]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2018]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2019]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2020]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2021]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021,
  [RawDynamicLayersFieldId.EmploymentPerPopRatio2022]:
    MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022,
};

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DynamicEmploymentPerPopLayer extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer) return null;
    if (getSelectedDynamicLayer !== MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population)
      return null;

    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    return (
      <>
        <Layer
          id={FIELD_TO_FILL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Fill}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={paintDemographicDynamicLayerFill(
            layerSelected,
            MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
          )}
          minZoom={10}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={FIELD_TO_BORDER_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Line}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          paint={mapboxPresentationProperties.demographicsLinePaint(layerSelected)}
          minZoom={10}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        <Layer
          id={FIELD_TO_SYMBOL_LAYER_ID[layerSelected]}
          type={MapStyleProperties.LayerType.Symbol}
          sourceId={MapStyleProperties.DynamicLayers.DemographicSourceId}
          sourceLayer={MapStyleProperties.DynamicLayers.DemographicSourceLayer}
          layout={mapboxPresentationProperties.demographicsLabelsSymbolLayout(layerSelected, { suffix: "%" })}
          paint={mapboxPresentationProperties.rentLayersSymbolPaint}
          minZoom={12}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
      </>
    );
  }
}

export default connect(mapStateToProps)(DynamicEmploymentPerPopLayer);
