import downloadBlobAsFile from "utils/downloadBlobAsFile";
import { VariableId } from "types/VariableId";
import unitConversions from "utils/unitConversions";
import Unit from "types/Unit";
import { BuildingUse } from "types/BuildingUse";
import config, {
  BUILDING_PROGRAM_SECTION_CSV,
  CONDO_ASSUMPTIONS_SECTION,
  COST_ASSUMPTIONS_SECTION_CSV,
  CsvRows,
  HOTEL_ASSUMPTIONS_SECTION_CSV,
  INCOME_ASSUMPTIONS_SECTION_CSV,
  INDUSTRIAL_ASSUMPTIONS_SECTION,
  MULTIFAMILY_ASSUMPTIONS_SECTION_CSV,
  OFFICE_ASSUMPTIONS_SECTION,
  ProjectData,
  PROPERTY_SECTION_CSV,
  RETAIL_ASSUMPTIONS_SECTION_CSV,
  SALES_ASSUMPTIONS_SECTION_CSV,
} from "./config";
import Papa from "papaparse";

interface CsvNewRow {
  Name: string;
  Value?: string;
  Suffix?: string;
}

const CSV_FILENAME = "Deepblocks Project Assumptions.csv";

enum SectionTitle {
  Location = "Location",
  Property = "Property",
  BuildingProgram = "Building Program",
  IncomeAssumptions = "Income Assumptions",
  SalesAssumptions = "Sales Assumptions",
  CostAssumptions = "Cost Assumptions",
  MultifamilyAssumptions = "Multifamily Assumptions",
  RetailAssumptions = "Retail Assumptions",
  HotelAssumptions = "Hotel Assumptions",
  OfficeAssumptions = "Office Assumptions",
  IndustrialAssumptions = "Industrial Assumptions",
  CondoAssumptions = "Condo Assumptions",
}

/**
 * Check if there is a variableId inside the custom slider names object
 */
const isCustomSliderName = (allCustomSliderNames, variableId: VariableId) => {
  return Object.keys(allCustomSliderNames).some(
    (customName) => customName === variableId
  );
};

/**
 * Add title row to the CSV data.
 */
const addTitleRowToCSV = (
  titleText: string,
  currentCsvData: Array<CsvNewRow>
) => {
  const newRow: CsvNewRow = { Name: titleText, Value: "" };
  currentCsvData.push(newRow);
  return currentCsvData;
};

/**
 * Add section to the CSV data.
 */
const addSectionToCSV = (
  sectionToAdd: Array<CsvRows>,
  currentCsvData: Array<CsvNewRow>,
  usageGroupList: BuildingUse,
  allCustomSliderNames: any,
  unitSystem: Unit.System,
  values: any,
  projectIsForSale?: boolean
): Array<CsvNewRow> => {
  for (const {
    rowName,
    variableId,
    usageGroup,
    formatter,
    parentCustomName,
    suffix,
  } of sectionToAdd) {
    if (usageGroup === BuildingUse.Project || usageGroupList[usageGroup]) {
      const isReturnOnCostValue =
        variableId ===
        VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope;
      let updatedVariableId =
        isReturnOnCostValue && projectIsForSale
          ? VariableId.SaleReturnOnInvestmentForBackOfEnvelope
          : variableId;
      const configProperties = config[updatedVariableId];

      const customSliderName = isCustomSliderName(
        allCustomSliderNames,
        updatedVariableId
      );
      const childOfCustomName =
        parentCustomName &&
        isCustomSliderName(allCustomSliderNames, parentCustomName);

      const csvRowName = customSliderName
        ? allCustomSliderNames[updatedVariableId].customName
        : parentCustomName && childOfCustomName
        ? allCustomSliderNames[parentCustomName].customName
        : rowName;

      const formattedRowName = suffix ? `${csvRowName} ${suffix}` : csvRowName;

      if (parentCustomName && values[parentCustomName] === 0) {
        const newRow: CsvNewRow = { Name: formattedRowName, Value: "0" };
        currentCsvData.push(newRow);
      } else {
        if (configProperties) {
          const unitTarget = configProperties[unitSystem]
            ?.unitTarget as Unit.Type;
          const unitIsInverse = configProperties[unitSystem]?.unitIsInverse;
          const isDensityRatio =
            updatedVariableId === VariableId.UnitsPerParcelArea;
          const convertValue =
            unitSystem === Unit.System.Imperial && unitTarget;

          const convertedValue = convertValue
            ? unitConversions.convertFromBase(
                values[updatedVariableId],
                unitTarget,
                unitIsInverse
              )
            : isDensityRatio
            ? values[updatedVariableId] * 10000
            : values[updatedVariableId];

          const newRow: CsvNewRow = {
            Name: formattedRowName,
            Value: formatter(convertedValue, unitSystem),
          };
          currentCsvData.push(newRow);
        }
      }
    } else {
      const formattedRowName = suffix ? `${rowName} ${suffix}` : rowName;
      const newRow: CsvNewRow = { Name: formattedRowName, Value: "0" };
      currentCsvData.push(newRow);
    }
  }
  return currentCsvData;
};

/**
 * Add Project Data row to the CSV.
 */
const addProjectDataRowToCSV = (
  rowName: string,
  value: string,
  currentCsvData: Array<CsvNewRow>
) => {
  const newRow: CsvNewRow = { Name: rowName, Value: value };
  currentCsvData.push(newRow);

  return currentCsvData;
};

/**
 * Extracts and formats the predetermined set of values from the given values
 * and returns them in an array of new rows.
 */
const getFormattedProjectValues = (
  values: any,
  unitSystem: Unit.System,
  usageGroupList: BuildingUse,
  allCustomSliderNames: any,
  developmentName: string,
  developmentAddress: string,
  developmentCity: string,
  developmentState: string,
  isAnAssembly: boolean,
  projectIsForSale: boolean
) => {
  let result = new Array<CsvNewRow>();

  result = addTitleRowToCSV(SectionTitle.Location, result);
  result = addProjectDataRowToCSV(ProjectData.Name, developmentName, result);
  result = addProjectDataRowToCSV(
    ProjectData.Address,
    developmentAddress,
    result
  );

  const formattedCityStateRowName = `${ProjectData.City}, ${ProjectData.State}`;
  const formattedCityStateRowValue =
    developmentCity !== "" && developmentState !== ""
      ? `${developmentCity}, ${developmentState}`
      : "";
  result = addProjectDataRowToCSV(
    formattedCityStateRowName,
    formattedCityStateRowValue,
    result
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.Property, result);
  result = addSectionToCSV(
    PROPERTY_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.BuildingProgram, result);
  result = addSectionToCSV(
    BUILDING_PROGRAM_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.IncomeAssumptions, result);
  result = addSectionToCSV(
    INCOME_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values,
    projectIsForSale
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.SalesAssumptions, result);
  result = addSectionToCSV(
    SALES_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.CostAssumptions, result);
  result = addSectionToCSV(
    COST_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.MultifamilyAssumptions, result);
  result = addSectionToCSV(
    MULTIFAMILY_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.RetailAssumptions, result);
  result = addSectionToCSV(
    RETAIL_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.HotelAssumptions, result);
  result = addSectionToCSV(
    HOTEL_ASSUMPTIONS_SECTION_CSV,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.OfficeAssumptions, result);
  result = addSectionToCSV(
    OFFICE_ASSUMPTIONS_SECTION,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.IndustrialAssumptions, result);
  result = addSectionToCSV(
    INDUSTRIAL_ASSUMPTIONS_SECTION,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  result = addTitleRowToCSV("", result);
  result = addTitleRowToCSV(SectionTitle.CondoAssumptions, result);
  result = addSectionToCSV(
    CONDO_ASSUMPTIONS_SECTION,
    result,
    usageGroupList,
    allCustomSliderNames,
    unitSystem,
    values
  );

  return result;
};

/**
 * Generates a CSV string for the given values and triggers a dialog box to download it.
 *
 * NOTE: The CSV string is generated synchronously and on the fly. This means that when this
 * function is called, it blocks the main program flow until the complete file content has been
 * generated in memory.
 */
const downloadAssumptionsCsv = (
  values,
  unitSystem,
  usageGroupList,
  allCustomSliderNames,
  developmentName,
  developmantAddress,
  developmentCity,
  developmentState,
  isAnAssembly,
  projectIsForSale
) => {
  const projectValues = getFormattedProjectValues(
    values,
    unitSystem,
    usageGroupList,
    allCustomSliderNames,
    developmentName,
    developmantAddress,
    developmentCity,
    developmentState,
    isAnAssembly,
    projectIsForSale
  );

  // Generate the CSV string synchronously. Note that the `csv-stringify` library provides
  // an stream-based API as well. See https://csv.js.org/stringify/api/.
  const csvString = Papa.unparse(projectValues);

  const blob = new Blob([csvString], { type: "text/csv" });
  downloadBlobAsFile(blob, CSV_FILENAME);
};

export default downloadAssumptionsCsv;
