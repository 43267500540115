/**
 * @fileoverview This module holds values for Graph and the types for the data.
 */
import { Feature } from "geojson";

export enum GraphId {
  Population = "population",
  MedianHouseholdIncome = "medianHouseholdIncome",
  MedianGrossRent = "medianGrossRent",
  EmploymentPerPopulation = "employmentPerPopulation",
}

export interface GraphDataSource {
  color: string;
  feature: Feature;
}

export type GraphDataSources = { [key: string]: GraphDataSource }

export interface GraphTextSettings {
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  yAxisTickValueFormatter: (value: React.ReactText) => string;
}

export interface GraphData {
  labels: Array<string>; // x-axis labels
  dataset: Array<DataSet>;
}

export interface DataSet {
  data: Array<number>;
  color: string;
  label: string;
  feature: Feature;
}
