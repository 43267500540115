import React from "react";
import { connect } from "react-redux";
import { ParcelProperty } from "utils/parcel/ParcelProperty";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import Cell from "../../../Cell";
import CellText from "../../../CellText";
import ConditionalCellRow from "../../ConditionalCellRow";

type OwnProps = {
  parcelFeature: any;
};

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render project links section.
 */
const ProjectLinks = (props: Props) => {
  const { parcelFeature, tier } = props;
  const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), tier);

  const project_URL_1 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.ProjectURL_1);
  const project_URL_2 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.ProjectURL_2);
  const project_URL_3 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.ProjectURL_3);
  const project_URL_4 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.ProjectURL_4);

  const pd_URL_1 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.PD_URL_1);
  const pd_URL_2 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.PD_URL_2);
  const pd_URL_3 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.PD_URL_3);
  const pd_URL_4 = parcelAccessors.getProjectURL(parcelFeature, ParcelProperty.PD_URL_4);

  const hiddenMap: Format.FormattingMap = (value) => ({ value, formatOptions: { hidden: !userHasAccess } });

  if (
    project_URL_1.length === 0 &&
    project_URL_2.length === 0 &&
    project_URL_3.length === 0 &&
    project_URL_4.length === 0 &&
    pd_URL_1.length === 0 &&
    pd_URL_2.length === 0 &&
    pd_URL_3.length === 0 &&
    pd_URL_4.length === 0
  )
    return null;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Other Zoning Documents</th>
        </tr>
      </thead>
      <tbody>
        {ConditionalCellRow(
          project_URL_1,
          <CellText text="Other Docs 1" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          project_URL_2,
          <CellText text="Other Docs 2" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          project_URL_3,
          <CellText text="Other Docs 3" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          project_URL_4,
          <CellText text="Other Docs 4" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}

        {ConditionalCellRow(
          pd_URL_1,
          <CellText text="PD Docs 1" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          pd_URL_2,
          <CellText text="PD Docs 2" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          pd_URL_3,
          <CellText text="PD Docs 3" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
        {ConditionalCellRow(
          pd_URL_4,
          <CellText text="PD Docs 4" />,
          <Cell getter={valueGetters.generic} styleVariation="bold" formattingMap={hiddenMap} isExternalURL={true} />
        )}
      </tbody>
    </table>
  );
};

export default connect(mapStateToProps)(ProjectLinks);
