import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../../state/development";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import wrapComponentWithPopup from "../../../../sharedComponents/wrapComponentWithPopup";
import presets from "../../../../../utils/presets";
import { jsonDeepCopy } from "../../../../../utils/deepCopy";
import analytics from "../../../../../utils/analytics";
import { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    parcel: developmentSelectors.getParcel(state),
    values: developmentSelectors.getValues(state),
    initialValues: newDevelopmentSelectors.getInitialValues(state),
    development: developmentSelectors.getDevelopment(state),
  };
};

const mapDispatchToProps = {
  updateInitialValues: newDevelopmentActions.updateInitialValues,
  recalculateDevelopment: developmentActions.recalculate,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & PopupProps;

interface State {
  floorAreaRatioValue: string;
}

class RecalculatePopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      floorAreaRatioValue: parcelAccessors.getMinimumFloorAreaRatio(this.props.parcel) || "6",
    };

    props.updateInitialValues({ floorAreaRatio: Number(this.state.floorAreaRatioValue) });
  }

  /**
   * Change local floor area ratio value.
   */
  handleInputChange = (event) => {
    if (!isNaN(event.target.value)) this.setState({ floorAreaRatioValue: event.target.value });
  };

  /**
   * Select input value when clicking on it.
   */
  handleInputFocus = (event) => {
    event.target.select();
  };

  /**
   * Change floor area ratio value on the store.
   */
  handleInputBlur = (event) => {
    const { floorAreaRatioValue } = this.state;

    this.props.updateInitialValues({ floorAreaRatio: Number(floorAreaRatioValue) });
    if (floorAreaRatioValue.length === 0) this.setState({ floorAreaRatioValue: "0" });
  };

  /**
   * Recalculate the project values.
   */
  recalculate = async () => {
    const floorAreaRatio = this.props.initialValues.floorAreaRatio || 0;
    let development = jsonDeepCopy(this.props.development);
    await presets.update(development, floorAreaRatio);
    this.props.recalculateDevelopment(development);
    analytics.trackRecalculateProject(floorAreaRatio);
    this.props.closePopup();
  };

  render() {
    const { closePopup } = this.props;

    return (
      <div className={`component--recalculate-popup`}>
        <div className="far-container">
          <p className="section-header">Recalculating Project.</p>
          <p>
            The project will be recalculated at an FAR of
            <input name="far" value={this.state.floorAreaRatioValue} onChange={this.handleInputChange} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} />
            <br />
            <span>Without counting parking.</span>
          </p>
          <p>All market values and areas will adjust.</p>
        </div>
        <p>
          <span className="bold">Note:</span> Parking ratios and setbacks will remain intact.
        </p>
        <div className="buttons-container">
          <button className="cancel" onClick={closePopup}>
            CANCEL
          </button>
          <button className="continue" onClick={this.recalculate}>
            RECALCULATE PROJECT
          </button>
        </div>
      </div>
    );
  }
}

export default wrapComponentWithPopup(connect(mapStateToProps, mapDispatchToProps)(RecalculatePopup));
