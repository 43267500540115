import { useEffect } from "react";
import { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ParcelTool } from "types/ParcelTool";
import { SetPolygonIsBeingChangedFunction } from "state/newDevelopment/types";
import { DrawingMode } from "types/DrawingModes";

export const drawOnSelectionChange = (
  map: React.MutableRefObject<Map | undefined>,
  mapboxDraw: React.MutableRefObject<MapboxDraw | null>,
  parcelToolFromToolbar: ParcelTool,
  setPolygonIsBeingChanged: SetPolygonIsBeingChangedFunction,
  setDistanceToLastPolygonVertex: React.Dispatch<React.SetStateAction<number | null>>,
  deleteDrawnParcels: (features: any) => void,
  updatePolygonInformation: () => void
) => {
  useEffect(() => {
    if (map.current) {
      const onSelectcionChange = (data) => {
        if (!mapboxDraw.current || !mapboxDraw) return;
        switch (parcelToolFromToolbar) {
          case ParcelTool.DeleteParcel:
            deleteDrawnParcels(data.features);
            break;
          case ParcelTool.EditParcel:
            if (data.features.length === 0) {
              setDistanceToLastPolygonVertex(null);
            } else if (mapboxDraw.current.getMode() === DrawingMode.DirectSelect) {
              updatePolygonInformation();
              setPolygonIsBeingChanged(true);
            }
            break;
        }
      };

      map.current.on("draw.selectionchange", onSelectcionChange);

      return () => {
        if (map.current) {
          map.current.off("draw.selectionchange", onSelectcionChange);
        }
      };
    } else {
      return () => {}; // Return a no-op function when map.current is not set
    }
  }, [parcelToolFromToolbar]);
};
