import React from "react";
import { useSelector } from "react-redux";
import { authenticationSelectors } from "../../../state/authentication";
import DemoOnboarding from "./DemoOnboarding";

const DemoOnboardingOverlay = () => {
  const authenticationIsInitialized = useSelector(authenticationSelectors.getAuthenticationIsInitialized);
  const userIsAuthenticated = useSelector(authenticationSelectors.getUserIsAuthenticated);

  if (!authenticationIsInitialized) return null;

  return userIsAuthenticated
    ? null
    : <DemoOnboarding />;
}

export default DemoOnboardingOverlay;
