import { Feature, Polygon } from "geojson";
import { DataSet, GraphId, GraphTextSettings, GraphData, GraphDataSource } from "types/Graph";
import Format from "types/Format";
import valueFormatter from "utils/valueFormatter";
import { RawGraphingFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";

const POPULATION_GRAPH_LABELS = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
];
const MEDIAN_HOUSEHOLD_INCOME_GRAPH_LABELS = [
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
];
const EMPLOYMENT_PER_POPULATION_GRAPH_LABELS = [
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
];
const MEDIAN_GROSS_RENT_GRAPH_LABELS = ["2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"];

const GRAPH_TEXT_SETTINGS: { [graphId in GraphId]: GraphTextSettings } = {
  [GraphId.Population]: {
    title: "Population",
    xAxisLabel: "Year",
    yAxisLabel: "Population",
    yAxisTickValueFormatter: (value) => valueFormatter.format(value, { type: Format.Type.Number }),
  },
  [GraphId.EmploymentPerPopulation]: {
    title: "Employment per Population",
    xAxisLabel: "Year",
    yAxisLabel: "Percentage",
    yAxisTickValueFormatter: (value) => valueFormatter.format(Number(value) / 100, { type: Format.Type.Percentage }),
  },
  [GraphId.MedianGrossRent]: {
    title: "Median Gross Rent",
    xAxisLabel: "Year",
    yAxisLabel: "per Month",
    yAxisTickValueFormatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
  [GraphId.MedianHouseholdIncome]: {
    title: "Median Household Income",
    xAxisLabel: "Year",
    yAxisLabel: "per Year",
    yAxisTickValueFormatter: (value) => valueFormatter.format(value, { type: Format.Type.Currency }),
  },
};

/**
 * Get the corresponding labels for each graph.
 */
const getLabelsForGraph = (graphId: GraphId) => {
  switch (graphId) {
    case GraphId.Population:
      return POPULATION_GRAPH_LABELS;
    case GraphId.MedianHouseholdIncome:
      return MEDIAN_HOUSEHOLD_INCOME_GRAPH_LABELS;
    case GraphId.EmploymentPerPopulation:
      return EMPLOYMENT_PER_POPULATION_GRAPH_LABELS;
    case GraphId.MedianGrossRent:
      return MEDIAN_GROSS_RENT_GRAPH_LABELS;
    default:
      return [];
  }
};

/**
 * Get dataset for the population graph.
 */
const getDataSetForPopulation = (graphDataSourceList: Array<GraphDataSource>): Array<DataSet> => {
  return graphDataSourceList
    .map((graphDataSource) => {
      const graphFeature = graphDataSource.feature as Feature<Polygon, any>;

      return {
        color: graphDataSource.color,
        label: graphFeature.properties[RawGraphingFieldId.GraphLabel],
        data: [
          graphFeature.properties[RawGraphingFieldId.Population2010],
          graphFeature.properties[RawGraphingFieldId.Population2011],
          graphFeature.properties[RawGraphingFieldId.Population2012],
          graphFeature.properties[RawGraphingFieldId.Population2013],
          graphFeature.properties[RawGraphingFieldId.Population2014],
          graphFeature.properties[RawGraphingFieldId.Population2015],
          graphFeature.properties[RawGraphingFieldId.Population2016],
          graphFeature.properties[RawGraphingFieldId.Population2017],
          graphFeature.properties[RawGraphingFieldId.Population2018],
          graphFeature.properties[RawGraphingFieldId.Population2019],
          graphFeature.properties[RawGraphingFieldId.Population2020],
          graphFeature.properties[RawGraphingFieldId.Population2021],
          graphFeature.properties[RawGraphingFieldId.Population2022],
        ],
        feature: graphFeature,
      };
    })
    .filter((dataSet) => dataSet.label !== "US");
};

/**
 * Get dataset for the median household income graph.
 */
const getDataSetForMedianHouseholdIncome = (graphDataSourceList: Array<GraphDataSource>): Array<DataSet> => {
  return graphDataSourceList.map((graphDataSource) => {
    const graphFeature = graphDataSource.feature as Feature<Polygon, any>;

    return {
      color: graphDataSource.color,
      label: graphFeature.properties[RawGraphingFieldId.GraphLabel],
      data: [
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2013],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2014],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2015],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2016],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2017],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2018],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2019],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2020],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2021],
        graphFeature.properties[RawGraphingFieldId.MedianHouseholdIncome2022],
      ],
      feature: graphFeature,
    };
  });
};

/**
 * Get dataset for the employment graph.
 */
const getDataSetForEmploymentPerPopulation = (graphDataSourceList: Array<GraphDataSource>): Array<DataSet> => {
  return graphDataSourceList.map((graphDataSource) => {
    const graphFeature = graphDataSource.feature as Feature<Polygon, any>;

    return {
      color: graphDataSource.color,
      label: graphFeature.properties[RawGraphingFieldId.GraphLabel],
      data: [
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2013],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2014],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2015],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2016],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2017],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2018],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2019],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2020],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2021],
        graphFeature.properties[RawGraphingFieldId.EmploymentPerPopRatio2022],
      ],
      feature: graphFeature,
    };
  });
};

/**
 * Get dataset for the median gross rent graph.
 */
const getDataSetForMedianGrossRent = (graphDataSourceList: Array<GraphDataSource>): Array<DataSet> => {
  return graphDataSourceList.map((graphDataSource) => {
    const graphFeature = graphDataSource.feature as Feature<Polygon, any>;

    return {
      color: graphDataSource.color,
      label: graphFeature.properties[RawGraphingFieldId.GraphLabel],
      data: [
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2013],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2014],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2015],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2016],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2017],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2018],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2019],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2020],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2021],
        graphFeature.properties[RawGraphingFieldId.MedianGrossRentTotal2022],
      ],
      feature: graphFeature,
    };
  });
};

/**
 * Get dataset for the specified graph.
 */
const getDataSetForGraph = (graphDataSourceList: Array<GraphDataSource>, graphId: GraphId): Array<DataSet> => {
  switch (graphId) {
    case GraphId.Population:
      return getDataSetForPopulation(graphDataSourceList);
    case GraphId.MedianHouseholdIncome:
      return getDataSetForMedianHouseholdIncome(graphDataSourceList);
    case GraphId.EmploymentPerPopulation:
      return getDataSetForEmploymentPerPopulation(graphDataSourceList);
    case GraphId.MedianGrossRent:
      return getDataSetForMedianGrossRent(graphDataSourceList);
    default:
      return [];
  }
};

/**
 * Get the graph data for the specified graph.
 */
const getGraphData = (graphDataSourceList: Array<GraphDataSource>, graphId: GraphId): GraphData => {
  return {
    labels: getLabelsForGraph(graphId),
    dataset: getDataSetForGraph(graphDataSourceList, graphId),
  };
};

export { GRAPH_TEXT_SETTINGS, getGraphData };
