import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "types/Path";

export const useExplorerReplaceProjectRoute = (projectId: string | null, urlProjectId: string | undefined) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (projectId && projectId !== urlProjectId) {
      navigate(`${Path.Explorer}/${projectId}`);
    }
  }, [projectId, urlProjectId]);
};
