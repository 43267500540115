import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "state/newDevelopment";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import Tabs from "../../../sharedComponents/Tabs";
import TabContainer from "../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../sharedComponents/Tabs/TabPanel";
import Tab from "../../../sharedComponents/Tabs/Tab";
import DrawParcelInformationPanel from "./DrawParcelInformationPanel";
import SearchPanel from "./SearchPanel";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Tier } from "../../../../types/Tier";
import MapLegendPanel from "../../../sharedComponents/MapLegendPanel";
import { mapsSelectors } from "../../../../state/ui/shared/maps";
import layerHelper from "../../../../utils/mapbox/layerHelper";

const TAB_CONFIG = {
  searchEngine: {
    label: "Search Engine",
    id: "searchEngine",
  },
  drawParcel: {
    label: "Draw Parcel",
    id: "drawParcel",
  },
  mapLegend: {
    label: "Map Legend",
    id: "mapLegend",
  },
};

const mapStateToProps = (state) => {
  return {
    smartSearchIsOpen: newDevelopmentSelectors.getSmartSearchIsOpen(state),
    tier: subscriptionSelectors.getTier(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  };
};

const mapDispatchToProps = {
  setSmartSearchIsOpen: newDevelopmentActions.setSmartSearchIsOpen,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = MapStateProps & DispatchProps;

class ParcelFinderPanel extends React.PureComponent<Props, {}> {
  render() {
    const { smartSearchIsOpen, tier, setSmartSearchIsOpen, layerConfigurations } = this.props;
    const tierIsFree = tier === Tier.Free;
    const tierIsDemo = tier === Tier.None;
    const tierIsDeveloper = tier === Tier.Developer;
    const defaultTabId = tierIsFree || tierIsDeveloper ? TAB_CONFIG.drawParcel.id : TAB_CONFIG.searchEngine.id;
    const panelHeader = tierIsFree || tierIsDemo ? "Draw Parcel" : "Market Scanner";
    const displayMapLegend = !tierIsFree && layerHelper.shouldDisplayLegend(layerConfigurations);

    return (
      <WithPanelHeader title={panelHeader} isActive={smartSearchIsOpen} onStateChange={setSmartSearchIsOpen}>
        <div className="component--parcel-finder-panel">
          <Tabs defaultTab={defaultTabId}>
            {(onTabClick, activeTab) => {
              if (tierIsDemo) {
                activeTab = TAB_CONFIG.drawParcel.id;
              } else {
                activeTab =
                  displayMapLegend || activeTab !== TAB_CONFIG.mapLegend.id ? activeTab : TAB_CONFIG.drawParcel.id;
              }
              return (
                <>
                  <TabContainer>
                    {!tierIsDemo && !tierIsDeveloper && (
                      <Tab
                        label={TAB_CONFIG.searchEngine.label}
                        tabId={TAB_CONFIG.searchEngine.id}
                        onTabClick={onTabClick}
                        activeTab={activeTab}
                      />
                    )}
                    {displayMapLegend && !tierIsDemo && !tierIsDeveloper && (
                      <Tab
                        label={TAB_CONFIG.mapLegend.label}
                        tabId={TAB_CONFIG.mapLegend.id}
                        onTabClick={onTabClick}
                        activeTab={activeTab}
                      />
                    )}
                    <Tab
                      label={TAB_CONFIG.drawParcel.label}
                      tabId={TAB_CONFIG.drawParcel.id}
                      onTabClick={onTabClick}
                      activeTab={activeTab}
                    />
                  </TabContainer>
                  {!tierIsDemo && !tierIsDeveloper && (
                    <TabPanel tabId={TAB_CONFIG.searchEngine.id} activeTab={activeTab}>
                      <SearchPanel />
                    </TabPanel>
                  )}
                  <TabPanel tabId={TAB_CONFIG.drawParcel.id} activeTab={activeTab}>
                    <DrawParcelInformationPanel />
                  </TabPanel>
                  {!tierIsDemo && !tierIsDeveloper && (
                    <TabPanel tabId={TAB_CONFIG.mapLegend.id} activeTab={activeTab}>
                      <MapLegendPanel />
                    </TabPanel>
                  )}
                </>
              );
            }}
          </Tabs>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParcelFinderPanel);
