import { createSelector } from "@reduxjs/toolkit";
import { AllowedUsesFilters } from "types/AllowedUsesFilter";
import { OwnerAddressesFilter } from "types/OwnerAddressesFilter";
import { OwnerNamesFilter } from "types/OwnerNamesFilter";
import { ZoningFilters } from "../../types/ZoningFilters";
import { NewDevelopment } from "./reducers";
import { LandUseFilter } from "types/LandUseFilter";

/**
 * Select the whole newDevelopment.
 */
const getNewDevelopment = (state): NewDevelopment => {
  return state.newDevelopment;
};

/**
 * Select the suggestion list.
 */
const getSuggestedFeatures = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeatures;
};

/**
 * Select the proximityCenter.
 */
const getGeocoderProximityCenter = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.geocoderProximityCenter;
};

/**
 * Select the input value.
 */
const getSearchAddress = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.searchAddress;
};

/**
 * Select the selected feature.
 */
const getSelectedFeature = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.selectedFeature;
};

/**
 * Select getIsTyping.
 */
const getUserIsTyping = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.userIsTyping;
};

/**
 * Select active suggestion index.
 */
const getSuggestedFeaturesSelectionIndex = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeaturesSelectionIndex;
};

/**
 * Select newDevelopmentIsQuerying.
 */
const getGeocoderIsQuerying = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.geocoderIsQuerying;
};

/**
 * Select the hovered feature.
 */
const getHoveredFeature = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.hoveredFeature;
};

/**
 * Select pinPosition.
 */
const getPinPosition = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.pinPosition;
};

/**
 * Get selectedFeatureMembers value.
 */
const getSelectedFeatureMembers = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.selectedFeatureMembers;
};

/**
 * Get the polygonArea value.
 */
const getPolygonArea = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonArea;
};

/**
 * Get the polygonPerimeter value.
 */
const getPolygonPerimeter = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonPerimeter;
};

/**
 * Get the polygonIsBeingChanged value.
 */
const getPolygonIsBeingChanged = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonIsBeingChanged;
};

/*
 * Get the parcelTool value.
 */
const getParcelTool = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelTool;
};

/**
 * Get the parcelTooFromToolbar value.
 */
const getParcelToolFromToolbar = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelToolFromToolbar;
};

/**
 * Get the drawnParcels array.
 */
const getDrawnParcels = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.drawnParcels;
};

/**
 * Get unit system.
 */
const getUnitSystem = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.unitSystem;
};

/**
 * Get the smartSearchIsOpen flag.
 */
const getSmartSearchIsOpen = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.smartSearchIsOpen;
};

/**
 * Get the displayPin flag.
 */
const getDisplayPin = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.displayPin;
};

/**
 * Get the parcelsInViewport array.
 */
const getParcelsInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelsInViewport;
};

/**
 * Get Smart Search Result array.
 */
const getSmartSearchResult = createSelector(getNewDevelopment, (newDevelopment) => newDevelopment.smartSearchResult);

/**
 * Get Smart Search Result array length.
 */
const getSmartSearchResultCount = createSelector(
  getNewDevelopment,
  (newDevelopment) => newDevelopment.smartSearchResult.length
);

/**
 * Get the filters array.
 */
const getFilters = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.filters;
};

/**
 * Get the subset of filters that are currently displayable.
 */
const getDisplayableFilters = createSelector(getFilters, (filters) =>
  Object.values(filters).filter((filter) => filter.shouldDisplay)
);

/**
 * Get the zoning filters object.
 */
const getZoningFilters = (state): ZoningFilters => {
  const newDevelopment = getNewDevelopment(state);
  return newDevelopment.zoningFilters;
};

/**
 * Get an alphabetically-sorted array of the currently-enabled zoning filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getEnabledZoningFilterIds = createSelector(getZoningFilters, (zoningFilters) =>
  Object.entries(zoningFilters)
    .filter(([zoneId, isEnabled]) => isEnabled)
    .map(([zoneId, isEnabled]) => zoneId)
    .sort()
);

/**
 * Get an alphabetically-sorted array of the currently-disabled zoning filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getZoningFilterIds = createSelector(getZoningFilters, (zoningFilters) => Object.keys(zoningFilters).sort());

/**
 * Get a boolean indicating whether there are currently any zoning filters to display.
 */
const getZoningFiltersShouldDisplay = createSelector(
  getZoningFilters,
  (zoningFilters) => Object.values(zoningFilters).length > 0
);

/**
 * Get the ownerNames filter object.
 */
const getOwnerNameFilters = (state): OwnerNamesFilter => {
  const newDevelopment = getNewDevelopment(state);
  return newDevelopment.ownerNamesFilters;
};

/**
 * Get an alphabetically-sorted array of the currently-enabled owner-name filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getEnabledOwnerNameFilter = createSelector(getOwnerNameFilters, (ownerNameFilters) =>
  Object.entries(ownerNameFilters)
    .filter(([ownerName, isEnabled]) => isEnabled)
    .map(([ownerName, isEnabled]) => ownerName)
    .sort()
);

/**
 * Get an alphabetically-sorted array of the currently-disabled owner-name filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getDisabledOwnerNameFilter = createSelector(getOwnerNameFilters, (ownerNameFilters) =>
  Object.keys(ownerNameFilters).sort()
);

/**
 * Get a boolean indicating whether there are currently any owner-name filters to display.
 */
const getOwnerNameFiltersShouldDisplay = createSelector(
  getOwnerNameFilters,
  (ownerNameFilters) => Object.values(ownerNameFilters).length > 0
);

/**
 * Get the ownerAddress filter object.
 */
const getOwnerAddressFilters = (state): OwnerAddressesFilter => {
  const newDevelopment = getNewDevelopment(state);
  return newDevelopment.ownerAddressesFilters;
};

/**
 * Get an alphabetically-sorted array of the currently-enabled owner-address filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getEnabledOwnerAddressFilter = createSelector(getOwnerAddressFilters, (ownerAddressFilters) =>
  Object.entries(ownerAddressFilters)
    .filter(([ownerAddress, isEnabled]) => isEnabled)
    .map(([ownerAddress, isEnabled]) => ownerAddress)
    .sort()
);

/**
 * Get an alphabetically-sorted array of the currently-disabled owner-address filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getDisabledOwnerAddressFilter = createSelector(getOwnerAddressFilters, (ownerAddressFilters) =>
  Object.keys(ownerAddressFilters).sort()
);

/**
 * Get a boolean indicating whether there are currently any owner-address filters to display.
 */
const getOwnerAddressFiltersShouldDisplay = createSelector(
  getOwnerAddressFilters,
  (ownerAddressFilters) => Object.values(ownerAddressFilters).length > 0
);

/**
 * Get the landUse filter object.
 */
const getLandUseFilters = (state): LandUseFilter => {
  const newDevelopment = getNewDevelopment(state);
  return newDevelopment.landUseFilters;
};

/**
 * Get an alphabetically-sorted array of the currently-enabled land-uses filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getEnabledLandUseFilter = createSelector(getLandUseFilters, (landUseFilters) =>
  Object.entries(landUseFilters)
    .filter(([landUseFilter, isEnabled]) => isEnabled)
    .map(([landUseFilter, isEnabled]) => landUseFilter)
    .sort()
);

/**
 * Get an alphabetically-sorted array of the currently-disabled land-use filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getDisabledLandUseFilter = createSelector(getLandUseFilters, (landUseFilters) =>
  Object.keys(landUseFilters).sort()
);

/**
 * Get a boolean indicating whether there are currently any land-use filters to display.
 */
const getLandUseFiltersShouldDisplay = createSelector(
  getLandUseFilters,
  (landUseFilters) => Object.values(landUseFilters).length > 0
);

/**
 * Get the allowed uses filters object.
 */
const getAllowedUsesFilters = (state): AllowedUsesFilters => {
  const newDevelopment = getNewDevelopment(state);
  return newDevelopment.allowedUsesFilters;
};

/**
 * Get an alphabetically-sorted array of the currently-enabled allowed uses filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getEnabledAllowedUsesFilters = createSelector(getAllowedUsesFilters, (allowedUsesFilters) =>
  Object.entries(allowedUsesFilters)
    .filter(([use, isEnabled]) => isEnabled)
    .map(([use, isEnabled]) => use)
    .sort()
);

/**
 * Get an alphabetically-sorted array of the currently-disabled allowed uses filters.
 *
 * NOTE: Sorting this list each time we select it is inefficient. Ideally, this list would be modeled
 * in the state tree in such a way that it is already sorted (for example using Redux Toolkit `entityAdapter`).
 * This requires refactoring a lot of the filtering subsystem.
 */
const getAllowedUsesIds = createSelector(getAllowedUsesFilters, (allowedUsesFilters) =>
  Object.keys(allowedUsesFilters).sort()
);

/**
 * Get a boolean indicating whether there are currently any allowed uses filters to display.
 */
const getAllowedUsesFiltersShouldDisplay = createSelector(
  getAllowedUsesFilters,
  (allowedUsesFilters) => Object.values(allowedUsesFilters).length > 0
);

/**
 * Get development initial values.
 */
const getInitialValues = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.initialValues;
};

/**
 * Get the zoningDataInViewport flag.
 */
const getZoningDataInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.zoningDataInViewport;
};

/**
 * Get the parcelDataInViewport flag.
 */
const getParcelDataInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelDataInViewport;
};

/**
 * Get the place type of the suggested feature.
 */
const getSuggestedFeaturePlaceType = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeaturePlaceType;
};

/**
 * Get query viewport flag.
 */
const getQueryViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.queryViewport;
};

export default {
  getUserIsTyping,
  getSuggestedFeatures,
  getSearchAddress,
  getGeocoderProximityCenter,
  getSelectedFeature,
  getSuggestedFeaturesSelectionIndex,
  getGeocoderIsQuerying,
  getHoveredFeature,
  getPinPosition,
  getPolygonArea,
  getPolygonPerimeter,
  getPolygonIsBeingChanged,
  getParcelToolFromToolbar,
  getParcelTool,
  getDrawnParcels,
  getUnitSystem,
  getSmartSearchIsOpen,
  getDisplayPin,
  getParcelsInViewport,
  getSmartSearchResult,
  getSmartSearchResultCount,
  getFilters,
  getDisplayableFilters,
  getZoningFilters,
  getZoningFiltersShouldDisplay,
  getEnabledZoningFilterIds,
  getZoningFilterIds,
  getAllowedUsesFilters,
  getEnabledAllowedUsesFilters,
  getAllowedUsesIds,
  getAllowedUsesFiltersShouldDisplay,
  getOwnerNameFilters,
  getEnabledOwnerNameFilter,
  getDisabledOwnerNameFilter,
  getOwnerNameFiltersShouldDisplay,
  getOwnerAddressFilters,
  getEnabledOwnerAddressFilter,
  getDisabledOwnerAddressFilter,
  getOwnerAddressFiltersShouldDisplay,
  getLandUseFilters,
  getEnabledLandUseFilter,
  getDisabledLandUseFilter,
  getLandUseFiltersShouldDisplay,
  getInitialValues,
  getZoningDataInViewport,
  getParcelDataInViewport,
  getSuggestedFeaturePlaceType,
  getSelectedFeatureMembers,
  getQueryViewport,
};
