import React from "react";
import { connect } from "react-redux";
import { mapsActions, mapsSelectors } from "../../../state/ui/shared/maps";
import { LayerConfigurationsLayerIds } from "../../../utils/mapbox/layerHelper";

interface OwnProps {
  layerIds: Array<LayerConfigurationsLayerIds>;
  children(isActive: boolean, onClick: () => void);
}

const mapStateToProps = (state) => {
  return {
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  }
}

const mapDispatchToProps = {
  toggleLayers: mapsActions.toggleLayers,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class LayerToggleBase extends React.PureComponent<Props, {}> {
  /**
   * Toggle layers.
   */
  toggleLayers = () => {
    this.props.toggleLayers(this.props.layerIds);
  }

  render() {
    const { layerIds, layerConfigurations, children } = this.props;

    const isVisible = layerIds.some((layerId) => layerConfigurations[layerId].isActive);
    return children(isVisible, this.toggleLayers)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayerToggleBase) as React.ComponentType<OwnProps>;
