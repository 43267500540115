import { Values } from  "../../../../types/Development/Values";

/**
 * @fileoverview This module manages financial calculations that pertain
 *  to the returns each building use generates.
 */

const DEFAULT_INPUTS = {
};

/**
 * Initialize the given `values` object. Ensures the object has something
 * defined for the minimum set of variables required for this module to operate.
 *
 * @param {Object} values - A development values object. If any required
 *  financial input is missing, the value from DEFAULT_INPUTS is applied.
 */
const ensureRequiredInputs = (values: Values) => {
  Object.assign(
    values,
    {...DEFAULT_INPUTS, ...values}
  );
};

/**
 * Update the financial quantities on the given `values` object.
 *
 * @param {Object} values - A development values object that may not have fully
 *    updated financial quantities.
 */
const update = (values: Values) => {
  updateHotelReturns(values);
  updateRentalUsesReturns(values);
  updateIncomeProducingUsesReturns(values);
};

/**
 * Update the hotel returns for the given `values` object.
 */
const updateHotelReturns = (values: Values) => {
  values.hotelAnnualReturnOnCostForBackOfEnvelope =
      values.hotelTotalDevelopmentCostIncludingParking === 0
          ? 0 // Guard against divide-by-zero errors.
          : (
              values.hotelAnnualNetOperatingIncomeForBackOfEnvelope
              /
              values.hotelTotalDevelopmentCostIncludingParking
            );
};

/**
 * Update the rental uses returns for the given `values` object.
 */
const updateRentalUsesReturns = (values: Values) => {
  values.rentalUsesAnnualReturnOnCostForBackOfEnvelope =
      values.rentalUsesTotalDevelopmentCostIncludingParking === 0
          ? 0 // Guard against divide-by-zero errors.
          : (
              values.rentalUsesAnnualNetOperatingIncomeForBackOfEnvelope
              /
              values.rentalUsesTotalDevelopmentCostIncludingParking
            );
};

/**
 * Update the income producing uses returns for the given `values` object.
 */
const updateIncomeProducingUsesReturns = (values: Values) => {
  values.incomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope =
      values.projectTotalDevelopmentCost === 0
          ? 0 // Guard against divide-by-zero errors.
          : (
              values.incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope
              /
              values.projectTotalDevelopmentCost
            );

  values.incomeProducingUsesSaleValueForBackOfEnvelope =
      values.exitCapRate === 0
          ? 0 // Guard against divide-by-zero errors.
          : (
              values.incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope
              /
              values.exitCapRate
            );

  values.totalProjectSaleForBackOfEnvelope =
      values.incomeProducingUsesSaleValueForBackOfEnvelope +
      values.condoGrossSales;

  values.totalProjectSalesCommissions =
      values.totalProjectSaleForBackOfEnvelope
    * values.projectSaleCommissionPercentage;

  values.totalProjectSaleProfitForBackOfEnvelope =
      values.totalProjectSaleForBackOfEnvelope -
      values.totalProjectSalesCommissions -
      values.projectTotalDevelopmentCost;

  values.saleReturnOnInvestmentForBackOfEnvelope =
        values.projectTotalDevelopmentCost === 0
          ? 0 // Guard against divide-by-zero errors.
          : (
              values.totalProjectSaleProfitForBackOfEnvelope
              /
              values.projectTotalDevelopmentCost
            );
};

export default {
  ensureRequiredInputs,
  update,
};
