import React from "react";
import { connect } from "react-redux";
import { GeoJSONLayer, Layer, Feature } from "react-mapbox-gl";
import { developmentSelectors } from "state/development";
import buildingGeoJson from "utils/buildingGeoJson";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import mapboxPresentationProperties from "views/utils/mapboxPresentationProperties";

const PIN_PROJECT_ZOOM = 14;

const mapStateToProps = (state) => {
  return {
    buildingModel: developmentSelectors.getBuildingModel(state),
    parcelGeoJson: developmentSelectors.getParcel(state),
    parcelCenterOfMass: developmentSelectors.getParcelCenterOfMass(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class Building extends React.PureComponent<Props, {}> {
  /**
   * Render building.
   */
  renderBuilding = () => {
    let buildingData = buildingGeoJson.geoJsonFor(this.props.buildingModel);
    if (!buildingData) return null;

    return (
      <GeoJSONLayer
        data={buildingData as any}
        fillExtrusionPaint={mapboxPresentationProperties.buildingFillExtrusionPaint as any}
        layerOptions={{ minzoom: PIN_PROJECT_ZOOM } as any}
      />
    );
  };

  render() {
    return (
      <>
        <GeoJSONLayer
          data={this.props.parcelGeoJson}
          linePaint={mapboxPresentationProperties.parcelFootprintLinePaint}
          fillPaint={mapboxPresentationProperties.parcelFootprintFillPaint}
          layerOptions={{ minzoom: PIN_PROJECT_ZOOM } as any}
          before={MapStyleProperties.LayerId.UsaBuildings}
        />
        {this.renderBuilding()}

        <Layer type="symbol" layout={mapboxPresentationProperties.mapPinLayout} maxZoom={PIN_PROJECT_ZOOM}>
          <Feature coordinates={this.props.parcelCenterOfMass} />
        </Layer>
      </>
    );
  }
}

export default connect(mapStateToProps)(Building);
