import React from "react";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import AuthenticationButtons from "../../../sharedComponents/AuthenticationButtons";
import Logo from "../../../sharedComponents/Logo";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import ZoningReportButtonLink from "views/sharedComponents/ZoningReportButtonLink";

interface OwnProps {
  isProTier: boolean;
}

class NewProjectToolbar extends React.PureComponent<OwnProps, {}> {
  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    const { isProTier } = this.props;

    return (
      <div className="component--new-project-toolbar">
        {!isProTier && <ZoningReportButtonLink />}
        <div className="new-project-toolbar">
          <Logo />
          <AuthenticationButtons />
          <ToolbarSettings>
            {userIsAuthenticated && <RedirectDropdownButton path={Path.Dashboard} text="Dashboard" />}
          </ToolbarSettings>
        </div>
      </div>
    );
  }
}

export default NewProjectToolbar;
