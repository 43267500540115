import { createSelector } from "@reduxjs/toolkit";
import turf from "utils/turf";
import { Development } from "types/Development/Development";
import developmentAccessors from "./utils/developmentAccessors";
import { DevelopmentState } from "./reducers";
import { VariableId } from "types/VariableId";

/**
 * Select the whole development.
 */
const getDevelopmentState = (state): DevelopmentState => {
  return state.development;
};

/**
 * Select the development document.
 */
const getDevelopment = (state): Development => {
  let developmentState = getDevelopmentState(state);
  return developmentState.developmentDocument;
};

/**
 * Select the is ready flag.
 */
const getIsReady = (state) => {
  let developmentState = getDevelopmentState(state);
  return developmentState.isReady;
};

/**
 * Get the `id` value from the development object.
 */
const getProjectId = (state) => {
  let developmentState = getDevelopmentState(state);
  return developmentState.id;
};

/**
 * Select the development's parcel.
 */
const getParcel = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getParcel(development);
};

/**
 * Select the Address of the parcel
 */
const getAddress = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getAddress(development);
};

/**
 * Select the Address of the first parcel of an assembly
 */
const getAddressForAssembly = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getAddressForAssembly(development);
};

/**
 * Select the City of the parcel
 */
const getCity = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getCity(development);
};

/**
 * Select the City of the first parcel of an assembly
 */
const getCityForAssembly = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getCityForAssembly(development);
};

/**
 * Select the State of the parcel
 */
const getState = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getState(development);
};

/**
 * Select the State of the first parcel of an assembly
 */
const getStateForAssembly = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getStateForAssembly(development);
};

/**
 * Select the coordinates (`[long, lat]`) of the center of mass of the development's parcel.
 */
const getParcelCenterOfMass = createSelector(getParcel, (parcel) => {
  return turf.getCoord(turf.centerOfMass(parcel)).slice(0, 2);
});

/**
 * Select the development's building model.
 */
const getBuildingModel = (state) => {
  let development = getDevelopment(state);
  return development.buildingModel;
};

/**
 * Select the development's camera.
 */
const getCamera = (state) => {
  let development = getDevelopment(state);
  return development.camera;
};

/**
 * Select the development's name.
 */
const getName = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getName(development);
};

/**
 * Select the development's working data values.
 */
const getValues = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getValues(development);
};

/**
 * Select an specific development value given its id.
 */
const getValue = (state, valueId, index?) => {
  let values = getValues(state);
  return index !== undefined ? values[valueId][index] : values[valueId];
};

/**
 * Selecting only the building usage toggle values from the development's
 * working data values.
 */
const getBuildingUsageToggles = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getBuildingUsageToggles(development);
};

/**
 * Select a single increment constraint from the development.
 */
const getIncrementConstraint = (state, incrementId) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputIncrement(development, incrementId);
};

/**
 * Select a single minimum value constraint from the development.
 */
const getMinimumConstraint = (state, minimumId, index?) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputMinimum(development, minimumId, index);
};

/**
 * Select a single maximum value constraint from the development.
 */
const getMaximumConstraint = (state, maximumId, index?) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputMaximum(development, maximumId, index);
};

/**
 * Select the setback type from the development, given the indexes of the polygon and setback.
 */
const getSetbackType = (state, polygonIndex, setbackIndex) => {
  let development = getDevelopment(state);
  return developmentAccessors.getSetbackType(
    development,
    development.selectedSetbackFloorIndex,
    polygonIndex,
    setbackIndex
  );
};

/**
 * Select the development's setbacks object.
 */
const getSetbacks = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getSetbacks(development, development.selectedSetbackFloorIndex);
};

/**
 * Select the unit system from the development.
 */
const getUnitSystem = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getUnitSystem(development);
};

/**
 * Select the is edited flag.
 */
const getIsEdited = (state) => {
  let developmentState = getDevelopmentState(state);
  return developmentState.isEdited;
};

/**
 * Select the is from shared flag.
 */
const getIsFromShared = (state) => {
  let development = getDevelopment(state);
  return development.isFromShared;
};

/**
 * Get the `floorsWithSetbacks` array from the development object.
 */
const getFloorsWithSetbacks = (state) => {
  let development = getDevelopment(state);
  return development.floorsWithSetbacks;
};

/**
 * Get the `selectedSetbackFloor` value from the development object.
 */
const getSelectedSetbackFloor = (state) => {
  let development = getDevelopment(state);
  return development.selectedSetbackFloor;
};

/**
 * Get the `selectedSetbackFloorIndex` value from the development object.
 */
const getSelectedSetbackFloorIndex = (state) => {
  let development = getDevelopment(state);
  return development.selectedSetbackFloorIndex;
};

/**
 * Get the `accessRestricted` value from the development object.
 */
const getAccessRestricted = (state) => {
  let developmentState = getDevelopmentState(state);
  return developmentState.accessRestricted;
};

/**
 * Get the project is for sale flag.
 */
const getProjectIsForSale = (state) => {
  let development = getDevelopment(state);
  return development.isForSale;
};

/**
 * Get the floorPlan for the given floor.
 */
const getFloorPlan = (state, floor) => {
  let development = getDevelopment(state);
  const floorPlans = development.buildingModel.floorPlansByFloor || {};

  const floorPlanByFloor = Object.entries(floorPlans)
    .filter(([floorNumber, shapes]) => floorNumber === floor.toString())
    .map(([floorNumber, shapes]) => shapes);

  return floorPlanByFloor;
};

/**
 * Get the customSliderName object (if any) for the given variableId.
 */
const getCustomSliderName = (state, variableId: VariableId) => {
  let development = getDevelopment(state);
  let sliderNames = development.customSliderNames || {};

  const customSliderName = Object.entries(sliderNames)
    .filter(([sliderVariableId, sliderBox]) => sliderVariableId === variableId)
    .map(([sliderVariableId, sliderBox]) => sliderBox);

  return customSliderName;
};

/**
 * Get the all the customSliderName objects.
 */
const getAllCustomSliderNames = (state) => {
  let development = getDevelopment(state);
  return development.customSliderNames || {};
};

/**
 * Get the customCellText object (if any) for the given defaultName
 */
const getCustomCellText = (state, variableId: VariableId) => {
  let development = getDevelopment(state);
  let customSliderNames = development.customSliderNames || {};

  const customCellText = Object.entries(customSliderNames)
    .filter(([sliderVariableId, sliderBox]) => sliderVariableId === variableId)
    .map(([sliderVariableId, sliderBox]) => sliderBox);

  return customCellText;
};

export default {
  getDevelopment,
  getIsReady,
  getProjectId,
  getParcel,
  getParcelCenterOfMass,
  getBuildingModel,
  getCamera,
  getName,
  getValues,
  getValue,
  getBuildingUsageToggles,
  getIncrementConstraint,
  getMinimumConstraint,
  getMaximumConstraint,
  getSetbackType,
  getSetbacks,
  getUnitSystem,
  getIsEdited,
  getIsFromShared,
  getFloorsWithSetbacks,
  getSelectedSetbackFloor,
  getSelectedSetbackFloorIndex,
  getAccessRestricted,
  getProjectIsForSale,
  getFloorPlan,
  getCustomSliderName,
  getCustomCellText,
  getAllCustomSliderNames,
  getAddress,
  getCity,
  getState,
  getAddressForAssembly,
  getCityForAssembly,
  getStateForAssembly,
};
