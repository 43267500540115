import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionRequiredContent from "./SubscriptionRequiredContent";
import LogInButton from "../../sharedComponents/Buttons/LogInButton";
import DemoButton from "../../sharedComponents/Buttons/DemoButton";
import LogOutButton from "../../sharedComponents/Buttons/LogOutButton";
import DashboardButton from "../../sharedComponents/Buttons/DashboardButton";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import { authenticationUiSelectors } from "state/ui/shared/authentication";
import { authenticationUiActions } from "state/ui/shared/authentication";
import analytics from "utils/analytics";
import { Path } from "types/Path";

const mapStateToProps = (state) => {
  return {
    projectId: developmentSelectors.getProjectId(state),
    logInIsOpen: authenticationUiSelectors.getLoginIsOpen(state),
  };
};

const mapDispatchToProps = {
  toggleLoginIsOpen: authenticationUiActions.toggleLoginIsOpen,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const SubscriptionRequiredPage: React.FC<Props> = ({ projectId, logInIsOpen, toggleLoginIsOpen }) => {
  const navigate = useNavigate();

  useEffect(() => {
    analytics.trackPageview(Path.Subscribe);
    if (logInIsOpen) {
      toggleLoginIsOpen();
    }
  }, []);

  /**
   * Redirect logged in user to appropriate page.
   */
  const onLogin = () => {
    if (projectId) {
      navigate(`${Path.Explorer}/${projectId}`);
    } else {
      navigate(Path.Dashboard);
    }
  };

  return (
    <div className="component--subscription-required-page">
      <div className="buttons-container">
        <LogInButton onLogin={onLogin} />
        <DemoButton />
        <DashboardButton />
        <LogOutButton />
      </div>

      <SubscriptionRequiredContent />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionRequiredPage);
