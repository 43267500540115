import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class WetlandsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.Wetlands}
        classes="wetlands"
        legendRows={[
          <LegendRow
            color="#d4c86e"
            text="Estuarine and Marine Deepwater"
          />,
          <LegendRow
            color="#87e23c"
            text="Estuarine and Marine Wetland"
          />,
          <LegendRow
            color="#57d3c7"
            text="Freshwater Emergent Wetland"
          />,
          <LegendRow
            color="#6aa3ea"
            text="Freshwater Forested/Shrub Wetland"
          />,
          <LegendRow
            color="#d10f66"
            text="Freshwater Pond"
          />,
          <LegendRow
            color="#cc7c66"
            text="Lake"
          />,
          <LegendRow
            color="#856be4"
            text="Other"
          />,
          <LegendRow
            color="#e08deb"
            text="Riverine"
          />,
          <LegendRow
            color="#f1f1f1"
            text="No Data"
          />,
        ]}
      />
    );
  }
}

export default WetlandsLegend;
