import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "state/subscription";
import { mapsActions, mapsSelectors } from "state/ui/shared/maps";
import { Tier } from "types/Tier";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import DividerComponent from "../DividerComponent";
import dynamicLayersConfig from "../dynamicLayersConfig";
import DynamicLayerTabs from "../DynamicLayerTabs";

interface OwnProps {
  layerSector: string;
}

const mapStateToProps = (state) => ({
  tier: subscriptionSelectors.getTier(state),
  dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
})

const mapDispatchToProps = {
  resetDynamicLayers: mapsActions.resetDynamicLayers,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;

export class DynamicLayerPanel extends React.PureComponent<Props, {}> {

  areLayersAvailable = () => {
    const layerGroup =  Object.keys(dynamicLayersConfig).filter((layerGroup) => dynamicLayersConfig[layerGroup].layerSector === this.props.layerSector)
    let layersIdsFromGroups:Array<MapStyleProperties.LayerId> = [];

    layerGroup.forEach((layerGroup) => {
      const layersIds = dynamicLayersConfig[layerGroup].layers;
      layersIdsFromGroups = layersIdsFromGroups.concat(layersIds);
    })

    return layersIdsFromGroups.some((layerId) => this.props.dynamicLayerConfigurations[layerId].isAvailable)
  }

  renderComponent = () => {
    return (
      <>
        <DividerComponent dividerLabel="Dynamic Layers"/>
        <DynamicLayerTabs layerSector={this.props.layerSector} />
      </>
    )
  }

  render(){
    const { tier } = this.props;
    if (tier === Tier.None) return;
    const layersAreAvailable = this.areLayersAvailable();

    return (
      <>
        {layersAreAvailable && this.renderComponent()}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicLayerPanel);