import { Migration } from "..";
import migration001_createChargebeeCustomerId from "./001_createChargebeeCustomerId";
import migration002_createIsOnboarded from "./002_createIsOnboarded";
import migration003_refactorProjectsList from "./003_refactorProjectsList";
import migration004_repairProjectsList from "./004_repairProjectsList";
import migration005_deleteUnusedFields from "./005_deleteUnusedFields";
import migration006_addUserInformationFields from "./006_addUserInformationFields";
import migration007_modifyEmptyChargebeeCustomerId from "./007_modifyEmptyChargebeeCustomerId";
import migration008_addReturnOnCost from "./008_addReturnOnCost";
import migration009_userEmailAndManagedUsers from "./009_userEmailAndManagedUsers";
import migration010_updateReturnOnCost from "./010_updateReturnOnCost";
import migration011_removeManagedSubscriptions from "./011_removeManagedSubscriptions";

const MIGRATIONS: Array<Migration> = [
  migration001_createChargebeeCustomerId,
  migration002_createIsOnboarded,
  migration003_refactorProjectsList,
  migration004_repairProjectsList,
  migration005_deleteUnusedFields,
  migration006_addUserInformationFields,
  migration007_modifyEmptyChargebeeCustomerId,
  migration008_addReturnOnCost,
  migration009_userEmailAndManagedUsers,
  migration010_updateReturnOnCost,
  migration011_removeManagedSubscriptions,
  // NOTE: Users are also being created from the firebase function `subscriptionInvitation.invite`.
  // If user document schema changes, make sure to update that file as well.
];

export default MIGRATIONS;
