import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import { explorerActions, explorerSelectors } from "../../../../state/ui/explorer";
import { allTogglesDisabled } from "../../../utils/uiToggleHelper";
import Tabs from "../../../sharedComponents/Tabs";
import Tab from "../../../sharedComponents/Tabs/Tab";
import TabContainer from "../../../sharedComponents/Tabs/TabContainer";
import TabPanel from "../../../sharedComponents/Tabs/TabPanel";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import ProjectCost from "./ProjectCost";
import PropertyUses from "./PropertyUses";

const mapStateToProps = (state) => {
  return {
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    assumptionsPanelIsOpen: explorerSelectors.getAssumptionPanelIsOpen(state),
  };
};

const mapDispatchToProps = {
  setAssumptionsPanelIsOpen: explorerActions.setAssumptionPanelIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

const TAB_CONFIG = {
  projectCost: {
    label: "Project Cost",
    id: "projectCost",
  },
  propertyUses: {
    label: "Property Uses",
    id: "propertyUses"
  },
}

class AssumptionsPanel extends React.Component<Props, {}>  {
  render() {
    const { assumptionsPanelIsOpen, setAssumptionsPanelIsOpen, toggles } = this.props;

    return (
      <WithPanelHeader
        title="Adjust Assumptions"
        isActive={assumptionsPanelIsOpen}
        onStateChange={setAssumptionsPanelIsOpen}
      >
        <div className="component--assumptions-panel">
          <Tabs defaultTab={TAB_CONFIG.propertyUses.id}>
            {(onTabClick, activeTab) =>
              <>
                <TabContainer>
                  <Tab
                    label={TAB_CONFIG.propertyUses.label}
                    tabId={TAB_CONFIG.propertyUses.id}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                    isDisabled={allTogglesDisabled(toggles)}
                  />
                  <Tab
                    label={TAB_CONFIG.projectCost.label}
                    tabId={TAB_CONFIG.projectCost.id}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                  />
                </TabContainer>
                <TabPanel tabId={TAB_CONFIG.projectCost.id} activeTab={activeTab}>
                  <ProjectCost />
                </TabPanel>
                <TabPanel tabId={TAB_CONFIG.propertyUses.id} activeTab={activeTab}>
                  <PropertyUses />
                </TabPanel>
              </>
            }
          </Tabs>
        </div>
      </WithPanelHeader>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssumptionsPanel);
