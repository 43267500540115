import { useEffect } from "react";
import { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ParcelTool } from "types/ParcelTool";
import { DrawingMode } from "types/DrawingModes";

const NON_DRAWING_TOOLS = [
  ParcelTool.DrawParcel,
  ParcelTool.EditParcel,
  ParcelTool.DeleteParcel,
  ParcelTool.CopyParcel,
];

export const drawOnModeChange = (
  map: React.MutableRefObject<Map | undefined>,
  mapboxDraw: React.MutableRefObject<MapboxDraw | null>,
  parcelTool: ParcelTool,
  cleanEditedParcel: () => void,
  setModeToDrawingOnKeyDown: React.MutableRefObject<boolean>,
  contextMenuClickPoint: React.MutableRefObject<[number, number] | null>,
  setDistanceToLastPolygonVertex: (
    value: React.SetStateAction<number | null>
  ) => void,
  selectParcelToEdit: (point: any) => void
) => {
  useEffect(() => {
    if (map.current) {
      const onModeChange = () => {
        if (!mapboxDraw.current || !mapboxDraw) return;
        if (!NON_DRAWING_TOOLS.includes(parcelTool)) {
          mapboxDraw.current.changeMode(DrawingMode.HiddenMode);
        }
        cleanEditedParcel();

        if (parcelTool === ParcelTool.DrawParcel) {
          if (setModeToDrawingOnKeyDown || contextMenuClickPoint) {
            mapboxDraw.current.changeMode(DrawingMode.DrawPolygon);
            setModeToDrawingOnKeyDown.current = false;
            contextMenuClickPoint.current = null;
          }

          setDistanceToLastPolygonVertex(null);
        } else if (
          parcelTool === ParcelTool.EditParcel &&
          contextMenuClickPoint
        ) {
          selectParcelToEdit(contextMenuClickPoint);
          contextMenuClickPoint.current = null;
        }
      };

      map.current.on("draw.modechange", onModeChange);

      return () => {
        if (map.current) {
          map.current.off("draw.modechange", onModeChange);
        }
      };
    } else {
      return () => {}; // Return a no-op function when map.current is not set
    }
  }, [map]);
};
