import { UserState } from "./reducers";

/**
 * Get user concern object.
 */
const getUserState = (state): UserState => {
  return state.user;
}

/**
 * Get user location.
 */
const getUserDocument = (state) => {
  const user = getUserState(state);
  return user.userDocument;
}

/**
 * Get user location.
 */
const getLocation = (state) => {
  const user = getUserState(state);
  return user.location;
}

/**
 * Get user is ready flag.
 */
const getIsReady = (state) => {
  const user = getUserState(state);
  return user.isReady;
}

/**
 * Get the sorted list of user's developments.
 */
const getDevelopments = (state) => {
  const userDocument = getUserDocument(state);
  if (!userDocument) return [];

  const rawDevelopments = userDocument["projects"];
  let developmentsList = Object.keys(rawDevelopments).map((key) => {
    return {
      id: key,
      ...rawDevelopments[key]
    }
  });

  // Filter out potential null values of development
  developmentsList = developmentsList.filter ((development) => development && development.name !== undefined);

  // By default sort alphabetically by project name. This is important for developments without timeModified.
  developmentsList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

  // Sort by timeModified.
  developmentsList.sort((a, b) => (b.timeModified || 0) - (a.timeModified || 0));

  return developmentsList;
}

/**
 * Get user image url.
 */
const getImageUrl = (state) => {
  const user = getUserState(state);
  return user.imageUrl;
}

export default {
  getUserDocument,
  getLocation,
  getIsReady,
  getDevelopments,
  getImageUrl,
}
