import valueGetters from "../../../../state/development/utils/valueGetters";
import { Config } from "../../../../types/Config";
import Format from "../../../../types/Format";
import Unit from "../../../../types/Unit";
import { VariableId } from "../../../../types/VariableId";

const config: Config = {

  /**
   * Assumptions Panel.
   */

  // <Property>

  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Building Assumption",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    },
    getter: valueGetters.forExistingBuildingAreaInAssumptions,
  },

  [VariableId.ParcelPurchasePricePerArea]: {
    variableId: VariableId.ParcelPurchasePricePerArea,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Purchase Price PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      text: "Purchase Price PSM (Per Square Meter)",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    text: "Property Purchase Price",
    formatOptions: { type: Format.Type.Currency },
  },

  // </ Property>

  // <Building>

  [VariableId.FloorAreaRatio]: {
    variableId: VariableId.FloorAreaRatio,
    text: "Floor Area Ratio (FAR)",
    formatOptions: {
      decimalPlaces: 2,
      type: Format.Type.Number,
    },
  },

  [VariableId.FloorAreaRatioWithoutParking]: {
    variableId: VariableId.FloorAreaRatioWithoutParking,
    text: "FAR Without Parking",
    formatOptions: {
      decimalPlaces: 2,
      type: Format.Type.Number,
    },
  },

  [VariableId.UnitsPerParcelArea]: {
    variableId: VariableId.UnitsPerParcelArea,
    text: "Unit Density Ratio",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Acres,
      unitIsInverse: true,
      formatOptions: { suffix: " units / acre" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Hectares,
      unitIsInverse: true,
      formatOptions: { suffix: " units / hectare" },
    }
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    text: "Number of Floors",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " floors",
    },
  },

  [VariableId.BuildingHeight]: {
    variableId: VariableId.BuildingHeight,
    text: "Building Height",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " feet" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " meters" }
    }
  },

  [VariableId.SiteCoveragePercentage]: {
    variableId: VariableId.SiteCoveragePercentage,
    text: "Lot Coverage",
    formatOptions: {
      type: Format.Type.Percentage,
    }
  },

  [VariableId.SiteCoverageArea]: {
    variableId: VariableId.SiteCoverageArea,
    text: "Building Footprint",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.OpenSpaceArea]: {
    variableId: VariableId.OpenSpaceArea,
    text: "Open Space Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  // </ Building>

  // <Areas>

  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Condo Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.RetailNetLeasableArea]: {
    variableId: VariableId.RetailNetLeasableArea,
    text: "Retail Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    text: "Office Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.IndustrialNetLeasableArea]: {
    variableId: VariableId.IndustrialNetLeasableArea,
    text: "Industrial Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelRoomQuantity]: {
    variableId: VariableId.HotelRoomQuantity,
    text: "Hotel Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys",
    },
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    text: "Parking After Reduction",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
  },

  [VariableId.ParkingGrossBuildableArea]: {
    variableId: VariableId.ParkingGrossBuildableArea,
    text: "Total Parking Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  // </ Areas>

  // <Economics>

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea,
    text: "Multifamily Monthly Rent",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossYearlyRentalIncomePerLeasableArea,
    text: "Multifamily Yearly Rent",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    text: "Retail Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    text: "Office Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    text: "Industrial Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    text: "Hotel ADR",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / key",
    },
  },

  [VariableId.HotelStabilizedOccupancyPercentage]: {
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    text: "Hotel Occupancy",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelStabilizedRevenuePerAvailableRoom]: {
    variableId: VariableId.HotelStabilizedRevenuePerAvailableRoom,
    text: "Hotel RevPAR",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / key",
    },
  },

  [VariableId.HotelOtherRevenuePercentage]: {
    variableId: VariableId.HotelOtherRevenuePercentage,
    text: "Hotel Other Rev %",
    formatOptions: { type: Format.Type.Percentage },
  },

  // </ Economics>

  // <Project Cost>

  [VariableId.ProjectGrossBuildableArea]: {
    variableId: VariableId.ProjectGrossBuildableArea,
    text: "Project Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.ExistingStructureDemolitionCost]: {
    variableId: VariableId.ExistingStructureDemolitionCost,
    text: "Total Demolition Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectHardCost]: {
    variableId: VariableId.ProjectHardCost,
    text: "Total Hard Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectSoftCost]: {
    variableId: VariableId.ProjectSoftCost,
    text: "Total Soft Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectContingencyCost]: {
    variableId: VariableId.ProjectContingencyCost,
    text: "Contingencies Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectConstructionCostIncludingDemolition]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolition,
    text: "Total Construction Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      text: "Avg All-In Cost PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "Avg All-In Cost PSM",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  // </ Project Cost>

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Total Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  /**
   * Back Of the Envelope Panel.
   */

  // <Gross Income>

  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    text: "Multifamily",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    text: "Retail",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    text: "Office",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    text: "Industrial",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.HotelAnnualPotentialRoomRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialRoomRevenueForBackOfEnvelope,
    text: "Hotel Rooms",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelAnnualPotentialOtherRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualPotentialOtherRevenueForBackOfEnvelope,
    text: "Hotel Other Revenue",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    text: "Total Income",
    formatOptions: { type: Format.Type.Currency }
  },

  // </ Gross Income>

  [VariableId.CondoGrossSales]: {
    variableId: VariableId.CondoGrossSales,
    text: "Condo Gross Sales",
    formatOptions: { type: Format.Type.Currency }
  },

  // <Vacancy>

  [VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope,
    text: "Multifamily",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope,
    text: "Retail",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope,
    text: "Office",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope,
    text: "Industrial",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.HotelAnnualRoomUnrealizedRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRoomUnrealizedRevenueForBackOfEnvelope,
    text: "Hotel Rooms",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.HotelAnnualOtherUnrealizedRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOtherUnrealizedRevenueForBackOfEnvelope,
    text: "Hotel Other Revenue",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope,
    text: "Total Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  // </ Vacancy>

  // <Operating Expense>

  [VariableId.MultifamilyYearOneOperatingExpense]: {
    variableId: VariableId.MultifamilyYearOneOperatingExpense,
    text: "Multifamily",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.RetailYearOneOperatingExpense]: {
    variableId: VariableId.RetailYearOneOperatingExpense,
    text: "Retail",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.OfficeYearOneOperatingExpense]: {
    variableId: VariableId.OfficeYearOneOperatingExpense,
    text: "Office",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.IndustrialYearOneOperatingExpense]: {
    variableId: VariableId.IndustrialYearOneOperatingExpense,
    text: "Industrial",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    }
  },

  [VariableId.HotelAnnualRoomOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRoomOperatingExpenseForBackOfEnvelope,
    text: "Hotel Rooms",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.HotelAnnualOtherOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOtherOperatingExpenseForBackOfEnvelope,
    text: "Hotel Other Revenue",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope,
    text: "Total Operating Expense",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.ProjectAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "NOI",
    formatOptions: {
      type: Format.Type.Currency,
    },
  },

  // </ Operating Expense>

  // <Sale>

  [VariableId.ExitCapRate]: {
    variableId: VariableId.ExitCapRate,
    text: "Exit Cap Rate",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    },
  },

  [VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesSaleValueForBackOfEnvelope,
    text: "Estimated Value",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.TotalProjectSaleForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleForBackOfEnvelope,
    text: "Total Project Sale",
    formatOptions: { type: Format.Type.Currency }
  },

  // </ Sale>

  // <Profit>

  [VariableId.ProjectSaleCommissionPercentage]: {
    variableId: VariableId.ProjectSaleCommissionPercentage,
    text: "Sales Commission",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1,
    }
  },

  [VariableId.TotalProjectSalesCommissions]: {
    variableId: VariableId.TotalProjectSalesCommissions,
    text: "Sales Cost",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  [VariableId.TotalProjectSaleProfitForBackOfEnvelope]: {
    variableId: VariableId.TotalProjectSaleProfitForBackOfEnvelope,
    text: "Total Profit",
    formatOptions: {
      type: Format.Type.Currency,
    }
  },

  // </ Profit>

  [VariableId.SaleReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.SaleReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnInvestmentForBackOfEnvelope,
    text: "ROC",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

};

export default config;
