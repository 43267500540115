import * as firebase from "firebase";
import { call, put, takeEvery, all } from "redux-saga/effects";
import { Chargebee } from "../../types/Service/Chargebee";
import { UserDocument } from "../../types/UserDocument";
import Firebase from "../../types/Service/Firebase";
import chargebee from "../../utils/functions/chargebee";
import subscriptions from "../../utils/database/subscriptions";
import users from "../../utils/database/users";
import databaseHelper from "../../utils/database/utils/databaseHelper";
import authentication from "../../utils/authentication";
import { authenticationActionTypes } from "../authentication";
import { subscriptionInvitationsActionTypes } from "../subscriptionInvitations";
import { userActionTypes, userActions } from "../user";
import actionTypes from "./actionTypes";
import actions from "./actions";
import handleMappingError from "utils/errorHelper";

/**
 * Load a subscription.
 */
function* load(action: ReturnType<typeof actions.loadStart>) {
  try {
    const subscription: Chargebee.Subscription = yield call(chargebee.getSubscription, action.payload.subscriptionId);
    yield put(actions.loadSuccess(subscription));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Load the subscription used by a specific user.
 */
function* loadByUser(action) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const subscriptionDocument = yield call(subscriptions.read, user.uid);
    if (subscriptionDocument === null) {
      yield put(actions.loadSuccess(null));
    } else {
      const subscription: Chargebee.Subscription = yield call(chargebee.getSubscription, subscriptionDocument.subscriptionId);
      yield put(actions.loadSuccess(subscription));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Create a subscription for the current user.
 */
function* create(action: ReturnType<typeof actions.createStart>) {
  try {
    const { userDocument, subscriptionPlanId, couponIds, paymentIntent, firstName, lastName } = action.payload;
    const user = authentication.getCurrentUser();
    if (!user) return;

    let chargebeeResult;
    if (!userDocument.chargebeeCustomerId) {
      if (!paymentIntent) throw Error("No payment intent provided.");

      // Create customer subscription with chargebee.
      let customer: Chargebee.Customer = {
        email: (user && user.email) || "",
        first_name: firstName,
        last_name: lastName,
      }

      chargebeeResult =
        yield call(chargebee.createSubscriptionAndCustomer, subscriptionPlanId, customer, paymentIntent.id, couponIds);
    } else {
      chargebeeResult =
        yield call(chargebee.createSubscriptionForCustomer, subscriptionPlanId, userDocument.chargebeeCustomerId, couponIds);
    }

    if (!chargebeeResult) throw Error("Internal error.");

    const newUserDocument: Partial<UserDocument> = {
      chargebeeCustomerId: chargebeeResult.customer.id,
    }

    const userReference = firebase.firestore().doc(`users/${user.uid}`);
    const updateUser = {
      type: Firebase.OperationType.Update,
      documentReference: userReference,
      data: newUserDocument,
    };

    const subscriptionId = chargebeeResult.subscription.id;
    const ownerId = user.uid;
    const subscriptionDocument = {
      ownerId,
      subscriptionId,
    };

    const subscriptionReference = firebase.firestore().doc(`subscriptions/${user.uid}`);
    const setSubscription = {
      type: Firebase.OperationType.Set,
      documentReference: subscriptionReference,
      data: subscriptionDocument,
    };

    yield call(databaseHelper.batchWrite, [updateUser, setSubscription]);
    yield call(loadCreditCardHelper, chargebeeResult.customer.id);
    yield put(actions.createSuccess(chargebeeResult.subscription, chargebeeResult.customer));
    yield put(actions.paymentSuccess());
  } catch (error) {
    const errorObject: any = error;
    const errorMessage = handleMappingError(errorObject.message)
    yield put(actions.setPaymentError(errorMessage));
  }
}

/**
 * Load the payment plan prices.
 */
function* loadPlanPrices() {
  try {
    const [
      standardMonthlyPlan,
      standardYearlyPlan,
      advancedMonthlyPlan,
      advancedYearlyPlan,
      proMonthlyPlan,
      proYearlyPlan
    ]: Chargebee.Plan[] = yield all([
      call(chargebee.getPaymentPlan, Chargebee.PlanId.DeveloperMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.DeveloperYearly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.AdvancedMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.AdvancedYearly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.ProMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.ProYearly),
    ]);

    yield put(
      actions.setPlanPrices({
        [Chargebee.PlanId.DeveloperMonthly]: standardMonthlyPlan.price / 100,
        [Chargebee.PlanId.DeveloperYearly]: standardYearlyPlan.price / 100,
        [Chargebee.PlanId.AdvancedMonthly]: advancedMonthlyPlan.price / 100,
        [Chargebee.PlanId.AdvancedYearly]: advancedYearlyPlan.price / 100,
        [Chargebee.PlanId.ProMonthly]: proMonthlyPlan.price / 100,
        [Chargebee.PlanId.ProYearly]: proYearlyPlan.price / 100,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

/**
 * Load the user's credit card information.
 */
function* loadCreditCard(action) {
  try {
    const chargebeeCustomerId = action.payload.userDocument.chargebeeCustomerId;
    yield call(loadCreditCardHelper, chargebeeCustomerId);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Load the user's credit card information.
 */
function* loadCreditCardHelper(chargebeeCustomerId: string) {
  let card = null;
  if (chargebeeCustomerId) {
    card = yield call(chargebee.getCard, chargebeeCustomerId);
  }

  yield put(actions.loadCreditCardSuccess(card));
}

/**
 * Update the user's credit card information.
 */
function* updateCreditCard(action: ReturnType<typeof actions.updateCreditCard>) {
  try {
    const { firstName, lastName, paymentIntent, chargebeeCustomerId } = action.payload;

    if (chargebeeCustomerId) {
      try {
        yield call(chargebee.addCard, chargebeeCustomerId, paymentIntent.id);
        yield call(loadCreditCardHelper, chargebeeCustomerId);
      } catch {
        throw Error("Internal error.");
      }
    } else {
      const currentUser = authentication.getCurrentUser();
      const customer: Chargebee.Customer = {
        email: (currentUser && currentUser.email) || "",
        first_name: firstName,
        last_name: lastName,
      };

      try {
        const chargebeeResult = yield call(chargebee.createCustomer, customer, paymentIntent.id);

        const userDocument: Partial<UserDocument> = {
          chargebeeCustomerId: chargebeeResult.customer.id,
        };

        if (currentUser) {
          yield call(users.update, currentUser.uid, userDocument);
        } else {
          throw Error("User document was not updated on chargebee customer creation.");
        }

        yield put(userActions.loadStart());
      } catch {
        throw Error("Internal error.");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Cancel subscription.
 */
function* cancelSubscription(action: ReturnType<typeof actions.cancelStart>) {
  try {
    const user = authentication.getCurrentUser();
    if (!user) return;

    const { subscriptionId } = action.payload;
    yield call(chargebee.cancelSubscription, subscriptionId, false);

    yield put(actions.cancelSuccess());
  } catch (error) {
    console.error(error);
  }
}

/**
 * Process all `cancelStart` actions.
 */
function* watchCancelStart() {
  yield takeEvery(actionTypes.CANCEL_START, cancelSubscription);
}

/**
 * Process all `updateCreditCard` actions.
 */
function* watchUpdateCreditCard() {
  yield takeEvery(actionTypes.UPDATE_CREDIT_CARD, updateCreditCard);
}

/**
 * Process all `loadCreditCardStart` and user `loadSuccess` actions.
 */
function* watchLoadCreditCardStart() {
  yield takeEvery([actionTypes.LOAD_CREDIT_CARD_START, userActionTypes.LOAD_SUCCESS], loadCreditCard);
}

/**
 * Process all sign in success actions.
 */
function* watchAuthenticationSuccess() {
  yield takeEvery([authenticationActionTypes.SIGN_IN_SUCCESS, authenticationActionTypes.SIGN_OUT_SUCCESS], loadPlanPrices);
}

/**
 * Process all `loadStart` actions.
 */
function* watchLoad() {
  yield takeEvery(actionTypes.LOAD_START, load);
}

/**
 * Process all the action that require a subscription load.
 */
function* watchLoadByCustomer() {
  yield takeEvery([
    authenticationActionTypes.SIGN_IN_SUCCESS,
    actionTypes.CANCEL_SUCCESS,
    subscriptionInvitationsActionTypes.ACCEPT_SUCCESS,
    subscriptionInvitationsActionTypes.REJECT_SUCCESS,
  ], loadByUser);
}

/**
 * Process all `createStart` actions.
 */
function* watchCreateStart() {
  yield takeEvery(actionTypes.CREATE_START, create);
}

export default {
  watchLoad,
  watchLoadByCustomer,
  watchAuthenticationSuccess,
  watchLoadCreditCardStart,
  watchCreateStart,
  watchUpdateCreditCard,
  watchCancelStart,
}
