import { Map } from "mapbox-gl";
import { Feature, MultiPolygon, Polygon } from "@turf/turf";
import { GeoJSON } from "geojson";
import geometry from "utils/geometry";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { RawParcelFieldId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import turf from "utils/turf";
import populateParcelProperties from "utils/parcel/populateParcelProperties";
import { SetParcelsInViewportFunction } from "state/newDevelopment/types";

/** Get the camera object if the url contains parameters for it. */
export const getCameraFromUrl = (
  cameraString: string | undefined
): { center: [number, number]; zoom: number } | null => {
  if (!cameraString) return null;
  if (!cameraString) return null;
  let camera = cameraString.split(",").map(Number);
  if (camera.length < 3) return null;

  let center = camera.slice(0, 2) as [number, number];
  let zoom = camera[2];
  if (center.some(isNaN) || isNaN(zoom)) return null;

  return { center, zoom };
};

/** Get parcels available in the viewport. */
export const getParcelsInViewport = (
  map: React.MutableRefObject<Map | undefined>,
  setParcelsInViewport: SetParcelsInViewportFunction
) => {
  /**
   * Get all the parcels in the viewport along with their sanitized zoning, demographics and
   * opportunity zone information.
   */
  if (!map.current) return;
  const parcelFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
  });

  const zoningFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
  });

  const opportunityZoneFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaOpportunityZoneFill],
  });

  const demographicsFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaDemographicsQuery],
  });

  let buildingFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.UsaBuildingsQuery],
  });

  let existingBuildingFeatures = map.current.queryRenderedFeatures(undefined, {
    layers: [MapStyleProperties.LayerId.ParcelsWithExistingBuildingQuery],
  });

  let reducedZoningFeatures: any = {};
  zoningFeatures.forEach((feature) => {
    if (feature.properties) {
      if (!reducedZoningFeatures[feature.properties[RawParcelFieldId.Id]]) {
        reducedZoningFeatures[feature.properties[RawParcelFieldId.Id]] = feature;
      }
    }
  });

  let reducedExistingBuildingFeatures: any = {};
  existingBuildingFeatures.forEach((feature) => {
    if (feature.properties) {
      if (!reducedExistingBuildingFeatures[feature.properties[RawParcelFieldId.Id]]) {
        reducedExistingBuildingFeatures[feature.properties[RawParcelFieldId.Id]] = feature;
      }
    }
  });

  // Parcels can be split into smaller features by Mapbox. This block joins them back together.
  let reducedParcelFeatures: any = {};
  parcelFeatures.forEach((parcel) => {
    if (parcel.properties) {
      if (!reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]]) {
        reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]] = [];
      }
      reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]].push(parcel);
    }
  });

  reducedParcelFeatures = Object.values(reducedParcelFeatures)
    .map((features) => geometry.featuresUnion(features))
    .filter((feature) => feature);

  let combinedParcelData: GeoJSON[] = [];
  reducedParcelFeatures.forEach((parcel) => {
    try {
      const center = turf.centerOfMass(parcel);
      let parcelZoning = reducedZoningFeatures[parcel.properties[RawParcelFieldId.Id]];
      let parcelExistingBuildingData = reducedExistingBuildingFeatures[parcel.properties[RawParcelFieldId.Id]];

      let parcelDemographics;
      for (let index = 0; index < demographicsFeatures.length; index++) {
        const demographicsFeature: Feature<Polygon | MultiPolygon> = {
          type: "Feature",
          geometry: demographicsFeatures[index].geometry as Polygon | MultiPolygon,
          properties: demographicsFeatures[index].properties,
        };
        if (turf.booleanPointInPolygon(center, demographicsFeature)) {
          parcelDemographics = demographicsFeatures[index];
          break;
        }
      }
      let parcelOpportunityZone;
      for (let index = 0; index < opportunityZoneFeatures.length; index++) {
        const opportunityZoneFeature: Feature<Polygon | MultiPolygon> = {
          type: "Feature",
          geometry: opportunityZoneFeatures[index].geometry as Polygon | MultiPolygon,
          properties: opportunityZoneFeatures[index].properties,
        };
        if (turf.booleanPointInPolygon(center, opportunityZoneFeature)) {
          parcelOpportunityZone = opportunityZoneFeatures[index];
          break;
        }
      }
      let parcelBuildingData;
      for (let index = 0; index < buildingFeatures.length; index++) {
        const buildingsFeature: Feature<Polygon | MultiPolygon> = {
          type: "Feature",
          geometry: buildingFeatures[index].geometry as Polygon | MultiPolygon,
          properties: buildingFeatures[index].properties,
        };
        if (turf.booleanPointInPolygon(center, buildingsFeature)) {
          parcelBuildingData = buildingFeatures[index];
          break;
        }
      }

      combinedParcelData.push(
        populateParcelProperties({
          parcelFeature: parcel,
          zoningFeature: parcelZoning,
          demographicsFeature: parcelDemographics,
          opportunityZoneFeature: parcelOpportunityZone,
          buildingFeature: parcelBuildingData,
          existingBuildingData: parcelExistingBuildingData,
        })
      );
    } catch (error) {
      console.warn(error);
    }
  });

  setParcelsInViewport(combinedParcelData);
};
