/**
 * Initialize Chargebee.
 */
const initialize = () => {
  const Chargebee = window["Chargebee"];
  Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE, publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY });
}

export default {
  initialize
};
