import React from 'react'

interface OwnProps {
  title: string;
  isOpen: boolean;
  iconClass?: string;
  className?: string;
  toggleIsOpen();
}

type Props = OwnProps;

export class AccordionSection extends React.PureComponent<Props, {}> {
  render() {
    const { title, children, iconClass, className } = this.props;
    const { isOpen, toggleIsOpen } = this.props;

    if (!children) return null;

    return (
      <div className={`component--accordion-section ${className || ""}`}>
        <div className={`section-title ${isOpen ? "open" : ""}`} onClick={toggleIsOpen}>
          <div className="title-wrapper">
            {iconClass && <div className={`title-icon icon ${iconClass}`} />}
            <div>{title}</div>
          </div>
          <span className="open-close-icon icon" />
        </div>
        {isOpen && <div className="accordion-content">{children}</div>}
      </div>
    );
  }
}

export default AccordionSection
