import userPopulators from "./users";
import Firebase from "../../../../types/Service/Firebase";

export type AsyncDataPopulator<T> = (data: T, id: string) => Promise<boolean>;

/**
 * Return the correct array of AsyncDataPopulators depending on the given collection.
 *
 * If the given collection does not have any AsyncDataPopulators, the empty array is
 * returned.
 */
const populatorsFor = (collection: Firebase.Collection): Array<AsyncDataPopulator<any>> => {
  switch (collection) {
    case Firebase.Collection.Users: return userPopulators;
    default: return [];
  }
}

/**
 * Populate missing data that depends on asynchronous calls.
 *
 * @return - true if there was data added and false otherwise.
 */
const populate = async (data: AsyncDataPopulator<any>, collection: Firebase.Collection, documentId: string): Promise<boolean> => {
  let result = false;
  const dataPopulators = populatorsFor(collection);

  // Out of an abundance of caution, we run each populator one at a time. It may
  // be safe to run them in parallel but this requires more thought.
  for (let populator of dataPopulators) {
    result = await populator(data, documentId) || result;
  }

  return result;
}

export default {
  populate: populate
}
