import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import ConfigurationHandler from "../../../../../sharedComponents/ConfigurationHandler";
import SliderBox from "../../../sharedComponents/SliderBox";
import PanelSectionTitle from "../../../../../sharedComponents/PanelSectionTitle";
import config from "./config";
import ReadOnlyLinkedSliderBox from "views/pages/ExplorerPage/sharedComponents/ReadOnlyLinkedSliderBox";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class CondoPanel extends React.PureComponent<Props, any> {
  /**
   * Render Condo Unit Mix section.
   */
  renderUnitMixSection = () => {
    return (
      <div data-tour="condo-unit-mix">
        <ConfigurationHandler
          config={config.condoTotalUnitQuantity}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.condoMicrounitQuantityToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.condoStudioQuantityToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.condoOneBedQuantityToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.condoTwoBedQuantityToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.condoThreeBedQuantityToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </div>
    );
  };

  /**
   * Render Condo Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    if (this.props.values.condoTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="condo-gross-buildable">
        <ConfigurationHandler
          config={config.condoGrossBuildableArea}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          {this.props.values.condoMicrounitQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoMicrounitArea}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoStudioQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoStudioArea}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoOneBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoOneBedArea}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoTwoBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoTwoBedArea}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoThreeBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoThreeBedArea}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          <ConfigurationHandler
            config={config.condoLossFactor}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.condoAmenitiesAreaToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </div>
    );
  };

  /**
   * Render Condo Sales section.
   */
  renderSalesSection = () => {
    if (this.props.values.condoTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="condo-sales">
        <ConfigurationHandler
          config={config.condoGrossSales}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          {this.props.values.condoMicrounitQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoSalePricePerMicrounit}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoStudioQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoSalePricePerStudio}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoOneBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoSalePricePerOneBed}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoTwoBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoSalePricePerTwoBed}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
          {this.props.values.condoThreeBedQuantityToggleable ? (
            <ConfigurationHandler
              config={config.condoSalePricePerThreeBed}
              component={ReadOnlyLinkedSliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="panel-content">
        {this.renderUnitMixSection()}
        {this.renderGrossBuildableSection()}
        {this.renderSalesSection()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(CondoPanel);
