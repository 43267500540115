import mapboxgl from "mapbox-gl";

/**
 * A boolean indicating whether mapbox supports the current browser.
 */
const isSupportedByMapbox = mapboxgl.supported();

/**
 * A boolean indicating whether Internet Explorer 6-11 was detected.
 */
const isInternetExplorer = /*@cc_on!@*/ false || !!document["documentMode"];

/**
 * A boolean indicating whether Edge 20+ was detected.
 */
const isEdge = !isInternetExplorer && !!window["StyleMedia"];

/**
 * Check if the device is a mobile (smarthphone or tablet) using WURFL.js,
 * more info at: https://web.wurfl.io/#wurfl-js
 */
const isMobile = window["WURFL"]["is_mobile"];

/**
 * A boolean indicating whether an officially supported browser was detected.
 */
const isFullySupported = !isMobile && isSupportedByMapbox;

/**
 * A boolean indicating whether any partially supported browser was detected.
 */
const isPartiallySupported = !isInternetExplorer && !isEdge;

/**
 * A string summarizing the browser detection results. Useful for debugging.
 */
const report = `\n` + `Partially Supported: ${isPartiallySupported}\n` + `Fully Supported: ${isFullySupported}\n`;

export default {
  isFullySupported,
  isPartiallySupported,
  report,
};
