import React from "react";
import { connect } from "react-redux";
import { DynamicLayerId, LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { mapsActions, mapsSelectors } from "../../../state/ui/shared/maps";
import dynamicLayersConfig from "../DynamicLayerSelection/dynamicLayersConfig";

interface OwnProps {
  layerIds: Array<LayerId>;
  layerGroup: DynamicLayerId;
  children(isActive: boolean, onClick: () => void);
}

const mapStateToProps = (state) => {
  return {
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
    getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  }
}

const mapDispatchToProps = {
  setSelectedDynamicLayerGroup: mapsActions.setSelectedDynamicLayerGroup,
  resetDynamicLayers: mapsActions.resetDynamicLayers,
  activateDynamicLayers: mapsActions.activateDynamicLayers,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class DynamicLayerToggleBase extends React.PureComponent<Props, {}> {
  /**
   * Toggle layers.
   */
   toggleDynamicLayersActivation = () => {
    const { layerGroup, getSelectedDynamicLayer, resetDynamicLayers, activateDynamicLayers, setSelectedDynamicLayerGroup } = this.props;

    if(getSelectedDynamicLayer === null){
      resetDynamicLayers();
      setSelectedDynamicLayerGroup(layerGroup);
      return activateDynamicLayers(layerGroup, dynamicLayersConfig[layerGroup].initializationYear, layerGroup);
    }

    if(getSelectedDynamicLayer !== null && getSelectedDynamicLayer !== layerGroup){
      resetDynamicLayers();
      setSelectedDynamicLayerGroup(layerGroup);
      return activateDynamicLayers(layerGroup, dynamicLayersConfig[layerGroup].initializationYear, layerGroup);
    }

    return resetDynamicLayers();
  }

  render() {
    const { layerIds, dynamicLayerConfigurations, children } = this.props;

    const isVisible = layerIds.some((layerId) => dynamicLayerConfigurations[layerId].isActive);
    return children(isVisible, this.toggleDynamicLayersActivation)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicLayerToggleBase) as React.ComponentType<OwnProps>;
