import React, { PureComponent } from "react";
import { VariableId } from "types/VariableId";
import { Getter } from "../../../types/Config";
import Cell from "../Cell/Cell";
import CellText from "../CellText";

interface Props {
  text: string;
  valueStyleVariation: string;
  hiddenValues?: boolean;
  isFromBackOfEnvelope?: boolean;
  styleVariation: string;
  variabeId: VariableId;
  getter: Getter;
}

class CellsContainer extends PureComponent<Props, {}> {
  render() {
    const { text, variabeId, valueStyleVariation, hiddenValues, isFromBackOfEnvelope, ...rest } = this.props;
    const styleVariation = `${rest.styleVariation || ""} ${valueStyleVariation || ""}`;

    return (
      <>
        <CellText text={text} variableId={variabeId} {...rest} />
        <Cell {...{ ...rest, styleVariation, hiddenValues, isFromBackOfEnvelope }} />
      </>
    );
  }
}

export default CellsContainer;
