import developmentAccessors from "../../../../../state/development/utils/developmentAccessors";
import { Development } from "../../../../../types/Development/Development";
import Unit from "../../../../../types/Unit";
import { VariableId } from "../../../../../types/VariableId";
import parcelAccessors from "../../../../parcel/parcelAccessors";
import unitConversions from "../../../../unitConversions";

interface RegressionConstant {
  incomeMultiplier: number,
  gdpMultiplier: number,
  intercept: number,
  minimum?: number,
  convert: {
    from: Unit.Type,
    to: Unit.Type,
    isInverse?: boolean,
  },
}

export type RegressionConstants = {
  [key in
  VariableId.CondoSalePricePerMicrounit |
  VariableId.CondoSalePricePerStudio |
  VariableId.CondoSalePricePerOneBed |
  VariableId.CondoSalePricePerTwoBed |
  VariableId.CondoSalePricePerThreeBed |
  VariableId.CondoHardCostPerArea |
  VariableId.MultifamilyYearOneMonthlyRentPerMicrounit |
  VariableId.MultifamilyYearOneMonthlyRentPerStudio |
  VariableId.MultifamilyYearOneMonthlyRentPerOneBed |
  VariableId.MultifamilyYearOneMonthlyRentPerTwoBed |
  VariableId.MultifamilyYearOneMonthlyRentPerThreeBed |
  VariableId.MultifamilyHardCostPerArea |
  VariableId.HotelStabilizedAverageDailyRate |
  VariableId.HotelHardCostPerArea |
  VariableId.OfficeYearOneRentPerArea |
  VariableId.OfficeHardCostPerArea |
  VariableId.RetailYearOneRentPerArea |
  VariableId.RetailHardCostPerArea |
  VariableId.IndustrialYearOneRentPerArea |
  VariableId.IndustrialHardCostPerArea |
  VariableId.ParkingHardCostPerArea
  ]: RegressionConstant
}

const LINEAR_REGRESSION_CONSTANTS: RegressionConstants = {
  [VariableId.CondoSalePricePerMicrounit]: {
    incomeMultiplier: 0.0024902056,
    gdpMultiplier: -0.0252054246,
    intercept: 100.61,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.CondoSalePricePerStudio]: {
    incomeMultiplier: 0.003096,
    gdpMultiplier: 0.331692,
    intercept: 26.55,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.CondoSalePricePerOneBed]: {
    incomeMultiplier: 0.006439,
    gdpMultiplier: 1.040201,
    intercept: -141.33,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.CondoSalePricePerTwoBed]: {
    incomeMultiplier: 0.010401,
    gdpMultiplier: 2.131342,
    intercept: -355.33,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.CondoSalePricePerThreeBed]: {
    incomeMultiplier: 0.010495,
    gdpMultiplier: 2.087941,
    intercept: -285.22,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.CondoHardCostPerArea]: {
    incomeMultiplier: 0.001407,
    gdpMultiplier: -0.015679,
    intercept: 150.17,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: {
    incomeMultiplier: 0.000069,
    gdpMultiplier: 0.002315,
    intercept: 0.50,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: {
    incomeMultiplier: 0.000054,
    gdpMultiplier: 0.002318,
    intercept: 0.43,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: {
    incomeMultiplier: 0.000042,
    gdpMultiplier: 0.002138,
    intercept: 0.29,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: {
    incomeMultiplier: 0.000039,
    gdpMultiplier: 0.002339,
    intercept: 0.20,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: {
    incomeMultiplier: 0.000045,
    gdpMultiplier: 0.002513,
    intercept: 0.08,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.MultifamilyHardCostPerArea]: {
    incomeMultiplier: 0.00117,
    gdpMultiplier: -0.013021,
    intercept: 124.1,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.HotelStabilizedAverageDailyRate]: {
    incomeMultiplier: 0.003705,
    gdpMultiplier: 0.166725,
    intercept: 20.8,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareFeet,
    }
  },
  [VariableId.HotelHardCostPerArea]: {
    incomeMultiplier: 0.002428,
    gdpMultiplier: 0.038138,
    intercept: 156.66,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.OfficeYearOneRentPerArea]: {
    incomeMultiplier: 0.000393,
    gdpMultiplier: 0.034538,
    intercept: 6.41,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.OfficeHardCostPerArea]: {
    incomeMultiplier: 0.000971,
    gdpMultiplier: -0.012057,
    intercept: 156.66,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.RetailYearOneRentPerArea]: {
    incomeMultiplier: 0.000513,
    gdpMultiplier: 0.008990,
    intercept: 6.95,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.RetailHardCostPerArea]: {
    incomeMultiplier: 0.000549,
    gdpMultiplier: -0.006493,
    intercept: 74.85,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.IndustrialYearOneRentPerArea]: {
    incomeMultiplier: 0.0002,
    gdpMultiplier: 0.001840,
    intercept: 6.12,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.IndustrialHardCostPerArea]: {
    incomeMultiplier: 0.000513,
    gdpMultiplier: -0.023789,
    intercept: 68.43,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
  [VariableId.ParkingHardCostPerArea]: {
    incomeMultiplier: 0.000483,
    gdpMultiplier: -0.001951,
    intercept: 39.17,
    minimum: 0,
    convert: {
      from: Unit.Type.SquareFeet,
      to: Unit.Type.SquareMeters,
      isInverse: true,
    }
  },
}

/**
 * Generate the market data presets that use linear regression.
 */
const generatePreset = (development: Development, variableId: keyof RegressionConstants, area?: number) => {
  const parcel = developmentAccessors.getParcel(development);
  const gdp = Math.max(...parcelAccessors.getGdp(parcel), 0);
  const medianIncome = Math.max(...parcelAccessors.getMedianIncomeTotal(parcel), 0);

  const { incomeMultiplier, gdpMultiplier, intercept, minimum } = LINEAR_REGRESSION_CONSTANTS[variableId];
  let value = (incomeMultiplier * medianIncome) + (gdpMultiplier * gdp) + intercept;

  value = unitConversions.convert(
    value,
    LINEAR_REGRESSION_CONSTANTS[variableId].convert.from,
    LINEAR_REGRESSION_CONSTANTS[variableId].convert.to,
    LINEAR_REGRESSION_CONSTANTS[variableId].convert.isInverse
  );

  return {
    "minimum": minimum !== undefined
      ? minimum
      : area ? (value / 2) * area : (value / 2),
    "value": area ? value * area : value,
    "maximum": area ? (value * 2) * area : (value * 2),
  }
}

export default {
  generatePreset
};
