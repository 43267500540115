import { VariableId } from "../../../types/VariableId"

const universalConstraints = {
  "minimum": {
    [VariableId.ParkingRatioCondo]: 0,
    [VariableId.ParkingRatioMultifamily]: 0,
    [VariableId.ParkingRatioHotel]: 0,
    [VariableId.ParkingRatioOffice]: 0,
    [VariableId.ParkingRatioRetail]: 0,
    [VariableId.ParkingRatioIndustrial]: 0,
    [VariableId.HeightOfGroundFloor]: 1,
    [VariableId.HeightOfTypicalFloor]: 1,
    [VariableId.SetbackA]: 0,
    [VariableId.SetbackB]: 0,
    [VariableId.SetbackC]: 0,
    [VariableId.SetbackD]: 0,
    [VariableId.CondoSalePricePerMicrounit]: 0,
    [VariableId.CondoSalePricePerStudio]: 0,
    [VariableId.CondoSalePricePerOneBed]: 0,
    [VariableId.CondoSalePricePerTwoBed]: 0,
    [VariableId.CondoSalePricePerThreeBed]: 0,
    [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: 0,
    [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: 0,
    [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: 0,
    [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: 0,
    [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: 0,
    [VariableId.HotelStabilizedAverageDailyRate]: 0,
    [VariableId.HotelOtherRevenuePercentage]: 0,
    [VariableId.OfficeYearOneRentPerArea]: 0,
    [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: 0,
    [VariableId.RetailYearOneRentPerArea]: 0,
    [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: 0,
    [VariableId.IndustrialYearOneRentPerArea]: 0,
    [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: 0,
    [VariableId.CondoMicrounitQuantityToggleable]: 0,
    [VariableId.CondoStudioQuantityToggleable]: 0,
    [VariableId.CondoOneBedQuantityToggleable]: 0,
    [VariableId.CondoTwoBedQuantityToggleable]: 0,
    [VariableId.CondoThreeBedQuantityToggleable]: 0,
    [VariableId.CondoMicrounitArea]: 0,
    [VariableId.CondoStudioArea]: 0,
    [VariableId.CondoOneBedArea]: 0,
    [VariableId.CondoTwoBedArea]: 0,
    [VariableId.CondoThreeBedArea]: 0,
    [VariableId.CondoLossFactor]: 0,
    [VariableId.CondoAmenitiesAreaToggleable]: 0,
    [VariableId.MultifamilyMicrounitQuantityToggleable]: 0,
    [VariableId.MultifamilyStudioQuantityToggleable]: 0,
    [VariableId.MultifamilyOneBedQuantityToggleable]: 0,
    [VariableId.MultifamilyTwoBedQuantityToggleable]: 0,
    [VariableId.MultifamilyThreeBedQuantityToggleable]: 0,
    [VariableId.MultifamilyMicrounitArea]: 0,
    [VariableId.MultifamilyStudioArea]: 0,
    [VariableId.MultifamilyOneBedArea]: 0,
    [VariableId.MultifamilyTwoBedArea]: 0,
    [VariableId.MultifamilyThreeBedArea]: 0,
    [VariableId.MultifamilyLossFactor]: 0,
    [VariableId.MultifamilyAmenitiesAreaToggleable]: 0,
    [VariableId.HotelRoomQuantityToggleable]: 0,
    [VariableId.HotelAverageRoomArea]: 0,
    [VariableId.HotelLossFactor]: 0,
    [VariableId.HotelAmenitiesAreaToggleable]: 0,
    [VariableId.OfficeNetUsableAreaToggleable]: 0,
    [VariableId.OfficeCommonAreaFactor]: 0,
    [VariableId.RetailNetLeasableAreaToggleable]: 0,
    [VariableId.RetailLossFactor]: 0,
    [VariableId.IndustrialNetLeasableAreaToggleable]: 0,
    [VariableId.IndustrialLossFactor]: 0,
    [VariableId.ParkingReductionFactor]: 0,
    [VariableId.ParkingSpaceArea]: 0,
    [VariableId.MultifamilyStabilizedVacancyPercentage]: 0,
    [VariableId.HotelStabilizedOccupancyPercentage]: 0,
    [VariableId.OfficeStabilizedVacancyPercentage]: 0,
    [VariableId.RetailStabilizedVacancyPercentage]: 0,
    [VariableId.IndustrialStabilizedVacancyPercentage]: 0,
    [VariableId.MultifamilyOperatingExpensePercentage]: 0,
    [VariableId.HotelOperatingExpensePercentage]: 0,
    [VariableId.OfficeYearOneOperatingExpensePerArea]: 0,
    [VariableId.RetailYearOneOperatingExpensePerArea]: 0,
    [VariableId.IndustrialYearOneOperatingExpensePerArea]: 0,
    [VariableId.CondoHardCostPerArea]: 0,
    [VariableId.MultifamilyHardCostPerArea]: 0,
    [VariableId.HotelHardCostPerArea]: 0,
    [VariableId.OfficeHardCostPerArea]: 0,
    [VariableId.RetailHardCostPerArea]: 0,
    [VariableId.IndustrialHardCostPerArea]: 0,
    [VariableId.ParkingHardCostPerArea]: 0,
    [VariableId.ParcelPurchasePrice]: 0,
    [VariableId.ExistingStructureDemolitionCostPerArea]: 0,
    [VariableId.ContingencyCostPercentage]: 0,
    [VariableId.SoftCostPercentage]: 0,
    [VariableId.ProjectSaleCommissionPercentage]: 0,
    [VariableId.ParkingReductionFeePerSpace]: 0,
    [VariableId.ExistingStructureArea]: 0,
    [VariableId.ExitCapRate]: 0,
  },
  "maximum": {
    [VariableId.ParkingRatioCondo]: 100,
    [VariableId.ParkingRatioMultifamily]: 100,
    [VariableId.ParkingRatioHotel]: 100,
    [VariableId.ParkingRatioOffice]: 100,
    [VariableId.ParkingRatioRetail]: 100,
    [VariableId.ParkingRatioIndustrial]: 100,
    [VariableId.HeightOfGroundFloor]: 30,
    [VariableId.HeightOfTypicalFloor]: 30,
    [VariableId.SetbackA]: 3000,
    [VariableId.SetbackB]: 3000,
    [VariableId.SetbackC]: 3000,
    [VariableId.SetbackD]: 3000,
    [VariableId.CondoSalePricePerMicrounit]: 900000000,
    [VariableId.CondoSalePricePerStudio]: 900000000,
    [VariableId.CondoSalePricePerOneBed]: 900000000,
    [VariableId.CondoSalePricePerTwoBed]: 900000000,
    [VariableId.CondoSalePricePerThreeBed]: 900000000,
    [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: 100000,
    [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: 100000,
    [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: 100000,
    [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: 100000,
    [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: 100000,
    [VariableId.HotelStabilizedAverageDailyRate]: 25000,
    [VariableId.HotelOtherRevenuePercentage]: 0.99,
    [VariableId.OfficeYearOneRentPerArea]: 100000,
    [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: 100000,
    [VariableId.RetailYearOneRentPerArea]: 100000,
    [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: 100000,
    [VariableId.IndustrialYearOneRentPerArea]: 100000,
    [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: 100000,
    [VariableId.CondoMicrounitQuantityToggleable]: 10000,
    [VariableId.CondoStudioQuantityToggleable]: 10000,
    [VariableId.CondoOneBedQuantityToggleable]: 10000,
    [VariableId.CondoTwoBedQuantityToggleable]: 10000,
    [VariableId.CondoThreeBedQuantityToggleable]: 10000,
    [VariableId.CondoMicrounitArea]: 30000,
    [VariableId.CondoStudioArea]: 30000,
    [VariableId.CondoOneBedArea]: 30000,
    [VariableId.CondoTwoBedArea]: 30000,
    [VariableId.CondoThreeBedArea]: 30000,
    [VariableId.CondoLossFactor]: 0.99,
    [VariableId.CondoAmenitiesAreaToggleable]: 30000000,
    [VariableId.MultifamilyMicrounitQuantityToggleable]: 10000,
    [VariableId.MultifamilyStudioQuantityToggleable]: 10000,
    [VariableId.MultifamilyOneBedQuantityToggleable]: 10000,
    [VariableId.MultifamilyTwoBedQuantityToggleable]: 10000,
    [VariableId.MultifamilyThreeBedQuantityToggleable]: 10000,
    [VariableId.MultifamilyMicrounitArea]: 30000,
    [VariableId.MultifamilyStudioArea]: 30000,
    [VariableId.MultifamilyOneBedArea]: 30000,
    [VariableId.MultifamilyTwoBedArea]: 30000,
    [VariableId.MultifamilyThreeBedArea]: 30000,
    [VariableId.MultifamilyLossFactor]: 0.99,
    [VariableId.MultifamilyAmenitiesAreaToggleable]: 30000000,
    [VariableId.HotelRoomQuantityToggleable]: 10000,
    [VariableId.HotelAverageRoomArea]: 30000,
    [VariableId.HotelLossFactor]: 0.99,
    [VariableId.HotelAmenitiesAreaToggleable]: 30000000,
    [VariableId.OfficeNetUsableAreaToggleable]: 30000000,
    [VariableId.OfficeCommonAreaFactor]: 0.99,
    [VariableId.RetailNetLeasableAreaToggleable]: 30000000,
    [VariableId.RetailLossFactor]: 0.99,
    [VariableId.IndustrialNetLeasableAreaToggleable]: 30000000,
    [VariableId.IndustrialLossFactor]: 0.99,
    [VariableId.ParkingReductionFactor]: 1,
    [VariableId.ParkingSpaceArea]: 3000,
    [VariableId.MultifamilyStabilizedVacancyPercentage]: 1,
    [VariableId.HotelStabilizedOccupancyPercentage]: 1,
    [VariableId.OfficeStabilizedVacancyPercentage]: 1,
    [VariableId.RetailStabilizedVacancyPercentage]: 1,
    [VariableId.IndustrialStabilizedVacancyPercentage]: 1,
    [VariableId.MultifamilyOperatingExpensePercentage]: 1,
    [VariableId.HotelOperatingExpensePercentage]: 1,
    [VariableId.OfficeYearOneOperatingExpensePerArea]: 30000,
    [VariableId.RetailYearOneOperatingExpensePerArea]: 30000,
    [VariableId.IndustrialYearOneOperatingExpensePerArea]: 30000,
    [VariableId.CondoHardCostPerArea]: 100000,
    [VariableId.MultifamilyHardCostPerArea]: 100000,
    [VariableId.HotelHardCostPerArea]: 100000,
    [VariableId.OfficeHardCostPerArea]: 100000,
    [VariableId.RetailHardCostPerArea]: 100000,
    [VariableId.IndustrialHardCostPerArea]: 100000,
    [VariableId.ParkingHardCostPerArea]: 100000,
    [VariableId.ParcelPurchasePrice]: 9000000000,
    [VariableId.ExistingStructureDemolitionCostPerArea]: 10000,
    [VariableId.ContingencyCostPercentage]: 1,
    [VariableId.SoftCostPercentage]: 1,
    [VariableId.ProjectSaleCommissionPercentage]: 1,
    [VariableId.ParkingReductionFeePerSpace]: 10000000,
    [VariableId.ExistingStructureArea]: 300000000,
    [VariableId.ExitCapRate]: 1,
  }
};

export default universalConstraints;
