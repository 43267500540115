import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import ConfigurationHandler from "../../../../../sharedComponents/ConfigurationHandler";
import SliderBox from "../../../sharedComponents/SliderBox";
import PanelSectionTitle from "../../../../../sharedComponents/PanelSectionTitle";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class RetailPanel extends React.Component<Props, {}> {
  /**
   * Render Retail Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    return (
      <>
        <ConfigurationHandler
          config={config.retailGrossBuildableArea}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.retailNetLeasableAreaToggleable}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          {this.props.values.retailNetLeasableAreaToggleable ? (
            <ConfigurationHandler
              config={config.retailLossFactor}
              component={SliderBox}
              unitSystem={this.props.unitSystem}
            />
          ) : null}
        </div>
      </>
    );
  };

  /**
   * Render Retail Income section.
   */
  renderIncomeSection = () => {
    if (this.props.values.retailNetLeasableAreaToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler
          config={config.retailYearOnePotentialGrossIncome}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.retailYearOneRentPerArea}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.retailYearOneExpenseReimbursementFeePerArea}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </>
    );
  };

  /**
   * Render Retail NOI section.
   */
  renderNOISection = () => {
    if (this.props.values.retailNetLeasableAreaToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler
          config={config.retailAnnualNetOperatingIncomeForBackOfEnvelope}
          component={PanelSectionTitle}
          unitSystem={this.props.unitSystem}
        />
        <div>
          <ConfigurationHandler
            config={config.retailStabilizedVacancyPercentage}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
          <ConfigurationHandler
            config={config.retailYearOneOperatingExpensePerArea}
            component={SliderBox}
            unitSystem={this.props.unitSystem}
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="panel-content">
        {this.renderGrossBuildableSection()}
        {this.renderIncomeSection()}
        {this.renderNOISection()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(RetailPanel);
