import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  newDevelopmentUiActions,
  newDevelopmentUiSelectors,
} from "../../../../state/ui/newDevelopment";
import InitialValuesPanel from "../InitialValuesPanel";

const StartProjectButton = () => {
  const dispatch = useDispatch();
  const initialValuesPanelIsOpen = useSelector(
    newDevelopmentUiSelectors.getInitialValuesPanelIsOpen
  );

  return (
    <div className="component--start-project-button">
      <button
        onClick={() =>
          dispatch(newDevelopmentUiActions.openInitialValuesPanel())
        }
      >
        Feasibility Study
        <div className="icon" />
      </button>
      {initialValuesPanelIsOpen && (
        <InitialValuesPanel
          onStateChange={() =>
            dispatch(newDevelopmentUiActions.closeInitialValuesPanel())
          }
        />
      )}
    </div>
  );
};

export default StartProjectButton;
