import React from "react";
import companyInfo from "../../../../utils/companyInfo";

const LIST_OF_FEATURES = [
  "Everything in Pro National +",
  "Custom Search Algorithms",
  "Country-wide Search",
  "Custom Map Layers",
  "Custom Indexes",
  "",
  "Cities on Demand",
  "Data on Demand",
  "",
  "Bulk Pricing",
  "White Label",
];
interface OwnProps {
  presentationOnly?: boolean;
}

type Props = OwnProps;

class EnterpriseTierPanel extends React.PureComponent<Props, {}> {
  /**
   * Render the list of features.
   */
  renderListOfFeatures = () => {
    return (
      <div className="list-wrapper">
        {LIST_OF_FEATURES.map((feature, index) => (
          <div className={`list-item ${index === 0 ? "bold" : ""}`} key={`feature_${index}`}>
            {feature}
          </div>
        ))}
      </div>
    );
  };

  renderButtonBox = () => {
    const { presentationOnly } = this.props;

    return presentationOnly === true ? (
      <div className="no-button-wrapper"></div>
    ) : (
      <div className="button-wrapper">
        <a
          className="component--subscribe-button"
          href={companyInfo.ENTERPRISE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </div>
    );
  };

  render() {
    return (
      <div className="component--tier-panel">
        <div className="plan-name">Enterprise</div>
        <label className="tier-sub-description">Data only you and your team can see.</label>
        <div className="price custom">Custom Price</div>
        {this.renderButtonBox()}
        {this.renderListOfFeatures()}
        <div className="bottom" />
      </div>
    );
  }
}

export default EnterpriseTierPanel;
