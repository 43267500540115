import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class AreasWithParcelsLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.CountyBoundaries}
        classes="areas-with-parcels"
        legendRows={[
          <LegendRow
            color="#B0B4DD"
            text="Areas with Parcels"
          />
        ]}
      />
    );
  }
}

export default AreasWithParcelsLegend;