import DynamicLayerGroup from "types/DynamicLayerGroup";
import LayerGroup from "types/LayerGroup";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";

export const mapLegendLayerGroup = {
  [MapStyleProperties.LayerId.BuildableAreaRatio]: LayerGroup.BuildableAreaRatio,
  [MapStyleProperties.LayerId.HistoricBuildingsPoint]: LayerGroup.HistoricBuildings,
  [MapStyleProperties.LayerId.HistoricBuildingsSymbol]: LayerGroup.HistoricBuildings,
  [MapStyleProperties.LayerId.BusinessesPoint]: LayerGroup.Businesses,
  [MapStyleProperties.LayerId.BusinessesSymbol]: LayerGroup.Businesses,
  [MapStyleProperties.LayerId.CapRates]: LayerGroup.CapRates,
  [MapStyleProperties.LayerId.CensusTractBorder]: LayerGroup.CensusTracts,
  [MapStyleProperties.LayerId.CensusTractSymbol]: LayerGroup.CensusTracts,
  [MapStyleProperties.LayerId.EducationFacilitiesPoint]: LayerGroup.EducationFacilities,
  [MapStyleProperties.LayerId.EducationFacilitiesSymbol]: LayerGroup.EducationFacilities,
  [MapStyleProperties.LayerId.HospitalsSymbol]: LayerGroup.Hospitals,
  [MapStyleProperties.LayerId.HospitalsPoint]: LayerGroup.Hospitals,
  [MapStyleProperties.LayerId.HotelAverageDailyRateFill]: LayerGroup.HotelAverageDailyRate,
  [MapStyleProperties.LayerId.HotelAverageDailyRateBorder]: LayerGroup.HotelAverageDailyRate,
  [MapStyleProperties.LayerId.HotelAverageDailyRateSymbol]: LayerGroup.HotelAverageDailyRate,
  [MapStyleProperties.LayerId.HotelsPoint]: LayerGroup.Hotels,
  [MapStyleProperties.LayerId.HotelsSymbol]: LayerGroup.Hotels,
  [MapStyleProperties.LayerId.IndustrialRateFill]: LayerGroup.IndustrialRate,
  [MapStyleProperties.LayerId.IndustrialRateBorder]: LayerGroup.IndustrialRate,
  [MapStyleProperties.LayerId.IndustrialRateSymbol]: LayerGroup.IndustrialRate,
  [MapStyleProperties.LayerId.MultifamilyRentFill]: LayerGroup.MultifamilyRent,
  [MapStyleProperties.LayerId.MultifamilyRentBorder]: LayerGroup.MultifamilyRent,
  [MapStyleProperties.LayerId.MultifamilyRentSymbol]: LayerGroup.MultifamilyRent,
  [MapStyleProperties.LayerId.OfficeRateFill]: LayerGroup.OfficeRate,
  [MapStyleProperties.LayerId.OfficeRateBorder]: LayerGroup.OfficeRate,
  [MapStyleProperties.LayerId.OfficeRateSymbol]: LayerGroup.OfficeRate,
  [MapStyleProperties.LayerId.ReitsProjectsPoint]: LayerGroup.ReitsProjects,
  [MapStyleProperties.LayerId.ReitsProjectsSymbol]: LayerGroup.ReitsProjects,
  [MapStyleProperties.LayerId.RetailRateFill]: LayerGroup.RetailRate,
  [MapStyleProperties.LayerId.RetailRateBorder]: LayerGroup.RetailRate,
  [MapStyleProperties.LayerId.RetailRateSymbol]: LayerGroup.RetailRate,
  [MapStyleProperties.LayerId.RoadTypeBorder]: LayerGroup.RoadType,
  [MapStyleProperties.LayerId.SmartSearchResultsFill]: LayerGroup.SmartSearchResults,
  [MapStyleProperties.LayerId.TrafficDailyAverage2019Border]: LayerGroup.TrafficDailyAverage2019,
  [MapStyleProperties.LayerId.UsaBuildings]: LayerGroup.Buildings,
  [MapStyleProperties.LayerId.UsaBuildingsQuery]: LayerGroup.Buildings,
  [MapStyleProperties.LayerId.UsaCountiesBorder]: LayerGroup.CountyBoundaries,
  [MapStyleProperties.LayerId.UsaCountiesFill]: LayerGroup.CountyBoundaries,
  [MapStyleProperties.LayerId.UsaOpportunityZoneFill]: LayerGroup.OpportunityZone,
  [MapStyleProperties.LayerId.UsaZoningBorder]: LayerGroup.Zoning,
  [MapStyleProperties.LayerId.UsaZoningSymbol]: LayerGroup.Zoning,
  [MapStyleProperties.LayerId.FederalOwnedLandsFill]: LayerGroup.FederalOwnedLands,
  [MapStyleProperties.LayerId.FederalOwnedLandsSymbol]: LayerGroup.FederalOwnedLands,
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsFill]: LayerGroup.NaturalAndMarineProtectedLands,
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsSymbol]: LayerGroup.NaturalAndMarineProtectedLands,
  [MapStyleProperties.LayerId.ForestsFill]: LayerGroup.Forests,
  [MapStyleProperties.LayerId.ForestsSymbol]: LayerGroup.Forests,
  [MapStyleProperties.LayerId.HazardousSitesFill]: LayerGroup.HazardousSites,
  [MapStyleProperties.LayerId.HazardousSitesSymbol]: LayerGroup.HazardousSites,
  [MapStyleProperties.LayerId.HazardousSitesPoint]: LayerGroup.HazardousSites,
  [MapStyleProperties.LayerId.WatershedsFill]: LayerGroup.Watersheds,
  [MapStyleProperties.LayerId.WatershedsSymbol]: LayerGroup.Watersheds,
  [MapStyleProperties.LayerId.HistoricSitesSymbol]: LayerGroup.HistoricSites,
  [MapStyleProperties.LayerId.HistoricSitesPoint]: LayerGroup.HistoricSites,
  [MapStyleProperties.LayerId.ParksFill]: LayerGroup.Parks,
  [MapStyleProperties.LayerId.IndianReservationsFill]: LayerGroup.IndianReservations,
  [MapStyleProperties.LayerId.WetlandsFill]: LayerGroup.Wetlands,
  [MapStyleProperties.LayerId.WetlandsBorder]: LayerGroup.Wetlands,
  [MapStyleProperties.LayerId.BayAreaHousingSitesFill]: LayerGroup.BayAreaHousingSites,
  [MapStyleProperties.LayerId.BayAreaHousingSitesSymbol]: LayerGroup.BayAreaHousingSites,
  [MapStyleProperties.LayerId.BayAreaHousingSitesBorder]: LayerGroup.BayAreaHousingSites,
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventoryPoint]: LayerGroup.LIHTC_ExistingPropertyInventory,
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventorySymbol]: LayerGroup.LIHTC_ExistingPropertyInventory,
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasFill]: LayerGroup.DifficultDevelopmentAreas,
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasBorder]: LayerGroup.DifficultDevelopmentAreas,
  [MapStyleProperties.LayerId.QualifiedCensusTractsFill]: LayerGroup.QualifiedCensusTracts,
  [MapStyleProperties.LayerId.QualifiedCensusTractsBorder]: LayerGroup.QualifiedCensusTracts,
  [MapStyleProperties.LayerId.CityBoundariesFill]: LayerGroup.CityBoundaries,
  [MapStyleProperties.LayerId.CityBoundariesSymbol]: LayerGroup.CityBoundaries,
  [MapStyleProperties.LayerId.CityBoundariesBorder]: LayerGroup.CityBoundaries,
  [MapStyleProperties.LayerId.TransitOrientedCommunityFill]: LayerGroup.TransitOrientedCommunity,

  [MapStyleProperties.LayerId.IndustrialSitesFill]: LayerGroup.IndustrialSites,
  [MapStyleProperties.LayerId.IndustrialSitesBorder]: LayerGroup.IndustrialSites,
  [MapStyleProperties.LayerId.MultifamilySitesFill]: LayerGroup.MultifamilySites,
  [MapStyleProperties.LayerId.MultifamilySitesBorder]: LayerGroup.MultifamilySites,
};

export const mapLegendDynamicLayerGroup = {
  [MapStyleProperties.LayerId.DynamicPopulationFill_2010]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2010]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2010]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2011]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2011]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2011]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2012]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2012]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2012]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2013]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2013]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2013]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2014]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2014]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2014]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2015]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2015]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2015]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2016]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2016]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2016]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2017]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2017]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2017]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2018]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2018]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2018]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2019]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2019]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2019]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2020]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2020]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2020]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2021]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2021]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2021]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationFill_2022]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2022]: DynamicLayerId.Dynamic_Layer_Population,
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2022]: DynamicLayerId.Dynamic_Layer_Population,

  [MapStyleProperties.LayerId.DynamicMedianRentFill_2013]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2013]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2014]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2014]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2015]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2015]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2016]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2016]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2017]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2017]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2018]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2018]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2019]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2019]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2020]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2020]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2021]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2021]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2022]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2022]: DynamicLayerId.Dynamic_Layer_MedianRent,
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022]: DynamicLayerId.Dynamic_Layer_MedianRent,

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2013]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022]:
    DynamicLayerId.Dynamic_Layer_Employment_Per_Population,

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2013]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2014]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2015]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2016]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2017]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2018]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2019]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2020]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2021]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2022]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022]: DynamicLayerId.Dynamic_Layer_Household_Income,
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022]: DynamicLayerId.Dynamic_Layer_Household_Income,

  [MapStyleProperties.LayerId.SeaLevelRiseQuery]: DynamicLayerId.Dynamic_Layer_Sea_Level_Rise,
};

export const getActiveLayerGroups = (activeLayers: string[]): LayerGroup[] => {
  const layerGroups: LayerGroup[] = [];
  activeLayers.forEach((layer) => {
    const layerGroup = mapLegendLayerGroup[layer];
    if (layerGroup && !layerGroups.includes(layerGroup)) {
      layerGroups.push(layerGroup);
    }
  });
  return layerGroups;
};

export const getActiveDynamicLayerGroups = (activeLayers: string[]): DynamicLayerGroup[] => {
  const layerGroups: DynamicLayerGroup[] = [];
  activeLayers.forEach((layer) => {
    const layerGroup = mapLegendDynamicLayerGroup[layer];
    if (layerGroup && !layerGroups.includes(layerGroup)) {
      layerGroups.push(layerGroup);
    }
  });
  return layerGroups;
};
