/**
 * @fileoverview This component provides the user with all of the options for
 * authenticating using third-party "federated" authentication providers.
 */

import React from "react";
// import AuthenticateWithFacebook from "./AuthenticateWithFacebook";
import AuthenticateWithGoogle from "./AuthenticateWithGoogle";
import Authentication from "../../../types/Authentication";
import authentication from "../../../utils/authentication";

interface Props {
  onError(errorMessage: string): void;
  termsOfServiceAccepted?: boolean;
}

class FederatedAuthenticationProviders extends React.PureComponent<Props, {}> {
  /**
   * Handle error code and set error message.
   */
  handleError = (errorCode: Authentication.ErrorCode) => {
    this.props.onError(authentication.getErrorMessage(errorCode));
  }

  render() {
    const { termsOfServiceAccepted } = this.props;

    return (
      <div className="component--federated-authentication-providers">
        <div className="top-separator"><div className="circle">Or</div></div>
        <AuthenticateWithGoogle onError={this.handleError} termsOfServiceAccepted={termsOfServiceAccepted} />
      </div>
    )
  }
}

export default FederatedAuthenticationProviders;
