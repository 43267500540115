import React from "react";
import SiteAnalysisLink from "./SiteAnalysisLink";
import ExistingBuildingLink from "./ExistingBuildingLink";
import NewDevelopmentLink from "./NewDevelopmentLink";
import { Tier } from "types/Tier";
import DashboardZoningReportLink from "./DashboardZoningReportLink/DashboardZoningReportLink";

interface OwnProps {
  tier: Tier;
}

export default class ApplicationLinks extends React.PureComponent<OwnProps, {}> {
  render() {
    const { tier } = this.props;
    const isProTier = tier === Tier.Pro;
    return (
      <div className="component--application-links">
        <SiteAnalysisLink />
        <NewDevelopmentLink />
        {isProTier ? <ExistingBuildingLink /> : <DashboardZoningReportLink />}
      </div>
    );
  }
}
