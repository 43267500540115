const TOGGLE_COPY_PROJECT_POPUP = "ui/explorer/TOGGLE_COPY_PROJECT_POPUP";
const SET_ASSUMPTIONS_PANEL_IS_OPEN = "ui/explorer/SET_ASSUMPTIONS_PANEL_IS_OPEN";
const SET_DEFINE_BUILDING_PANEL_IS_OPEN = "ui/explorer/SET_DEFINE_BUILDING_PANEL_IS_OPEN";
const SET_RETURN_ANALYSIS_PANEL_IS_OPEN = "ui/explorer/SET_RETURN_ANALYSIS_PANEL_IS_OPEN";
const SET_PROPERTY_INFO_PANEL_IS_OPEN = "ui/explorer/SET_PROPERTY_INFO_PANEL_IS_OPEN";

export default {
  TOGGLE_COPY_PROJECT_POPUP,
  SET_ASSUMPTIONS_PANEL_IS_OPEN,
  SET_DEFINE_BUILDING_PANEL_IS_OPEN,
  SET_RETURN_ANALYSIS_PANEL_IS_OPEN,
  SET_PROPERTY_INFO_PANEL_IS_OPEN
};
