import { BuildingUse } from "../../types/BuildingUse";

/**
 * @fileoverview This module provides functions for working with building usage
 *  toggle input values. These are primarily targeted at helping UI components
 *  make rendering decisions.
 */

const RENTAL_USES = ["multifamily", "office", "retail", "industrial"];
const INCOME_PRODUCING_USES = [...RENTAL_USES, "hotel"];
const FOR_SALE_USES = ["condo"];
const PARKING_USES = [...INCOME_PRODUCING_USES, ...FOR_SALE_USES];

/**
 * A boolean indicating whether all of the given toggles are disabled.
 */
const allTogglesDisabled = (toggles: {[toggleName: string]: boolean; }) => {
  return !Object.values(toggles)
    .some((toggleValue) => toggleValue);
};

/**
 * A boolean indicating whether the given usageGroup is enabled, based on the
 * given toggle values.
 */
const buildingUsageIsEnabled = (toggles, usageGroup: BuildingUse) => {
  switch (usageGroup) {
    case BuildingUse.Parking:
      return parkingIsEnabled(toggles);
    case BuildingUse.RentalUses:
      return rentalUsesIsEnabled(toggles);
    case BuildingUse.IncomeProducing:
      return incomeProducingUsesIsEnabled(toggles);
    default:
      if (toggles[usageGroup] === undefined) return true;
      return toggles[usageGroup];
  }
};

/**
 * A boolean indicating whether the given toggle values should result in parking
 * usages being enabled.
 */
const parkingIsEnabled = (toggles) => {
  // `parking` is true if any of the building usages that require parking
  // are true.
  return PARKING_USES
    .some((toggleId) => toggles[toggleId]);
};

/**
 * A boolean indicating whether the given toggle values should result in rental
 * use usages being enabled.
 */
const rentalUsesIsEnabled = (toggles) => {
  // `rentalUses` is enabled if any of the building usages that require rentalUses
  // are true.
  return RENTAL_USES.some((toggleId) => toggles[toggleId]);
};

/**
 * A boolean indicating whether the given toggle values should result in income
 * producing usages being enabled.
 */
const incomeProducingUsesIsEnabled = (toggles) => {
  return INCOME_PRODUCING_USES.some((toggleId) => toggles[toggleId]);
};

/**
 * A boolean indicating whether the given toggle values should result in for
 * sale usages being enabled.
 */
const forSaleUsesIsEnabled = (toggles) => {
  return FOR_SALE_USES.some((toggleId) => toggles[toggleId]);
};

/**
 * Returns the list of rental uses which are enabled.
 */
const getEnabledRentalUses = (toggles) => {
  return RENTAL_USES.filter((toggleId) => toggles[toggleId]);
}

export {
  getEnabledRentalUses,
  allTogglesDisabled,
  buildingUsageIsEnabled,
  incomeProducingUsesIsEnabled,
  forSaleUsesIsEnabled,
};
