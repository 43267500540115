import produce from "immer";
import { Chargebee } from "../../../../types/Service";
import { Tier } from "../../../../types/Tier";
import layerHelper, {
  LayerConfigurationsLayerIds,
  DynamicLayerConfigurationsLayerIds,
} from "../../../../utils/mapbox/layerHelper";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import { DynamicLayerId, LayerId, LayerPaint } from "../../../../utils/mapbox/mapStyleProperties/mapStyleProperties";
import { setbackModeActions, setbackModeActionTypes } from "../../../setbackMode";
import { subscriptionActions, subscriptionActionTypes } from "../../../subscription";
import { userActions, userActionTypes } from "../../../user";
import subscriptionHelper from "../../../utils/subscriptionHelper";
import actions from "./actions";
import actionTypes from "./actionTypes";

export type LayerConfiguration = {
  minimumTier: Tier;
  isVisible: boolean; // Paint property in mapbox is set to "visible"
  isEnabled: boolean; // Layer enabled depending on the user tier.
  isActive: boolean; // Layer is displayed to user. opacity is used.
  isAvailable: boolean; // Layer is available in current viewport.
  layerType: MapStyleProperties.LayerType;
  paint: LayerPaint;
};

export type DynamicLayerConfiguration = {
  minimumTier: Tier;
  isVisible: boolean;
  isEnabled: boolean; // Layer enabled depending on the user tier.
  isActive: boolean; // Layer is displayed to user.
  isAvailable: boolean; // Layer is available in current viewport.
  layerType: MapStyleProperties.LayerType;
  fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId;
  paint: LayerPaint;
};

export type DynamicLayerObject = {
  selectedLayer: MapStyleProperties.RawDynamicLayersFieldId;
  isActive: boolean;
};

export interface MapsState {
  layerConfigurations: {
    [key in LayerConfigurationsLayerIds]: LayerConfiguration;
  };
  dynamicLayerConfigurations: {
    [key in DynamicLayerConfigurationsLayerIds]: DynamicLayerConfiguration;
  };
  selectedIndividualDynamicLayers: {
    [key in DynamicLayerId]: MapStyleProperties.RawDynamicLayersFieldId | null;
  };
  selectedDynamicLayerGroup: MapStyleProperties.DynamicLayerId | null;
  mapStyleUrl: MapStyleProperties.StyleUrl;
  developerTierMapStyleUrl: MapStyleProperties.DeveloperTierStyleUrl;
  mapIsReady: boolean;
  styleChanged: boolean;
}

export const INITIAL_STATE: MapsState = {
  layerConfigurations: layerHelper.getInitialConfigurationValues(),
  dynamicLayerConfigurations: layerHelper.getInitialDynamicLayersConfigurationValues(),
  selectedIndividualDynamicLayers: layerHelper.getInitialSelectedIndividualDynamicLayers(),
  selectedDynamicLayerGroup: null,
  mapStyleUrl: MapStyleProperties.StyleUrl.Streets,
  developerTierMapStyleUrl: MapStyleProperties.DeveloperTierStyleUrl.DeveloperTierStreets,
  mapIsReady: false,
  styleChanged: false,
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): MapsState => {
  switch (action.type) {
    case userActionTypes.EMAIL_VERIFIED:
      return onUserEmailVerified(previousState, action);
    case subscriptionActionTypes.LOAD_SUCCESS:
      return onSubscriptionLoadSuccess(previousState, action);
    case subscriptionActionTypes.CREATE_SUCCESS:
      return onSubscriptionCreateSuccess(previousState, action);
    case setbackModeActionTypes.SET_SETBACK_MODE:
      return onSetSetbackMode(previousState, action);
    case actionTypes.RESET_FLAGS:
      return resetFlags(previousState, action);
    case actionTypes.TOGGLE_LAYERS:
      return toggleLayers(previousState, action);
    case actionTypes.INITIALIZE_LAYERS:
      return initializeLayers(previousState, action);
    case actionTypes.INITIALIZE_LAYERS_ON_STYLE_CHANGE:
      return initializeLayersOnStyleChange(previousState, action);
    case actionTypes.TOGGLE_MAP_STYLE:
      return toggleMapStyleUrl(previousState);
    case actionTypes.TOGGLE_DEVELOPER_MAP_STYLE:
      return toggleDeveloperMapStyleUrl(previousState);
    case actionTypes.SET_AVAILABLE_LAYERS:
      return setAvailableLayers(previousState, action);
    case actionTypes.TOGGLE_DYNAMIC_LAYERS:
      return toggleDynamicLayers(previousState, action);
    case actionTypes.ACTIVATE_DYNAMIC_LAYERS:
      return activateDynamicLayers(previousState, action);
    case actionTypes.SET_AVAILABLE_DYNAMIC_LAYERS:
      return setAvailableDynamicLayers(previousState, action);
    case actionTypes.SET_SELECTED_DYNAMIC_LAYER:
      return setSelectedDynamicLayerGroup(previousState, action);
    case actionTypes.RESET_DYNAMIC_LAYERS:
      return resetDynamicLayers(previousState);
    default:
      return previousState;
  }
};

/**
 * See `resetFlags` action creator.
 *
 * Update the layers when subscription is loaded.
 */
const resetFlags = (previousState: MapsState, action: ReturnType<typeof actions.resetFlags>): MapsState => {
  return {
    ...previousState,
    mapIsReady: false,
    styleChanged: false,
  };
};

/**
 * See `setbackModeActions.setSetbackMode` action creator.
 *
 * Update the layers when subscription is loaded.
 */
const onSetSetbackMode = (
  previousState: MapsState,
  action: ReturnType<typeof setbackModeActions.setSetbackMode>
): MapsState => {
  return {
    ...previousState,
    mapIsReady: !action.payload.setbackModeIsActive ? false : previousState.mapIsReady,
  };
};

/**
 * See `SubscriptionActions.loadSuccess` action creator.
 *
 * Update the layers when subscription is loaded.
 */
const onSubscriptionLoadSuccess = (
  previousState: MapsState,
  action: ReturnType<typeof subscriptionActions.loadSuccess>
): MapsState => {
  return updateLayerConfigurations(previousState, action.payload.subscription);
};

/**
 * See `SubscriptionActions.createSuccess` action creator.
 *
 * Update the layers when subscription is created.
 */
const onSubscriptionCreateSuccess = (
  previousState: MapsState,
  action: ReturnType<typeof subscriptionActions.createSuccess>
): MapsState => {
  return updateLayerConfigurations(previousState, action.payload.subscription);
};

/**
 * See `UserActions.emailVerified` action creator.
 *
 * Update the layers when the email is verified.
 */
const onUserEmailVerified = (
  previousState: MapsState,
  action: ReturnType<typeof userActions.emailVerified>
): MapsState => {
  return updateLayerConfigurations(previousState);
};

/**
 * Update enabled layers depending on the tier the user is in.
 */
const updateLayerConfigurations = (
  previousState: MapsState,
  subscription?: Chargebee.Subscription | null
): MapsState => {
  const userTier: Tier = subscriptionHelper.getTier(subscription);

  const layerConfigurations = produce(INITIAL_STATE.layerConfigurations, (draftLayerConfigurations) => {
    for (let layerId of Object.keys(draftLayerConfigurations)) {
      if (userTier < INITIAL_STATE.layerConfigurations[layerId].minimumTier) {
        draftLayerConfigurations[layerId].isEnabled = false;
      }
    }
  });

  return {
    ...previousState,
    layerConfigurations,
  };
};

/**
 * Toggle map style url and update state.
 */
const toggleMapStyleUrl = (previousState: MapsState): MapsState => {
  if (!previousState.mapIsReady) return previousState;

  const nextStyleUrl =
    previousState.mapStyleUrl === MapStyleProperties.StyleUrl.Streets
      ? MapStyleProperties.StyleUrl.Satellite
      : MapStyleProperties.StyleUrl.Streets;

  return {
    ...previousState,
    mapStyleUrl: nextStyleUrl,
    mapIsReady: false,
    styleChanged: true,
  };
};

/**
 * Toggle map style url and update state.
 */
const toggleDeveloperMapStyleUrl = (previousState: MapsState): MapsState => {
  const nextStyleUrl =
    previousState.developerTierMapStyleUrl === MapStyleProperties.DeveloperTierStyleUrl.DeveloperTierStreets
      ? MapStyleProperties.DeveloperTierStyleUrl.DeveloperTierSatellite
      : MapStyleProperties.DeveloperTierStyleUrl.DeveloperTierStreets;

  return {
    ...previousState,
    developerTierMapStyleUrl: nextStyleUrl,
  };
};

/**
 * See `setAvailableLayers` action creator.
 *
 * Update the layers that are available in the viewport.
 */
const setAvailableLayers = (
  previousState: MapsState,
  action: ReturnType<typeof actions.setAvailableLayers>
): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(nextDraftState.layerConfigurations).forEach((layerId) => {
      const castedLayerId = layerId as LayerConfigurationsLayerIds;
      nextDraftState.layerConfigurations[castedLayerId].isAvailable = action.payload.availableLayers.has(castedLayerId);
    });

    nextDraftState.mapIsReady = true;
  });
};

/**
 * See `toggleLayers` action creator.
 *
 * Toggle layers 'isVisible' flag on the state.
 */
const toggleLayers = (previousState, action: ReturnType<typeof actions.toggleLayers>): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    action.payload.layerIds.forEach((layerId) => {
      nextDraftState.layerConfigurations[layerId].isActive = !nextDraftState.layerConfigurations[layerId].isActive;
    });
  });
};

/**
 * See `setAvailableDynamicLayers` action creator.
 *
 * Update the dynamic layers that are available in the viewport.
 */
const setAvailableDynamicLayers = (
  previousState: MapsState,
  action: ReturnType<typeof actions.setAvailableDynamicLayers>
): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(nextDraftState.dynamicLayerConfigurations).forEach((dynamicLayerId) => {
      const castedDynamicLayerId = dynamicLayerId as LayerId;
      nextDraftState.dynamicLayerConfigurations[castedDynamicLayerId].isAvailable =
        action.payload.availableDynamicLayers.includes(castedDynamicLayerId);
    });

    nextDraftState.mapIsReady = true;
  });
};

/**
 * See `toggleDynamicLayers` action creator.
 *
 * Toggle dynamicLayers 'isActive' flag on the state.
 */
const toggleDynamicLayers = (previousState, action: ReturnType<typeof actions.toggleDynamicLayers>): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    nextDraftState.selectedIndividualDynamicLayers[action.payload.layerGroup] = action.payload.selectedLayer;
    action.payload.dynamicLayerIds.forEach((dynamicLayerId) => {
      if (!nextDraftState.dynamicLayerConfigurations[dynamicLayerId].isActive) {
        nextDraftState.dynamicLayerConfigurations[dynamicLayerId].isActive = true;
      }
    });
  });
};

/**
 * See `activateDynamicLayers` action creator.
 *
 * Activate a set of Dynamic Layers based on the initial Year for that set.
 */
const activateDynamicLayers = (previousState, action: ReturnType<typeof actions.activateDynamicLayers>): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(nextDraftState.dynamicLayerConfigurations).forEach((dynamicLayerId) => {
      if (nextDraftState.dynamicLayerConfigurations[dynamicLayerId].fieldToQuery === action.payload.initialYear) {
        nextDraftState.dynamicLayerConfigurations[dynamicLayerId].isActive = true;
      }
    });
    nextDraftState.selectedIndividualDynamicLayers[action.payload.layerGroup] = action.payload.initialYear;
  });
};

/**
 * See `resetDynamicLayers` action creator.
 *
 * Reset the Dynamic Layers to default values.
 */
const resetDynamicLayers = (previousState): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(nextDraftState.dynamicLayerConfigurations).forEach((dynamicLayerId) => {
      nextDraftState.dynamicLayerConfigurations[dynamicLayerId].isActive = false;
    });

    Object.keys(nextDraftState.selectedIndividualDynamicLayers).forEach((dynamicLayerId) => {
      nextDraftState.selectedIndividualDynamicLayers[dynamicLayerId] = null;
    });

    nextDraftState.selectedDynamicLayerGroup = null;
  });
};

/**
 * See `setSelectedDynamicLayerGroup` action creator.
 *
 * Set the Selected Dynamic Group with the selected layer Id.
 */
const setSelectedDynamicLayerGroup = (
  previousState,
  action: ReturnType<typeof actions.setSelectedDynamicLayerGroup>
): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    nextDraftState.selectedDynamicLayerGroup = action.payload.dynamicLayerId;
  });
};

/**
 * See `initializeLayers` action creator.
 *
 * Initialize layers opacity values and set initial layers to visible on the state.
 */
const initializeLayers = (previousState, action: ReturnType<typeof actions.initializeLayers>): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(INITIAL_STATE.layerConfigurations).forEach((layerId) => {
      const castedLayerId = layerId as LayerConfigurationsLayerIds;

      nextDraftState.layerConfigurations[castedLayerId] = {
        ...INITIAL_STATE.layerConfigurations[castedLayerId],
        isEnabled: previousState.layerConfigurations[castedLayerId].isEnabled,
      };
    });

    action.payload.visibleLayerIds.forEach((layerId) => {
      nextDraftState.layerConfigurations[layerId].isVisible = true;
      nextDraftState.layerConfigurations[layerId].isActive = true;
    });

    nextDraftState.mapIsReady = false;
  });
};

/**
 * See `initializeLayersOnStyleChange` action creator.
 *
 * Initialize layers opacity values and set initial layers to visible on the state.
 */
const initializeLayersOnStyleChange = (
  previousState,
  action: ReturnType<typeof actions.initializeLayersOnStyleChange>
): MapsState => {
  return produce(previousState, (nextDraftState: MapsState) => {
    Object.keys(INITIAL_STATE.layerConfigurations).forEach((layerId) => {
      const castedLayerId = layerId as LayerConfigurationsLayerIds;

      nextDraftState.layerConfigurations[castedLayerId] = {
        ...INITIAL_STATE.layerConfigurations[castedLayerId],
        isEnabled: previousState.layerConfigurations[castedLayerId].isEnabled,
        isActive: previousState.layerConfigurations[castedLayerId].isActive,
      };
    });

    action.payload.visibleLayerIds.forEach((layerId) => {
      nextDraftState.layerConfigurations[layerId].isVisible = true;
      nextDraftState.layerConfigurations[layerId].isActive = true;
    });

    nextDraftState.mapIsReady = false;
    nextDraftState.styleChanged = false;
  });
};

export default reducer;
