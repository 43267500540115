import { Map } from "mapbox-gl";
import { DynamicLayerGroupConfiguration } from "types/DynamicLayerGroupConfiguration";
import config from "views/sharedComponents/DynamicLayerSelection/dynamicLayersConfig";
import { DynamicLayerConfiguration, LayerConfiguration, MapsState } from "../../state/ui/shared/maps/reducers";
import { Tier } from "../../types/Tier";
import mapboxPresentationProperties from "../../views/utils/mapboxPresentationProperties";
import { paintDemographicDynamicLayerFill } from "./layerPaintersHelper";
import { MapStyleProperties } from "./mapStyleProperties";
import { DynamicLayerId, LayerId, LayerPaint, RawDynamicLayersFieldId } from "./mapStyleProperties/mapStyleProperties";
import staticLayersConfig from "../../views/sharedComponents/LayerSelection/config";

type BasicPaintTypes =
  | MapStyleProperties.LayerType.Fill
  | MapStyleProperties.LayerType.FillExtrusion
  | MapStyleProperties.LayerType.Line;

type CirclePaintTypes = MapStyleProperties.LayerType.Circle;
type SymbolPaintTypes = MapStyleProperties.LayerType.Symbol;

type LayerTypePaintPropertyIdMap = {
  [K in MapStyleProperties.LayerType]: Extract<MapStyleProperties.LayerType, K> extends BasicPaintTypes
    ? {
        opacityId: string;
      }
    : Extract<MapStyleProperties.LayerType, K> extends CirclePaintTypes
    ? {
        opacityId: string;
        strokeOpacityId: string;
      }
    : Extract<MapStyleProperties.LayerType, K> extends SymbolPaintTypes
    ? {
        textOpacityId: string;
        iconOpacityId: string;
      }
    : never;
};

const LAYER_TYPE_PAINT_PROPERTY_ID_MAP: LayerTypePaintPropertyIdMap = {
  [MapStyleProperties.LayerType.Circle]: {
    opacityId: "circle-opacity",
    strokeOpacityId: "circle-stroke-opacity",
  },
  [MapStyleProperties.LayerType.Fill]: { opacityId: "fill-opacity" },
  [MapStyleProperties.LayerType.FillExtrusion]: {
    opacityId: "fill-extrusion-opacity",
  },
  [MapStyleProperties.LayerType.Line]: { opacityId: "line-opacity" },
  [MapStyleProperties.LayerType.Symbol]: {
    textOpacityId: "text-opacity",
    iconOpacityId: "icon-opacity",
  },
};

const SEA_LEVEL_FIELDS = [
  RawDynamicLayersFieldId.SeaLevelRise_0,
  RawDynamicLayersFieldId.SeaLevelRise_1,
  RawDynamicLayersFieldId.SeaLevelRise_2,
  RawDynamicLayersFieldId.SeaLevelRise_3,
  RawDynamicLayersFieldId.SeaLevelRise_4,
  RawDynamicLayersFieldId.SeaLevelRise_5,
  RawDynamicLayersFieldId.SeaLevelRise_6,
  RawDynamicLayersFieldId.SeaLevelRise_7,
  RawDynamicLayersFieldId.SeaLevelRise_8,
  RawDynamicLayersFieldId.SeaLevelRise_9,
  RawDynamicLayersFieldId.SeaLevelRise_10,
];

/**
 * Initializes the map layers according to the layer configurations and initial visible layers parameters.
 *
 * @param map
 * @param layerConfigurations
 * @param initialVisibleLayers
 */
const initializeMapLayers = (
  map: Map,
  layerConfigurations: LayerConfigurations,
  initialVisibleLayers: Array<LayerConfigurationsLayerIds>
): void => {
  Object.keys(layerConfigurations).forEach((layerId) => {
    if (!map.getLayer(layerId)) return;

    const castedLayerId = layerId as LayerConfigurationsLayerIds;
    const layerConfiguration = layerConfigurations[castedLayerId];

    // Set opacity to `0`.
    setLayerOpacity(map, castedLayerId, layerConfiguration.layerType, {
      iconOpacity: 0,
      opacity: 0,
      textOpacity: 0,
      strokeOpacity: 0,
    });
    const layoutProperty = map.getLayoutProperty(castedLayerId, "visibility");

    if (layerConfiguration.isEnabled) {
      // Set Visible Layers.
      if (
        (initialVisibleLayers.includes(castedLayerId) || layerConfiguration.isVisible) &&
        (layoutProperty === "none" || layoutProperty === undefined)
      ) {
        map.setLayoutProperty(castedLayerId, "visibility", "visible");
      }
    }
  });
};

/**
 * Initializes the dynamic map layers according to the layer configurations and initial visible layers parameters.
 *
 * @param map
 * @param dynamicLayerConfigurations
 * @param initialVisibleLayers
 */
const initializeDynamicMapLayers = (
  map: Map,
  dynamicLayerConfigurations: DynamicLayerConfigurations,
  initialVisibleLayers: Array<DynamicLayerConfigurationsLayerIds>
): void => {
  Object.keys(dynamicLayerConfigurations).forEach((layerId) => {
    if (!map.getLayer(layerId)) return;

    const castedLayerId = layerId as DynamicLayerConfigurationsLayerIds;
    const dynamicLayerConfiguration = dynamicLayerConfigurations[castedLayerId];

    // Set opacity to `0`.
    setLayerOpacity(map, castedLayerId, dynamicLayerConfiguration.layerType, {
      iconOpacity: 0,
      opacity: 0,
      textOpacity: 0,
    });
    const layoutProperty = map.getLayoutProperty(castedLayerId, "visibility");

    if (dynamicLayerConfiguration.isEnabled) {
      // Set Visible Layers.
      if (
        (initialVisibleLayers.includes(castedLayerId) || dynamicLayerConfiguration.isVisible) &&
        (layoutProperty === "none" || layoutProperty === undefined)
      ) {
        map.setLayoutProperty(castedLayerId, "visibility", "visible");
      }
    }
  });
};

/**
 * Returns opacity object containing the opacity depending on the layer type.
 *
 * @param map - Mapbox map object.
 * @param layerId - Layer id to get paint property from.
 * @param layerType - Layer type.
 *
 * @returns opacityObject containing the opacity depending on the layer type.
 */
const getLayerOpacity = (
  map: Map,
  layerId: MapStyleProperties.LayerId,
  layerType: MapStyleProperties.LayerType
): LayerPaint => {
  try {
    switch (layerType) {
      case MapStyleProperties.LayerType.Circle:
        const opacity = map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId);
        const strokeOpacity = map.getPaintProperty(
          layerId,
          LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].strokeOpacityId
        );

        return { opacity, strokeOpacity };
      case MapStyleProperties.LayerType.Fill:
        return {
          opacity: map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId),
        };
      case MapStyleProperties.LayerType.FillExtrusion:
        return {
          opacity: map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId),
        };
      case MapStyleProperties.LayerType.Line:
        return {
          opacity: map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId),
        };
      case MapStyleProperties.LayerType.Symbol:
        let textOpacity, iconOpacity;
        try {
          textOpacity = map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].textOpacityId);
        } catch (error) {}

        try {
          iconOpacity = map.getPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].iconOpacityId);
        } catch (error) {}

        return {
          textOpacity: textOpacity,
          iconOpacity: iconOpacity,
        };
    }
  } catch (error) {
    return { opacity: 0 };
  }
};

/**
 * Sets the paint property of the layer id depending on the type of layer and values passed in the
 * `opacityObject`.
 *
 * @param map - Mapbox map object.
 * @param layerId - Layer id to get paint property from.
 * @param layerType - Layer type.
 * @param opacityObject - opacity object with opacity values.
 */
const setLayerOpacity = (
  map: Map,
  layerId: MapStyleProperties.LayerId,
  layerType: MapStyleProperties.LayerType,
  opacityObject: LayerPaint
): void => {
  try {
    switch (layerType) {
      case MapStyleProperties.LayerType.Circle:
        if (opacityObject.opacity !== undefined)
          map.setPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId, opacityObject.opacity);
        if (opacityObject.strokeOpacity !== undefined)
          map.setPaintProperty(
            layerId,
            LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].strokeOpacityId,
            opacityObject.strokeOpacity
          );
        break;
      case MapStyleProperties.LayerType.Fill:
        map.setPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId, opacityObject.opacity);
        break;
      case MapStyleProperties.LayerType.FillExtrusion:
        map.setPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId, opacityObject.opacity);
        break;
      case MapStyleProperties.LayerType.Line:
        map.setPaintProperty(layerId, LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].opacityId, opacityObject.opacity);
        break;
      case MapStyleProperties.LayerType.Symbol:
        if (opacityObject.iconOpacity !== undefined)
          map.setPaintProperty(
            layerId,
            LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].iconOpacityId,
            opacityObject.iconOpacity
          );
        if (opacityObject.textOpacity !== undefined)
          map.setPaintProperty(
            layerId,
            LAYER_TYPE_PAINT_PROPERTY_ID_MAP[layerType].textOpacityId,
            opacityObject.textOpacity
          );
        break;
    }
  } catch (error) {}
};

export type LayerConfigurations = {
  [MapStyleProperties.LayerId.BuildableAreaRatio]: LayerConfiguration;
  [MapStyleProperties.LayerId.HistoricBuildingsPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.HistoricBuildingsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.BusinessesPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.BusinessesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.CapRates]: LayerConfiguration;
  [MapStyleProperties.LayerId.CensusTractBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.CensusTractSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.EducationFacilitiesPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.EducationFacilitiesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HospitalsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HospitalsPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.HotelAverageDailyRateFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.HotelAverageDailyRateBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.HotelAverageDailyRateSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HotelsPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.HotelsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.IndustrialRateFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.IndustrialRateBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.IndustrialRateSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilyBuildingPermits2019Fill]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilyBuildingPermitsChange20182019Fill]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilyRentFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilyRentBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilyRentSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.OfficeRateFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.OfficeRateBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.OfficeRateSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.ReitsProjectsPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.ReitsProjectsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.RentPerIncomeFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.RetailRateFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.RetailRateBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.RetailRateSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.ResidentialBuildingPermits2019Fill]: LayerConfiguration;
  [MapStyleProperties.LayerId.ResidentialBuildingPermitsChange20182019Fill]: LayerConfiguration;
  [MapStyleProperties.LayerId.ResidentialVacancyFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.RoadTypeBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.SmartSearchResultsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.TrafficDailyAverage2019Border]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaBuildings]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaBuildingsQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaCountiesBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaCountiesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaDemographicsQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaGraphsQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaOpportunityZoneFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaParcelsBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaParcelsQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaParcelsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaParcelsZoningQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaZoningBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaZoningSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.ZoningDensity]: LayerConfiguration;
  [MapStyleProperties.LayerId.FederalOwnedLandsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.FederalOwnedLandsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.ForestsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.ForestsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HazardousSitesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.HazardousSitesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HazardousSitesPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.WatershedsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.WatershedsSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HistoricSitesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.HistoricSitesPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.ParksFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.IndianReservationsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.WetlandsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.WetlandsBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.BayAreaHousingSitesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.BayAreaHousingSitesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.BayAreaHousingSitesBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventoryPoint]: LayerConfiguration;
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventorySymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.QualifiedCensusTractsFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.QualifiedCensusTractsBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.CityBoundariesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.CityBoundariesSymbol]: LayerConfiguration;
  [MapStyleProperties.LayerId.CityBoundariesBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.TransitOrientedCommunityFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.SeaLevelRiseQuery]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaCountiesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.UsaCountiesBorder]: LayerConfiguration;

  [MapStyleProperties.LayerId.IndustrialSitesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.IndustrialSitesBorder]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilySitesFill]: LayerConfiguration;
  [MapStyleProperties.LayerId.MultifamilySitesBorder]: LayerConfiguration;
};
export type DynamicLayerConfigurations = {
  [MapStyleProperties.LayerId.DynamicPopulationFill_2010]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2010]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2010]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2011]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2011]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2011]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2012]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2012]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2012]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationFill_2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2022]: DynamicLayerConfiguration;

  [MapStyleProperties.LayerId.DynamicMedianRentFill_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022]: DynamicLayerConfiguration;

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022]: DynamicLayerConfiguration;

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022]: DynamicLayerConfiguration;
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022]: DynamicLayerConfiguration;

  [MapStyleProperties.LayerId.SeaLevelRiseQuery]: LayerConfiguration;
};

export type LayerConfigurationsLayerIds = keyof LayerConfigurations;
export type DynamicLayerConfigurationsLayerIds = keyof DynamicLayerConfigurations;

/**
 * Returns initial layer configuration values.
 */
const getInitialConfigurationValues = (): MapsState["layerConfigurations"] => ({
  [MapStyleProperties.LayerId.BuildableAreaRatio]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HistoricBuildingsPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HistoricBuildingsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.BusinessesPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.BusinessesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.CapRates]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.34, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.CensusTractBorder]: {
    minimumTier: Tier.None,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.CensusTractSymbol]: {
    minimumTier: Tier.None,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.EducationFacilitiesPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.EducationFacilitiesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.HospitalsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
      iconOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HospitalsPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HotelAverageDailyRateBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // See Component `HotelAverageDailyRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.HotelAverageDailyRateFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // See Component `HotelAverageDailyRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.HotelAverageDailyRateSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // See Component `HotelAverageDailyRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.HotelsPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HotelsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.IndustrialRateBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // See Component `IndustrialRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.IndustrialRateFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // See Component `IndustrialRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.IndustrialRateSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // See Component `IndustrialRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.MultifamilyBuildingPermits2019Fill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.MultifamilyBuildingPermitsChange20182019Fill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.MultifamilyRentBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // See Component `MultifamilyRentFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.MultifamilyRentFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // See Component `MultifamilyRentFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.MultifamilyRentSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // See Component `MultifamilyRentFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.OfficeRateBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // See Component `OfficeRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.OfficeRateFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // See Component `OfficeRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.OfficeRateSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // See Component `OfficeRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.ReitsProjectsPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 1, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.ReitsProjectsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.RentPerIncomeFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.4, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.ResidentialBuildingPermitsChange20182019Fill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.ResidentialBuildingPermits2019Fill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.ResidentialVacancyFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.4, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.RetailRateBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // See Component `RetailRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.RetailRateFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.5, // See Component `RetailRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.RetailRateSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // See Component `RetailRateFeatureLayer`
    },
  },
  [MapStyleProperties.LayerId.RoadTypeBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.SmartSearchResultsFill]: {
    minimumTier: Tier.None,
    isVisible: true,
    isActive: true,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // see `mapboxPresentationProperties[searchResultsFillPaint]`
    },
  },
  [MapStyleProperties.LayerId.TrafficDailyAverage2019Border]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaBuildings]: {
    minimumTier: Tier.None,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.FillExtrusion,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaBuildingsQuery]: {
    minimumTier: Tier.Free,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.FillExtrusion,
    paint: {
      opacity: 0, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaCountiesBorder]: {
    minimumTier: Tier.Pro,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaCountiesFill]: {
    minimumTier: Tier.Pro,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaDemographicsQuery]: {
    minimumTier: Tier.Free,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaGraphsQuery]: {
    minimumTier: Tier.None,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.6, // see `mapboxPresentationProperties["parcelSelectFillPaint"]`
    },
  },
  [MapStyleProperties.LayerId.UsaOpportunityZoneFill]: {
    minimumTier: Tier.None,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaParcelsBorder]: {
    minimumTier: Tier.None,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaParcelsQuery]: {
    minimumTier: Tier.None,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaParcelsSymbol]: {
    minimumTier: Tier.None,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      iconOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaParcelsZoningQuery]: {
    minimumTier: Tier.None,
    isVisible: false,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaZoningBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.UsaZoningSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1, // From AWS style
    },
  },
  [MapStyleProperties.LayerId.ZoningDensity]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.FederalOwnedLandsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.FederalOwnedLandsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.NaturalAndMarineProtectedLandsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.ForestsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.ForestsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HazardousSitesFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HazardousSitesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HazardousSitesPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.WatershedsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.WatershedsSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HistoricSitesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.HistoricSitesPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.ParksFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.IndianReservationsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.WetlandsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.WetlandsBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.BayAreaHousingSitesFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.BayAreaHousingSitesBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.BayAreaHousingSitesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventoryPoint]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Circle,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.LIHTC_ExistingPropertyInventorySymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.DifficultDevelopmentAreasBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.QualifiedCensusTractsFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.QualifiedCensusTractsBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.CityBoundariesFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.CityBoundariesSymbol]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    paint: {
      textOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.CityBoundariesBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.TransitOrientedCommunityFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65, // From AWS style
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.SeaLevelRiseQuery]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65,
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.IndustrialSitesFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65,
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.IndustrialSitesBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
  [MapStyleProperties.LayerId.MultifamilySitesFill]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    paint: {
      opacity: 0.65,
      strokeOpacity: 1,
    },
  },
  [MapStyleProperties.LayerId.MultifamilySitesBorder]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    paint: {
      opacity: 1,
    },
  },
});

/**
 * Returns initial dynamic layer configuration values.
 */
const getInitialDynamicLayersConfigurationValues = (): MapsState["dynamicLayerConfigurations"] => ({
  [MapStyleProperties.LayerId.DynamicPopulationFill_2010]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2010,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2010,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2010]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2010,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2010)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2010]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2010,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2011]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2011,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2011,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2011]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2011,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2011)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2011]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2011,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2012]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2012,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2012,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2012]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2012,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2012)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2012]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2012,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2013,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2013,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2013,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2013)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2013,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2014,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2014,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2014,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2014)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2014,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2015,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2015,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2015,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2015)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2015,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2016,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2016,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2016,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2016)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2016,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2017,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2017,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2017,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2017)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2017,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2018,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2018,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2018,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2018)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2018,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2019,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2019,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2019,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2019)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2019,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2020,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2020,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2020,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2020)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2020,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2021,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2021,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2021,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2021)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2021,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationFill_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.Population2022,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.Population2022,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationBorder_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.Population2022,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.Population2022)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicPopulationSymbol_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.Population2022,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2013,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2013,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2013,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2013)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2013,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2014,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2014,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2014,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2014)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2014,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2015,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2015,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2015,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2015)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2015,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2016,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2016,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2016,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2016)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2016,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2017,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2017,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2017,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2017)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2017,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2018,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2018,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2018,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2018)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2018,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2019,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2019,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2019,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2019)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2019,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2020,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2020,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2020,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2020)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2020,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2021,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2021,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2021,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2021)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2021,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentFill_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2022,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianGrossRentTotal2022,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentBorder_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2022,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianGrossRentTotal2022)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianRentSymbol_2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianGrossRentTotal2022,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2013)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2014)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2015)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2016)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2017)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2018)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2019)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2020)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2021)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioFill2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioBorder2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.EmploymentPerPopRatio2022)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicEmploymentPerPopRatioSymbol2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2013,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2013,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2013,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2013)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2013]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2013,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2014,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2014,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2014,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2014)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2014]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2014,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2015,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2015,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2015,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2015)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2015]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2015,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2016,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2016,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2016,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2016)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2016]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2016,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2017,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2017,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2017,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2017)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2017]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2017,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2018,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2018,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2018,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2018)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2018]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2018,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2019,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2019,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2019,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2019)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2019]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2019,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2020,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2020,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2020,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2020)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2020]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2020,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2021,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2021,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2021,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2021)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2021]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2021,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.DynamicMedianIncomeFill2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2022,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.MedianHouseholdIncome2022,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income
      )["fill-opacity"],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeBorder2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Line,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2022,
    paint: {
      opacity: mapboxPresentationProperties.demographicsLinePaint(RawDynamicLayersFieldId.MedianHouseholdIncome2022)[
        "line-opacity"
      ],
    },
  },
  [MapStyleProperties.LayerId.DynamicMedianIncomeSymbol2022]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Symbol,
    fieldToQuery: RawDynamicLayersFieldId.MedianHouseholdIncome2022,
    paint: {
      textOpacity: mapboxPresentationProperties.rentLayersSymbolPaint["text-opacity"],
    },
  },

  [MapStyleProperties.LayerId.SeaLevelRiseQuery]: {
    minimumTier: Tier.Pro,
    isVisible: true,
    isActive: false,
    isEnabled: true,
    isAvailable: true,
    layerType: MapStyleProperties.LayerType.Fill,
    fieldToQuery: RawDynamicLayersFieldId.SeaLevelRise_1,
    paint: {
      opacity: paintDemographicDynamicLayerFill(
        RawDynamicLayersFieldId.SeaLevelRise_0,
        MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise
      )["fill-opacity"],
    },
  },
});

/**
 * Returns initial Selected Individual Dynamic Layers configuration values.
 */
const getInitialSelectedIndividualDynamicLayers = (): MapsState["selectedIndividualDynamicLayers"] => ({
  [MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population]: null,
  [MapStyleProperties.DynamicLayerId.Dynamic_Layer_MedianRent]: null,
  [MapStyleProperties.DynamicLayerId.Dynamic_Layer_Employment_Per_Population]: null,
  [MapStyleProperties.DynamicLayerId.Dynamic_Layer_Household_Income]: null,
  [MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise]: null,
});

/**
 * Returns true if any of the layers are currently available in the viewport.
 */
const shouldDisplayLegend = (layerConfigurations: LayerConfigurations): boolean => {
  return Object.keys(layerConfigurations).some(
    (layerId) => layerConfigurations[layerId].isAvailable && !layerId.includes("query")
  );
};

/**
 * Returns boolean value determining if given tier and layer ids are for demo user.
 */
const disableForDemo = (
  userTier: Tier | undefined,
  layersId: Array<LayerConfigurationsLayerIds>,
  layerConfigurations: LayerConfigurations
) => {
  return userTier === Tier.None && layersId.some((layerId) => layerConfigurations[layerId].minimumTier > userTier);
};

/**
 * Returns boolean value determining if given tier and layer ids are for demo user.
 */
const disableDynamicLayersForDemo = (
  userTier: Tier | undefined,
  layersId: Array<LayerId>,
  dynamicLayerConfigurations: DynamicLayerConfigurations
) => {
  return (
    userTier === Tier.None && layersId.some((layerId) => dynamicLayerConfigurations[layerId].minimumTier > userTier)
  );
};

/**
 * @param config
 * @param fieldToQuery
 * @returns all the layer Ids with a specific fieldToQuery.
 */
const getDynamicLayersByField = (
  config: DynamicLayerGroupConfiguration,
  fieldToQuery: MapStyleProperties.RawDynamicLayersFieldId
) => {
  const layerIds: LayerId[] = [];

  const layersConfigurationsIds = Object.entries(config)
    .filter(([layerId, layerGroup]) => layerGroup.fieldToQuery === fieldToQuery)
    .map(([layerId, layerGroup]) => layerGroup.layers)[0];

  if (layersConfigurationsIds && layersConfigurationsIds.length > 0) {
    layersConfigurationsIds.forEach((layerConfigId) => {
      const layerId: LayerId = layerConfigId;
      layerIds.push(layerId);
    });
  }

  return layerIds;
};

/**
 * Check if there are static layers inside a layer Sector (Map Layers Tabs).
 * @param layerSector
 * @returns true if at least there is one layer in that sector.
 */
const containsAvaliableStaticLayers = (layerSector) => {
  return Object.values(staticLayersConfig).some((layerGroup) => layerGroup.layerSector === layerSector);
};

/**
 * Check if there are dynamic layers inside a layer Sector (Map Layers Tabs).
 * @param layerSector
 * @returns true if at least there is one layer in that sector.
 */
const sectorContainsDynamicLayers = (layerSector) => {
  return Object.values(config).some((layerGroup) => layerGroup.layerSector === layerSector);
};

/**
 * Get the dynamic layers inside a layer Sector (Map Layers Tabs).
 * @param layerSector
 */
const getAvaliableLayersBySector = (layerSector) => {
  return Object.entries(config).filter(([layerName, layerGroup]) => layerGroup.layerSector === layerSector);
};

/**
 * Check if there is data for the specified features.
 */
const isDataAvailable = (field: RawDynamicLayersFieldId, features: any) => {
  return features.some((feature) => feature && feature.properties![field] !== undefined);
};

/**
 * Check if there is data for the specified features.
 */
const isSeaLevelDataAvailable = (features: any) => {
  let dataAvailable = false;

  for (const seaLevelRiseField of SEA_LEVEL_FIELDS) {
    if (isDataAvailable(seaLevelRiseField, features)) {
      dataAvailable = true;
      break;
    }
  }
  return dataAvailable;
};

/**
 * Check if there is availability inside a Dynamic Layer Group.
 */
const areLayersAvailableInGroup = (
  layerConfigurations: DynamicLayerConfigurations,
  dynamicLayerGroup: DynamicLayerId
) => {
  if (layerConfigurations && dynamicLayerGroup) {
    const layers = config[dynamicLayerGroup].layers;
    return layers.some((layerId) => layerConfigurations[layerId].isAvailable);
  }
  return null;
};

export default {
  initializeMapLayers,
  initializeDynamicMapLayers,
  getLayerOpacity,
  setLayerOpacity,
  getInitialConfigurationValues,
  getInitialDynamicLayersConfigurationValues,
  shouldDisplayLegend,
  disableForDemo,
  disableDynamicLayersForDemo,
  getDynamicLayersByField,
  sectorContainsDynamicLayers,
  getAvaliableLayersBySector,
  getInitialSelectedIndividualDynamicLayers,
  isDataAvailable,
  areLayersAvailableInGroup,
  containsAvaliableStaticLayers,
  isSeaLevelDataAvailable,
};
