import { createSelector } from "@reduxjs/toolkit";
import { PdfState } from "./reducers";
import Pdf from "types/Pdf";
import { GraphId } from "types/Graph";
import { ReactMapboxGlCamera } from "types/ReactMapboxGlCamera";

/**
 * Select the whole pdf object.
 */
const getPdfState = (state): PdfState => {
  return state.pdf;
}

/**
 * Select the isActive value.
 */
const getIsActive = (state): boolean => {
  const pdf = getPdfState(state);
  return pdf.isActive;
}

/**
 * Select the mapImages object.
 */
const getMapImages = (state) => {
  const pdf = getPdfState(state);
  return pdf.mapImages;
}

/**
 * Select the map image for the given image type.
 */
const getMapImage = (state, imageType: Pdf.ImageType): string | null => {
  let mapImages = getMapImages(state);
  return (mapImages && mapImages[imageType]) || null;
}

/**
 * Select the camera object.
 */
const getCamera = (state): ReactMapboxGlCamera | null => {
  const pdf = getPdfState(state);
  return pdf.camera || null;
}

/**
 * Select the cover page image for the given type.
 */
const getCoverPageImage = (state, imageType: Pdf.ImageType): Pdf.Image | null => {
  const pdf = getPdfState(state);
  return (pdf.coverPageImages && pdf.coverPageImages[imageType]) || null;
}

/**
 * Select the coverPageImages object.
 */
const getCoverPageImages = (state) => {
  const pdf = getPdfState(state);
  return pdf.coverPageImages;
}

/**
 * Select the title value.
 */
const getTitle = (state) => {
  const pdf = getPdfState(state);
  return pdf.title;
}

/**
 * Select the summary value.
 */
const getSummary = (state) => {
  const pdf = getPdfState(state);
  return pdf.summary;
}

/**
 * Select the fromContactDetails object.
 */
const getFromContactDetails = (state) => {
  const pdf = getPdfState(state);
  return pdf.fromContactDetails;
}

/**
 * Select the toContactDetails object.
 */
const getToContactDetails = (state) => {
  const pdf = getPdfState(state);
  return pdf.toContactDetails;
}

/**
 * Select the colorPalette object.
 */
const getColorPalette = (state) => {
  const pdf = getPdfState(state);
  return pdf.colorPalette;
}

/**
 * Select Document properties from pdf object for saving.
 */
const getPdfDocument = (state): Pdf.Document => {
  const pdf = getPdfState(state);

  return {
    colorPalette: pdf.colorPalette,
    fromContactDetails: pdf.fromContactDetails,
    toContactDetails: pdf.toContactDetails,
    summary: pdf.summary,
    title: pdf.title,
    address: pdf.address,
  }
}

/**
 * Select Address object.
 */
const getAddress = (state): Pdf.Address => {
  const pdf = getPdfState(state);
  return pdf.address;
}

/**
 * Select the graph data sources object.
 */
const getGraphDataSources = createSelector(
  getPdfState,
  (pdfState) => pdfState.graphDataSources
)

/**
 * Select a boolean indicating whether graph data sources have already been initialized.
 */
const getGraphDataSourcesAreInitialized = createSelector(
  getGraphDataSources,
  (graphFeatures) => graphFeatures !== undefined
)

/**
 * Select an unsorted array of the graph data sources from the lookup table. If the data sources
 * are undefined, returns an empty list.
 */
const getGraphDataSourceList = createSelector(
  getGraphDataSources,
  (graphDataSources) => graphDataSources === undefined
    ? []
    : Object.values(graphDataSources)
)

/**
 * Select a boolean indicating whether there is any graph data available for the current
 * development.
 */
const getGraphDataIsAvailable = createSelector(
  getGraphDataSourceList,
  (graphDataSourceList) => graphDataSourceList.length > 0
)

/*
 * Select the lookup table of graph image object URLs.
 */
const getGraphImages = createSelector(
  getPdfState,
  (pdfState) => pdfState.graphImages
)

/**
 * Returns a selector scoped to the identified graph. The returned selector selects a boolean
 * indicating whether the identified graph exists.
 */
const graphImageExistsSelectorFor = (graphId: GraphId) => createSelector(
  getGraphImages,
  (graphImages) => Boolean(graphImages && graphImages[graphId])
)

/**
 * Select a boolean indicating whether the map and cover images for the PDF are available to embed.
 */
const getMapAndCoverImagesAreReady = createSelector(
  getMapImages,
  getCoverPageImages,
  (mapImages, coverPageImages) => {
    if (!mapImages) return false;

    // We can render the PDF as soon as the required map screenshots are available.
    // If a user-uploaded cover image is available, we don't need a screenshot for the cover.
    return coverPageImages[Pdf.ImageType.Cover]
      ? mapImages[Pdf.ImageType.Building] && mapImages[Pdf.ImageType.Location]
      : mapImages[Pdf.ImageType.Building] && mapImages[Pdf.ImageType.Location] && mapImages[Pdf.ImageType.Cover]
  }
)

/**
 * Select a boolean indicating whether the graph images for the PDF are available to embed.
 */
const getGraphImagesAreReady = createSelector(
  getGraphDataIsAvailable,
  getGraphImages,
  (graphDataIsAvailable, graphImages) => {
    // If there is no graph data available, there won't be any graph images to wait for.
    if (!graphDataIsAvailable) return true;
    return graphImages
      && graphImages[GraphId.Population]
      && graphImages[GraphId.MedianHouseholdIncome]
      && graphImages[GraphId.MedianGrossRent]
      && graphImages[GraphId.EmploymentPerPopulation]
  }

)

/**
 * Select a boolean indicating whether all the data needed to render the PDF is available.
 */
const getIsReady = createSelector(
  getMapAndCoverImagesAreReady,
  getGraphImagesAreReady,
  (mapAndCoverImagesAreReady, graphImagesAreReady) => mapAndCoverImagesAreReady && graphImagesAreReady
)

export default {
  getIsActive,
  getMapImages,
  getMapImage,
  getCamera,
  getTitle,
  getSummary,
  getFromContactDetails,
  getToContactDetails,
  getColorPalette,
  getCoverPageImages,
  getCoverPageImage,
  getPdfDocument,
  getAddress,
  getGraphDataSources,
  getGraphDataSourcesAreInitialized,
  getGraphDataSourceList,
  getGraphDataIsAvailable,
  getGraphImages,
  graphImageExistsSelectorFor,
  getIsReady,
}
