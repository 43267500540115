import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import { Type } from "../../../../types/Format/Format";
import valueFormatter from "../../../../utils/valueFormatter";
import SubscribeButton from "../SubscribeButton";
import BusySpinner from "../../BusySpinner";

const LIST_OF_FEATURES = [
  "Everything in Developer + ",
  "Zoning Breakdowns in 100 US cities",
  "Canvassing Tools",
  "Search by Total Buildable Area",
  "Search by Uses Allowed",
  "Search by Lot Size",
  "Export Search CSV",
  "",
  "US Parcel Layer (1000+ cities)",
  "US Zoning Layer (100+ cities)",
  "Demographic Data (US)",
  "Location Layers (US)",
  "Streets and Traffic Layers (US)",
  "",
  "Baseline Market Rates (US)",
  "Baseline Construction Costs (US)",
  "Baseline Hotel ADR (US)",
];

const mapStateToProps = (state) => {
  return {
    planPrices: subscriptionSelectors.getPlanPrices(state),
  };
};

interface OwnProps {
  planPeriodIsYearly: boolean;
  presentationOnly?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

class ProTierPanel extends React.PureComponent<Props, {}> {
  /**
   * Render the plan price.
   */
  renderPrice = () => {
    const { planPeriodIsYearly, planPrices } = this.props;
    const price = planPeriodIsYearly ? planPrices["standard-annual-001"] : planPrices["standard-monthly-001"];

    return (
      <div className="price">
        {price === undefined ? (
          <BusySpinner />
        ) : (
          <>
            {valueFormatter.format(price, { type: Type.Currency })}
            <div className="suffix">{planPeriodIsYearly ? "/yr" : "/mo"}</div>
          </>
        )}
      </div>
    );
  };

  /**
   * Render the subscribe button.
   */
  renderButton = () => {
    const { planPeriodIsYearly, presentationOnly } = this.props;
    const text = "Sign Up for PRO";

    return presentationOnly === true ? (
      <div className="no-button-wrapper"></div>
    ) : (
      this.renderButtonBox(text, planPeriodIsYearly)
    );
  };

  /**
   * Render the list of features.
   */
  renderListOfFeatures = () => {
    return (
      <div className="list-wrapper">
        {LIST_OF_FEATURES.map((feature, index) => (
          <div className={`list-item ${index === 0 ? "bold" : ""}`} key={`feature_${index}`}>
            {feature}
          </div>
        ))}
      </div>
    );
  };

  renderButtonBox = (text: string, planPeriodIsYearly) => {
    return (
      <div className="button-wrapper">
        <SubscribeButton
          text={text}
          paymentPlanId={planPeriodIsYearly ? Chargebee.PlanId.ProYearly : Chargebee.PlanId.ProMonthly}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="component--tier-panel">
        <div className="plan-name">PRO by City</div>
        <p className="tier-sub-description">City pricing for a limited time.</p>
        {this.renderPrice()}
        {this.renderButton()}
        {this.renderListOfFeatures()}
        <div className="bottom" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ProTierPanel);
