import React from "react";
import { connect } from "react-redux";
import Pdf from "types/Pdf";
import { pdfSelectors } from "state/pdf";
import BuildingLayer from "views/pages/ExplorerPage/Map/BuildingLayer";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import Map from "../sharedComponents/Map";
import { ReactMapboxGlCamera } from "types/ReactMapboxGlCamera";

const CAMERA_DEFAULT_PITCH: [number] = [0];
const CAMERA_DEFAULT_BEARING: [number] = [0];
const CAMERA_DEFAULT_CENTER: [number, number] = [40, 30];
const CAMERA_DEFAULT_ZOOM: [number] = [16.1];

const mapStateToProps = (state) => {
  return {
    camera: pdfSelectors.getCamera(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class MapBuilding extends React.PureComponent<Props, {}> {
  render() {
    const cameraProps: ReactMapboxGlCamera = this.props.camera
      ? this.props.camera
      : { zoom: CAMERA_DEFAULT_ZOOM, pitch: CAMERA_DEFAULT_PITCH, bearing: CAMERA_DEFAULT_BEARING, center: CAMERA_DEFAULT_CENTER };
    return (
      <Map pdfImageType={Pdf.ImageType.Building} camera={cameraProps} mapStyleUrl={MapStyleProperties.StyleUrl.Streets}>
        <BuildingLayer />
      </Map>
    );
  }
}

export default connect(mapStateToProps)(MapBuilding);
