import React from "react";
import AccountInformation from "./AccountInformation";
import PaymentMethod from "./PaymentMethod";
import MyTeam from "./MyTeam";
import WithPanelHeader from "../../../sharedComponents/WithPanelHeader";
import { Tier } from "types/Tier";

interface OwnProps {
  tier: Tier;
}

class AccountSettings extends React.PureComponent<OwnProps, {}> {
  render() {
    const { tier } = this.props;
    const isDeveloperTier = tier === Tier.Developer;
    return (
      <WithPanelHeader title="Account Settings" hideControls>
        <div className="component--account-settings">
          <AccountInformation />
          <PaymentMethod />
          {!isDeveloperTier && <MyTeam />}
        </div>
      </WithPanelHeader>
    );
  }
}

export default AccountSettings;
