// PRO USERS
export enum StyleUrl {
  // Production
  // Streets = "https://styles.deepblocks.com/street-style.json",
  // Satellite = "https://styles.deepblocks.com/satellite-style.json",

  // Development
  // Streets = "https://styles-dev.deepblocks.com/dev-street-style.json",
  // Satellite = "https://styles-dev.deepblocks.com/dev-satellite-style.json",

  // Staging
  // Streets = "https://styles-dev.deepblocks.com/staging-street-style.json",
  // Satellite = "https://styles-dev.deepblocks.com/staging-satellite-style.json",

  // Exclusive
  Streets = "https://styles.deepblocks.com/x-street-style.json",
  Satellite = "https://styles.deepblocks.com/x-satellite-style.json",
}

export enum SetbackMode {
  // Production
  // SetbackMapUrl = "https://styles.deepblocks.com/setbacks-style.json", // v3

  // Development
  // SetbackMapUrl = "https://styles-dev.deepblocks.com/dev-setbacks-style.json", // v3

  // Staging
  // SetbackMapUrl = "https://styles-dev.deepblocks.com/staging-setbacks-style.json", // v3

  // Exclusive
  SetbackMapUrl = "https://styles.deepblocks.com/x-setbacks-style.json", // v3
}

// DEVELOPER USERS
export enum DeveloperTierStyleUrl {
  // Production
  // DeveloperTierStreets = "https://developer-styles.deepblocks.com/developer-street-style.json",
  // DeveloperTierSatellite = "https://developer-styles.deepblocks.com/developer-satellite-style.json",

  // Development
  // DeveloperTierStreets = "https://developer-dev-styles.deepblocks.com/developer-street-style.json",
  // DeveloperTierSatellite = "https://developer-dev-styles.deepblocks.com/developer-satellite-style.json",

  // Staging
  DeveloperTierStreets = "https://developer-dev-styles.deepblocks.com/developer-street-style.json",
  DeveloperTierSatellite = "https://developer-dev-styles.deepblocks.com/developer-satellite-style.json",
}

export enum DeveloperTierSetbackModeMap {
  SetbackMapUrl = "https://developer-dev-styles.deepblocks.com/developer-setbacks-style.json",
}

export enum BaseUrlsForRequest {
  BaseMapboxURL = "https://api.mapbox.com",
}

export const OVERLAYS_STORE_CONFIG_ENDPOINT = "https://overlays.deepblocks.xyz/overlays-store-configurations.json";

export const OVERLAYS_VISUAL_CONFIG_ENDPOINT = "https://overlays.deepblocks.xyz/overlays-visual-configurations.json";

export const OVERLAYS_MEDIA_ENDPOINT = "https://overlays.deepblocks.xyz/media/";

export enum SourceId {
  Composite = "composite",
  Demogprahics = "demographics",
  ParcelsZoningQuery = "parcels_with_zoning",
  SeaLevelRise = "sea_level_rise",
  ExistingBuildingArea = "parcels_with_existing_buildings_area",
}

export enum SourceLayerId {
  UsaDemographics = "usa_demographics",
  ParcelsWithZoningQuery = "usa_parcels_with_zoning",
  SeaLevelRise = "sea-level-rise-source-id",
  ExistingBuildingArea = "parcels_with_existing_building_area",
}

export const SOURCE_ID_TO_URL_MAP = {
  [SourceId.ParcelsZoningQuery]: [
    // "https://tile-api-1.deepblocks.com/data/parcels_with_zoning/{z}/{x}/{y}.pbf", //production
    // "https://tile-api-2.deepblocks.com/data/parcels_with_zoning/{z}/{x}/{y}.pbf", //production
    // "https://tile-api-3.deepblocks.com/data/parcels_with_zoning/{z}/{x}/{y}.pbf", //production
    "https://tile-api-x-1.deepblocks.com/data/parcels_with_zoning/{z}/{x}/{y}.pbf", //exclusive
  ],
  [SourceId.SeaLevelRise]: [
    "https://tile-api-x-1.deepblocks.xyz/data/sea_level_rise/{z}/{x}/{y}.pbf", // exclusive
    // "https://tile-api-1.deepblocks.xyz/data/sea_level_rise/{z}/{x}/{y}.pbf", //production
    // "https://tile-api-2.deepblocks.xyz/data/sea_level_rise/{z}/{x}/{y}.pbf", //production
    // "https://tile-api-3.deepblocks.xyz/data/sea_level_rise/{z}/{x}/{y}.pbf", //production
  ],
  [SourceId.ExistingBuildingArea]: [
    // "https://tile-api-dev-1.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // development
    // "https://tile-api-staging-1.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // staging
    // "https://tile-api-1.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // production
    // "https://tile-api-2.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // production
    // "https://tile-api-3.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // production
    "https://tile-api-x-1.deepblocks.com/data/parcels_with_existing_buildings_area/{z}/{x}/{y}.pbf", // exclusive
  ],
};

export enum MapResourceType {
  TileServer = "Tile",
}

export enum SourceType {
  Vector = "vector",
}

export enum DynamicLayers {
  DemographicSourceId = "graphs",
  DemographicSourceLayer = "graphs",
  SeaLevelRiseSourceId = "sea_level_rise",
  SeaLevelRiseSourceLayer = "sea_level_rise",
}

export enum LayerId {
  // Layers from Mapbox
  BuildableAreaRatio = "buildable-area-ratio",
  HistoricBuildingsSymbol = "historic-buildings-symbol",
  HistoricBuildingsPoint = "historic-buildings-point",
  BusinessesSymbol = "businesses-symbol",
  BusinessesPoint = "businesses-point",
  CapRates = "cap-rates",
  CensusTractBorder = "census-tract-border",
  CensusTractSymbol = "census-tract-symbol",
  DrawnParcels = "drawn-parcels",
  DrawnParcelsBorder = "drawn-parcels-border",
  EducationFacilitiesPoint = "education-facilities-point",
  EducationFacilitiesSymbol = "education-facilities-symbol",
  GrossMedianRent = "usa-gross-median-rent",
  HospitalsSymbol = "hospitals-symbol",
  HospitalsPoint = "hospitals-point",
  HotelsPoint = "hotels-point",
  HotelsSymbol = "hotels-symbol",
  MultifamilyBuildingPermits2019Fill = "multifamily-building-permits-2019-fill",
  MultifamilyBuildingPermitsChange20182019Fill = "multifamily-building-permits-change-2018-2019-fill",
  PopulationDensity = "usa-population-density",
  ReitsProjectsPoint = "reits-projects-point",
  ReitsProjectsSymbol = "reits-projects-symbol",
  RentPerIncomeFill = "rent-per-income-fill",
  ResidentialBuildingPermits2019Fill = "residential-building-permits-2019-fill",
  ResidentialBuildingPermitsChange20182019Fill = "residential-building-permits-change-2018-2019-fill",
  ResidentialVacancyFill = "residential-vacancy-fill",
  RoadTypeBorder = "road-type-border",
  SetbackBackground = "setback-background",
  TrafficDailyAverage2019Border = "traffic-daily-average-2019-border",
  UsaBuildings = "usa-buildings",
  UsaBuildingsQuery = "usa-buildings-query",
  UsaCountiesFill = "usa-counties-fill",
  UsaCountiesBorder = "usa-counties-border",
  UsaDemographicsQuery = "usa-demographics-query",
  UsaGraphsQuery = "usa-graphs-query",
  UsaOpportunityZoneFill = "usa-opportunity-zone-fill",
  UsaParcelsBorder = "usa-parcels-border",
  UsaParcelsQuery = "usa-parcels-query",
  UsaParcelsSymbol = "usa-parcels-symbol",
  UsaParcelsZoningQuery = "usa-parcels-zoning-query",
  UsaZoningBorder = "usa-zoning-border",
  UsaZoningSymbol = "usa-zoning-symbol",
  ZoningDensity = "zoning-density",
  SeaLevelRiseQuery = "sea-level-rise-query",

  // Overlays Master Layer Query
  UsaOverlaysQuery = "overlays_query",

  //USDA Layers
  FederalOwnedLandsFill = "federal-owned-lands-fill",
  FederalOwnedLandsSymbol = "federal-owned-lands-symbol",
  NaturalAndMarineProtectedLandsFill = "natural-and-marine-protected-lands-fill",
  NaturalAndMarineProtectedLandsSymbol = "natural-and-marine-protected-lands-symbol",
  ForestsFill = "forests-fill",
  ForestsSymbol = "forests-symbol",
  HazardousSitesFill = "hazardous-sites-fill",
  HazardousSitesSymbol = "hazardous-sites-symbol",
  HazardousSitesPoint = "hazardous-sites-point",
  HistoricSitesSymbol = "historic-sites-symbol",
  HistoricSitesPoint = "historic-sites-point",
  WatershedsFill = "watersheds-fill",
  WatershedsSymbol = "watersheds-symbol",
  ParksFill = "parks-fill",
  IndianReservationsFill = "indian-reservations-fill",
  WetlandsFill = "wetlands-fill",
  WetlandsBorder = "wetlands-border",
  SeaLevelRiseFill = "sea-level-rise-fill",
  SeaLevelRiseBorder = "sea-level-rise-border",

  //Mike's Layers
  BayAreaHousingSitesFill = "bay-area-housing-opportunity-sites-fill",
  BayAreaHousingSitesSymbol = "bay-area-housing-opportunity-sites-symbol",
  BayAreaHousingSitesBorder = "bay-area-housing-opportunity-sites-border",
  LIHTC_ExistingPropertyInventoryPoint = "lihtc-existing-property-inventory-point",
  LIHTC_ExistingPropertyInventorySymbol = "lihtc-existing-property-inventory-symbol",
  DifficultDevelopmentAreasFill = "difficult-development-areas-fill",
  DifficultDevelopmentAreasBorder = "difficult-development-areas-border",
  QualifiedCensusTractsFill = "qualified-census-tracts-fill",
  QualifiedCensusTractsBorder = "qualified-census-tracts-border",
  CityBoundariesFill = "city-boundaries-fill",
  CityBoundariesSymbol = "city-boundaries-symbol",
  CityBoundariesBorder = "city-boundaries-border",
  TransitOrientedCommunityFill = "transit-oriented-community-fill",

  // Dynamically Generated Layers
  HotelAverageDailyRateBorder = "hotel-average-daily-rate-border",
  HotelAverageDailyRateFill = "hotel-average-daily-rate-fill",
  HotelAverageDailyRateSymbol = "hotel-average-daily-rate-symbol",
  IndustrialRateBorder = "industrial-rate-border",
  IndustrialRateFill = "industrial-rate-fill",
  IndustrialRateSymbol = "industrial-rate-symbol",
  MultifamilyRentBorder = "multifamily-rent-border",
  MultifamilyRentFill = "multifamily-rent-fill",
  MultifamilyRentSymbol = "multifamily-rent-symbol",
  OfficeRateBorder = "office-rate-border",
  OfficeRateFill = "office-rate-fill",
  OfficeRateSymbol = "office-rate-symbol",
  RetailRateBorder = "retail-rate-border",
  RetailRateFill = "retail-rate-fill",
  RetailRateSymbol = "retail-rate-symbol",
  SmartSearchResultsFill = "smart-search-results-fill",

  //Dynamic Population Layers
  DynamicPopulationFill_2010 = "dynamic-population-fill-2010",
  DynamicPopulationFill_2011 = "dynamic-population-fill-2011",
  DynamicPopulationFill_2012 = "dynamic-population-fill-2012",
  DynamicPopulationFill_2013 = "dynamic-population-fill-2013",
  DynamicPopulationFill_2014 = "dynamic-population-fill-2014",
  DynamicPopulationFill_2015 = "dynamic-population-fill-2015",
  DynamicPopulationFill_2016 = "dynamic-population-fill-2016",
  DynamicPopulationFill_2017 = "dynamic-population-fill-2017",
  DynamicPopulationFill_2018 = "dynamic-population-fill-2018",
  DynamicPopulationFill_2019 = "dynamic-population-fill-2019",
  DynamicPopulationFill_2020 = "dynamic-population-fill-2020",
  DynamicPopulationFill_2021 = "dynamic-population-fill-2021",
  DynamicPopulationFill_2022 = "dynamic-population-fill-2022",

  DynamicPopulationBorder_2010 = "dynamic-population-border-2010",
  DynamicPopulationBorder_2011 = "dynamic-population-border-2011",
  DynamicPopulationBorder_2012 = "dynamic-population-border-2012",
  DynamicPopulationBorder_2013 = "dynamic-population-border-2013",
  DynamicPopulationBorder_2014 = "dynamic-population-border-2014",
  DynamicPopulationBorder_2015 = "dynamic-population-border-2015",
  DynamicPopulationBorder_2016 = "dynamic-population-border-2016",
  DynamicPopulationBorder_2017 = "dynamic-population-border-2017",
  DynamicPopulationBorder_2018 = "dynamic-population-border-2018",
  DynamicPopulationBorder_2019 = "dynamic-population-border-2019",
  DynamicPopulationBorder_2020 = "dynamic-population-border-2020",
  DynamicPopulationBorder_2021 = "dynamic-population-border-2021",
  DynamicPopulationBorder_2022 = "dynamic-population-border-2022",

  DynamicPopulationSymbol_2010 = "dynamic-population-symbol-2010",
  DynamicPopulationSymbol_2011 = "dynamic-population-symbol-2011",
  DynamicPopulationSymbol_2012 = "dynamic-population-symbol-2012",
  DynamicPopulationSymbol_2013 = "dynamic-population-symbol-2013",
  DynamicPopulationSymbol_2014 = "dynamic-population-symbol-2014",
  DynamicPopulationSymbol_2015 = "dynamic-population-symbol-2015",
  DynamicPopulationSymbol_2016 = "dynamic-population-symbol-2016",
  DynamicPopulationSymbol_2017 = "dynamic-population-symbol-2017",
  DynamicPopulationSymbol_2018 = "dynamic-population-symbol-2018",
  DynamicPopulationSymbol_2019 = "dynamic-population-symbol-2019",
  DynamicPopulationSymbol_2020 = "dynamic-population-symbol-2020",
  DynamicPopulationSymbol_2021 = "dynamic-population-symbol-2021",
  DynamicPopulationSymbol_2022 = "dynamic-population-symbol-2022",

  //Dynamic Median Rent Layers
  DynamicMedianRentFill_2013 = "dynamic-median-rent-fill-2013",
  DynamicMedianRentFill_2014 = "dynamic-median-rent-fill-2014",
  DynamicMedianRentFill_2015 = "dynamic-median-rent-fill-2015",
  DynamicMedianRentFill_2016 = "dynamic-median-rent-fill-2016",
  DynamicMedianRentFill_2017 = "dynamic-median-rent-fill-2017",
  DynamicMedianRentFill_2018 = "dynamic-median-rent-fill-2018",
  DynamicMedianRentFill_2019 = "dynamic-median-rent-fill-2019",
  DynamicMedianRentFill_2020 = "dynamic-median-rent-fill-2020",
  DynamicMedianRentFill_2021 = "dynamic-median-rent-fill-2021",
  DynamicMedianRentFill_2022 = "dynamic-median-rent-fill-2022",

  DynamicMedianRentBorder_2013 = "dynamic-median-rent-border-2013",
  DynamicMedianRentBorder_2014 = "dynamic-median-rent-border-2014",
  DynamicMedianRentBorder_2015 = "dynamic-median-rent-border-2015",
  DynamicMedianRentBorder_2016 = "dynamic-median-rent-border-2016",
  DynamicMedianRentBorder_2017 = "dynamic-median-rent-border-2017",
  DynamicMedianRentBorder_2018 = "dynamic-median-rent-border-2018",
  DynamicMedianRentBorder_2019 = "dynamic-median-rent-border-2019",
  DynamicMedianRentBorder_2020 = "dynamic-median-rent-border-2020",
  DynamicMedianRentBorder_2021 = "dynamic-median-rent-border-2021",
  DynamicMedianRentBorder_2022 = "dynamic-median-rent-border-2022",

  DynamicMedianRentSymbol_2013 = "dynamic-median-rent-symbol-2013",
  DynamicMedianRentSymbol_2014 = "dynamic-median-rent-symbol-2014",
  DynamicMedianRentSymbol_2015 = "dynamic-median-rent-symbol-2015",
  DynamicMedianRentSymbol_2016 = "dynamic-median-rent-symbol-2016",
  DynamicMedianRentSymbol_2017 = "dynamic-median-rent-symbol-2017",
  DynamicMedianRentSymbol_2018 = "dynamic-median-rent-symbol-2018",
  DynamicMedianRentSymbol_2019 = "dynamic-median-rent-symbol-2019",
  DynamicMedianRentSymbol_2020 = "dynamic-median-rent-symbol-2020",
  DynamicMedianRentSymbol_2021 = "dynamic-median-rent-symbol-2021",
  DynamicMedianRentSymbol_2022 = "dynamic-median-rent-symbol-2022",

  //Dynamic Employment per Population Layers
  DynamicEmploymentPerPopRatioFill2013 = "dynamic-employment-per-population-fill-2013",
  DynamicEmploymentPerPopRatioFill2014 = "dynamic-employment-per-population-fill-2014",
  DynamicEmploymentPerPopRatioFill2015 = "dynamic-employment-per-population-fill-2015",
  DynamicEmploymentPerPopRatioFill2016 = "dynamic-employment-per-population-fill-2016",
  DynamicEmploymentPerPopRatioFill2017 = "dynamic-employment-per-population-fill-2017",
  DynamicEmploymentPerPopRatioFill2018 = "dynamic-employment-per-population-fill-2018",
  DynamicEmploymentPerPopRatioFill2019 = "dynamic-employment-per-population-fill-2019",
  DynamicEmploymentPerPopRatioFill2020 = "dynamic-employment-per-population-fill-2020",
  DynamicEmploymentPerPopRatioFill2021 = "dynamic-employment-per-population-fill-2021",
  DynamicEmploymentPerPopRatioFill2022 = "dynamic-employment-per-population-fill-2022",

  DynamicEmploymentPerPopRatioBorder2013 = "dynamic-employment-per-population-border-2013",
  DynamicEmploymentPerPopRatioBorder2014 = "dynamic-employment-per-population-border-2014",
  DynamicEmploymentPerPopRatioBorder2015 = "dynamic-employment-per-population-border-2015",
  DynamicEmploymentPerPopRatioBorder2016 = "dynamic-employment-per-population-border-2016",
  DynamicEmploymentPerPopRatioBorder2017 = "dynamic-employment-per-population-border-2017",
  DynamicEmploymentPerPopRatioBorder2018 = "dynamic-employment-per-population-border-2018",
  DynamicEmploymentPerPopRatioBorder2019 = "dynamic-employment-per-population-border-2019",
  DynamicEmploymentPerPopRatioBorder2020 = "dynamic-employment-per-population-border-2020",
  DynamicEmploymentPerPopRatioBorder2021 = "dynamic-employment-per-population-border-2021",
  DynamicEmploymentPerPopRatioBorder2022 = "dynamic-employment-per-population-border-2022",

  DynamicEmploymentPerPopRatioSymbol2013 = "dynamic-employment-per-population-symbol-2013",
  DynamicEmploymentPerPopRatioSymbol2014 = "dynamic-employment-per-population-symbol-2014",
  DynamicEmploymentPerPopRatioSymbol2015 = "dynamic-employment-per-population-symbol-2015",
  DynamicEmploymentPerPopRatioSymbol2016 = "dynamic-employment-per-population-symbol-2016",
  DynamicEmploymentPerPopRatioSymbol2017 = "dynamic-employment-per-population-symbol-2017",
  DynamicEmploymentPerPopRatioSymbol2018 = "dynamic-employment-per-population-symbol-2018",
  DynamicEmploymentPerPopRatioSymbol2019 = "dynamic-employment-per-population-symbol-2019",
  DynamicEmploymentPerPopRatioSymbol2020 = "dynamic-employment-per-population-symbol-2020",
  DynamicEmploymentPerPopRatioSymbol2021 = "dynamic-employment-per-population-symbol-2021",
  DynamicEmploymentPerPopRatioSymbol2022 = "dynamic-employment-per-population-symbol-2022",

  //Dynamic Median Income Layers
  DynamicMedianIncomeFill2013 = "dynamic-median-income-fill-2013",
  DynamicMedianIncomeFill2014 = "dynamic-median-income-fill-2014",
  DynamicMedianIncomeFill2015 = "dynamic-median-income-fill-2015",
  DynamicMedianIncomeFill2016 = "dynamic-median-income-fill-2016",
  DynamicMedianIncomeFill2017 = "dynamic-median-income-fill-2017",
  DynamicMedianIncomeFill2018 = "dynamic-median-income-fill-2018",
  DynamicMedianIncomeFill2019 = "dynamic-median-income-fill-2019",
  DynamicMedianIncomeFill2020 = "dynamic-median-income-fill-2020",
  DynamicMedianIncomeFill2021 = "dynamic-median-income-fill-2021",
  DynamicMedianIncomeFill2022 = "dynamic-median-income-fill-2022",

  DynamicMedianIncomeBorder2013 = "dynamic-median-income-border-2013",
  DynamicMedianIncomeBorder2014 = "dynamic-median-income-border-2014",
  DynamicMedianIncomeBorder2015 = "dynamic-median-income-border-2015",
  DynamicMedianIncomeBorder2016 = "dynamic-median-income-border-2016",
  DynamicMedianIncomeBorder2017 = "dynamic-median-income-border-2017",
  DynamicMedianIncomeBorder2018 = "dynamic-median-income-border-2018",
  DynamicMedianIncomeBorder2019 = "dynamic-median-income-border-2019",
  DynamicMedianIncomeBorder2020 = "dynamic-median-income-border-2020",
  DynamicMedianIncomeBorder2021 = "dynamic-median-income-border-2021",
  DynamicMedianIncomeBorder2022 = "dynamic-median-income-border-2022",

  DynamicMedianIncomeSymbol2013 = "dynamic-median-income-symbol-2013",
  DynamicMedianIncomeSymbol2014 = "dynamic-median-income-symbol-2014",
  DynamicMedianIncomeSymbol2015 = "dynamic-median-income-symbol-2015",
  DynamicMedianIncomeSymbol2016 = "dynamic-median-income-symbol-2016",
  DynamicMedianIncomeSymbol2017 = "dynamic-median-income-symbol-2017",
  DynamicMedianIncomeSymbol2018 = "dynamic-median-income-symbol-2018",
  DynamicMedianIncomeSymbol2019 = "dynamic-median-income-symbol-2019",
  DynamicMedianIncomeSymbol2020 = "dynamic-median-income-symbol-2020",
  DynamicMedianIncomeSymbol2021 = "dynamic-median-income-symbol-2021",
  DynamicMedianIncomeSymbol2022 = "dynamic-median-income-symbol-2022",

  // Existing Building Layers (New)
  ParcelsWithExistingBuildingQuery = "parcels-with-height-query",

  // Leads Layers
  IndustrialSitesFill = "industrial-sites-fill",
  IndustrialSitesBorder = "industrial-sites-border",
  MultifamilySitesFill = "multifamily-sites-fill",
  MultifamilySitesBorder = "multifamily-sites-border",
}

export enum DynamicLayerId {
  Dynamic_Layer_Population = "dynamic-layer-population",
  Dynamic_Layer_MedianRent = "dynamic-layer-median-rent",
  Dynamic_Layer_Employment_Per_Population = "dynamic-layer-employment-per-population",
  Dynamic_Layer_Household_Income = "dynamic-layer-household-income",
  Dynamic_Layer_Sea_Level_Rise = "dynamic-layer-sea-level-rise",
}

// Field IDs in Mapbox parcel layer
export enum RawParcelFieldId {
  Address = "ADDRESS",
  AreaPublished = "LAND_SF",
  BasicStratum = "BAS_STRT",
  City = "CITY",
  ConstructionClass = "CONST_CLAS",
  ExistingStructureArea = "TOT_BL_SF",
  ExistingStructureYearBuilt = "YEAR_BUILT",
  Id = "ID",
  ImprovementQuality = "IMP_QUAL",
  LandUseCode = "DOR_UC",
  LandValue = "LND_VAL",
  MultiParcelSale = "M_PAR_SAL1",
  NumberOfBuildings = "NO_BULDNG",
  NumberOfResidentialUnits = "NO_RES_UNT",
  NumberOfExistingUnitsQuery = "NO_RES_UNT",
  OwnerAddress = "OWN_ADDR1",
  OwnerCity = "OWN_CITY",
  OwnerName = "OWN_NAME",
  OwnerState = "OWN_STATE",
  OwnerZipCode = "OWN_ZIPCD",
  ParcelId = "PARCEL_ID",
  PublicLand = "PUBLIC_LND",
  PurchasePrice = "AMKT_VALUE",
  SaleMonth = "SALE_MO1",
  SalePrice = "SALE_PRC1",
  SaleYear = "SALE_YR1",
  State = "STATE",
  ZipCode = "ZIP_CODE",
}

// Field IDs in Mapbox graphs zoning layer
export enum RawGraphingFieldId {
  EmploymentPerPopRatio2013 = "EMPLOYMENT_PER_POP_RATIO_2013",
  EmploymentPerPopRatio2014 = "EMPLOYMENT_PER_POP_RATIO_2014",
  EmploymentPerPopRatio2015 = "EMPLOYMENT_PER_POP_RATIO_2015",
  EmploymentPerPopRatio2016 = "EMPLOYMENT_PER_POP_RATIO_2016",
  EmploymentPerPopRatio2017 = "EMPLOYMENT_PER_POP_RATIO_2017",
  EmploymentPerPopRatio2018 = "EMPLOYMENT_PER_POP_RATIO_2018",
  EmploymentPerPopRatio2019 = "EMPLOYMENT_PER_POP_RATIO_2019",
  EmploymentPerPopRatio2020 = "EMPLOYMENT_PER_POP_RATIO_2020",
  EmploymentPerPopRatio2021 = "EMPLOYMENT_PER_POP_RATIO_2021",
  EmploymentPerPopRatio2022 = "EMPLOYMENT_PER_POP_RATIO_2022",
  GraphLabel = "GRAPH_LABEL",
  GeoId = "GEOID",
  MedianHouseholdIncome2013 = "MEDIAN_HOUSEHOLD_INCOME_2013",
  MedianHouseholdIncome2014 = "MEDIAN_HOUSEHOLD_INCOME_2014",
  MedianHouseholdIncome2015 = "MEDIAN_HOUSEHOLD_INCOME_2015",
  MedianHouseholdIncome2016 = "MEDIAN_HOUSEHOLD_INCOME_2016",
  MedianHouseholdIncome2017 = "MEDIAN_HOUSEHOLD_INCOME_2017",
  MedianHouseholdIncome2018 = "MEDIAN_HOUSEHOLD_INCOME_2018",
  MedianHouseholdIncome2019 = "MEDIAN_HOUSEHOLD_INCOME_2019",
  MedianHouseholdIncome2020 = "MEDIAN_HOUSEHOLD_INCOME_2020",
  MedianHouseholdIncome2021 = "MEDIAN_HOUSEHOLD_INCOME_2021",
  MedianHouseholdIncome2022 = "MEDIAN_HOUSEHOLD_INCOME_2022",
  MedianGrossRentTotal2013 = "MEDIAN_GROSS_RENT_TOTAL_2013",
  MedianGrossRentTotal2014 = "MEDIAN_GROSS_RENT_TOTAL_2014",
  MedianGrossRentTotal2015 = "MEDIAN_GROSS_RENT_TOTAL_2015",
  MedianGrossRentTotal2016 = "MEDIAN_GROSS_RENT_TOTAL_2016",
  MedianGrossRentTotal2017 = "MEDIAN_GROSS_RENT_TOTAL_2017",
  MedianGrossRentTotal2018 = "MEDIAN_GROSS_RENT_TOTAL_2018",
  MedianGrossRentTotal2019 = "MEDIAN_GROSS_RENT_TOTAL_2019",
  MedianGrossRentTotal2020 = "MEDIAN_GROSS_RENT_TOTAL_2020",
  MedianGrossRentTotal2021 = "MEDIAN_GROSS_RENT_TOTAL_2021",
  MedianGrossRentTotal2022 = "MEDIAN_GROSS_RENT_TOTAL_2022",
  Population2010 = "POPULATION_2010",
  Population2011 = "POPULATION_2011",
  Population2012 = "POPULATION_2012",
  Population2013 = "POPULATION_2013",
  Population2014 = "POPULATION_2014",
  Population2015 = "POPULATION_2015",
  Population2016 = "POPULATION_2016",
  Population2017 = "POPULATION_2017",
  Population2018 = "POPULATION_2018",
  Population2019 = "POPULATION_2019",
  Population2020 = "POPULATION_2020",
  Population2021 = "POPULATION_2021",
  Population2022 = "POPULATION_2022",
}

// Field IDs in Mapbox zoning layer
export enum RawZoningFieldId {
  AllowedDetailedUses = "ALL_USES",
  AllowedUses = "USES",
  ArchitectFirm = "ARCHITECT_FIRM",
  ArchitectUrl = "ARCHITECT_URL",
  BuildingHeight = "BUILDING_HEIGHT",
  BuildingHeightQuery = "BUILDING_HEIGHT_QUERY",
  AllowedBuildableArea = "BUILDABLE_SQFT",
  BuildableAreaQuery = "BUILDABLE_SQFT_QUERY",
  FloorAreaRatio = "FAR",
  FloorAreaRatioQuery = "FAR_QUERY",
  HotelUnitDensityDenominator = "HOTEL_UNIT_DENSITY_DENOMINATOR",
  HotelUnitDensityNumerator = "HOTEL_UNIT_DENSITY_NUMERATOR",
  HotelUnitDensityUnitOfMeasure = "HOTEL_UNIT_DENSITY_UNIT_OF_MEASURE",
  InteriorSideSetback = "INTERIOR_SIDE_SETBACK",
  InteriorSideSetbackAboveFirstFloor = "INTERIOR_SIDE_SETBACK_ABOVE_FIRST_FLOOR",
  LivingUnitDensityDenominator = "LIVING_UNIT_DENSITY_DENOMINATOR",
  LivingUnitDensityNumerator = "LIVING_UNIT_DENSITY_NUMERATOR",
  LivingUnitDensityUnitOfMeasure = "LIVING_UNIT_DENSITY_UNIT_OF_MEASURE",
  LotCoverage = "LOT_COVERAGE",
  MinimumLotSize = "LOT_SIZE",
  NumberOfFloors = "NUMBER_OF_FLOORS",
  NumberOfUnitsAllowed = "MAX_NUMBER_UNITS_ALLOWED",
  NumberOfUnitsAllowedQuery = "MAX_UNITS_ALLOWED_QUERY",
  NumberOfExistingUnitsQuery = "NO_RES_UNT",
  PrimarySetback = "PRIMARY_SETBACK",
  ParkingSpacesIndustrialDenominator = "PARKING_SPACES_INDUSTRIAL_DENOMINATOR",
  ParkingSpacesIndustrialNumerator = "PARKING_SPACES_INDUSTRIAL_NUMERATOR",
  ParkingSpacesIndustrialUnitOfMeasure = "PARKING_SPACES_INDUSTRIAL_UNIT_OF_MEASURE",
  ParkingSpacesHotelDenominator = "PARKING_SPACES_HOTEL_DENOMINATOR",
  ParkingSpacesHotelNumerator = "PARKING_SPACES_HOTEL_NUMERATOR",
  ParkingSpacesHotelUnitOfMeasure = "PARKING_SPACES_HOTEL_UNIT_OF_MEASURE",
  ParkingSpacesOfficeDenominator = "PARKING_SPACES_OFFICE_DENOMINATOR",
  ParkingSpacesOfficeNumerator = "PARKING_SPACES_OFFICE_NUMERATOR",
  ParkingSpacesOfficeUnitOfMeasure = "PARKING_SPACES_OFFICE_UNIT_OF_MEASURE",
  ParkingSpacesResidentialDenominator = "PARKING_SPACES_RESIDENTIAL_DENOMINATOR",
  ParkingSpacesResidentialNumerator = "PARKING_SPACES_RESIDENTIAL_NUMERATOR",
  ParkingSpacesResidentialUnitOfMeasure = "PARKING_SPACES_RESIDENTIAL_UNIT_OF_MEASURE",
  ParkingSpacesRetailDenominator = "PARKING_SPACES_RETAIL_DENOMINATOR",
  ParkingSpacesRetailNumerator = "PARKING_SPACES_RETAIL_NUMERATOR",
  ParkingSpacesRetailUnitOfMeasure = "PARKING_SPACES_RETAIL_UNIT_OF_MEASURE",
  ParkingSpacesSingleFamilyDenominator = "PARKING_SPACES_SINGLE_FAMILY_DENOMINATOR",
  ParkingSpacesSingleFamilyNumerator = "PARKING_SPACES_SINGLE_FAMILY_NUMERATOR",
  ParkingSpacesSingleFamilyUnitOfMeasure = "PARKING_SPACES_SINGLE_FAMILY_UNIT_OF_MEASURE",
  PrimarySetbackAboveFirstFloor = "PRIMARY_SETBACK_ABOVE_FIRST_FLOOR",
  RearSetback = "REAR_SETBACK",
  RearSetbackAboveFirstFloor = "REAR_SETBACK_ABOVE_FIRST_FLOOR",
  SideStreetSetback = "SIDE_STREET_SETBACK",
  SideStreetSetbackAboveFirstFloor = "SIDE_STREET_SETBACK_ABOVE_FIRST_FLOOR",
  ZoningCode = "ZONING_CODE_ABBREVIATION",
  ZoningCodeTruncated = "SEARCH_BY_ZONING",
  Project_URL_1 = "Other_URL_0",
  Project_URL_2 = "Other_URL_1",
  Project_URL_3 = "Other_URL_2",
  Project_URL_4 = "Other_URL_3",
  PD_URL_1 = "PD_URL_0",
  PD_URL_2 = "PD_URL_1",
  PD_URL_3 = "PD_URL_2",
  PD_URL_4 = "PD_URL_3",
  Overlays = "OVERLAYS",
  BuildingHeightFootnote = "BUILDING_HEIGHT_FOOTNOTE",
  UnitDensityFootnote = "UNIT_DENSITY_FOOTNOTE",
  HotelDensityFootnote = "HOTEL_UNIT_DENSITY_FOOTNOTE",
  FarFootnote = "FAR_FOOTNOTE",
  LotCoverageFootnote = "LOT_COVERAGE_FOOTNOTE",
  MinimumLotSizeFootnote = "LOT_SIZE_FOOTNOTE",
  NumberOfFloorsFootnote = "NUMBER_OF_FLOORS_FOOTNOTE",
  SetbackFootnote = "SETBACK_FOOTNOTE",
  UsesFootnote = "USES_FOOTNOTE",
  ParkingMultifamilyFootnote = "PARKING_RESIDENTIAL_FOOTNOTE",
  ParkingSingleFamilyFootnote = "PARKING_SINGLE_FAMILY_FOOTNOTE",
  ParkingHotelFootnote = "PARKING_HOTEL_FOOTNOTE",
  ParkingOfficeFootnote = "PARKING_OFFICE_FOOTNOTE",
  ParkingRetailFootnote = "PARKING_RETAIL_FOOTNOTE",
  ParkingIndustrialFootnote = "PARKING_INDUSTRIAL_FOOTNOTE",
}

export enum RawDemographicsFieldId {
  EmploymentPerPopulationRatio = "EMPLOYMENT_PER_POP_RATIO",
  EmploymentPerPopulationRatioGenerated = "EMPLOYMENT_PER_POP_RATIO_GENERATED",
  Id = "ID",
  Gdp = "GDP",
  GrossMedianRent = "GROSS_MEDIAN_RENT",
  GrossMedianRentGenerated = "GROSS_MEDIAN_RENT_GENERATED",
  Market = "MARKET",
  MedianIncomeTotal = "MEDIAN_INCOME_TOTAL",
  MedianIncomeTotalGenerated = "MEDIAN_INCOME_TOTAL_GENERATED",
  PopulationDensity = "POPULATION_DENSITY",
  PopulationDensityGenerated = "POPULATION_DENSITY_GENERATED",
}

export enum RawOverlaysFieldId {
  OverlayId = "OVERLAY_ID",
  OverlayMapCode = "OVERLAY_MAP_CODE",
  OverlayName = "OVERLAY_NAME",
  OverlayLinkOne = "OVERLAY_LINK_1",
  OverlayLinkTwo = "OVERLAY_LINK_2",
  OverlayDescription = "OVERLAY_DESCRIPTION",
  OverlayZoningParametersAffected = "ZONING PARAMETERS AFFECTED",
  OverlayAllUses = "OVERLAY_ALL_USES",
  OverlayUsesFootnote = "OVERLAY_USES_FOOTNOTE",
  OverlayBuildingHeight = "OVERLAY_BUILDING_HEIGHT",
  OverlayBuildingHeightQuery = "OVERLAY_BUILDING_HEIGHT_QUERY",
  OverlayBuildingHeightFootnote = "OVERLAY_BUILDING_HEIGHT_FOOTNOTE",
  OverlayState = "OVERLAY_STATE",
  OverlayCountry = "OVERLAY_COUNTRY",
  OverlayCity = "OVERLAY_CITY",
  OverlayCounty = "OVERLAY_COUNTY",
  OverlayFar = "OVERLAY_FAR",
  OverlayFarQuery = "OVERLAY_FAR_QUERY",
  OverlayFarFootnote = "OVERLAY_FAR_FOOTNOTE",
  OverlayLivingUnitDensityDenominator = "OVERLAY_LIVING_UNIT_DENSITY_DENOMINATOR",
  OverlayLivingUnitDensityNumerator = "OVERLAY_LIVING_UNIT_DENSITY_NUMERATOR",
  OverlayLivingUnitDensityUnitOfMeasure = "OVERLAY_LIVING_UNIT_DENSITY_UNIT_OF_MEASURE",
  OverlayUnitDensityFootnote = "OVERLAY_UNIT_DENSITY_FOOTNOTE",
  OverlayHotelUnitDensityDenominator = "OVERLAY_HOTEL_UNIT_DENSITY_DENOMINATOR",
  OverlayHotelUnitDensityNumerator = "OVERLAY_HOTEL_UNIT_DENSITY_NUMERATOR",
  OverlayHotelUnitDensityUnitOfMeasure = "OVERLAY_HOTEL_UNIT_DENSITY_UNIT_OF_MEASURE",
  OverlayHotelDensityFootnote = "OVERLAY_HOTEL_DENSITY_FOOTNOTE",
  OverlayLotCoverage = "OVERLAY_LOT_COVERAGE",
  OverlayLotCoverageQuery = "OVERLAY_LOT_COVERAGE_QUERY",
  OverlayLotCoverageFootnote = "OVERLAY_LOT_COVERAGE_FOOTNOTE",
  OverlayNumberOfFloors = "OVERLAY_NUMBER_OF_FLOORS",
  OverlayNumberOfFloorsQuery = "OVERLAY_NUMBER_OF_FLOORS_QUERY",
  OverlayNumberOfFloorsFootnote = "OVERLAY_NUMBER_OF_FLOORS_FOOTNOTE",
  OverlayParkingSpacesHotelNumerator = "OVERLAY_PARKING_SPACES_HOTEL_NUMERATOR",
  OverlayParkingSpacesHotelDenominator = "OVERLAY_PARKING_SPACES_HOTEL_DENOMINATOR",
  OverlayParkingSpacesHotelUnitOfMeasure = "OVERLAY_PARKING_SPACES_HOTEL_UNIT_OF_MEASURE",
  OverlayParkingHotelFootnote = "OVERLAY_PARKING_HOTEL_FOOTNOTE",
  OverlayParkingSpacesIndustrialNumerator = "OVERLAY_PARKING_SPACES_INDUSTRIAL_NUMERATOR",
  OverlayParkingSpacesIndustrialDenominator = "OVERLAY_PARKING_SPACES_INDUSTRIAL_DENOMINATOR",
  OverlayParkingSpacesIndustrialUnitOfMeasure = "OVERLAY_PARKING_SPACES_INDUSTRIAL_UNIT_OF_MEASURE",
  OverlayParkingIndustrialFootnote = "OVERLAY_PARKING_INDUSTRIAL_FOOTNOTE",
  OverlayParkingSpacesOfficeNumerator = "OVERLAY_PARKING_SPACES_OFFICE_NUMERATOR",
  OverlayParkingSpacesOfficeDenominator = "OVERLAY_PARKING_SPACES_OFFICE_DENOMINATOR",
  OverlayParkingSpacesOfficeUnitOfMeasure = "OVERLAY_PARKING_SPACES_OFFICE_UNIT_OF_MEASURE",
  OverlayParkingOfficeFootnote = "OVERLAY_PARKING_OFFICE_FOOTNOTE",
  OverlayParkingSpacesResidentialNumerator = "OVERLAY_PARKING_SPACES_RESIDENTIAL_NUMERATOR",
  OverlayParkingSpacesResidentialDenominator = "OVERLAY_PARKING_SPACES_RESIDENTIAL_DENOMINATOR",
  OverlayParkingSpacesResidentialUnitOfMeasure = "OVERLAY_PARKING_SPACES_RESIDENTIAL_UNIT_OF_MEASURE",
  OverlayParkingResidentialFootnote = "OVERLAY_PARKING_RESIDENTIAL_FOOTNOTE",
  OverlayParkingSpacesRetailNumerator = "OVERLAY_PARKING_SPACES_RETAIL_NUMERATOR",
  OverlayParkingSpacesRetailDenominator = "OVERLAY_PARKING_SPACES_RETAIL_DENOMINATOR",
  OverlayParkingSpacesRetailUnitOfMeasure = "OVERLAY_PARKING_SPACES_RETAIL_UNIT_OF_MEASURE",
  OverlayParkingRetailFootnote = "OVERLAY_PARKING_RETAIL_FOOTNOTE",
  OverlayParkingSpacesSingleFamilyNumerator = "OVERLAY_PARKING_SPACES_SINGLE_FAMILY_NUMERATOR",
  OverlayParkingSpacesSingleFamilyDenominator = "OVERLAY_PARKING_SPACES_SINGLE_FAMILY_DENOMINATOR",
  OverlayParkingSpacesSingleFamilyUnitOfMeasure = "OVERLAY_PARKING_SPACES_SINGLE_FAMILY_UNIT_OF_MEASURE",
  OverlayParkingSingleFamilyFootnote = "OVERLAY_PARKING_SINGLE_FAMILY_FOOTNOTE",
  OverlayPrimarySetback = "OVERLAY_PRIMARY_SETBACK",
  OverlayRearSetback = "OVERLAY_REAR_SETBACK",
  OverlaySideStreetSetback = "OVERLAY_SIDE_STREET_SETBACK",
  OverlayInteriorSideSetback = "OVERLAY_INTERIOR_SIDE_SETBACK",
  OverlaySetbacksFootnote = "OVERLAY_SETBACKS_FOOTNOTE",
}

export enum RentFeatureFieldId {
  Value = "value",
}

export enum RawBuildingFieldId {
  Height = "HEIGHT",
  StructureArea = "EXISTING_BUILDING_AREA",
}

export enum LayerType {
  Circle = "circle",
  Fill = "fill",
  FillExtrusion = "fillExtrusion",
  Line = "line",
  Symbol = "symbol",
}

export type LayerPaint = {
  opacity?: number | Array<any>;
  strokeOpacity?: number;
  textOpacity?: number;
  iconOpacity?: number;
};

export const camera: { center: [number, number]; zoom: number } = {
  center: [-94.11, 38.364],
  zoom: 15,
};

export enum FloorPlanFields {
  FloorPlanShapeId = "ID",
}

export enum RawDynamicLayersFieldId {
  EmploymentPerPopRatio2013 = "EMPLOYMENT_PER_POP_RATIO_2013",
  EmploymentPerPopRatio2014 = "EMPLOYMENT_PER_POP_RATIO_2014",
  EmploymentPerPopRatio2015 = "EMPLOYMENT_PER_POP_RATIO_2015",
  EmploymentPerPopRatio2016 = "EMPLOYMENT_PER_POP_RATIO_2016",
  EmploymentPerPopRatio2017 = "EMPLOYMENT_PER_POP_RATIO_2017",
  EmploymentPerPopRatio2018 = "EMPLOYMENT_PER_POP_RATIO_2018",
  EmploymentPerPopRatio2019 = "EMPLOYMENT_PER_POP_RATIO_2019",
  EmploymentPerPopRatio2020 = "EMPLOYMENT_PER_POP_RATIO_2020",
  EmploymentPerPopRatio2021 = "EMPLOYMENT_PER_POP_RATIO_2021",
  EmploymentPerPopRatio2022 = "EMPLOYMENT_PER_POP_RATIO_2022",

  MedianHouseholdIncome2013 = "MEDIAN_HOUSEHOLD_INCOME_2013",
  MedianHouseholdIncome2014 = "MEDIAN_HOUSEHOLD_INCOME_2014",
  MedianHouseholdIncome2015 = "MEDIAN_HOUSEHOLD_INCOME_2015",
  MedianHouseholdIncome2016 = "MEDIAN_HOUSEHOLD_INCOME_2016",
  MedianHouseholdIncome2017 = "MEDIAN_HOUSEHOLD_INCOME_2017",
  MedianHouseholdIncome2018 = "MEDIAN_HOUSEHOLD_INCOME_2018",
  MedianHouseholdIncome2019 = "MEDIAN_HOUSEHOLD_INCOME_2019",
  MedianHouseholdIncome2020 = "MEDIAN_HOUSEHOLD_INCOME_2020",
  MedianHouseholdIncome2021 = "MEDIAN_HOUSEHOLD_INCOME_2021",
  MedianHouseholdIncome2022 = "MEDIAN_HOUSEHOLD_INCOME_2022",

  MedianGrossRentTotal2013 = "MEDIAN_GROSS_RENT_TOTAL_2013",
  MedianGrossRentTotal2014 = "MEDIAN_GROSS_RENT_TOTAL_2014",
  MedianGrossRentTotal2015 = "MEDIAN_GROSS_RENT_TOTAL_2015",
  MedianGrossRentTotal2016 = "MEDIAN_GROSS_RENT_TOTAL_2016",
  MedianGrossRentTotal2017 = "MEDIAN_GROSS_RENT_TOTAL_2017",
  MedianGrossRentTotal2018 = "MEDIAN_GROSS_RENT_TOTAL_2018",
  MedianGrossRentTotal2019 = "MEDIAN_GROSS_RENT_TOTAL_2019",
  MedianGrossRentTotal2020 = "MEDIAN_GROSS_RENT_TOTAL_2020",
  MedianGrossRentTotal2021 = "MEDIAN_GROSS_RENT_TOTAL_2021",
  MedianGrossRentTotal2022 = "MEDIAN_GROSS_RENT_TOTAL_2022",

  Population2010 = "POPULATION_2010",
  Population2011 = "POPULATION_2011",
  Population2012 = "POPULATION_2012",
  Population2013 = "POPULATION_2013",
  Population2014 = "POPULATION_2014",
  Population2015 = "POPULATION_2015",
  Population2016 = "POPULATION_2016",
  Population2017 = "POPULATION_2017",
  Population2018 = "POPULATION_2018",
  Population2019 = "POPULATION_2019",
  Population2020 = "POPULATION_2020",
  Population2021 = "POPULATION_2021",
  Population2022 = "POPULATION_2022",

  SeaLevelRise_0 = "SEA_LEVEL_RISE_0",
  SeaLevelRise_1 = "SEA_LEVEL_RISE_1",
  SeaLevelRise_2 = "SEA_LEVEL_RISE_2",
  SeaLevelRise_3 = "SEA_LEVEL_RISE_3",
  SeaLevelRise_4 = "SEA_LEVEL_RISE_4",
  SeaLevelRise_5 = "SEA_LEVEL_RISE_5",
  SeaLevelRise_6 = "SEA_LEVEL_RISE_6",
  SeaLevelRise_7 = "SEA_LEVEL_RISE_7",
  SeaLevelRise_8 = "SEA_LEVEL_RISE_8",
  SeaLevelRise_9 = "SEA_LEVEL_RISE_9",
  SeaLevelRise_10 = "SEA_LEVEL_RISE_10",

  None = "NONE",
}
