import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { subscriptionInvitationsActions, subscriptionInvitationsSelectors } from "../../../../../state/subscriptionInvitations";
import Format from "../../../../../types/Format";
import { Chargebee } from "../../../../../types/Service";
import valueFormatter from "../../../../../utils/valueFormatter";
import wrapComponentWithPopup, { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import PriceEstimateCalculator from "../../sharedComponents/PriceEstimateCalculator";

const TIMEOUT = 1000;

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    planPrices: subscriptionSelectors.getPlanPrices(state),
  }
}

const mapDispatchToProps = {
  resendInvitation: subscriptionInvitationsActions.resendStart
};

interface OwnProps {
  userId: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & DispatchProps & StateProps & OwnProps;

interface State {
  couponCode: string;
  couponIsValid: boolean | null;
  planPeriodIsYearly: boolean;
  priceEstimate?: number;
}

class ReinvitePopup extends React.PureComponent<Props, State>  {
  calculateEstimateTimeout: any;
  couponCodeHasBeenVerifiedOnInit: boolean = false;

  constructor(props: Props) {
    super(props);

    const couponCode = props.managedSubscriptions && props.managedSubscriptions[props.userId].discountCode.length > 0
      ? props.managedSubscriptions[props.userId].discountCode[0]
      : "";

    const planId = this.getPlanId();

    this.state = {
      couponCode: couponCode,
      couponIsValid: null,
      priceEstimate: props.planPrices[planId],
      planPeriodIsYearly: planId.includes("annual"),
    }
  }

  /**
   * Cancel invitation.
   */
  resendInvitation = () => {
    const { userId, resendInvitation, closePopup } = this.props;
    if (this.state.couponIsValid) {
      resendInvitation(userId, this.state.couponCode);
      closePopup();
    }
  }

  /**
   * Updates the state when the discount changes.
   */
  handleCouponCodeChange = (event, calculateFinalEstimate: (planPrices: Chargebee.PlanPrices, planId: Chargebee.PlanId, couponCode: string) => Promise<void>) => {
    const couponCode = event.target.value && event.target.value.replace(" ", "").toUpperCase();
    this.setState({ couponCode: couponCode, couponIsValid: couponCode.length === 0 ? true : null });

    if (this.calculateEstimateTimeout) clearInterval(this.calculateEstimateTimeout);
    this.calculateEstimateTimeout = setTimeout(() => {
      calculateFinalEstimate(this.props.planPrices, this.getPlanId(), this.state.couponCode);
    }, TIMEOUT);
  }

  /**
   * Get the plan ID.
   */
  getPlanId = () => {
    return this.props.managedSubscriptions![this.props.userId].planId;
  }

  /**
   * Callback that has price estimate and coupon valid values.
   */
  handlePriceEstimateChange = (values: { priceEstimate: number, couponIsValid: boolean }) => {
    this.setState({ ...values });
  }

  /**
   * Returns boolean if the coupon is valid.
   */
  isValidToSend = () => {
    return this.couponCodeHasBeenVerifiedOnInit && !Boolean(this.state.couponIsValid);
  }

  render() {
    const { userId, managedSubscriptions, planPrices } = this.props;
    const { couponCode, priceEstimate, couponIsValid, planPeriodIsYearly } = this.state;
    if (!managedSubscriptions) return null;

    return (
      <PriceEstimateCalculator onStateChange={this.handlePriceEstimateChange}>
        {(calculateFinalEstimate) => {
          if (!this.couponCodeHasBeenVerifiedOnInit) {
            calculateFinalEstimate(planPrices, this.getPlanId(), couponCode);
            this.couponCodeHasBeenVerifiedOnInit = true;
          }

          return (
            <div className="component--reinvite-popup">
              <div className="top-container">
                <div className="text">
                  Are you sure you would like to resend <span>{managedSubscriptions[userId].name}</span> the invitation?
                </div>

                <div className="price-text">
                  {priceEstimate !== undefined && `${valueFormatter.format(priceEstimate, { type: Format.Type.Currency })}${planPeriodIsYearly ? "/yr" : "/mo"} will be added to your next bill.`}
                </div>

                <div className={`input-field ${couponIsValid === false ? "invalid" : ""}`}>
                  <input
                    type="text"
                    placeholder="Discount Code"
                    onChange={(event) => this.handleCouponCodeChange(event, calculateFinalEstimate)}
                    value={couponCode}
                  />
                </div>

                <div className="error">{couponIsValid === false ? "Discount Code is invalid. Please try again with a valid code." : ""}</div>
              </div>

              <div className="buttons-container">
                <button onClick={this.props.closePopup}>Cancel</button>
                <button
                  className="delete"
                  onClick={this.resendInvitation}
                  disabled={this.isValidToSend()}
                >
                  Resend Invitation
                </button>
              </div>
            </div>
          )

        }}
      </PriceEstimateCalculator>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReinvitePopup)
);
