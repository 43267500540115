import React from "react";
import SetbackMeasurementTools from "../SetbackMeasurementTools";
import SetbackTools from "../SetbackTools/SetbackTools";

const SetbackToolsWrapper = () => {

  return (
    <div className="component--setback-tools-wrapper">
      <SetbackTools />
      <SetbackMeasurementTools />
    </div>
  )
}

export default SetbackToolsWrapper;
