import log from "loglevel";
import { call, put, takeEvery } from "redux-saga/effects";
import { pdfActions } from ".";
import DocumentNotFoundError from "../../errors/DocumentNotFoundError";
import Pdf from "../../types/Pdf";
import pdf from "../../utils/database/pdf";
import pdfCovers from "../../utils/storage/developments/pdfCovers";
import pdfLogos from "../../utils/storage/developments/pdfLogos";
import { developmentActionTypes } from "../development";
import actions from "./actions";
import actionTypes from "./actionTypes";

/**
 * Start loading pdf information and setting it in the store.
 */
function* loadStart(action) {
  const currentProjectId = action.payload.projectId;
  let pdfData;

  try {
    pdfData = yield call(pdf.read, currentProjectId);
  } catch (error) {
    if (error instanceof DocumentNotFoundError) {
      yield call(pdf.create, currentProjectId);
      pdfData = {};
    } else {
      throw error;
    }
  }

  let coverImage, logoImage;
  try {
    coverImage = yield call(pdfCovers.download, currentProjectId);
  } catch (error) {
    coverImage = null;
  }

  try {
    logoImage = yield call(pdfLogos.download, currentProjectId);
  } catch (error) {
    logoImage = null;
  }

  yield put(
    pdfActions.loadDataSuccess(pdfData, {
      [Pdf.ImageType.Cover]: coverImage,
      [Pdf.ImageType.Logo]: logoImage,
    })
  );
}

/**
 * Upload cover page image.
 */
function* uploadImage(action: ReturnType<typeof actions.uploadCoverPageImageStart>) {
  const payload = action.payload;

  try {
    switch (payload.imageType) {
      case Pdf.ImageType.Cover:
        yield call(pdfCovers.upload, payload.imageFile, payload.developmentId);
        break;
      case Pdf.ImageType.Logo:
        yield call(pdfLogos.upload, payload.imageFile, payload.developmentId);
        break;
    }

    let image: Pdf.Image = {
      fileName: payload.imageFile.name,
      url: URL.createObjectURL(payload.imageFile),
      isLoading: false,
    };

    yield put(actions.uploadCoverPageImageSuccess(image, payload.imageType));
  } catch (error) {
    log.warn(error);
  }
}

/**
 * Delete cover page image.
 */
function* deleteImage(action: ReturnType<typeof actions.deleteCoverPageImageStart>) {
  const payload = action.payload;

  try {
    switch (payload.imageType) {
      case Pdf.ImageType.Cover:
        yield call(pdfCovers.remove, payload.developmentId);
        break;
      case Pdf.ImageType.Logo:
        yield call(pdfLogos.remove, payload.developmentId);
        break;
    }

    yield put(actions.deleteCoverPageImageSuccess(payload.imageType));
  } catch (error) {
    log.warn(error);
  }
}

/**
 * Watcher for the loadStart task.
 */
function* watchDevelopmentInitialize() {
  yield takeEvery(developmentActionTypes.LOAD_SUCCESS, loadStart);
}

/**
 * Watcher for the upload image task.
 */
function* watchImageUploadStart() {
  yield takeEvery(actionTypes.UPLOAD_COVER_PAGE_IMAGE_START, uploadImage);
}

/**
 * Watcher for the delete image task.
 */
function* watchImageDeleteStart() {
  yield takeEvery(actionTypes.DELETE_COVER_PAGE_IMAGE_START, deleteImage);
}

export default {
  watchDevelopmentInitialize,
  watchImageUploadStart,
  watchImageDeleteStart,
};
