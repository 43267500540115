import React from "react";
import { subscriptionInvitationsActions, subscriptionInvitationsSelectors } from "../../../../../state/subscriptionInvitations";
import wrapComponentWithPopup, { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import WithPanelHeader from "../../../../sharedComponents/WithPanelHeader";
import { connect } from "react-redux";
import { subscriptionManagementSelectors } from "../../../../../state/ui/subscriptionManagement";
import BusySpinner from "../../../../sharedComponents/BusySpinner";

const mapStateToProps = (state) => {
  return {
    managedSubscriptions: subscriptionInvitationsSelectors.getManagedSubscriptions(state),
    invitationIsBeingCancelled: subscriptionManagementSelectors.getInvitationIsBeingCancelled(state),
  }
}

const mapDispatchToProps = {
  cancelInvitation: subscriptionInvitationsActions.cancelStart,
};

interface OwnProps {
  userId: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = PopupProps & DispatchProps & StateProps & OwnProps;

class CancelInvitationPopup extends React.PureComponent<Props, {}>  {
  /**
   * Cancel invitation.
   */
  cancelInvitation = () => {
    const { userId, cancelInvitation, invitationIsBeingCancelled } = this.props;
    if (invitationIsBeingCancelled) return;

    cancelInvitation(userId);
  }

  render() {
    const { userId, managedSubscriptions, invitationIsBeingCancelled } = this.props;
    if (!managedSubscriptions) return null;

    return (
      <WithPanelHeader title="Cancel Invitation" hideControls>
        <div className="component--cancel-invitation-popup">
          <div className="text">
            Are you sure you’d like to cancel <span>{managedSubscriptions[userId].name}</span> invitation?
          </div>

          <div className="buttons-container">
            <button
              className="close-button"
              onClick={this.props.closePopup}
            >
              Close
            </button>
            <button
              className="cancel"
              onClick={this.cancelInvitation}
            >
              {invitationIsBeingCancelled
                ? <BusySpinner classes="white-spinner" />
                : "Cancel Invitation"
              }
            </button>
          </div>
        </div>
      </WithPanelHeader>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CancelInvitationPopup)
);
