import { Dispatch } from "react";
import "./_styles.scss";

interface OwnProps {
  showOnlyActive: boolean;
  setShowOnlyActive: Dispatch<React.SetStateAction<boolean>>;
}

const ShowOnlyFilter = ({ showOnlyActive, setShowOnlyActive }: OwnProps) => {
  const handleShowAll = () => setShowOnlyActive(false);
  const handleShowOnlyActive = () => setShowOnlyActive(true);

  return (
    <div className="show-only-filter">
      <div className="filter-box">
        <input
          type="radio"
          value="showAll"
          name="unitSystem"
          onChange={handleShowAll}
          defaultChecked={true}
          checked={!showOnlyActive}
        />
        <p className="label-text">All Layers</p>
      </div>
      <div className="filter-box">
        <input
          type="radio"
          value="showActive"
          name="unitSystem"
          onChange={handleShowOnlyActive}
          checked={showOnlyActive}
        />
        <p className="label-text">Active Layers</p>
      </div>
    </div>
  );
};

export default ShowOnlyFilter;
