import React from "react";
import Slider from "rc-slider";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import {
  EmploymentPerPopulationDataYear,
  MedianIncomeDataYear,
  MedianRentDataYear,
  PopulationDataYear,
  SeaLevelRiseData,
} from "types/DynamicLayersDataYear";
import { mapsActions, mapsSelectors } from "state/ui/shared/maps";
import layerHelper from "utils/mapbox/layerHelper";
import config from "../../config";
import { DynamicLayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import { connect } from "react-redux";

const SLIDER_TO_DATA_FIELD = {
  [DynamicLayerId.Dynamic_Layer_Population]: {
    [PopulationDataYear.Population2010]: MapStyleProperties.RawDynamicLayersFieldId.Population2010,
    [PopulationDataYear.Population2011]: MapStyleProperties.RawDynamicLayersFieldId.Population2011,
    [PopulationDataYear.Population2012]: MapStyleProperties.RawDynamicLayersFieldId.Population2012,
    [PopulationDataYear.Population2013]: MapStyleProperties.RawDynamicLayersFieldId.Population2013,
    [PopulationDataYear.Population2014]: MapStyleProperties.RawDynamicLayersFieldId.Population2014,
    [PopulationDataYear.Population2015]: MapStyleProperties.RawDynamicLayersFieldId.Population2015,
    [PopulationDataYear.Population2016]: MapStyleProperties.RawDynamicLayersFieldId.Population2016,
    [PopulationDataYear.Population2017]: MapStyleProperties.RawDynamicLayersFieldId.Population2017,
    [PopulationDataYear.Population2018]: MapStyleProperties.RawDynamicLayersFieldId.Population2018,
    [PopulationDataYear.Population2019]: MapStyleProperties.RawDynamicLayersFieldId.Population2019,
    [PopulationDataYear.Population2020]: MapStyleProperties.RawDynamicLayersFieldId.Population2020,
    [PopulationDataYear.Population2021]: MapStyleProperties.RawDynamicLayersFieldId.Population2021,
    [PopulationDataYear.Population2022]: MapStyleProperties.RawDynamicLayersFieldId.Population2022,
  },
  [DynamicLayerId.Dynamic_Layer_MedianRent]: {
    [MedianRentDataYear.MedianRent2013]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2013,
    [MedianRentDataYear.MedianRent2014]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2014,
    [MedianRentDataYear.MedianRent2015]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2015,
    [MedianRentDataYear.MedianRent2016]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2016,
    [MedianRentDataYear.MedianRent2017]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2017,
    [MedianRentDataYear.MedianRent2018]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2018,
    [MedianRentDataYear.MedianRent2019]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2019,
    [MedianRentDataYear.MedianRent2020]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2020,
    [MedianRentDataYear.MedianRent2021]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2021,
    [MedianRentDataYear.MedianRent2022]: MapStyleProperties.RawDynamicLayersFieldId.MedianGrossRentTotal2022,
  },
  [DynamicLayerId.Dynamic_Layer_Employment_Per_Population]: {
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2013]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2013,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2014]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2014,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2015]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2015,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2016]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2016,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2017]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2017,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2018]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2018,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2019]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2019,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2020]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2020,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2021]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2021,
    [EmploymentPerPopulationDataYear.EmploymentPerPopulation2022]:
      MapStyleProperties.RawDynamicLayersFieldId.EmploymentPerPopRatio2022,
  },
  [DynamicLayerId.Dynamic_Layer_Household_Income]: {
    [MedianIncomeDataYear.MedianIncome2013]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2013,
    [MedianIncomeDataYear.MedianIncome2014]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2014,
    [MedianIncomeDataYear.MedianIncome2015]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2015,
    [MedianIncomeDataYear.MedianIncome2016]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2016,
    [MedianIncomeDataYear.MedianIncome2017]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2017,
    [MedianIncomeDataYear.MedianIncome2018]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2018,
    [MedianIncomeDataYear.MedianIncome2019]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2019,
    [MedianIncomeDataYear.MedianIncome2020]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2020,
    [MedianIncomeDataYear.MedianIncome2021]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2021,
    [MedianIncomeDataYear.MedianIncome2022]: MapStyleProperties.RawDynamicLayersFieldId.MedianHouseholdIncome2022,
  },
  [DynamicLayerId.Dynamic_Layer_Sea_Level_Rise]: {
    [SeaLevelRiseData.Level_0]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_0,
    [SeaLevelRiseData.Level_1]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_1,
    [SeaLevelRiseData.Level_2]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_2,
    [SeaLevelRiseData.Level_3]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_3,
    [SeaLevelRiseData.Level_4]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_4,
    [SeaLevelRiseData.Level_5]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_5,
    [SeaLevelRiseData.Level_6]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_6,
    [SeaLevelRiseData.Level_7]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_7,
    [SeaLevelRiseData.Level_8]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_8,
    [SeaLevelRiseData.Level_9]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_9,
    [SeaLevelRiseData.Level_10]: MapStyleProperties.RawDynamicLayersFieldId.SeaLevelRise_10,
  },
};

const SLIDER_STYLE = {
  background: "transparent",
  height: "15px",
};

const RAIL_STYLE = {
  background: "#D5D5E0",
  height: "7px",
};

const HANDLE_STYLE = {
  background: "#3A4276",
};

const DOT_STYLE = {
  background: "#3A4276",
  borderColor: "#ffffff",
  marginTop: "2px",
};

interface OwnProps {
  currentLabel: string;
  sliderMin: number;
  sliderMax: number;
  defaultValue: number;
}

const mapStateToProps = (state) => ({
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
});

const mapDispatchToProps = {
  toggleDynamicLayers: mapsActions.toggleDynamicLayers,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

export class DynamicSliderComponent extends React.PureComponent<Props, {}> {
  /**
   * Toggle the Dynamic Layer with the selected year by the user
   */
  toggleDynamicLayers = (layerYear) => {
    const { getSelectedDynamicLayer } = this.props;

    if (getSelectedDynamicLayer) {
      const fieldToQuery = SLIDER_TO_DATA_FIELD[getSelectedDynamicLayer][layerYear];
      const layersIds = layerHelper.getDynamicLayersByField(config, fieldToQuery);
      this.props.toggleDynamicLayers(layersIds, fieldToQuery, getSelectedDynamicLayer);
    }
  };

  /**
   * Render label for the selected slider step.
   */
  renderTitleLabel = () => {
    const { getSelectedDynamicLayer } = this.props;
    const demographicLabel = "Selected Year: ";
    const isSeaLevelRiseLayer =
      getSelectedDynamicLayer === MapStyleProperties.DynamicLayerId.Dynamic_Layer_Sea_Level_Rise;
    return isSeaLevelRiseLayer ? "Sea Level Rise: " : demographicLabel;
  };

  render() {
    const { currentLabel, sliderMin, sliderMax, defaultValue } = this.props;

    return (
      <div className="dynamic-layer-selection-panel">
        <div className="component--dynamic-layer-box">
          <div className="dynamic-layer-panel-title-box">
            <p className="dynamic-layer-panel-title">{this.renderTitleLabel()}</p>
            <p className="dynamic-layer-panel-title value">{currentLabel}</p>
          </div>

          <div className="slider-wrapper">
            <div className="slider-inner-wrapper">
              <Slider
                dots
                style={SLIDER_STYLE}
                railStyle={RAIL_STYLE}
                trackStyle={{
                  backgroundColor: "transparent",
                }}
                handleStyle={HANDLE_STYLE}
                dotStyle={DOT_STYLE}
                onChange={this.toggleDynamicLayers}
                min={sliderMin}
                max={sliderMax}
                defaultValue={defaultValue}
                step={1}
              />
            </div>
          </div>

          <div className="years-label-box">
            <p className="year-label">{sliderMin}</p>
            <p className="year-label">{sliderMax}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicSliderComponent);
