import React from "react";

interface ownProps {
  dividerLabel: string;
}

const DividerComponent = (props: ownProps) => {
  return (
    <div className="divider-component-box">
      <label className="divider-label">{props.dividerLabel}</label>
    </div>
  )
}

export default DividerComponent;