import React from "react";

interface OwnProps {
  activeTab: string;
  tabId: string;
}

type Props = OwnProps;

export class TabPanel extends React.PureComponent<Props, {}> {
  render() {
    const { children, activeTab, tabId } = this.props;
    if (activeTab !== tabId) return null;

    return (
      <div className="component--tab-panel">
        {children}
      </div>
    )
  }
}

export default TabPanel
