import React, { Component } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "state/development";
import { VariableId } from "types/VariableId";

type OwnProps = {
  text: string;
  variableId?: VariableId;
  styleVariation?: string;
  hiddenValues?: boolean;
  hiddenText?: boolean;
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const { variableId, text } = ownProps;
  const customCellTextArray = variableId && developmentSelectors.getCustomCellText(state, variableId);

  const cellText = variableId && customCellTextArray ? (customCellTextArray[0] ? customCellTextArray[0].customName : text) : text;

  return { cellText };
};
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;
class CellText extends Component<Props, {}> {
  render() {
    const { hiddenValues, styleVariation, hiddenText, cellText } = this.props;

    let classes = "component--cell-text ";
    if (styleVariation) classes += ` ${styleVariation}`;
    if (hiddenText) classes += " blurry-text";

    return (
      <td className={classes}>
        <span className={`value ${hiddenValues ? " blurry-text" : ""}`}>{cellText}</span>
      </td>
    );
  }
}

export default connect(mapStateToProps)(CellText);
