import { createAction } from "@reduxjs/toolkit"

/**
 * Open the initial values panel.
 */
const openInitialValuesPanel = createAction("ui/newDevelopment/OPEN_INITIAL_VALUES_PANEL");

/**
 * Close the initial values panel.
 */
const closeInitialValuesPanel = createAction("ui/newDevelopment/CLOSE_INITIAL_VALUES_PANEL");

export default {
  openInitialValuesPanel,
  closeInitialValuesPanel,
};
