import React from "react";
import { useSelector } from "react-redux";
import { authenticationSelectors } from "../../../../state/authentication";
import KpiPanel from "./KpiPanel";

const KpiPanelVisibility = () => {
  const authenticationIsInitialized = useSelector(authenticationSelectors.getAuthenticationIsInitialized);
  const userIsAuthenticated = useSelector(authenticationSelectors.getUserIsAuthenticated);

  return authenticationIsInitialized && userIsAuthenticated
    ? <KpiPanel />
    : null;
}

export default KpiPanelVisibility;
