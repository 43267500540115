import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";
import { UserDocument } from "../../types/UserDocument";

/**
 * Load a subscription.
 */
const loadStart = (subscriptionId: string) => {
  return {
    type: actionTypes.LOAD_START,
    payload: { subscriptionId },
  };
}

/**
 * Update the subscription concern in the state.
 */
const loadSuccess = (subscription: Chargebee.Subscription | null) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { subscription },
  };
}

/**
 * Create a subscription for the current user.
 */
const createStart = (
  userDocument: UserDocument,
  subscriptionPlanId: Chargebee.PlanId,
  couponIds: Array<string>,
  paymentIntent?: Chargebee.PaymentIntent,
  firstName?: string,
  lastName?: string,
) => {
  return {
    type: actionTypes.CREATE_START,
    payload: { userDocument, paymentIntent, subscriptionPlanId, couponIds, firstName, lastName },
  };
}

/*
 * Save subscription into the state.
 */
const createSuccess = (subscription: Chargebee.Subscription, customer: Chargebee.Customer) => {
  return {
    type: actionTypes.CREATE_SUCCESS,
    payload: { subscription, customer },
  }
}

/**
 * Cancel a subscription for the current user.
 */
const cancelStart = (subscriptionId: string) => {
  return {
    type: actionTypes.CANCEL_START,
    payload: { subscriptionId },
  };
}

/*
 * Reload subscription and subscription invitations information.
 */
const cancelSuccess = () => {
  return {
    type: actionTypes.CANCEL_SUCCESS,
  }
}

/**
 * Load the current user credit card information.
 */
const loadCreditCardStart = (userDocument) => {
  return {
    type: actionTypes.LOAD_CREDIT_CARD_START,
    payload: { userDocument },
  };
}

/**
 * Update the subscription concern in the state with the credit card information.
 */
const loadCreditCardSuccess = (creditCard: Chargebee.Card | null) => {
  return {
    type: actionTypes.LOAD_CREDIT_CARD_SUCCESS,
    payload: { creditCard },
  };
}

/**
 * Set plan prices in the state.
 */
const setPlanPrices = (planPrices: Chargebee.PlanPrices) => {
  return {
    type: actionTypes.SET_PLAN_PRICES,
    payload: { planPrices },
  };
}

/**
 * Update the current user credit card information.
 */
const updateCreditCard = (firstName, lastName, paymentIntent, chargebeeCustomerId) => {
  return {
    type: actionTypes.UPDATE_CREDIT_CARD,
    payload: { firstName, lastName, paymentIntent, chargebeeCustomerId },
  };
}

/**
 * Set the payment Start flag in the state.
 */
 const paymentStart = () => {
    return {
      type: actionTypes.PAYMENT_PROCESS_START,
    };
}

/**
 * Set the payment Success flag in the state.
 */
 const paymentSuccess = () => {
    return {
      type: actionTypes.PAYMENT_PROCESS_SUCCESS,
    };
}

/**
 * Set the payment error flag and the error message in the state.
 */
 const setPaymentError = (errorMessage) => {
    return {
      type: actionTypes.SET_PAYMENT_ERROR,
      payload: { errorMessage },
    };
}

/**
 * Reset all error flags.
 */
const resetErrorFlags = () => {
  return {
    type: actionTypes.RESET_ERROR_FLAGS,
  };
}

export default {
  loadStart,
  loadSuccess,
  createStart,
  createSuccess,
  cancelStart,
  cancelSuccess,
  loadCreditCardStart,
  loadCreditCardSuccess,
  setPlanPrices,
  updateCreditCard,
  paymentStart,
  paymentSuccess,
  setPaymentError,
  resetErrorFlags,
}
