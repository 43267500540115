export enum SubscriptionInvitationStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Cancelled = "cancelled",
  PaymentFailed = "payment failed",
}

export enum ManagedSubscriptionStatus {
  Pending = "pending",
  Active = "active",
  Cancelled = "cancelled",
  Expiring = "expiring",
  PaymentFailed = "payment failed",
  Rejected = "rejected",
}
