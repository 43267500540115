import actionTypes from "./actionTypes";
import Pdf from "types/Pdf";
import { GraphId, GraphDataSources } from "types/Graph";
import { ReactMapboxGlCamera } from "types/ReactMapboxGlCamera";
import { PdfState } from "./reducers";

/**
 * Initialize the pdf object.
 */
const start = () => {
  return {
    type: actionTypes.START,
  }
}

/**
 * Reset the pdf object to its initial state.
 */
const finish = () => {
  return {
    type: actionTypes.FINISH,
  }
}

/**
 * Set the value of the given mapImage variable.
 */
const setMapImage = (imageType: Pdf.ImageType, mapImage: string) => {
  return {
    type: actionTypes.SET_MAP_IMAGE,
    payload: {
      imageType,
      mapImage,
    },
  }
}

/**
 * Set the current camera options of the development project.
 */
const setCamera = (camera: ReactMapboxGlCamera) => {
  return {
    type: actionTypes.SET_CAMERA,
    payload: {
      camera,
    },
  }
}

/**
 * Set the title.
 */
const setTitle = (title: string) => {
  return {
    type: actionTypes.SET_TITLE,
    payload: {
      title,
    },
  }
}

/**
 * Set the summary.
 */
const setSummary = (summary: string) => {
  return {
    type: actionTypes.SET_SUMMARY,
    payload: {
      summary,
    },
  }
}

/**
 * Set the `from` contact details.
 */
const setFromContactsDetails = (fromContactDetails: Pdf.ContactDetails) => {
  return {
    type: actionTypes.SET_FROM_CONTACTS_DETAILS,
    payload: {
      fromContactDetails,
    },
  }
}

/**
 * Set the `to` contact details.
 */
const setToContactsDetails = (toContactDetails: Pdf.ContactDetails) => {
  return {
    type: actionTypes.SET_TO_CONTACTS_DETAILS,
    payload: {
      toContactDetails,
    },
  }
}

/**
 * Set the color palette.
 */
const setColorPalette = (colorPalette: Partial<PdfState["colorPalette"]>) => {
  return {
    type: actionTypes.SET_COLOR_PALETTE,
    payload: {
      colorPalette,
    },
  }
}

/**
 * Clear the pdf state object
 */
const clearState = () => {
  return {
    type: actionTypes.CLEAR_STATE,
  }
}

/**
 * Upload image to the database.
 */
const uploadCoverPageImageStart = (developmentId: string, imageFile: File, imageType: Pdf.ImageType) => {
  return {
    type: actionTypes.UPLOAD_COVER_PAGE_IMAGE_START,
    payload: {
      developmentId,
      imageFile,
      imageType,
    },
  }
}

/**
 * Upload image success.
 */
const uploadCoverPageImageSuccess = (image: Pdf.Image, imageType: Pdf.ImageType) => {
  return {
    type: actionTypes.UPLOAD_COVER_PAGE_IMAGE_SUCCESS,
    payload: {
      image,
      imageType,
    },
  }
}

/**
 * Initialize deletion of image.
 */
const deleteCoverPageImageStart = (developmentId: string, imageType: Pdf.ImageType) => {
  return {
    type: actionTypes.DELETE_COVER_PAGE_IMAGE_START,
    payload: {
      developmentId,
      imageType,
    },
  }
}

/**
 * Success deletion of image.
 */
const deleteCoverPageImageSuccess = (imageType: Pdf.ImageType) => {
  return {
    type: actionTypes.DELETE_COVER_PAGE_IMAGE_SUCCESS,
    payload: {
      imageType,
    },
  }
}

/**
 * Set the loaded data in the store.
 */
const loadDataSuccess = (
  document: Pdf.Document,
  coverPageImages: {
    [Pdf.ImageType.Cover]: Pdf.Image | null;
    [Pdf.ImageType.Logo]: Pdf.Image | null;
}) => {
  return {
    type: actionTypes.LOAD_DATA_SUCCESS,
    payload: {
      document,
      coverPageImages,
    },
  }
}

/**
 * Set the address in the store.
 */
const setAddress = (address: Pdf.Address) => {
  return {
    type: actionTypes.SET_ADDRESS,
    payload: {
      address,
    },
  }
}

/**
 * Initialize the graph data sources for the current development.
 */
const initializeGraphDataSources = (graphDataSources: GraphDataSources) => ({
  type: actionTypes.INITIALIZE_GRAPH_DATA_SOURCES,
  payload: graphDataSources,
})

/**
 * Set the object URL for the identified graph image.
 */
const setGraphImage = (graphId: GraphId, graphImage: string) => ({
  type: actionTypes.SET_GRAPH_IMAGE,
  payload: { [graphId]: graphImage },
})

export default {
  start,
  finish,
  setMapImage,
  setCamera,
  setTitle,
  setSummary,
  setFromContactsDetails,
  setToContactsDetails,
  setColorPalette,
  deleteCoverPageImageSuccess,
  loadDataSuccess,
  uploadCoverPageImageStart,
  uploadCoverPageImageSuccess,
  deleteCoverPageImageStart,
  clearState,
  setAddress,
  initializeGraphDataSources,
  setGraphImage,
}
