import React from "react";
import AddressBar from "../AddressBar";
import PdfContext from "../../../PdfContext";
import { View, StyleSheet } from "@react-pdf/renderer";

const DEFAULT_LEFT_PADDING = 25;

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    right: 0,
    height: 48,
    backgroundColor: "white",
    paddingTop: 20,
    paddingLeft: DEFAULT_LEFT_PADDING,
  },
});

interface Props {
  leftPadding?: number;
}

const Header = (props: Props) => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <View style={[styles.container, { backgroundColor: colorPalette.primary, paddingLeft: props.leftPadding }]} fixed>
        <AddressBar textColor={colorPalette.text} />
      </View>
    )}
  </PdfContext.Consumer>
);

export default Header;
