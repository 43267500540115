import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { mapsSelectors } from "../../../../state/ui/shared/maps";
import LayerGroup from "../../../../types/LayerGroup";
import { LayerSector } from "../../../../types/LayerSector";
import layerHelper from "../../../../utils/mapbox/layerHelper";
import config from "../config";
import LayerButton from "../LayerButton";

const mapStateToProps = (state) => ({
  layerConfigurations: mapsSelectors.getLayerConfigurations(state),
  tier: subscriptionSelectors.getTier(state),
});

interface OwnProps {
  layerSector: LayerSector;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

export class LayerSectorPanel extends React.PureComponent<Props, {}> {
  /**
   * Render layer control button.
   */
  renderLayerButton = (layerGroup: LayerGroup, index: number) => {
    if (
      !config[layerGroup].layers.some(
        (layerId) =>
          this.props.layerConfigurations[layerId].isEnabled && this.props.layerConfigurations[layerId].isAvailable
      )
    ) {
      return null;
    }

    const disableForDemo = layerHelper.disableForDemo(
      this.props.tier,
      config[layerGroup].layers,
      this.props.layerConfigurations
    );
    return (
      <LayerButton
        layerIds={config[layerGroup].layers}
        text={config[layerGroup].buttonText}
        key={`layer_button_${index}`}
        disableForDemo={disableForDemo}
      />
    );
  };

  render() {
    const { layerSector } = this.props;

    return (
      <div className="component--layer-sector-panel">
        {Object.keys(config)
          .filter((layerGroup) => config[layerGroup].layerSector === layerSector)
          .map((layerGroup, index) => this.renderLayerButton(layerGroup as LayerGroup, index))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(LayerSectorPanel);
