import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../../state/subscription";
import { mapsSelectors } from "../../../../../state/ui/shared/maps";
import { MapStyleProperties } from "utils/mapbox/mapStyleProperties";
import DynamicSliderComponent from "../DynamicSliderComponent";
import { Tier } from "types/Tier";
import layerHelper from "utils/mapbox/layerHelper";

const FIELD_TO_LABEL_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.Population2010]: "2010",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2011]: "2011",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2012]: "2012",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2013]: "2013",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2014]: "2014",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2015]: "2015",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2016]: "2016",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2017]: "2017",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2018]: "2018",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2019]: "2019",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2020]: "2020",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2021]: "2021",
  [MapStyleProperties.RawDynamicLayersFieldId.Population2022]: "2022",
};

const SELECTED_TO_DEFAULT_YEAR = {
  [MapStyleProperties.RawDynamicLayersFieldId.Population2010]: 2010,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2011]: 2011,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2012]: 2012,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2013]: 2013,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2014]: 2014,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2015]: 2015,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2016]: 2016,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2017]: 2017,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2018]: 2018,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2019]: 2019,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2020]: 2020,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2021]: 2021,
  [MapStyleProperties.RawDynamicLayersFieldId.Population2022]: 2022,
};

const mapStateToProps = (state) => ({
  tier: subscriptionSelectors.getTier(state),
  getSelectedDynamicLayer: mapsSelectors.getSelectedDynamicLayerGroup(state),
  dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  getSelectedDynamicLayerSet: mapsSelectors.getSelectedDynamicLayerSet(state),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

export class PopulationDynamicLayerSlider extends React.PureComponent<Props, {}> {
  render() {
    const { getSelectedDynamicLayer, tier, dynamicLayerConfigurations, getSelectedDynamicLayerSet } = this.props;
    if (!getSelectedDynamicLayer || !getSelectedDynamicLayerSet) return null;

    const layerSelected = getSelectedDynamicLayerSet[getSelectedDynamicLayer];
    if (!layerSelected) return null;

    const isPopulationLayer = getSelectedDynamicLayer === MapStyleProperties.DynamicLayerId.Dynamic_Layer_Population;
    const isDemo = tier === Tier.None;
    const hasData = layerHelper.areLayersAvailableInGroup(dynamicLayerConfigurations, getSelectedDynamicLayer);
    const renderComponent = isPopulationLayer && !isDemo && hasData;

    return (
      <>
        {renderComponent && (
          <DynamicSliderComponent
            currentLabel={FIELD_TO_LABEL_YEAR[layerSelected]}
            sliderMin={2010}
            sliderMax={2022}
            defaultValue={SELECTED_TO_DEFAULT_YEAR[layerSelected]}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(PopulationDynamicLayerSlider);
