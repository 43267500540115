import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../state/development";
import { pdfSelectors } from "../../../../state/pdf";
import wrapComponentWithPopup from "../../../sharedComponents/wrapComponentWithPopup";
import { PopupProps } from "../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import authentication from "../../../../utils/authentication";
import developmentUiHelper from "../../../utils/developmentUiHelper";
import analytics from "../../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    development: developmentSelectors.getDevelopment(state),
    developmentName: developmentSelectors.getName(state),
    pdfDocument: pdfSelectors.getPdfDocument(state),
    developmentId: developmentSelectors.getProjectId(state),
    developmentIsEdited: developmentSelectors.getIsEdited(state),
  };
};

interface OwnProps {
  closePopup(): void;
}

const mapDispatchToProps = {
  duplicateDevelopment: developmentActions.duplicateStart,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps & PopupProps;

interface State {
  projectName: string;
  formSubmitting: boolean;
}

class CopyProjectPopup extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      projectName: `Copy of ${developmentUiHelper.getDisplayName(this.props.developmentName)}`,
      formSubmitting: false,
    };
  }

  /**
   * Return true if the submit button is disabled.
   */
  buttonDisabled = () => {
    return this.state.formSubmitting;
  };

  /**
   * Handle input change.
   */
  handleInputChange = (event) => {
    this.setState({ projectName: event.target.value });
  };

  /**
   * Make a copy of the current project.
   */
  handleFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ formSubmitting: true });
    if (this.props.closePopup) this.props.closePopup();

    const user = authentication.getCurrentUser();
    const { development, pdfDocument, developmentId, developmentIsEdited } = this.props;
    this.props.duplicateDevelopment(user, development, this.state.projectName, developmentIsEdited, pdfDocument);
    analytics.trackDuplicateProject(developmentId);
  };

  render() {
    const projectName = developmentUiHelper.getDisplayName(this.props.developmentName);
    return (
      <div className="component--copy-project-popup">
        <div className="top">
          <div className="header">{`${projectName} Project`}</div>
          <div className="top-separator" />
          <div className="text">This project will be added to your Dashboard</div>
        </div>

        <form onSubmit={this.handleFormSubmit}>
          <div className="input-container">
            <label>Name:</label>
            <input type="text" value={this.state.projectName} onChange={this.handleInputChange} placeholder={developmentUiHelper.EMPTY_NAME_PLACEHOLDER} />
          </div>

          <button type="submit" disabled={this.buttonDisabled()}>
            Duplicate Project
          </button>
        </form>
      </div>
    );
  }
}

export default wrapComponentWithPopup(connect(mapStateToProps, mapDispatchToProps)(CopyProjectPopup));
