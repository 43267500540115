import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { mapsSelectors } from "../../../state/ui/shared/maps";
import BusySpinner from "../BusySpinner";
import AreasWithParcelsLegend from "./AreasWithParcelsLegend";
import BayAreaHousingSitesLegend from "./BayAreaHousingSitesLegend";
import BuildingsLegend from "./BuildingsLegend";
import BusinessesLegend from "./BusinessesLegend";
import CapRateLegend from "./CapRateLegend";
import CensusTractsLegend from "./CensusTractsLegend";
import CityBoundariesLegend from "./CityBoundariesLegend";
import DifficultDevelopmentAreasLegend from "./DifficultDevelopmentAreasLegend";
import DynamicEmploymentPerPopulationLegend from "./DynamicEmploymentPerPopulationLegend";
import DynamicMedianIncomeLegend from "./DynamicMedianIncomeLegend";
import DynamicMedianRentLegend from "./DynamicMedianRentLegend";
import DynamicPopulationLegend from "./DynamicPopulationLegend";
import EducationLegend from "./EducationLegend";
import FederalOwnedLandsLegend from "./FederalOwnedLandsLegend";
import ForestsLegend from "./ForestsLegend";
import HazardousSitesLegend from "./HazardousSitesLegend";
import HistoricBuildingsLegend from "./HistoricBuildings";
import HistoricSitesLegend from "./HistoricSitesLegend";
import HospitalsLegend from "./HospitalsLegend";
import HotelAverageDailyRateLegend from "./HotelAverageDailyRateLegend";
import HotelsLegend from "./HotelsLegend";
import IndianReservationsLegend from "./IndianReservationsLegend";
import IndustrialRateLegend from "./IndustrialRateLegend";
import LihtcExistingPropertyInventoryLegend from "./LIHTC_ExistingPropertyInventoryLegend";
import MultifamilyRentLegend from "./MultifamilyRentLegend";
import NaturalAndMarineProtectedLandsLegend from "./NaturalAndMarineProtectedLandsLegend";
import OfficeRateLegend from "./OfficeRateLegend";
import OpportunityZoneLegend from "./OpportunityZoneLegend";
import ParksLegend from "./ParksLegend";
import QualifiedCensusTractsLegend from "./QualifiedCensusTractsLegend";
import ReitsProjectsLegend from "./ReitsProjectsLegend";
import RetailRateLegend from "./RetailRateLegend";
import RoadTypeLegend from "./RoadTypeLegend";
import SeaLevelRiseLegend from "./SeaLevelRiseLegend";
import SmartSearchResultsLegend from "./SmartSearchResultsLegend";
import TrafficCountLegend from "./TrafficCountLegend";
import TransitOrientedCommunityLegend from "./TransitOrientedCommunityLegend/TransitOrientedCommunityLegend";
import WatershedsLegend from "./WatershedsLegend/WatershedsLegend";
import WetlandsLegend from "./WetlandsLegend";
import ZoningLegend from "./ZoningLegend";
import LayerGroup from "types/LayerGroup";
import { getActiveDynamicLayerGroups, getActiveLayerGroups } from "./MapLayersGroups";
import { DynamicLayerId, LayerId } from "utils/mapbox/mapStyleProperties/mapStyleProperties";
import ShowOnlyFilter from "./sharedComponents/ShowOnlyFilter/ShowOnlyFilter";
import IndustrialSitesLegend from "./IndustrialSitesLegend";
import MultifamilySitesLegend from "./MultifamilySitesLegend";

const layerGroupToComponent = {
  [LayerGroup.CountyBoundaries]: <AreasWithParcelsLegend />,
  [LayerGroup.BayAreaHousingSites]: <BayAreaHousingSitesLegend />,
  [LayerGroup.CapRates]: <CapRateLegend />,
  [LayerGroup.CensusTracts]: <CensusTractsLegend />,
  [LayerGroup.CityBoundaries]: <CityBoundariesLegend />,
  [LayerGroup.DifficultDevelopmentAreas]: <DifficultDevelopmentAreasLegend />,
  [LayerGroup.EducationFacilities]: <EducationLegend />,
  [LayerGroup.Buildings]: <BuildingsLegend />,
  [LayerGroup.FederalOwnedLands]: <FederalOwnedLandsLegend />,
  [LayerGroup.Forests]: <ForestsLegend />,
  [LayerGroup.HazardousSites]: <HazardousSitesLegend />,
  [LayerGroup.HistoricBuildings]: <HistoricBuildingsLegend />,
  [LayerGroup.HistoricSites]: <HistoricSitesLegend />,
  [LayerGroup.Hospitals]: <HospitalsLegend />,
  [LayerGroup.HotelAverageDailyRate]: <HotelAverageDailyRateLegend />,
  [LayerGroup.Hotels]: <HotelsLegend />,
  [LayerGroup.IndianReservations]: <IndianReservationsLegend />,
  [LayerGroup.IndustrialRate]: <IndustrialRateLegend />,
  [LayerGroup.LIHTC_ExistingPropertyInventory]: <LihtcExistingPropertyInventoryLegend />,
  [LayerGroup.Businesses]: <BusinessesLegend />,
  [LayerGroup.OfficeRate]: <OfficeRateLegend />,
  [LayerGroup.OpportunityZone]: <OpportunityZoneLegend />,
  [LayerGroup.Parks]: <ParksLegend />,
  [LayerGroup.NaturalAndMarineProtectedLands]: <NaturalAndMarineProtectedLandsLegend />,
  [LayerGroup.QualifiedCensusTracts]: <QualifiedCensusTractsLegend />,
  [LayerGroup.ReitsProjects]: <ReitsProjectsLegend />,
  [LayerGroup.MultifamilyRent]: <MultifamilyRentLegend />,
  [LayerGroup.RetailRate]: <RetailRateLegend />,
  [LayerGroup.RoadType]: <RoadTypeLegend />,
  [LayerGroup.SmartSearchResults]: <SmartSearchResultsLegend />,
  [LayerGroup.TrafficDailyAverage2019]: <TrafficCountLegend />,
  [LayerGroup.TransitOrientedCommunity]: <TransitOrientedCommunityLegend />,
  [LayerGroup.Watersheds]: <WatershedsLegend />,
  [LayerGroup.Wetlands]: <WetlandsLegend />,
  [LayerGroup.Zoning]: <ZoningLegend />,
  [LayerGroup.IndustrialSites]: <IndustrialSitesLegend />,
  [LayerGroup.MultifamilySites]: <MultifamilySitesLegend />,
  [DynamicLayerId.Dynamic_Layer_Population]: <DynamicPopulationLegend />,
  [DynamicLayerId.Dynamic_Layer_MedianRent]: <DynamicMedianRentLegend />,
  [DynamicLayerId.Dynamic_Layer_Employment_Per_Population]: <DynamicEmploymentPerPopulationLegend />,
  [DynamicLayerId.Dynamic_Layer_Household_Income]: <DynamicMedianIncomeLegend />,
  [DynamicLayerId.Dynamic_Layer_Sea_Level_Rise]: <SeaLevelRiseLegend />,
};

const mapStateToProps = (state) => {
  return {
    mapIsReady: mapsSelectors.getMapIsReady(state),
    layerConfigurations: mapsSelectors.getLayerConfigurations(state),
    dynamicLayerConfigurations: mapsSelectors.getDynamicLayerConfigurations(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const MapLegendPanel: FC<Props> = ({ mapIsReady, layerConfigurations, dynamicLayerConfigurations }) => {
  const [showOnlyActive, setShowOnlyActive] = useState<boolean>(false);

  const renderActiveLayersLegends = () => {
    const activeLayers = Object.entries(layerConfigurations)
      // The layer `usa-buildings-query` is not a visual layer (but a query layer)
      //  and it activates the Existing Building Map Legend button.
      // Even when it is not activated on the map
      .filter(([key, layer]) => layer.isActive && key != LayerId.UsaBuildingsQuery)
      .map(([key, layer]) => key);

    const activeDynamicLayers = Object.entries(dynamicLayerConfigurations)
      .filter(([key, layer]) => layer.isActive)
      .map(([key, layer]) => key);

    const activeLayerGroups = getActiveLayerGroups(activeLayers);
    const activeDynamicLayerGroups = getActiveDynamicLayerGroups(activeDynamicLayers);

    return (
      <>
        {activeLayerGroups.map((layerGroup) => {
          return <div key={layerGroup}>{layerGroupToComponent[layerGroup]}</div>;
        })}
        {activeDynamicLayerGroups.map((layerGroup) => {
          return <div key={layerGroup}>{layerGroupToComponent[layerGroup]}</div>;
        })}
      </>
    );
  };

  const renderLegends = () => (
    <>
      {/*
        Make sure these components are sorted
        alphabetically by the value of
        the `legendTitle` in the config file
        `...LayerSelection/config.ts`
      */}
      <AreasWithParcelsLegend />
      <BayAreaHousingSitesLegend />
      <CapRateLegend />
      <CensusTractsLegend />
      <CityBoundariesLegend />
      <DifficultDevelopmentAreasLegend />
      <EducationLegend />
      <DynamicEmploymentPerPopulationLegend />
      <BuildingsLegend />
      <FederalOwnedLandsLegend />
      <ForestsLegend />
      <HazardousSitesLegend />
      <HistoricBuildingsLegend />
      <HistoricSitesLegend />
      <HospitalsLegend />
      <HotelAverageDailyRateLegend />
      <HotelsLegend />
      <IndianReservationsLegend />
      <IndustrialRateLegend />
      <IndustrialSitesLegend />
      <LihtcExistingPropertyInventoryLegend />
      <BusinessesLegend />
      <DynamicMedianRentLegend />
      <DynamicMedianIncomeLegend />
      <MultifamilySitesLegend />
      <OfficeRateLegend />
      <OpportunityZoneLegend />
      <ParksLegend />
      <DynamicPopulationLegend />
      <NaturalAndMarineProtectedLandsLegend />
      <QualifiedCensusTractsLegend />
      <ReitsProjectsLegend />
      <MultifamilyRentLegend />
      <RetailRateLegend />
      <RoadTypeLegend />
      <SeaLevelRiseLegend />
      <SmartSearchResultsLegend />
      <TrafficCountLegend />
      <TransitOrientedCommunityLegend />
      <WatershedsLegend />
      <WetlandsLegend />
      <ZoningLegend />

      {/* These layers would be paused for now */}
      {/* <MultifamilyPermitsLegend /> */}
      {/* <ResidentialPermitsChangeLegend /> */}
      {/* <ResidentialPermitsLegend /> */}
      {/* <MultifamilyPermitsChangeLegend /> */}
    </>
  );

  return (
    <div className="component--map-legend-panel">
      <div className="section-divisor">
        <label>Map Layers Legend</label>
        <ShowOnlyFilter showOnlyActive={showOnlyActive} setShowOnlyActive={setShowOnlyActive} />
      </div>
      {mapIsReady ? showOnlyActive ? renderActiveLayersLegends() : renderLegends() : <BusySpinner />}
    </div>
  );
};

export default connect(mapStateToProps)(MapLegendPanel);
