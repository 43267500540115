import actionTypes from "./actionTypes";
import { developmentActionTypes } from "../../development";

export interface ExplorerUiState {
  copyProjectPopupIsOpen: boolean;
  assumptionsPanelIsOpen: boolean;
  defineBuildingPanelIsOpen: boolean;
  returnAnalysisPanelIsOpen: boolean;
  propertyInfoPanelIsOpen: boolean;
}

export const INITIAL_STATE: ExplorerUiState = {
  copyProjectPopupIsOpen: false,
  assumptionsPanelIsOpen: false,
  defineBuildingPanelIsOpen: true,
  returnAnalysisPanelIsOpen: true,
  propertyInfoPanelIsOpen: false,
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: ExplorerUiState = INITIAL_STATE, action): ExplorerUiState => {
  switch (action.type) {
    case actionTypes.TOGGLE_COPY_PROJECT_POPUP: return toggleCopyProjectPopup(previousState, action.payload);
    case actionTypes.SET_ASSUMPTIONS_PANEL_IS_OPEN: return setAssumptionPanelIsOpen(previousState, action.payload);
    case actionTypes.SET_DEFINE_BUILDING_PANEL_IS_OPEN: return setDefineBuildingPanelIsOpen(previousState, action.payload);
    case actionTypes.SET_RETURN_ANALYSIS_PANEL_IS_OPEN: return setReturnAnalysisPanelIsOpen(previousState, action.payload);
    case actionTypes.SET_PROPERTY_INFO_PANEL_IS_OPEN: return setPropertyInfoPanelIsOpen(previousState, action.payload);
    case developmentActionTypes.LOAD_SUCCESS: return reset(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * See `toggleCopyProjectPopup` action creator.
 *
 * Toggles the copy project popup flag.
 */
const toggleCopyProjectPopup = (previousState: ExplorerUiState, payload): ExplorerUiState => {
  return {
    ...previousState,
    copyProjectPopupIsOpen: !previousState.copyProjectPopupIsOpen,
  }
}

/**
 * See `setAssumptionPanelsIsOpen` action creator.
 *
 * Sets the assumptionPanelIsOPen flag.
 */
const setAssumptionPanelIsOpen = (previousState: ExplorerUiState, payload): ExplorerUiState => {
  return {
    ...previousState,
    assumptionsPanelIsOpen: payload.value,
    defineBuildingPanelIsOpen: payload.value ? false : previousState.defineBuildingPanelIsOpen,
  }
}

/**
 * See `setDefineBuildingPanelIsOpen` action creator.
 *
 * Sets the defineBuildingPanelIsOpen flag.
 */
const setDefineBuildingPanelIsOpen = (previousState: ExplorerUiState, payload): ExplorerUiState => {
  return {
    ...previousState,
    defineBuildingPanelIsOpen: payload.value,
    assumptionsPanelIsOpen: payload.value ? false : previousState.assumptionsPanelIsOpen,
  }
}

/**
 * See `setReturnAnalysisPanelIsOpen` action creator.
 *
 * Sets the returnAnalysisPanelIsOpen flag.
 */
const setReturnAnalysisPanelIsOpen = (previousState: ExplorerUiState, payload): ExplorerUiState => {
  return {
    ...previousState,
    returnAnalysisPanelIsOpen: payload.value,
    propertyInfoPanelIsOpen: payload.value ? false : previousState.propertyInfoPanelIsOpen,
  }
}

/**
 * See `setPropertyInfoPanelIsOpen` action creator.
 *
 * Sets the propertyInfoPanelIsOpen flag.
 */
const setPropertyInfoPanelIsOpen = (previousState: ExplorerUiState, payload): ExplorerUiState => {
  return {
    ...previousState,
    propertyInfoPanelIsOpen: payload.value,
    returnAnalysisPanelIsOpen: payload.value ? false : previousState.returnAnalysisPanelIsOpen,
  }
}

/**
 * Returns the initial state.
 */
const reset = (previousState, payload): ExplorerUiState => {
  return INITIAL_STATE;
}

export default reducer;
