import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import generatorFromModel from "./generatorFromModel";

/**
 * Generate the retail buildables presets.
 */
const generatePresets = async (development: Development, targetArea: number): Promise<object> => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.RetailLossFactor) as number;
  const netLeasableArea = targetArea * (1 - lossFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development, VariableId.RetailNetLeasableAreaToggleable);

  return {
    [VariableId.RetailNetLeasableAreaToggleable]: {
      "minimum": 0,
      "maximum": 2 * netLeasableArea || previousMaximum,
      "value": netLeasableArea
    },
    [VariableId.RetailYearOneRentPerArea]: await generatorFromModel.generateRentPreset(development,VariableId.RetailYearOneRentPerArea, BuildingUse.Retail), //TODO area?
    [VariableId.RetailHardCostPerArea]: await generatorFromModel.generateHardCostPreset(development, VariableId.RetailHardCostPerArea, BuildingUse.Retail),
  };
}

export default generatePresets;
