import React from "react";
import { useLocation } from "react-router-dom";
import { authenticationUiActions, authenticationUiSelectors } from "../../../../state/ui/shared/authentication";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import LogInPopup from "../../LogInPopup";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    logInIsOpen: authenticationUiSelectors.getLoginIsOpen(state),
  };
};

const mapDispatchToProps = {
  toggleLoginIsOpen: authenticationUiActions.toggleLoginIsOpen,
};

interface OwnProps {
  onLogin?(): void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

const LogInButton: React.FC<Props> = ({ onLogin, logInIsOpen, toggleLoginIsOpen }) => {
  const user = authentication.getCurrentUser();
  const location = useLocation();
  const page = location.pathname;

  let classes = "";
  if (page.includes(Path.NewProject)) classes = "on-new-project-page";
  else if (page.includes(Path.Explorer)) classes = "on-explorer-page";
  else if (page === Path.Home) classes = "on-home-page";

  if (user) return null;

  return (
    <>
      <div className={`component--button login ${logInIsOpen ? "active" : ""}`} onClick={toggleLoginIsOpen}>
        <div className="button" />
        <div className="text">Log In</div>
      </div>
      {logInIsOpen && <LogInPopup onStateChange={toggleLoginIsOpen} onLogIn={onLogin} classes={classes} />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInButton);
