import React from "react";
import { connect } from "react-redux";
import valueGetters from "../../../../../state/development/utils/valueGetters";
import { subscriptionSelectors } from "../../../../../state/subscription";
import Format from "../../../../../types/Format";
import authentication from "../../../../../utils/authentication";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import userHelper from "../../../../../utils/userHelper";
import Cell from "../../../Cell";
import CellRow from "../../../CellRow";
import CellText from "../../../CellText";

type OwnProps = {
  parcelFeature: any;
};

const mapStateToProps = (state) => {
  return {
    tier: subscriptionSelectors.getTier(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

/**
 * Render unit density ratio section.
 */
class Footnotes extends React.PureComponent<Props, {}> {
  renderFootnotesSection = (parcelFeature, index, isAssembly = false) => {
    const userHasAccess = userHelper.userHasAccess(authentication.isUserAuthenticated(), this.props.tier);
    const address = parcelAccessors.getAddress(parcelFeature) || `Property ${index + 1}`;

    const footnotes = {
      buildingHeight: parcelAccessors.getBuildingHeightFootnotes(parcelFeature),
      far: parcelAccessors.getFarFootnotes(parcelFeature),
      lotCoverage: parcelAccessors.getLotCoverageFootnotes(parcelFeature),
      minimumLotSize: parcelAccessors.getMinimumLotSizeFootnote(parcelFeature),
      numberOfFloors: parcelAccessors.getNumberOfFloorsFootnotes(parcelFeature),
      unitDensity: parcelAccessors.getUnitDensityFootnotes(parcelFeature),
      hotelDensity: parcelAccessors.getHotelDensityFootnotes(parcelFeature),
      setbacks: parcelAccessors.getSetbacksFootnotes(parcelFeature),
      uses: parcelAccessors.getUsesFootnotes(parcelFeature),
      parkingMultifamily: parcelAccessors.getParkingMultifamilyFootnote(parcelFeature),
      parkingSingleFamily: parcelAccessors.getParkingSingleFamilyFootnote(parcelFeature),
      parkingHotel: parcelAccessors.getParkingHotelFootnote(parcelFeature),
      parkingOffice: parcelAccessors.getParkingOfficeFootnote(parcelFeature),
      parkingRetail: parcelAccessors.getParkingRetailFootnote(parcelFeature),
      parkingIndustrial: parcelAccessors.getParkingIndustrialFootnote(parcelFeature),
    };

    if (Object.values(footnotes).every((type) => type === null)) return null;

    return (
      <table key={`table_${index}`}>
        <thead>
          <tr>
            <th colSpan={2}>{`Footnotes${isAssembly ? ` for ${address}` : ""}`}</th>
          </tr>
        </thead>
        <tbody>
          {footnotes.buildingHeight ? (
            <CellRow classes="sub-section">
              <CellText text="• Building Height" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.buildingHeight}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.far ? (
            <CellRow classes="sub-section">
              <CellText text="• FAR" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.far}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.lotCoverage ? (
            <CellRow classes="sub-section">
              <CellText text="• Lot Coverage" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.lotCoverage}
                formatOptions={{ type: Format.Type.PlainText, suffix: " FT", hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.minimumLotSize ? (
            <CellRow classes="sub-section">
              <CellText text="• Minimum Lot Size" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.minimumLotSize}
                formatOptions={{ type: Format.Type.PlainText, suffix: " FT", hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.numberOfFloors ? (
            <CellRow classes="sub-section">
              <CellText text="• Number of Floors" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.numberOfFloors}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.unitDensity ? (
            <CellRow classes="sub-section">
              <CellText text="• Unit Density" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.unitDensity}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.hotelDensity ? (
            <CellRow classes="sub-section">
              <CellText text="• Hotel Density" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.unitDensity}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.setbacks ? (
            <CellRow classes="sub-section">
              <CellText text="• Setbacks" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.setbacks}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.uses ? (
            <CellRow classes="sub-section">
              <CellText text="• Uses Allowed" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.uses}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingMultifamily ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Residential" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingMultifamily}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingSingleFamily ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Single Family" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingSingleFamily}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingHotel ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Hotel" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingHotel}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingOffice ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Office" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingOffice}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingRetail ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Retail" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingRetail}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
          {footnotes.parkingIndustrial ? (
            <CellRow classes="sub-section">
              <CellText text="• Parking Industrial" />
              <Cell
                getter={valueGetters.generic}
                value={footnotes.parkingIndustrial}
                formatOptions={{ type: Format.Type.PlainText, hidden: !userHasAccess }}
                styleVariation="bold capitalize"
              />
            </CellRow>
          ) : null}
        </tbody>
      </table>
    );
  };

  render() {
    const { parcelFeature } = this.props;

    return parcelAccessors.getIsAnAssembly(parcelFeature)
      ? parcelAccessors
          .getAssemblyParcels(parcelFeature)
          .map((parcel, index) => this.renderFootnotesSection(parcel, index, true))
      : this.renderFootnotesSection(parcelFeature, 0);
  }
}

export default connect(mapStateToProps)(Footnotes);
