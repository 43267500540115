import React from "react";
import authentication from "../../../../../../../utils/authentication";
import { TooltipType } from "../../../../../../sharedComponents/Tooltip/config";

interface OwnProps {
  handleUpload(file?: File);
}

type Props = OwnProps;

interface State {
  dragOverIsActive: boolean;
}

class DragAndDrop extends React.PureComponent<Props, State> {
  inputReference;

  constructor(props) {
    super(props);

    this.state = {
      dragOverIsActive: false,
    }
  }

  /**
   * Set drag over flag.
   */
  handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.dragOverIsActive) this.setState({ dragOverIsActive: true });
  }

  /**
   * Unset drag over flag.
   */
  handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dragOverIsActive: false });
  }

  /**
   * Handle file upload.
   */
  handleFileUpload = (file) => {
    this.setState({ dragOverIsActive: false });
    this.props.handleUpload(file);
  }

  /**
   * Handle file drop.
   */
  handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated) {
      return;
    }

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.handleFileUpload(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  }

  /**
   * Handle file selection.
   */
  handleOnChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files.length > 0) this.handleFileUpload(event.target.files[0]);
  }

  /**
   * Open browsers file selection window.
   */
  openFileSelect = () => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated) {
      return;
    }

    if (this.inputReference) this.inputReference.click();
  }

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <div className="component--drag-and-drop">
        <div
          className={`drop-container ${this.state.dragOverIsActive ? "drag-over" : ""}`}
          onDragOver={this.handleDragOver}
          onMouseOver={this.handleDragOver}
          onMouseLeave={this.handleDragLeave}
          onDragLeave={this.handleDragLeave}
          onDrop={this.handleFileDrop}
          onClick={this.openFileSelect}
        >
          {!userIsAuthenticated && <div className="tooltip-helper" data-for="click-left" data-tip={TooltipType.PdfPanel} />}
          <div className="upload-logo">Drag and drop a file here or click</div>
          <input
            ref={(ref) => this.inputReference = ref}
            type="file"
            accept="image/png, image/jpeg"
            onChange={this.handleOnChange}
            hidden
          />
        </div>
      </div>
    )
  }
}

export default DragAndDrop;
