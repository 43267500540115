import React, { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { MapContext } from "react-mapbox-gl";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../state/newDevelopment";
import { developmentSelectors } from "../../../state/development";
import { ParcelTool } from "../../../types/ParcelTool";
import { MapTool } from "../../../types/MapTool";
import { Path } from "../../../types/Path";
import analytics from "../../../utils/analytics";
import developmentUiHelper from "../../utils/developmentUiHelper";
import WithPanelHeader from "../WithPanelHeader";
import MapStyleButton from "../MapStyleButton";
import ShareProject from "../ShareProject";
import MapTools from "../MapTools";
import CopyProject from "../CopyProject";
import NewProject from "../NewProject";
import { subscriptionSelectors } from "state/subscription";
import { Tier } from "types/Tier";
import DeveloperMapStyleButton from "../DeveloperMapStyleButton";

const mapStateToProps = (state) => {
  return {
    parcelToolProp: newDevelopmentSelectors.getParcelTool(state),
    developmentName: developmentSelectors.getName(state),
    tier: subscriptionSelectors.getTier(state),
  };
};

const mapDispatchToProps = {
  setParcelTool: newDevelopmentActions.setParcelTool,
};

const ParcelTools = ({ mapIsReady, parcelToolProp, developmentName, tier, setParcelTool }) => {
  const { projectId } = useParams();
  const location = useLocation();

  /**
   * Set parcel tool.
   */
  const handleSetParcelTool = useCallback(
    (parcelTool) => () => {
      setParcelTool(parcelTool);
      analytics.trackSelectParcelTool(parcelTool);
    },
    [setParcelTool]
  );

  /**
   * Render individual tool icons.
   */
  const renderParcelToolIcon = (parcelTool: ParcelTool, description: string) => {
    let className =
      `control-icon ${parcelTool}` +
      ` ${parcelToolProp === parcelTool ? "active" : ""}` +
      ` ${mapIsReady ? "" : "disabled"}`;

    return <div title={description} className={className} onClick={handleSetParcelTool(parcelTool)} />;
  };

  /**
   * Render map tool icons.
   */
  const renderMapToolIcon = (mapTool: MapTool, description: string, onClickHandler, pitchIs3D?: boolean) => {
    let className =
      `control-icon ${mapTool}` +
      ` ${mapTool === MapTool.TogglePitch && pitchIs3D ? "is-3d" : "is-2d"}` +
      ` ${mapIsReady ? "" : "disabled"}`;

    return <div title={description} className={className} onClick={() => onClickHandler(mapTool)} />;
  };

  /**
   * Render parcel tools on new project page only.
   */
  const renderParcelTools = () => {
    if (!location.pathname.includes(Path.NewProject)) return null;
    return (
      <>
        <div className="column">
          {renderParcelToolIcon(ParcelTool.SelectParcel, "Select Parcel")}
          {renderParcelToolIcon(ParcelTool.CombineParcels, "Select Multiple Parcels")}
        </div>
        <div className="column">
          {renderParcelToolIcon(ParcelTool.DrawParcel, "Draw Parcels")}
          {renderParcelToolIcon(ParcelTool.CopyParcel, "Copy Drawn Parcels")}
        </div>
        <div className="column">
          {renderParcelToolIcon(ParcelTool.EditParcel, "Edit and Move Drawn Parcels")}
          {renderParcelToolIcon(ParcelTool.DeleteParcel, "Delete Drawn Parcels")}
        </div>
      </>
    );
  };

  /**
   * Render explorer tools on explorer page only
   */
  const renderProjectTools = () => {
    if (!location.pathname.includes(Path.Explorer)) return null;
    const projectName = developmentUiHelper.getDisplayName(developmentName);

    return (
      <>
        <div className="column">
          <ShareProject
            developmentName={projectName}
            developmentId={projectId as string}
            popupClasses="explorer"
            classes="control-icon"
            text="Share Project"
          />
        </div>
        <div className="column">
          <CopyProject classes="control-icon" />
        </div>
        <div className="column">
          <NewProject classes="control-icon" />
        </div>
      </>
    );
  };

  return (
    <WithPanelHeader title="Tools" classes="first-child">
      <div className="component--parcel-tools">
        <div className="column">{tier === Tier.Developer ? <DeveloperMapStyleButton /> : <MapStyleButton />}</div>
        {renderParcelTools()}
        <MapContext.Consumer>
          {(map) => {
            if (!map) return null;

            return (
              <MapTools map={map}>
                {(pitchIs3D, onClick) => (
                  <>
                    <div className="column">
                      {renderMapToolIcon(MapTool.TogglePitch, "Change Map Pitch", onClick, pitchIs3D)}
                      {renderMapToolIcon(MapTool.Rotate, "Rotate Map", onClick)}
                    </div>
                    <div className="column">
                      {renderMapToolIcon(MapTool.ZoomIn, "Zoom In", onClick)}
                      {renderMapToolIcon(MapTool.ZoomOut, "Zoom Out", onClick)}
                    </div>
                  </>
                )}
              </MapTools>
            );
          }}
        </MapContext.Consumer>
        {renderProjectTools()}
      </div>
    </WithPanelHeader>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelTools);
