import actionTypes from "./actionTypes";
import { ThumbnailState } from "./thumbnail/reducers";
import thumbnailReducer from "./thumbnail/reducers";

export type ThumbnailsCollectionState = { [developmentId: string]: ThumbnailState };

export interface ThumbnailsState {
  collection: ThumbnailsCollectionState;
  currentThumbnail: Blob | null;
}

const INITIAL_STATE: ThumbnailsState = {
  collection: {},
  currentThumbnail: null,
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): ThumbnailsState => {
  switch (action.type) {
    case actionTypes.DELETE: return deleteThumbnail(previousState, action.payload);
    case actionTypes.SET_CURRENT_THUMBNAIL: return setCurrentThumbnail(previousState, action.payload);
    case actionTypes.LOAD_SUCCESS:
    case actionTypes.LOAD_ERROR:
      return reduceMember(previousState, action);

    default: return previousState;
  };
}

/**
 * Update a particular member of the thumbnail collection.
 */
const reduceMember = (previousState, action): ThumbnailsState => {
  const developmentId: string = action.payload.developmentId;
  const thumbnail: ThumbnailState = previousState.collection[developmentId];

  return {
    ...previousState,
    collection: {
      ...previousState.collection,
      [developmentId]: thumbnailReducer(thumbnail, action),
    },
  };
}

/**
 * Delete a particular member of the thumbnail collection.
 */
const deleteThumbnail = (previousState, payload): ThumbnailsState => {
  const developmentId: string = payload.developmentId;
  let newCollection = { ...previousState.collection };
  delete newCollection[developmentId];

  return {
    ...previousState,
    collection: { ...newCollection },
  };
}

/**
 * Set the current thumbnail.
 */
const setCurrentThumbnail = (previousState, payload): ThumbnailsState => {
  return {
    ...previousState,
    currentThumbnail: payload.thumbnail,
  };
}

export default reducer;
