import React from "react";
import LayerGroup from "../../../../types/LayerGroup";
import LayerLegend from "../sharedComponents/LayerLegend";
import LegendRow from "../sharedComponents/LegendRow";

class RoadTypeLegend extends React.PureComponent<{}, {}> {
  render() {
    return (
      <LayerLegend
        layerGroup={LayerGroup.RoadType}
        classes="road-type"
        legendRows={[
          <LegendRow
            color="#dc8491"
            text="Principal - Interstate"
          />,
          <LegendRow
            color="#c4aba1"
            text="Principal - Expressway"
          />,
          <LegendRow
            color="#e4b883"
            text="Principal - Other"
          />,
          <LegendRow
            color="#f2d98e"
            text="Minor Arterial"
          />,
          <LegendRow
            color="#f2eb9e"
            text="Major Collector"
          />,
          <LegendRow
            color="#bacc81"
            text="Minor Collector"
          />,
          <LegendRow
            color="#709959"
            text="Local"
          />,
        ]}
      />
    );
  }
}

export default RoadTypeLegend;
