import React from "react";
import MapLocation from "./MapLocation";
import MapBuilding from "./MapBuilding";
import MapCover from "./MapCover";

const PdfMapScreenshotGenerators = () => (
  <>
    <MapLocation />
    <MapBuilding />
    <MapCover />
  </>
)

export default PdfMapScreenshotGenerators;
