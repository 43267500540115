import React, { useRef, useEffect } from "react";
import { ChartData } from "chart.js";
import { useSelector, useDispatch } from "react-redux";
import { Line } from "react-chartjs-2";
import { GraphId } from "types/Graph";
import { pdfSelectors, pdfActions } from "state/pdf";
import { getGraphData, GRAPH_TEXT_SETTINGS } from "utils/graphHelper";

interface Props {
  graphId: GraphId;
}

const PdfGraphRenderAndCapture = ({ graphId }: Props) => {
  const chartJsReference = useRef<any>(null);
  const dispatch = useDispatch();
  const graphDataSourceList = useSelector(pdfSelectors.getGraphDataSourceList);

  useEffect(() => {
    const canvas = chartJsReference.current?.chartInstance?.canvas as any;

    // NOTE: If the canvas element has not been attached to the ref yet, this line will cause the
    // PDF generator to hang forever. This is unlikely since `useEffect()` only runs after the
    // first paint, but it may be appropriate to add code to retry or fall back to something else.
    if (canvas !== undefined) {
      // NOTE: We create the blob this way because Microsoft Edge has spotty support for
      // `canvas.toBlob()`.
      const base64Url = canvas.toDataURL();
      fetch(base64Url)
        .then((response) => response.blob())
        .then((blob) => {
          const imageObjectUrl = URL.createObjectURL(blob);
          dispatch(pdfActions.setGraphImage(graphId, imageObjectUrl));
        })
    }
  })

  const { xAxisLabel, yAxisLabel, yAxisTickValueFormatter } = GRAPH_TEXT_SETTINGS[graphId];
  const graphData = getGraphData(graphDataSourceList, graphId);
  const data: ChartData = {
    labels: graphData.labels,
    datasets: graphData.dataset.map((dataset) => ({
      // NOTE: The label formatting here assumes there will be at most two data sources: one for
      // the national average, and one for the nearest census tract data.
      label: dataset.label === "US" ? "National Average" : "Local Trend",
      fill: false,
      lineTension: 0.3,
      borderColor: dataset.color,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: dataset.color,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: dataset.color,
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: dataset.data,
    }))
  };

  return (
    <div className="component--pdf-graph-renderer">
      <Line
        ref={chartJsReference}
        data={data}
        height={250}
        width={250}
        options={{
          animation: {
            duration: 0,
          },
          legend: {
            reverse: true,
            position: "bottom",
            align: "start",
            display: true,
            labels: {
              boxWidth: 20,
              fontFamily: "Roboto",
              fontColor: "#888888",
              fontSize: 12,
            },
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: Boolean(xAxisLabel),
                labelString: xAxisLabel,
                fontFamily: "Roboto",
                fontColor: "#888888",
                fontSize: 12,
              },
            }],
            yAxes: [{
              ticks: {
                callback: yAxisTickValueFormatter,
              },
              scaleLabel: {
                display: Boolean(yAxisLabel),
                labelString: yAxisLabel,
                fontFamily: "Roboto",
                fontColor: "#888888",
                fontSize: 12,
              }
            }],
          }
        }}
      />
    </div>
  )
}

// Use `React.memo()` to prevent multiple render and capture sequences resulting from the parent
// component re-rendering.
export default React.memo(PdfGraphRenderAndCapture)
