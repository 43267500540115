import { ExplorerUiState } from "./reducers";

/**
 * Get the getMapsState object.
 */
const getExplorerUiState = (state): ExplorerUiState => {
  let explorerState = state.ui.explorer;
  return explorerState;
}

/**
 * Get the copy project popup flag.
 */
const getCopyProjectPopupIsOpen= (state) => {
  let explorerState = getExplorerUiState(state);
  return explorerState.copyProjectPopupIsOpen;
}

/**
 * Get the assumptions panel is open flag.
 */
const getAssumptionPanelIsOpen= (state) => {
  let explorerState = getExplorerUiState(state);
  return explorerState.assumptionsPanelIsOpen;
}

/**
 * Get the define building panel is open flag.
 */
const getDefineBuildingPanelIsOpen= (state) => {
  let explorerState = getExplorerUiState(state);
  return explorerState.defineBuildingPanelIsOpen;
}

/**
 * Get the return analysis panel is open flag.
 */
const getReturnAnalysisPanelIsOpen= (state) => {
  let explorerState = getExplorerUiState(state);
  return explorerState.returnAnalysisPanelIsOpen;
}

/**
 * Get the property info panel is open flag.
 */
const getPropertyInfoPanelIsOpen= (state) => {
  let explorerState = getExplorerUiState(state);
  return explorerState.propertyInfoPanelIsOpen;
}

export default {
  getCopyProjectPopupIsOpen,
  getAssumptionPanelIsOpen,
  getDefineBuildingPanelIsOpen,
  getReturnAnalysisPanelIsOpen,
  getPropertyInfoPanelIsOpen,
}
